import React from 'react';

import DetailsLink from './DetailsLink';
import ToWork from './ToWork';

const Actions = ({ id, action }) => (
  <React.Fragment>
    <DetailsLink id={id} />
    {action && <ToWork action={action} />}
  </React.Fragment>
);

export default Actions;
