import { privilegeStatusConstants } from '../constants/privilegeStatusConstants';
import { userConstants } from '../constants/userConstants';
import { authConstants } from '../constants/authConstants';

const createItem = (items, item) => {
  items.unshift(item);
  return items;
};
const updateItem = (items, item) => {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== item.id) {
      result.push(items[i]);
    } else {
      result.push(item);
    }
  }
  return result;
};

const initialState = {
  showFormModal: false,
  currentItem: {},
  meta: { per_page: 7, total: 0, current_page: 1 },
  items: [],

  showVehicleFormModal: false,
  currentStatus: {
    loading: false,
    error: null,
    meta: { per_page: 7, total: 0, current_page: 1 },
    items: [],
    currentItem: {}
  },

  uploadFileError: false,
  fileUploaders: []
};

export const privilegeStatuses = (state = initialState, action) => {
  switch (action.type) {
    // Get page
    case privilegeStatusConstants.GET_PAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case privilegeStatusConstants.GET_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.privilegeStatuses.data,
        meta: action.privilegeStatuses.meta
      };
    case privilegeStatusConstants.GET_PAGE_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Get all
    case privilegeStatusConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case privilegeStatusConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        fetched: true,
        loading: false,
        items: action.privilegeStatuses.data
      };
    case privilegeStatusConstants.GET_ALL_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Create
    case privilegeStatusConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: createItem(state.items, action.privilegeStatus),
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        },
        showFormModal: false
      };

    // Update
    case privilegeStatusConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: updateItem(state.items, action.privilegeStatus),
        showFormModal: false
      };

    // Delete
    case privilegeStatusConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(privilegeStatus =>
          privilegeStatus.id === action.id
            ? { ...privilegeStatus, deleting: true }
            : privilegeStatus
        )
      };
    case privilegeStatusConstants.DELETE_SUCCESS:
      // remove deleted HeaderMenu from state
      return {
        ...state,
        loading: false,
        items: state.items.filter(privilegeStatus => privilegeStatus.id !== action.id),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    case privilegeStatusConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to HeaderMenu
      return {
        ...state,
        loading: false,
        items: state.items.map(privilegeStatus => {
          if (privilegeStatus.id === action.id) {
            // make copy of HeaderMenu without 'deleting:true' property
            const { deleting, ...privilegeStatusCopy } = privilegeStatus;
            // return copy of HeaderMenu with 'deleteError:[error]' property
            return { ...privilegeStatusCopy, deleteError: action.error };
          }

          return privilegeStatus;
        })
      };

    // Get item
    case privilegeStatusConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case privilegeStatusConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.privilegeStatus.data
      };
    case privilegeStatusConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // form modal
    case privilegeStatusConstants.FORM_MODAL_OPEN:
      return {
        ...state,
        showFormModal: true
      };
    case privilegeStatusConstants.FORM_MODAL_CLOSE:
      return {
        ...state,
        showFormModal: false
      };
    case privilegeStatusConstants.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item
      };

    // vehicles
    case privilegeStatusConstants.GET_VEHICLE_PAGE_REQUEST:
      return {
        ...state,
        currentStatus: {
          ...state.currentStatus,
          loading: true
        }
      };
    case privilegeStatusConstants.GET_VEHICLE_PAGE_SUCCESS:
      return {
        ...state,
        currentStatus: {
          ...state.currentStatus,
          loading: false,
          items: action.vehicles.data,
          meta: action.vehicles.meta
        }
      };
    case privilegeStatusConstants.GET_VEHICLE_PAGE_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        currentStatus: {
          ...state.currentStatus,
          loading: false,
          error: 'Не удалось загрузить список!'
        }
      };

    // form modal
    case privilegeStatusConstants.VEHICLE_FORM_MODAL_OPEN:
      return {
        ...state,
        showVehicleFormModal: true
      };
    case privilegeStatusConstants.VEHICLE_FORM_MODAL_CLOSE:
      return {
        ...state,
        showVehicleFormModal: false
      };
    case privilegeStatusConstants.VEHICLE_SET_CURRENT_ITEM:
      return {
        ...state,
        currentStatus: {
          ...state.currentStatus,
          currentItem: action.item
        }
      };

    case privilegeStatusConstants.FILE_UPLOAD_REQUEST:
      return {
        ...state,
        uploadFileError: false,
        fileUploaders: [
          ...state.fileUploaders,
          {
            uploaderId: action.uploaderId,
            name: action.name
          }
        ]
      };
    case privilegeStatusConstants.FILE_UPLOAD_FAILURE:
      return {
        ...state,
        uploadFileError: true,
        fileUploaders: state.fileUploaders.filter(item => item.uploaderId !== action.uploaderId)
      };

    case privilegeStatusConstants.FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        // currentItem: state.currentItem ? {
        //     ...state.currentItem,
        //     files: state.currentItem.files ? [
        //         ...state.currentItem.files,
        //         action.data
        //     ] : [action.data]
        // } : state.currentItem,
        fileUploaders: state.fileUploaders.filter(item => item.uploaderId !== action.uploaderId)
      };
    // case privilegeStatusConstants.FILE_REMOVE: {
    //     return {
    //         ...state,
    //         currentItem: {
    //             ...state.currentItem,
    //             files: state.currentItem.files.filter(item => item.id !== action.id)
    //         }
    //     }
    // }

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
