import { range, flattenDeep } from 'lodash';
import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'apvgk';
};

const getPage = (page, perPage = 10, auth = true, type = undefined) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/` + (auth ?'page':'public-page')+ `?page=${page}&per_page=${perPage || 10}` + (type ? '&type=1' : '') ,
    {},
    true
  );
};
const getControlPage = (page, perPage = 10) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/control/${getResourceName()}/page?page=${page}&per_page=${perPage || 10}`,
    {},
    true
  );
};

function prepareParams(filter) {
    let params = {...filter};
    params.min_date = params.min_date.replace('T', ' ')
    params.max_date = params.max_date && params.max_date.replace('T', ' ') || null
    params.violation = params.violation === true ? 1 : null;
    params.without_number = params.without_number === true ? 1 : null;
    params.more12 = params.more12 === true ? 1 : null;
    params.gos = params.gos === true ? 1 : null;
    return params;
}

const getTrackDataPage = (filter) => {
    let params = prepareParams(filter);
    return apiService.call(
        apiConstants.METHOD_GET,
        `/${getResourceName()}/trackData`,
        params,
        true
    );
};
const getStatisticPdf = async (filter) => {
    let params = {...filter};
    params.min_date = params.min_date.replace('T', ' ') +' 23:59'
    params.max_date = params.max_date.replace('T', ' ') + ' 23:59'
    params.date = (new Date()).getTime() + ''
    params.low = params.low === true ? 1 : 0;
    let response =  await apiService.call(
        apiConstants.METHOD_GET,
        `/${getResourceName()}/statistic`,
        params,
        true
    );
    if (!response || !response.file) {
        alert("Ошибка при формировании акта")
        return null;
    }
    return apiService.downloadFile(response.file, response.file.split('/').reverse()[0], true)
};
const getTrackPhotos = (id, data) => {
    return apiService.call(
        apiConstants.METHOD_GET,
        `/${getResourceName()}/trackData/${id}`,
        data || {},
        true
    );
};
const exportTrackData= (filter = {}) => {
    let params = prepareParams(filter);
    return apiService.call(
        apiConstants.METHOD_GET,
        `/${getResourceName()}/trackData/export`,
        params,
        true
    );
};
const PAGE_COUNT = 10000;

const getAll = async () => {
  const result = [];
  const response = await getPage(1, PAGE_COUNT);
  result.push(...response.data);
  const pagesCount = Math.ceil(response.meta.total / PAGE_COUNT);
  if (pagesCount === 1) {
    return result;
  }
  const responses = await Promise.all(
    range(2, pagesCount + 1).map(page => getPage(page, PAGE_COUNT).then(r => r.data))
  );
  result.push(...flattenDeep(responses));
  return result;
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/create`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};

export const apvgkService = {
  getPage,
  getControlPage,
  getAll,
  getItem,
  create,
  update,
  getTrackDataPage,
  getStatisticPdf,
  getTrackPhotos,
  exportTrackData,
  delete: _delete
};
