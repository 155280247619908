export const paymentsConstants = {

  GET_PAGE_REQUEST: 'PAYMENT_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'PAYMENT_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'PAYMENT_GET_PAGE_FAILURE',

  GETALL_REQUEST: 'PAYMENT_GETALL_REQUEST',
  GETALL_SUCCESS: 'PAYMENT_GETALL_SUCCESS',
  GETALL_FAILURE: 'PAYMENT_GETALL_FAILURE',
    
  GETSTAT_REQUEST: 'PAYMENT_GETSTAT_REQUEST',
  GETSTAT_SUCCESS: 'PAYMENT_GETSTAT_SUCCESS',
  GETSTAT_FAILURE: 'PAYMENT_GETSTAT_FAILURE',

  LOAD_REQUEST: 'PAYMENT_LOAD_REQUEST',
  LOAD_SUCCESS: 'PAYMENT_LOAD_SUCCESS',
  LOAD_FAILURE: 'PAYMENT_LOAD_FAILURE',

  CREATE_REQUEST: 'PAYMENT_CREATE_REQUEST',
  CREATE_SUCCESS: 'PAYMENT_CREATE_SUCCESS',
  CREATE_FAILURE: 'PAYMENT_CREATE_FAILURE',

  UPDATE_REQUEST: 'PAYMENT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PAYMENT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PAYMENT_UPDATE_FAILURE',

  DELETE_REQUEST: 'PAYMENT_DELETE_REQUEST',
  DELETE_SUCCESS: 'PAYMENT_DELETE_SUCCESS',
  DELETE_FAILURE: 'PAYMENT_DELETE_FAILURE',

  RESET_CURRENT_ITEM: 'PAYMENT_RESET_CURRENT_ITEM',
  SET_FILTER: 'PAYMENT_SET_FILTER',

  FORM_MODAL_OPEN: 'PAYMENT_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'PAYMENT_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'PAYMENT_SET_CURRENT_ITEM',

};
