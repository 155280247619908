import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';
import { authentication } from './authenticationReducer';
import { registration } from './registrationReducer';
import { restorePassword } from './restorePasswordReducer';
import { users } from './usersReducer';
import { alert } from './alertReducer';
import { payments } from './paymentsReducer';
import { code } from './codeReducer';
import { legalForms } from './legalFormReducer';
import { routeAddresses } from './routeAddressReducer';
import { regApps } from './regAppReducer';
import { cabinet } from './cabinetReducer';
import { cabinetVehicles } from './cabinetVehiclesReducer';
import { adminVehicles } from './adminVehiclesReducer';
import { vehicleBrands } from './vehicleBrandReducer';
import { vehicleModels } from './vehicleModelReducer';
import { departments } from './departmentReducer';
import { coefficients } from './coefficientReducer';
import { axleLoads } from './axleLoadReducer';
import { privilegeStatuses } from './privilegeStatusReducer';
import { privilegeVehicles } from './privilegeVehicleReducer';
import { specialConditions } from './specialConditionReducer';
import { userApplications } from './userApplicationsReducer';
import { adminApplications } from './adminApplicationsReducer';
import { controlPosts } from './controlPostReducer';
import { adminFirmVehicles } from './adminFirmVehiclesReducer';
import { applicationReducer } from './newApplicationReducer';
import  {apvgkReports} from './apvgkReportsReducer'

const rootReducer = combineReducers({
  authentication,
  registration,
  restorePassword,

  users,
  regApps,
  cabinet,

  cabinetVehicles,
  adminVehicles,

  // libraries
  form: formReducer,
  alert,
  code,

  // dictionaries
  legalForms,
  routeAddresses,
  vehicleBrands,
  vehicleModels,
  controlPosts,
  departments,
  coefficients,
  axleLoads,
  privilegeStatuses,
  privilegeVehicles,
  specialConditions,
  userApplications,
  adminApplications,
  adminFirmVehicles,
  payments,
  apvgkReports,
  application: applicationReducer
});

export default rootReducer;
