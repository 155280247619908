import React, { useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useAsync } from 'react-async';
import { TableHead, TableRow, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell/TableCell';

import { Loader, TablePagination } from '../../../../components';
import { StateContext } from '../components/StateContext';
import { polishFilters } from '../../../../constants/cooperationConstants';
import smevService from '../../../../services/smevService';
import { useDidUpdateEffect } from '../util/useWithQuery';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  loaderWrapper: {
    minHeight: 300
  }
});

const ContentContainer = ({
  user,
  classes,
  renderPreHeader,
  renderHeader,
  renderBody,
  status,
  col
}) => {
  const { filters, meta, setMeta, setSelected } = useContext(StateContext);

  const { data: res, run, isLoading, isSettled, isRejected, isResolved } = useAsync({
    deferFn: ([params]) =>
      smevService.getApplications({
        ...polishFilters(filters, status),
        ...params
      })
  });
  const refresh = () => run(meta);

  useDidUpdateEffect(() => {
    setMeta({ ...meta, page: 0 });
  }, [filters]);

  useEffect(() => {
    run(meta);
  }, [meta]);

  return (
    <div>
      {isLoading && (
        <Grid className={classes.loaderWrapper} container alignItems="center" justify="center">
          <Loader />
        </Grid>
      )}
      {isSettled && (
        <Paper className={classes.root}>
          {renderPreHeader && renderPreHeader({ refresh })}
          <Table size="small" className={classes.table}>
            <TableHead>{renderHeader({ items: res.data })}</TableHead>
            <TableBody>
              {isRejected && (
                <TableRow>
                  <TableCell align="center" colSpan={col}>
                    <span className="text-danger">Ошибка: Не удалось загрузить данные</span>
                  </TableCell>
                </TableRow>
              )}
              {isResolved && isEmpty(res.data) && (
                <TableRow>
                  <TableCell align="center" colSpan={col}>
                    Заявки не найдены
                  </TableCell>
                </TableRow>
              )}
              {isResolved && !isEmpty(res.data) && renderBody({ items: res.data, user, refresh })}
            </TableBody>
          </Table>
        </Paper>
      )}
      {isResolved && (
        <TablePagination
          options={[20, 50, 100]}
          meta={res.meta}
          onChangePage={page => {
            setMeta({ ...meta, page });
            setSelected([]);
          }}
          onChangeRowsPerPage={perPage => {
            setMeta({ ...meta, page: 0, per_page: perPage });
            setSelected([]);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.authentication.user
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(ContentContainer);
