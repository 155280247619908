import React from 'react';

const WITH_DECIMAL = /[^0-9\\.]/g;
const WITHOUT_DECIMAL = /[^0-9]/g;

const MaterialInput = ({
  field, // { name, value, onChange, onBlur }
  afterChange = () => null,
  form,
  Component = 'input',
  ...props
}) => (
  <Component
    className="form-control"
    {...field}
    {...props}
    onChange={e => {
      if (props.fieldType === 'number') {
        const rg = props.disableDecimal ? WITHOUT_DECIMAL : WITH_DECIMAL;
        e.target.value = e.target.value.replace(',', '.').replace(rg, '');
      }
      if (props.maxLength && e.target.value.toString().length > props.maxLength) {
        e.target.value = e.target.value.substring(0, props.maxLength);
      }
      field.onChange(e);
      afterChange(e.target.value);
    }}
  />
);

export default MaterialInput;
