import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field, Form, Formik } from 'formik';
import MaterialInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput';
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialSelect from '../../admin/quickApplications/CreateQuickApplication/components/MaterialSelect';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import {apvgkService} from "../../../services/apvgkService";
import MaterialCheckbox from "../../admin/quickApplications/CreateQuickApplication/components/MaterialCheckbox";


const Filter = ({ currentFilters, onFiltersChange, reasons}) => {
    const [open, setOpen] = useState(false);
    const [apvgks, setApvgks] = useState([]);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const onCancel = () => {
        onFiltersChange({});
        handleClose()
    };
    const fetchPage = async (page) =>  {
        const response = await apvgkService.getPage(page, 1000);
        setApvgks(response.data);
    }
    useEffect(() => {
        fetchPage(1);
    }, [])
    return (
        <div>
            <Link

                color="primary"
                to={{}}
                onClick={handleClickOpen}
            >
                Фильтровать
            </Link>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Фильтровать
                    </Typography>
                    <IconButton color="inherit" onClick={handleClose}>
                        <CloseIcon className="blue" />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <Formik
                        initialValues={currentFilters}
                        onSubmit={values => {
                            onFiltersChange(values);
                            localStorage.setItem('report_type', values.type ? values.type : 0);
                            handleClose();
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        render={({ values, setFieldValue }) => (
                            <Form>
                                <Grid container direction="column" spacing={16} wrap="nowrap">
                                    <Grid item>
                                        <FieldLabel label="Дата время с" name="min_date">
                                            <Field
                                                name="min_date"
                                                component={MaterialInput}
                                                type="datetime-local"
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Дата время по" name="max_date">
                                            <Field
                                                name="max_date"
                                                component={MaterialInput}
                                                type="datetime-local"
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item className={"text-left"}>
                                        <FieldLabel label="Причина отклонения*" name="reject_reason">
                                            <Field
                                                name="reject_reason"
                                                component={MaterialSelect}
                                                options={reasons ?reasons.map((title, index) => ({
                                                    value: index,
                                                    label: title
                                                })) : []}
                                                placeholder="Выберите причину отклонения"
                                            >
                                            </Field>
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="id акта" name="id">
                                            <Field
                                                name="id"
                                                type="text"
                                                component={MaterialInput}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="id взвешивания" name="IDBetamount">
                                            <Field
                                                name="IDBetamount"
                                                type="text"
                                                component={MaterialInput}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Наименование АПВГК" name="apvgk_id">
                                            <Field
                                                name="apvgk_id"
                                                placeholder="Выберите АПВГК"
                                                component={MaterialSelect}
                                                options={apvgks.map((v) => {return {value:v.id, label:v.name};})}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Направление АПВГК" name="PlatformId">
                                            <Field
                                                name="PlatformId"
                                                type="text"
                                                component={MaterialInput}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Максимальная скорость" name="max_speed">
                                            <Field
                                                name="max_speed"
                                                type="text"
                                                component={MaterialInput}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="ГРЗ ТС" name="TrackStateNumber">
                                            <Field
                                                name="TrackStateNumber"
                                                type="text"
                                                component={MaterialInput}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Сотрудник" name="user">
                                            <Field
                                                name="user"
                                                type="text"
                                                component={MaterialInput}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Ошибка в Цафап" name="cafap_error">
                                            <Field
                                                name="cafap_error"
                                                onChange={(e) => {
                                                    setFieldValue(e.target.name, e.target.checked)
                                                }}
                                                component={MaterialCheckbox}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Тип нарушений" name="type">
                                            <Field
                                                name="type"
                                                onChange={(e) => {
                                                    setFieldValue("type", e.value)
                                                }}
                                                component={MaterialSelect}
                                                options={[{value:0, label:'Осевые нагрузки'},{value:1, label:'Нет грз'},{value:2, label:'Габариты'},{value:3, label:'Распознанные грз'},]}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="btn-add no-margin"
                                            type="submit"
                                        >
                                            Применить
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            component="span"
                                            className="info-btn"
                                            onClick={onCancel}
                                        >
                                            Очистить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Filter;
