import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Toolbar,
  Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, Formik } from 'formik';
import { get } from 'lodash';
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput';
import { userService } from '../../../services/userService';

const UserModal = ({
  onAdd,
  onUpdate,
  enqueueSnackbar,
  initial = { name: '', email: '' },
  children
}) => {
  const [show, setShow] = useState(false);
  const open = () => setShow(true);
  const close = () => setShow(false);
  const isNew = !initial.id;
  const validate = ({ email, name }) => {
    const errors = {};

    if (!email) {
      errors.email = 'Обязательное поле';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errors.email = 'Некорректный E-mail';
    }

    if (!name) {
      errors.name = 'Обязательное поле';
    }

    return errors;
  };
  return (
    <>
      {children(open)}
      <Formik
        initialValues={initial}
        validate={validate}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            if (isNew) {
              const user = await userService.createFirmUser(values);
              onAdd(user);
              resetForm({ values: initial });
            } else {
              const user = await userService.updateFirmUser(initial.id, values);
              onUpdate(user);
            }
            setSubmitting(false);
            close();
          } catch (e) {
            const error = get(e, 'errors.email');
            if (error && error.length && error.length > 0) {
              enqueueSnackbar(error[0], { variant: 'error' });
            } else {
              enqueueSnackbar('Произошла ошибка, попробуйте позже!', { variant: 'error' });
            }
            setSubmitting(false);
          }
        }}
        render={({ submitForm, isSubmitting, isValid }) => (
          <Dialog open={show} onClose={close} aria-labelledby="form-dialog-title">
            <Toolbar className="text-left">
              <Typography variant="title" color="inherit" className="flex">
                {isNew ? 'Редактирование' : 'Добавление'} сотрудника
              </Typography>
              <IconButton color="inherit" onClick={close}>
                <CloseIcon className="blue" />
              </IconButton>
            </Toolbar>

            <DialogContent className="dialog">
              <Grid container direction="column" spacing={16}>
                <Grid item>
                  <FieldLabel label="Наименование" name="name">
                    <Field name="name" component={MaterialInput} placeholder="Наименование" />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="E-mail" name="email">
                    <Field name="email" component={MaterialInput} placeholder="E-mail" />
                  </FieldLabel>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={submitForm}
                color="primary"
                variant="contained"
                disabled={isSubmitting || !isValid}
                className="btn-add"
              >
                Сохранить
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    </>
  );
};

export default UserModal;
