import React from 'react';

const InstructionsPage = props => {
  return (
    <main role="main" className="container text-white text-left">
      <div className="kyc">
        <h1 className="welcome">Инструкции</h1>

        <div className="row" />
      </div>
    </main>
  );
};

export default InstructionsPage;
