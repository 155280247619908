import React from 'react';
import { get } from 'lodash';
import Select from 'react-select';
import StartPointIcon from '@material-ui/icons/FiberManualRecord';
import PointIcon from '@material-ui/icons/Adjust';
import EndPointIcon from '@material-ui/icons/Room';
import { Grid } from '@material-ui/core';
import Input from '../../components/Input';

const SinglePathReadOnly = ({ values, name, index, options }) => {
  const { steps, useSaved, saved, id } = get(values, name);
  const routeOptions = React.useMemo(
    () =>
      options.map(option => ({
        value: option,
        label: option.text
      })),
    [options]
  );

  return (
    <Grid container direction="column" spacing={8} wrap="nowrap">
      <Grid item>
        <Grid container spacing={16}>
          <Grid item>
            <h4 className="h4-title">Отрезок {index + 1}</h4>
          </Grid>
        </Grid>
      </Grid>
      {useSaved && (
        <Grid item style={{ zIndex: 1010 - index }}>
          <Select
            className="react-select-container_multiline"
            classNamePrefix="react-select_multiline"
            name="tripType"
            placeholder="Маршрут"
            value={routeOptions.find(option => id && option.value.id === id)}
            options={routeOptions}
            isDisabled
          />
        </Grid>
      )}
      {!useSaved && (
        <>
          <Grid item>
            <Grid container direction="column" spacing={8} className="points_area">
              <div className="points_div points_line-map">
                <div className="points_line" />
              </div>
              {steps.map((step, ind) => (
                <React.Fragment>
                  <Grid item>
                    <Grid container spacing={8} alignItems="center" justify="space-between">
                      <Grid
                        item
                        className="map-point"
                        container
                        alignItems="center"
                        justify="flex-start"
                        sm={1}
                      >
                        {ind === 0 && <StartPointIcon />}
                        {ind > 0 && ind < steps.length - 1 && <PointIcon />}
                        {ind === steps.length - 1 && <EndPointIcon className="red" />}
                      </Grid>
                      <Grid item sm={10}>
                        <Input value={steps[ind].text} />
                      </Grid>
                      <Grid item sm={1} />
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SinglePathReadOnly;
