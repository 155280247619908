import React from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { authActions } from '../../../actions/authActions';
import LoginForm from './LoginForm';
import { RestoreModal } from './RestoreModal';
import { NewPasswordModal } from './NewPasswordModal';

const loginSubmit = (values, dispatch) => {
  return dispatch(authActions.login(values));
};

const LoginPage = props => {
  const { authentication, handleRestoreOpen, handleRegisterStep1Open } = props;

  return (
    <main role="main" className="container text-white text-left">
      <div className="kyc">
        <h1 className="welcome">
          Добро пожаловать в <br />
          личный кабинет <br />
          АИС КТГ{' '}
        </h1>

        <div className="row">
          {(!authentication.user || !authentication.user.id) && (
            <div className="col-md-6">
              <LoginForm onSubmit={loginSubmit} />

              <div className="forgot-links row">
                <div className="col-6">
                  {/* <Link className="text-white" to='register'>Зарегистрироваться</Link> */}
                  <span onClick={handleRegisterStep1Open}>Зарегистрироваться</span>
                  <br />
                </div>
                <div className="col-md-6 text-right">
                  <span onClick={handleRestoreOpen}>Забыли пароль?</span>
                  <br />
                </div>
              </div>

              <RestoreModal />

              <NewPasswordModal />
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  const { authentication } = state;
  return {
    authentication
  };
};

const mapDispatchToProps = (dispatch, props) => {
  // dispatch(authActions.logout());
  dispatch(authActions.closeRegisterStep1Modal());
  dispatch(authActions.closeRegisterStep2Modal());

  // check if we need to show new password form
  const params = qs.parse(props.location.search.slice(1));
  if (params.token) {
    dispatch(authActions.toggleNewModal(params.token));
  }

  // check if we need to confirm email
  if (params.confirmation_code) {
    dispatch(authActions.confirmEmail(params.confirmation_code));
  }

  return {
    togglePasswordVisible: () => {
      dispatch(authActions.togglePasswordVisible());
    },

    // Restore password
    handleRestoreOpen: () => {
      dispatch(authActions.openRestoreModal());
    },

    // Register
    handleRegisterStep1Open: () => {
      dispatch(authActions.openRegisterStep1Modal());
    }
  };
};

const connectedLoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
export { connectedLoginPage as LoginPage };
