import React from 'react';
import { Field } from 'formik';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import MaterialCheckbox from './components/MaterialCheckbox';
import Section from './components/Section';
import FieldLabel from './components/FieldLabel';
import SelectStatus from './components/SelectStatus';
import MaterialInput from './components/MaterialInput';
import RouteMap from './RouteMap';
import { privilegeVehicleService } from '../../../../services/privilegeVehicleService';
import Truncate from '../../../../components/Truncate';

const RouteInfo = ({ values, setFieldValue, setFieldTouched }) => (
  <Section title="Маршрут">
    <FieldLabel
      label={
        <Field name="route.useStatus" component={MaterialCheckbox} label="Использовать статус"
               onChange={async status => {
                   setFieldValue('tripCount', undefined);
                   setFieldValue('route.useStatus', status.target.checked)
               }}/>
      }
    />
    {values.route.useStatus ? (
      <React.Fragment>
        <Grid item>
          <FieldLabel label="Название статуса*" name="vehicleCorrect">
            <Field
              restrictionDates={values.restrictionDates}
              name="route.status"
              component={SelectStatus}
              afterSelect={async status => {
                const startDate = moment(status.start_date, 'DD.MM.YYYY').format('YYYY-MM-DD');
                const finishDate = moment(status.finish_date, 'DD.MM.YYYY').format('YYYY-MM-DD');
                setFieldValue('route.start_date', startDate);
                setFieldValue('route.finish_date', finishDate);
                setFieldValue('route.route_info', status.route_info);
                setFieldValue('route.statusEntity', status);
                privilegeVehicleService.getVehicles(status.id, 1, 1000).then(res => {
                  setFieldValue('route.status_vehicles', res.data);
                  setFieldTouched('route.status_vehicles', true, true);
                  setFieldTouched('vehicleCorrect', true, true);
                });
              }}
            />
          </FieldLabel>
        </Grid>
        <Grid item>
          <FieldLabel label="Период действия разрешения">
            <Grid container spacing={8} alignItems="center">
              <Grid item xs={1} className="text-center">
                <span>с</span>
              </Grid>
              <Grid item xs={5}>
                <Field name="route.start_date" component={MaterialInput} type="date" disabled />
              </Grid>
              <Grid item xs={1} className="text-center">
                <span>по</span>
              </Grid>
              <Grid item xs={5}>
                <Field name="route.finish_date" component={MaterialInput} type="date" disabled />
              </Grid>
            </Grid>
          </FieldLabel>
        </Grid>
        <Grid item>
          <FieldLabel label="Маршрут">
            <div className="privilege_status">
              <Truncate maxCount={100}>{get(values, 'route.route_info', '')}</Truncate>
            </div>
          </FieldLabel>
        </Grid>
      </React.Fragment>
    ) : (
      <RouteMap values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
    )}
  </Section>
);

export default RouteInfo;
