export const specialConditionConstants = {
  LOAD_REQUEST: 'SPECIAL_CONDITION_LOAD_REQUEST',
  LOAD_SUCCESS: 'SPECIAL_CONDITION_LOAD_SUCCESS',
  LOAD_FAILURE: 'SPECIAL_CONDITION_LOAD_FAILURE',

  SET_REQUEST: 'SPECIAL_CONDITION_SET_REQUEST',
  SET_SUCCESS: 'SPECIAL_CONDITION_SET_SUCCESS',
  SET_FAILURE: 'SPECIAL_CONDITION_SET_FAILURE',

  SET_TAB_INDEX: 'SET_TAB_INDEX',

  TAB_DEFAULT: 0,
  TAB_SPRING: 1
};
