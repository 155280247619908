import { get } from 'lodash'
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormField } from '../../../../components/form/FormField';
import { fieldValidators } from '../../../../helpers/fieldValidators';
import { Loader } from '../../../../components/Loader';
import { fieldNormalizers } from '../../../../helpers/fieldNormalizers';

let AxleLoadForm = props => {
  const { error, handleSubmit, submitting, currentItem } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="title"
        type="text"
        label="Название"
        validate={[fieldValidators.required]}
        component={FormField}
        disabled="disabled"
      />
      <Field
        name="value0"
        type="text"
        label="10 т/ось"
        validate={[fieldValidators.required]}
        component={FormField}
        normalize={fieldNormalizers.number}
      />
      <Field
        name="value1"
        type="text"
        label="11,5 т/ось"
        validate={[fieldValidators.required]}
        component={FormField}
        normalize={fieldNormalizers.number}
      />
      <Field
        name="value2"
        type="text"
        label="6 т/ось"
        validate={[fieldValidators.required]}
        component={FormField}
        normalize={fieldNormalizers.number}
      />

      {error && <strong>{error}</strong>}
      <div>
        {submitting && <Loader />}
        <button type="submit" className="btn" disabled={submitting}>
          {currentItem ? 'Редактировать' : 'Создать'}
        </button>
      </div>
    </form>
  );
};

AxleLoadForm = reduxForm({
  form: 'axle-load-form', // a unique identifier for this form
  enableReinitialize: true
})(AxleLoadForm);

AxleLoadForm = connect(state => ({
  initialValues: {
    id: state.axleLoads.currentItem ? state.axleLoads.currentItem.id : '',
    title: state.axleLoads.currentItem ? state.axleLoads.currentItem.title : '',
    short_title: state.axleLoads.currentItem ? state.axleLoads.currentItem.short_title : '',
    value0: get(state, 'axleLoads.currentItem.value0', ''),
    value1: get(state, 'axleLoads.currentItem.value1', ''),
    value2: get(state, 'axleLoads.currentItem.value2', '')
  }
}))(AxleLoadForm);

export default AxleLoadForm;
