import React from 'react';
import { connect } from 'react-redux';
import { applicationActions } from '../../../../../actions/applicationActions';
import { ApplicationStepLoadForm } from './ApplicationStepLoadForm';
import { urlHelper } from '../../../../../helpers/urlHelper';
import { Loader } from '../../../../../components';
import { axleLoadActions } from '../../../../../actions/axleLoadActions';

const vehicleSubmit = (values, dispatch) => {
  return dispatch(applicationActions.saveLoadStep(values));
};

const ApplicationStepLoad = props => {
  const { match, axleLoads, loadAxleLoads, axlesIsLoading } = props;

  const id = urlHelper.getIdFromApplicationStepUrl(match.path);
  React.useEffect(() => {
    if (!axleLoads.length && !axlesIsLoading) {
      loadAxleLoads();
    }
  });
  if (!axleLoads.length) {
    return <Loader />;
  }
  return (
    <main role="main" className="container">
      <ApplicationStepLoadForm id={id} axleLoads={axleLoads} onSubmit={vehicleSubmit} />
    </main>
  );
};

const mapStateToProps = state => ({
  axleLoads: state.axleLoads.items,
  axlesIsLoading: state.axleLoads.isLoading
});

const mapDispatchToProps = {
  loadAxleLoads: axleLoadActions.getAll
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationStepLoad);
