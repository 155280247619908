import React, { useState } from 'react';
import Select from './Select';
import { controlPostService } from '../../../../../services/controlPostService';

const SelectControlPosts = ({ ...props }) => {
  const [fetched, setFetched] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const fetch = () => {
    setLoading(true);
    controlPostService.getAll().then(res => {
      setData(res.data);
      setLoading(false);
      setFetched(true);
    });
  };
  return (
    <Select
      {...props}
      placeholder="Выберите ПВК"
      fetched={fetched}
      fetch={fetch}
      isLoading={isLoading}
      data={data}
    />
  );
};

export default SelectControlPosts;
