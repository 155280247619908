import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import { Loader, TablePagination } from '../../../components/index';
import {apvgkReportsActions} from "../../../actions/apvgkReportsActions";
import {history} from "../../../helpers/history";
import {urlHelper} from "../../../helpers/urlHelper";
import {roleConstants} from "../../../constants/roleConstants";
import {apvgkReports} from "../../../reducers/apvgkReportsReducer";

const styles = () => ({
    root: {
        width: '100%',
        overflowX: 'auto'
    },
    table: {
        minWidth: 700
    }
});

const TabContainer = props => {
    const {
        apvgkReports,
        handlePageChange,
        handlePerPageChange,
        children,
        classes,
        authentication,
        paginateProps,
    } = props;
    const { items, meta } = apvgkReports;
    const { user } = authentication;
    return (
        <div>
            {apvgkReports.loading && <Loader />}
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    {items && !apvgkReports.loading && children(items, props.handleShowItem)}
                </Table>
            </Paper>
            {!apvgkReports.loading && apvgkReports.items && apvgkReports.items.length === 0 && (
                <p>Акты не найдены</p>
            )}
            {items && !apvgkReports.loading && (
                <Table >
                <TablePagination
                    meta={meta}
                    onChangePage={paginateProps.handlePageChange}
                    onChangeRowsPerPage={paginateProps.handlePerPageChange}
                />
                </Table>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    const { apvgkReports, authentication } = state;

    const selector = formValueSelector('apvgk-reports-towork-form');
    const filterData = selector(
        state,
        'id',
        'IDBetamount',
        'apvgk_id',
        'PlatformId',
        'user',
        'pre_page',
    );

    return {
        apvgkReports,
        authentication,
        filterData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { apvgkReports, authentication } = stateProps;
    const { dispatch } = dispatchProps;

    const getReports = ({ page, perPage } = {}) => {
        const values = {
            status: apvgkReports.tabStatus,
            page: page || apvgkReports.meta.current_page,
            per_page: perPage || apvgkReports.meta.per_page,
            ...apvgkReports.filter
        };

        return dispatch(apvgkReportsActions.getApvgkReports(values));
    };

    const handlePageChange = page => getReports({ page });

    const handlePerPageChange = perPage => getReports({ page: 1, perPage });

    const handleShowItem = (item) => {
        dispatch(apvgkReportsActions.setCurrentItem(item))
        history.push(
            `${urlHelper.getHomeUrl(roleConstants.ROLE_APVGK)}/apvgk/` + item.id
        );
    }



    return {
        ...ownProps,
        apvgkReports,
        authentication,

        handlePageChange,
        handlePerPageChange,
        handleShowItem,
    };
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )
)(TabContainer);
