import React from 'react';
import { connect } from 'react-redux';
import { cabinetActions } from '../../../actions/cabinetActions';
import { ChangePasswordForm } from '../../cabinet/main/ChangePasswordForm';
import { UserMainInfoForm } from './UserMainIfoForm';

let AdminMainPage = props => {
  const { cabinetSubmit, changePasswordSubmit } = props;

  return (
    <main role="main" className="container">
      <div className="kyc">
        <h2 className="h2-title">Личный кабинет</h2>

        <div className="row">
          <div className="col-md-8">
            <UserMainInfoForm onSubmit={cabinetSubmit} />
            <ChangePasswordForm onSubmit={changePasswordSubmit} />
          </div>
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  dispatch(cabinetActions.resetStatuses());

  const cabinetSubmit = (values, dispatch) => {
    return dispatch(cabinetActions.saveInfo(values));
  };

  const changePasswordSubmit = (values, dispatch) => {
    return dispatch(cabinetActions.changePassword(values));
  };

  return {
    cabinetSubmit,
    changePasswordSubmit
  };
};

AdminMainPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMainPage);
export default AdminMainPage;
