import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { TableRow } from 'material-ui';
import TableCell from '@material-ui/core/TableCell';
import Filter from '@material-ui/icons/FilterList';
import { Loader } from '../../../components/Loader';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { roleConstants } from '../../../constants/roleConstants';

let RegAppsFilterForm = props => {
  const { regApps, handleSubmit, submitting } = props;

  return (
    <TableRow>
      <TableCell>
        <Filter />
      </TableCell>
      <TableCell>
        <Field
          name="name"
          type="text"
          label="Фио заявителя / Название компании"
          className=" form_input"
          component={FormFieldReg}
        />
      </TableCell>

      <TableCell>
        <Field
          name="email"
          type="text"
          label="Email"
          className=" form_input"
          component={FormFieldReg}
        />
      </TableCell>

      <TableCell>
        <Field
          name="phone"
          type="text"
          label="Телефон"
          className=" form_input"
          component={FormFieldReg}
        />
      </TableCell>

      <TableCell>
        <Field
          name="inn"
          type="text"
          label="Инн заявителя"
          className=" form_input"
          component={FormFieldReg}
        />
      </TableCell>

        {regApps.roleIndex === roleConstants.ROLE_FIRM && (
            <TableCell>

                <Field
                    name="kpp"
                    type="text"
                    label="Кпп заявителя"
                    className=" form_input"
                    component={FormFieldReg}
                />
            </TableCell>
        )}
      {regApps.roleIndex === roleConstants.ROLE_FIRM && (
        <TableCell>
          <Field
            name="contact_name"
            type="text"
            label="Имя контактного лица"
            className=" form_input"
            component={FormFieldReg}
          />
        </TableCell>
      )}

      <TableCell>
        <Field
          name="date"
          type="date"
          label="Дата подачи заявки"
          className=" form_input"
          component={FormFieldReg}
        />
      </TableCell>

      <TableCell numeric>
        <Button
          variant="contained"
          color="primary"
          className="btn-add no-margin"
          type="submit"
          disabled={submitting}
        >
          {submitting && <Loader />} Применить
        </Button>
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = state => {
  const { authentication, regApps } = state;
  const { user } = authentication;
  return {
    regApps
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

RegAppsFilterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegAppsFilterForm);
export default RegAppsFilterForm;
