import React from 'react';
import { apiConstants } from '../../../constants/apiConstants';

const VersionPage = () => (
  <main role="main" className="container text-white text-left">
    <div className="kyc">
      <h1 className="welcome">Версия v{apiConstants.APP_VERSION}</h1>
      <div className="row" />
    </div>
  </main>
);

export default VersionPage;
