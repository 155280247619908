import React from 'react';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import * as selectors from '../../../selectors/applicationSelectors';

function check(allowedArray, disallowedArray, allowed, value) {
  let isValid = true;
  if (allowedArray.length > 0) {
    isValid = isValid && allowedArray.includes(value);
  }
  if (!isNil(allowed)) {
    isValid = isValid && allowed === value;
  }
  if (disallowedArray.length > 0) {
    isValid = isValid && !disallowedArray.includes(value);
  }
  return isValid;
}

const ShowByStatus = ({
  status,
  disallowedStatuses = [],
  disallowedRoles = [],
  allowedStatuses = [],
  allowedStatus = null,
  allowedRoles = [],
  allowedRole = null,
  additionalCondition = true,
  role,
  children
}) => {
  let isValid = check(allowedStatuses, disallowedStatuses, allowedStatus, status);
  isValid = isValid && check(allowedRoles, disallowedRoles, allowedRole, role);
  if (!isValid || !additionalCondition) {
    return null;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const mapStateToProps = state => ({
  status: selectors.getApplicationStatus(state),
  role: state.authentication.user.role_id
});

export default connect(mapStateToProps)(ShowByStatus);
