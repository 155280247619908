import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authActions } from '../../../../actions/authActions';

const GuestMenu = props => {
  const { handleRegisterStep1Open } = props;

  return (
    <ul className="navbar-nav mr-auto">
      <li>
        <a className="nav-link" onClick={handleRegisterStep1Open}>
          Регистрация
        </a>
      </li>
      <li key="login">
        <Link className="nav-link" to="/">
          Войти
        </Link>
      </li>
    </ul>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    // Register
    handleRegisterStep1Open: () => {
      dispatch(authActions.openRegisterStep1Modal());
    }
  };
};

const connectedGuestMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestMenu);
export { connectedGuestMenu as GuestMenu };
