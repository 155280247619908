import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';
import Routing from './Routing';
import RouteLines from '../../../components/RouteLines';
import DrawMarkers from '../../../components/map/DrawMarkers';
import RestrictedArea from '../../../components/map/RestrictedArea';
import {
  getApplicationData,
  getApplicationEditingMap,
  getApplicationMarkers,
  isApplicationRouteEditable
} from '../../../selectors/applicationSelectors';
import ApvgksLayer from '../../../components/map/ApvksLayer';

const stamenTonerTiles = 'https://tile.aisktg.ru/tatarstan/{z}/{x}/{y}.png';
const stamenTonerAttr = '';
const mapCenter = [55.211, 50.634];
const zoomLevel = 7;

class MapContainer extends Component {
  render() {
    const { markers, editable, editMap, application } = this.props;
    return (
      <div>
        <Map center={mapCenter} zoom={zoomLevel} ref={map => (this.map = map)}>
          <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
          {editable && editMap ? (
            <Routing
              map={this.map}
              markers={markers}
              editable={editable}
              application={application}
            />
          ) : (
            <React.Fragment>
              <RouteLines steps={markers} center={mapCenter} />
              <DrawMarkers markers={markers} />
            </React.Fragment>
          )}
          <RestrictedArea />
          <ApvgksLayer />
        </Map>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  markers: getApplicationMarkers(state),
  application: getApplicationData(state),
  editMap: getApplicationEditingMap(state),
  editable: isApplicationRouteEditable(state)
});

export default connect(mapStateToProps)(MapContainer);
