import React from 'react';
import ApplicationSectionTitle from './ApplicationSectionTitle';
import ApplicationField from './ApplicationField';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';

const IssuePlace = ({ application }) => (
  <React.Fragment>
    <ApplicationSectionTitle>Пункт выдачи</ApplicationSectionTitle>
    <ApplicationField
      label="Территориальное управление ГБУ БДД"
      value={dictionaryHelper.issuePlaces.getItemTitle(application.dates.issue_place_id)}
    />
  </React.Fragment>
);

export default IssuePlace;
