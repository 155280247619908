import React from 'react';

import { withSnackbar } from 'notistack';
import { applicationActions } from '../../../../../actions/applicationActions';
import { ApplicationStepRouteForm } from './ApplicationStepRouteForm';
import { urlHelper } from '../../../../../helpers/urlHelper';

const vehicleSubmit = enqueueSnackbar => (values, dispatch) => {
  if (!values.privilege_status_id && !values.markers.every(marker => marker.coords)) {
    enqueueSnackbar('Заполните все поля маршрута!', { variant: 'error' });
    return null;
  }
  return dispatch(applicationActions.saveRouteStep(values));
};

const ApplicationStepRoute = props => {
  const { match } = props;

  const id = urlHelper.getIdFromApplicationStepUrl(match.path);

  return (
    <main role="main" className="container">
      <ApplicationStepRouteForm id={id} onSubmit={vehicleSubmit(props.enqueueSnackbar)} />
    </main>
  );
};

export default withSnackbar(ApplicationStepRoute);
