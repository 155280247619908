import { departmentConstants } from '../constants/departmentConstants';
import { authConstants } from '../constants/authConstants';

const createItem = (items, item) => {
  items.unshift(item);
  return items;
};
const updateItem = (items, item) => {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== item.id) {
      result.push(items[i]);
    } else {
      result.push(item);
    }
  }
  return result;
};
const initialState = {
  showDeleteError: false,
  showFormModal: false,
  currentItem: {},
  meta: { per_page: 7, total: 0, current_page: 1 },
  items: []
};

export const departments = (state = initialState, action) => {
  switch (action.type) {
    // Get page
    case departmentConstants.GET_PAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case departmentConstants.GET_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.departments.data,
        meta: action.departments.meta
      };
    case departmentConstants.GET_PAGE_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Get all
    case departmentConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case departmentConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.departments.data
      };
    case departmentConstants.GETALL_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Create
    case departmentConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: createItem(state.items, action.department),
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        },
        showFormModal: false
      };

    // Update
    case departmentConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: updateItem(state.items, action.department),
        showFormModal: false
      };

    // Delete
    case departmentConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(department =>
          department.id === action.id ? { ...department, deleting: true } : department
        )
      };
    case departmentConstants.DELETE_SUCCESS:
      // remove deleted HeaderMenu from state
      return {
        ...state,
        loading: false,
        items: state.items.filter(department => department.id !== action.id),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    case departmentConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        showDeleteError: true,
        items: state.items.map(department => {
          if (department.id === action.id) {
            // make copy of HeaderMenu without 'deleting:true' property
            const { deleting, ...departmentCopy } = department;
            // return copy of HeaderMenu with 'deleteError:[error]' property
            return { ...departmentCopy, deleteError: action.error };
          }

          return department;
        })
      };

    // Get item
    case departmentConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case departmentConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.department.data
      };
    case departmentConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // form modal
    case departmentConstants.FORM_MODAL_OPEN:
      return {
        ...state,
        showFormModal: true
      };
    case departmentConstants.FORM_MODAL_CLOSE:
      return {
        ...state,
        showFormModal: false
      };
    case departmentConstants.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item
      };
    case departmentConstants.CLOSE_ERROR_MODAL:
      return {
        ...state,
        showDeleteError: false
      };
    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
