export const privilegeStatusConstants = {
  GET_PAGE_REQUEST: 'PRIVILEGE_STATUS_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'PRIVILEGE_STATUS_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'PRIVILEGE_STATUS_GET_PAGE_FAILURE',

  GET_ALL_REQUEST: 'PRIVILEGE_STATUS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'PRIVILEGE_STATUS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'PRIVILEGE_STATUS_GET_ALL_FAILURE',

  LOAD_REQUEST: 'PRIVILEGE_STATUS_LOAD_REQUEST',
  LOAD_SUCCESS: 'PRIVILEGE_STATUS_LOAD_SUCCESS',
  LOAD_FAILURE: 'PRIVILEGE_STATUS_LOAD_FAILURE',

  CREATE_REQUEST: 'PRIVILEGE_STATUS_CREATE_REQUEST',
  CREATE_SUCCESS: 'PRIVILEGE_STATUS_CREATE_SUCCESS',
  CREATE_FAILURE: 'PRIVILEGE_STATUS_CREATE_FAILURE',

  UPDATE_REQUEST: 'PRIVILEGE_STATUS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PRIVILEGE_STATUS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PRIVILEGE_STATUS_UPDATE_FAILURE',

  DELETE_REQUEST: 'PRIVILEGE_STATUS_DELETE_REQUEST',
  DELETE_SUCCESS: 'PRIVILEGE_STATUS_DELETE_SUCCESS',
  DELETE_FAILURE: 'PRIVILEGE_STATUS_DELETE_FAILURE',

  FORM_MODAL_OPEN: 'PRIVILEGE_STATUS_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'PRIVILEGE_STATUS_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'PRIVILEGE_STATUS_SET_CURRENT_ITEM',

  FILE_UPLOAD_REQUEST: 'PRIVILEGE_STATUS_FILE_UPLOAD_REQUEST',
  FILE_UPLOAD_SUCCESS: 'PRIVILEGE_STATUS_FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_FAILURE: 'PRIVILEGE_STATUS_FILE_UPLOAD_FAILURE',
  FILE_REMOVE: 'PRIVILEGE_STATUS_FILE_REMOVE',
  SET_UPLOAD_LICENSES: 'PRIVILEGE_STATUS_SET_UPLOAD_LICENSES'
};
