import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaterialInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput';
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import {Field, formValueSelector, reduxForm, change, formValues} from 'redux-form'
import {connect} from "react-redux";
import {FormFieldNoLabel} from "../../../components/form/FormFieldNoLabel";
import {UserForm} from "../../cabinet/vehicles/UserForm";
import {UserFormField} from "../payments/UserFormField";
import {renderSelectFieldReg} from "../../../components/form/renderSelectFieldReg";

let Filter = (props) => {
    const { currentFilters, onFiltersChange } = props;
    const [open, setOpen] = useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const onCancel = () => {
        handleClose();
    };
    return (
        <div>
            <Button
                className="no-margin info-btn"
                variant="outlined"
                color="primary"
                type="button"
                onClick={handleClickOpen}
            >
                Фильтровать
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Фильтровать
                    </Typography>
                    <IconButton color="inherit" onClick={onCancel}>
                        <CloseIcon className="blue" />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <form
                        onSubmit={props.handleSubmit((values) => {
                            onFiltersChange(values);
                            handleClose();
                        })}>
                                <Grid container direction="column" spacing={16} wrap="nowrap">
                                    <Grid item>

                                        <FieldLabel label="Дата от:" name="date_start">
                                            <Field
                                                name="date_start"
                                                type="date"
                                                label="Дата от:"
                                                className="date_style"
                                                component={FormFieldNoLabel}
                                            />
                                        </FieldLabel>
                                    </Grid>

                                    <Grid item>

                                        <FieldLabel label="Дата до:" name="date_last">
                                            <Field
                                                name="date_last"
                                                type="date"
                                                label="Дата до:"
                                                className="date_style"
                                                component={FormFieldNoLabel}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>

                                        <FieldLabel label="Организация/ФИО:" name="user_id">
                                            <Field
                                                name="user_id"
                                                selectOptions={{inputValue: props.initialValues.user_id}}
                                                component={UserFormField}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    {props.payment_type && <Grid item>
                                        <FieldLabel label="Тип платежа:" name="payment_type">
                                            <Field
                                                name="payment_type"
                                                component={renderSelectFieldReg}
                                            >
                                                <option key="type" value=''>
                                                    Тип платежа
                                                </option>
                                                <option key="type-0" value={0}>
                                                    Госпошлина
                                                </option>
                                                <option key="type-1" value={1}>
                                                    Вред
                                                </option>
                                            </Field>
                                        </FieldLabel>
                                    </Grid> }
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="btn-add no-margin"
                                            type="submit"
                                        >
                                            Применить
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            component="span"
                                            className="info-btn"
                                            onClick={onCancel}
                                        >
                                            Очистить
                                        </Button>
                                    </Grid>
                                </Grid>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

Filter = reduxForm({
    // a unique name for the form
    form: 'payment-filter',
    enableReinitialize: true,
})(Filter)

const selector = formValueSelector('payment-filter')
const mapStateToProps = (state) => {
    // or together as a group
    let initialValues = selector(state, 'date_start', 'date_last', 'user_id');
    return {
        initialValues: {
            ...initialValues,
        }
    };
}

Filter = connect(mapStateToProps)(Filter)
export default Filter;
