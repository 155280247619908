import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Field, formValueSelector, submit } from 'redux-form';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { authActions } from '../../../actions/authActions';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { fieldMasks } from '../../../helpers/fieldMasks';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { Loader } from '../../../components/Loader';
import { legalFormActions } from '../../../actions/legalFormActions';

import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { regAppConstants } from '../../../constants/regAppConstants';
import { renderDropdownList } from '../../../components/form/renderDropdownList';
import { renderSelectFieldReg } from '../../../components/form/renderSelectFieldReg';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';

const RegisterStep1Modal = props => {
  const {
    error,
    submitting,
    showRegisterStep1,
    handleStep1Close,
    handleToStep2,
    roleValue,
    legalForms,
    submitForm
  } = props;
  return (
    <Dialog
      className="dialog-window"
      open={showRegisterStep1}
      onClose={handleStep1Close}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Регистрация
        </Typography>
        <IconButton color="inherit" onClick={handleStep1Close}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>
      <DialogContent className="dialog text-center">
        <h4 className="form_title">Заявитель</h4>
        <br />
        <Field
          className="form-control"
          name="role_id"
          component={renderSelectFieldReg}
          //    label="Заявитель"
          validate={[fieldValidators.required]}
        >
          {regAppConstants.ROLES.map(option => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Field>
        {/* Поля физ лица */}
        {roleValue === regAppConstants.ROLE_VALUES.individual && (
          <div>
            <h4>Контактные данные</h4>
            <Field
              name="name"
              type="text"
              placeholder="Полное имя (ФИО) / Название ИП"
              validate={[fieldValidators.required, fieldValidators.maxLength60]}
              component={FormFieldReg}
            />

            <Field
              name="email"
              type="email"
              placeholder="Email"
              validate={[
                fieldValidators.required,
                fieldValidators.email,
                fieldValidators.maxLength40
              ]}
              component={FormFieldReg}
            />
            <small>Контактный телефон</small>
            <Field
              name="phone"
              type="phone"
              placeholder="Контактный телефон"
              validate={[fieldValidators.required, fieldValidators.phoneRus]}
              component={FormFieldReg}
              {...fieldMasks.phoneRus}
            />

            <h4>Платежная информация</h4>
            <small>ИНН</small>
            <Field
              name="inn"
              type="text"
              placeholder="ИНН"
              validate={[fieldValidators.required, fieldValidators.exactLength12]}
              component={FormFieldReg}
              {...fieldMasks.innIndividual}
            />
            <h4>Регистрация</h4>
            <Field
              name="password"
              type="password"
              placeholder="Пароль"
              validate={[
                fieldValidators.required,
                fieldValidators.minLength8,
                fieldValidators.maxLength40
              ]}
              component={FormFieldReg}
            />

            <Field
              name="password_confirmation"
              type="password"
              placeholder="Повторите пароль"
              validate={fieldValidators.passwordsMatch}
              component={FormFieldReg}
            />

            {error && <strong>{error}</strong>}

            <div className="text-left">
              <Field
                name="remember"
                type="checkbox"
                label="Согласие на обработку персональных данных"
                className="checkbox"
                component={MuiCheckbox}
                validate={fieldValidators.required}
              />
            </div>

            <div>
              {submitting && <Loader />}
              <Button
                onClick={submitForm}
                className="btn"
                variant="contained"
                color="primary"
                disabled={submitting}
              >
                Зарегистрироваться
              </Button>
            </div>
          </div>
        )}

        {/* Поля юр лица */}
        {roleValue === regAppConstants.ROLE_VALUES.firm && (
          <div>
            <h4>Данные об организации</h4>
            <Field
              name="name"
              type="text"
              placeholder="Название организации"
              validate={[fieldValidators.required, fieldValidators.maxLength100]}
              component={FormFieldReg}
            />
            <small>ИНН организации</small>
            <Field
                name="inn_org"
                type="text"
                label="ИНН организации"
                validate={[fieldValidators.required, fieldValidators.exactLength10]}
                component={FormFieldReg}
                {...fieldMasks.innFirm}
            />
            <Field
                name="kpp_org"
                type="text"
                label="КПП организации"
                validate={[fieldValidators.required, fieldValidators.exactLength9]}
                component={FormFieldReg}
                {...fieldMasks.kppFirm}
            />
            <small>Организационно-правовая форма</small>
            <Field
              name="legal_form_id"
              component={renderDropdownList}
              data={legalForms ? legalForms.items : []}
              valueField="id"
              textField="title"
              validate={[fieldValidators.required]}
            />
            <br />

            <Field
              name="address"
              type="text"
              placeholder="Юридический адрес"
              validate={[fieldValidators.required, fieldValidators.maxLength100]}
              component={FormFieldReg}
            />
            <Field
              name="executive_fio"
              type="text"
              placeholder="ФИО руководителя"
              validate={[fieldValidators.required, fieldValidators.maxLength100]}
              component={FormFieldReg}
            />
            <Field
              name="executive_position"
              type="text"
              placeholder="Должность руководителя"
              validate={[fieldValidators.required, fieldValidators.maxLength100]}
              component={FormFieldReg}
            />

            <h4 className="form_title">Действует на основании</h4>
            <br />
            <Field
              className="form-control"
              name="reason"
              component={renderSelectFieldReg}
              //    label="Действует на основании"
              validate={[fieldValidators.required]}
            >
              {dictionaryHelper.firmReasons.getList().map(option => (
                <option key={option.id} value={option.id}>
                  {option.title}
                </option>
              ))}
            </Field>

            <h4>Платежная информация</h4>
            <Field
              name="bank_name"
              type="text"
              placeholder="Наименование банка"
              validate={[fieldValidators.required, fieldValidators.maxLength100]}
              component={FormFieldReg}
            />
            <small>ИНН банка</small>
            <Field
              name="bank_inn"
              type="text"
              placeholder="ИНН банка"
              validate={[fieldValidators.required, fieldValidators.exactLength10]}
              component={FormFieldReg}
              {...fieldMasks.innFirm}
            />
            <small>Корреспондентский счет</small>
            <Field
              name="correspondent_account"
              type="text"
              placeholder="Корреспондентский счет"
              validate={[fieldValidators.required, fieldValidators.exactLength20]}
              component={FormFieldReg}
              {...fieldMasks.account}
            />
            <small>БИК банка</small>
            <Field
              name="bank_bik"
              type="text"
              placeholder="БИК банка"
              validate={[fieldValidators.required, fieldValidators.exactLength9]}
              component={FormFieldReg}
              {...fieldMasks.bik}
            />
            <small>Расчетный счет</small>
            <Field
              name="account"
              type="text"
              placeholder="Расчетный счет"
              validate={[fieldValidators.required, fieldValidators.exactLength20]}
              component={FormFieldReg}
              {...fieldMasks.account}
            />
            <br />

            {error && <strong>{error}</strong>}
            <div>
              {submitting && <Loader />}
              <Button onClick={handleToStep2} className="btn" variant="contained" color="primary">
                Продолжить
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const selector = formValueSelector('register');
const mapStateToProps = state => {
  const { legalForms } = state;
  const { showRegisterStep1 } = state.registration;
  const roleValue = selector(state, 'role_id');

  return {
    legalForms,
    roleValue,
    showRegisterStep1
  };
};

const mapDispatchToProps = (dispatch, props) => {
  dispatch(legalFormActions.getAll());

  return {
    handleToStep2: () => {
      dispatch(authActions.openRegisterStep2Modal());
      dispatch(authActions.closeRegisterStep1Modal());
    },
    handleStep1Open: () => {
      dispatch(authActions.openRegisterStep1Modal());
    },
    handleStep1Close: () => {
      dispatch(authActions.closeRegisterStep1Modal());
    },
    submitForm: () => {
      dispatch(submit('register'));
    }
  };
};

const connectedRegisterStep1Modal = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStep1Modal);
export { connectedRegisterStep1Modal as RegisterStep1Modal };
