import React from 'react';
import { FormField } from '../../../components/form/FormField';
import { FormFieldNoLabel } from '../../../components/form/FormFieldNoLabel';

const ApplicationField = ({ value, label, disabled = 'disabled', placeholder = '', link }) => {
  const Component = label ? FormField : FormFieldNoLabel;
  return (
    <Component
      placeholder={placeholder}
      className="form-control"
      disabled={disabled}
      label={label}
      input={{ value }}
      meta={{}}
      link={link}
    />
  );
};

export default ApplicationField;
