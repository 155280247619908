import React from 'react';
import { sumBy, get, set } from 'lodash';
import { Field, ErrorMessage } from 'formik';
import Grid from '@material-ui/core/Grid';
import Section from './components/Section';
import MaterialInput from './components/MaterialInput';
import FieldLabel from './components/FieldLabel';
import MaterialSelect from './components/MaterialSelect';
import { AxleSuspension, NameByAxleSuspension } from './types';
import { getTotalWeight, isContainSpring, recomputeLoad } from './utils';

function renderFields(transport, trailers, renderFn, setFieldValue, axleLoads, values) {
  if (!transport.axles) {
    return null;
  }
  let diff = get(transport, 'axles.length', 0);
  return (
    <Grid container spacing={8} alignItems="flex-start">
      {renderFn(transport, 'transport', 0, setFieldValue, axleLoads, values)}
      {trailers.map((trailer, index) => {
        const oldDiff = diff;
        diff += get(trailer, 'axles.length', 0);
        return renderFn(trailer, `trailers[${index}]`, oldDiff, setFieldValue, axleLoads, values);
      })}
    </Grid>
  );
}

function render(transport, prefix, diff, renderFn, field) {
  if (!transport.axles) {
    return null;
  }
  return transport.axles.map((axle, index) => (
    <WithError index={index + 1 + diff} key={axle.id} name={`${prefix}.axles[${index}].${field}`}>
      {renderFn(index)}
    </WithError>
  ));
}

export const WithError = ({ children, name, index }) => (
  <Grid item xs={4}>
    <Grid container direction="column" justify="center" wrap="nowrap">
      <Grid item>
        <Grid container spacing={8} alignItems="center" wrap="nowrap">
          <Grid item xs={4} className="text-left">
            {index}:
          </Grid>
          <Grid item xs={8}>
            {children}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid item className="has-error">
          <ErrorMessage name={name}>
            {msg => <span className="small-error">{msg}</span>}
          </ErrorMessage>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

function renderAxles(transport, prefix, diff, setFieldValue, axleLoads, values, isLast) {
  if (!transport.axles) return null;
  return transport.axles.map((axle, index) => {
    if (index === transport.axles.length - 1 && isLast) {
      return null;
    }
    return (
      <WithError index={index + 1 + diff} key={axle.id} name={`${prefix}.axles[${index}].distance`}>
        <Field
          fieldType="number"
          name={`${prefix}.axles[${index}].distance`}
          component={MaterialInput}
          afterChange={v =>
            recomputeLoad(
              prefix,
              set({ ...transport }, `axles[${index}].distance`, v),
              setFieldValue,
              axleLoads,
              values
            )
          }
        />
      </WithError>
    );
  });
}

function renderAxlesSection(transport, trailers, setFieldValue, axleLoads, values) {
  let diff = get(transport, 'axlesCount', 0);
  return (
    <Grid container spacing={8} alignItems="flex-start">
      {renderAxles(
        transport,
        'transport',
        0,
        setFieldValue,
        axleLoads,
        values,
        trailers.length === 0
      )}
      {trailers.map((trailer, index) => {
        const isLast = trailers.length - 1 === index;
        const oldDiff = diff;
        diff += get(trailer, 'axlesCount', 0);
        return (
          <React.Fragment>
            {renderAxles(
              trailer,
              `trailers[${index}]`,
              oldDiff,
              setFieldValue,
              axleLoads,
              values,
              isLast
            )}
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

function renderSuspension(transport, prefix, diff, setFieldValue, axleLoads, values) {
  return render(
    transport,
    prefix,
    diff,
    index => (
      <Field
        name={`${prefix}.axles[${index}].suspension`}
        component={MaterialSelect}
        placeholder=""
        options={Object.values(AxleSuspension).map(type => ({
          value: type,
          label: NameByAxleSuspension[type]
        }))}
        getValue={f => f.value}
        afterChange={v =>
          recomputeLoad(
            prefix,
            set({ ...transport }, `axles[${index}].suspension`, v),
            setFieldValue,
            axleLoads,
            values
          )
        }
      />
    ),
    'suspension'
  );
}

function renderSloppiness(transport, prefix, diff, setFieldValue, axleLoads, values) {
  return render(
    transport,
    prefix,
    diff,
    index => (
      <Field
        name={`${prefix}.axles[${index}].sloppiness`}
        component={MaterialSelect}
        placeholder=""
        options={[{ value: 1, label: '1' }, { value: 2, label: '2' }]}
        getValue={f => f.value}
        afterChange={v =>
          recomputeLoad(
            prefix,
            set({ ...transport }, `axles[${index}].sloppiness`, v),
            setFieldValue,
            axleLoads,
            values
          )
        }
      />
    ),
    'sloppiness'
  );
}

function renderWheel(transport, prefix, diff, setFieldValue, axleLoads, values) {
  return render(
    transport,
    prefix,
    diff,
    index => (
      <Field
        fieldType="number"
        name={`${prefix}.axles[${index}].wheelsCount`}
        component={MaterialInput}
        afterChange={v =>
          recomputeLoad(
            prefix,
            set({ ...transport }, `axles[${index}].wheelsCount`, v),
            setFieldValue,
            axleLoads,
            values
          )
        }
      />
    ),
    'wheelsCount'
  );
}

function renderPermissibleLoad(transport, prefix, diff) {
  return render(transport, prefix, diff, index => (
    <Field name={`${prefix}.axles[${index}].permissibleLoad`} component={MaterialInput} disabled />
  ));
}

function renderPermissibleLoadSpring(transport, prefix, diff) {
  return render(transport, prefix, diff, index => (
    <Field
      name={`${prefix}.axles[${index}].permissibleLoadSpring`}
      component={MaterialInput}
      disabled
    />
  ));
}

function renderAxlesLoad(transport, prefix, diff) {
  return render(
    transport,
    prefix,
    diff,
    index => (
      <Field name={`${prefix}.axles[${index}].load`} component={MaterialInput} fieldType="number" />
    ),
    'load'
  );
}

function renderSuspensions(transport, trailers, setFieldValue, axleLoads, values) {
  return renderFields(transport, trailers, renderSuspension, setFieldValue, axleLoads, values);
}

function renderSloppinesses(transport, trailers, setFieldValue, axleLoads, values) {
  return renderFields(transport, trailers, renderSloppiness, setFieldValue, axleLoads, values);
}

function renderWheels(transport, trailers, setFieldValue, axleLoads, values) {
  return renderFields(transport, trailers, renderWheel, setFieldValue, axleLoads, values);
}

function renderPermissibleLoads(transport, trailers, setFieldValue, axleLoads, values) {
  return renderFields(transport, trailers, renderPermissibleLoad, setFieldValue, axleLoads, values);
}

function renderPermissibleLoadsSpring(transport, trailers, setFieldValue) {
  return renderFields(transport, trailers, renderPermissibleLoadSpring, setFieldValue);
}

function renderAxlesLoads(transport, trailers, setFieldValue) {
  return renderFields(transport, trailers, renderAxlesLoad, setFieldValue);
}

const Axles = ({ values, springCondition, setFieldValue, axleLoads }) => {
  const { trailers = [], transport } = values;
  const transportAxles = transport.axlesCount || 0;
  const totalCount = sumBy(trailers, t => t.axlesCount || 0) + transportAxles;
  const totalWeight = getTotalWeight(transport, trailers);
  return (
    <Section title="Оси">
      <Grid item>
        <FieldLabel label="Количество осей*">
          <span className="border-mini">{totalCount}</span>
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel label="Расстояние между осями*" name="transport.axles.distance">
          {renderAxlesSection(transport, trailers, setFieldValue, axleLoads, values)}
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel label="Подвеска*">
          {renderSuspensions(transport, trailers, setFieldValue, axleLoads, values)}
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel label="Скатность*">
          {renderSloppinesses(transport, trailers, setFieldValue, axleLoads, values)}
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel label="Количество колес*">
          {renderWheels(transport, trailers, setFieldValue, axleLoads, values)}
        </FieldLabel>
      </Grid>
      {/* <Grid item> */}
      {/*  <FieldLabel label="Допустимая нагрузка на оси (т)"> */}
      {/*    {renderPermissibleLoads(transport, trailers, setFieldValue)} */}
      {/*  </FieldLabel> */}
      {/* </Grid> */}
      {/* {isContainSpring(values.restrictionDates, springCondition) && ( */}
      {/*  <Grid item> */}
      {/*    <FieldLabel label="Допустимая нагрузка на оси (Весна) (т)"> */}
      {/*      {renderPermissibleLoadsSpring(transport, trailers, setFieldValue)} */}
      {/*    </FieldLabel> */}
      {/*  </Grid> */}
      {/* )} */}
      <Grid item>
        <FieldLabel label="Нагрузка на оси*">{renderAxlesLoads(transport, trailers)}</FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel label="Общая масса" name="totalWeight">
          <span className="border-mini">{totalWeight}</span>
        </FieldLabel>
      </Grid>
    </Section>
  );
};

export default Axles;
