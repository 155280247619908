import React from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Place from '@material-ui/icons/Place';
import Lens from '@material-ui/icons/FiberManualRecord';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { applicationActions } from '../../../../actions/applicationActions';
import { roleConstants } from '../../../../constants/roleConstants';
import ApplicationRoute from '../../../../components/elements/ApplicationRoute';

const TemplateCard = props => {
  const { application, handleUse, handleRemove } = props;

  return (
    <Card className="">
      <CardContent>
        <h3>Шаблон</h3>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                {application.user.role_id === roleConstants.ROLE_INDIVIDUAL
                  ? 'ФИО'
                  : 'Название фирмы'}
              </TableCell>
              <TableCell numeric>{application.user && application.user.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ГРЗ ТС </TableCell>
              <TableCell numeric>
                {application.vehicle && application.vehicle.real_number}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ГРЗ прицепа</TableCell>
              <TableCell numeric>
                {application.trailer ? application.trailer.real_number : 'Без прицепа'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ApplicationRoute application={application} />
      </CardContent>
      <CardActions>
        <Grid container className="app_action" justify="flex-end">
          <Button className="red" onClick={e => handleRemove(application.id)}>
            Удалить
          </Button>
          {((new Date().getTime() < (new Date('2023-01-01 00:00')).getTime())) && (
              <Button className="blue" onClick={e => handleUse(application.id)}>
                Использовать
              </Button>)
          }
        </Grid>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = state => {
  const { authentication } = state;
  return {
    authentication
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { authentication } = stateProps;
  const { user } = authentication;

  const { dispatch } = dispatchProps;

  const handleUse = id => {
    return dispatch(applicationActions.useTemplate(id, user.role_id));
  };

  const handleRemove = id => {
    return dispatch(applicationActions.deleteTemplate(id));
  };

  return {
    ...ownProps,
    ...dispatchProps,
    handleRemove,
    handleUse
  };
};

const connectedTemplateCard = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TemplateCard);
export { connectedTemplateCard as TemplateCard };
