import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { vehicleBrandActions } from '../../../../../actions/vehicleBrandActions';
import VehicleBrandsForm from './VehicleBrandsForm';

const VehicleBrandModal = props => {
  const { vehicleBrands, handleSubmit, handleModalClose } = props;

  return (
    <Dialog
      open={vehicleBrands.showFormModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          {vehicleBrands.currentItem ? 'Редактирование марки' : 'Создание марки'}
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <VehicleBrandsForm currentItem={vehicleBrands.currentItem} onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { vehicleBrands } = state;

  return {
    vehicleBrands
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { vehicleBrands } = stateProps;
  const { dispatch } = dispatchProps;

  const handleSubmit = (values, dispatch) => {
    if (vehicleBrands.currentItem && vehicleBrands.currentItem.id) {
      return dispatch(vehicleBrandActions.update(values));
    }
    return dispatch(vehicleBrandActions.create(values));
  };

  const handleModalClose = () => {
    dispatch(vehicleBrandActions.closeFormModal());
  };

  return {
    ...ownProps,
    vehicleBrands,
    handleSubmit,
    handleModalClose
  };
};

const connectedVehicleBrandModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VehicleBrandModal);

export { connectedVehicleBrandModal as VehicleBrandModal };
