import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { legalFormActions } from '../../../../../actions/legalFormActions';
import { LegalFormsTableList } from './LegalFormsTableList';
import { Loader } from '../../../../../components/Loader';
import { LegalFormModal } from './LegalFormModal';

class LegalFormsBlock extends Component {
  componentDidMount() {
    const { legalForms } = this.props;

    if (!legalForms.items || legalForms.items.length === 0) {
      this.props.dispatch(legalFormActions.getPage(1));
    }
  }

  render() {
    const { legalForms, openCreateModal } = this.props;

    return (
      <div>
        <p>
          Справочник организационно-правовых форм
          <Button aria-label="Добавить" className="btn-add" onClick={openCreateModal}>
            + Создать
          </Button>
        </p>

        <div className="">
          {legalForms.loading && <Loader />}
          {legalForms.error && <span className="text-danger">Ошибка: {legalForms.error}</span>}
          {legalForms.items && (
            <LegalFormsTableList data={legalForms.items} meta={legalForms.meta} />
          )}
        </div>

        <LegalFormModal />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { legalForms } = state;
  return {
    legalForms
  };
};

const mapDispatchToProps = dispatch => {
  const openCreateModal = e => {
    dispatch(legalFormActions.setCurrentItem(null));
    dispatch(legalFormActions.openFormModal());
  };

  return {
    openCreateModal,
    dispatch
  };
};

const connectedLegalFormsBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(LegalFormsBlock);
export { connectedLegalFormsBlock as LegalFormsBlock };
