import * as yup from 'yup';

export const DeclineSchema = yup.object().shape({
    note: yup
        .string()
        .required('Пожалуйста заполните причину отклонения')
});

export const Schema = yup.object().shape({
    ecp: yup.object().shape({
        sig: yup.string().required('Необходимо указать сертификат ЭЦП')
    }).required('Необходимо указать сертификат ЭЦП'),
});
