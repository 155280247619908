import React from 'react';
import { withStyles } from '@material-ui/core';

import ProgressItem from './ProgressItem';

const styles = {
  content: {
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 300,
    overflowY: 'scroll'
  },
  centralizedContainer: {
    width: 300,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  }
};

const ProgressList = ({ items = [], classes }) => {
  return (
    <div className={classes.content}>
      {items.map(item => (
        <ProgressItem key={item.id} item={item} />
      ))}
    </div>
  );
};

export default withStyles(styles)(ProgressList);
