import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { applicationActions } from '../../../../actions/applicationActions';

class ApplicationToWorkButton extends Component {
  render() {
    const { application, user, handleToWork, cls } = this.props;

    return application.status === applicationConstants.STATUS_NEW ? (
      <Button className={cls} onClick={handleToWork(application.id)}>
        Взять в работу
      </Button>
    ) : (
      ''
    );
  }
}

const mapStateToProps = state => {
  const { adminApplications } = state;

  return {
    adminApplications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;

  const handleToWork = id => {
    return e => dispatch(applicationActions.toWork(id));
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,

    handleToWork
  };
};

const connectedApplicationToWorkButton = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationToWorkButton);
export { connectedApplicationToWorkButton as ApplicationToWorkButton };
