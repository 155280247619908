import React from 'react';
import { connect } from 'react-redux';
import { GuestMenu } from './GuestMenu';
import { AdminMenu } from './AdminMenu';

const UserMenu = props => {
  const { user } = props;
  return <div className="menu-right">{user ? <AdminMenu /> : <GuestMenu />}</div>;
};

const mapStateToProps = state => {
  const { user } = state.authentication;
  return {
    user
  };
};

const connectedUserMenu = connect(mapStateToProps)(UserMenu);
export { connectedUserMenu as UserMenu };
