import { change } from 'redux-form';
import { cabinetConstants } from '../constants/cabinetConstants';
import { userService } from '../services/userService';
import { authConstants } from '../constants/authConstants';
import { fileService } from '../services/fileService';

const togglePasswordChange = () => {
  return { type: cabinetConstants.PASSWORD_CHANGE_TOGGLE };
};

const getInfo = (onSuccess = null) => {
  const request = () => {
    return { type: cabinetConstants.GET_INFO_REQUEST };
  };

  const success = data => {
    return { type: cabinetConstants.GET_INFO_SUCCESS, data };
  };

  const failure = error => {
    return { type: cabinetConstants.GET_INFO_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    return userService.getMyInfo().then(
      response => {
        dispatch(success(response.data));
        if (onSuccess) {
          onSuccess(dispatch, response.data);
        }
      },
      error => dispatch(failure(error))
    );
  };
};

const saveInfo = values => {
  const request = values => {
    return { type: cabinetConstants.SAVE_INFO_REQUEST };
  };

  const success = data => {
    return { type: cabinetConstants.SAVE_INFO_SUCCESS, data };
  };
  const userSuccess = data => {
    return { type: authConstants.SAVE_USER_SUCCESS, user: data };
  };

  return dispatch => {
    dispatch(request(values));

    return userService.saveMyInfo(values).then(response => {
      // fix with change user
      const oldUser = JSON.parse(localStorage.getItem('user'));
      const user = {
        ...oldUser,
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        inn: response.data.inn,
        firm: {
            ...oldUser.firm,
            ...response.data.firm
        },
        confirmation_status: response.data.confirmation_status
      };
      localStorage.setItem('user', JSON.stringify(user));

      dispatch(success(response));
      dispatch(userSuccess(response.data));
      return Promise.resolve(response)
    }).catch((error) => Promise.reject(error));
  };
};

const changePassword = (values, dispatch) => {
  const request = data => {
    return { type: cabinetConstants.PASSWORD_CHANGE_REQUEST, data };
  };
  const success = data => {
    return { type: cabinetConstants.PASSWORD_CHANGE_SUCCESS, data };
  };
  return dispatch => {
    dispatch(request({ values }));

    return userService.changePassword(values).then(response => {
      const { data } = response;
      if (data && response.success) {
        // @todo regenerate api token
        dispatch(success(data));
      }

      return data;
    });
  };
};

const uploadInn = (values, existingFiles, dispatch) => {
  const request = data => {
    return { type: cabinetConstants.INN_UPLOAD_REQUEST, data };
  };
  const success = data => {
    return { type: cabinetConstants.INN_UPLOAD_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request(values));

    return fileService
      .uploadInn(values)
      .then(response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));
          dispatch(change('cabinet-firm', 'innFiles', `${existingFiles},${response.data.id}`));

          // set new files list
          existingFiles.push(response.data);
          dispatch(change('cabinet-individual', 'inn_files', existingFiles));
        }

        return response.data;
      })
      .catch(err => {
        console.log('Upload error', err);
      });
  };
};

const removeInnFile = id => {
  return { type: cabinetConstants.INN_REMOVE, id };
};
const setUploadInns = files => {
  return { type: cabinetConstants.SET_UPLOAD_INNS, files };
};

const getFirmUserFirm = () => {
  const request = () => {
    return { type: cabinetConstants.GET_FIRMUSER_FIRM_REQUEST };
  };

  const success = data => {
    return { type: cabinetConstants.GET_FIRMUSER_FIRM_SUCCESS, data };
  };

  const failure = error => {
    return { type: cabinetConstants.GET_FIRMUSER_FIRM_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    return userService.getFirmUserFirmInfo().then(
      response => {
        dispatch(success(response.data));
      },
      error => dispatch(failure(error))
    );
  };
};

const dataConfirmationClose = () => {
  return { type: cabinetConstants.DATA_CONFIRMATION_CLOSE };
};
const dataConfirmationOpen = () => {
  return { type: cabinetConstants.DATA_CONFIRMATION_OPEN };
};

const resetStatuses = () => {
  return { type: cabinetConstants.RESET_STATUSES };
};

export const cabinetActions = {
  togglePasswordChange,

  getInfo,
  saveInfo,

  changePassword,

  uploadInn,
  removeInnFile,
  setUploadInns,

  // firm user
  getFirmUserFirm,

  dataConfirmationOpen,
  dataConfirmationClose,

  resetStatuses
};
