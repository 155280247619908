import React, { useState, useContext } from 'react';
import { get, isEmpty, range, sum } from 'lodash';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCellMaterial from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';

import TableCell from './components/TableCell';
import ReportsFilterModal from './components/ReportsFilterModal';
import { Loader, TablePagination } from '../../../components';
import { StateContext } from './components/StateContext';
import ProgressBar from '../../../components/ProgressBar';
import { ProcessingType } from '../../../constants/fileConstants';
import StatisticsFilterForm from './components/StatisticsFilterModal';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

const doDum = rows => Math.floor(sum(rows) * 100) / 100;

const ReportRow = ({ report, agreement = {}, rowSpan = 1, fill = false }) => (
  <TableRow key={report.id}>
    {fill && <TableCell rowSpan={rowSpan}>{report.formatted_id}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{report.form_id}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{report.issue_place}</TableCell>}
    {fill && (
      <TableCell rowSpan={rowSpan}>{`${report.start_date} - ${report.finish_date}`}</TableCell>
    )}
    {fill && (
      <TableCell rowSpan={rowSpan}>
        {report.user_name}{' '}
        {get(report, 'employee.name') ? ` (${get(report, 'employee.name')})` : ''}
      </TableCell>
    )}
    {fill && <TableCell rowSpan={rowSpan}>{report.user_inn}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{report.real_number}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{report.price}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{report.damage_price}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{report.to_review_time}</TableCell>}
    <TableCell>{agreement.department_title}</TableCell>
    <TableCell>{agreement.created_at}</TableCell>
    <TableCell>{agreement.accept_time}</TableCell>
    <TableCell>{agreement.accept_username}</TableCell>
    {fill && <TableCell rowSpan={rowSpan}>{report.accept_time}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{report.accept_username}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{report.activate_time}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{report.activate_username}</TableCell>}
  </TableRow>
);

const StatisticsRow = ({ data, name }) => (
  <TableRow>
    <TableCellMaterial>{name}</TableCellMaterial>
    <TableCellMaterial>{data.all}</TableCellMaterial>
    <TableCellMaterial>{data.new}</TableCellMaterial>
    <TableCellMaterial>{data.work}</TableCellMaterial>
    <TableCellMaterial>{data.decline}</TableCellMaterial>
    <TableCellMaterial>{data.repeat}</TableCellMaterial>
    <TableCellMaterial>{data.accept}</TableCellMaterial>
    <TableCellMaterial>{data.active}</TableCellMaterial>
    <TableCellMaterial>{data.used}</TableCellMaterial>
    <TableCellMaterial>{data.delete}</TableCellMaterial>
  </TableRow>
);

const ReportsPageView = ({
  onSubmit,
  onExport,
  filter,
  statisticsFilter,
  onStatisticsSubmit,
  onResetStatistics,
  setFilter,
  classes
}) => {
  const { reportsState, statistic } = useContext(StateContext);
  const [filterIsOpen, setFilterOpen] = useState(false);
  const { data, isResolved, isRejected, isSettled, isLoading } = reportsState;
  return (
    <main role="main" className="container full-height">
      <Grid container direction="column" spacing={16}>
        <Grid item container spacing={16}>
          <Grid item>
            <h2 className="h2-title no-margin">Статистика</h2>
          </Grid>
          <Grid item container alignItems="center" justify="space-between">
            <Grid item xs>
              <StatisticsFilterForm
                initialValues={statisticsFilter}
                onSubmit={onStatisticsSubmit}
                onResetStatistics={onResetStatistics}
              />
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Paper className={classes.root}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellMaterial>Тип</TableCellMaterial>
                    <TableCellMaterial>Количество разрешений</TableCellMaterial>
                    <TableCellMaterial>Новые</TableCellMaterial>
                    <TableCellMaterial>В работе</TableCellMaterial>
                    <TableCellMaterial>Отклоненные</TableCellMaterial>
                    <TableCellMaterial>Доработанные</TableCellMaterial>
                    <TableCellMaterial>Одобренные</TableCellMaterial>
                    <TableCellMaterial>Активные</TableCellMaterial>
                    <TableCellMaterial>Завершенные</TableCellMaterial>
                    <TableCellMaterial>Удаленные</TableCellMaterial>
                  </TableRow>
                </TableHead>
                <TableBody style={{ position: 'relative', minHeight: 200 }}>
                  {statistic.isLoading || !statistic.isSettled ? (
                    <TableRow>
                      <TableCellMaterial colSpan={10} align="center" style={{ padding: 32 }}>
                        <Loader size={45} />
                      </TableCellMaterial>
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      <StatisticsRow data={statistic.data.data.normal} name="От заявителей" />
                      <StatisticsRow data={statistic.data.data.fast} name="Быстрые разрешения " />
                      <StatisticsRow data={statistic.data.data.smev} name="СМЭВ" />
                      <StatisticsRow data={statistic.data.data.all} name="Итого" />
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Grid item>
          <h2 className="h2-title no-margin">Выданные разрешения</h2>
        </Grid>
        <Grid item container alignItems="center" justify="space-between">
          {isSettled && (
            <Grid item xs>
              <Button
                className="no-margin info-btn"
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => setFilterOpen(true)}
              >
                Фильтровать
              </Button>
            </Grid>
          )}
          {isResolved && <ProgressBar type={ProcessingType.REPORTS} onExport={onExport} />}
        </Grid>

        {isSettled && (
          <Grid item xs>
            <Paper className={classes.root}>
              <Table>
                <TableHead>
                  {isResolved && isEmpty(data.data) && (
                    <TableRow>
                      <TableCellMaterial />
                    </TableRow>
                  )}
                  {isResolved && !isEmpty(data.data) && (
                    <TableRow>
                      <TableCellMaterial>№ разрешения</TableCellMaterial>
                      <TableCellMaterial>№ бланка</TableCellMaterial>
                      <TableCellMaterial>Территориальное управление</TableCellMaterial>
                      <TableCellMaterial>Период действия разрешения</TableCellMaterial>
                      <TableCellMaterial>Организация / ФИО заявителя</TableCellMaterial>
                      <TableCellMaterial>ИНН заявителя</TableCellMaterial>
                      <TableCellMaterial>ГРЗ ТС</TableCellMaterial>
                      <TableCellMaterial>
                        Стоимость разрешения <br />
                        (Сумма <b>{doDum(data.data.map(r => Number.parseFloat(r.price)))}</b>)
                      </TableCellMaterial>
                      <TableCellMaterial>
                        Стоимость вреда <br />
                        (Сумма <b>{doDum(data.data.map(r => Number.parseFloat(r.damage_price)))}</b>
                        )
                      </TableCellMaterial>
                      <TableCellMaterial>Дата и время подачи заявления</TableCellMaterial>
                      <TableCellMaterial>Ведомства для согласования</TableCellMaterial>
                      <TableCellMaterial>Дата подачи на согласование</TableCellMaterial>
                      <TableCellMaterial>Дата согласования</TableCellMaterial>
                      <TableCellMaterial>Сотрудник ведомства</TableCellMaterial>
                      <TableCellMaterial>Дата согласования разрешения</TableCellMaterial>
                      <TableCellMaterial>Сотрудник, согласовавший рзарешение</TableCellMaterial>
                      <TableCellMaterial>Дата выдачи разрешения</TableCellMaterial>
                      <TableCellMaterial>Сотрудник, выдавший разрешение</TableCellMaterial>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {isResolved && isEmpty(data.data) && (
                    <TableRow>
                      <TableCellMaterial align="center">Объекты не найдены</TableCellMaterial>
                    </TableRow>
                  )}
                  {isResolved &&
                    !isEmpty(data.data) &&
                    data.data.map(({ agreements = [], ...report }) => {
                      const agreementsLength = agreements.length > 0 ? agreements.length : 1;
                      return range(agreementsLength).map(i => (
                        <ReportRow
                          fill={i === 0}
                          report={report}
                          rowSpan={agreementsLength}
                          agreement={agreements[i]}
                        />
                      ));
                    })}
                  {isRejected && (
                    <TableRow>
                      <TableCellMaterial align="center" colSpan={16}>
                        <span className="text-danger">Ошибка: Не удалось загрузить данные</span>
                      </TableCellMaterial>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
            {isResolved && (
              <TablePagination
                meta={{ ...data.meta }}
                onChangePage={newPage => setFilter({ ...filter, page: newPage })}
                onChangeRowsPerPage={newPerPage => setFilter({ ...filter, perPage: newPerPage })}
                options={[20, 50, 100]}
              />
            )}
          </Grid>
        )}
      </Grid>
      {isLoading && (
        <div className={classes.loader}>
          <Loader size={45} />
        </div>
      )}
      <ReportsFilterModal
        initialValues={filter}
        onSubmit={onSubmit}
        isOpen={filterIsOpen}
        setOpen={setFilterOpen}
      />
    </main>
  );
};

export default withStyles(styles)(ReportsPageView);
