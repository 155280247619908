import React from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { applicationConstants } from '../../../constants/applicationConstants';
import { roleConstants } from '../../../constants/roleConstants';
import { formatHelper } from '../../../helpers/formatHelper';
import { statusHelper } from '../../../helpers/statusHelper';
import ApplicationRoute from '../../../components/elements/ApplicationRoute';

const ApplicationCard = props => {
  const { application, authentication } = props;
  const { user } = authentication;
  const isQuick = !!application.fast;
  const isSmev = application.is_smev === 1;
  const role = isQuick ? get(application, 'fast.role_id') : get(application, 'user.role_id');
  const name = isSmev ? get(application, 'vehicle.owner_name') : get(application, 'user.name');

  return (
    <Card className="">
      <CardContent>
        {statusHelper.getStatusIcon(user.role_id, application)}
        <h2 className="h2-title">
          Заявление №{formatHelper.appNumber(application)}
          <br />
          <small>Статус: {statusHelper.getStatusText(user.role_id, application)}</small>
        </h2>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                {role === roleConstants.ROLE_INDIVIDUAL ? 'ФИО' : 'Название фирмы'}
              </TableCell>
              <TableCell numeric>{name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ГРЗ ТС </TableCell>
              <TableCell numeric>
                {application.vehicle && application.vehicle.real_number}
              </TableCell>
            </TableRow>
            {application &&
              application.trailers &&
              application.trailers.map((trailer, index) => (
                <TableRow key={`trailer-value-${index}`}>
                  <TableCell>ГРЗ Трейлера</TableCell>
                  <TableCell numeric>{trailer.real_number}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {isSmev ? (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{get(application, 'route.description', 'Маршрут не задан')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <ApplicationRoute application={application} />
        )}
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Период действия</TableCell>
              <TableCell numeric>
                с {application.start_date} до {application.finish_date}
              </TableCell>
            </TableRow>
            {(application.status === applicationConstants.STATUS_DECLINE ||
              application.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES) && (
              <TableRow>
                <TableCell>
                  {application.status === applicationConstants.STATUS_DECLINE
                    ? 'Причина отклонения'
                    : 'Комментарий'}
                </TableCell>
                <TableCell numeric>{application.comment}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <div className="full-width row">
          <div className="col-md-6">
            <h4 className=" blue" />
          </div>
          <div className="col-md-6 text-right app_action with_price">
            {isSmev ? (
              <Link to={`/control/cooperation/view/${application.id}`}>
                <Button className="moreinfo-btn">Подробнее</Button>
              </Link>
            ) : (
              <Link to={`/control/applications/view/${application.id}`}>
                <Button className="moreinfo-btn">Подробнее</Button>
              </Link>
            )}
          </div>
        </div>
      </CardActions>
    </Card>
  );
};

const mapStateToProps = state => {
  const { authentication } = state;
  return {
    authentication
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const connectedApplicationCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationCard);
export { connectedApplicationCard as ApplicationCard };
