import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { departmentActions } from '../../../../actions/departmentActions';

const DepartmentErrorModal = props => {
  const { departments, handleErrorClose } = props;

  return (
    <Dialog
      open={departments.showDeleteError}
      onClose={handleErrorClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Удаление ведомства
        </Typography>
        <IconButton color="inherit" onClick={handleErrorClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <span>Ведомство не может быть удалено, т.к. есть сотрудники, привязанные к нему</span>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { departments } = state;
  return {
    departments
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleErrorClose: () => {
      dispatch(departmentActions.closeErrorModal());
    }
  };
};

const connectedDepartmentErrorModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentErrorModal);
export { connectedDepartmentErrorModal as DepartmentErrorModal };
