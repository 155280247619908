import React from 'react';
import { connect } from 'react-redux';
import { authActions } from '../../actions/authActions';
import { history } from '../../helpers/history';

const LogoutPage = props => {
  return (
    <main role="main" className="container text-white text-left">
      <div className="kyc">
        <h1 className="welcome">Выход из системы</h1>
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  dispatch(authActions.logout());
  history.push('/');
  return {};
};

const connectedLogoutPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutPage);
export { connectedLogoutPage as LogoutPage };
