import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { axleLoadActions } from '../../../../actions/axleLoadActions';
import TablePagination from '../../../../components/TablePagination';

const AxleLoadTableList = props => {
  const { data, meta, handleChangePage, handleDelete, openUpdateModal } = props;

  return (
    <div>
      <Paper className="overflow">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>10 т/ось</TableCell>
              <TableCell>11,5 т/ось</TableCell>
              <TableCell>6 т/ось</TableCell>
              <TableCell numeric>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((n, index) => {
              return (
                <TableRow key={n.id}>
                  <TableCell>{(meta.current_page - 1) * meta.per_page + index + 1}</TableCell>
                  <TableCell>{n.title}</TableCell>
                  <TableCell>{n.value0}</TableCell>
                  <TableCell>{n.value1}</TableCell>
                  <TableCell>{n.value2}</TableCell>
                  <TableCell numeric>
                    <Button
                      variant="outlined"
                      className="info-btn"
                      size="small"
                      onClick={openUpdateModal(n)}
                    >
                      <span>Редактировать</span>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination meta={meta} onChangePage={handleChangePage} />
    </div>
  );
};

const mapStateToProps = state => {
  const { axleLoads, specialConditions } = state;

  return {
    axleLoads,
    specialConditions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
    //        handleChangePage,
    //         openUpdateModal
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { specialConditions } = stateProps;
  const { tabIndex } = specialConditions;
  const { dispatch } = dispatchProps;

  const handleChangePage = page => {
    dispatch(axleLoadActions.getPage(page, tabIndex));
  };

  const openUpdateModal = item => {
    return e => {
      dispatch(axleLoadActions.setCurrentItem(item));
      dispatch(axleLoadActions.openFormModal());
    };
  };

  return {
    ...ownProps,
    specialConditions,
    dispatch,
    openUpdateModal,
    handleChangePage
  };
};

const connectedAxleLoadTableList = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AxleLoadTableList);
export { connectedAxleLoadTableList as AxleLoadTableList };
