import { createSelector } from 'reselect';

export const getPrivilegeStatusesState = state => state.privilegeStatuses;

export const getIsPrivilegeStatusesLoading = createSelector(
  getPrivilegeStatusesState,
  state => state.loading
);

export const getPrivilegeStatuses = createSelector(
  getPrivilegeStatusesState,
  state => state.items
);

export const getPrivilegeStatusesError = createSelector(
  getPrivilegeStatusesState,
  state => state.error
);

export const getPrivilegeStatusesFetched = createSelector(
  getPrivilegeStatusesState,
  state => state.fetched
);
