import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { routeAddressService } from '../../../services/routeAddressService';

const RouteAutosuggest = ({ text, isRouteEditable, setMarkerValue }) => {
  const [suggestions, setSuggestions] = useState([]);
  const search = searchText =>
    routeAddressService.findLocations(searchText).then(response => setSuggestions(response.data));
  const [showError, setError] = useState(false);
  return (
    <>
      <Autosuggest
        suggestions={suggestions || []}
        onSuggestionsFetchRequested={({ value }) => {
          if (value.length >= 3) search(value);
        }}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={suggestion => suggestion}
        renderSuggestion={({ title }) => <span>{title || ''}</span>}
        inputProps={{
          onBlur: () => setError(true),
          onChange: (event, { method, newValue }) => {
            let val = '';
            if (method === 'click') {
              val = newValue.title;
            }
            if (method === 'type') {
              val = newValue;
            }
            setMarkerValue(val);
          },
          value: text || '',
          placeholder: 'Введите адрес',
          disabled: !isRouteEditable
        }}
      />
      {showError && !text && (
        <div className="has-error">
          <span>Обязательное поле</span>
        </div>
      )}
    </>
  );
};

export default RouteAutosuggest;
