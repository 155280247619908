import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import RegAppsTableList from './RegAppsTableList';
import { applicationActions } from '../../../actions/applicationActions';
import { regAppActions } from '../../../actions/regAppActions';

const filterSubmit = (values, dispatch) => {
  return dispatch(regAppActions.adminFilter(values));
};

const TabContainer = props => {
  return <Typography component="div">{props.children}</Typography>;
};

const RegAppsTab = props => {
  const { regApps } = props;

  return (
    <div>
      <TabContainer>
        <RegAppsTableList onSubmit={filterSubmit} data={regApps.items} meta={regApps.meta} />
      </TabContainer>
    </div>
  );
};

const mapStateToProps = state => {
  const { regApps } = state;
  return {
    regApps
  };
};

const connectedRegAppsTab = connect(mapStateToProps)(RegAppsTab);
export { connectedRegAppsTab as RegAppsTab };
