import React from 'react';
import { Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import { uniqueId, get } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Section from './components/Section';
import FieldLabel from './components/FieldLabel';
import MaterialCheckbox from './components/MaterialCheckbox';
import MaterialInput from './components/MaterialInput';
import SelectControlPosts from './components/SelectControlPosts';
import FileUpload from './components/FileUpload';
import { fileService } from '../../../../services/fileService';

const Penalty = ({ values, setFieldValue }) => {
  const act = get(values, 'penalty.act');
  return (
    <Section title="Штрафник">
      <FieldLabel
        label={<Field name="isPenalty" component={MaterialCheckbox} label="Штрафник" />}
      />
      {values.isPenalty && (
        <React.Fragment>
          <Grid item>
            <FieldLabel label="№ ПВК*" name="penalty.controlPost">
              <Field name="penalty.controlPost" component={SelectControlPosts} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel label="№ акта*" name="penalty.actNumber">
              <Field name="penalty.actNumber" component={MaterialInput} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel label="Штрафстоянка*" name="penalty.parking">
              <Field name="penalty.parking" component={MaterialInput} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel label="Акт" name="penalty.parking">
              <Grid container direction="row" spacing={16} alignItems="center">
                {!act ? (
                  <Grid item xs={8}>
                    <Field
                      id={uniqueId()}
                      buttonText="Загрузить Акт"
                      name="penalty.act"
                      component={FileUpload}
                      upload={fileService.uploadFastPenalty}
                    />
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid item xs={8}>
                      <span>{get(values, 'penalty.act.name')}</span>
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton
                        color="inherit"
                        onClick={() => setFieldValue('penalty.act', null)}
                      >
                        <CloseIcon className="blue" />
                      </IconButton>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </FieldLabel>
          </Grid>
        </React.Fragment>
      )}
    </Section>
  );
};

export default Penalty;
