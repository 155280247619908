import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Field } from 'redux-form';
import { renderSelectFieldReg } from '../../../components/form/renderSelectFieldReg';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';

const VehiclesAxlesTable = props => {
  const { axles } = props;

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Ось</TableCell>
            <TableCell>Скатность</TableCell>
            <TableCell>Тип</TableCell>
            <TableCell numeric>Подъемная</TableCell>
            <TableCell>Число колес</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {axles.map((axle, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                Ось {index + 1}
              </TableCell>
              <TableCell>
                <Field
                  name={`axle_length[${index}]`}
                  placeholder={axle.wheel_count}
                  className="mini-input"
                  component={FormFieldReg}
                  disabled="disabled"
                  value={axle.wheel_count}
                />
              </TableCell>
              <TableCell>
                <Field
                  className="form-control"
                  name={`axle_type[${index}]`}
                  component={renderSelectFieldReg}
                  disabled="disabled"
                >
                  <option key="0" value="">
                    Выберите тип оси
                  </option>
                  {dictionaryHelper.axleTypes.getList().map(option => (
                    <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  ))}
                </Field>
              </TableCell>
              <TableCell>
                <div className="text-right">
                  <Field
                    name={`axle_main[${index}]`}
                    type="checkbox"
                    className="checkbox-table"
                    component={MuiCheckbox}
                    disabled="disabled"
                  />
                </div>
              </TableCell>
              <TableCell>
                <Field
                  name={`axle_wheels[${index}]`}
                  className="mini-input"
                  component={FormFieldReg}
                  disabled="disabled"
                  value={axle.wheels}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default VehiclesAxlesTable;
