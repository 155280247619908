import React, { useEffect, useState } from 'react';
import { flatten, isArray, uniqueId } from 'lodash';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import RouteLines from '../../../../components/RouteLines';
import smevService from '../../../../services/smevService';
import ApvgksLayer from '../../../../components/map/ApvksLayer';
import RestrictedArea from "../../../../components/map/RestrictedArea";
import DrawMarkers from "../../../../components/map/DrawMarkers";

const stamenTonerTiles = 'https://tile.aisktg.ru/tatarstan/{z}/{x}/{y}.png';
const stamenTonerAttr = '';
const mapCenter = [55.211, 50.634];
const zoomLevel = 7;

function toPaths(paths) {
  return paths.map(path => ({
    id: path.id,
    text: path.name || path.name_prefix,
    steps: path.points.map(step => ({
      text: step.name,
      coords: {
        lat: +step.lat,
        lng: +(step.lon || step.lng)
      }
    }))
  }));
}

function getPoints(savedRoutes, points) {
  return points.map(path => ({
    useSaved: !isArray(path),
    ...(isArray(path)
      ? {
          steps: path.map(part => ({
            id: uniqueId('new'),
            text: part.name,
            coords: part.coords
          }))
        }
      : savedRoutes.find(route => route.id === path.id_list))
  }));
}

const RouteMap = ({ points, showLines }) => {
  const [savedRoutes, setSavedRoutes] = useState(null);
  useEffect(() => {
    async function fetchSaved() {
      const response = await smevService.getRoutesList();
      setSavedRoutes(toPaths(response));
    }
    fetchSaved();
  }, []);
  const paths = React.useMemo(() => {
    return savedRoutes ? getPoints(savedRoutes, points) : [];
  }, [points, savedRoutes]);
  console.log('paths', paths);
  return (
      <Map center={mapCenter} zoom={zoomLevel}>
        <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
        <RouteLines steps={points} />
        <RestrictedArea />
        <ApvgksLayer />
        <DrawMarkers markers={points} />
      </Map>);
  return (
    <Map center={mapCenter} zoom={zoomLevel}>
      <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
      <ApvgksLayer />
      {showLines &&
        savedRoutes &&
        paths.map(path => <RouteLines key={path.id} steps={path.steps} />)}
      {flatten(paths.map(path => path.steps)).map(({ coords, text }, i) => {
        const position = coords && coords.lat ? [coords.lat, coords.lon || coords.lng] : null;
        if (position) {
          return (
            <Marker key={i} position={position} alt={i}>
              <Popup>
                <span>{text}</span>
              </Popup>
            </Marker>
          );
        }
        return null;
      })}
    </Map>
  );
};

export default RouteMap;
