import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import VehiclesForm from './VehicleCarForm';
import { vehicleActions } from '../../../actions/vehicleActions';
import { vehicleConstants } from '../../../constants/vehicleConstants';

const updateSubmit = (values, dispatch) => {
  return dispatch(vehicleActions.update(values));
};

const VehiclesUpdatePage = props => {
  const { cabinetVehicles } = props;

  return (
    <div>
      <h3>Редактировать транспортное средство / прицеп</h3>

      <div className="row">
        <div className="col-md-9">
          <div className="tc_type">
            <span
              className={
                cabinetVehicles.typeIndex === vehicleConstants.TAB_CAR
                  ? 'active_tc cursor'
                  : 'cursor'
              }
            >
              Транспортное средство
            </span>
            <span
              className={
                cabinetVehicles.typeIndex === vehicleConstants.TAB_TRAILER
                  ? 'active_tc cursor'
                  : 'cursor'
              }
            >
              Прицеп (полуприцеп)
            </span>
          </div>
        </div>
      </div>

      <VehiclesForm itemId={props.match.params.id} onSubmit={updateSubmit} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { cabinetVehicles } = state;

  return {
    cabinetVehicles
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { cabinetVehicles } = stateProps;
  const { dispatch } = dispatchProps;

  return {
    ...ownProps,
    cabinetVehicles
  };
};

const connectedVehiclesUpdatePage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VehiclesUpdatePage);
export { connectedVehiclesUpdatePage as VehiclesUpdatePage };
