import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ApplicationFilterForm from './ApplicationFilterForm';
import { applicationActions } from '../../../actions/applicationActions';

const ApplicationFilterModal = ({ adminApplications, handleModalClose, filterSubmit }) => (
  <Dialog
    className="dialog-window"
    open={adminApplications.showFilterModal}
    onClose={handleModalClose}
    aria-labelledby="form-dialog-title"
  >
    <Toolbar className="text-left">
      <Typography variant="title" color="inherit" className="flex">
        Фильтр
      </Typography>
      <IconButton color="inherit" onClick={handleModalClose}>
        <CloseIcon className="blue" />
      </IconButton>
    </Toolbar>

    <DialogContent className="dialog">
      <ApplicationFilterForm onSubmit={filterSubmit} />
    </DialogContent>
  </Dialog>
);

export default connect(
  ({ adminApplications }) => ({ adminApplications }),
  dispatch => ({ dispatch }),
  (stateProps, dispatchProps, ownProps) => {
    const { adminApplications } = stateProps;
    const { dispatch } = dispatchProps;

    const handleModalClose = () => {
      dispatch(applicationActions.closeFilterModal());
    };

    const filterSubmit = (values, dispatch) =>
      dispatch(
        applicationActions.getAdminApplications({
          ...values,
          per_page: adminApplications.meta.per_page
        })
      );

    return {
      ...ownProps,
      adminApplications,
      handleModalClose,
      filterSubmit
    };
  }
)(ApplicationFilterModal);
