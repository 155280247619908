import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { regAppActions } from '../../../actions/regAppActions';

const ReasonModal = props => {
  const { handleReasonClose, regApps } = props;

  return (
    <Dialog
      open={regApps.showReasonModal}
      onClose={handleReasonClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Информация о заявке
        </Typography>
        <IconButton color="inherit" onClick={handleReasonClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <span>Причина отклонения: {regApps.currentItem && regApps.currentItem.note}</span>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { regApps } = state;
  return {
    regApps
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleReasonClose: () => {
      dispatch(regAppActions.closeReasonModal());
    }
  };
};

const connectedReasonModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReasonModal);
export { connectedReasonModal as ReasonModal };
