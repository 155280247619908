export const CooperationStatus = {
  New: 0,
  InProgress: 1,
  Completed: 2,
  Returned: 3,
  Finished: 4
};

// Filter options
export const CooperationFilterStatuses = [2, 4, 5, 9];

// Statuses for completed tab
export const CooperationCompletedStatuses = [2, 3, 4, 5, 6, 9];

export const isAgreedStatus = cooperation => [3, 4, 5, 6, 7, 9].includes(cooperation.status);

const getStatus = (status, statuses = []) => {
  if (status === CooperationStatus.Returned) {
    return [8];
  }
  if (status === CooperationStatus.Finished) {
    return [7];
  }
  if (status === CooperationStatus.Completed) {
    if (statuses.length === 0) return CooperationCompletedStatuses;

    const filterStatuses = [];
    statuses
      .map(({ value }) => value)
      .forEach(filterStatus => {
        switch (filterStatus) {
          case 2:
            filterStatuses.push(2);
            break;
          case 4:
            // All completed values for 'Completed' filter
            filterStatuses.push(3, 4, 5, 6);
            break;
          case 5:
            filterStatuses.push(5);
            break;
          case 9:
            filterStatuses.push(9);
            break;
          default:
            break;
        }
      });
    return filterStatuses;
  }
  return [status];
};

export const polishFilters = (filters, status) => {
  const { statuses, ...otherFilters } = filters;
  if (status === CooperationStatus.New) {
    otherFilters.admin_name = undefined;
  }
  otherFilters.status = getStatus(status, statuses);
  return otherFilters;
};

export const CooperationRequestStatusValue = {
  Declined: 2,
  Materials: 9
};

export const CooperationRequestStatus = {
  0: 'Новое',
  1: 'В работе',
  2: 'Отклонено',
  3: 'Согласовано',
  4: 'Согласовано',
  5: 'Активировано',
  6: 'Согласовано',
  7: 'Просрочено',
  8: 'Отозвано заявителем',
  9: 'Требуются доп. материалы'
};

export const LocationType = {
  0: 'Межрегиональная',
  1: 'Межрегиональная',
  2: 'Местная'
};

export const agreementStatus = {
  New: 0,
  InReview: 1,
  Declined: 2,
  AcceptedWithChanges: 3,
  Accepted: 4
};

export const getAgreementStatus = status => {
  switch (status) {
    case agreementStatus.New:
      return 'Отправлен на согласование';
    case agreementStatus.InReview:
      return 'В работе';
    case agreementStatus.Accepted:
      return 'Запрос согласован';
    case agreementStatus.Declined:
      return 'Запрос отклонен';
    default:
      return '';
  }
};

export const getStatusName = item => {
  // запрос активен
  if (item.status === 5 && item.dates.runs_count <= item.dates.runs_used) {
    return 'Истрачено количество поездок';
  }

  return CooperationRequestStatus[item.status];
};
