import React, { useEffect, useState } from 'react';
import { SignService } from '../../../../../services/signService';
import SignPluginCheck from './SignPluginCheck';

const SignPluginCheckContainer = () => {
  const [showModal, setShowModal] = useState(false);
  async function checkPlugin() {
    const hasPlugin = await SignService.hasPlugin();
    setShowModal(!hasPlugin);
  }

  const onClose = () => setShowModal(false);

  useEffect(() => {
    checkPlugin();
  }, []);

  return (
    <SignPluginCheck show={showModal} onClose={onClose} />
  );
};

export default SignPluginCheckContainer;
