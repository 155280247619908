import React, { useContext } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ContentContainer from './ContentContainer';
import { QuickApplicationStatus } from '../../../../constants/quickApplicationConstants';
import { SortField } from '../Sort';
import SortableColumn from './SortableColumn';
import { StateContext } from '../../reports/components/StateContext';
import { getApplicationViewData } from './util';
import ApplicationActions from './ApplicationActions';

const DeclineContent = () => {
  const { sort, onSortChange } = useContext(StateContext);
  return (
    <ContentContainer
      col={5}
      status={QuickApplicationStatus.Declined}
      renderHeader={() => (
        <TableRow>
          <SortableColumn column={SortField.Id} onSortChange={onSortChange} sort={sort}>
            № заявления
          </SortableColumn>
          <TableCell>Дата отклонения</TableCell>
          <SortableColumn column={SortField.Name} onSortChange={onSortChange} sort={sort}>
            Организация / ФИО
          </SortableColumn>
          <TableCell>Сотрудник, отклонивший заявку</TableCell>
          <TableCell align="right">Действие</TableCell>
        </TableRow>
      )}
      renderBody={(items, user) => {
        return items.map(item => {
          const viewData = getApplicationViewData(item, QuickApplicationStatus.Declined, user);
          return (
            <TableRow key={viewData.id}>
              <TableCell>{viewData.appNumber}</TableCell>
              <TableCell>{viewData.createdAt}</TableCell>
              <TableCell>{viewData.userName}</TableCell>
              <TableCell>{viewData.eventUser}</TableCell>
              <TableCell align="right" className="nowrap">
                <ApplicationActions user={user} item={item} showDecline />
              </TableCell>
            </TableRow>
          );
        });
      }}
    />
  );
};

export default DeclineContent;
