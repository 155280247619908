import React from 'react';
import { Field } from 'redux-form';
import {uniqueId} from "lodash";
import {fieldValidators} from "../../../../helpers/fieldValidators";
import IconButton from "@material-ui/core/IconButton";
import {Remove} from "@material-ui/icons";
import {renderSelectFieldNoLabel} from "../../../../components/form/renderSelectFieldNoLabel";

const ApvgkPlatformsFieldComponent = ({ fields, meta: { error, submitFailed }, apvgkList, twoDirections = false, disableActions = false, enableDirection = true, handleChange}) => {
    return (
        <React.Fragment>
            <div className="col-md-10" style={{padding: "10px 0px"}}>
                {!disableActions ? <button
                    variant="contained"
                    className="btn"
                    onClick={(e) => {
                        fields.push({id: -uniqueId()});
                    }}
                >
                    Добавить АПВГК
                </button> :
                    <b>Список станций АПВГК</b>}
            </div>
            {fields.map((apvgk, index) => (
                <div className="row" key={`apvgk_list_${index}`}>
                    <div className="col-md-5">
                        <Field name={`${apvgk}.id`}
                               component={renderSelectFieldNoLabel}
                               disabled={disableActions}
                               validate={[fieldValidators.required, fieldValidators.minValue0]}
                        >
                            <option value={''}>Выберите АПВГК</option>
                            {apvgkList.map(v => (
                                <option value={v.id} key={v.id} selected={v.id === apvgk.id}>{v.name}</option>
                            ))}
                        </Field>
                    </div>
                    <div className="col-md-5">
                        <Field name={`${apvgk}.direction`}
                               component={renderSelectFieldNoLabel}
                               label="Направление"
                               disabled={!enableDirection}
                               onChange={handleChange ? (() => handleChange([...fields.getAll()])) : () => {}}
                               validate={[
                                   fieldValidators.required,
                                   fieldValidators.minValue0,
                                   twoDirections ? fieldValidators.maxValue3 : fieldValidators.maxValue2
                               ]}
                        >
                            {(() => {
                                let apvgkFull = apvgkList.find((v) => v.id === fields.get(index).id);
                                let toRender = apvgkFull ? [1, 2].map((v) => {
                                    return <option value={v} key={v}
                                                   selected={v === fields.get(index).direction}>{apvgkFull[`road_direction${v}_name`]}</option>
                                }) : [];
                                if (twoDirections)
                                    toRender = [(<option value={3} label="">{"Оба направления"}</option>)].concat(toRender)
                                toRender = [(<option value={null} label="">{apvgkFull ? "Укажите направление" : "Выберите АПВГК"}</option>)].concat(toRender)
                                return toRender;
                            })()}
                        </Field>
                    </div>

                    <div className="col-md-2">
                        {!disableActions && <IconButton size="small"
                                    style={{padding: 6}}
                                    onClick={() => {
                                        fields.remove(index);
                                    }}>
                            <Remove/>
                        </IconButton>}
                    </div>
                </div>
            ))}
        </React.Fragment>
    )
}
export default ApvgkPlatformsFieldComponent