import { userConstants } from '../constants/userConstants';
import { userService } from '../services/userService';

const getAll = () => {
  const request = () => {
    return { type: userConstants.GETALL_REQUEST };
  };

  const success = users => {
    return { type: userConstants.GETALL_SUCCESS, users };
  };

  const failure = error => {
    return { type: userConstants.GETALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    userService.getAll().then(users => dispatch(success(users)), error => dispatch(failure(error)));
  };
};

const getPage = (page, filters) => {
  const request = { type: userConstants.GET_PAGE_REQUEST, page, filters };

  const success = users => {
    return { type: userConstants.GET_PAGE_SUCCESS, users };
  };

  const failure = error => {
    return { type: userConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request);

    userService
      .getPage(page, filters)
      .then(users => dispatch(success(users)), error => dispatch(failure(error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: userConstants.LOAD_REQUEST, id };
  };

  const success = user => {
    return { type: userConstants.LOAD_SUCCESS, user };
  };

  const failure = error => {
    return { type: userConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    userService
      .getItem(id)
      .then(user => dispatch(success(user)), error => dispatch(failure(error)));
  };
};

const resetCurrentItem = () => {
  return { type: userConstants.RESET_CURRENT_ITEM };
};

const create = values => {
  const request = values => {
    return { type: userConstants.CREATE_REQUEST, values };
  };

  const success = user => {
    return { type: userConstants.CREATE_SUCCESS, user };
  };

  return dispatch => {
    dispatch(request(values));

    return userService.create(values).then(user => dispatch(success(user)));
  };
};

const update = values => {
  const request = values => {
    return { type: userConstants.UPDATE_REQUEST, values };
  };

  const success = user => {
    return { type: userConstants.UPDATE_SUCCESS, user };
  };

  return dispatch => {
    dispatch(request(values));

    return userService.update(values).then(user => dispatch(success(user)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: userConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: userConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: userConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    userService.delete(id).then(
      user => {
        dispatch(success(id));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const setFilter = role => {
  return { type: userConstants.SET_FILTER, role };
};

const getFirmFull = id => {
  const request = id => {
    return { type: userConstants.LOAD_REQUEST, id };
  };

  const success = user => {
    return { type: userConstants.LOAD_SUCCESS, user };
  };

  const failure = error => {
    return { type: userConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    userService
      .getFirmFull(id)
      .then(user => dispatch(success(user)), error => dispatch(failure(error)));
  };
};

const openFormModal = () => {
  return { type: userConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: userConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: userConstants.SET_CURRENT_ITEM, item };
};

const changeEmail = email => {
  return { type: userConstants.USER_CHANGE_EMAIL, payload: email };
};

export const userActions = {
  getPage,
  getAll,
  getItem,
  resetCurrentItem,
  create,
  update,
  delete: _delete,

  getFirmFull,

  openFormModal,
  closeFormModal,
  setCurrentItem,
  changeEmail
};
