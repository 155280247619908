import React from 'react';
import { flatMap, uniqueId } from 'lodash';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { FieldArray } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import { applicationService } from '../../../../../services/applicationService';
import SinglePath from './SinglePath';
import RestrictedArea from '../../../../../components/map/RestrictedArea';
import smevService from '../../../../../services/smevService';
import RouteLines from '../../../../../components/RouteLines';
import { Loader } from '../../../../../components';
import SinglePathReadOnly from './SinglePathReadOnly';
import ApvgksLayer from '../../../../../components/map/ApvksLayer';

const stamenTonerTiles = 'https://tile.aisktg.ru/tatarstan/{z}/{x}/{y}.png';
const stamenTonerAttr = '';

const getName = (index, markers) => {
  if (index === 0) {
    return 'Отправление:';
  }
  if (index === markers.length - 1) {
    return 'Прибытие:';
  }
  return 'Промежуточная точка';
};

const getStepByCoords = async latlng => {
  if (!latlng) {
    return {
      id: uniqueId(),
      isEmpty: false
    };
  }
  const { lat, lng } = latlng;
  const response = await applicationService.getCoordsLocation(lat, lng);
  const { data } = response;
  return {
    id: uniqueId(),
    isEmpty: false,
    text: data.text,
    coords: latlng
  };
};

const RouteMap = ({
  values,
  arrayHelpers,
  enqueueSnackbar,
  id,
  savedRoutes,
  onRouteChange,
  setFieldValue,
  readOnly
}) => {
  const [mapCenter, setMapCenter] = React.useState([55.211, 50.634]);
  const [savingRoute, setSavingRoute] = React.useState(false);
  const [changed, setChanged] = React.useState(false);
  const zoomLevel = 7;
  const { paths } = values;
  const steps = flatMap(paths, (path, pathIndex) =>
    path ? path.steps.map((step, stepIndex) => ({ ...step, path, pathIndex, stepIndex })) : []
  );
  React.useEffect(() => {
    if (steps.filter(s => s.text).length > 1 && changed) {
      setChanged(false);
      smevService.changeRouteByAdmin(id, paths).then(response => onRouteChange(response.data));
    }
  }, [steps, changed]);
  const saveRoute = React.useCallback(async () => {
    setSavingRoute(true);
    try {
      await smevService.saveRoute(id, paths);
      setFieldValue('routeSaved', true);
      enqueueSnackbar(`Маршрут сохранен`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Ошибка при сохранени маршрута`, { variant: 'error' });
    }
    setSavingRoute(false);
  }, [paths]);
  const updateCenter = ({ coords }) => coords && setMapCenter([coords.lat, coords.lng]);
  const addStep = async ({ latlng }) => {
    try {
      const step = await getStepByCoords(latlng);
      const emptySteps = values.paths.map(path => path.steps.find(s => !s.text));
      const firstEmpty = emptySteps.findIndex(p => !!p);
      if (firstEmpty < 0) {
        const len = values.paths.length - 1;
        const path = values.paths[len];
        if (!path.useSaved) {
          arrayHelpers.replace(len, {
            ...path,
            steps: [...path.steps, step]
          });
        }
        setChanged(true);
        return;
      }
      const path = values.paths[firstEmpty];
      const emptyIndex = path.steps.findIndex(s => !s.text);
      arrayHelpers.replace(firstEmpty, {
        ...path,
        steps: path.steps.map((s, index) => {
          if (index === emptyIndex) {
            return step;
          }
          return s;
        })
      });
      setChanged(true);
    } catch (e) {
      console.log(e);
    }
  };

  const onDrag = ({ stepIndex, pathIndex, path }) => async e => {
    const step = await getStepByCoords(e.target.getLatLng());
    if (path.useSaved) {
      arrayHelpers.replace(pathIndex, {
        id: uniqueId(),
        useSaved: false,
        steps: [...path.steps, step]
      });
      return;
    }
    const newPath = { ...paths[pathIndex] };
    newPath.steps = [...newPath.steps];
    newPath.steps[stepIndex] = step;
    arrayHelpers.replace(pathIndex, newPath);
    updateCenter(step);
    setChanged(true);
  };

  const onAddPath = () => {
    arrayHelpers.push({
      id: uniqueId(),
      useSaved: false,
      steps: [{ text: '' }, { text: '' }]
    });
  };

  return (
    <Grid container spacing={16} direction="column">
      <Grid item>
        <Map center={mapCenter} zoom={zoomLevel} onClick={readOnly ? undefined : addStep}>
          <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
          <RestrictedArea />
          <ApvgksLayer />
          {paths.map(path => (
            <RouteLines key={path.id} steps={path.steps} />
          ))}

          {steps.map((step, index) => {
            const { coords, id, text } = step;
            const position = coords && coords.lat ? [coords.lat, coords.lng] : null;
            const textPre = getName(index, steps);
            if (position) {
              return (
                <Marker
                  key={id}
                  position={position}
                  draggable={!readOnly}
                  alt={index}
                  onDragend={onDrag(step)}
                >
                  <Popup>
                    <span>
                      {textPre} {text}
                    </span>
                  </Popup>
                </Marker>
              );
            }
            return null;
          })}
        </Map>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          spacing={16}
          wrap="nowrap"
          justify="space-between"
          className="full-height"
        >
          {paths.map((path, index) => (
            <Grid item key={path.id}>
              {readOnly ? (
                <SinglePathReadOnly
                  options={savedRoutes}
                  index={index}
                  values={values}
                  key={path.id}
                  name={`paths[${index}]`}
                />
              ) : (
                <SinglePath
                  options={savedRoutes}
                  index={index}
                  values={values}
                  key={path.id}
                  name={`paths[${index}]`}
                  onChange={update => {
                    arrayHelpers.replace(
                      index,
                      update.saved
                        ? { ...update.saved.value, useSaved: true }
                        : { ...path, ...update }
                    );
                    setChanged(true);
                  }}
                  removeStep={() => {
                    arrayHelpers.remove(index);
                    setChanged(true);
                  }}
                />
              )}
            </Grid>
          ))}
          {!readOnly && (
            <React.Fragment>
              <Grid item>
                <Button className="info-btn no-margin" onClick={onAddPath}>
                  + Добавить отрезок
                </Button>
              </Grid>
              <Grid item>
                <Button className="info-btn no-margin" onClick={saveRoute} disabled={savingRoute}>
                  {savingRoute && <Loader size={16} />} Сохранить маршрут
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const RouteMapContainer = ({ ...props }) => (
  <FieldArray
    name="paths"
    render={arrayHelpers => <RouteMap arrayHelpers={arrayHelpers} {...props} />}
  />
);

export default withSnackbar(RouteMapContainer);
