import React from 'react';
import { Field, FieldArray } from 'formik';
import { range, uniqueId } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Section from './components/Section';
import SelectBrand from './components/SelectBrand';
import SelectModel from './components/SelectModel';
import FieldLabel from './components/FieldLabel';
import MaterialInput from './components/MaterialInput';
import { createAxles, setTransportInfo } from './utils';
import TransportNumberInput from './components/TransportNumberInput';

const Trailers = ({ values, setFieldValue, axleLoads }) => (
  <Section title="Прицепы">
    <FieldArray
      name="trailers"
      render={arrayHelpers => (
        <React.Fragment>
          {values.trailers.map((trailer, index) => (
            <React.Fragment key={trailer.id}>
              <Grid item>
                <FieldLabel label={<b>{`Прицеп ${index + 1}`}</b>}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="flat"
                    className="no-margin reset-transform decline-btn"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    Удалить прицеп
                  </Button>
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Марка прицепа*" name={`trailers[${index}].brand`}>
                  <Field
                    name={`trailers[${index}].brand`}
                    component={SelectBrand}
                    afterSelect={() => setFieldValue(`trailers[${index}].model`, null)}
                  />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Модель прицепа*" name={`trailers[${index}].model`}>
                  <Field
                    name={`trailers[${index}].model`}
                    component={SelectModel}
                    brand={trailer.brand}
                    isTrailer
                    isDisabled={!trailer.brand}
                    afterSelect={model => {
                      if (model.id < 1) {
                        return;
                      }
                      setTransportInfo(
                        model,
                        `trailers[${index}]`,
                        setFieldValue,
                        axleLoads,
                        values
                      );
                    }}
                  />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="ГРЗ ТС*" name={`trailers[${index}].number`}>
                  <Field name={`trailers[${index}].number`} component={TransportNumberInput} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Регион*" name={`trailers[${index}].region`}>
                  <Field
                    name={`trailers[${index}].region`}
                    component={MaterialInput}
                    fieldType="number"
                    disableDecimal
                    maxLength={3}
                  />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel label="Количество осей*" name={`trailers[${index}].axlesCount`}>
                  <Field
                    name={`trailers[${index}].axlesCount`}
                    component={MaterialInput}
                    fieldType="number"
                    disableDecimal
                    step="1"
                    min={0}
                    afterChange={v => {
                      const value = v ? Number.parseFloat(v) : '';
                      setFieldValue(`trailers[${index}].axles`, v ? createAxles(value) : []);
                      setFieldValue(`trailers[${index}].axlesCount`, value);
                    }}
                  />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Масса прицепа без груза (т)*"
                  name={`trailers[${index}].initialWeight`}
                >
                  <Field
                    name={`trailers[${index}].initialWeight`}
                    component={MaterialInput}
                    fieldType="number"
                  />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Разрешенная максимальная масса прицепа (т)*"
                  name={`trailers[${index}].maxWeight`}
                >
                  <Field
                    name={`trailers[${index}].maxWeight`}
                    component={MaterialInput}
                    fieldType="number"
                  />
                </FieldLabel>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item>
            <Button
              variant="contained"
              className="btn-add no-margin"
              onClick={() => arrayHelpers.push({ id: -uniqueId() })}
            >
              Добавить прицеп
            </Button>
          </Grid>
        </React.Fragment>
      )}
    />
  </Section>
);

export default Trailers;
