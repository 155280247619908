import { userService } from '../services/userService';
import { regAppConstants } from '../constants/regAppConstants';
import { regAppService } from '../services/regAppService';

const setTab = tabIndex => {
  return { type: regAppConstants.SET_TAB_INDEX, tabIndex };
};
const setRole = roleIndex => {
  return { type: regAppConstants.SET_ROLE_INDEX, roleIndex };
};

const getByStatuses = () => {
  const request = () => {
    return { type: regAppConstants.GET_BY_STATUSES_REQUEST };
  };

  const success = users => {
    return { type: regAppConstants.GET_BY_STATUSES_SUCCESS, regApps: users };
  };

  const failure = error => {
    return { type: regAppConstants.GET_BY_STATUSES_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    regAppService
      .getByStatuses()
      .then(users => dispatch(success(users)), error => dispatch(failure(error)));
  };
};

const getPageByStatus = (page, status, role) => {
  const request = (page, status, role) => {
    return {
      type: regAppConstants.GET_PAGE_BY_STATUS_REQUEST,
      page,
      status,
      role
    };
  };

  const success = (users, page, status, role) => {
    return {
      type: regAppConstants.GET_PAGE_BY_STATUS_SUCCESS,
      regApps: users,
      page,
      status,
      role
    };
  };

  const failure = error => {
    return { type: regAppConstants.GET_PAGE_BY_STATUS_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(page, status));

    regAppService
      .getPageByStatus(page, status, role)
      .then(
        users => dispatch(success(users, page, status, role)),
        error => dispatch(failure(error))
      );
  };
};

const getItem = id => {
  const request = id => {
    return { type: regAppConstants.LOAD_REQUEST, id };
  };

  const success = regApp => {
    return { type: regAppConstants.LOAD_SUCCESS, regApp };
  };

  const failure = error => {
    return { type: regAppConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    regAppService
      .getItem(id)
      .then(regApp => dispatch(success(regApp)), error => dispatch(failure(error)));
  };
};

const setStatus = (id, values) => {
  const request = values => {
    return { type: regAppConstants.SET_STATUS_REQUEST, values };
  };

  const success = data => {
    return { type: regAppConstants.SET_STATUS_SUCCESS, data };
  };

  const failure = (id, error) => {
    return { type: regAppConstants.SET_STATUS_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(values));

    userService.setStatus(values).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const adminUpdate = values => {
  const request = value => {
    return { type: regAppConstants.ADMIN_UPDATE_REQUEST, value };
  };

  const success = regApp => {
    return { type: regAppConstants.ADMIN_UPDATE_SUCCESS, values };
  };

  const failure = error => {
    return { type: regAppConstants.ADMIN_UPDATE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    return regAppService
      .adminUpdate(values)
      .then(regApp => dispatch(success(regApp)), error => dispatch(failure(error)));
  };
};

const accept = id => {
  const request = id => {
    return { type: regAppConstants.ACCEPT_REQUEST, id };
  };

  const success = id => {
    return { type: regAppConstants.ACCEPT_SUCCESS, id };
  };

  const failure = error => {
    return { type: regAppConstants.ACCEPT_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(id));

    return regAppService
      .accept(id)
      .then(regApp => dispatch(success(id)), error => dispatch(failure(error)));
  };
};

const decline = values => {
  const request = values => {
    return { type: regAppConstants.DECLINE_REQUEST, values };
  };

  const success = id => {
    return { type: regAppConstants.DECLINE_SUCCESS, id };
  };

  const failure = error => {
    return { type: regAppConstants.DECLINE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    return regAppService.decline(values).then(
      regApp => {
        dispatch(closeDeclineModal());
        dispatch(success(values.id));
      },
      error => dispatch(failure(error))
    );
  };
};

const openDeclineModal = id => {
  return { type: regAppConstants.DECLINE_OPEN, id };
};
const closeDeclineModal = () => {
  return { type: regAppConstants.DECLINE_CLOSE };
};

const openReasonModal = id => {
  return { type: regAppConstants.REASON_OPEN, id };
};
const closeReasonModal = () => {
  return { type: regAppConstants.REASON_CLOSE };
};

const lock = id => {
  const request = id => {
    return { type: regAppConstants.LOCK_REQUEST, id };
  };

  const success = id => {
    return { type: regAppConstants.LOCK_SUCCESS, id };
  };

  const failure = error => {
    return { type: regAppConstants.LOCK_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(id));

    return regAppService
      .lock(id)
      .then(regApp => dispatch(success(id)), error => dispatch(failure(error)));
  };
};

const unlock = id => {
  const request = id => {
    return { type: regAppConstants.UNLOCK_REQUEST, id };
  };

  const success = id => {
    return { type: regAppConstants.UNLOCK_SUCCESS, id };
  };

  const failure = error => {
    return { type: regAppConstants.UNLOCK_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(id));

    return regAppService
      .unlock(id)
      .then(regApp => dispatch(success(id)), error => dispatch(failure(error)));
  };
};

const adminFilter = values => {
  const request = values => {
    return { type: regAppConstants.ADMIN_FILTER_REQUEST, values };
  };

  const success = response => {
    return { type: regAppConstants.ADMIN_FILTER_SUCCESS, response };
  };

  const failure = error => {
    return { type: regAppConstants.ADMIN_FILTER_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    return regAppService.adminFilter(values).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error))
    );
  };
};

export const regAppActions = {
  setTab,
  setRole,

  getByStatuses,
  getPageByStatus,
  getItem,

  adminUpdate,

  setStatus,

  accept,
  decline,

  openDeclineModal,
  closeDeclineModal,

  openReasonModal,
  closeReasonModal,

  lock,
  unlock,

  adminFilter
};
