import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ClientRoute } from '../../../components/routes/ClientRoute';
import { ApplicationsListPage } from './ApplicationsListPage';
import { ApplicationTemplatesListPage } from './templates/ApplicationTemplatesListPage';
import {ApplicationsStepLayout} from './step/ApplicationStepLayout';
import { ApplicationViewPage } from './ApplicationViewPage';
import { userConstants } from '../../../constants/userConstants';
import { history } from '../../../helpers/history';
import { roleConstants } from '../../../constants/roleConstants';
import { urlHelper } from '../../../helpers/urlHelper';
import NewApplicationViewPage from '../../admin/applications_new/ApplicationViewPageContainer';

class ApplicationsLayout extends Component {
  componentDidMount() {
    const { authentication } = this.props;
    const { user } = authentication;

    if (
      user.role_id !== roleConstants.ROLE_FIRMUSER &&
      user.confirmation_status !== userConstants.CONFIRMAtION_STATUS_ACCEPT
    ) {
      history.push(urlHelper.getHomeUrl(user.role_id));
    }
  }

  render() {
    const { match } = this.props;

    return (
      <div>
        <ClientRoute exact path={`${match.url}/`} component={ApplicationsListPage} />

        {((new Date().getTime() < (new Date('2023-01-01 00:00')).getTime())) && (<div>
            <ClientRoute path={`${match.url}/create/step`} component={ApplicationsStepLayout} />
            <ClientRoute path={`${match.url}/update/:id/step`} component={ApplicationsStepLayout} />
        </div>)}

        <ClientRoute path={`${match.url}/view/:id`} component={ApplicationViewPage} />
        <ClientRoute path={`${match.url}/view_new/:id`} component={NewApplicationViewPage} />

        <ClientRoute path={`${match.url}/templates`} component={ApplicationTemplatesListPage} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { authentication } = state;

  return {
    authentication
  };
};

const connectedApplicationsLayout = connect(mapStateToProps)(ApplicationsLayout);
export { connectedApplicationsLayout as ApplicationsLayout };
