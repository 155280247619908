import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Field, Form, yupToFormErrors, validateYupSchema } from 'formik';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { IssuePlaces, RouteTypes } from '../../../../helpers/dictionaryHelper';
import GeneralInfo from './GeneralInfo';
import TransportInfo from './TransportInfo';
import Trailers from './Trailers';
import FieldLabel from './components/FieldLabel';
import MaterialSelect, { convertToOptions } from './components/MaterialSelect';
import { Types } from './types';
import Escort from './Escort';
import Axles from './Axles';
import Penalty from './Penalty';
import RouteInfo from './RouteInfo';
import { createQuickApplication } from '../../../../services/quickApplicationService';
import { urlHelper } from '../../../../helpers/urlHelper';
import { transformToRequest } from './utils';
import { Schema } from './Schema';
import { specialConditionActions } from '../../../../actions/specialConditionActions';
import { axleLoadActions } from '../../../../actions/axleLoadActions';

const defaultInitialValues = {
  type: Types.Individual,
  restrictionDates: {},
  tripCount: 1,
  transport: {},
  trailers: [],
  escort: [],
  is_fast: 1,
  dimensions: {},
  escortCount: 0,
  isPenalty: false,
  penalty: {},
  axles: [],
  route: {
    useStatus: false,
    steps: [{ text: '' }, { text: '' }]
  }
};

const CreateQuickApplication = ({
  initialValues = defaultInitialValues,
  history,
  user,
  loadSpringCondition,
  springCondition,
  springIsLoading,
  loadAxleLoads,
  axleLoads,
  axlesIsLoading
}) => {
  React.useEffect(() => {
    if (!springCondition && !springIsLoading) {
      loadSpringCondition(1);
    }
  }, []);
  React.useEffect(() => {
    if (!axleLoads.length && !axlesIsLoading) {
      loadAxleLoads();
    }
  }, []);
  return (
    <main role="main" className="container">
      <div className="kyc">
        <Grid container direction="column" spacing={24}>
          <Grid item>
            <h2 className="h2-title no-margin">Быстрые разрешения</h2>
          </Grid>
          <Grid item>
            <Formik
              // validationSchema={Schema}
              initialValues={initialValues}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                try {
                  const requestData = await transformToRequest(data);
                  const res = await createQuickApplication(requestData);
                  const home = urlHelper.getHomeUrl(user.role_id);
                  history.push(`${home}/quick_applications/view/${res.id}`);
                  console.log(res);
                } catch (e) {
                  console.log(e);
                }
                setSubmitting(false);
              }}
              validate={async values => {
                return new Promise((resolve, reject) => {
                  validateYupSchema(values, Schema, false, { head: values }).then(
                    function() {
                      resolve({});
                    },
                    function(err) {
                      reject(yupToFormErrors(err));
                    }
                  );
                });
              }}
              render={({ values, isValid, isSubmitting, setFieldValue, setFieldTouched }) => (
                <Form>
                  <Grid container direction="column" spacing={16} wrap="nowrap">
                    <GeneralInfo values={values} setFieldValue={setFieldValue} />
                    <Penalty values={values} setFieldValue={setFieldValue} />
                    <TransportInfo
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      axleLoads={axleLoads}
                    />
                    <Trailers values={values} setFieldValue={setFieldValue} axleLoads={axleLoads} />
                    <Escort values={values} setFieldValue={setFieldValue} />
                    <Axles
                      values={values}
                      setFieldValue={setFieldValue}
                      springCondition={springCondition}
                      axleLoads={axleLoads}
                    />
                    <RouteInfo
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    <br />
                    <Grid item>
                      <FieldLabel label="Вид перевозки*" name="routeType">
                        <Field
                          name="routeType"
                          component={MaterialSelect}
                          options={convertToOptions(RouteTypes)}
                          placeholder="Выберите Вид перевозки"
                        />
                      </FieldLabel>
                    </Grid>
                    <Grid item>
                      <FieldLabel label="Территориальное управление ГБУ БДД*" name="issuePlaces">
                        <Field
                          name="issuePlaces"
                          component={MaterialSelect}
                          options={convertToOptions(IssuePlaces)}
                          placeholder="Выберите Территориальное управление"
                        />
                      </FieldLabel>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className={`btn-add no-margin ${!isValid ? 'disabled-button' : ''}`}
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                        {isSubmitting ? <CircularProgress /> : 'Создать'}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            />
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

const mapStateToProps = state => ({
  user: state.authentication.user,
  springCondition: state.specialConditions.currentItem,
  springIsLoading: state.specialConditions.isLoading,
  axleLoads: state.axleLoads.items,
  axlesIsLoading: state.axleLoads.isLoading
});

const mapDispatchToProps = {
  loadSpringCondition: specialConditionActions.getItem,
  loadAxleLoads: axleLoadActions.getAll
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateQuickApplication)
);
