import React from 'react';
import { connect } from 'react-redux';
import MainMenuLink from './MainMenuLink';
import mainLogo from './img/logo.png';
import { urlHelper } from '../../../../helpers/urlHelper';
import { apiConstants } from '../../../../constants/apiConstants';

const MainMenu = props => {
  const { authentication } = props;

  let homePath = '';
  if (authentication && authentication.user) {
    homePath = urlHelper.getHomeUrl(authentication.user.role_id);
  }
  const links = [
    {
      key: '1',
      path: homePath,
      title: 'АИС КТГ',
      img: mainLogo,
      imgClass: 'logo'
    },
    { key: '2', path: homePath, title: 'АИС КТГ', menuClass: 'logo-title' }
  ];

  const centerLinks = [
    { key: '1', path: '/agreement', title: 'О нас' },
    {
      key: '2',
      path: `${apiConstants.API_URL}/storage/info/instructions.pdf`,
      title: 'Инструкция',
      isExternal: true
    }
  ];

  return (
    <div className="row">
      <div className="col-md-6">
        <ul className="navbar-nav mr-auto">
          {links.map(link => (
            <MainMenuLink {...link} />
          ))}
        </ul>
      </div>
      <div className="col-md-6 menu-center">
        <ul className="navbar-nav mr-auto">
          {centerLinks.map(link => (
            <MainMenuLink {...link} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { authentication } = state;
  return {
    authentication
  };
};

const connectedMainMenu = connect(mapStateToProps)(MainMenu);
export { connectedMainMenu as MainMenu };
