import React, { Fragment, useState } from 'react';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';

const DOTS_COUNT = 3;

const styles = theme => ({
  button: {
    marginLeft: theme.spacing.unit
  }
});

const Truncate = ({ children, maxCount, classes }) => {
  const [showFull, setShowFull] = useState(false);
  if (!children || !isString(children)) return null;
  const shouldBeTruncated = children.length > maxCount;
  const truncated = children.substring(0, maxCount - DOTS_COUNT);
  const toggle = () => setShowFull(prev => !prev);
  return (
    <Fragment>
      {showFull || !shouldBeTruncated ? children : `${truncated}...`}
      {shouldBeTruncated && (
        <Button onClick={toggle} className={classes.button}>
          {showFull ? 'Свернуть' : 'Подробнее'}
        </Button>
      )}
    </Fragment>
  );
};

Truncate.propTypes = {
  children: PropTypes.string.isRequired,
  maxCount: PropTypes.number.isRequired,
  classes: PropTypes.any.isRequired
};

export default withStyles(styles)(Truncate);
