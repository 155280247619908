import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import { MuiCheckbox } from '../../../../../components/form/MuiCheckbox';
import { applicationActions } from '../../../../../actions/applicationActions';
import { vehicleConstants } from '../../../../../constants/vehicleConstants';
import { ApplicationVehicleInfo } from './ApplicationVehicleInfo';
import { specialConditionActions } from '../../../../../actions/specialConditionActions';
import { FormFieldReg } from '../../../../../components/form/FormFieldReg';
import { fieldValidators } from '../../../../../helpers/fieldValidators';
import { privilegeStatusActions } from '../../../../../actions/privilegeStatusActions';
import { FormField } from '../../../../../components/form/FormField';
import FileUploader from '../../../../../components/form/FileUploader';
import CardContent from '@material-ui/core/CardContent';

class ApplicationStepVehicleForm extends Component {
  componentDidMount() {
    const { id, specialConditions, dispatch } = this.props;

    const checkStatus = (dispatch, application) => {
      dispatch(privilegeStatusActions.checkStatus(application.vehicle.real_number));
    };

    // if (id && (!userApplications.currentItem || userApplications.currentItem.id !== id)) {
    if (id) {
      dispatch(applicationActions.getItem(id, checkStatus));
    } else {
      dispatch(applicationActions.resetItem());
    }

    if (!specialConditions.currentItem) {
      dispatch(specialConditionActions.getItem(1));
    }
  }

  render() {
    const {
      specialConditions,
      handleSubmit,
      openSearchVehicleModal,
      addTrailer,
      vehicle,
      trailers
    } = this.props;

    const getPrivilegeStatus = () => {
      const result = get(vehicle, 'privilege_status_title') || get(vehicle, 'privilege.privilegeStatus.title');
      if (trailers) {
        for (let i = 0; i < trailers.length; i++) {
          if (trailers[i] && trailers[i].id) {
            if (!get(vehicle, 'privilege_status_title')) {
              return null;
            }
          }
        }
      }

      return result || null;
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-8">
            <h2 className="h2-title">Выбор ТС и прицепа</h2>
            <div className="row">
              <div className="col-md-12">
                <p>
                  Транспортное средство
                  <Button
                    onClick={e => {
                      return openSearchVehicleModal(vehicleConstants.TAB_CAR, null);
                    }}
                    className="info-btn"
                  >
                    Выбрать
                  </Button>
                </p>
              </div>
            </div>
            <Field
              name="vehicle"
              type="hidden"
              label=""
              validate={[fieldValidators.required]}
              component={FormFieldReg}
            />
            {vehicle && vehicle.id && (
              <ApplicationVehicleInfo isTrailer={false} index={null} vehicle={vehicle} />
            )}
            <br />

            {trailers &&
              trailers.map((trailer, index) => {
                return (
                  <div key={index}>
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          Прицеп или полуприцеп{' '}
                          <Button
                            onClick={e => {
                              return openSearchVehicleModal(vehicleConstants.TAB_TRAILER, index);
                            }}
                            className="info-btn"
                          >
                            Выбрать
                          </Button>
                        </p>
                      </div>
                    </div>
                    {trailer && trailer.id && (
                      <ApplicationVehicleInfo isTrailer vehicle={trailer} index={index} />
                    )}
                    <br />
                  </div>
                );
              })}
            <br />

            {trailers && trailers.length < 5 && (
              <Button
                onClick={e => {
                  return addTrailer();
                }}
                size="small"
                color="primary"
                className="no-margin btn-add"
                type="button"
              >
                + Добавить прицеп
              </Button>
            )}
          </div>
        </div>
        <br />

        {getPrivilegeStatus() ? (
          <p>
            <strong>Особые условия</strong>
          </p>
        ) : (
          ''
        )}

        {getPrivilegeStatus() ? (
          <Field
            name="use_privilege_status"
            type="checkbox"
            label={`Использовать статус "${getPrivilegeStatus()}"`}
            className="checkbox_filter"
            component={MuiCheckbox}
            value="1"
          />
        ) : (
          ''
        )}
        {false && specialConditions.currentItem && specialConditions.currentItem.value ? (
          <Field
            name="use_special_condition"
            type="checkbox"
            label={`Согласен оплатить разрешение с учетом действия условия: '${
              specialConditions.currentItem ? specialConditions.currentItem.title : ''
            }'`}
            className="checkbox_filter"
            component={MuiCheckbox}
            value="1"
          />
        ) : (
          ''
        )}
        <br />
        {/* Штрафник */}
        <Field
          name="is_penalty"
          type="checkbox"
          label="Штрафник"
          className="checkbox"
          component={MuiCheckbox}
        />
        <Field
          name="is_agricultural"
          type="checkbox"
          label="Сельхозтехника"
          className="checkbox"
          component={MuiCheckbox}
        />
        <br />
        <Button type="submit" className="btn-add no-margin">
          Продолжить
        </Button>
      </form>
    );
  }
}

const mapStateToProps = state => {
  const { userApplications, specialConditions, authentication } = state;
  const { currentItem } = userApplications;
  const { user } = authentication;

  const selector = formValueSelector('application-form');
  const { vehicle, trailers } = selector(state, 'vehicle', 'trailers');

  return {
    initialValues: {
      id: currentItem ? currentItem.id : 0,
      vehicle: currentItem ? currentItem.vehicle : null,
      trailers: currentItem ? currentItem.trailers : [],

      use_special_condition: currentItem && currentItem.special_condition_id ? 1 : 0,
      special_condition_id: currentItem ? currentItem.special_condition_id : 0,

      use_privilege_status: currentItem && currentItem.privilege_status_id ? 1 : 0,
      privilege_status_id: currentItem ? currentItem.privilege_status_id : 0,
      role_id: user.role_id,
      is_penalty: currentItem && currentItem.dates ? currentItem.dates.is_penalty : false,
      is_agricultural: currentItem ? currentItem.is_agricultural : false,
      use_status_coefficient: currentItem ? currentItem.use_status_coefficient : false,
    },
    userApplications,
    specialConditions,
    vehicle,
    trailers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { userApplications, specialConditions, vehicle, trailers, initialValues } = stateProps;

  const { dispatch } = dispatchProps;

  const openSearchVehicleModal = (isTrailer, index) => {
    dispatch(applicationActions.setApplicationVehicleType(isTrailer, index));
    dispatch(applicationActions.openMyVehiclesModal());
  };

  const addTrailer = () => {
    dispatch(change('application-form', 'trailers', [...trailers, {}]));
  };

  return {
    ...ownProps,

    userApplications,
    specialConditions,
    vehicle,
    trailers,
    initialValues,

    dispatch,

    openSearchVehicleModal,
    addTrailer
  };
};

ApplicationStepVehicleForm = reduxForm(
  {
    form: 'application-form',
    enableReinitialize: true,
    destroyOnUnmount: false
  },
  mapStateToProps
)(ApplicationStepVehicleForm);

const connectedApplicationStepVehicleForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationStepVehicleForm);
export { connectedApplicationStepVehicleForm as ApplicationStepVehicleForm };
