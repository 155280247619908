import React from 'react';

const NumberRegexp = /[^0-9АВЕКМНОРСТУХETYOPAHKXCBM]/g;

const TransportNumberInput = ({
  field, // { name, value, onChange, onBlur }
  afterChange = () => null,
  ...props
}) => (
  <input
    className="form-control"
    {...field}
    {...props}
    onChange={e => {
      let { value } = e.target;
      value = value
        .toUpperCase()
        .replace(NumberRegexp, '')
        .substr(0, 10);
      e.target.value = value;
      field.onChange(e);
      afterChange(e.target.value);
    }}
  />
);

export default TransportNumberInput;
