import React from 'react';
import { roleConstants } from '../../../constants/roleConstants';
import { applicationConstants } from '../../../constants/applicationConstants';
import ShowByStatus from './ShowByStatus';
import ApplicationSectionTitle from './ApplicationSectionTitle';

const ApplicationBillInfo = ({ application }) => (
  <ShowByStatus
    disallowedRoles={roleConstants.ROLE_WEIGHT_CONTROL}
    additionalCondition={!!application.permit}
    allowedStatuses={[
      applicationConstants.STATUS_ACCEPT,
      applicationConstants.STATUS_ACCEPT_WITH_CHANGES,
      applicationConstants.STATUS_ACTIVE
    ]}
  >
    <div>
      <ApplicationSectionTitle>Оплата разрешения</ApplicationSectionTitle>
      <p>
        <a target="_blank" href={application.permit && application.permit.invoice_link}>
          Счет на оплату
        </a>
        <hr />
      </p>
    </div>
  </ShowByStatus>
);

export default ApplicationBillInfo;
