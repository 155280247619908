import React from 'react';
import { FormField } from './form/FormField';
import { momentHelper } from '../helpers/momentHelper';

const CooperationApplicationInfo = ({ is_smev, smev }) => {
  if (!is_smev || !smev) {
    return null;
  }
  return (
    <React.Fragment>
      <p>
        <strong>Межведомственное взаимодействие</strong>
      </p>
      <FormField
        className="form-control"
        disabled="disabled"
        label="Наименование заявителя"
        input={{ value: smev.authority_name }}
        meta={{}}
      />
      <FormField
        className="form-control"
        disabled="disabled"
        label="Номер документа"
        input={{ value: smev.output_number }}
        meta={{}}
      />
      <FormField
        className="form-control"
        disabled="disabled"
        label="Дата"
        input={{ value: momentHelper.changeYmdHisTodmY(smev.output_date) }}
        meta={{}}
      />
    </React.Fragment>
  );
};

export default CooperationApplicationInfo;
