import React from 'react';
import Select from 'react-select';
import { isNil } from 'lodash';

export const convertToOptions = data =>
  data.map(d => ({
    value: d.id,
    label: d.title
  }));

const MaterialSelect = ({
  field, // { name, value, onChange, onBlur }
  children,
  form,
  getValue = f => f.value,
  options,
  afterChange = () => null,
  ...props
}) => (
  <Select
    name={field.name}
    onBlur={() => form.setFieldTouched(field.name)}
    value={
      !isNil(field.value)
        ? // eslint-disable-next-line react/destructuring-assignment
          options[props.isMulti ? 'filter' : 'find'](o =>
            !props.isMulti
              ? o.value === getValue(field)
              : field.value.map(getValue).includes(o.value)
          )
        : null
    }
    onChange={change => {
      const { value } = change;
      if (props.isMulti) {
        console.log(change);
        form.setFieldValue(field.name, change);
        afterChange(value);
      } else {
        form.setFieldValue(field.name, value);
        afterChange(value);
      }
    }}
    isSearchable={false}
    options={options}
    {...props}
  />
);

export default MaterialSelect;
