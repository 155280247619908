import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { authActions } from '../../../actions/authActions';


const showKey = 'create-disabled-work-alert-show';
const AlertModal = props => {
  let item = Number.parseInt(localStorage.getItem(showKey) || '1');
  const [show, setShow] = React.useState(item <= 3 && ((new Date().getTime() > (new Date('2022-12-31 15:00')).getTime())));

  const onClose = () => {
    localStorage.setItem(showKey, item + 1)
    setShow(false);
  }
  return (
    <Dialog open={show} onClose={onClose} aria-labelledby="form-dialog-title">
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Изменения в федеральном законе
        </Typography>
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <p>В связи с новыми изменениями в законе, выдача разрешений будет приостановлена с 1 января 2023 года. Приносим извинения за неудобства.</p>

      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

const connectedLoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertModal);
export { connectedLoginPage as AlertModal };
