import React from 'react';
import ShowByStatus from './ShowByStatus';
import { applicationConstants } from '../../../constants/applicationConstants';
import { roleConstants } from '../../../constants/roleConstants';
import ApplicationSectionTitle from './ApplicationSectionTitle';
import ApplicationField from './ApplicationField';

const ApplicationDeclineInfo = ({ admin }) => {
  if (!admin) {
    return null;
  }
  return (
    <ShowByStatus
      additionalCondition={!!admin}
      allowedStatus={applicationConstants.STATUS_DECLINE}
      allowedRoles={[
        roleConstants.ROLE_ADMIN,
        roleConstants.ROLE_INDIVIDUAL,
        roleConstants.ROLE_FIRM,
        roleConstants.ROLE_FIRMUSER
      ]}
    >
      <ApplicationSectionTitle>Дополнительная информация</ApplicationSectionTitle>
      <ApplicationField value={admin.name} label="Сотрудник, отклонивший заявку" />
    </ShowByStatus>
  );
};

export default ApplicationDeclineInfo;
