export const legalFormConstants = {
  GETPAGE_REQUEST: 'LEGAL_FORM_GETPAGE_REQUEST',
  GETPAGE_SUCCESS: 'LEGAL_FORM_GETPAGE_SUCCESS',
  GETPAGE_FAILURE: 'LEGAL_FORM_GETPAGE_FAILURE',

  GETALL_REQUEST: 'LEGAL_FORM_GETALL_REQUEST',
  GETALL_SUCCESS: 'LEGAL_FORM_GETALL_SUCCESS',
  GETALL_FAILURE: 'LEGAL_FORM_GETALL_FAILURE',

  LOAD_REQUEST: 'LEGAL_FORM_LOAD_REQUEST',
  LOAD_SUCCESS: 'LEGAL_FORM_LOAD_SUCCESS',
  LOAD_FAILURE: 'LEGAL_FORM_LOAD_FAILURE',

  CREATE_REQUEST: 'LEGAL_FORM_CREATE_REQUEST',
  CREATE_SUCCESS: 'LEGAL_FORM_CREATE_SUCCESS',
  CREATE_FAILURE: 'LEGAL_FORM_CREATE_FAILURE',

  UPDATE_REQUEST: 'LEGAL_FORM_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'LEGAL_FORM_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'LEGAL_FORM_UPDATE_FAILURE',

  DELETE_REQUEST: 'LEGAL_FORM_DELETE_REQUEST',
  DELETE_SUCCESS: 'LEGAL_FORM_DELETE_SUCCESS',
  DELETE_FAILURE: 'LEGAL_FORM_DELETE_FAILURE',

  FORM_MODAL_OPEN: 'LEGAL_FORM_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'LEGAL_FORM_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'LEGAL_FORM_SET_CURRENT_ITEM'
};
