export const roleConstants = {
  ROLE_ADMIN: 1,
  ROLE_OFFICER: 2,
  ROLE_INDIVIDUAL: 3,
  ROLE_FIRM: 4,
  ROLE_FIRMUSER: 5,
  ROLE_DEPARTMENT_AGENT: 6,
  ROLE_WEIGHT_CONTROL: 7,
  ROLE_APVGK: 8, // Сотрудник обработки данных с АПВГК
  ROLE_ACCOUNTANT: 9,
  ROLE_TRACK_CHECKER: 10,

  ROLE_INDIVIDUAL_TAB: 0,
  ROLE_FIRM_TAB: 1
};
