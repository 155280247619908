import React, {Component} from 'react';
import {connect} from 'react-redux';
import {controlPostActions} from "../../../actions/controlPostActions";
import ApvgkConnectForm from "./ApvgkConnectForm";
import {controlPostService} from "../../../services/controlPostService";
import {history} from "../../../helpers/history";

class ApvgkConnectPage extends Component {
    state = {
        load: false
    };
    componentDidMount() {
        const {controlPosts} = this.props;
        if (!controlPosts.items || controlPosts.items.length === 0) {
            this.props.dispatch(controlPostActions.getAll());
        }
    }

    render() {
        const {connectApvgk} = controlPostService;
        const { params, controlPosts} = this.props;
        const submit = (params) => {
            this.setState({load:true});
            return new Promise((res,rej) => connectApvgk(params).then((r) => {
                history.push(window.location.pathname.replace('/add', ''));
                res(r);
            }).catch(rej).finally(() => {
                this.setState({load:false});
            }))
        };
        return (
            <main role="main" className="container">
                <div className="">
                    <h2 className="h2-title">Информация о смене </h2>
                    <br/>
                    <br/>
                </div>
                <div className="">
                    <br/>
                    <ApvgkConnectForm onSubmit={submit}/>
                    <br />
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => {

    const { controlPosts } = state;

    return {
        controlPosts
    };
};

const mapDispatchToProps = dispatch => {
    const params = new URLSearchParams(window.location.search);

    return {
        params,
        dispatch
    };
};

const connectedApplicationsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ApvgkConnectPage);
export {connectedApplicationsPage as ApvgkConnectPage};
