import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { controlPostActions } from '../../../../actions/controlPostActions';
import { ControlPostsTableList } from './ControlPostTableList';
import { Loader } from '../../../../components/Loader';
import { ControlPostModal } from './ControlPostModal';

class ControlPostBlock extends Component {
  componentDidMount() {
    const { controlPosts } = this.props;

    if (!controlPosts.items || controlPosts.items.length === 0) {
      this.props.dispatch(controlPostActions.getPage(1));
    }
  }

  render() {
    const { controlPosts, openCreateModal } = this.props;

    return (
      <main role="main" className="container text-left">
        <p>
          Посты весового контроля
          <Button aria-label="Добавить" className="btn-add" onClick={openCreateModal}>
            + Создать
          </Button>
        </p>

        <div className="">
          {controlPosts.loading && <Loader />}
          {controlPosts.error && <span className="text-danger">Ошибка: {controlPosts.error}</span>}
          {controlPosts.items && (
            <ControlPostsTableList data={controlPosts.items} meta={controlPosts.meta} />
          )}
        </div>

        <ControlPostModal />
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { controlPosts } = state;
  return {
    controlPosts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    openCreateModal: e => {
      dispatch(controlPostActions.setCurrentItem(null));
      dispatch(controlPostActions.openFormModal());
    }
  };
};

const connectedControlPostBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlPostBlock);
export { connectedControlPostBlock as ControlPostBlock };
