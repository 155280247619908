import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import UsersForm from './UsersForm';
import { userActions } from '../../../actions/userActions';
import { roleConstants } from '../../../constants/roleConstants';

const UserModal = props => {
  const { users, handleSubmit, handleModalClose } = props;

  return (
    <Dialog
      open={users.showFormModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{className: "UserModalPaper"}}
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          {users.currentItem ? 'Редактирование пользователя' : 'Добавление пользователя'}
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog_important text-left">
        <UsersForm currentItem={users.currentItem} onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { users } = state;

  return {
    users
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { users } = stateProps;
  const { dispatch } = dispatchProps;

  const handleSubmit = (values, dispatch) => {
    const data = values;
    const role = parseInt(data.role_id, 10);
    if (role !== roleConstants.ROLE_DEPARTMENT_AGENT) {
      data.department_id = null;
    }
    if (role !== roleConstants.ROLE_ADMIN && role !== roleConstants.ROLE_OFFICER) {
      data.smev_enabled = 0;
      data.position = null;
    }
    if (users.currentItem && users.currentItem.id) {
      return dispatch(userActions.update(data));
    }
    return dispatch(userActions.create(data));
  };

  const handleModalClose = () => {
    dispatch(userActions.closeFormModal());
  };

  return {
    ...ownProps,
    users,
    handleSubmit,
    handleModalClose
  };
};

const connectedUserModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UserModal);

export { connectedUserModal as UserModal };
