import { controlPostConstants } from '../constants/controlPostConstants';
import { authConstants } from '../constants/authConstants';

const createItem = (items, item) => {
  items.unshift(item);
  return items;
};
const updateItem = (items, item) => {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== item.id) {
      result.push(items[i]);
    } else {
      result.push(item);
    }
  }
  return result;
};
const initialState = {
  showDeleteError: false,
  showFormModal: false,
  currentItem: {},
  meta: { per_page: 7, total: 0, current_page: 1 },
  items: []
};
const post_id = localStorage.getItem('post_id');
initialState.post_id = post_id;

export const controlPosts = (state = initialState, action) => {
  switch (action.type) {
    // Get page
    case controlPostConstants.GET_PAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case controlPostConstants.GET_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.controlPosts.data,
        meta: action.controlPosts.meta
      };
    case controlPostConstants.GET_PAGE_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Get all
    case controlPostConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case controlPostConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.controlPosts.data
      };
    case controlPostConstants.GET_ALL_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Create
    case controlPostConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: createItem(state.items, action.controlPost),
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        },
        showFormModal: false
      };

    // Update
    case controlPostConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: updateItem(state.items, action.controlPost),
        showFormModal: false
      };

    // Delete
    case controlPostConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(controlPost =>
          controlPost.id === action.id ? { ...controlPost, deleting: true } : controlPost
        )
      };
    case controlPostConstants.DELETE_SUCCESS:
      // remove deleted HeaderMenu from state
      return {
        ...state,
        loading: false,
        items: state.items.filter(controlPost => controlPost.id !== action.id),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    case controlPostConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        showDeleteError: true,
        items: state.items.map(controlPost => {
          if (controlPost.id === action.id) {
            // make copy of HeaderMenu without 'deleting:true' property
            const { deleting, ...controlPostCopy } = controlPost;
            // return copy of HeaderMenu with 'deleteError:[error]' property
            return { ...controlPostCopy, deleteError: action.error };
          }

          return controlPost;
        })
      };

    // Get item
    case controlPostConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case controlPostConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.controlPost.data
      };
    case controlPostConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // form modal
    case controlPostConstants.FORM_MODAL_OPEN:
      return {
        ...state,
        showFormModal: true
      };
    case controlPostConstants.FORM_MODAL_CLOSE:
      return {
        ...state,
        showFormModal: false
      };
    case controlPostConstants.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item
      };
    case controlPostConstants.CLOSE_ERROR_MODAL:
      return {
        ...state,
        showDeleteError: false
      };
    case controlPostConstants.CHOOSE_POST:
      return {
        ...state,
        post_id: action.id
      };
    case authConstants.LOGOUT:
      return {
        ...initialState,
        post_id: null
      };

    default:
      return state;
  }
};
