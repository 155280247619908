import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'privilege-vehicles';
};

const getVehicles = (status_id, values) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/page?privilege_status_id=${status_id}`,
    values,
    true
  );
};

const getAll = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}`, {}, true);
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};
const exportXls = (values, privilege_status_id) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/export/${privilege_status_id}`,
    values,
    true
  );
};

export const privilegeVehicleService = {
  getVehicles,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,
  exportXls
};
