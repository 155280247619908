import React from 'react';

import { AdminRoute } from '../../../components/routes/AdminRoute';
import CooperationListPage from './CooperationListPage';
import CooperationViewPage from './CooperationViewPage';

const CooperationAdminLayout = ({ match }) => (
  <React.Fragment>
    <AdminRoute exact path={`${match.url}/`} component={CooperationListPage} />
    <AdminRoute exact path={`${match.url}/view/:id`} component={CooperationViewPage} />
  </React.Fragment>
);

export default CooperationAdminLayout;
