import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import {Field, Form, Formik, validateYupSchema, yupToFormErrors} from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MateriaInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput'
import MaterialSelect from '../../admin/quickApplications/CreateQuickApplication/components/MaterialSelect'

import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import {paymentService} from "../../../services/paymentService";
import {apvgkReportsService} from "../../../services/apvgkReportsService";
import {apvgkReportsActions} from "../../../actions/apvgkReportsActions";
import {DeclineSchema} from "./util/Schema";

const RejectModal = ({isShow, onClose, model_id, classes, reasons, application_id, dispatch = null}) => {
    const [note, setNote] = useState('')
    return (
        <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth
                PaperProps={{ style: {overflow: 'visible'} }}>
            <Toolbar className="text-center">
                <Typography variant="title" color="inherit" className="flex">
                    {'Укажите причину отклонения'}
                </Typography>
                <IconButton color="inherit" onClick={onClose}>
                    <CloseIcon className="blue"/>
                </IconButton>
            </Toolbar>

            <DialogContent className="text-center" style={{overflow: 'visible'}}>
                <Formik
                    onSubmit={async (data, {setSubmitting}) => {
                        setSubmitting(true);
                        try {
                            await apvgkReportsService.decline({id: model_id, id_list:  data.note, application_id}).finally(() => dispatch &&
                                dispatch(apvgkReportsActions.clearItems()));
                            onClose();
                        } catch (e) {
                            if (e.response && e.response.data.errorMessage) alert(e.response.data.errorMessage)
                            else alert("Произошла ошибка при попытке отклонения")
                        }
                        setSubmitting(false);
                    }}
                    isInitialValid
                    initialValues={
                        {note}
                    }
                    validate={async values => {
                        return new Promise((resolve, reject) => {
                            validateYupSchema(values, DeclineSchema, false, { head: values }).then(
                                () => resolve({}),
                                err => reject(yupToFormErrors(err))
                            );
                        });
                    }}
                >
                    {({values, isValid, isSubmitting, setFieldValue}) => (
                        <Form>
                            <Grid container direction="column" spacing={16} wrap="nowrap" justify={"center"}>
                                <Grid item className={"text-left"}>
                                    <FieldLabel label="Причина*" name="note">
                                        <Field
                                            name="note"
                                            component={MaterialSelect}
                                            options={reasons.map((title, index) => ({
                                                value: index,
                                                label: title
                                            }))}
                                            placeholder="Выберите причину"
                                        >
                                        </Field>
                                    </FieldLabel>
                                </Grid>
                                <Grid item>
                                    <Button className="btn" disabled={isSubmitting || !isValid} type="submit">
                                        Отклонить
                                    </Button>
                                    <br/>
                                </Grid>
                            </Grid>

                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>

    )
}

export default RejectModal