import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { change, formValueSelector } from 'redux-form';
import { get } from 'lodash';
import { Loader } from '../../../../../components/Loader';
import { applicationActions } from '../../../../../actions/applicationActions';
import { dictionaryHelper } from '../../../../../helpers/dictionaryHelper';

class ApplicationMyVehiclesList extends Component {
  componentDidMount() {
    const { userApplications } = this.props;
    const { items } = userApplications.searchVehicles;

    if (!items || items.length === 0) {
      this.props.dispatch(applicationActions.getSearchVehiclesAll());
    }
  }

  render() {
    const {
      userApplications,
      searchText,
      chooseVehicle,
      isTrailer,
      vehicle,
      trailers
    } = this.props;
    const { vehicleLoading } = userApplications;
    if (vehicleLoading) {
      return (
        <div className="full-screen-loader-container">
          <Loader />
        </div>
      );
    }
    const { items } = userApplications.searchVehicles;
    const { trailerIndex } = userApplications.pickStep;

    const isSemitrailerException = item => {
      // check only if vehicle is set, and item is semitrailer
      if (
        vehicle &&
        dictionaryHelper.vehicleTypes.getSemiTrailerIds().indexOf(item.vehicle_type_id) !== -1
      ) {
        // if not just after vehicle or vehicle is not truck - except vehicle
        if (
          trailerIndex > 0 ||
          dictionaryHelper.vehicleTypes.getTractorIds().indexOf(vehicle.vehicle_type_id) === -1
        ) {
          return true;
        }
      }
      return false;
    };

    const isTractorException = item => {
      if (
        vehicle && // vehicle is set
        dictionaryHelper.vehicleTypes.getTractorIds().indexOf(vehicle.vehicle_type_id) !== -1 && // vehicle is tractor
        trailerIndex === 0 && // item - just after tractor
        dictionaryHelper.vehicleTypes.getSemiTrailerIds().indexOf(item.vehicle_type_id) === -1 // item - is not semitrailer
      ) {
        return true;
      }
      return false;
    };

    const isTrailerAlreadyUsed = item => {
      if (!trailers) {
        return false;
      }
      for (let i = 0; i < trailers.length; i++) {
        if (trailers[i] && trailers[i].id === item.id) {
          return true;
        }
      }
      return false;
    };

    const filterVehicles = () => {
      // Проверяем тип
      let filtered = items.filter(item => item.is_trailer === isTrailer);

      // Для полуприцепов проверяем, что полуприцеп только после тягача
      filtered = filtered.filter(item => {
        return !isSemitrailerException(item);
      });

      // Если 1 тягач, то только полуприцеп следом
      filtered = filtered.filter(item => {
        return !isTractorException(item);
      });

      // Проверяем, что прицеп не был использован
      filtered = filtered.filter(item => {
        return !isTrailerAlreadyUsed(item);
      });

      // Проверяем по полю с фильтром
      if (searchText && filtered) {
        const searchLower = searchText.toLowerCase();

        filtered = filtered.filter(item => {
          return (
            (item.real_number && item.real_number.toLowerCase().indexOf(searchLower) !== -1) ||
            (item.brandTitle && item.brandTitle.toLowerCase().indexOf(searchLower) !== -1) ||
            (item.modelTitle && item.modelTitle.toLowerCase().indexOf(searchLower) !== -1)
          );
        });
      }
      return filtered;
    };

    return (
      <Paper className="overflow">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Марка</TableCell>
              <TableCell>Модель</TableCell>
              <TableCell>ГРЗ</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell numeric>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterVehicles().map((n, index) => {
              return (
                <TableRow key={n.id + "" + index}>
                  <TableCell>{n.brandTitle}</TableCell>
                  <TableCell>{n.modelTitle}</TableCell>
                  <TableCell>{n.real_number}</TableCell>
                  <TableCell>{get(n, 'privilege_status_title') || 'Без особых статусов'}</TableCell>
                  <TableCell numeric>
                    <Button
                      variant="outlined"
                      className="info-btn"
                      size="small"
                      onClick={e => chooseVehicle(n)}
                    >
                      {n.deleting ? <Loader /> : <span>Выбрать</span>}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  const { userApplications } = state;

  const selector = formValueSelector('my-vehicle-search-form');
  const searchText = selector(state, 'search');

  const stepSelector = formValueSelector('application-form');
  const vehicle = stepSelector(state, 'vehicle');
  const trailers = stepSelector(state, 'trailers');
  return {
    userApplications,
    searchText,
    vehicle,
    trailers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { userApplications, searchText, vehicle, trailers } = stateProps;
  const { isTrailer, trailerIndex } = userApplications.pickStep;
  const { dispatch } = dispatchProps;
  const getPrivilegeStatus = (vehicle, trailers) => {
    const result = get(vehicle, 'privilege_status_id');
    if (trailers) {
      for (let i = 0; i < trailers.length; i++) {
        if (trailers[i] && trailers[i].id) {
          if (!get(vehicle, 'privilege_status_id')) {
            return null;
          }
        }
      }
    }

    return result || null;
  };

  const chooseVehicle = vehicle => {
    if (!isTrailer) {
      dispatch(change('application-form', 'vehicle', vehicle));
    } else {
      dispatch(change('application-form', `trailers[${trailerIndex}]`, vehicle));
    }

    dispatch(applicationActions.closeMyVehiclesModal());

    // check status
    const privilegeStatus = getPrivilegeStatus(vehicle, trailers);
    dispatch(
      change('application-form', 'privilege_status_id', privilegeStatus)
    );
    // dispatch(privilegeStatusActions.checkStatus(vehicle.real_number));
  };

  return {
    ...ownProps,
    dispatch,

    userApplications,
    searchText,

    isTrailer,
    vehicle,
    trailers,

    chooseVehicle
  };
};

const connectedApplicationMyVehiclesList = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationMyVehiclesList);
export { connectedApplicationMyVehiclesList as ApplicationMyVehiclesList };
