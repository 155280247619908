export const apvgkReportsConstants = {

  STATUS_NEW: 0,
  STATUS_IN_WORK: 1,
  STATUS_DECLINE: 2,
  STATUS_ACCEPT: 3,

  SET_TAB_INDEX: 'APVGK_REPORT_SET_TAB_INDEX',
  SET_ROLE_INDEX: 'APVGK_REPORT_SET_ROLE_INDEX',

  GET_BY_STATUSES_REQUEST: 'APVGK_REPORT_GET_BY_STATUSES_REQUEST',
  GET_BY_STATUSES_SUCCESS: 'APVGK_REPORT_GET_BY_STATUSES_SUCCESS',
  GET_BY_STATUSES_FAILURE: 'APVGK_REPORT_GET_BY_STATUSES_FAILURE',

  GET_PAGE_BY_STATUS_REQUEST: 'APVGK_REPORT_GET_PAGE_BY_STATUS_REQUEST',
  GET_PAGE_BY_STATUS_SUCCESS: 'APVGK_REPORT_GET_PAGE_BY_STATUS_SUCCESS',
  GET_PAGE_BY_STATUS_FAILURE: 'APVGK_REPORT_GET_PAGE_BY_STATUS_FAILURE',
    
  GET_ITEM_REQUEST: 'APVGK_REPORT_GET_ITEM_REQUEST',
  GET_ITEM_SUCCESS: 'APVGK_REPORT_GET_ITEM_SUCCESS',
  GET_ITEM_FAILURE: 'APVGK_REPORT_GET_ITEM_FAILURE',

  LOAD_REQUEST: 'APVGK_REPORT_LOAD_REQUEST',
  LOAD_SUCCESS: 'APVGK_REPORT_LOAD_SUCCESS',
  LOAD_FAILURE: 'APVGK_REPORT_LOAD_FAILURE',

  SET_STATUS_REQUEST: 'APVGK_REPORT_SET_STATUS_REQUEST',
  SET_STATUS_SUCCESS: 'APVGK_REPORT_SET_STATUS_SUCCESS',
  SET_STATUS_FAILURE: 'APVGK_REPORT_SET_STATUS_FAILURE',

  ADMIN_UPDATE_REQUEST: 'APVGK_REPORT_ADMIN_UPDATE_REQUEST',
  ADMIN_UPDATE_SUCCESS: 'APVGK_REPORT_ADMIN_UPDATE_SUCCESS',
  ADMIN_UPDATE_FAILURE: 'APVGK_REPORT_ADMIN_UPDATE_FAILURE',

  ACCEPT_REQUEST: 'APVGK_REPORT_ACCEPT_REQUEST',
  ACCEPT_SUCCESS: 'APVGK_REPORT_ACCEPT_SUCCESS',
  ACCEPT_FAILURE: 'APVGK_REPORT_ACCEPT_FAILURE',

  DECLINE_REQUEST: 'APVGK_REPORT_DECLINE_REQUEST',
  DECLINE_SUCCESS: 'APVGK_REPORT_DECLINE_SUCCESS',
  DECLINE_FAILURE: 'APVGK_REPORT_DECLINE_FAILURE',

  DECLINE_OPEN: 'APVGK_REPORT_DECLINE_OPEN',
  DECLINE_CLOSE: 'APVGK_REPORT_DECLINE_CLOSE',

  REASON_OPEN: 'APVGK_REPORT_REASON_OPEN',
  REASON_CLOSE: 'APVGK_REPORT_REASON_CLOSE',

  LOCK_REQUEST: 'APVGK_REPORT_LOCK_REQUEST',
  LOCK_SUCCESS: 'APVGK_REPORT_LOCK_SUCCESS',
  LOCK_FAILURE: 'APVGK_REPORT_LOCK_FAILURE',

  UNLOCK_REQUEST: 'APVGK_REPORT_UNLOCK_REQUEST',
  UNLOCK_SUCCESS: 'APVGK_REPORT_UNLOCK_SUCCESS',
  UNLOCK_FAILURE: 'APVGK_REPORT_UNLOCK_FAILURE',

  ADMIN_FILTER_REQUEST: 'APVGK_REPORT_ADMIN_FILTER_REQUEST',
  ADMIN_FILTER_SUCCESS: 'APVGK_REPORT_ADMIN_FILTER_SUCCESS',
  ADMIN_FILTER_FAILURE: 'APVGK_REPORT_ADMIN_FILTER_FAILURE',

  TO_WORK_REQUEST: 'APVGK_REPORT_TO_WORK_REQUEST',
  TO_WORK_SUCCESS: 'APVGK_REPORT_TO_WORK_SUCCESS',
  TO_WORK_FAILURE: 'APVGK_REPORT_TO_WORK_FAILURE',

  TO_WORK_MULTIPLE_REQUEST: 'APVGK_REPORT_TO_WORK_MULTIPLE_REQUEST',
  TO_WORK_MULTIPLE_SUCCESS: 'APVGK_REPORT_TO_WORK_MULTIPLE_SUCCESS',
  TO_WORK_MULTIPLE_FAILURE: 'APVGK_REPORT_TO_WORK_MULTIPLE_FAILURE',

  FILTER_MODAL_OPEN: 'APVGK_REPORT_FILTER_FORM_MODAL_OPEN',
  FILTER_MODAL_CLOSE: 'APVGK_REPORT_FILTER_FORM_MODAL_CLOSE',

    DELETE_REQUEST: 'APVGK_REPORT_DELETE_REQUEST',
    DELETE_SUCCESS: 'APVGK_REPORT_DELETE_SUCCESS',
    DELETE_FAILURE: 'APVGK_REPORT_DELETE_FAILURE',
    SET_CURRENT_ITEM: 'SET_CURRENT_ITEM',
    GET_REASONS_SUCCESS: "GET_REASONS_SUCCESS",
    GET_REASONS_FAILURE: "GET_REASONS_FAILURE",

};
