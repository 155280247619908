export const privilegeVehicleConstants = {
  GET_VEHICLES: 'PRIVILEGE_VEHICLE_GET_VEHICLES_REQUEST',
  GET_VEHICLES_SUCCESS: 'PRIVILEGE_VEHICLE_GET_VEHICLES_SUCCESS',
  GET_VEHICLES_FAILURE: 'PRIVILEGE_VEHICLE_GET_VEHICLES_FAILURE',

  GET_ALL_REQUEST: 'PRIVILEGE_VEHICLE_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'PRIVILEGE_VEHICLE_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'PRIVILEGE_VEHICLE_GET_ALL_FAILURE',

  LOAD_REQUEST: 'PRIVILEGE_VEHICLE_LOAD_REQUEST',
  LOAD_SUCCESS: 'PRIVILEGE_VEHICLE_LOAD_SUCCESS',
  LOAD_FAILURE: 'PRIVILEGE_VEHICLE_LOAD_FAILURE',

  CREATE_REQUEST: 'PRIVILEGE_VEHICLE_CREATE_REQUEST',
  CREATE_SUCCESS: 'PRIVILEGE_VEHICLE_CREATE_SUCCESS',
  CREATE_FAILURE: 'PRIVILEGE_VEHICLE_CREATE_FAILURE',

  UPDATE_REQUEST: 'PRIVILEGE_VEHICLE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'PRIVILEGE_VEHICLE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'PRIVILEGE_VEHICLE_UPDATE_FAILURE',

  DELETE_REQUEST: 'PRIVILEGE_VEHICLE_DELETE_REQUEST',
  DELETE_SUCCESS: 'PRIVILEGE_VEHICLE_DELETE_SUCCESS',
  DELETE_FAILURE: 'PRIVILEGE_VEHICLE_DELETE_FAILURE',

  FORM_MODAL_OPEN: 'PRIVILEGE_VEHICLE_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'PRIVILEGE_VEHICLE_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'PRIVILEGE_VEHICLE_SET_CURRENT_ITEM',

  EXPORT_UPLOAD_REQUEST: 'PRIVILEGE_VEHICLE_EXPORT_UPLOAD_REQUEST',
  EXPORT_UPLOAD_SUCCESS: 'PRIVILEGE_VEHICLE_EXPORT_UPLOAD_SUCCESS',
  EXPORT_UPLOAD_FAILURE: 'PRIVILEGE_VEHICLE_EXPORT_UPLOAD_FAILURE'
};
