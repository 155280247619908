import { authConstants } from '../constants/authConstants';
import {apvgkReportsConstants} from "../constants/apvgkReportsConstants";

const findItem = (items, id) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      return items[i];
    }
  }
  return null;
};

const removeItem = (items, id) => {
  return items.filter(item => item.id !== id);
};

const initialState = {
  tabIndex: apvgkReportsConstants.STATUS_NEW,
  tabStatus: apvgkReportsConstants.STATUS_NEW,
  tabs: [
    [{}], // individual and firm data for status = new
    [{}],
    [{}],
    [{}]
  ],
  meta: {},
  items: [],
  filter:{
    id: '',
    IDBetamount: '',
    PlatformId: '',
    TrackStateNumber: '',
    user:'',
    reject_reason: null,
    min_date: '',
    max_date: '',
    max_speed: '',
    page:1,
    type: Number.parseInt(localStorage.getItem('report_type') ? localStorage.getItem('report_type') : 0),
    per_page: 10,
  },
  currentItem: null,
  statusLoading: false,
  reasons: [],
  showFilterModal: false,
};


export const apvgkReports = (state = initialState, action) => {
  switch (action.type) {
      case apvgkReportsConstants.SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex,
        tabStatus: action.status,
      };


      case apvgkReportsConstants.GET_PAGE_BY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        filter: {
            ...initialState.filter,
            ...action.values
        }
      };
    case apvgkReportsConstants.GET_PAGE_BY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.apvgkReports.data,
        meta: action.apvgkReports.meta
      };
    case apvgkReportsConstants.GET_PAGE_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить список актов!'
      };


    // Get item
    case apvgkReportsConstants.GET_ITEM_REQUEST:
      return {
        ...state,
        currentItem: null,
        loading: true,
      };
    case apvgkReportsConstants.GET_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.data
      };
    case apvgkReportsConstants.GET_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    case apvgkReportsConstants.ACCEPT_REQUEST:
      return {
        ...state,
        statusLoading: true,
        routeSaved: false
      };
      case apvgkReportsConstants.ACCEPT_SUCCESS:
      return {
        ...state,
        editMap: false,
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
          ...action.apvgkReports,
        },
      };
    case apvgkReportsConstants.ACCEPT_FAILURE:
      return {
        ...state,
        statusLoading: false
      };
    case apvgkReportsConstants.ACTIVATE_REQUEST:
      return {
        ...state,
        statusLoading: true
      };
      case apvgkReportsConstants.ACTIVATE_SUCCESS:
      return {
        ...state,
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
            ...action.apvgkReports,
        },
        showActivateModal: false
      };
    case apvgkReportsConstants.ACTIVATE_FAILURE:
      return {
        ...state,
        statusLoading: false
      };
    case apvgkReportsConstants.DECLINE_REQUEST:
      return {
        ...state,
        statusLoading: true
      };
      case apvgkReportsConstants.DECLINE_SUCCESS:
      return {
        ...state,
        statusLoading: false,
        showDeclineModal: false,
        currentItem: {
          ...state.currentItem,
          ...action.apvgkReports,
        }
      };
    case apvgkReportsConstants.DECLINE_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    case apvgkReportsConstants.RESTORE_REQUEST:
      return {
        ...state,
        statusLoading: true
      };
    case apvgkReportsConstants.RESTORE_SUCCESS:
      return {
        ...state,
        statusLoading: false
      };
    case apvgkReportsConstants.RESTORE_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    // decline modal
    case apvgkReportsConstants.DECLINE_CURRENT_ITEM_OPEN:
      return {
        ...state,
        showDeclineModal: true
      };
    case apvgkReportsConstants.DECLINE_OPEN:
      return {
        ...state,
        showDeclineModal: true,
        currentItem:
          state.currentItem && action.id === state.currentItem.id
            ? state.currentItem
            : findItem(state.items, action.id)
      };
    case apvgkReportsConstants.DECLINE_CLOSE:
      return {
        ...state,
        showDeclineModal: false
      };




    case apvgkReportsConstants.TO_WORK_MULTIPLE_REQUEST:
    case apvgkReportsConstants.TO_WORK_REQUEST:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            item.loading = true;
          }
          return item;
        })
      };

    case apvgkReportsConstants.TO_WORK_MULTIPLE_SUCCESS:
    case apvgkReportsConstants.TO_WORK_SUCCESS:
    case apvgkReportsConstants.TO_WORK_MULTIPLE_FAILURE:
    case apvgkReportsConstants.TO_WORK_FAILURE:
      return {
        ...state,
        statusLoading: false
      };
    case apvgkReportsConstants.LOCK_REQUEST:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            item.loading = true;
          }
          return item;
        })
      };
    case apvgkReportsConstants.LOCK_SUCCESS:
      return {
        ...state,
        items: state.items.map(item =>
          action.apvgkReports.id === item.id
            ? {
                ...item,
                loading: false,
                is_locked: action.apvgkReports.is_locked
              }
            : item
        ),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,

          is_locked: action.apvgkReports.is_locked
        }
      };
    case apvgkReportsConstants.LOCK_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    case apvgkReportsConstants.UNLOCK_REQUEST:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            item.loading = true;
          }
          return item;
        })
      };
    case apvgkReportsConstants.UNLOCK_SUCCESS:
      return {
        ...state,
        items: state.items.map(item =>
          action.apvgkReports.id === item.id
            ? {
                ...item,
                is_locked: action.apvgkReports.is_locked,
                loading: false
              }
            : item
        ),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
          is_locked: action.apvgkReports.is_locked
        }
      };
    case apvgkReportsConstants.UNLOCK_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    // filter modal
    case apvgkReportsConstants.FILTER_MODAL_OPEN:
      return {
        ...state,
        showFilterModal: true
      };
    case apvgkReportsConstants.FILTER_MODAL_CLOSE:
      return {
        ...state,
        showFilterModal: false
      };
      case apvgkReportsConstants.GET_REASONS_SUCCESS:
        console.log(action)
        return {
            ...state,
            reasons: action.data,
        }
    case authConstants.LOGOUT:
      return initialState;

    case apvgkReportsConstants.SET_CURRENT_ITEM:
        return {
            ...state,
            currentItem: action.item
        };

    default:
      return state;
  }
};
