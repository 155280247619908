import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';
import { CooperationStatus } from '../../../../../constants/cooperationConstants';
import { getFile } from '../../../../../helpers/fileUploadHelper';
import { Loader } from '../../../../../components';
import { fileService } from '../../../../../services/fileService';

async function processFile(fileEvent, handleFileAdd, enqueueSnackbar, application) {
  try {
    const file = await getFile(fileEvent);
    const result = await fileService.uploadDocumentFile(application.id, file.plain);
    handleFileAdd(result.data);
    enqueueSnackbar(`Файл "${file.name}" успешно загружен! `, {
      variant: 'success'
    });
  } catch (e) {
    enqueueSnackbar(`Ошибка при загрузке файла`);
    console.log(e);
  }
}

const CooperationFiles = ({
  application,
  files,
  handleFileRemove,
  handleFileAdd,
  enqueueSnackbar
}) => {
  const [isUploading, setUploading] = React.useState(false);
  const uploadFile = async event => {
    setUploading(true);
    try {
      await Promise.all(
        Array.from(event.target.files).map(eventFile =>
          processFile(eventFile, handleFileAdd, enqueueSnackbar, application)
        )
      );
    } catch (e) {
      enqueueSnackbar(`Ошибка при загрузке файлов`, {
        variant: 'error'
      });
      console.log(e);
    }
    setUploading(false);
  };
  const onFileRemove = async file => {
    try {
      await fileService.removeDocumentFile(file);
      enqueueSnackbar(`Файл ${file.name} удален!`, {
        variant: 'success'
      });
      handleFileRemove(file);
    } catch (e) {
      enqueueSnackbar('Ошибка при удалении файла!', {
        variant: 'error'
      });
    }
  };
  if (application.status === CooperationStatus.New) {
    return null;
  }
  const isEditable = [1].includes(application.status);
  return (
    <>
      <Grid item>
        <h2 className="h3-title">Прикрепляемые Документы</h2>
      </Grid>
      {files.length === 0 && (
        <Grid item>
          <div>Нет прикрепленных документов</div>
        </Grid>
      )}
      <Grid item>
        {files.map(file => (
          <div key={file.id}>
            <a target="_blank" href={file.url ? file.url : ''}>
              {file.name ? file.name : ''}
            </a>
            <IconButton color="inherit" onClick={() => onFileRemove(file)}>
              <CloseIcon />
            </IconButton>
          </div>
        ))}
      </Grid>
      {isEditable && (
        <Grid item>
          <input
            className="file_input"
            id="cooperation-upload-button"
            type="file"
            onChange={uploadFile}
            multiple
          />
          <label className="file_btn" htmlFor="cooperation-upload-button">
            <Button
              variant="contained"
              component="span"
              className="btn"
              disabled={isUploading ? 'disabled' : undefined}
            >
              {isUploading && <Loader size={24} />}
              &nbsp; Прикрепить Документ
            </Button>
          </label>
        </Grid>
      )}
    </>
  );
};

export default withSnackbar(CooperationFiles);
