import React from 'react';
import { connect } from 'react-redux';
import { CabinetMainMenuLink } from './CabinetMainMenuLink';
import mainLogo from '../../../main/blocks/MainMenu/img/logo.png';
import { authActions } from '../../../../actions/authActions';
import { history } from '../../../../helpers/history';
import { urlHelper } from '../../../../helpers/urlHelper';

const CabinetMainMenu = props => {
  const { user, logoutUser } = props;

  let homePath = '';
  if (user) {
    homePath = urlHelper.getHomeUrl(user.role_id);
  }
  const links = [
    {
      key: '1',
      path: homePath,
      title: 'АИС КТГ',
      img: mainLogo,
      imgClass: 'logo'
    },
    {
      key: '2',
      path: homePath,
      title: 'АИС КТГ',
      menuClass: 'logo-title-inner'
    }
  ];

  const rightLinks = [
    {
      key: '1',
      path: urlHelper.getHomeUrl(user.role_id),
      title: user.name,
      menuClass: 'user_name'
    }
  ];

  return (
    <header>
      <nav className="navbar navbar-expand-md">
        <div className="row">
          <div className="col-md-8">
            <ul className="navbar-nav mr-auto">
              {links.map(link => (
                <CabinetMainMenuLink {...link} />
              ))}
            </ul>
          </div>
          <div className="col-md-4  menu-right">
            <ul className="navbar-nav mr-auto">
              {rightLinks.map(link => (
                <CabinetMainMenuLink {...link} />
              ))}
              <li key="3" className="nav-link cursor" onClick={logoutUser}>
                Выйти
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = state => {
  const { user } = state.authentication;
  return {
    user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: () => {
      history.push('/');
      dispatch(authActions.logout());
    }
  };
}

const connectedCabinetMainMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(CabinetMainMenu);
export { connectedCabinetMainMenu as CabinetMainMenu };
