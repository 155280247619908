import React, { useEffect } from 'react';
import { Polygon } from 'react-leaflet';
import { applicationService } from '../../services/applicationService';

const RestrictedArea = () => {
  const [polygons, setPolygons] = React.useState([]);
  useEffect(() => {
    async function loadAreas() {
      const areas = await applicationService.getRestrictedAreas();
      setPolygons(areas);
    }
    loadAreas();
  }, []);
  if (polygons.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      {polygons.map(r => (
        <Polygon positions={r.coordinates} key={r.id} fillColor="black" color="#ff6961" />
      ))}
    </React.Fragment>
  );
};

export default RestrictedArea;
