import React from 'react';
import ApplicationSectionTitle from './ApplicationSectionTitle';
import ShowByStatus from './ShowByStatus';
import { applicationConstants } from '../../../constants/applicationConstants';
import ApplicationField from './ApplicationField';
import { momentHelper } from '../../../helpers/momentHelper';

const ApplicationAcceptance = ({ admin, acceptDate }) => {
  if (!admin) {
    return null;
  }
  return (
    <ShowByStatus
      allowedStatuses={[
        applicationConstants.STATUS_ACCEPT_WITH_CHANGES,
        applicationConstants.STATUS_ACCEPT,
        applicationConstants.STATUS_ACTIVE
      ]}
    >
      <ApplicationSectionTitle>Разрешение выдано</ApplicationSectionTitle>
      <ApplicationField value={admin.name} label="Сотрудник, одобривший разрешение" />
      <ApplicationField value={momentHelper.changeYmdHisTodmY(acceptDate)} label="Дата одобрения" />
    </ShowByStatus>
  );
};

export default ApplicationAcceptance;
