import { specialConditionService } from '../services/specialConditionService';
import { specialConditionConstants } from '../constants/specialConditionConstants';

const getItem = id => {
  const request = id => {
    return { type: specialConditionConstants.LOAD_REQUEST, id };
  };

  const success = specialCondition => {
    return { type: specialConditionConstants.LOAD_SUCCESS, specialCondition };
  };

  const failure = error => {
    return { type: specialConditionConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    specialConditionService
      .getItem({ id })
      .then(
        specialCondition => dispatch(success(specialCondition)),
        error => dispatch(failure(error))
      );
  };
};

const setItem = values => {
  const request = values => {
    return { type: specialConditionConstants.SET_REQUEST, values };
  };

  const success = specialCondition => {
    return { type: specialConditionConstants.SET_SUCCESS, specialCondition };
  };

  const failure = error => {
    return { type: specialConditionConstants.SET_FAILURE, values, error };
  };

  return dispatch => {
    dispatch(request(values));

    return specialConditionService
      .setItem(values)
      .then(
        specialCondition => dispatch(success(specialCondition)),
        error => dispatch(failure(error))
      );
  };
};

const setTab = tabIndex => {
  return { type: specialConditionConstants.SET_TAB_INDEX, tabIndex };
};

export const specialConditionActions = {
  getItem,
  setItem,
  setTab
};
