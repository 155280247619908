import { vehicleModelConstants } from '../constants/vehicleModelConstants';
import { authConstants } from '../constants/authConstants';

const createItem = (items, item) => {
  items.unshift(item);
  return items;
};
const updateItem = (items, item) => {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== item.id) {
      result.push(items[i]);
    } else {
      result.push(item);
    }
  }
  return result;
};

const initialState = {
  showDeleteError: false,
  isTrailer: 0,
  showFormModal: false,
  currentItem: {},
  selectItems: [],
  meta: { per_page: 7, total: 0, current_page: 1 },
  items: [],
  filters: {},
  sort: {},
};

export const vehicleModels = (state = initialState, action) => {
  switch (action.type) {
    // Get all
    case vehicleModelConstants.GET_ALL_REQUEST:
      return {
        ...state,
        fetched: true,
        success: false,
      };
    case vehicleModelConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        selectItems: action.vehicleModels.data,
        fetched: true
      };
    case vehicleModelConstants.GET_ALL_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!',
        fetched: true
      };

    // Get page
    case vehicleModelConstants.GET_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        filters: action.filters,
        sort: action.sort,
      };
    case vehicleModelConstants.GET_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.vehicleModels.data,
        meta: action.vehicleModels.meta
      };
    case vehicleModelConstants.GET_PAGE_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Get item
    case vehicleModelConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case vehicleModelConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.vehicleModel.data
      };
    case vehicleModelConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // Create
    case vehicleModelConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: createItem(state.items, action.vehicleModel),
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        },
        showFormModal: false
      };

    // Update
    case vehicleModelConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: updateItem(state.items, action.vehicleModel),
        showFormModal: false
      };

    // Delete
    case vehicleModelConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(vehicleModel =>
          vehicleModel.id === action.id ? { ...vehicleModel, deleting: true } : vehicleModel
        )
      };
    case vehicleModelConstants.DELETE_SUCCESS:
      // remove deleted HeaderMenu from state
      return {
        ...state,
        loading: false,
        items: state.items.filter(vehicleModel => vehicleModel.id !== action.id)
      };
    case vehicleModelConstants.DELETE_FAILURE:
      return {
        ...state,
        showDeleteError: true,
        loading: false,
        items: state.items.map(vehicleModel => {
          if (vehicleModel.id === action.id) {
            const { deleting, ...vehicleModelCopy } = vehicleModel;
            return { ...vehicleModelCopy, deleteError: action.error };
          }

          return vehicleModel;
        })
      };

    // accept
    case vehicleModelConstants.ACCEPT_REQUEST:
      return {
        ...state,
        items: state.items.map(vehicleModel =>
          vehicleModel.id === action.id ? { ...vehicleModel, accepting: true } : vehicleModel
        )
      };
    case vehicleModelConstants.ACCEPT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.map(vehicleModel =>
          vehicleModel.id === action.id ? { ...vehicleModel, accepted: 1 } : vehicleModel
        )
      };
    case vehicleModelConstants.ACCEPT_FAILURE:
      return {
        ...state,
        loading: false,
        items: state.items.map(vehicleModel => {
          if (vehicleModel.id === action.id) {
            const { accepting, ...vehicleModelCopy } = vehicleModel;
            return { ...vehicleModelCopy, accepteError: action.error };
          }

          return vehicleModel;
        })
      };

    case vehicleModelConstants.HIDE_REQUEST:
      return {
        ...state,
        items: state.items.map(vehicleModel =>
          vehicleModel.id === action.id ? { ...vehicleModel, accepting: true } : vehicleModel
        )
      };
    case vehicleModelConstants.HIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter(vehicleModel => vehicleModel.id !== action.id)
      };
    case vehicleModelConstants.HIDE_FAILURE:
      return {
        ...state,
        loading: false,
        items: state.items.map(vehicleModel => {
          if (vehicleModel.id === action.id) {
            const { accepting, ...vehicleModelCopy } = vehicleModel;
            return { ...vehicleModelCopy, accepteError: action.error };
          }

          return vehicleModel;
        })
      };

    // change list
    case vehicleModelConstants.SET_IS_TRAILER:
      return {
        ...state,
        // currentItem: {
        //     ...state.currentItem,
        //     is_trailer: action.isTrailer,
        // },
        isTrailer: action.isTrailer
      };
    // form modal
    case vehicleModelConstants.FORM_MODAL_OPEN:
      return {
        ...state,
        showFormModal: true
      };
    case vehicleModelConstants.FORM_MODAL_CLOSE:
      return {
        ...state,
        showFormModal: false
      };
    case vehicleModelConstants.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item
      };

    case vehicleModelConstants.BRAND_LIST_LOAD_SUCCESS:
      return {
        ...state,
        initialState: {
          ...state.initialState,
          brand_id: action.vehicleBrands ? action.vehicleBrands[0].id : ''
        }
      };

    case vehicleModelConstants.CLOSE_ERROR_MODAL:
      return {
        ...state,
        showDeleteError: false
      };

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
