import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import MaterialInput from '../quickApplications/CreateQuickApplication/components/MaterialInput';
import FieldLabel from '../reports/components/FieldLabel';
import SelectAll from '../../../components/form/SelectAll';
import {
  CooperationFilterStatuses,
  CooperationRequestStatus,
  CooperationStatus
} from '../../../constants/cooperationConstants';

const Filter = ({ tab, currentFilters, onFiltersChange }) => {
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const onCancel = () => {
    onFiltersChange({});
    handleClose();
  };

  const statusOptions = CooperationFilterStatuses.map(k => ({
    value: k,
    label: CooperationRequestStatus[k]
  }));

  return (
    <div>
      <Button
        className="no-margin info-btn"
        variant="outlined"
        color="primary"
        type="button"
        onClick={handleClickOpen}
      >
        Фильтровать
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Toolbar>
          <Typography variant="title" color="inherit" className="flex">
            Фильтровать
          </Typography>
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon className="blue" />
          </IconButton>
        </Toolbar>
        <DialogContent>
          <Formik
            initialValues={currentFilters}
            onSubmit={values => {
              onFiltersChange(values);
              handleClose();
            }}
            validateOnChange={false}
            validateOnBlur={false}
            render={({ values, setFieldValue }) => (
              <Form>
                <Grid container direction="column" spacing={8} wrap="nowrap">
                  <Grid item>
                    <FieldLabel label="По номеру запроса">
                      <Field name="id" component={MaterialInput} placeholder="№ запроса" />
                    </FieldLabel>
                  </Grid>
                  {tab === CooperationStatus.Completed && (
                    <Grid item>
                      <FieldLabel label="По статус запроса">
                        <Field
                          placeholder="Выберите статус(ы)"
                          name="statuses"
                          component={SelectAll}
                          allowSelectAll
                          value={values.statuses || []}
                          options={statusOptions}
                          allOption={{ value: 0, label: 'Все статусы' }}
                          onChange={value => setFieldValue('statuses', value)}
                          menuPlacement="auto"
                          isMulti
                          closeMenuOnSelect={false}
                        />
                      </FieldLabel>
                    </Grid>
                  )}
                  <Grid item>
                    <FieldLabel label="По № документа, присвоенный заявилетелем" name="fio">
                      <Field
                        name="output_number"
                        component={MaterialInput}
                        placeholder="№ документа"
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel label="По наименованию заявителя (органа, направившего заявку)">
                      <Field
                        name="authority_name"
                        component={MaterialInput}
                        placeholder="Наименование заявителя"
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel label="По наименованию организации владельца ТС">
                      <Field
                        name="name"
                        component={MaterialInput}
                        placeholder="Наименование организации"
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel label="По ГРЗ ТС">
                      <Field name="number" component={MaterialInput} placeholder="ГРЗ ТС" />
                    </FieldLabel>
                  </Grid>
                  {/* <Grid item>
                    <MaterialCheckbox
                      label="Отображать только просроченные"
                      field={{
                        name: 'overdue',
                        value: !!values.overdue,
                        onChange: e => {
                          setFieldValue(
                            'overdue',
                            e.target.checked ? moment().format('DD.MM.YYYY') : null
                          );
                        }
                      }}
                    />
                  </Grid> */}
                  {tab !== CooperationStatus.New && (
                    <Grid item>
                      <FieldLabel label="По сотруднику">
                        <Field
                          name="admin_name"
                          component={MaterialInput}
                          placeholder="Сотрудник"
                        />
                      </FieldLabel>
                    </Grid>
                  )}
                  <Grid item>
                    <FieldLabel label="По периоду">
                      <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                          <Field
                            name="min_date"
                            component={MaterialInput}
                            placeholder="Начало периода"
                            type="date"
                          />
                        </Grid>
                        <Grid item>
                          <span>—</span>
                        </Grid>
                        <Grid item>
                          <Field
                            name="max_date"
                            component={MaterialInput}
                            placeholder="Конец периода"
                            type="date"
                          />
                        </Grid>
                      </Grid>
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className="btn-add no-margin"
                      type="submit"
                    >
                      Применить
                    </Button>
                    <Button
                      variant="outlined"
                      component="span"
                      className="info-btn"
                      onClick={onCancel}
                    >
                      Очистить
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Filter;
