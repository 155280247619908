export const vehicleModelConstants = {
  GET_PAGE_REQUEST: 'VEHICLE_MODEL_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'VEHICLE_MODEL_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'VEHICLE_MODEL_GET_PAGE_FAILURE',

  GET_ALL_REQUEST: 'VEHICLE_MODEL_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'VEHICLE_MODEL_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'VEHICLE_MODEL_GET_ALL_FAILURE',

  LOAD_REQUEST: 'VEHICLE_MODEL_LOAD_REQUEST',
  LOAD_SUCCESS: 'VEHICLE_MODEL_LOAD_SUCCESS',
  LOAD_FAILURE: 'VEHICLE_MODEL_LOAD_FAILURE',

  CREATE_REQUEST: 'VEHICLE_MODEL_CREATE_REQUEST',
  CREATE_SUCCESS: 'VEHICLE_MODEL_CREATE_SUCCESS',
  CREATE_FAILURE: 'VEHICLE_MODEL_CREATE_FAILURE',

  UPDATE_REQUEST: 'VEHICLE_MODEL_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'VEHICLE_MODEL_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'VEHICLE_MODEL_UPDATE_FAILURE',

  DELETE_REQUEST: 'VEHICLE_MODEL_DELETE_REQUEST',
  DELETE_SUCCESS: 'VEHICLE_MODEL_DELETE_SUCCESS',
  DELETE_FAILURE: 'VEHICLE_MODEL_DELETE_FAILURE',

  ACCEPT_REQUEST: 'VEHICLE_MODEL_ACCEPT_REQUEST',
  ACCEPT_SUCCESS: 'VEHICLE_MODEL_ACCEPT_SUCCESS',
  ACCEPT_FAILURE: 'VEHICLE_MODEL_ACCEPT_FAILURE',

  HIDE_REQUEST: 'VEHICLE_MODEL_HIDE_REQUEST',
  HIDE_SUCCESS: 'VEHICLE_MODEL_HIDE_SUCCESS',
  HIDE_FAILURE: 'VEHICLE_MODEL_HIDE_FAILURE',

  SET_IS_TRAILER: 'VEHICLE_MODEL_SET_IS_TRAILER',
  SET_BRAND_ID: 'VEHICLE_MODEL_SET_BRAND_ID',
  SET_VEHICLE_TYPE_ID: 'VEHICLE_MODEL_VEHICLE_TYPE_ID',

  FORM_MODAL_OPEN: 'VEHICLE_MODEL_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'VEHICLE_MODEL_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'VEHICLE_MODEL_SET_CURRENT_ITEM',

  BRAND_LIST_LOAD_SUCCESS: 'VEHICLE_MODEL_BRAND_LIST_LOAD_SUCCESS',

  CLOSE_ERROR_MODAL: 'VEHICLE_MODEL_CLOSE_ERROR_MODAL'
};
