import React from 'react';
import { connect } from 'react-redux';
import Select from './Creatable';
import {
  getIsVehicleBrandsLoading,
  getVehicleBrands,
  getVehicleBrandsError,
  getVehicleBrandsFetched
} from '../../../../../selectors/vehicleBrandSelectors';
import { vehicleBrandActions } from '../../../../../actions/vehicleBrandActions';

const SelectBrand = props => {
  return <Select noOptions="Марка не найдена" placeholder="Выбрать Марку ТС" {...props} />;
};

const mapStateToProps = state => ({
  data: getVehicleBrands(state),
  isLoading: getIsVehicleBrandsLoading(state),
  error: getVehicleBrandsError(state),
  fetched: getVehicleBrandsFetched(state),
});

const mapDispatchToProps = {
  fetch: vehicleBrandActions.getAll
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectBrand);
