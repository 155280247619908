import React from 'react';
import { connect } from 'react-redux';
import RouteContent from './content/RouteContent';
import { MapContainer } from '../../../components/map/MapContainer';
import { FormField } from '../../../components/form/FormField';
import {FieldArray} from 'redux-form';
import ApvgkPlatformsFieldComponent from "../panel/status/ApvgkPlatformsFieldComponent";
import {applicationConstants} from "../../../constants/applicationConstants";
import { get, range } from 'lodash';

function round(distance) {
  return Math.round(distance * 100) / 100;
}

const ApplicationRoute = ({
  application,
  routeMarkers,
  isRouteEditable,
  user,
  handleRemoveMarker,
  setMarkerValue,
  errors,
  updateError,
  editMap,
  adminRoute,
  dispatch
}) => {
  return (
    <div
      className="map row"
      style={{
        display: application.privilege_status_id && !(application.privilegeStatus.coefficient > 0)? 'none' : 'flex'
      }}
    >
      <div className="col-md-12">
        <MapContainer
          markers={routeMarkers.map(marker => ({
            id: marker.id,
            coords: {
              lat: marker.lat,
              lon: marker.lon
            },
            text: marker.text
          }))}
          editable={isRouteEditable}
          editMap={editMap}
        />
      </div>
      <div className="col-md-12">
        <h2 className="h2-title mt-4 margin-bottom-10">Маршрут</h2>
        <div className="points_area">
          <div className="points_div">
            <div className="points_line" />
          </div>
          {routeMarkers && (
            <RouteContent
              markers={routeMarkers}
              isRouteEditable={isRouteEditable && editMap}
              userRole={user.role_id}
              removeMarker={handleRemoveMarker}
              setMarkerValue={setMarkerValue}
              errors={errors}
              updateError={updateError}
            />
          )}
        </div>
      </div>
      <div className="col-md-12">
        {get(application.route || {}, 'apvgks') && application.route.apvgks.length > 0 &&
          <FieldArray name="apvgk_list" component={ApvgkPlatformsFieldComponent}
              apvgkList={adminRoute.apvgks}
              disableActions={true}
              handleChange={(value) => {
                  dispatch({type: applicationConstants.CHANGE_APVGKS_VALUE, value})
              }}
              enableDirection={isRouteEditable && editMap}
          />}
      </div>
      <div className="col-md-10">
        {application.route && application.route.distance_info ? (
          <div>
            <FormField
              className="form-control"
              disabled="disabled"
              label="Общая протяженность маршрута"
              input={{
                value: `${application.route ? round(application.route.distance) : 0} км`
              }}
              meta={{}}
            />
            <FormField
              className="form-control"
              disabled="disabled"
              label="Протяженность дорог с нормативной нагрузкой 11,5 т/ось"
              input={{
                value: `${application.route ? round(application.route.distance_info[1]) : 0} км`
              }}
              meta={{}}
            />
            <FormField
              className="form-control"
              disabled="disabled"
              label="Протяженность дорог с нормативной нагрузкой 10 т/ось"
              input={{
                value: `${application.route ? round(application.route.distance_info[0]) : 0}км`
              }}
              meta={{}}
            />
            <FormField
              className="form-control"
              disabled="disabled"
              label="Протяженность дорог с нормативной нагрузкой 6 т/ось"
              input={{
                value: `${application.route ? round(application.route.distance_info[2]) : 0} км`
              }}
              meta={{}}
            />
          </div>
        ) : (
          <FormField
            className="form-control"
            disabled="disabled"
            label="Движение по региональным дорогам"
            input={{ value: `${application.route ? application.route.distance : 0} км` }}
            meta={{}}
          />
        )}

      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  editMap: state.adminApplications.editMap,
  adminRoute:state.adminApplications.adminRoute,
});

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationRoute);
