import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VpnKey from '@material-ui/icons/VpnKey';

class MuiPasswordField extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      showPassword: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  render() {
    const {
      showPasswordVisible,
      icon,
      input,
      label,
      className,
      meta: { touched, error },
      ...custom
    } = this.props;
    return (
      <TextField
        type={this.state.showPassword ? 'text' : 'password'}
        placeholder={label}
        className={className}
        helperText={touched && error}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{icon === 'password' && <VpnKey />}</InputAdornment>
          ),
          endAdornment: showPasswordVisible ? (
            <InputAdornment position="end">
              <IconButton aria-label="Toggle password visibility" onClick={() => this.toggle()}>
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : (
            ''
          ),
          disableUnderline: true
        }}
        {...input}
        {...custom}
      />
    );
  }
}

export default MuiPasswordField;
