import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import {renderSelectFieldNoLabel} from '../../../components/form/renderSelectFieldNoLabel';
import {Loader} from '../../../components/Loader';
import {controlPostActions} from '../../../actions/controlPostActions';
import {apvgkService} from "../../../services/apvgkService";
import {renderSelectField} from "../../../components/form/renderSelectField";
import {FormField} from "../../../components/form/FormField";
import {fieldValidators} from "../../../helpers/fieldValidators";

// let ApvgkConnectForm = (props) => {
class ApvgkConnectForm extends Component {
    state = {
        apvgks: [],
    };

    componentDidMount() {
        const {controlPosts, fetchApvgk, dispatch} = this.props;
        fetchApvgk().then(r => {
            this.setState({...this.state, apvgks: r.data || []})
        });
        if (!controlPosts.items || controlPosts.items.length === 0) {
            dispatch(controlPostActions.getAll());
        }
    }

    render() {
        const {controlPosts, handleSubmit, submitting, error} = this.props;
        return (
            <div>
                <form onSubmit={handleSubmit}>
                    <Field
                        className="form-control"
                        name="control_post_id"
                        disabled={!!localStorage.getItem('post_id')}
                        label="Контрольный пост"
                        component={renderSelectField}
                        validate={[fieldValidators.required]}
                    >
                        <option key="0" value="">
                            Выберите пост
                        </option>
                        {controlPosts.items
                            ? controlPosts.items.map(option => (
                                <option key={option.id} value={option.id}>
                                    {option.title} {option.equipment_seria_number}
                                </option>
                            ))
                            : ''}
                    </Field>
                    <Field
                        className="form-control"
                        name="apvgk_id"
                        label="Пост АПВГК"
                        component={renderSelectField}
                        validate={[fieldValidators.required]}
                    >
                        <option key="0" value="">
                            Выберите АПВГК
                        </option>
                        {this.state.apvgks
                            ? this.state.apvgks.map(option => (
                                <option key={option.id} value={option.id}>
                                    {option.name + ' ' + option.equipment_seria_number}
                                </option>
                            ))
                            : ''}
                    </Field>
                    {/*<Field*/}
                    {/*    className="form-control"*/}
                    {/*    name="end_date"*/}
                    {/*    label="Дата завершения смены"*/}
                    {/*    type="date"*/}
                    {/*    component={FormField}*/}
                    {/*    validate={[fieldValidators.required]}*/}
                    {/*/>*/}
                    <Field
                        className="form-control"
                        name="distance"
                        label="Расстояние до АПВГК"
                        type="number"
                        component={FormField}
                        validate={[fieldValidators.required]}
                    />
                    <Field
                        className="form-control"
                        name="end_time"
                        label="Время завершения смены"
                        type="time"
                        component={FormField}
                        validate={[fieldValidators.required]}
                    />
                    <Field
                        className="form-control"
                        name="apvgk_direction"
                        label="Камера направлена на рамку"
                        type="checkbox"
                        component={FormField}
                    />
                    <Field
                        className="form-control"
                        name="place_before_apvgk"
                        label="Камера расположена к 0 км от АПВГК"
                        type="checkbox"
                        component={FormField}
                    />
                    <br/>
                    {error
                        ? <>
                            {error}
                            </>
                        : <Button
                            variant="contained"
                            color="primary"
                            className="login"
                            type="submit"
                            disabled={submitting}
                        >
                            {submitting && <Loader/>} Начать
                    </Button>}
                    <br/>
                    <br/>
                </form>
            </div>
        );
    }
}

ApvgkConnectForm = reduxForm({
    form: 'apvgk-pick-form',
    enableReinitialize: true
})(ApvgkConnectForm);

const mapStateToProps = state => {
    const {controlPosts} = state;

    return {
        controlPosts,
        initialValues: {
            control_post_id: localStorage.getItem('post_id'),
            apvgk_id: null,
            // end_date: null,
            end_time: null,
            apvgk_direction: false,
            place_before_apvgk: false,
            distance: null,
        }
    };
};
const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        fetchApvgk: () => apvgkService.getControlPage(1, 10000),
    };
};

ApvgkConnectForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(ApvgkConnectForm);

export default ApvgkConnectForm;
