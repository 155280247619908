import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';

import FieldLabel from '../../components/FieldLabel';
import Input, { Textarea } from '../../components/Input';
import { Truncate } from '../../../../../components';
import RouteMap from '../../components/RouteMap';
import { mapProcessing } from '../../util/mapping';
import { CooperationRequestStatusValue } from '../../../../../constants/cooperationConstants';
import AgreementsForm from '../../components/AgreementsForm';
import Damage from '../../components/Damage';
import Activate from './Activate';

const styles = {
  declineReason: {
    border: '1px solid #d9001b',
    borderRadius: 5,
    padding: '10px 20px'
  }
};

function roundPrice(price) {
  return Math.round(price * 100) / 100;
}

const RequestProcessing = ({
  application: { status, ...application },
  onActivate,
  withoutMarks = false
}) => {
  const cooperation = mapProcessing({ ...application, status });
  const isAccepted = [3, 4, 5, 6, 7, 8].includes(status);
  const isDeclined = status === CooperationRequestStatusValue.Declined;

  return (
    <>
      {!isDeclined && (
        <>
          <Grid item>
            <Grid container spacing={16}>
              <Grid item xs={4}>
                <FieldLabel label="Согласуемый маршрут">
                  <div className="privilege_status">
                    <Truncate maxCount={500}>{cooperation.routeString}</Truncate>
                  </div>
                </FieldLabel>
              </Grid>
              <Grid item xs={8}>
                <RouteMap points={cooperation.points} showLines />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Общая протяженность"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={`${cooperation.commonDistance} км`} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Протяженность дорог с нормативной нагрузкой 11,5 т/ось"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={`${roundPrice(cooperation.federalDistance)} км`} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Протяженность дорог с нормативной нагрузкой 10 т/ось"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={`${roundPrice(cooperation.regionalDistance)} км`} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Протяженность дорог с нормативной нагрузкой 6 т/ось"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={`${roundPrice(cooperation.municipalDistance)} км`} />
            </FieldLabel>
          </Grid>

          <Damage
            priceInfo={cooperation.priceInfo}
            isSpring={cooperation.isSpring}
            hideRegular={cooperation.isSpring}
          />
          <Grid item>
            <FieldLabel
              label="Исходящий № согласования"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={cooperation.number} />
            </FieldLabel>
          </Grid>
        </>
      )}
      {isAccepted && (
        <Grid item>
          <FieldLabel
            label="Особые условия движения"
            labelProps={{ xs: 12, sm: 4, lg: 3 }}
            childrenProps={{ xs: 12, sm: 8, lg: 5 }}
          >
            <Textarea value={cooperation.specialConditions} />
          </FieldLabel>
        </Grid>
      )}
      <Grid item>
        <FieldLabel
          label="Сотрудник"
          labelProps={{ xs: 12, sm: 4, lg: 3 }}
          childrenProps={{ xs: 12, sm: 8, lg: 5 }}
        >
          <Input value={cooperation.acceptedBy} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Должность сотрудника"
          labelProps={{ xs: 12, sm: 4, lg: 3 }}
          childrenProps={{ xs: 12, sm: 8, lg: 5 }}
        >
          <Input value={cooperation.acceptedByPosition} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Дата ответа"
          labelProps={{ xs: 4, lg: 3 }}
          childrenProps={{ xs: 8, lg: 2 }}
        >
          <Input value={cooperation.responseDate} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Комментарий"
          labelProps={{ xs: 12, sm: 4, lg: 3 }}
          childrenProps={{ xs: 12, sm: 8, lg: 5 }}
        >
          <Input value={cooperation.comment} />
        </FieldLabel>
      </Grid>
      <AgreementsForm
        processing={[]}
        agreements={application.application_agreements}
        changesDisabled
      />
      <Grid item>
        <h2 className="h3-title">Документы</h2>
      </Grid>
      {cooperation.invoice && (
        <Grid item>
          <a target="_blank" href={cooperation.invoice}>
            Счет на оплату
          </a>
        </Grid>
      )}
      {cooperation.filesInWork.map(file => (
        <Grid item key={file.url}>
          <a href={file.url}>{file.name}</a>
        </Grid>
      ))}
      {cooperation.filesInWork.length === 0 && !cooperation.invoice && (
        <Grid item>Нет прикрепленных документов</Grid>
      )}
      {isAccepted && !withoutMarks && cooperation.marks.length > 0 && (
        <>
          <Grid item>
            <h2 className="h3-title">Отметки о проезде через ПВК</h2>
          </Grid>
          <Grid item>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>№ отметки</TableCell>
                  <TableCell>дата и время отметки</TableCell>
                  <TableCell>Наименование ПВК</TableCell>
                  <TableCell>ФИО сотрудника ПВК</TableCell>
                  <TableCell>Направление</TableCell>
                  <TableCell>Учитывается в проездах</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cooperation.marks.map((m, i) => (
                  <TableRow>
                    <TableCell>{i + 1}</TableCell>
                    <TableCell>{m.date}</TableCell>
                    <TableCell>{m.name}</TableCell>
                    <TableCell>{m.gdrName}</TableCell>
                    <TableCell>{m.direction}</TableCell>
                    <TableCell>{m.active ? 'Да' : 'Нет'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </>
      )}
      {status === 4 && onActivate && (
        <Grid item>
          <Activate onActivate={onActivate} />
        </Grid>
      )}
    </>
  );
};

export default withStyles(styles)(RequestProcessing);
