import React from 'react';
import { change, Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormField } from '../../../../../components/form/FormField';
import { fieldValidators } from '../../../../../helpers/fieldValidators';
import { Loader } from '../../../../../components/Loader';
import { vehicleBrandActions } from '../../../../../actions/vehicleBrandActions';
import { renderSelectField } from '../../../../../components/form/renderSelectField';
import { dictionaryHelper } from '../../../../../helpers/dictionaryHelper';
import { vehicleModelActions } from '../../../../../actions/vehicleModelActions';

let VehicleModelsForm = props => {
  const {
    error,
    handleSubmit,
    submitting,
    currentItem,
    vehicleBrands,
    vehicleModels,
    initialValues,
    handleIsTrailerChange,
    handleVehicleTypeChange
  } = props;

  const tsTypes = dictionaryHelper.vehicleTypes.getList(0);
  const trailerTypes = dictionaryHelper.vehicleTypes.getList(1);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        className="form-control"
        name="is_trailer"
        component={renderSelectField}
        label="Вид транспорта"
        validate={[fieldValidators.required]}
        onChange={handleIsTrailerChange}
      >
        {dictionaryHelper.isTrailer.getList().map(option => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </Field>

      <Field
        className="form-control"
        name="brand_id"
        component={renderSelectField}
        label="Марка"
        validate={[fieldValidators.required]}
      >
        <option key="0" value="0">
          Выберите марку
        </option>
        {vehicleBrands.items
          ? vehicleBrands.selectItems.map(option => (
              <option key={option.id} value={option.id}>
                {option.title}
              </option>
            ))
          : ''}
      </Field>

      <Field
        name="title"
        type="text"
        label="Модель"
        validate={[fieldValidators.required]}
        component={FormField}
      />

      <Field
        className="form-control"
        name="vehicle_type_id"
        component={renderSelectField}
        label="Тип"
        validate={[fieldValidators.required]}
        onChange={handleVehicleTypeChange}
      >
        {vehicleModels.isTrailer
          ? trailerTypes.map(option => (
              <option key={option.id} value={option.id}>
                {option.title}
              </option>
            ))
          : tsTypes.map(option => (
              <option key={option.id} value={option.id}>
                {option.title}
              </option>
            ))}
      </Field>

      <Field
        name="size"
        type="text"
        label="Габариты"
        placeholder="длина*ширина*высота"
        validate={[fieldValidators.required]}
        component={FormField}
      />

      <Field
        name="axle_count"
        type="text"
        label="Количество осей"
        validate={[fieldValidators.required]}
        component={FormField}
        disabled
      />

      <Field
        name="axles_distance"
        type="text"
        label="Расстояние между осями"
        placeholder="1140,1200"
        validate={[fieldValidators.required]}
        component={FormField}
      />

      <Field
        name="axles_wheel_count"
        type="text"
        label="Скатность колес"
        placeholder="1,2"
        validate={[fieldValidators.required]}
        component={FormField}
      />
      {/* <Field */}
      {/* name="hidden" */}
      {/* type="checkbox" */}
      {/* label="Скрыть из вывода при создании заявки на тс" */}
      {/* className="checkbox" */}
      {/* component={MuiCheckbox} */}
      {/* /> */}

      {error && <strong>{error}</strong>}
      <div>
        {submitting && <Loader />}
        <button type="submit" className="btn" disabled={submitting}>
          {currentItem && currentItem.id ? 'Редактировать' : 'Создать'}
        </button>
      </div>
    </form>
  );
};

VehicleModelsForm = reduxForm({
  form: 'vehicle-model-form', // a unique identifier for this form
  enableReinitialize: true
})(VehicleModelsForm);

const mapStateToProps = state => {
  const { vehicleBrands, vehicleModels } = state;
  const { currentItem } = vehicleModels;

  return {
    vehicleBrands,
    vehicleModels,
    initialValues: {
      id: currentItem ? currentItem.id : '',
      title: currentItem ? currentItem.title : '',
      brand_id: currentItem ? currentItem.brand_id : '',
      hidden: currentItem ? currentItem.hidden : '',
      is_trailer: currentItem && currentItem.is_trailer ? '1' : '0',
      vehicle_type_id: currentItem ? currentItem.vehicle_type_id : 1,
      axle_count: currentItem ? currentItem.axle_count : 2,
      size: currentItem ? `${currentItem.length}*${currentItem.width}*${currentItem.height}` : '',
      axles_distance: currentItem ? currentItem.axles_distance : '',
      axles_wheel_count: currentItem ? currentItem.axles_wheel_count : ''
    }
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(vehicleBrandActions.getAll());

  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { vehicleBrands, vehicleModels, initialValues } = stateProps;

  const { dispatch } = dispatchProps;

  const handleIsTrailerChange = (event, value) => {
    dispatch(vehicleModelActions.setIsTrailer(parseInt(value)));
  };
  const handleVehicleTypeChange = (event, value) => {
    const axleCount = dictionaryHelper.vehicleTypes.getItemAxleCount(value);

    if (axleCount) {
      dispatch(change('vehicle-model-form', 'axle_count', axleCount));
    }
  };

  return {
    ...ownProps,
    vehicleBrands,
    vehicleModels,
    initialValues,
    handleIsTrailerChange,
    handleVehicleTypeChange
  };
};

VehicleModelsForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VehicleModelsForm);
export default VehicleModelsForm;
