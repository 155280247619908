const AXLE_TYPE_SPRING = 0;
const AXLE_TYPE_PNEUMATIC = 1;

function getDistance(distance) {
  if (distance <= 1) {
    return 0;
  }
  if (distance > 1 && distance <= 1.3) {
    return 1;
  }
  if (distance > 1.3 && distance <= 1.8) {
    return 2;
  }
  if (distance > 1.8 && distance <= 2.5) {
    return 3;
  }
  return 4;
}

const calculateAxlesAllowedWeights = (wheelCounts, distances, axleTypes, wheels, axleLoads) => {
  const result = [];

  const groups = splitAxlesToGroups(wheelCounts, distances);

  for (let i = 0; i < groups.length; i++) {
    const groupWeight = calculateGroupAllowedWeight(
      groups[i],
      wheelCounts,
      distances,
      axleTypes,
      wheels,
      axleLoads
    );
    for (let j = 0; j < groups[i].length; j++) {
      result.push(groupWeight);
    }
  }

  return result;
};

function normalizeParams(axleCount, distanceId, wheelCount, wheels, axleType) {
  let normalizedAxleCount = axleCount;
  let normalizedDistanceId = distanceId;
  let normalizedWheelCount = wheelCount;
  let normalizedWheels = wheels;
  let normalizedAxleType = axleType;
  if (normalizedAxleCount === 1) {
    normalizedDistanceId = 4;
    if (normalizedAxleCount > 2) {
      normalizedWheelCount = 2;
    }
    normalizedWheels = 1;
  }
  if (normalizedAxleCount > 4) {
    normalizedAxleCount = 4;
  }
  // axle type fix
  if (normalizedAxleCount === 3 && normalizedWheelCount === 1 && normalizedDistanceId === 2) {
    // do nothing
  } else {
    // set default value
    normalizedAxleType = AXLE_TYPE_SPRING;
  }

  // wheels count fix
  if (normalizedWheels <= 4) {
    normalizedWheels = 1;
  } else {
    normalizedWheels = 5;
  }

  // wheels fix
  if (normalizedAxleCount > 1 && normalizedWheels === 5) {
    normalizedAxleCount = 2;
    normalizedWheelCount = 1;
    normalizedAxleType = AXLE_TYPE_SPRING;
  }
  return [
    normalizedAxleCount,
    normalizedDistanceId,
    normalizedWheelCount,
    normalizedWheels,
    normalizedAxleType
  ];
}

const calculateGroupAllowedWeight = (
  group,
  wheelCounts,
  distances,
  axleTypes,
  wheels,
  axleLoads
) => {
  const groupWheelCount = calculateGroupWheelCount(group, wheelCounts);
  const groupAxleType = calculateGroupAxleType(group, axleTypes);

  const groupOverageDistance = calculateGroupOverageDistance(group, distances);
  const groupWheels = calculateGroupWheels(group, wheels);
  const [
    normalizedAxleCount,
    normalizedDistanceId,
    normalizedWheelCount,
    normalizedWheels,
    normalizedAxleType
  ] = normalizeParams(
    group.length,
    getDistance(groupOverageDistance),
    groupWheelCount,
    groupWheels,
    groupAxleType
  );
  const axles = axleLoads.filter(
    load =>
      load.wheel_count === normalizedWheelCount && load.group_axles_count === normalizedAxleCount
  );

  const filteredAxles =
    axles.length === 1
      ? axles
      : axles.filter(
          a => normalizedDistanceId === a.distance_index && normalizedWheels === a.wheels
        );

  const axle =
    filteredAxles.length === 1
      ? filteredAxles[0]
      : filteredAxles.find(a => a.axle_type === normalizedAxleType);

  return axle ? axle.value : '';
};

const calculateGroupWheelCount = (group, wheelCounts) => {
  for (let i = 0; i < group.length; i++) {
    const wheelCount = wheelCounts[group[i]];
    if (wheelCount === 1) {
        return 1;
    }
  }

  return 2;
};

const calculateGroupWheels = (group, wheels) => {
  const maximum = 8;
  const minimum = 1;

  let max = minimum;
  for (let i = 0; i < group.length; i++) {
    const wheel = wheels[group[i]];
    if (wheel > max) {
      max = wheel;
    }
  }

  // check min, max
  return Math.max(minimum, Math.min(maximum, max));
};

const calculateGroupAxleType = (group, axleTypes) => {
  let result = AXLE_TYPE_SPRING;

  for (let i = 0; i < group.length; i++) {
    const axleType = axleTypes[group[i]];
    if (axleType !== AXLE_TYPE_SPRING) {
      result = AXLE_TYPE_PNEUMATIC;
    }
  }

  return result;
};

const calculateGroupOverageDistance = (group, distances) => {
  if (group.length === 1) {
    return distances[group[0]];
  }

  let sum = 0;
  for (let i = 0; i < group.length - 1; i++) {
    const distance = distances[group[i]];
    sum += distance;
  }

  return Math.max(0, sum / (group.length - 1));
};

const splitAxlesToGroups = (wheelCounts, distances) => {
  const GROUP_MAX_DISTANCE = 2.5;

  const groups = [];
  let currentGroup = 0;

  groups[0] = [0];
  for (let i = 0; i < distances.length; i++) {
    // if distance > max distance - start new group
    if (distances[i] > GROUP_MAX_DISTANCE) {
      currentGroup++;
      groups[currentGroup] = [];
    }

    // add next axle to group
    groups[currentGroup].push(i + 1);
  }

  return groups;
};

const calculateAxlesAllowedWeightsBoth = (wheelCounts, distances, axleTypes, wheels, loadAxles) => {
  const mainLoadAxles = loadAxles.filter(l => !l.is_spring);
  const springLoadAxles = loadAxles.filter(l => l.is_spring);
  return [mainLoadAxles, springLoadAxles].map(a =>
    calculateAxlesAllowedWeights(wheelCounts, distances, axleTypes, wheels, a)
  );
};

export const axlesCalculator = {
  calculateAxlesAllowedWeights,
  calculateAxlesAllowedWeightsBoth
};
