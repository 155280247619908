import { chain, get } from 'lodash';

import { applicationConstants } from '../../../../constants/applicationConstants';
import { roleConstants } from '../../../../constants/roleConstants';
import { formatHelper } from '../../../../helpers/formatHelper';
import { dictionaryHelper } from '../../../../helpers/dictionaryHelper';
import { momentHelper } from '../../../../helpers/momentHelper';

const diffMoreThan3BussinessDays = events => {
  const date = get(events, 'APPLICANT_APP_TOREVIEW.created_at');
  return date ? momentHelper.diffMoreThanNBussinessDays(date, 3) : true;
};

const getUserName = application => {
  if (application.is_smev === 1) {
    return application.username;
  }
  return get(application, 'user.name') || get(application, 'fast.name');
};

const newTabData = (application, events, event) => {
  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    createdAt: get(events, `${event}.created_at`, application.updated_at),
    userName: getUserName(application),
    issuePlace: application.dates
      ? dictionaryHelper.issuePlaces.getItemTitle(application.dates.issue_place_id)
      : ''
  };
};

const defaultData = (application, events, event) => {
  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    createdAt: get(events, `${event}.created_at`, application.updated_at),
    userName: getUserName(application),
    eventUser: get(events, `${event}.user_name`)
  };
};

const getAgreementEvents = (application, departmentId) => {
  const agreement = application.application_agreements.find(a => a.department_id === departmentId);
  return get(agreement, 'events', {});
};

function mapNew(application, user) {
  if (user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT) {
    return newTabData(
      application,
      getAgreementEvents(application, user.department_id),
      'ADMINGBU_APP_TODEPARTMENT'
    );
  }

  return {
    ...newTabData(application, application.events || {}, 'APPLICANT_APP_TOREVIEW'),
    timePassed: diffMoreThan3BussinessDays(application.events)
  };
}

function mapReview(application, user) {
  if (user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT) {
    return defaultData(
      application,
      getAgreementEvents(application, user.department_id),
      'DEPAGENT_APP_TOWORK'
    );
  }
  return {
    ...defaultData(application, application.events || {}, 'ADMINGBU_APP_TOWORK'),
    timePassed: diffMoreThan3BussinessDays(application.events),
    agreements: application.application_agreements.map(a => ({
      title: a.department.short_title,
      status: dictionaryHelper.getAgreementStatus(a.status)
    }))
  };
}

function mapDecline(application, user) {
  if (user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT) {
    const agreementEvents = getAgreementEvents(application, user.department_id);
    return defaultData(application, agreementEvents, 'DEPAGENT_APP_DECLINE');
  }
  const events = application.events || {};
  const applicationAgreements = application.application_agreements || [];
  const declinedEvents = applicationAgreements.map(
    agreement => agreement.events.DEPAGENT_APP_DECLINE
  );
  const { created_at, user_name } =
    chain([events.ADMINGBU_APP_DECLINE, ...declinedEvents])
      .orderBy(['created_at', 'desc'])
      .head()
      .value() || {};

  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    createdAt: created_at || application.updated_at,
    userName: getUserName(application),
    eventUser: user_name
  };
}

function mapAcceptWithChanges(application) {
  return {
    ...defaultData(application, application.events || {}, 'ADMINGBU_APP_TOWORK'),
    timePassed: diffMoreThan3BussinessDays(application.events),
    agreements: application.application_agreements.map(a => ({
      title: a.department.short_title,
      status: dictionaryHelper.getAgreementStatus(a.status)
    }))
  };
}

function mapAccept(application, user) {
  if (user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT) {
    return defaultData(
      application,
      getAgreementEvents(application, user.department_id),
      'DEPAGENT_APP_ACCEPT'
    );
  }
  return {
    ...defaultData(application, application.events || {}, 'ADMINGBU_APP_ACCEPT'),
    payFile: get(application, 'files.pay_files[0].url')
  };
}

function mapActive(application) {
  const events = application.events || {};
  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    formNumber: application.form_id,
    createdAt: get(events, 'ADMINGBU_APP_ACTIVATE.created_at', application.updated_at),
    userName: getUserName(application),
    eventUser: get(events, 'ADMINGBU_APP_ACTIVATE.user_name'),
    runsLeft: application.dates.runs_count - application.dates.runs_used,
    runsTotal: application.dates.runs_count
  };
}

function mapComplete(application) {
  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    userName: getUserName(application),
    runsLeft: application.dates.runs_count - application.dates.runs_used,
    runsTotal: application.dates.runs_count
  };
}

function mapRemoved(application) {
  const events = application.events || {};
  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    createdAt: get(events, 'APPLICANT_APP_SOFT_DELETE.created_at', application.updated_at),
    userName: get(application, 'user.name') || get(application, 'fast.name')
  };
}

const mapItem = {
  [applicationConstants.STATUS_NEW]: mapNew,
  [applicationConstants.STATUS_REVIEW]: mapReview,
  [applicationConstants.STATUS_DECLINE]: mapDecline,
  [applicationConstants.STATUS_ACCEPT_WITH_CHANGES]: mapAcceptWithChanges,
  [applicationConstants.STATUS_ACCEPT]: mapAccept,
  [applicationConstants.STATUS_ACTIVE]: mapActive,
  [applicationConstants.STATUS_COMPLETE]: mapComplete,
  [applicationConstants.STATUS_REMOVED]: mapRemoved
};

export function getApplicationViewData(application, tab, user) {
  return mapItem[tab](application, user);
}
