import React from 'react';

export const FormField = ({
  disabled,
  input,
  label,
  className,
  placeholder,
  type,
  link,
  meta: { touched, error }
}) => {
  const inputField = (
    <input
      disabled={disabled}
      className={`form-control ${className}${touched && error ? ' form-control-error' : ''}`}
      {...input}
      placeholder={placeholder}
      type={type}
    />
  );
  return (
    <div className={`form-group${touched && error ? ' has-error' : ''}`}>
      <div className="row">
        <div className="col-md-4">
          <p className="label-form">{label}</p>
        </div>
        <div className="col-md-8 input-link-container">
          {link && <a href={link} target="_blank" className="input-link" />}
          {inputField}
          {touched && (error && <span>{error}</span>)}
        </div>
      </div>
    </div>
  );
};
