import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select/creatable';
import { isEqual, get } from 'lodash';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Creatable = ({
  field,
  form,
  isLoading,
  data,
  fetch,
  fetched,
  error,
  noOptions,
  placeholder,
  fetchProps = [],
  afterSelect = () => null,
  filter = () => true,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');
  const prevValues = usePrevious({ fetchProps });
  const prevProps = prevValues ? prevValues.fetchProps : [];
  useEffect(() => {
    if ((!fetched && !isLoading) || !isEqual(prevProps, fetchProps)) {
      fetch(...fetchProps);
    }
  }, [fetchProps]);
  useEffect(() => setInputValue(get(field, 'value.title', '')), [field.value]);
  const handleChange = newValue => {
    form.setFieldValue(field.name, newValue);
    afterSelect(data.find(d => d.id === newValue) || { id: -1, value: newValue });
  };
  const fieldValue = field.value && data && data.find(d => d.id === field.value);
  return (
    <Select
      {...field}
      inputValue={inputValue}
      onInputChange={v => setInputValue(v)}
      onBlur={e => {
        const { value } = e.target;
        form.setFieldTouched(form.name);
        // не костыль, а костылище.. но по-другому не работает
        setTimeout(() => {
          if (value) {
            handleChange(value);
            setInputValue(inputValue);
          }
        }, 0);
      }}
      value={fieldValue ? { value: field.value, label: fieldValue.title } : undefined}
      placeholder={placeholder}
      noOptionsMessage={() => noOptions}
      options={data.filter(filter).map(b => ({ label: b.title, value: b.id }))}
      onChange={({ value }) => handleChange(value)}
      formatCreateLabel={label => `Создать "${label}"`}
      isSearchable
      {...props}
    />
  );
};

export default Creatable;
