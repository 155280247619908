import React from 'react';

const AgreementPage = props => {
  return (
    <main role="main" className="container text-white text-left">
      <div className="kyc">
        <h1 className="welcome">О системе</h1>

        <div className="about_system">
          <p>
            Для удобства пользователей и упрощения процедуры оформления грузоперевозок в Республике
            Татарстан функционирует автоматизированная информационная система «Портал выдачи
            специальных разрешений и согласований на движение по региональным автомобильным дорогам
            Республики Татарстан тяжеловесного и (или) крупногабаритного транспортного средства»
            (далее ‒ АИС КТГ).{' '}
          </p>
          <p>
            АИС КТГ позволяет:
            <br />
            - зарегистрировать в личном кабинете физическое или юридическое лицо и принадлежащие ему
            транспортные средства;
            <br />
            - подать заявление на получение специального разрешения на движение тяжеловесного и
            (или) крупногабаритного транспортного средства;
            <br />
            - получить специальное разрешение в электронном эквиваленте;
            <br />
            - разработать и согласовать маршрут движения транспортного средства.
            <br />
          </p>
        </div>
      </div>
    </main>
  );
};

export default AgreementPage;
