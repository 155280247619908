import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { formatHelper } from '../../../../helpers/formatHelper';

class DeclineReasonDialog extends React.Component {
  state = {
    show: false
  };

  toggleDialog = () => {
    this.setState(state => ({ show: !state.show }));
  };

  render() {
    const { show } = this.state;
    const { item } = this.props;
    return (
      <React.Fragment>
        <Dialog onClose={this.toggleDialog} aria-labelledby="simple-dialog-title" open={show}>
          <DialogTitle id="simple-dialog-title">
            Причина отклонения для №{formatHelper.appNumber(item)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{item.comment || 'Причина не указана'}</DialogContentText>
          </DialogContent>
        </Dialog>
        <Button variant="text" className="info-btn" onClick={this.toggleDialog}>
          Причина отклонения
        </Button>
      </React.Fragment>
    );
  }
}

export default DeclineReasonDialog