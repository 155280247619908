import React from 'react';
import { get, uniqueId } from 'lodash';
import Select from 'react-select';
import { FieldArray } from 'formik';
import StartPointIcon from '@material-ui/icons/FiberManualRecord';
import PointIcon from '@material-ui/icons/Adjust';
import EndPointIcon from '@material-ui/icons/Room';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Close';
import { Button, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { applicationService } from '../../../../../services/applicationService';
import LocationAutosuggest from '../../../quickApplications/CreateQuickApplication/components/LocationAutosuggest';

const getStepByCoords = async latlng => {
  if (!latlng) {
    return {
      id: uniqueId(),
      isEmpty: false
    };
  }
  const { lat, lng } = latlng;
  const response = await applicationService.getCoordsLocation(lat, lng);
  const { data } = response;
  return {
    id: uniqueId(),
    isEmpty: false,
    text: data.text,
    coords: latlng
  };
};

const SinglePath = ({ values, arrayHelpers, name, onChange, removeStep, index, options }) => {
  const { steps, useSaved, saved, id } = get(values, name);
  const routeOptions = React.useMemo(
    () =>
      options.map(option => ({
        value: option,
        label: option.text
      })),
    [options]
  );

  const onRemove = ind => () => {
    if (steps.length > 2) {
      arrayHelpers.remove(ind);
    } else {
      arrayHelpers.replace(ind, { text: '' });
    }
  };

  const replace = (ind, step) => {
    arrayHelpers.replace(ind, step);
  };

  const onAddBtn = async () => {
    const step = await getStepByCoords();
    arrayHelpers.push(step);
  };

  return (
    <Grid container direction="column" spacing={8} wrap="nowrap">
      <Grid item>
        <Grid container spacing={16}>
          <Grid item>
            <h4 className="h4-title">Отрезок {index + 1}</h4>
          </Grid>
          <Grid item>
            {index > 0 && (
              <Button className="info-btn no-margin" onClick={removeStep}>
                Удалить отрезок
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <RadioGroup
          value={String(useSaved)}
          onChange={(event, value) => onChange({ useSaved: value === 'true' })}
        >
          <FormControlLabel
            className="radio"
            value="false"
            control={<Radio />}
            label=" добавить новый"
          />
          {!useSaved && (
            <>
              <Grid item>
                <Grid container direction="column" spacing={8} className="points_area">
                  <div className="points_div points_line-map">
                    <div className="points_line" />
                  </div>
                  {steps.map((step, ind) => (
                    <React.Fragment>
                      <Grid item>
                        <Grid container spacing={8} alignItems="center" justify="space-between">
                          <Grid
                            item
                            className="map-point"
                            container
                            alignItems="center"
                            justify="flex-start"
                            sm={1}
                          >
                            {ind === 0 && <StartPointIcon />}
                            {ind > 0 && ind < steps.length - 1 && <PointIcon />}
                            {ind === steps.length - 1 && <EndPointIcon className="red" />}
                          </Grid>
                          <Grid item sm={10}>
                            <LocationAutosuggest
                              onTouched={() => null}
                              onBlur={text =>
                                replace(ind, {
                                  ...steps[ind],
                                  text,
                                  isEmpty: false
                                })
                              }
                              value={steps[ind]}
                              onChange={newLocation =>
                                replace(ind, {
                                  ...steps[ind],
                                  ...newLocation,
                                  isEmpty: false
                                })
                              }
                            />
                          </Grid>
                          <Grid item sm={1}>
                            <IconButton aria-label="Delete" size="small" onClick={onRemove(ind)}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Button className="info-btn no-margin" onClick={onAddBtn}>
                  + Добавить пункт назначения
                </Button>
              </Grid>
            </>
          )}
          <FormControlLabel
            className="radio"
            value="true"
            control={<Radio />}
            label="выбрать из списка"
          />
        </RadioGroup>
      </Grid>
      {useSaved && (
        <Grid item style={{ zIndex: 1010 - index }}>
          <Select
            className="react-select-container_multiline"
            classNamePrefix="react-select_multiline"
            name="tripType"
            placeholder="Маршрут"
            value={routeOptions.find(option => id && option.value.id === id)}
            options={routeOptions}
            onChange={value => onChange({ saved: value })}
          />
        </Grid>
      )}
    </Grid>
  );
};

const SinglePathContainer = ({ name, ...props }) => (
  <FieldArray
    name={`${name}.steps`}
    render={arrayHelpers => <SinglePath name={name} arrayHelpers={arrayHelpers} {...props} />}
  />
);

export default SinglePathContainer;
