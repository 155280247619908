import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {reduxForm} from 'redux-form';
import {withSnackbar} from 'notistack';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import TabContainer from './TabContainer'
import {apvgkReportsConstants} from "../../../constants/apvgkReportsConstants";
import ReasonModal from './ReasonModal'
import {apvgkReportsService} from "../../../services/apvgkReportsService";
import {roleConstants} from "../../../constants/roleConstants";
import {Check as CheckIcon, Close as CloseIcon} from '@material-ui/icons';
import {isObject} from "formik";
import {isArray} from "leaflet/src/core/Util";
import GrzCheckStatusIcon from "./GrzCheckStatusIcon";


let WorkReportsTab = props => {
    const {
        classes,
        apvgkReports,
        paginateProps,
        authentication,
    } = props;

    const { user } = authentication;
    return (
        <TabContainer paginateProps={paginateProps}>
            {(items, onItem) => (
                <React.Fragment>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID акта</TableCell>
                            <TableCell>Дата создания акта</TableCell>
                            <TableCell>Наименование АПВГК</TableCell>
                            <TableCell>ГРЗ ТС</TableCell>
                            <TableCell>№</TableCell>
                            <TableCell>Скорость</TableCell>
                            <TableCell>{
                                apvgkReports.tabStatus === apvgkReportsConstants.STATUS_IN_WORK
                                    ? 'Дата взятия акта в работу'
                                    : (apvgkReports.tabStatus === apvgkReportsConstants.STATUS_ACCEPT
                                        ? 'Дата одобрения акта'
                                        : 'Дата отклонения акта')
                            }</TableCell>
                            <TableCell>{
                                apvgkReports.tabStatus === apvgkReportsConstants.STATUS_IN_WORK
                                    ? 'Сотрудник взявший акт в работу'
                                    : (apvgkReports.tabStatus === apvgkReportsConstants.STATUS_ACCEPT
                                        ? 'Сотрудник одобривший акт'
                                        : 'Сотрудник отклонивший акт')}
                            </TableCell>
                            <TableCell>Ссылка на файл</TableCell>
                            {apvgkReports.tabStatus === apvgkReportsConstants.STATUS_ACCEPT && <TableCell>ЦАФАП</TableCell>}
                            {apvgkReports.tabStatus === apvgkReportsConstants.STATUS_DECLINE
                                ? (<TableCell>Причина отклонения</TableCell>)
                                : (<TableCell>Действие</TableCell>)
                            }
                            {apvgkReports.tabStatus === apvgkReportsConstants.STATUS_IN_WORK && <TableCell>СМЭВ</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items ? items.map(viewData => {
                            const [showReason, setShowReason] = React.useState(false)
                            return (
                                <TableRow
                                    key={`application-${viewData.id}`}
                                >
                                    <TableCell>{viewData.id}</TableCell>
                                    <TableCell>{viewData.date_create}</TableCell>
                                    <TableCell>{'' + (viewData.apvgk_violation.Equipment && viewData.apvgk_violation.Equipment.EquipmentSeriaNumber)
                                    + (viewData.apvgk ? ' ('+ viewData.apvgk.name + ')':'')}</TableCell>
                                    <TableCell>
                                        {viewData.apvgk_violation.TrackStateNumber}
                                        {apvgkReports.tabStatus === apvgkReportsConstants.STATUS_IN_WORK && <GrzCheckStatusIcon report={viewData}/>}
                                    </TableCell>
                                    <TableCell>
                                        {viewData.apvgk_violation.PlatformId}
                                    </TableCell>
                                    <TableCell>
                                        {viewData.apvgk_violation.Speed}
                                    </TableCell>
                                    <TableCell>{
                                        apvgkReports.tabStatus === apvgkReportsConstants.STATUS_IN_WORK
                                            ? viewData.date_in_work
                                            : viewData.date_update
                                    }</TableCell>
                                    <TableCell>{viewData.user.name}</TableCell>
                                    <TableCell>
                                        <Link to={''}
                                              onClick={(e) => {
                                                  e.preventDefault()
                                                  apvgkReportsService.downloadFile(viewData.id)
                                              }}
                                              target="_blank"
                                        >
                                            Ссылка на файл
                                        </Link>
                                    </TableCell>
                                    {apvgkReports.tabStatus === apvgkReportsConstants.STATUS_ACCEPT && (
                                        <TableCell>
                                            {viewData.cafap_result && (isObject(viewData.cafap_result) || isArray(viewData.cafap_result)) &&
                                                (Object.keys(viewData.cafap_result).length == 1 && viewData.cafap_result.return
                                                    ? <CheckIcon style={{color: 'green'}}/>
                                                    : <CloseIcon color={"error"}/>
                                            )}
                                        </TableCell>
                                    )}
                                    <TableCell align={"right"} className="nowrap">
                                        {user.role_id == roleConstants.ROLE_APVGK && <Button className="info-btn" onClick={() => {onItem(viewData)}}>
                                            Подробнее
                                        </Button>}
                                        {viewData.status == apvgkReportsConstants.STATUS_DECLINE && (
                                            viewData.reason
                                        )}
                                        {showReason && (<ReasonModal report={viewData} onClose={() => {setShowReason(false)}}/>)}
                                    </TableCell>
                                    {apvgkReports.tabStatus === apvgkReportsConstants.STATUS_IN_WORK && viewData.smev_result && (
                                        <TableCell>
                                            {
                                                viewData.smev_result.status == 2 && viewData.smev_result.rejects &&
                                                viewData.smev_result.rejects.code ==='NO_DATA'
                                                    ? viewData.smev_result.rejects.description
                                                    :(viewData.smev_result.status > 1 && (viewData.smev_result.status == 4
                                                    ? <CheckIcon style={{color: 'green'}}/>
                                                    : <CloseIcon color={"error"}/>
                                            ))}
                                        </TableCell>
                                    )}
                                </TableRow>
                            );
                        }) : null}
                    </TableBody>
                </React.Fragment>
            )}
        </TabContainer>
    );
};

const mapStateToProps = state => state;

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { apvgkReports, authentication, filterData } = stateProps;
    const { dispatch } = dispatchProps;



    return {
        ...ownProps,
        apvgkReports,
        authentication,
        dispatch,
    };
};

WorkReportsTab = reduxForm({
    form: 'apvgk-reports-towork-form',
    enableReinitialize: true
})(WorkReportsTab);

export default compose(
    withStyles(() => {}),
    withSnackbar,
    connect(
        mapStateToProps,
        null,
        mergeProps
    )
)(WorkReportsTab);
