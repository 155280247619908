import React, { useState, useContext } from 'react';
import { get, isEmpty, range, sum } from 'lodash';
import { withStyles } from '@material-ui/core';
import Filter from './Filter'
import Grid from '@material-ui/core/Grid';
import {Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import {Loader, TablePagination} from '../../../components';
import Button from '@material-ui/core/Button';
import {apvgkService} from "../../../services/apvgkService";
import PhotoModal from "./PhotoModal";
import moment from 'moment';
import StatFilter from "./StatFilter";
import ProgressBar from "../../../components/ProgressBar";
import {ProcessingType} from "../../../constants/fileConstants";

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});


const TrackPageView = () => {
  const [filter, setFilter] = useState({
      min_date: moment(new Date(new Date().getTime() - 3600*1000)).format('YYYY-MM-DD HH:mm'),
      max_date: null,
      apvgk_id: null,
      TrackStateNumber: null,
      without_number: null,
      rows1: null,
      more12: null,
      gos: null,
      violation : null,
      page: 1,
      per_page: 10,
  });
  const [items, setItems] = useState({
      data: [],
      per_page:filter.per_page,
      current_page: 1,
  });
  const fetchPage = async (filt) =>  {
      setItems({...items, ...{data: null}});
      setFilter({ ...filt});
      try {
          const response = await apvgkService.getTrackDataPage(filt,);
          setItems(response);
      } catch (e) {
          setItems({...items, ...{data: []}});
          console.log(e);
      }
  }
  const onExport = async () =>  apvgkService.exportTrackData(filter);
  React.useEffect(() => {
      fetchPage(filter)
  }, [])
  return (
      <>
        <Grid
            container
            role="main"
            className="container text-left"
            direction="column"
            spacing={16}
            wrap="nowrap"
        >
            <Grid item>
                <Grid container direction="column" spacing={16}>
                    <Grid container direction="row" spacing={16}>
                        <Grid item xs={12}>
                            <h2 className="h2-title">Данные о проездах</h2>
                        </Grid>
                        <Filter currentFilters={filter} onFiltersChange={fetchPage}/>
                        <StatFilter/>
                        {<ProgressBar type={ProcessingType.GRUZ_TRACK_DATA} onExport={onExport} />}
                    </Grid>
                    <br/>
                </Grid>
            </Grid>
        </Grid>
        <main role="main" className="container text-center">
            {!items.data ? <Loader/> : <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Время</TableCell>
                            <TableCell>Страна</TableCell>
                            <TableCell>ГРЗ</TableCell>
                            <TableCell>12Т</TableCell>
                            <TableCell>Полоса</TableCell>
                            <TableCell>Длина</TableCell>
                            <TableCell>Ширина</TableCell>
                            <TableCell>Высота</TableCell>
                            <TableCell>Скорость</TableCell>
                            <TableCell>Общая масса</TableCell>
                            <TableCell>Параметры осей</TableCell>
                            <TableCell>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.data.map((viewData, index) => {
                            return (
                                <TableRow
                                    key={`track-item-${index}`}
                                    style={{backgroundColor: viewData.Violation ? '#ff8a81' : 'white'}}
                                >
                                    <TableCell>{viewData.id}</TableCell>
                                    <TableCell>{viewData.ExcessFactDate}</TableCell>
                                    <TableCell>{viewData.TrackCountryCode}</TableCell>
                                    <TableCell>{viewData.TrackStateNumber}</TableCell>
                                    <TableCell>{viewData.Properties.TrackGrossWeight > 12000 ? "Da": "Net"}</TableCell>
                                    <TableCell>{viewData.PlatformId}</TableCell>
                                    <TableCell>{viewData.Properties.TrackLength}</TableCell>
                                    <TableCell>{viewData.Properties.TrackWidth}</TableCell>
                                    <TableCell>{viewData.Properties.TrackHeight}</TableCell>
                                    <TableCell>{viewData.Properties.Speed}</TableCell>
                                    <TableCell>{viewData.Properties.TrackGrossWeight}</TableCell>
                                    <TableCell>{(() =>{
                                        let result = "";
                                        (new Array(12)).fill(null).map((v, i) => i + 1).forEach((v) => {
                                            let w = viewData.Properties["TrackThrust" + v];
                                            if (w && result) result += " X ";
                                            if (w) result += `${w}`;
                                        })
                                        return result;
                                    })()}</TableCell>
                                    <TableCell><PhotoModal id={viewData.id}/></TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
            </Table>}
        </main>
          <TablePagination
              meta={items}
              onChangePage={page => {fetchPage({ ...filter, page,});}}
              onChangeRowsPerPage={per_page => { fetchPage({ ...filter, page: 1, per_page, })}}
          />
      </>
  );
};

export default withStyles(styles)(TrackPageView);
