import { legalFormConstants } from '../constants/legalFormConstants';
import { authConstants } from '../constants/authConstants';

const createItem = (items, item) => {
  items.unshift(item);
  return items;
};
const updateItem = (items, item) => {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== item.id) {
      result.push(items[i]);
    } else {
      result.push(item);
    }
  }
  return result;
};
const initialState = {
  showFormModal: false,
  currentItem: {},
  meta: { per_page: 7, total: 0, current_page: 1 },
  items: []
};

export const legalForms = (state = initialState, action) => {
  switch (action.type) {
    // Get page
    case legalFormConstants.GETPAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case legalFormConstants.GETPAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.legalForms.data,
        meta: action.legalForms.meta
      };
    case legalFormConstants.GETPAGE_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Get all
    case legalFormConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case legalFormConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.legalForms.data
      };
    case legalFormConstants.GETALL_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Create
    case legalFormConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: createItem(state.items, action.legalForm),
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        },
        showFormModal: false
      };

    // Update
    case legalFormConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: updateItem(state.items, action.legalForm),
        showFormModal: false
      };

    // Delete
    case legalFormConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(legalForm =>
          legalForm.id === action.id ? { ...legalForm, deleting: true } : legalForm
        )
      };
    case legalFormConstants.DELETE_SUCCESS:
      // remove deleted HeaderMenu from state
      return {
        ...state,
        loading: false,
        items: state.items.filter(user => user.id !== action.id),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    case legalFormConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to HeaderMenu
      return {
        ...state,
        loading: false,
        items: state.items.map(legalForm => {
          if (legalForm.id === action.id) {
            // make copy of HeaderMenu without 'deleting:true' property
            const { deleting, ...legalFormCopy } = legalForm;
            // return copy of HeaderMenu with 'deleteError:[error]' property
            return { ...legalFormCopy, deleteError: action.error };
          }

          return legalForm;
        })
      };

    // Get item
    case legalFormConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case legalFormConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.legalForm.data
      };
    case legalFormConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // form modal
    case legalFormConstants.FORM_MODAL_OPEN:
      return {
        ...state,
        showFormModal: true
      };
    case legalFormConstants.FORM_MODAL_CLOSE:
      return {
        ...state,
        showFormModal: false
      };
    case legalFormConstants.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item
      };
    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
