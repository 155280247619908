import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { paymentsActions }from '../../../actions/paymentsActions'
import { connect } from 'react-redux';
import CreateForm from './CreateForm'
import { SubmissionError } from 'redux-form';
import {history} from "../../../helpers/history";
import {urlHelper} from "../../../helpers/urlHelper";
import {roleConstants} from "../../../constants/roleConstants";

class AccountantPaymentCreatePage extends React.Component{
    componentDidMount() {
        paymentsActions.setCurrentItem({
            payment_type: 0,
            name: '',
            price: '',
            count: ''
        })
    }
    render() {
        return (
            <Grid
                container
                role="main"
                className="container text-left"
                direction="column"
                spacing={16}
                wrap="nowrap"
            >
                <Grid item>
                    <Grid container spacing={16}>
                        <Grid item>
                            Создать платеж
                        </Grid>
                        <Grid item>
                            {/*<UsersFilter />*/}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className="">
                    <CreateForm onSubmit={this.props.handleSubmit}/>
                </Grid>

                {/*<UserModal />*/}
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {...state}
};

const mapDispatchToProps = (dispatch, props) => {
    const handleSubmit = (values) => {
        const {
            payment_type, price, count, user_id,
        } = values
        const disabled = !((payment_type !== null)&& (!!user_id) && (price >= 1) && (count >= 1));
        if (disabled) return null;
        return dispatch(paymentsActions.create({
            payment_type, price, count, user_id,
        })).then((r) => {
            history.push(
                `${urlHelper.getHomeUrl(roleConstants.ROLE_ACCOUNTANT)}/payments/`
            );
        }).catch((e) => {
            if (e instanceof SubmissionError) {
                const key = Object.keys(e.errors)[0]
                alert(e.errors[key].toString())
            }
        })
    }
    return {
        handleSubmit,
        dispatch
    };
};

const connectedAccountantPaymentCreatePage = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountantPaymentCreatePage)
export { connectedAccountantPaymentCreatePage as AccountantPaymentCreatePage };


