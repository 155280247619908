import React from 'react';
import Paper from '@material-ui/core/Paper';
import { LegalFormsBlock } from './legalForms/LegalFormsBlock';
import { VehicleBrandsBlock } from './vehicleBrands/VehicleBrandsBlock';
import { VehicleBrandErrorModal } from './vehicleBrands/VehicleBrandDeleteErrorModal';
import { VehicleModelsBlock } from './vehicleModels/VehicleModelsBlock';
import { VehicleModelErrorModal } from './vehicleModels/VehicleModelDeleteErrorModal';

const DictionariesPage = props => {
  return (
    <div>
      <Paper>
        <main role="main" className="container text-left">
          <br />
          <LegalFormsBlock />
          <br />
          <VehicleBrandsBlock />
          <VehicleBrandErrorModal />
          <br />
          <VehicleModelsBlock />
          <VehicleModelErrorModal />
          <br />
        </main>
      </Paper>
    </div>
  );
};

export default DictionariesPage;
