import React from 'react';
import Paper from '@material-ui/core/Paper';
import { StatusBlock } from './StatusBlock';
import { StatusErrorModal } from './StatusDeleteErrorModal';

const StatusesPage = props => {
  return (
    <div>
      <Paper>
        <StatusBlock />
        <StatusErrorModal />
      </Paper>
    </div>
  );
};

export default StatusesPage;
