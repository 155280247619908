import { routeAddressConstants } from '../constants/routeAddressConstants';
import { routeAddressService } from '../services/routeAddressService';
import { applicationService } from '../services/applicationService';

const getPage = (page, filters) => {
  const request = { type: routeAddressConstants.GET_PAGE_REQUEST, page, filters };

  const success = routeAddresses => {
    return { type: routeAddressConstants.GET_PAGE_SUCCESS, routeAddresses };
  };

  const failure = error => {
    return { type: routeAddressConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request);

    routeAddressService
      .getPage(page, filters)
      .then(routeAddresses => dispatch(success(routeAddresses)), error => dispatch(failure(error)));
  };
};

const getAll = () => {
  const request = () => {
    return { type: routeAddressConstants.GET_ALL_REQUEST };
  };

  const success = routeAddresses => {
    return { type: routeAddressConstants.GET_ALL_SUCCESS, routeAddresses };
  };

  const failure = error => {
    return { type: routeAddressConstants.GET_ALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    routeAddressService
      .getAll()
      .then(routeAddresses => dispatch(success(routeAddresses)), error => dispatch(failure(error)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: routeAddressConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: routeAddressConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: routeAddressConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    routeAddressService
      .delete(id)
      .then(routeAddress => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: routeAddressConstants.LOAD_REQUEST, id };
  };

  const success = routeAddress => {
    return { type: routeAddressConstants.LOAD_SUCCESS, routeAddress };
  };

  const failure = error => {
    return { type: routeAddressConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    routeAddressService
      .getItem(id)
      .then(routeAddress => dispatch(success(routeAddress)), error => dispatch(failure(error)));
  };
};

const create = values => {
  const request = value => {
    return { type: routeAddressConstants.CREATE_REQUEST, value };
  };

  const success = routeAddress => {
    return { type: routeAddressConstants.CREATE_SUCCESS, routeAddress };
  };

  return dispatch => {
    dispatch(request(values));

    return routeAddressService.create(values).then(routeAddress => dispatch(success(routeAddress)));
  };
};

const update = values => {
  const request = value => {
    return { type: routeAddressConstants.UPDATE_REQUEST, value };
  };

  const success = routeAddress => {
    return { type: routeAddressConstants.UPDATE_SUCCESS, routeAddress };
  };

  return dispatch => {
    dispatch(request(values));

    return routeAddressService.update(values).then(routeAddress => dispatch(success(routeAddress)));
  };
};

const openFormModal = () => {
  return { type: routeAddressConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: routeAddressConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: routeAddressConstants.SET_CURRENT_ITEM, item };
};
const setSuggestions = (index, suggestions) => {
  return { type: routeAddressConstants.SET_SUGGESTIONS, index, suggestions };
};
const setValue = (index, value) => {
  return { type: routeAddressConstants.SET_VALUE, index, value };
};

const searchLocation = (index, value) => {
  const request = value => {
    return { type: routeAddressConstants.SEARCH_LOCATION_REQUEST };
  };

  const success = (index, suggestions) => {
    return { type: routeAddressConstants.SET_SUGGESTIONS, index, suggestions };
  };

  const failure = error => {
    return { type: routeAddressConstants.SEARCH_LOCATION_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(value));

    routeAddressService.findLocations(value).then(
      response => {
        dispatch(success(index, response.data));
      },
      error => dispatch(failure(error))
    );
  };
};

export const routeAddressActions = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,

  openFormModal,
  closeFormModal,
  setCurrentItem,

  setSuggestions,
  setValue,
  searchLocation
};
