export const vehicleBrandConstants = {
  GET_PAGE_REQUEST: 'VEHICLE_BRAND_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'VEHICLE_BRAND_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'VEHICLE_BRAND_GET_PAGE_FAILURE',

  GET_ALL_REQUEST: 'VEHICLE_BRAND_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'VEHICLE_BRAND_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'VEHICLE_BRAND_GET_ALL_FAILURE',

  LOAD_REQUEST: 'VEHICLE_BRAND_LOAD_REQUEST',
  LOAD_SUCCESS: 'VEHICLE_BRAND_LOAD_SUCCESS',
  LOAD_FAILURE: 'VEHICLE_BRAND_LOAD_FAILURE',

  CREATE_REQUEST: 'VEHICLE_BRAND_CREATE_REQUEST',
  CREATE_SUCCESS: 'VEHICLE_BRAND_CREATE_SUCCESS',
  CREATE_FAILURE: 'VEHICLE_BRAND_CREATE_FAILURE',

  UPDATE_REQUEST: 'VEHICLE_BRAND_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'VEHICLE_BRAND_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'VEHICLE_BRAND_UPDATE_FAILURE',

  DELETE_REQUEST: 'VEHICLE_BRAND_DELETE_REQUEST',
  DELETE_SUCCESS: 'VEHICLE_BRAND_DELETE_SUCCESS',
  DELETE_FAILURE: 'VEHICLE_BRAND_DELETE_FAILURE',

  ACCEPT_REQUEST: 'VEHICLE_BRAND_ACCEPT_REQUEST',
  ACCEPT_SUCCESS: 'VEHICLE_BRAND_ACCEPT_SUCCESS',
  ACCEPT_FAILURE: 'VEHICLE_BRAND_ACCEPT_FAILURE',

  HIDE_REQUEST: 'VEHICLE_BRAND_HIDE_REQUEST',
  HIDE_SUCCESS: 'VEHICLE_BRAND_HIDE_SUCCESS',
  HIDE_FAILURE: 'VEHICLE_BRAND_HIDE_FAILURE',

  FORM_MODAL_OPEN: 'VEHICLE_BRAND_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'VEHICLE_BRAND_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'VEHICLE_BRAND_SET_CURRENT_ITEM',

  CLOSE_ERROR_MODAL: 'VEHICLE_BRAND_CLOSE_ERROR_MODAL',

  EXPORT_UPLOAD_REQUEST: 'VEHICLE_EXPORT_UPLOAD_REQUEST',
  EXPORT_UPLOAD_SUCCESS: 'VEHICLE_EXPORT_UPLOAD_SUCCESS'
};
