import React from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { Field, Formik } from 'formik';
import Select from 'react-select';
import MaterialInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput';

const IS_TRAILER_OPTIONS = [
  { value: undefined, label: 'Все' },
  { value: 0, label: 'ТС' },
  { value: 1, label: 'Прицеп' }
];

const VehicleAccessFilters = ({ filters, setFilters }) => {
  return (
    <Formik
      initialValues={filters}
      onSubmit={value => setFilters(value)}
      render={({ values, submitForm, setFieldValue }) => (
        <TableRow>
          <TableCell>
            <Field
              placeholder="Тип"
              name="type"
              component={Select}
              value={IS_TRAILER_OPTIONS.find(({ value }) => value === values.type)}
              options={IS_TRAILER_OPTIONS}
              onChange={v => setFieldValue('type', v.value)}
            />
          </TableCell>
          <TableCell colSpan={1}>
            <Field
              onKeyUp={e => (e.key === 'Enter' ? submitForm() : null)}
              name="brand"
              component={MaterialInput}
              placeholder="Марка"
            />
          </TableCell>
          <TableCell colSpan={1}>
            <Field
              onKeyUp={e => (e.key === 'Enter' ? submitForm() : null)}
              name="model"
              component={MaterialInput}
              placeholder="Модель"
            />
          </TableCell>
          <TableCell colSpan={1}>
            <Field
              onKeyUp={e => (e.key === 'Enter' ? submitForm() : null)}
              name="number"
              component={MaterialInput}
              placeholder="ГРЗ"
            />
          </TableCell>
          <TableCell colSpan={2}>
            <Button
              variant="contained"
              color="primary"
              className="btn-add no-margin"
              type="submit"
              onClick={submitForm}
            >
              Применить
            </Button>
          </TableCell>
        </TableRow>
      )}
    />
  );
};

export default VehicleAccessFilters;
