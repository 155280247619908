import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Close';
import { applicationActions } from '../../../actions/applicationActions';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { roleConstants } from '../../../constants/roleConstants';
import { applicationConstants } from '../../../constants/applicationConstants';

let AgreementsList = props => {
  const { authentication, application, handleRemove } = props;
  const { user } = authentication;
  return (
    <Table>
      <TableBody>
        {application &&
          application.application_agreements &&
          application.application_agreements.map(agreement => {
            return (
              <TableRow key={`agreement-${agreement.id}`}>
                <TableCell>{agreement.department.title}</TableCell>
                <TableCell className="text-right">
                  {dictionaryHelper.getAgreementStatus(agreement.status)}
                </TableCell>

                {/* {agreement.status === applicationConstants.AGREEMENT_DECLINED ? agreement.comment : ''} */}

                <TableCell className="text-right">
                  {agreement.status === applicationConstants.AGREEMENT_DECLINED
                    ? agreement.comment
                    : ''}
                </TableCell>

                {(user.role_id === roleConstants.ROLE_ADMIN ||
                  user.role_id === roleConstants.ROLE_OFFICER) &&
                  (application.status !== applicationConstants.STATUS_ACCEPT &&
                    application.status !== applicationConstants.STATUS_ACCEPT_WITH_CHANGES &&
                    application.status !== applicationConstants.STATUS_ACTIVE) && (
                    <TableCell className="text-right">
                      <Button className="red" onClick={e => handleRemove(agreement.id)}>
                        <DeleteIcon />
                      </Button>
                    </TableCell>
                  )}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { authentication } = state;
  return {
    authentication
  };
};

const mapDispatchToProps = dispatch => {
  const handleRemove = id => {
    dispatch(applicationActions.removeAgreement(id));
  };
  return {
    dispatch,
    handleRemove
  };
};

AgreementsList = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementsList);
export default AgreementsList;
