import { vehicleModelConstants } from '../constants/vehicleModelConstants';
import { vehicleModelService } from '../services/vehicleModelService';

const getAll = (brand_id = null) => {
  const request = () => {
    return { type: vehicleModelConstants.GET_ALL_REQUEST };
  };

  const success = vehicleModels => {
    return { type: vehicleModelConstants.GET_ALL_SUCCESS, vehicleModels };
  };

  const failure = error => {
    return { type: vehicleModelConstants.GET_ALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    vehicleModelService
      .getAll(brand_id)
      .then(vehicleModels => dispatch(success(vehicleModels)), error => dispatch(failure(error)));
  };
};

const getPage = (page, filters, sort) => {
  const request = { type: vehicleModelConstants.GET_PAGE_REQUEST, page, filters, sort };

  const success = vehicleModels => {
    return { type: vehicleModelConstants.GET_PAGE_SUCCESS, vehicleModels };
  };

  const failure = error => {
    return { type: vehicleModelConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request);

    vehicleModelService
      .getPage(page, filters, sort)
      .then(vehicleModels => dispatch(success(vehicleModels)), error => dispatch(failure(error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: vehicleModelConstants.LOAD_REQUEST, id };
  };

  const success = vehicleModel => {
    return { type: vehicleModelConstants.LOAD_SUCCESS, vehicleModel };
  };

  const failure = error => {
    return { type: vehicleModelConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleModelService
      .getItem(id)
      .then(vehicleModel => dispatch(success(vehicleModel)), error => dispatch(failure(error)));
  };
};

const create = values => {
  const request = value => {
    return { type: vehicleModelConstants.CREATE_REQUEST, value };
  };

  const success = vehicleModel => {
    return { type: vehicleModelConstants.CREATE_SUCCESS, vehicleModel };
  };

  return dispatch => {
    dispatch(request(values));

    return vehicleModelService.create(values).then(vehicleModel => dispatch(success(vehicleModel)));
  };
};

const update = values => {
  const request = value => {
    return { type: vehicleModelConstants.UPDATE_REQUEST, value };
  };

  const success = vehicleModel => {
    return { type: vehicleModelConstants.UPDATE_SUCCESS, vehicleModel };
  };

  return dispatch => {
    dispatch(request(values));

    return vehicleModelService.update(values).then(vehicleModel => dispatch(success(vehicleModel)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: vehicleModelConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: vehicleModelConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: vehicleModelConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleModelService.delete(id).then(
      data => {
        if (data.success) {
          dispatch(success(id));
        } else {
          dispatch(failure(id, 'Не может быть удалено!'));
        }
      },
      error => dispatch(failure(id, error))
    );
  };
};

const accept = id => {
  const request = id => {
    return { type: vehicleModelConstants.ACCEPT_REQUEST, id };
  };

  const success = id => {
    return { type: vehicleModelConstants.ACCEPT_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: vehicleModelConstants.ACCEPT_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleModelService.accept(id).then(
      data => {
        if (data.success) {
          dispatch(success(id));
        } else {
          dispatch(failure(id, 'Не может быть утверждено!'));
        }
      },
      error => dispatch(failure(id, error))
    );
  };
};

const hide = id => {
  const request = id => {
    return { type: vehicleModelConstants.HIDE_REQUEST, id };
  };

  const success = id => {
    return { type: vehicleModelConstants.HIDE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: vehicleModelConstants.HIDE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleModelService.hide(id).then(
      data => {
        if (data.success) {
          dispatch(success(id));
        } else {
          dispatch(failure(id, 'Не может быть отклонено!'));
        }
      },
      error => dispatch(failure(id, error))
    );
  };
};

const setIsTrailer = value => {
  return { type: vehicleModelConstants.SET_IS_TRAILER, isTrailer: value };
};

const openFormModal = () => {
  return { type: vehicleModelConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: vehicleModelConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: vehicleModelConstants.SET_CURRENT_ITEM, item };
};

const closeErrorModal = () => {
  return { type: vehicleModelConstants.CLOSE_ERROR_MODAL };
};

export const vehicleModelActions = {
  getAll,
  getPage,
  getItem,
  create,
  update,
  delete: _delete,
  accept,
  hide,

  setIsTrailer,

  openFormModal,
  closeFormModal,
  setCurrentItem,

  closeErrorModal
};
