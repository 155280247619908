export const Types = {
  Individual: 3,
  Company: 4
};

export const TripTypes = {
  Local: 0,
  Regional: 1,
  Worldwide: 2
};

export const NameByTripType = {
  [TripTypes.Local]: 'Местная',
  [TripTypes.Regional]: 'Межрегиональная',
  [TripTypes.Worldwide]: 'Международная'
};

export const AxleSuspension = {
  Spring: 0,
  Pneumatic: 1
};

export const NameByAxleSuspension = {
  [AxleSuspension.Spring]: 'Р',
  [AxleSuspension.Pneumatic]: 'П'
};
