import React from 'react'
import {Field, formValueSelector, reduxForm, change, formValues} from 'redux-form'
import {connect} from "react-redux";
import {FormField} from "../../../components/form/FormField";
import {dictionaryHelper} from "../../../helpers/dictionaryHelper";
import {fieldValidators} from "../../../helpers/fieldValidators";
import {renderSelectField} from "../../../components/form/renderSelectField";
import {Loader} from "../../../components";
import {UserFormField} from './UserFormField'

class CreateForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            user_id: null,
        }
    }

    render() {
        const {handleSubmit, handleChange, price_disabled, submitting, error} = this.props
        return (
            <form
                onSubmit={handleSubmit}
            >
                <Field
                    className="form-control"
                    name="payment_type"
                    component={renderSelectField}
                    label="Тип"
                    validate={[fieldValidators.required]}
                >
                    {[<option key={-1} value=''>Укажите тип</option>].concat(dictionaryHelper.paymentTypes.map(option => (
                        <option key={option.id} value={option.id}>
                            {option.title}
                        </option>
                    )))}
                </Field>

                <Field
                    name="user_id"
                    type="text"
                    label="Организация/ФИО"
                    selectOptions={{
                        placeholder:'Организация/ФИО',
                    }}
                    validate={[fieldValidators.required]}
                    component={UserFormField}
                />

                <Field
                    name="count"
                    type="number"
                    min="1"
                    disabled={this.props.initialValues.payment_type === '1'}
                    label="Количество"
                    validate={[fieldValidators.required]}
                    component={FormField}
                />


                <Field
                    className="form-control"
                    name="price"
                    type="number"
                    min="1"
                    label="Сумма"
                    disabled={price_disabled || this.props.initialValues.payment_type === '0'}
                    component={FormField}
                    validate={[fieldValidators.required]}
                />

                {error && <strong>{error}</strong>}
                <div>
                    {submitting && <Loader/>}
                    <button type="submit" className="btn" disabled={submitting}>
                        Сохранить
                    </button>
                    <br/>
                    <br/>
                </div>
            </form>
        )
    }
}

CreateForm = reduxForm({
    // a unique name for the form
    form: 'payment-form',
    enableReinitialize: true,
})(CreateForm)

const selector = formValueSelector('payment-form')
const mapStateToProps = (state) => {
    // or together as a group
    let initialValues = selector(state, 'price', 'count', 'payment_type');
    if (initialValues.payment_type === '0') {
        initialValues.price = (initialValues.count || 1)* 1600;
    } else if (initialValues.payment_type === '1') {
        initialValues.count = 1;
    }
    return {
        initialValues: {
            ...initialValues,
            count: initialValues.count || 1,
        }
    };
}

CreateForm = connect(mapStateToProps)(CreateForm)
export default CreateForm