import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { compose } from 'redux';
import { useAsync } from 'react-async';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import InfoContent from './content/ApplicationDetails/InfoContent';
import RequestProcessing from './content/ApplicationDetails/RequestProcessing';
import TransportSettings from './content/ApplicationDetails/TransportSettings';
import RequestProcessingInProgress from './content/ApplicationDetails/RequestProcessingInProgress';
import {
  CooperationCompletedStatuses,
  CooperationStatus
} from '../../../constants/cooperationConstants';
import { applicationService } from '../../../services/applicationService';
import { Loader } from '../../../components';
import { specialConditionService } from '../../../services/specialConditionService';
import { isContainSpring } from '../quickApplications/CreateQuickApplication/utils';
import Files from '../applications_new/components/Files';

const styles = () => ({
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

const CooperationViewPage = ({ match, classes, enqueueSnackbar }) => {
  const { id } = match.params;
  const [isSpring, setSpring] = useState(false);

  const {
    data: specialConditions,
    isRejected: conditionsRejected,
    isLoading: conditionsLoading,
    isResolved: conditionsResolved
  } = useAsync({ promiseFn: specialConditionService.getItem, id: 1 });
  const {
    data: response,
    isRejected: dataRejected,
    isLoading: dataLoading,
    isResolved: dataResolved,
    reload: reloadData
  } = useAsync({ promiseFn: applicationService.getApplication, id });
  const isCompleted =
    response && response.data && CooperationCompletedStatuses.includes(response.data.status);

  useEffect(() => {
    if (response && specialConditions) {
      const { start_date, finish_date, is_spring } = response.data;
      if (isCompleted) {
        setSpring(is_spring === 1);
      } else {
        setSpring(
          isContainSpring(
            {
              start: moment(start_date, 'DD.MM.YYYY').toISOString(),
              end: moment(finish_date, 'DD.MM.YYYY').toISOString()
            },
            specialConditions.data
          )
        );
      }
    }
  }, [response, specialConditions, isCompleted]);

  const toWork = useAsync({
    deferFn: ([a]) => applicationService.toWorkMultiple(a)
  });

  const onWork = values =>
    toWork.run({ values: { towork: values } }).then(({ data: application }) => {
      if (application) {
        reloadData();
      } else {
        enqueueSnackbar('Не удалось взять запрос в работу!', { variant: 'error' });
      }
    });

  const activate = useAsync({
    deferFn: () => applicationService.activate(response.data.id)
  });
  const onActivate = () =>
    activate.run().then(({ data: application }) => {
      if (application) {
        enqueueSnackbar('Запрос активирован!', { variant: 'success' });
        reloadData();
      } else {
        enqueueSnackbar('Не удалось активировать запрос!', { variant: 'error' });
      }
    });

  const files = get(response, 'data.files.smev_files', []) || [];

  return (
    <main role="main" className="container">
      {dataResolved && conditionsResolved && (
        <Grid container direction="column" spacing={16}>
          <InfoContent application={response.data} />
          <TransportSettings
            application={response.data}
            isSpring={isSpring}
            isCompleted={isCompleted}
          />
          {files && files.length > 0 && (
            <Grid item>
              <Files files={files} title="Документы от заявителя" />
            </Grid>
          )}
          {response.data.status === CooperationStatus.New && (
            <Grid item>
              <Button className="btn-add no-margin" onClick={() => onWork([id])}>
                Взять в работу
              </Button>
            </Grid>
          )}
          {response.data.status === CooperationStatus.InProgress && (
            <RequestProcessingInProgress
              application={response.data}
              reloadData={reloadData}
              isSpring={isSpring}
            />
          )}
          {isCompleted && <RequestProcessing application={response.data} onActivate={onActivate} />}
        </Grid>
      )}
      {(dataLoading || conditionsLoading) && (
        <div className={classes.loader}>
          <Loader size={45} />
        </div>
      )}
    </main>
  );
};

export default compose(
  withStyles(styles),
  withSnackbar
)(CooperationViewPage);
