import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field, Form, Formik } from 'formik';
import MaterialInput from '../quickApplications/CreateQuickApplication/components/MaterialInput';
import FieldLabel from '../quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialSelect from '../quickApplications/CreateQuickApplication/components/MaterialSelect';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import {apvgkService} from "../../../services/apvgkService";
import MaterialCheckbox from "../quickApplications/CreateQuickApplication/components/MaterialCheckbox";


const Filter = ({ currentFilters, onFiltersChange }) => {
    const [open, setOpen] = useState(false);
    const [without_number, setWithoutNumber] = useState(false);
    const [rows1, setRows1] = useState(false);
    const [rows2, setRows2] = useState(false);
    const [rows3, setRows3] = useState(false);
    const [rows4, setRows4] = useState(false);
    const [apvgks, setApvgks] = useState([]);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const onCancel = () => {
        onFiltersChange({});
        handleClose()
    };
    const fetchPage = async (page) =>  {
        const response = await apvgkService.getPage(page, 1000, false);
        setApvgks(response.data);
    }
    useEffect(() => {
        fetchPage(1);
    }, [])
    const checkboxHandler = (setField) => async (e) => {
        setField(e.target.name, e.target.checked)
    }
    return (
        <Grid item>
            <Link

                color="primary"
                to={{}}
                onClick={handleClickOpen}
            >
                Фильтровать
            </Link>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Фильтровать
                    </Typography>
                    <IconButton color="inherit" onClick={handleClose}>
                        <CloseIcon className="blue" />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <Formik
                        initialValues={{...currentFilters, min_date: currentFilters.min_date.replace(' ', 'T')} }
                        onSubmit={values => {
                            onFiltersChange(values);
                            handleClose();
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        render={({ values, setFieldValue }) => (
                            <Form>
                                <Grid container direction="column" spacing={16} wrap="nowrap">
                                    <Grid item>
                                        <FieldLabel label="Дата время с" name="min_date">
                                            <Field
                                                name="min_date"
                                                component={MaterialInput}
                                                type="datetime-local"
                                                // required="required"
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Дата время по" name="max_date">
                                            <Field
                                                name="max_date"
                                                component={MaterialInput}
                                                type="datetime-local"
                                                // required="required"
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Наименование АПВГК" name="apvgk_id">
                                            <Field
                                                name="apvgk_id"
                                                placeholder="Выберите АПВГК"
                                                component={MaterialSelect}
                                                options={apvgks.map((v) => {return {value:v.id, label:v.name};})}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="ГРЗ ТС" name="TrackStateNumber">
                                            <Field
                                                name="TrackStateNumber"
                                                type="text"
                                                component={MaterialInput}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Без ГРЗ" name="without_number">
                                            <Field
                                                name="without_number"
                                                onChange={checkboxHandler(setFieldValue)}
                                                component={MaterialCheckbox}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Больше 12Т" name="more12">
                                            <Field
                                                name="more12"
                                                onChange={checkboxHandler(setFieldValue)}
                                                component={MaterialCheckbox}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Госзадание" name="gos">
                                            <Field
                                                name="gos"
                                                onChange={checkboxHandler(setFieldValue)}
                                                component={MaterialCheckbox}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Нарушения: допустимых нагрузок/габаритов" name="violation">
                                            <Field
                                                name="violation"
                                                onChange={checkboxHandler(setFieldValue)}
                                                component={MaterialCheckbox}
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    {false && <Grid item>
                                        <FieldLabel label="Полосы" name="rows">
                                            <Grid container direction="row" spacing={16} wrap="nowrap">
                                                <FieldLabel label="1" name="rows1">
                                                    <Field
                                                        name="rows1"
                                                        component={MaterialCheckbox}
                                                        onChange={checkboxHandler(setFieldValue)}
                                                    />
                                                </FieldLabel>
                                                <FieldLabel label="2" name="rows2">
                                                    <Field
                                                        name="rows2"
                                                        component={MaterialCheckbox}
                                                        onChange={checkboxHandler(setFieldValue)}
                                                    />
                                                </FieldLabel>
                                                <FieldLabel label="3" name="rows3">
                                                    <Field
                                                        name="rows3"
                                                        onChange={checkboxHandler(setFieldValue)}
                                                        component={MaterialCheckbox}
                                                    />
                                                </FieldLabel>
                                                <FieldLabel label="4" name="rows4">
                                                    <Field
                                                        name="rows4"
                                                        onChange={checkboxHandler(setFieldValue)}
                                                        component={MaterialCheckbox}
                                                    />
                                                </FieldLabel>
                                            </Grid>
                                        </FieldLabel>
                                    </Grid>}
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="btn-add no-margin"
                                            type="submit"
                                        >
                                            Применить
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            component="span"
                                            className="info-btn"
                                            onClick={onCancel}
                                        >
                                            Очистить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default Filter;
