import { authActions } from '../actions/authActions';
import { history } from './history';

const storeUser = user => {
  if (user && user.api_token) {
    localStorage.setItem('user', JSON.stringify(user));
  }
};

const logout = dispatch => {
  history.push('/');
  dispatch(authActions.logout());
};

export const authHelper = {
  storeUser,
  logout
};
