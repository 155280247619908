import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { vehicleBrandActions } from '../../../../../actions/vehicleBrandActions';
import { VehicleBrandsTableList } from './VehicleBrandsTableList';
import { Loader } from '../../../../../components/Loader';
import { VehicleBrandModal } from './VehicleBrandModal';
import { VehicleBrandFilter } from './VehicleBrandFilter';

class VehicleBrandsBlock extends Component {
  componentDidMount() {
    const { vehicleBrands } = this.props;

    if (!vehicleBrands.items || vehicleBrands.items.length === 0) {
      this.props.dispatch(vehicleBrandActions.getPage(1));
    }
  }

  render() {
    const { vehicleBrands, openCreateModal, handleLicenseFileUpload } = this.props;

    return (
      <div>
        <div>
          <span>Справочник марок тс / прицепов</span>
          <Button aria-label="Добавить" className="btn-add" onClick={openCreateModal}>
            + Создать
          </Button>
          <div className="file_models">
            <input
              accept=".application/vnd.sealed-xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="file_input"
              id="license-upload-button"
              type="file"
              onChange={handleLicenseFileUpload}
            />
            <label className="file_btn" htmlFor="license-upload-button">
              <Button
                variant="contained"
                component="span"
                aria-label="Добавить"
                className="info-btn"
              >
                <CloudDownloadIcon />
                &nbsp;&nbsp;Загрузить
              </Button>
            </label>
          </div>
        </div>
        <p>
          <span>{vehicleBrands.exportMessage}</span>
        </p>

        <div className="">
          {vehicleBrands.loading && <Loader />}
          {vehicleBrands.error && (
            <span className="text-danger">Ошибка: {vehicleBrands.error}</span>
          )}
          {vehicleBrands.items && (
            <VehicleBrandsTableList data={vehicleBrands.items} meta={vehicleBrands.meta} />
          )}
        </div>

        <VehicleBrandModal />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { vehicleBrands } = state;
  return {
    vehicleBrands
  };
};

const mapDispatchToProps = dispatch => {
  const openCreateModal = e => {
    dispatch(vehicleBrandActions.setCurrentItem(null));
    dispatch(vehicleBrandActions.openFormModal());
  };

  const handleLicenseFileUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      const values = { myfile: reader.result };
      dispatch(vehicleBrandActions.uploadBrandsXls(values));
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };

  return {
    openCreateModal,
    handleLicenseFileUpload,

    dispatch
  };
};

const connectedVehicleBrandsBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleBrandsBlock);
export { connectedVehicleBrandsBlock as VehicleBrandsBlock };
