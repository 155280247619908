import React, {Component} from 'react';
import {Field, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import {renderSelectFieldNoLabel} from '../../components/form/renderSelectFieldNoLabel';
import {fieldValidators} from '../../helpers/fieldValidators';
import {Loader} from '../../components/Loader';
import {controlPostActions} from '../../actions/controlPostActions';
import {authActions} from '../../actions/authActions';
import {history} from '../../helpers/history';
import mainLogo from '../App/img/logo.png';

// let PostPickForm = (props) => {
class PostPickForm extends Component {
  componentDidMount() {
    const { controlPosts } = this.props;

    // if (!controlPosts.items || controlPosts.items.length === 0) {
    this.props.dispatch(controlPostActions.getAll());
    // }
  }

  render() {
    const { controlPosts, handleSubmit, submitting } = this.props;
    const { user, logoutUser } = this.props;
    return (
      <div>
        <div className="cabinet-header">
          <header>
            <nav className="navbar navbar-expand-md">
              <div className="row">
                <div className="col-md-8">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                      <span className="nav-link undefined">
                        <img src={mainLogo} className="logo" />
                      </span>
                    </li>
                    <li className="nav-item active">
                      <span className="nav-link logo-title-inner">АИС КТГ</span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4  menu-right">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-link cursor" onClick={logoutUser}>
                      Выйти
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        </div>
        <div className="control_post text-center">
          <form onSubmit={handleSubmit}>
            <Field
              className="form-control"
              name="control_post_id"
              label="Контрольный пост"
              component={renderSelectFieldNoLabel}
              validate={[fieldValidators.required]}
            >
              <option key="0" value="">
                Выберите пост
              </option>
              {controlPosts.items
                ? controlPosts.items.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  ))
                : ''}
            </Field>
            <br />
            <Button
              variant="contained"
              color="primary"
              className="login"
              type="submit"
              disabled={submitting}
            >
              {submitting && <Loader />} Выбрать
            </Button>
          </form>
        </div>
        <footer className="footer">
          <div className="row">
            <div className="col-md-3">
              <span className="text-muted">Email:</span> ais.ktg@tatar.ru
              {/* <br />
              <span className="text-muted">Техническая поддержка:</span>{' '}
              <small>+7 (843) 5-333-813</small> */}
            </div>
            <div className="col-md-5">
              <span className="text-muted">По вопросам регистрации ТС и выдаче разрешений:</span>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <small>Казань: +7 (843) 5-333-797</small>
                  <br />
                  <small>Н.Челны: +7 (855) 2-770-917</small>
                </div>
                <div className="col-md-6">
                  <small>Альметьевск: +7 (855) 3-439-066</small>
                  <br />
                  <small>Нурлат: +7 (843) 4-529-326</small>
                </div>
              </div>
            </div>
            <div className="col-md-5 text-right">
              {/* <a href={`/agreement`}>О нас</a> */}
              {/* <a target="_blank" href={`${apiConstants.API_URL}/storage/info/instructions.pdf`}>Инструкция</a> */}
              {/* <a href={`/personal`}>О персональных данных</a> */}
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

PostPickForm = reduxForm({
  form: 'post-pick-form'
})(PostPickForm);

const mapStateToProps = state => {
  const { controlPosts } = state;

  return {
    controlPosts
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => {
      history.push('/');
      dispatch(authActions.logout());
    }
  };
};

PostPickForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostPickForm);

export default PostPickForm;
