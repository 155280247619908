import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import ApplicationsTableList from './ApplicationsTableList';
import { applicationActions } from '../../../actions/applicationActions';

const submitMultipleToWork = (values, dispatch) => {
  const towork = [];
  Object.entries(values.towork).forEach(([key, value]) => value && towork.push(key))
  return dispatch(applicationActions.toWorkMultiple({ ...values, towork }));
};

const TabContainer = props => {
  return <Typography component="div">{props.children}</Typography>;
};

const ApplicationsTableTab = props => {
  const { adminApplications } = props;

  return (
    <div>
      <TabContainer>
        <ApplicationsTableList
          onSubmit={submitMultipleToWork}
          data={adminApplications.items}
          meta={adminApplications.meta}
        />
      </TabContainer>
    </div>
  );
};

const mapStateToProps = state => {
  const { adminApplications } = state;
  return {
    adminApplications
  };
};

const connectedApplicationsTableTab = connect(mapStateToProps)(ApplicationsTableTab);
export { connectedApplicationsTableTab as ApplicationsTableTab };
