import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core';
import { Info } from '@material-ui/icons';

import { isEmpty } from 'lodash';
import { urlHelper } from '../../../helpers/urlHelper';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { vehicleActions } from '../../../actions/vehicleActions';
import { useMountEffect } from '../../../helpers/hooks';
import { Loader } from '../../../components';
import RegAppsVehicleFilterForm from './RegAppsVehicleFilterForm';

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto'
  }
};

const RegAppsVehiclesList = ({
  firmId,
  getVehicles,
  reset,
  user,
  vehiclesState,
  classes,
  values,
  handleSubmit
}) => {
  useMountEffect(() => {
    getVehicles(firmId, values);
    return reset;
  });

  const { items, error, loading, meta } = vehiclesState;

  const haveNoItems = isEmpty(items);

  return (
    <Grid container>
      <h2 className="h2-title">Транспортные средства</h2>
      <form onSubmit={handleSubmit(filters => getVehicles(firmId, filters))}>
        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Тип</TableCell>
                <TableCell>Марка</TableCell>
                <TableCell>Модель</TableCell>
                <TableCell>ГРЗ</TableCell>
                <TableCell>Статус</TableCell>
              </TableRow>
              <RegAppsVehicleFilterForm submitting={loading} />
            </TableHead>
            <TableBody>
              {(loading || error || haveNoItems) && (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    {!error && !loading && haveNoItems && <span>Объекты не найдены</span>}
                    {error && <span className="text-danger">Ошибка: {error}</span>}
                    {loading && <Loader />}
                  </TableCell>
                </TableRow>
              )}
              {!loading &&
                !error &&
                items.map(vehicle => (
                  <TableRow key={`vehicle-${vehicle.id}`}>
                    <TableCell>
                      <Link
                        to={`${urlHelper.getHomeUrl(user.role_id)}/vehicles/info/${vehicle.id}`}
                      >
                        <IconButton color="primary">
                          <Info />
                        </IconButton>
                      </Link>
                    </TableCell>
                    <TableCell>
                      {dictionaryHelper.isTrailer.getItemTitle(vehicle.is_trailer)}
                    </TableCell>
                    <TableCell>{vehicle.brandTitle}</TableCell>
                    <TableCell>{vehicle.modelTitle}</TableCell>
                    <TableCell>{vehicle.real_number}</TableCell>
                    <TableCell>{vehicle.privilegeTitle || 'Без особого статуса'}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </form>
    </Grid>
  );
};

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'application-firm-vehicles-filter-form',
    enableReinitialize: true
  }),
  connect(
    state => ({
      vehiclesState: state.adminFirmVehicles
    }),
    {
      getVehicles: vehicleActions.getFirmVehicles,
      reset: vehicleActions.resetFirmVehicles
    }
  )
)(RegAppsVehiclesList);
