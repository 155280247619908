export const regAppConstants = {
  REASONS: [{ id: 1, name: 'Устав' }, { id: 2, name: 'Доверенность' }],
  ROLES: [{ id: 3, name: 'Физическое лицо' }, { id: 4, name: 'Юридическое лицо' }],
  ROLE_VALUES: {
    individual: '3',
    firm: '4'
  },

  STATUS_NEW: 0,
  STATUS_DECLINE: 1,
  STATUS_REVIEW: 2,
  STATUS_ACCEPT: 3,

  SET_TAB_INDEX: 'REG_APP_SET_TAB_INDEX',
  SET_ROLE_INDEX: 'REG_APP_SET_ROLE_INDEX',

  GET_BY_STATUSES_REQUEST: 'REG_APP_GET_BY_STATUSES_REQUEST',
  GET_BY_STATUSES_SUCCESS: 'REG_APP_GET_BY_STATUSES_SUCCESS',
  GET_BY_STATUSES_FAILURE: 'REG_APP_GET_BY_STATUSES_FAILURE',

  GET_PAGE_BY_STATUS_REQUEST: 'REG_APP_GET_PAGE_BY_STATUS_REQUEST',
  GET_PAGE_BY_STATUS_SUCCESS: 'REG_APP_GET_PAGE_BY_STATUS_SUCCESS',
  GET_PAGE_BY_STATUS_FAILURE: 'REG_APP_GET_PAGE_BY_STATUS_FAILURE',

  LOAD_REQUEST: 'REG_APP_LOAD_REQUEST',
  LOAD_SUCCESS: 'REG_APP_LOAD_SUCCESS',
  LOAD_FAILURE: 'REG_APP_LOAD_FAILURE',

  SET_STATUS_REQUEST: 'REG_APP_SET_STATUS_REQUEST',
  SET_STATUS_SUCCESS: 'REG_APP_SET_STATUS_SUCCESS',
  SET_STATUS_FAILURE: 'REG_APP_SET_STATUS_FAILURE',

  ADMIN_UPDATE_REQUEST: 'REG_APP_ADMIN_UPDATE_REQUEST',
  ADMIN_UPDATE_SUCCESS: 'REG_APP_ADMIN_UPDATE_SUCCESS',
  ADMIN_UPDATE_FAILURE: 'REG_APP_ADMIN_UPDATE_FAILURE',

  ACCEPT_REQUEST: 'REG_APP_ACCEPT_REQUEST',
  ACCEPT_SUCCESS: 'REG_APP_ACCEPT_SUCCESS',
  ACCEPT_FAILURE: 'REG_APP_ACCEPT_FAILURE',

  DECLINE_REQUEST: 'REG_APP_DECLINE_REQUEST',
  DECLINE_SUCCESS: 'REG_APP_DECLINE_SUCCESS',
  DECLINE_FAILURE: 'REG_APP_DECLINE_FAILURE',

  DECLINE_OPEN: 'REG_APP_DECLINE_OPEN',
  DECLINE_CLOSE: 'REG_APP_DECLINE_CLOSE',

  REASON_OPEN: 'REG_APP_REASON_OPEN',
  REASON_CLOSE: 'REG_APP_REASON_CLOSE',

  LOCK_REQUEST: 'REG_APP_LOCK_REQUEST',
  LOCK_SUCCESS: 'REG_APP_LOCK_SUCCESS',
  LOCK_FAILURE: 'REG_APP_LOCK_FAILURE',

  UNLOCK_REQUEST: 'REG_APP_UNLOCK_REQUEST',
  UNLOCK_SUCCESS: 'REG_APP_UNLOCK_SUCCESS',
  UNLOCK_FAILURE: 'REG_APP_UNLOCK_FAILURE',

  ADMIN_FILTER_REQUEST: 'REG_APP_ADMIN_FILTER_REQUEST',
  ADMIN_FILTER_SUCCESS: 'REG_APP_ADMIN_FILTER_SUCCESS',
  ADMIN_FILTER_FAILURE: 'REG_APP_ADMIN_FILTER_FAILURE'
};
