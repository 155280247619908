import React from 'react';
import { flatMap, uniqueId } from 'lodash';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { FieldArray } from 'formik';
import Grid from '@material-ui/core/Grid';
import { withSnackbar } from 'notistack';
import { applicationService } from '../../../../../services/applicationService';
import SinglePath from './SinglePath';
import RestrictedArea from '../../../../../components/map/RestrictedArea';
import smevService from '../../../../../services/smevService';
import RouteLines from '../../../../../components/RouteLines';
import ApvgksLayer from '../../../../../components/map/ApvksLayer';

const stamenTonerTiles = 'https://tile.aisktg.ru/tatarstan/{z}/{x}/{y}.png';
const stamenTonerAttr = '';

const getName = (index, markers) => {
  if (index === 0) {
    return 'Отправление:';
  }
  if (index === markers.length - 1) {
    return 'Прибытие:';
  }
  return 'Промежуточная точка';
};

const getStepByCoords = async latlng => {
  if (!latlng) {
    return {
      id: uniqueId(),
      isEmpty: false
    };
  }
  const { lat, lng } = latlng;
  const response = await applicationService.getCoordsLocation(lat, lng);
  const { data } = response;
  return {
    id: uniqueId(),
    isEmpty: false,
    text: data.text,
    coords: latlng
  };
};

const RouteMap = ({ values, options, arrayHelpers, enqueueSnackbar }) => {
  const [mapCenter, setMapCenter] = React.useState([55.211, 50.634]);
  const [savingRoute, setSavingRoute] = React.useState(false);
  const zoomLevel = 7;
  const { paths } = values;
  const saveRoute = React.useCallback(async () => {
    setSavingRoute(true);
    try {
      await smevService.saveRoute(paths);
      enqueueSnackbar(`Маршрут сохранен`, { variant: 'success' });
    } catch (e) {
      setSavingRoute(false);
      enqueueSnackbar(`Ошибка при сохранени маршрута`, { variant: 'error' });
    }
  }, [paths]);
  const updateCenter = ({ coords }) => coords && setMapCenter([coords.lat, coords.lng]);
  const addStep = async ({ latlng }) => {
    try {
      const step = await getStepByCoords(latlng);
      const emptySteps = values.paths.map(path => path.steps.find(s => !s.text));
      const firstEmpty = emptySteps.findIndex(p => !!p);
      if (firstEmpty < 0) {
        const len = values.paths.length - 1;
        const path = values.paths[len];
        if (!path.useSaved) {
          arrayHelpers.replace(len, {
            ...path,
            steps: [...path.steps, step]
          });
        }
        return;
      }
      const path = values.paths[firstEmpty];
      const emptyIndex = path.steps.findIndex(s => !s.text);
      arrayHelpers.replace(firstEmpty, {
        ...path,
        steps: path.steps.map((s, index) => {
          if (index === emptyIndex) {
            return step;
          }
          return s;
        })
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onDrag = index => async e => {
    const step = await getStepByCoords(e.target.getLatLng());
    arrayHelpers.replace(index, step);
    updateCenter(step);
  };

  const onAddPath = () => {
    arrayHelpers.push({
      id: uniqueId(),
      useSaved: false,
      steps: [{ text: '' }, { text: '' }]
    });
  };

  const steps = flatMap(paths, path => path.steps);

  return (
    <Grid container spacing={16} direction="column">
      <Grid item>
        <Map center={mapCenter} zoom={zoomLevel}>
          <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
          <RestrictedArea />
          <ApvgksLayer />
          {paths.map(path => (
            <RouteLines key={path.id} steps={path.steps} />
          ))}

          {steps.map(({ coords, id, text }, index) => {
            const position = coords && coords.lat ? [coords.lat, coords.lng] : null;
            const textPre = getName(index, steps);
            if (position) {
              return (
                <Marker key={id} position={position} alt={index}>
                  <Popup>
                    <span>
                      {textPre} {text}
                    </span>
                  </Popup>
                </Marker>
              );
            }
            return null;
          })}
        </Map>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          spacing={16}
          wrap="nowrap"
          justify="space-between"
          className="full-height"
        >
          {paths.map((path, index) => (
            <Grid item key={path.id}>
              <SinglePath
                options={options}
                index={index}
                values={values}
                key={path.id}
                name={`paths[${index}]`}
                onChange={update => arrayHelpers.replace(index, { ...path, ...update })}
                removeStep={() => arrayHelpers.remove(index)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const RouteMapContainer = ({ ...props }) => (
  <FieldArray
    name="paths"
    render={arrayHelpers => <RouteMap arrayHelpers={arrayHelpers} {...props} />}
  />
);

export default withSnackbar(RouteMapContainer);
