import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {FirmForm} from "../../main/FirmForm";
import {cabinetActions} from "../../../../actions/cabinetActions";
import {CABINET_SUBMIT_MESSAGE, CABINET_SUBMIT_TITLE} from "../../main/CabinetMainPage";
import { submit } from 'redux-form';
import ConfirmationModal from '../../../../components/modals/ConfirmationModal';


const KppEditModal = props => {
  const { show, handleModalClose,dispatch } = props;
  const [showConfirm, setShowConfirm] = React.useState(false)
  return (
    <Dialog
      open={show}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-left">
        <Typography variant="title" color="inherit" className="flex">
          Редактирование данных компании
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog">
          <Typography style={{marginBottom:10}}>Для фиксирования данных о государственных и муниципальных платежах необходимо ввести КПП организации.</Typography>
          <FirmForm
              onlyKpp={true}
              kppConfirmation={() => setShowConfirm(true)}
              onSubmit={(v) => {
                  setShowConfirm(false);
                  dispatch(cabinetActions.saveInfo(v)).then((r) => {
                    handleModalClose()
                  }).catch((error) => {
                    alert('При сохранении КПП произошла ошибка')
                  })
            }}/>
          <ConfirmationModal
              title={CABINET_SUBMIT_TITLE}
              description={CABINET_SUBMIT_MESSAGE}
              handleSubmit={() => {
                  dispatch(submit('cabinet-firm'));
              }}
              isShow={showConfirm}
              handleClose={() => {setShowConfirm(false)}}
          />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;


  return {
    ...ownProps,
      dispatch,
  };
};

const connectedKppEditModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(KppEditModal);

export { connectedKppEditModal as KppEditModal };
