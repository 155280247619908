import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { applicationConstants } from '../../../constants/applicationConstants';
import { applicationActions } from '../../../actions/applicationActions';
import { roleConstants } from '../../../constants/roleConstants';
import { formatHelper } from '../../../helpers/formatHelper';
import { urlHelper } from '../../../helpers/urlHelper';
import { statusHelper } from '../../../helpers/statusHelper';
import ApplicationRoute from '../../../components/elements/ApplicationRoute';
import { applicationHelper } from '../../../helpers/applicationHelper';
import FileUploader from '../../../components/form/FileUploader';
import { createFileUploadHandler } from '../../../helpers/fileUploadHelper';
import { fileConstants } from '../../../constants/fileConstants';

const ApplicationCard = props => {
  const {
    application = {},
    handleWithdraw,
    handleRemove,
    authentication,
    userApplications,
    handleFileUpload,
    handleFileRemove
  } = props;
  const { user } = authentication;

  const {
    id,
    is_draft,
    permit,
    status,
    user: applicationUser,
    comment,
    admin,
    real_price,
    start_date,
    trailers = [],
    files,
    employee_id,
    finish_date,
    vehicle,
    dates
  } = application;
  const payFiles = get(files, 'pay_files', []);
  const disabledAdd = ((new Date().getTime() >= (new Date('2023-01-01 00:00')).getTime()));
  return (
    <Card
      className={
        status === applicationConstants.STATUS_ACTIVE &&
        dates &&
        dates.runs_count - dates.runs_used <= 2
          ? 'yellow-bg'
          : ''
      }
    >
      <CardContent>
        {statusHelper.getStatusIcon(user.role_id, application)}
        <h2 className="h2-title">
          Заявление
          {(status !== applicationConstants.STATUS_NEW ||
            (status === applicationConstants.STATUS_NEW && !is_draft)) &&
            ` №${formatHelper.appNumber(application)}`}
          <br />
          <small>Статус: {statusHelper.getStatusText(user.role_id, application)}</small>
        </h2>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                {applicationUser.role_id === roleConstants.ROLE_INDIVIDUAL
                  ? 'ФИО'
                  : 'Название фирмы'}
              </TableCell>
              <TableCell numeric>{applicationUser && applicationUser.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ГРЗ ТС </TableCell>
              <TableCell numeric>{vehicle && vehicle.real_number}</TableCell>
            </TableRow>
            {trailers.map((trailer, index) => (
              <TableRow key={`trailer-value-${index}`}>
                <TableCell>ГРЗ прицепа</TableCell>
                <TableCell numeric>{trailer.real_number}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ApplicationRoute application={application} />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Период действия</TableCell>
              <TableCell numeric>
                с {start_date} до {finish_date}
              </TableCell>
            </TableRow>
            {(status === applicationConstants.STATUS_DECLINE ||
              status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES) && (
              <TableRow>
                <TableCell>
                  {status === applicationConstants.STATUS_DECLINE
                    ? 'Причина отклонения'
                    : 'Комментарий'}
                </TableCell>
                <TableCell numeric>{comment}</TableCell>
              </TableRow>
            )}

            {status === applicationConstants.STATUS_DECLINE ? (
              <TableRow>
                <TableCell>ФИО сотрудника, отклонившего заявку</TableCell>
                <TableCell numeric>{admin ? admin.name : ''}</TableCell>
              </TableRow>
            ) : (
              ''
            )}
            {status === applicationConstants.STATUS_ACCEPT ||
            status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
            status === applicationConstants.STATUS_ACTIVE ? (
              <TableRow>
                <TableCell>ФИО сотрудника, одобрившего заявку</TableCell>
                <TableCell numeric>{admin ? admin.name : ''}</TableCell>
              </TableRow>
            ) : (
              ''
            )}

            {/* Счет */}
            {status === applicationConstants.STATUS_ACCEPT ||
            status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
            status === applicationConstants.STATUS_ACTIVE ? (
              <TableRow>
                <TableCell>Счет на оплату</TableCell>
                <TableCell numeric>
                  {permit && permit.invoice_link ? <a href={permit.invoice_link}>Счет</a> : ''}
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}

            {/* Файлы, подтверждающие оплату */}
            {status === applicationConstants.STATUS_ACCEPT ||
            status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
            status === applicationConstants.STATUS_ACTIVE ? (
              <TableRow>
                <TableCell>Подтверждение оплаты</TableCell>
                {(payFiles.length > 0 || status === applicationConstants.STATUS_ACTIVE) && (
                  <TableCell numeric>
                    {payFiles.map(({ id: fileId, name, url }) => (
                      <div key={fileId}>
                        <a target="_blank" href={url}>
                          {name}
                        </a>
                      </div>
                    ))}
                  </TableCell>
                )}
                {(status === applicationConstants.STATUS_ACCEPT ||
                  status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES) &&
                  payFiles.length === 0 && (
                    <TableCell numeric>
                      <FileUploader
                        className=""
                        entity="pay"
                        inputClassName="file_input"
                        buttonText="+ Добавить файл"
                        fileList={[]}
                        handleFileChange={handleFileUpload}
                        handleFileRemove={handleFileRemove}
                        hasError={userApplications.uploadPayError}
                        uploaders={userApplications.payUploaders}
                        multiple
                      />
                    </TableCell>
                  )}
              </TableRow>
            ) : (
              ''
            )}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        {(status === applicationConstants.STATUS_NEW ||
          status === applicationConstants.STATUS_REVIEW ||
          status === applicationConstants.STATUS_REPEAT ||
          status === applicationConstants.STATUS_DECLINE) && (
          <Grid container className="app_action" justify="flex-end">
            {((status === applicationConstants.STATUS_NEW && is_draft) ||
              status === applicationConstants.STATUS_DECLINE) &&
            (user.role_id !== roleConstants.ROLE_FIRMUSER || employee_id === user.id) ? (
              <Button className="decline-btn" onClick={handleRemove(id)}>
                Удалить
              </Button>
            ) : (
              ''
            )}
            {status === applicationConstants.STATUS_NEW &&
            is_draft && !disabledAdd &&
            (user.role_id !== roleConstants.ROLE_FIRMUSER || employee_id === user.id) ? (
              <Link
                to={`${urlHelper.getHomeUrl(user.role_id)}/applications/update/${id}/step/vehicle`}
              >
                <Button className="info-btn no-margin">Продолжить</Button>
              </Link>
            ) : (
              ''
            )}
            {status === applicationConstants.STATUS_REVIEW ||
            status === applicationConstants.STATUS_REPEAT ||
            (status === applicationConstants.STATUS_NEW &&
              !is_draft &&
              (user.role_id !== roleConstants.ROLE_FIRMUSER || employee_id === user.id)) ? (
              <Button className="decline-btn" onClick={handleWithdraw(id)}>
                Отозвать
              </Button>
            ) : (
              ''
            )}
            {!disabledAdd && status === applicationConstants.STATUS_DECLINE &&
            (user.role_id !== roleConstants.ROLE_FIRMUSER || employee_id === user.id) ? (
              <Link
                to={`${urlHelper.getHomeUrl(user.role_id)}/applications/update/${id}/step/vehicle`}
              >
                <Button className="info-btn no-margin">Редактировать</Button>
              </Link>
            ) : (
              ''
            )}

            {status !== applicationConstants.STATUS_NEW ||
            (status === applicationConstants.STATUS_NEW && !is_draft) ||
            !(user.role_id !== roleConstants.ROLE_FIRMUSER || employee_id === user.id) ? (
              <Link to={`${urlHelper.getHomeUrl(user.role_id)}/applications/view/${id}`}>
                <Button className="moreinfo-btn no-margin">Подробнее</Button>
              </Link>
            ) : (
              ''
            )}
          </Grid>
        )}

        {(status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
          status === applicationConstants.STATUS_ACCEPT ||
          status === applicationConstants.STATUS_ACTIVE) && (
          <div className="full-width row">
            <div className="col-md-6 text-left">
              <h4 className=" blue">{real_price} р</h4>
            </div>
            <div className="col-md-6 text-right app_action with_price">
              {status === applicationConstants.STATUS_ACTIVE &&
              (!applicationHelper.checkRunsExist(application) ||
                !applicationHelper.checkDatesValid(application)) &&
              (user.role_id !== roleConstants.ROLE_FIRMUSER || employee_id === user.id) ? (
                <Button className="decline-btn" onClick={handleRemove(id)}>
                  Удалить
                </Button>
              ) : (
                ''
              )}

              {status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES && (
                <Button className="decline-btn" onClick={handleWithdraw(id)}>
                  Отозвать
                </Button>
              )}
              <Link to={`${urlHelper.getHomeUrl(user.role_id)}/applications/view/${id}`}>
                <Button className="no-margin moreinfo-btn">Подробнее</Button>
              </Link>
            </div>
          </div>
        )}
      </CardActions>
    </Card>
  );
};

const mapStateToProps = state => {
  const { userApplications, authentication } = state;
  return {
    authentication,
    userApplications
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { application } = props;
  const handleWithdraw = id => {
    return e => dispatch(applicationActions.withdraw(id));
  };

  const handleRemove = id => {
    return e => dispatch(applicationActions.delete(id));
  };

  const handleFileUpload = createFileUploadHandler(
    dispatch,
    fileConstants.TYPE_APPLICATION_PAY,
    (fileInfo, uploaderId) =>
      applicationActions.uploadPayFile(application.id, fileInfo, uploaderId),
    { type: applicationConstants.PAY_UPLOAD_FAILURE }
  );

  const handleFileRemove = id => {
    dispatch(applicationActions.removePayFile(id));
  };

  return {
    handleWithdraw,
    handleRemove,
    handleFileRemove,
    handleFileUpload,
    dispatch
  };
};

const connectedApplicationCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationCard);
export { connectedApplicationCard as ApplicationCard };
