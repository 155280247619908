import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { Field } from 'redux-form';
import { FormFieldReg } from './FormFieldReg';

export const FileUploader = props => {
  const {
    entity,
    inputClassName,
    className,
    buttonText,
    fileList,
    handleFileChange,
    handleFileRemove,
    hasError,
    uploaders,
    multiple = false
  } = props;
  return (
    <div className={className}>
      <Field name={`${entity}_files`} type="hidden" label="" component={FormFieldReg} />
      <input
        className={inputClassName}
        id={`${entity}-upload-button`}
        multiple={multiple}
        type="file"
        onChange={handleFileChange}
      />

      <label className="file_btn" htmlFor={`${entity}-upload-button`}>
        <Button variant="contained" component="span" className="btn">
          {buttonText}
        </Button>
      </label>
      <div id={`${entity}-file-list`}>
        {fileList &&
          fileList.map((file, index) =>
            file ? (
              <div key={index}>
                <a target="_blank" href={file.url ? file.url : ''}>
                  {file.name ? file.name : ''}
                </a>
                <a onClick={e => handleFileRemove(file.id)}>
                  &nbsp;
                  <CloseIcon className="delete_file" />
                </a>
              </div>
            ) : (
              ''
            )
          )}
        {uploaders &&
          uploaders.map((uploader, index) => (
            <div key={index}>
              <span>{`Загрузка файла ${uploader.name}`}</span>
            </div>
          ))}
      </div>
      {hasError ? <span className="danger">Ошибка загрузки файла</span> : ''}
      <br />
    </div>
  );
};

export default FileUploader;
