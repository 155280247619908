import React from 'react';
import { Route } from 'react-router-dom';
import { MainHeaderBlock } from './blocks/MainHeaderBlock/MainHeaderBlock';
import { MainFooterBlock } from './blocks/MainFooterBlock/MainFooterBlock';
import { LoginPage } from './login/LoginPage';
import RegisterForm from './register/RegisterForm';
import { authActions } from '../../actions/authActions';
import AgreementPage from './pages/AgreementPage';
import PersonalPage from './pages/PersonalPage';
import InstructionsPage from './pages/InstructionsPage';
import VersionPage from './pages/VersionPage';

const registerSubmit = (values, dispatch) => {
  const submitValues = {
    ...values,
    legal_form_id: values.legal_form_id ? values.legal_form_id.id : ''
  };

  return dispatch(authActions.register(submitValues));
};

const MainLayout = props => {
  const isLogin =
    props.match.path === '/' ||
    props.match.path === '/login' ||
    props.match.path === '/instructions' ||
    props.match.path === '/personal' ||
    props.match.path === '/agreement' ||
    props.match.path === '/version';

  return (
    <div>
      <div className={isLogin ? 'login-bg' : 'no-login-page'} />

      <MainHeaderBlock />

      <Route exact path="/" component={LoginPage} />
      <Route exact path="/agreement" component={AgreementPage} />
      <Route exact path="/personal" component={PersonalPage} />
      <Route exact path="/instructions" component={InstructionsPage} />
      <Route exact path="/version" component={VersionPage} />

      <MainFooterBlock />

      <RegisterForm onSubmit={registerSubmit} />
    </div>
  );
};

export default MainLayout;
