import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { withSnackbar } from 'notistack';
import { roleConstants } from '../../../../constants/roleConstants';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { applicationActions } from '../../../../actions/applicationActions';

class ApplicationSaveButton extends Component {
  render() {
    const {
      application,
      user,
      handleSave,
      cls,
      routeChanged,
      adminApplications,
      toggleEditMap,
      comment,
      enqueueSnackbar,
      apvgk_list
    } = this.props;

    const editable =
      ((application.status === applicationConstants.STATUS_REVIEW ||
        application.status === applicationConstants.STATUS_REPEAT) &&
        (user.role_id === roleConstants.ROLE_ADMIN ||
          user.role_id === roleConstants.ROLE_OFFICER)) ||
      (application.status === applicationConstants.STATUS_DECLINE &&
        user.role_id === roleConstants.ROLE_ADMIN);
    if (!editable) {
      return null;
    }
    if (adminApplications.editMap) {
      if (routeChanged) {
        const saveHandler = handleSave(application.id);
        let message = null;
        if (Array.isArray(apvgk_list) && apvgk_list.length > 0) {
          let a = apvgk_list.findIndex((v) => v.direction === 'null' || !v.direction );
          if (a !== -1) message = 'Необходимо указать направления по всем АПВГК'
        }
        if (!message && !comment) message = 'Необходимо указать комментарий изменения маршрута!';
        const handler = !message
          ? () => saveHandler()
          : () =>
              enqueueSnackbar(message, {
                variant: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                }
              });
        return (
          <Button className={cls} onClick={handler}>
            Сохранить маршрут
          </Button>
        );
      }
      return (
        <Button className={cls} onClick={toggleEditMap(false)}>
          Отменить изменение маршрута
        </Button>
      );
    }

    return (
      <Button className={cls} onClick={toggleEditMap(true)}>
        Изменить маршрут
      </Button>
    );
  }
}

const mapStateToProps = state => {
  const { adminApplications } = state;
  const selector = formValueSelector('application-view-form');
  const comment = selector(state, 'comment');
  const apvgk_list = selector(state, 'apvgk_list')

  return {
    adminApplications,
    comment,
    apvgk_list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;

  const { adminApplications, comment, apvgk_list} = stateProps;
  const { adminRoute } = adminApplications;
  const { markers, routeChanged } = adminRoute;
  const { haveOtherErrors } = ownProps;

  const handleSave = id => {
    // validate
    if (!haveOtherErrors && comment && typeof comment !== 'undefined') {
      return () => dispatch(applicationActions.accept(id, comment, markers, routeChanged, 0, 0, apvgk_list));
    }

    return () => Promise.resolve();
  };
  const toggleEditMap = editMap => {
    return () => dispatch(applicationActions.toggleEditMap(editMap));
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,

    routeChanged,
    handleSave,
    toggleEditMap
  };
};

const connectedApplicationSaveButton = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withSnackbar(ApplicationSaveButton));
export { connectedApplicationSaveButton as ApplicationSaveButton };
