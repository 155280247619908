import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  root: {
    marginLeft: -6,
    marginBottom: -6
  }
};

const MaterialCheckbox = ({
  field, // { name, value, onChange, onBlur }
  label,
  classes,
  ...props
}) => (
  <FormControlLabel
    className={classes.root}
    control={<Checkbox checked={field.value} color="primary" {...field} {...props} />}
    label={label}
  />
);

export default withStyles(styles)(MaterialCheckbox);
