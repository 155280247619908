import React, { useContext } from 'react';
import { Formik, Field, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import Select from 'react-select';

import { Loader } from '../../../../../components';
import { IssuePlaces } from '../../../../../helpers/dictionaryHelper';
import MaterialInput from '../../../quickApplications/CreateQuickApplication/components/MaterialInput';
import FieldLabel from '../FieldLabel';
import SelectAll from '../../../../../components/form/SelectAll';
import { StateContext } from '../StateContext';
import { minLengthText } from '../../../../../helpers/fieldValidators';
import MaterialSelect from '../../../quickApplications/CreateQuickApplication/components/MaterialSelect';

export const TYPE_OPTIONS = [
  { value: 'normal', label: 'От заявителя' },
  { value: 'fast', label: 'Быстрое' },
  { value: 'smev', label: 'Запрос от СМЭВ' },
  { value: 'in_work', label: 'В работе' },
  { value: 'rejected', label: 'Отклоненные' },
  { value: 'revision', label: 'На доработку' }
];
export const STATUS_OPTIONS = [
  { value: '1', label: 'Со статусом' },
  { value: '2', label: 'Без статуса' },
];

const ReportsFilterForm = ({ initialValues, onSubmit, onClose }) => {
  const {
    reportsState: { isLoading },
    departments: { data: { data = [] } = {}, isResolved }
  } = useContext(StateContext);
  const options = data.map(({ id, title }) => ({ value: id, label: title }));
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        onSubmit(values);
        onClose();
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        fio: Yup.string().min(3, minLengthText(3))
      })}
      render={({ values, setFieldValue }) => (
        <Form>
          <Grid container direction="column" spacing={16} wrap="nowrap">
            <Grid item>
              <FieldLabel label="По номеру разрешения">
                <Field name="id" component={MaterialInput} placeholder="№ разрешения" />
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="По территориальному управлению">
                <Field
                  placeholder="Выберите территориальное управление"
                  name="issue_place_id"
                  component={Select}
                  isClearable
                  value={values.issue_place_id}
                  options={IssuePlaces.map(({ id, title }) => ({
                    value: id,
                    label: title
                  }))}
                  onChange={value => setFieldValue('issue_place_id', value)}
                />
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="По ФИО/названию организации" name="name">
                <Field
                  name="name"
                  component={MaterialInput}
                  placeholder="По ФИО/названию организации"
                />
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="По дате подачи">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Field
                      name="create_date_from"
                      component={MaterialInput}
                      placeholder="Начало периода"
                      type="date"
                    />
                  </Grid>
                  <Grid item>
                    <span>—</span>
                  </Grid>
                  <Grid item>
                    <Field
                      name="create_date_to"
                      component={MaterialInput}
                      placeholder="Конец периода"
                      type="date"
                    />
                  </Grid>
                </Grid>
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="По дате одобрения">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Field
                      name="accept_date_from"
                      component={MaterialInput}
                      placeholder="Начало периода"
                      type="date"
                    />
                  </Grid>
                  <Grid item>
                    <span>—</span>
                  </Grid>
                  <Grid item>
                    <Field
                      name="accept_date_to"
                      component={MaterialInput}
                      placeholder="Конец периода"
                      type="date"
                    />
                  </Grid>
                </Grid>
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="По дате активации">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Field
                      name="activate_date_from"
                      component={MaterialInput}
                      placeholder="Начало периода"
                      type="date"
                    />
                  </Grid>
                  <Grid item>
                    <span>—</span>
                  </Grid>
                  <Grid item>
                    <Field
                      name="activate_date_to"
                      component={MaterialInput}
                      placeholder="Конец периода"
                      type="date"
                    />
                  </Grid>
                </Grid>
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="Тип разрешения">
                <Field
                  name="type"
                  component={MaterialSelect}
                  placeholder="Выберите тип разрешения"
                  options={TYPE_OPTIONS}
                  isMulti
                />
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="Cтатус">
                <Field
                    name="privilege"
                    component={MaterialSelect}
                    placeholder="Со статусом и без"
                    options={STATUS_OPTIONS}
                    isMulti
                />
              </FieldLabel>
            </Grid>
            {isResolved && (
              <Grid item>
                <FieldLabel label="По ведомству согласования">
                  <Field
                    placeholder="Выберите ведомство(а)"
                    name="departments"
                    component={SelectAll}
                    allowSelectAll
                    value={values.departments || []}
                    options={options}
                    allOption={{ value: 0, label: 'Все ведомства' }}
                    onChange={value => setFieldValue('departments', value)}
                    isMulti
                    menuPlacement="auto"
                    closeMenuOnSelect={false}
                    menuPortalTarget={document.querySelector('body > div[role=dialog]')}
                  />
                </FieldLabel>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="btn-add no-margin"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && <Loader size={24} />} &nbsp; Применить
              </Button>
              &nbsp;
              <Button variant="outlined" component="span" className="info-btn" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
          </Grid>
          <br />
        </Form>
      )}
    />
  );
};

export default ReportsFilterForm;
