import { coefficientConstants } from '../constants/coefficientConstants';
import { coefficientService } from '../services/coefficientService';

const getPage = page => {
  const request = page => {
    return { type: coefficientConstants.GET_PAGE_REQUEST, page };
  };

  const success = coefficients => {
    return { type: coefficientConstants.GET_PAGE_SUCCESS, coefficients };
  };

  const failure = error => {
    return { type: coefficientConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(page));

    coefficientService
      .getPage(page)
      .then(coefficients => dispatch(success(coefficients)), error => dispatch(failure(error)));
  };
};

const getAll = () => {
  const request = () => {
    return { type: coefficientConstants.GET_ALL_REQUEST };
  };

  const success = coefficients => {
    return { type: coefficientConstants.GET_ALL_SUCCESS, coefficients };
  };

  const failure = error => {
    return { type: coefficientConstants.GET_ALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    coefficientService
      .getAll()
      .then(coefficients => dispatch(success(coefficients)), error => dispatch(failure(error)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: coefficientConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: coefficientConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: coefficientConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    coefficientService
      .delete(id)
      .then(coefficient => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: coefficientConstants.LOAD_REQUEST, id };
  };

  const success = coefficient => {
    return { type: coefficientConstants.LOAD_SUCCESS, coefficient };
  };

  const failure = error => {
    return { type: coefficientConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    coefficientService
      .getItem(id)
      .then(coefficient => dispatch(success(coefficient)), error => dispatch(failure(error)));
  };
};

const create = values => {
  const request = value => {
    return { type: coefficientConstants.CREATE_REQUEST, value };
  };

  const success = coefficient => {
    return { type: coefficientConstants.CREATE_SUCCESS, coefficient };
  };

  return dispatch => {
    dispatch(request(values));

    return coefficientService.create(values).then(coefficient => dispatch(success(coefficient)));
  };
};

const update = values => {
  const request = value => {
    return { type: coefficientConstants.UPDATE_REQUEST, value };
  };

  const success = coefficient => {
    return { type: coefficientConstants.UPDATE_SUCCESS, coefficient };
  };

  return dispatch => {
    dispatch(request(values));

    return coefficientService.update(values).then(coefficient => dispatch(success(coefficient)));
  };
};

const openFormModal = () => {
  return { type: coefficientConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: coefficientConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: coefficientConstants.SET_CURRENT_ITEM, item };
};

export const coefficientActions = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,

  openFormModal,
  closeFormModal,
  setCurrentItem
};
