import React from 'react';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import ApplicationField from './ApplicationField';
import { momentHelper } from '../../../helpers/momentHelper';

const ApplicationBaseInfo = ({ application }) => {
  return (
    <div className="row">
      <div className="col-md-2">
        <p>Вид перевозки</p>
      </div>
      <div className="col-md-6">
        <ApplicationField
          value={dictionaryHelper.routeTypes.getItemTitle(
            application.privilege_status_id ? 2 : application.route.type_id
          )}
        />
      </div>
      <div className="col-md-1">
        <p>Год</p>
      </div>
      <div className="col-md-3">
        <ApplicationField value={momentHelper.getYearFromDotFormat(application.finish_date)} />
      </div>
      <div className="col-md-2">
        <p>Количество поездок. Осталось / всего</p>
      </div>
      <div className="col-md-2">
        <ApplicationField
          placeholder={10}
          value={
            application.dates
              ? `${application.dates.runs_count - application.dates.runs_used}/${
                  application.dates.runs_count
                }`
              : ''
          }
        />
      </div>
      <div className="col-md-1">
        <p>Период&nbsp;с</p>
      </div>
      <div className="col-md-3">
        <ApplicationField value={application.start_date} />
      </div>
      <div className="col-md-1">
        <p>по</p>
      </div>
      <div className="col-md-3">
        <ApplicationField value={application.finish_date} />
      </div>
    </div>
  );
};

export default ApplicationBaseInfo;
