import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const DetailsLink = ({ id }) => (
  <Link to={`cooperation/view/${id}`}>
    <Button variant="text" className="info-btn" color="primary">
      Подробнее
    </Button>
  </Link>
);

export default DetailsLink;
