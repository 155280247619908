import { find, uniqueId } from 'lodash';
import { change } from 'redux-form';

import { apvgkReportsConstants } from '../constants/apvgkReportsConstants';
import { apvgkReportsService } from '../services/apvgkReportsService';
import { momentHelper } from '../helpers/momentHelper';

const openFilterModal = () => {
  return { type: apvgkReportsConstants.FILTER_MODAL_OPEN };
};
const closeFilterModal = () => {
  return { type: apvgkReportsConstants.FILTER_MODAL_CLOSE };
};


const getApvgkReports = values => {
  const request = values => {
    return { type: apvgkReportsConstants.GET_PAGE_BY_STATUS_REQUEST, values };
  };

  const success = apvgkReports => {
    return {
      type: apvgkReportsConstants.GET_PAGE_BY_STATUS_SUCCESS,
      apvgkReports
    };
  };

  const failure = error => {
    return { type: apvgkReportsConstants.GET_PAGE_BY_STATUS_FAILURE, error };
  };

  return dispatch => {

    if (!values.export) {
        dispatch(request(values));
        apvgkReportsService
            .getApvgkReports(values)
            .then(apvgkReports => dispatch(success(apvgkReports)), error => dispatch(failure(error)));
    } else {
        apvgkReportsService
            .getApvgkReports(values);
    }
  };
};
const clearItems = () => {
    const success = () => {
        return {
            type: apvgkReportsConstants.GET_PAGE_BY_STATUS_SUCCESS,
            apvgkReports:[]
        };
    };
    return dispatch => {
        dispatch(success());
    };
};
const getItem = (id, onSuccess = false) => {
  const request = id => {
    return { type: apvgkReportsConstants.GET_ITEM_REQUEST, id };
  };

  const success = apvgkReport => {
    return { type: apvgkReportsConstants.GET_ITEM_SUCCESS, data: apvgkReport };
  };

  const failure = error => {
    return { type: apvgkReportsConstants.GET_ITEM_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    apvgkReportsService.getItem(id).then(
      apvgkReports => {
        dispatch(success(apvgkReports));
        if (onSuccess) {
          onSuccess(dispatch, apvgkReports.data);
        }
      },
      error => dispatch(failure(error))
    );
  };
};
const getReasons = (id, onSuccess = false) => {

    const success = reasons => {
        return { type: apvgkReportsConstants.GET_REASONS_SUCCESS, data: reasons };
    };

    const failure = error => {
        return { type: apvgkReportsConstants.GET_REASONS_FAILURE, id, error };
    };

    return dispatch => {

        return apvgkReportsService.getReasons().then(
            reasons => {
                dispatch(success(reasons.data));
                if (onSuccess) {
                    onSuccess(dispatch, reasons);
                }
                return Promise.resolve(reasons)
            },
            error => {
                dispatch(failure(error))
                return Promise.reject(error)
            }
        );
    };
};



const resetItem = () => {
  return { type: apvgkReportsConstants.RESET_CURRENT_ITEM };
};

const setTab = (tabIndex, status) => {
  return { type: apvgkReportsConstants.SET_TAB_INDEX, tabIndex, status };
};

const accept = ({id, data}) => {
  const request = id => {
    return { type: apvgkReportsConstants.ACCEPT_REQUEST, id };
  };

  const success = apvgkReports => {
    return {
      type: apvgkReportsConstants.ACCEPT_SUCCESS,
      apvgkReports: apvgkReports.data,
    };
  };

  const failure = (id, error) => {
    return { type: apvgkReportsConstants.ACCEPT_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    return apvgkReportsService.accept(id, data).then(
      apvgkReports => {
        return dispatch(success(apvgkReports));
      },
      error => {
        return dispatch(failure(id, error));
      }
    );
  };
};

const decline = values => {
    const request = values => {
        return { type: apvgkReportsConstants.DECLINE_REQUEST, values };
    };

    const success = apvgkReports => {
        return {
            type: apvgkReportsConstants.DECLINE_SUCCESS,
            apvgkReports: apvgkReports.data
        };
    };

    const failure = (id, error) => {
        return { type: apvgkReportsConstants.DECLINE_FAILURE, id, error };
    };

    return dispatch => {
        dispatch(request(values));

        apvgkReportsService.decline(values).then(
            apvgkReports => {
                dispatch(success(apvgkReports));
            },
            error => {
                dispatch(failure(values.id, error));
            }
        );
    };
};



const _delete = id => {
  const request = id => {
    return { type: apvgkReportsConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: apvgkReportsConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: apvgkReportsConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    apvgkReportsService
      .delete(id)
      .then(apvgkReports => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};





const toWorkMultiple = values => {
  const request = () => {
    return { type: apvgkReportsConstants.TO_WORK_MULTIPLE_REQUEST };
  };

  const success = data => {
    return { type: apvgkReportsConstants.TO_WORK_MULTIPLE_SUCCESS, data };
  };

  const failure = error => {
    return { type: apvgkReportsConstants.TO_WORK_MULTIPLE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    return apvgkReportsService.toWorkMultiple(values).then(
      response => {
        dispatch(success(response.data));
        return Promise.resolve(response)
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error)
      }
    );
  };
};

const setCurrentItem = item => {
    return { type: apvgkReportsConstants.SET_CURRENT_ITEM, item };
};


export const apvgkReportsActions = {
  openFilterModal,
  closeFilterModal,
  getApvgkReports,
  clearItems,
  getItem,
  resetItem,
  accept,
  decline,
  setTab,
  delete: _delete,
  toWorkMultiple,
  setCurrentItem,
  getReasons,
};
