import { privilegeVehicleService } from '../services/privilegeVehicleService';
import { privilegeVehicleConstants } from '../constants/privilegeVehicleConstants';

const getVehicles = (privilege_status_id, values) => {
  const request = v => {
    return { type: privilegeVehicleConstants.GET_VEHICLES, v };
  };

  const success = privilegeVehicles => {
    return {
      type: privilegeVehicleConstants.GET_VEHICLES_SUCCESS,
      privilegeVehicles
    };
  };

  const failure = error => {
    return { type: privilegeVehicleConstants.GET_VEHICLES_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    privilegeVehicleService
      .getVehicles(privilege_status_id, values)
      .then(
        privilegeVehicles => dispatch(success(privilegeVehicles)),
        error => dispatch(failure(error))
      );
  };
};

const getAll = () => {
  const request = () => {
    return { type: privilegeVehicleConstants.GET_ALL_REQUEST };
  };

  const success = privilegeVehicles => {
    return {
      type: privilegeVehicleConstants.GET_ALL_SUCCESS,
      privilegeVehicles
    };
  };

  const failure = error => {
    return { type: privilegeVehicleConstants.GET_ALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    privilegeVehicleService
      .getAll()
      .then(
        privilegeVehicles => dispatch(success(privilegeVehicles)),
        error => dispatch(failure(error))
      );
  };
};

const _delete = id => {
  const request = id => {
    return { type: privilegeVehicleConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: privilegeVehicleConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: privilegeVehicleConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    privilegeVehicleService
      .delete(id)
      .then(privilegeVehicle => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: privilegeVehicleConstants.LOAD_REQUEST, id };
  };

  const success = privilegeVehicle => {
    return { type: privilegeVehicleConstants.LOAD_SUCCESS, privilegeVehicle };
  };

  const failure = error => {
    return { type: privilegeVehicleConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    privilegeVehicleService
      .getItem(id)
      .then(
        privilegeVehicle => dispatch(success(privilegeVehicle)),
        error => dispatch(failure(error))
      );
  };
};

const create = values => {
  const request = value => {
    return { type: privilegeVehicleConstants.CREATE_REQUEST, value };
  };

  const success = privilegeVehicle => {
    return { type: privilegeVehicleConstants.CREATE_SUCCESS, privilegeVehicle };
  };

  return dispatch => {
    dispatch(request(values));

    return privilegeVehicleService
      .create(values)
      .then(privilegeVehicle => dispatch(success(privilegeVehicle)));
  };
};

const update = values => {
  const request = value => {
    return { type: privilegeVehicleConstants.UPDATE_REQUEST, value };
  };

  const success = privilegeVehicle => {
    return { type: privilegeVehicleConstants.UPDATE_SUCCESS, privilegeVehicle };
  };

  return dispatch => {
    dispatch(request(values));

    return privilegeVehicleService
      .update(values)
      .then(privilegeVehicle => dispatch(success(privilegeVehicle)));
  };
};

const openFormModal = () => {
  return { type: privilegeVehicleConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: privilegeVehicleConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: privilegeVehicleConstants.SET_CURRENT_ITEM, item };
};

const uploadVehiclesXls = (values, privilege_status_id) => {
  const request = data => {
    return { type: privilegeVehicleConstants.EXPORT_UPLOAD_REQUEST, data };
  };
  const success = privilegeVehicles => {
    return {
      type: privilegeVehicleConstants.EXPORT_UPLOAD_SUCCESS,
      privilegeVehicles
    };
  };

  return dispatch => {
    dispatch(request(values));

    return privilegeVehicleService
      .exportXls(values, privilege_status_id)
      .then(response => dispatch(success(response)))
      .catch(err => {
        console.log('Upload error', err);
      });
  };
};

export const privilegeVehicleActions = {
  getVehicles,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,

  openFormModal,
  closeFormModal,
  setCurrentItem,
  uploadVehiclesXls
};
