import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { icon } from 'leaflet';

export const iconOptions = {
  iconSize: [25, 41],
  iconAnchor: [12, 41]
};

const configs = [
  {
    condition: index => index === 0,
    Icon: icon({
      iconUrl:
        'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
      ...iconOptions
    }),
    name: 'Отправление:'
  },
  {
    condition: (index, count) => index === count - 1,
    Icon: icon({
      iconUrl:
        'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png',
      ...iconOptions
    }),
    name: 'Прибытие: '
  },
  {
    condition: (index, count) => index !== 0 && index !== count - 1,
    Icon: icon({
      iconUrl:
        'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png',
      ...iconOptions
    }),
    name: 'Промежуточная точка: '
  }
];

const SingleMarker = ({ marker, index, count }) => {
  const position = [marker.coords.lat, marker.coords.lng];
  const { name, Icon } = configs.find(c => c.condition(index, count));
  return (
    <Marker position={position} icon={Icon}>
      <Popup position={position}>
        <span>
          <b>{name}</b> {marker.text}
        </span>
      </Popup>
    </Marker>
  );
};

const DrawMarkers = ({ markers }) => {
  const filteredMarkers = markers
    .filter(marker => marker.coords && marker.coords.lat)
    .map(marker => ({
      ...marker,
      coords: {
        ...marker.coords,
        lng: marker.coords.lng || marker.coords.lon
      }
    }));
  return (
    <React.Fragment>
      {filteredMarkers.map((marker, index) => (
        <SingleMarker
          key={marker.id}
          marker={marker}
          index={index}
          count={filteredMarkers.length}
        />
      ))}
    </React.Fragment>
  );
};

export default DrawMarkers;
