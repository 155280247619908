import { routeAddressConstants } from '../constants/routeAddressConstants';
import { authConstants } from '../constants/authConstants';

const createItem = (items, item) => {
  items.unshift(item);
  return items;
};
const updateItem = (items, item) => {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== item.id) {
      result.push(items[i]);
    } else {
      result.push(item);
    }
  }
  return result;
};
const suggestions = [];
const values = [];
for (let i = 0; i < 20; i++) {
  suggestions.push([]);
  values.push('');
}

const initialState = {
  showFormModal: false,
  currentItem: {},
  meta: { per_page: 7, total: 0, current_page: 1 },
  items: [],
  suggestions,
  values,
  filters: {}
};

export const routeAddresses = (state = initialState, action) => {
  switch (action.type) {
    // Get page
    case routeAddressConstants.GET_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        filters: action.filters
      };
    case routeAddressConstants.GET_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.routeAddresses.data,
        meta: action.routeAddresses.meta
      };
    case routeAddressConstants.GET_PAGE_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Get all
    case routeAddressConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case routeAddressConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.routeAddresses.data
      };
    case routeAddressConstants.GET_ALL_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Create
    case routeAddressConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: createItem(state.items, action.routeAddress),
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        },
        showFormModal: false
      };

    // Update
    case routeAddressConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: updateItem(state.items, action.routeAddress),
        showFormModal: false
      };

    // Delete
    case routeAddressConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(routeAddress =>
          routeAddress.id === action.id ? { ...routeAddress, deleting: true } : routeAddress
        )
      };
    case routeAddressConstants.DELETE_SUCCESS:
      // remove deleted HeaderMenu from state
      return {
        ...state,
        loading: false,
        items: state.items.filter(user => user.id !== action.id),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    case routeAddressConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to HeaderMenu
      return {
        ...state,
        loading: false,
        items: state.items.map(routeAddress => {
          if (routeAddress.id === action.id) {
            // make copy of HeaderMenu without 'deleting:true' property
            const { deleting, ...routeAddressCopy } = routeAddress;
            // return copy of HeaderMenu with 'deleteError:[error]' property
            return { ...routeAddressCopy, deleteError: action.error };
          }

          return routeAddress;
        })
      };

    // Get item
    case routeAddressConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case routeAddressConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.routeAddress.data
      };
    case routeAddressConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // form modal
    case routeAddressConstants.FORM_MODAL_OPEN:
      return {
        ...state,
        showFormModal: true
      };
    case routeAddressConstants.FORM_MODAL_CLOSE:
      return {
        ...state,
        showFormModal: false
      };
    case routeAddressConstants.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item
      };

    case routeAddressConstants.SET_SUGGESTIONS:
      return {
        ...state,
        suggestions: state.suggestions.map((suggestionsList, index) =>
          index === action.index ? action.suggestions : suggestionsList
        )
      };
    case routeAddressConstants.SET_VALUE:
      return {
        ...state,
        values: state.values.map((value, index) => (index === action.index ? action.value : value))
      };

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
