import React, { useState } from 'react';
import Select from './Creatable';
import { vehicleModelService } from '../../../../../services/vehicleModelService';

const SelectModel = ({ brand, isTrailer = false, ...props }) => {
  const [fetched, setFetched] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const fetch = () => {
    if (!brand) {
      return;
    }
    setLoading(true);
    vehicleModelService.getAll(brand).then(res => {
      setData(res.data);
      setLoading(false);
      setFetched(true);
    });
  };
  return (
    <Select
      {...props}
      key={brand}
      noOptions="Модель не найдена"
      placeholder="Выбрать Модель ТС"
      fetchProps={[brand]}
      fetched={fetched}
      fetch={fetch}
      isLoading={isLoading}
      data={data}
      filter={option => option.is_trailer === (isTrailer ? 1 : 0)}
    />
  );
};

export default SelectModel;
