import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import { applicationActions } from '../../../actions/applicationActions';
import ApplicationPrintForm from './ApplicationPrintForm';

const printSubmit = (values, dispatch) => {
  return dispatch(applicationActions.printPdf(values));
};

const ApplicationPrintModal = ({
  adminApplications,
  handleModalClose,
  enqueueSnackbar,
  application
}) => {
  React.useEffect(() => {
    if (adminApplications.printError) {
      enqueueSnackbar(adminApplications.printError, { variant: 'error' });
    }
  }, [adminApplications.printError]);

  return (
    <Dialog
      className="dialog-window"
      open={adminApplications.showPrintModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Печать
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>
      <DialogContent className="dialog">
        <ApplicationPrintForm onSubmit={printSubmit} appliaction={application} />
        <br />
        {adminApplications.printLink && (
          <a target="_blank" href={adminApplications.printLink}>
            Предпромотр документа
          </a>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { adminApplications } = state;

  return {
    adminApplications
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { adminApplications } = stateProps;
  const { dispatch } = dispatchProps;

  const handleModalClose = () => {
    dispatch(applicationActions.closePrintModal());
  };

  return {
    ...ownProps,
    adminApplications,
    handleModalClose
  };
};

const connectedApplicationPrintModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withSnackbar(ApplicationPrintModal));

export { connectedApplicationPrintModal as ApplicationPrintModal };
