import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {apvgkService} from "../../../services/apvgkService";
import {Loader} from "../../../components";


const PhotoModal = ({ id, params = {}, label= 'Фото' }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const fetchFiles = async () =>  {
        try {
            const response = await apvgkService.getTrackPhotos(id, params);
            setData(response);
            setLoaded(true);
        } catch (e) {
            console.log(e)
            setData({files: ['Фотографии ТС отстуствуют']})
            setLoaded(false);
        }
    }
    React.useEffect(() => {
        if(open && !loaded) fetchFiles()
    }, [open])
    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }


    return (
        <>
            <Link
                style={{padding: "0 2px"}}
                to={{}}
                onClick={handleClickOpen}
            >
                {label}
            </Link>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Фотографии ТС
                    </Typography>
                    <IconButton color="inherit" onClick={handleClose}>
                        <CloseIcon className="blue" />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    {data === null ? <Loader/> : <div className="col-xs-12 overflow">
                        {data.files.map((v) => {
                            return v === `${v}` ? v :  <Paper variant="outlined"><img
                                style={{maxWidth: '100%'}}
                                src={`data:image/png;base64,${v.file}`}
                            /></Paper>
                        })}
                    </div>
                    }
                </DialogContent>
            </Dialog>
        </>
    );
};

export default PhotoModal;
