import React, { useContext } from 'react';
import { Formik, Field, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';

import { Loader } from '../../../../../components';
import MaterialInput from '../../../quickApplications/CreateQuickApplication/components/MaterialInput';
import SelectAll from '../../../../../components/form/SelectAll';
import { StateContext } from '../StateContext';
import { minLengthText } from '../../../../../helpers/fieldValidators';
import FieldLabel from '../../../reports/components/FieldLabel';

const AuditFilterForm = ({ initialValues, onSubmit, onClose }) => {
  const {
    auditState: { isLoading },
    eventTypesState: { data: { events = [] } = {}, isResolved: eventsResolved },
    controlPostsState: { data: { data: posts = [] } = {}, isResolved: postsResolved }
  } = useContext(StateContext);
  const actionOptions = Object.keys(events).map(k => ({
    value: k,
    label: events[k]
  }));
  const postsOptions = posts.map(({ id, title }) => ({
    value: id,
    label: title
  }));
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        onSubmit(values);
        onClose();
      }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        fio: Yup.string().min(3, minLengthText(3))
      })}
      render={({ values, setFieldValue }) => (
        <Form>
          <Grid container direction="column" spacing={16} wrap="nowrap">
            <Grid item>
              <FieldLabel label="По номеру заявления">
                <Field
                  disabled={!!values.grz}
                  name="app_id"
                  component={MaterialInput}
                  placeholder="№ заявления"
                />
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="По ФИО сотрудника" name="fio">
                <Field name="fio" component={MaterialInput} placeholder="ФИО сотрудника" />
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="По ГРЗ">
                <Field
                  disabled={!!values.app_id}
                  name="grz"
                  component={MaterialInput}
                  placeholder="ГРЗ"
                />
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="По периоду действия">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Field
                      name="from"
                      component={MaterialInput}
                      placeholder="Начало периода"
                      type="date"
                    />
                  </Grid>
                  <Grid item>
                    <span>—</span>
                  </Grid>
                  <Grid item>
                    <Field
                      name="to"
                      component={MaterialInput}
                      placeholder="Конец периода"
                      type="date"
                    />
                  </Grid>
                </Grid>
              </FieldLabel>
            </Grid>
            {eventsResolved && (
              <Grid item>
                <FieldLabel label="По действию">
                  <Field
                    placeholder="Выберите действие(я)"
                    name="actionTypes"
                    component={SelectAll}
                    allowSelectAll
                    value={values.actionTypes || []}
                    options={actionOptions}
                    allOption={{ value: 0, label: 'Все действия' }}
                    onChange={value => setFieldValue('actionTypes', value)}
                    menuPlacement="auto"
                    isMulti
                    closeMenuOnSelect={false}
                  />
                </FieldLabel>
              </Grid>
            )}
            {postsResolved && (
              <Grid item>
                <FieldLabel label="По ПВК">
                  <Field
                    placeholder="Выберите ПВК"
                    name="posts"
                    component={SelectAll}
                    allowSelectAll
                    value={values.posts || []}
                    options={postsOptions}
                    allOption={{ value: 0, label: 'Все ПВК' }}
                    onChange={value => setFieldValue('posts', value)}
                    menuPlacement="auto"
                    isMulti
                    closeMenuOnSelect={false}
                  />
                </FieldLabel>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="btn-add no-margin"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && <Loader size={24} />} &nbsp; Применить
              </Button>
              &nbsp;
              <Button variant="outlined" component="span" className="info-btn" onClick={onClose}>
                Отменить
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default AuditFilterForm;
