import React, { useState, useEffect } from 'react';
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Grid,
  Checkbox
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { userService } from '../../../services/userService';
import { Loader, TablePagination } from '../../../components';
import UsersFilter from '../users/UsersFilter';
import { vehicleService } from '../../../services/vehicleService';

const VehicleAccessModalList = ({ vehicle, enqueueSnackbar }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filters, setFilters] = useState({});
  const [response, setResponse] = useState({});
  const [firmUsers, setFirmUsers] = useState([]);

  async function fetch(page = 1, newFilters = filters, per_page = 20) {
    try {
      setError(false);
      setLoading(true);
      const resp = await userService.getFirmUsers(page, { per_page, ...newFilters });
      setResponse(resp);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }

  const onFiltersChange = async newFilters => {
    setFilters(filters);
    fetch(1, newFilters, response.meta.per_page);
  };

  useEffect(() => {
    async function loadFirmUsers() {
      const vehicleResp = await vehicleService.getItem(vehicle.id);
      setFirmUsers(vehicleResp.data.firm_users);
    }
    fetch(1);
    loadFirmUsers();
  }, []);

  const users = response && response.data ? response.data : [];

  const usersToShow = users;
  const meta = (response && response.meta) || { page: 1, per_page: 20 };

  const onChange = async (user, access, canEdit) => {
    try {
      await userService.updateAccess(user.id, vehicle.id, access, access ? canEdit : 0);
      enqueueSnackbar(`Доступ к ${vehicle.real_number} обновлен для ${user.name}`, {
        variant: 'success'
      });
      let newFirmUsers = firmUsers;
      const oldFirmUser = firmUsers.find(u => u.id === user.id);
      if (access !== (oldFirmUser ? 1 : 0)) {
        newFirmUsers = access
          ? [...firmUsers, { id: user.id, name: user.name, email: user.email, can_edit: canEdit }]
          : firmUsers.filter(f => f.id !== user.id);
      }
      newFirmUsers = newFirmUsers.map(f => {
        if (f.id === user.id) {
          return { ...f, can_edit: canEdit };
        }
        return f;
      });
      setFirmUsers(newFirmUsers);
    } catch (e) {
      enqueueSnackbar('Произошла ошибка, попробуйте позже!', { variant: 'error' });
    }
  };

  const haveNoItems = response && response.data && response.data.length === 0 && !isLoading;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Наименование</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Доступ к ТС</TableCell>
          <TableCell>Редактирование ТС</TableCell>
        </TableRow>
        <UsersFilter filters={filters} setFilters={onFiltersChange} />
      </TableHead>
      <TableBody>
        {(isLoading || error || haveNoItems) && (
          <TableRow>
            <TableCell align="center" colSpan={4}>
              {!error && !isLoading && haveNoItems && <span>Объекты не найдены</span>}
              {error && <span className="text-danger">Ошибка при загрузке данных</span>}
              {isLoading && <Loader />}
            </TableCell>
          </TableRow>
        )}
        {!isLoading &&
          !haveNoItems &&
          !error &&
          usersToShow.map(user => {
            const firmUser = firmUsers.find(f => f.id === user.id);
            return (
              <TableRow key={user.id}>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={!!firmUser}
                    onChange={(_, checked) =>
                      onChange(user, checked ? 1 : 0, firmUser ? firmUser.can_edit : 0)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={!!firmUser && firmUser.can_edit}
                    disabled={!firmUser}
                    onChange={(_, checked) => onChange(user, firmUser ? 1 : 0, checked ? 1 : 0)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
      {!isLoading && (
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item>
            <TablePagination
              onChangePage={page => fetch(page, filters, response.meta.per_page)}
              onChangeRowsPerPage={per_page => fetch(1, filters, per_page)}
              meta={meta}
            />
          </Grid>
        </Grid>
      )}
    </Table>
  );
};

export default withSnackbar(VehicleAccessModalList);
