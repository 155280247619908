import React from 'react';
import { LinearProgress, Typography, withStyles } from '@material-ui/core';
import { ProcessingStatus } from '../../constants/fileConstants';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8
  },
  nameWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  name: {
    fontSize: 14
  },
  date: {
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.54)'
  },
  status: {
    flex: 1,
    marginTop: -5,
    paddingLeft: 20,
    whiteSpace: 'nowrap',
    minWidth: 300
  },
  failedText: {},
  downloadText: {}
};

const New = () => <Typography>Генерация скоро начнется</Typography>;

const Processing = ({ progress }) => <LinearProgress variant="determinate" value={progress} />;

const Failed = () => <Typography className="text-danger">Ошибка генерации файла</Typography>;

const Succeed = ({ url, classes }) => (
  <a href={url} target="_blank" className={classes.downloadText}>
    Скачать
  </a>
);

function determineComponent(item, classes) {
  switch (item.status) {
    case ProcessingStatus.PROCESSING:
      return <Processing progress={item.progress} />;
    case ProcessingStatus.SUCCEED:
      return <Succeed url={item.result.url} classes={classes} />;
    case ProcessingStatus.FAILED:
      return <Failed classes={classes} />;
    default:
      return <New progress={item.progress} />;
  }
}

const ProgressItem = ({ item, classes }) => (
  <div className={classes.root}>
    <div className={classes.nameWrapper}>
      <div className={classes.name}>Файл #{item.id}</div>
      <div className={classes.date}>{item.created_at}</div>
    </div>
    <div className={classes.status}>{determineComponent(item, classes)}</div>
  </div>
);

export default withStyles(styles)(ProgressItem);
