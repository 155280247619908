import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const CabinetMainMenuLink = props => {
  const { hasChild, path, title, img, imgClass, menuClass } = props;

  let isActive = false;
  if (!hasChild) {
    isActive = props.location.pathname === path;
  } else {
    isActive = props.location.pathname.indexOf(path) !== -1;
  }
  // const isActive = props.location.pathname === path;

  const className = `nav-item${isActive ? ' active' : ''}`;
  const linkTitle = img ? <img alt={title} className={imgClass} src={img} /> : title;

  return (
    <li className={className} key={path}>
      <Link className={`nav-link ${menuClass}`} to={path}>
        {linkTitle}
      </Link>
    </li>
  );
};

const connectedCabinetMainMenuLink = withRouter(CabinetMainMenuLink);
export { connectedCabinetMainMenuLink as CabinetMainMenuLink };
