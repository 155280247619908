import React, { useState } from 'react';
import { Button, Grid, Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

const options = [
  'В период весеннего ограничения данное согласование не действительно.',
  'При введении временного ограничения в летний период движение разрешается в период с 22.00 до 10.00 часов.'
];

const AddSpecialText = ({ onAdd }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const onSelect = option => () => {
    onAdd(option);
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="info-btn no-margin"
      >
        <Grid container spacing={8} justify={'center'} alignItems={'center'}>
          <Grid item>Добавить заготовленный текст</Grid>
          <Grid item style={{ display: 'flex' }}>
            <KeyboardArrowDown />
          </Grid>
        </Grid>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem onClick={onSelect(option)}>{option}</MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default AddSpecialText;
