import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { roleConstants } from '../../../../constants/roleConstants';
import { applicationHelper } from '../../../../helpers/applicationHelper';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { applicationActions } from '../../../../actions/applicationActions';
import { get } from 'lodash';

const ApplicationAcceptButton = ({ application, user, handleAccept, isSpring, cls }) => {
  const repeatOrReview =
    application.status === applicationConstants.STATUS_REVIEW ||
    application.status === applicationConstants.STATUS_REPEAT;
  const adminOrOfficer =
    user.role_id === roleConstants.ROLE_ADMIN || user.role_id === roleConstants.ROLE_OFFICER;
  const declinedIfAdmin =
    application.status === applicationConstants.STATUS_DECLINE &&
    user.role_id === roleConstants.ROLE_ADMIN;
  const bad_apvgk_directions = !applicationHelper.withRDCNumber(application)
      && (get(application.route || {}, 'apvgks') || [])
          .findIndex((v) => !v.pivot || v.pivot.direction === null) !== -1;

  const agrAccepted = applicationHelper.allAgreementsAccepted(application.application_agreements);
  const needAgreement = applicationHelper.needSizeAgreement(application);
  return ((repeatOrReview && adminOrOfficer) || declinedIfAdmin) &&
    (!needAgreement || applicationHelper.oversizeAgreed(application)) ? (
    <>
      <Button
        className={cls}
        disabled={bad_apvgk_directions || !agrAccepted && needAgreement }
        onClick={handleAccept(application.id, 0)}
      >
        Одобрить по стандартным нагрузкам
      </Button>
      {isSpring && (
        <Button
          className={cls}
          disabled={bad_apvgk_directions || !agrAccepted && needAgreement }
          onClick={handleAccept(application.id, 1)}
        >
          Одобрить по весенним нагрузкам
        </Button>
      )}
    </>
  ) : (
    ''
  );
};

const mapStateToProps = state => {
  const { adminApplications, specialConditions } = state;
  const selector = formValueSelector('application-view-form');
  const {
    comment,
    total_price,
    federal_distance,
    regional_distance,
    federal_price,
    regional_price
  } = selector(
    state,
    'comment',
    'total_price',
    'federal_distance',
    'regional_distance',
    'federal_price',
    'regional_price'
  );

  return {
    adminApplications,
    comment,
    total_price,
    federal_distance,
    regional_distance,
    federal_price,
    regional_price,
    specialConditions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { saveRoute, application, haveOtherErrors } = ownProps;
  const { dispatch } = dispatchProps;

  const { adminApplications, comment, specialConditions } = stateProps;
  const { adminRoute } = adminApplications;
  const { markers, routeChanged } = adminRoute;
  const isSpring = applicationHelper.checkSpring(application, specialConditions.currentItem);

  const handleAccept = (id, asSpring) => {
    // validate
    if (saveRoute) {
      if (routeChanged) {
        if (!haveOtherErrors && comment && typeof comment !== 'undefined') {
          return () => dispatch(applicationActions.accept(id, comment, markers, 1, 1, asSpring));
        }
        return () => {};
      }
      return () => dispatch(applicationActions.accept(id, null, markers, 0, 1, asSpring));
    }
    return () =>
      dispatch(applicationActions.accept(id, undefined, undefined, undefined, undefined, asSpring));
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    isSpring,
    handleAccept
  };
};

const connectedApplicationAcceptButton = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationAcceptButton);
export { connectedApplicationAcceptButton as ApplicationAcceptButton };
