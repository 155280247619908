import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import { isEmpty } from 'lodash';

import AuditFilterModal from './components/AuditFilterModal/index';
import { Loader, TablePagination } from '../../../components';
import { StateContext } from './components/StateContext';
import ProgressBar from '../../../components/ProgressBar';
import { roles } from '../../../helpers/dictionaryHelper';
import { auditType } from './types';
import { ProcessingType } from '../../../constants/fileConstants';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  eventCell: {
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase'
    }
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

const getAuditObjectString = (type, audit) => {
  if (!audit) return '';
  switch (type) {
    case auditType.application:
      return `${auditType.application} №${audit.number}`;
    case auditType.user:
      return `${audit.name} ${audit.email} (${roles[audit.role_id]})`;
    case auditType.vehicle:
      return `${auditType.vehicle} ${audit.real_number}`;
    case auditType.postPick:
      return audit.title;
    case auditType.department:
      return audit.title;
    case auditType.status:
      return audit.title;
    case auditType.coefficient:
      return audit.title;
    case auditType.agreement:
      return `${auditType.application} №${audit.application_number}`;
  case auditType.apvgk_processing:
      return `Акт №${audit.id}`
    default:
      return audit.id;
  }
};

const AuditPageView = ({ onSubmit, onExport, filter, setFilter, classes }) => {
  const { auditState } = useContext(StateContext);
  const [filterIsOpen, setFilterOpen] = useState(false);
  const { data, isResolved, isRejected, isSettled, isLoading } = auditState;
  return (
    <main role="main" className="container full-height">
      <Grid container direction="column" spacing={24}>
        <Grid item container alignItems="center" justify="space-between">
          <Grid item>
            <h2 className="h2-title no-margin">Логирование</h2>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justify="space-between">
          {isSettled && (
            <Grid item xs>
              <Button
                className="no-margin info-btn"
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => setFilterOpen(true)}
              >
                Фильтровать
              </Button>
            </Grid>
          )}
          {isResolved && <ProgressBar type={ProcessingType.AUDIT} onExport={onExport} />}
        </Grid>
        {isSettled && (
          <Grid item xs>
            <Paper className={classes.root}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Дата/время действия</TableCell>
                    <TableCell>Сотрудник</TableCell>
                    <TableCell>Действие</TableCell>
                    <TableCell>Объект действия</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isResolved && isEmpty(data.data) && (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <span>Объекты не найдены</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {isResolved &&
                    data.data.map(audit => (
                      <TableRow key={audit.id}>
                        <TableCell>{audit.created_at}</TableCell>
                        <TableCell>{`${audit.user_name} (${roles[audit.user_role]})`}</TableCell>
                        <TableCell className={classes.eventCell}>{audit.event_title}</TableCell>
                        <TableCell>
                          {getAuditObjectString(audit.audit_type, audit.audit_object)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {isRejected && (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        <span className="text-danger">Ошибка: Не удалось загрузить данные</span>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
            {isResolved && (
              <TablePagination
                meta={{ ...data.meta }}
                onChangePage={newPage => setFilter({ ...filter, page: newPage })}
                onChangeRowsPerPage={newPerPage => setFilter({ ...filter, perPage: newPerPage })}
                options={[20, 50, 100]}
              />
            )}
          </Grid>
        )}
      </Grid>
      {isLoading && (
        <div className={classes.loader}>
          <Loader size={45} />
        </div>
      )}
      <AuditFilterModal
        initialValues={filter}
        onSubmit={onSubmit}
        isOpen={filterIsOpen}
        setOpen={setFilterOpen}
      />
    </main>
  );
};

export default withStyles(styles)(AuditPageView);
