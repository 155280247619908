import React from 'react';
import Button from '@material-ui/core/Button';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';

const Activate = ({ onActivate }) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const onClose = () => setShowConfirm(false);
  return (
    <React.Fragment>
      <ConfirmationModal
        title="Подтверждение активации"
        description="Вы уверены, что данное разрешение оплачено Заявителем?"
        handleSubmit={() => {
          onClose();
          onActivate();
        }}
        isShow={showConfirm}
        handleClose={onClose}
      />
      <Button className="btn-add no-margin-left" onClick={() => setShowConfirm(true)}>
        Активировать
      </Button>
    </React.Fragment>
  );
};

export default Activate;
