import React from 'react';

import Grid from '@material-ui/core/Grid';
import AgreementsList from './AgreementsList';

const AgreementsForm = ({ agreements, departments: { data: departments = [] } = {}, onRemove }) => {
  return (
    <Grid container direction="column" spacing={8}>
      <Grid item>
        <h3 className="h3-title">Согласование с ведомствами</h3>
      </Grid>
      <Grid item>
        <AgreementsList agreements={agreements} remove={onRemove} />
      </Grid>
    </Grid>
  );
};

export default AgreementsForm;
