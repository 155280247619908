import React, { Component } from 'react';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { isNumber, get } from 'lodash';
import Button from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { FormField } from '../../../components/form/FormField';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { renderSelectField } from '../../../components/form/renderSelectField';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { Loader } from '../../../components/Loader';
import { vehicleModelActions } from '../../../actions/vehicleModelActions';
import { vehicleBrandActions } from '../../../actions/vehicleBrandActions';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { vehicleActions } from '../../../actions/vehicleActions';
import { vehicleConstants } from '../../../constants/vehicleConstants';
import { VehiclesAxlesTable } from './VehicleAxlesTable';
import { fieldMasks } from '../../../helpers/fieldMasks';
import { UserForm } from './UserForm';
import { roleConstants } from '../../../constants/roleConstants';
import { fieldNormalizers } from '../../../helpers/fieldNormalizers';
import { fileConstants } from '../../../constants/fileConstants';
import { userConstants } from '../../../constants/userConstants';
import FileUploader from '../../../components/form/FileUploader';
import { createFileUploadHandler } from '../../../helpers/fileUploadHelper';
import SelectBrand from '../../admin/quickApplications/CreateQuickApplication/components/SelectBrand';
import SelectModel from '../../admin/quickApplications/CreateQuickApplication/components/SelectModel';

const SelectBrandWrapper = ({ input, disabled }) => (
  <SelectBrand
    isDisabled={disabled}
    field={{ name: input.name, value: input.value }}
    form={{
      setFieldValue: (name, newValue) => input.onChange(newValue),
      setFieldTouched: input.onBlur
    }}
  />
);

const SelectModelWrapper = ({ input, brand, disabled, isTrailer, afterSelect }) => (
  <SelectModel
    isTrailer={isTrailer}
    isDisabled={disabled}
    brand={brand}
    afterSelect={afterSelect}
    field={{ name: input.name, value: input.value }}
    form={{
      setFieldValue: (name, newValue) => input.onChange(newValue),
      setFieldTouched: input.onBlur
    }}
  />
);

// let VehicleCarForm = (props) => {
class VehicleCarForm extends Component {
  componentDidMount() {}

  render() {
    const {
      height,
      width,
      length,
      axle_distances,
      vehicle_type_id,
      axle_length,
      is_new_brand,
      is_new_model,
      is_owner,
      is_non_standard_number,
      brand_id,
      model_id,
      is_trailer,

      error,
      handleSubmit,
      submitting,
      cabinetVehicles,
      vehicleBrands,
      vehicleModels,
      authentication,

      handleVehicleTypeChange,
      handleVehicleBrandChange,
      handleVehicleModelChange,
      handleAxleCountChange,
      handleNonStandardChange,
      handleUserFormAdd,
      handleUserFormRemove,

      setActiveField,

      license_files,
      pts_files,
      handleLicenseFileUpload,
      handleLicenseFileRemove,
      handlePtsFileUpload,
      handlePtsFileRemove
    } = this.props;

    console.log(isNumber(model_id))
    const tsTypes = dictionaryHelper.vehicleTypes.getList(0);
    const trailerTypes = dictionaryHelper.vehicleTypes.getList(1);

    const getFrontImage = vehicle_type_id => {
      return dictionaryHelper.vehicleTypes.getItemImgFront(vehicle_type_id);
    };
    const getBodyImage = vehicle_type_id => {
      return dictionaryHelper.vehicleTypes.getItemImgBody(vehicle_type_id);
    };
    const getCarWidthStyle = vehicle_type_id => {
      return dictionaryHelper.vehicleTypes.getItemCarWidthStyle(vehicle_type_id);
    };
    const getCarLengthStyle = vehicle_type_id => {
      return dictionaryHelper.vehicleTypes.getItemCarLengthStyle(vehicle_type_id);
    };
    const getCarHeightStyle = vehicle_type_id => {
      return dictionaryHelper.vehicleTypes.getItemCarHeightStyle(vehicle_type_id);
    };

    const getCarAxleStyle = index => {
      return dictionaryHelper.vehicleTypes.getItemCarAxleStyle(vehicle_type_id, index);
    };

    const getCarDistanceStyle = index => {
      return dictionaryHelper.vehicleTypes.getItemCarDistanceStyle(vehicle_type_id, index);
    };

    const isShowHeightPointer = () => {
      return cabinetVehicles.activeField === 'height';
    };
    const isShowLengthPointer = () => {
      return cabinetVehicles.activeField === 'length';
    };
    const isShowWidthPointer = () => {
      return cabinetVehicles.activeField === 'width';
    };
    const isShowAxlePointer = index => {
      return cabinetVehicles.activeField === `axle_${index}`;
    };
    const isShowDistancePointer = index => {
      return cabinetVehicles.activeField === `distance_${index}`;
    };

    const getConfirmationStatus = statusKey => {
      switch (statusKey) {
        case userConstants.CONFIRMAtION_STATUS_NEW:
          return 'Новая заявка';
        case userConstants.CONFIRMAtION_STATUS_DECLINE:
          return 'Заявка отклонена';
        case userConstants.CONFIRMAtION_STATUS_REVIEW:
          return 'На повторном рассмотрении';
        case userConstants.CONFIRMAtION_STATUS_ACCEPT:
          return 'Заявка принята';
        default:
          return '';
      }
    };

    const numberMask = is_trailer ? fieldMasks.trailerNumber : fieldMasks.vehicleNumber;

    return (
      <div>
        {cabinetVehicles.currentItem && cabinetVehicles.currentItem.id && (
          <div className="blue_frame">
            <p>
              <strong>Статус заявки: </strong>
              {cabinetVehicles.currentItem &&
                getConfirmationStatus(cabinetVehicles.currentItem.confirmation_status)}
              <br />
              {cabinetVehicles.currentItem &&
                cabinetVehicles.currentItem.confirmation_status ===
                  vehicleConstants.STATUS_DECLINE && (
                  <span>Причина отклонения: {cabinetVehicles.currentItem.note}</span>
                )}
            </p>
          </div>
        )}

        {!cabinetVehicles.loading && (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-9">
                <div className="row mb-2">
                  <div className="col-md-4">
                    <p className="label-form">Марка тс</p>
                  </div>
                  <div className="col-md-8">
                    <Field
                      className="form-control"
                      name="brand_id"
                      label="Марка тс"
                      placeholder="Новая марка"
                      component={SelectBrandWrapper}
                      validate={[fieldValidators.required]}
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-md-4">
                    <p className="label-form">Модель тс</p>
                  </div>
                  <div className="col-md-8">
                    <Field
                      isTrailer={is_trailer}
                      disabled={!brand_id}
                      brand={brand_id}
                      className="form-control"
                      name="model_id"
                      placeholder="Новая модель"
                      component={SelectModelWrapper}
                      validate={[fieldValidators.required]}
                      afterSelect={handleVehicleModelChange}
                    />
                  </div>
                </div>

                <Field
                  className="form-control"
                  disabled={isNumber(model_id) ? 'disabled' : false}
                  name="vehicle_type_id"
                  label="Тип ТС"
                  component={renderSelectField}
                  validate={[fieldValidators.required]}
                  onChange={handleVehicleTypeChange}
                >
                  <option key="0" value="">
                    Выберите тип
                  </option>
                  {cabinetVehicles.typeIndex === vehicleConstants.TAB_CAR &&
                    tsTypes.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.title}
                      </option>
                    ))}
                  {cabinetVehicles.typeIndex === vehicleConstants.TAB_TRAILER &&
                    trailerTypes.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.title}
                      </option>
                    ))}
                </Field>

                <div className="text-left">
                  <Field
                    name="is_owner"
                    type="checkbox"
                    label="Владельцем ТС является Заявитель"
                    className="checkbox"
                    component={MuiCheckbox}
                  />
                </div>

                {!is_owner && (
                  <Field
                    className="form-control"
                    name="owner_name"
                    label="ФИО владельца ТС"
                    //    placeholder="ФИО"
                    component={FormField}
                    validate={[fieldValidators.required]}
                  />
                )}
              </div>
            </div>
            {!is_owner && (
              <div className="row text-left">
                <div className="col-md-3">Документ, подтверждающий право владения (до 5 Мб)</div>

                <FileUploader
                  className="col-md-6"
                  entity="license"
                  inputClassName="file_input"
                  buttonText="+ Добавить файл"
                  fileList={license_files}
                  handleFileChange={handleLicenseFileUpload}
                  handleFileRemove={handleLicenseFileRemove}
                  hasError={cabinetVehicles.uploadLicenseError}
                  uploaders={cabinetVehicles.licenseUploaders}
                  multiple
                />
              </div>
            )}
            {!is_owner && <br />}

            <div className="row text-left">
              {!is_non_standard_number ? (
                <div className="col-md-3">
                  <p>Государственный регистрационный знак ТС</p>
                </div>
              ) : (
                ''
              )}
              {!is_non_standard_number ? (
                <div className="col-md-2">
                  <Field
                    name="number"
                    placeholder="номер"
                    className="full-input"
                    component={FormFieldReg}
                    validate={[fieldValidators.required, fieldValidators.exactLength6]}
                    {...numberMask}
                  />
                </div>
              ) : (
                ''
              )}
              {!is_non_standard_number ? (
                <div className="col-md-2">
                  <Field
                    name="regions"
                    placeholder="регион"
                    className="full-input"
                    component={FormFieldReg}
                    validate={[
                      fieldValidators.required,
                      fieldValidators.number,
                      fieldValidators.minLength2,
                      fieldValidators.maxLength3
                    ]}
                    normalize={fieldNormalizers.vehicleRegion}
                  />
                </div>
              ) : (
                ''
              )}

              {is_non_standard_number ? (
                <div className="col-md-3">
                  <p>Нестандартный ГРЗ ТС</p>
                </div>
              ) : (
                ''
              )}

              {is_non_standard_number ? (
                <div className="col-md-4">
                  <Field
                    name="non_standard_number"
                    placeholder=""
                    className="full-input"
                    component={FormFieldReg}
                    validate={[fieldValidators.required]}
                  />
                </div>
              ) : (
                ''
              )}

              <div className="col-md-5">
                <Button
                  variant="outlined"
                  href="#outlined-buttons"
                  className="info-btn"
                  onClick={handleNonStandardChange}
                >
                  {is_non_standard_number
                    ? 'Ввести стандартный номер'
                    : 'Ввести нестандартный номер'}
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                <p>
                  <strong>Данные ПТС</strong>
                </p>

                <Field
                  name="pts_number"
                  label="Номер ПТС/ПСМ"
                  placeholder=""
                  className="form-control"
                  component={FormField}
                  validate={[fieldValidators.requiredPts]}
                  {...fieldMasks.ptsNumber}
                />

                <Field
                  name="sts_number"
                  label="Номер свидетельства о регистрации тс"
                  placeholder=""
                  className="form-control"
                  component={FormField}
                  validate={[fieldValidators.requiredSts]}
                  {...fieldMasks.ptsNumber}
                />

                <Field
                  name="pts_weight"
                  label="Масса транспорта без нагрузки (т)"
                  placeholder=""
                  className="mini-input"
                  component={FormField}
                  normalize={fieldNormalizers.number}
                  validate={[
                    fieldValidators.required,
                    fieldValidators.number,
                    fieldValidators.maxValue1000,
                    fieldValidators.minValue0
                  ]}
                />

                <Field
                  name="pts_max_weight"
                  label="Разрешенная максимальная масса ТС (т)"
                  placeholder=""
                  className="mini-input"
                  component={FormField}
                  normalize={fieldNormalizers.number}
                  validate={[
                    fieldValidators.required,
                    fieldValidators.number,
                    fieldValidators.maxValue1000,
                    fieldValidators.minValue0
                  ]}
                />
              </div>
            </div>
            <div className="row text-left">
              <div className="col-md-3">
                Копии данных ПТС или свидетельства о регистрации в формате pdf, jpeg (до 5 Мб)
              </div>

              <FileUploader
                className="col-md-6"
                entity="pts"
                inputClassName="file_input"
                buttonText="+ Добавить файл"
                fileList={pts_files}
                handleFileChange={handlePtsFileUpload}
                handleFileRemove={handlePtsFileRemove}
                hasError={cabinetVehicles.uploadPtsError}
                uploaders={cabinetVehicles.ptsUploaders}
                multiple
              />
            </div>

            <br />
            <div className="row">
              <div className="col-md-12">
                <p>
                  <strong>Габариты</strong>
                </p>
                <div className="row">
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-7">Длина (м)</div>
                      <div className="col-md-5">
                        <Field
                          name="length"
                          // label="Длина"
                          placeholder=""
                          component={FormFieldReg}
                          validate={[fieldValidators.required]}
                          normalize={fieldNormalizers.number}
                          onBlur={e => setActiveField('')}
                          onFocus={e => setActiveField('length')}
                        />
                      </div>
                      <div className="col-md-7">Ширина (м)</div>
                      <div className="col-md-5">
                        <Field
                          name="width"
                          placeholder=""
                          component={FormFieldReg}
                          onBlur={e => setActiveField('')}
                          onFocus={e => setActiveField('width')}
                          validate={[fieldValidators.required]}
                          normalize={fieldNormalizers.number}
                        />
                      </div>
                      <div className="col-md-7">Высота (м)</div>
                      <div className="col-md-5">
                        <Field
                          name="height"
                          placeholder=""
                          component={FormFieldReg}
                          validate={[fieldValidators.required]}
                          normalize={fieldNormalizers.number}
                          onBlur={e => setActiveField('')}
                          onFocus={e => setActiveField('height')}
                        />
                      </div>
                      <div className="col-md-7">Количество осей</div>
                      <div className="col-md-5">
                        <Field
                          name="axle_count"
                          onBlur={handleAxleCountChange}
                          placeholder=""
                          component={FormFieldReg}
                          readonly={
                            parseInt(vehicle_type_id, 10) !== vehicleConstants.SPEC_CAR_TYPE_ID &&
                            parseInt(vehicle_type_id, 10) !== vehicleConstants.OTHER_CAR_TYPE_ID &&
                            parseInt(vehicle_type_id, 10) !==
                              vehicleConstants.OTHER_TRAILER_TYPE_ID &&
                            parseInt(vehicle_type_id, 10) !==
                              vehicleConstants.OTHER_SEMI_TRAILER_TYPE_ID
                              ? 'readonly'
                              : null
                          }
                          validate={[fieldValidators.required]}
                          normalize={fieldNormalizers.number}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9 nowrap overflow">
                    {vehicle_type_id && (
                      <div className="car-block-front">
                        {getFrontImage(vehicle_type_id) && (
                          <img
                            className="car-img"
                            alt="Car front"
                            src={getFrontImage(vehicle_type_id)}
                          />
                        )}

                        {getFrontImage(vehicle_type_id) && isShowHeightPointer() && (
                          <div className="car_height" style={getCarHeightStyle(vehicle_type_id)}>
                            <ExpandLess className="arrow_top" />
                            <div className="vertical_line" />
                            {height}м
                            <ExpandMore className="arrow_bottom" />
                          </div>
                        )}

                        {getFrontImage(vehicle_type_id) && isShowWidthPointer() && (
                          <div className="car_width" style={getCarWidthStyle(1)}>
                            <ChevronLeft className="arrow_left" />
                            <div className="horizontal_line" />
                            {width}м
                            <ChevronRight className="arrow_right" />
                          </div>
                        )}
                      </div>
                    )}
                    {vehicle_type_id && getBodyImage(vehicle_type_id) && (
                      <div className="car-block-side">
                        {getBodyImage(vehicle_type_id) && (
                          <img
                            alt="Car Body"
                            className="car-img"
                            src={getBodyImage(vehicle_type_id)}
                          />
                        )}

                        {getBodyImage(vehicle_type_id) && isShowLengthPointer() && (
                          <div className="car_length" style={getCarLengthStyle(vehicle_type_id)}>
                            <ChevronLeft className="arrow_left" />
                            <div className="horizontal_line" />
                            {length}м
                            <ChevronRight className="arrow_right" />
                          </div>
                        )}

                        {isShowAxlePointer(0) && (
                          <div className="car_axle text-center" style={getCarAxleStyle(0)}>
                            <ArrowUpwardIcon />
                            &nbsp;{axle_length ? axle_length[0] : ''}&nbsp;
                          </div>
                        )}
                        {isShowAxlePointer(1) && (
                          <div className="car_axle text-center" style={getCarAxleStyle(1)}>
                            <ArrowUpwardIcon />
                            &nbsp;{axle_length ? axle_length[1] : ''}&nbsp;
                          </div>
                        )}
                        {isShowAxlePointer(2) && (
                          <div className="car_axle text-center" style={getCarAxleStyle(2)}>
                            <ArrowUpwardIcon />
                            &nbsp;{axle_length ? axle_length[2] : ''}&nbsp;
                          </div>
                        )}
                        {isShowAxlePointer(3) && (
                          <div className="car_axle text-center" style={getCarAxleStyle(3)}>
                            <ArrowUpwardIcon />
                            &nbsp;{axle_length ? axle_length[3] : ''}&nbsp;
                          </div>
                        )}
                        {isShowAxlePointer(4) && (
                          <div className="car_axle text-center" style={getCarAxleStyle(4)}>
                            <ArrowUpwardIcon />
                            &nbsp;{axle_length ? axle_length[4] : ''}&nbsp;
                          </div>
                        )}

                        {isShowDistancePointer(0) && (
                          <div className="car_spacing" style={getCarDistanceStyle(0)}>
                            <ChevronLeft className="arrow_left" />
                            <div className="horizontal_line" />
                            {axle_distances && axle_distances[0]}м
                            <ChevronRight className="arrow_right" />
                          </div>
                        )}

                        {isShowDistancePointer(1) && (
                          <div className="car_spacing" style={getCarDistanceStyle(1)}>
                            <ChevronLeft className="arrow_left" />
                            <div className="horizontal_line" />
                            {axle_distances && axle_distances[1]}м
                            <ChevronRight className="arrow_right" />
                          </div>
                        )}

                        {isShowDistancePointer(2) && (
                          <div className="car_spacing" style={getCarDistanceStyle(2)}>
                            <ChevronLeft className="arrow_left" />
                            <div className="horizontal_line" />
                            {axle_distances && axle_distances[2]}м
                            <ChevronRight className="arrow_right" />
                          </div>
                        )}

                        {isShowDistancePointer(3) && (
                          <div className="car_spacing" style={getCarDistanceStyle(3)}>
                            <ChevronLeft className="arrow_left" />
                            <div className="horizontal_line" />
                            {axle_distances && axle_distances[3]}м
                            <ChevronRight className="arrow_right" />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row text-left">
              <div className="col-md-7">
                <p>
                  <strong>Характеристики осей</strong>
                </p>

                <VehiclesAxlesTable axles={cabinetVehicles.vehicleAxles} is_trailer={is_trailer} />
                <br />

                <p>
                  <strong>Расстояние между осями</strong>
                </p>
                {cabinetVehicles.vehicleAxles.map((vehicleAxle, index) => {
                  return (
                    index > 0 && (
                      <Field
                        key={index}
                        name={`axle_distances[${index - 1}]`}
                        label={`Расстояние ${index}`}
                        placeholder=""
                        className="mini-input"
                        component={FormField}
                        validate={[fieldValidators.required]}
                        normalize={fieldNormalizers.number}
                        onBlur={e => setActiveField('')}
                        onFocus={e => setActiveField(`distance_${index - 1}`)}
                      />
                    )
                  );
                })}
              </div>
            </div>
            <div className="row text-left">
              <div className="col-md-7">
                <br />
                {error && <strong>{error}</strong>}
                <div>
                  <button type="submit" className="btn" disabled={submitting}>
                    {submitting && <Loader />}
                    {cabinetVehicles.currentItem && cabinetVehicles.currentItem.id
                      ? 'Редактировать'
                      : 'Создать'}
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
        {cabinetVehicles.saveStatus}
      </div>
    );
  }
}

VehicleCarForm = reduxForm({
  form: 'vehicle-car-form',
  enableReinitialize: true
})(VehicleCarForm);

const mapStateToProps = state => {
  const { cabinetVehicles, vehicleBrands, vehicleModels, authentication } = state;
  const { currentItem } = cabinetVehicles;

  const currentIsSet = currentItem && currentItem.id;
  const initValues = {
    id: currentIsSet ? currentItem.id : '',
    is_trailer: currentIsSet ? currentItem.is_trailer : 0,
    brand_id: currentIsSet ? currentItem.brand_id : '',
    model_id: currentIsSet ? currentItem.model_id : '',
    vehicle_type_id: currentIsSet ? currentItem.vehicle_type_id : '',

    is_owner: currentIsSet ? currentItem.is_owner : '',
    owner_name: currentIsSet ? currentItem.owner_name : '',

    number: currentIsSet ? currentItem.number : '',
    regions: currentIsSet ? currentItem.regions : '',

    pts_number: currentIsSet ? currentItem.pts_number : '',
    sts_number: currentIsSet ? currentItem.sts_number : '',
    pts_weight: currentIsSet ? currentItem.pts_weight : '',
    pts_max_weight: currentIsSet ? currentItem.pts_max_weight : '',

    length: currentIsSet ? currentItem.length : '',
    width: currentIsSet ? currentItem.width : '',
    height: currentIsSet ? currentItem.height : '',

    axle_count: currentIsSet ? currentItem.axle_count : '',
    non_standard_number: currentIsSet ? currentItem.non_standard_number : '',

    license_files: currentIsSet ? get(currentItem, 'files.license_files', []) : [],
    pts_files: currentIsSet ? get(currentItem, 'files.pts_files', []) : [],

    is_non_standard_number: currentIsSet ? currentItem.is_non_standard_number : 0,
    status: currentIsSet ? currentItem.confirmation_status : 0
  };

  // axles
  initValues.axle_length = [];
  initValues.axle_type = [];
  initValues.axle_main = [];
  initValues.axle_distances = [];
  initValues.axle_wheels = [];
  if (currentItem && currentItem.vehicle_axles) {
    for (let i = 0; i < currentItem.vehicle_axles.length; i++) {
      initValues.axle_length[i] = currentItem.vehicle_axles[i].wheel_count;
      initValues.axle_wheels[i] = currentItem.vehicle_axles[i].wheels;
      initValues.axle_type[i] = currentItem.vehicle_axles[i].type_id.toString();
      initValues.axle_main[i] = currentItem.vehicle_axles[i].is_lifting;
      initValues.axle_distances[i] = currentItem.vehicle_axles[i].distance;
    }
  }

  // userforms
  initValues.userform = [];
  if (currentItem && currentItem.firm_users) {
    for (let i = 0; i < currentItem.firm_users.length; i++) {
      initValues.userform[i] = {
        is_existing_user: true,
        existing_user_id: currentItem.firm_users[i].id,
        user_edit_access: currentItem.firm_users[i].can_edit
      };
    }
  }

  // form values
  const selector = formValueSelector('vehicle-car-form');
  const {
    height,
    width,
    length,
    axle_distances,
    vehicle_type_id,
    axle_length,
    license_files,
    pts_files,
    lease_files,
    is_new_brand,
    is_new_model,
    is_owner,
    is_non_standard_number,
    brand_id,
    model_id,
    is_trailer
  } = selector(
    state,
    'height',
    'width',
    'length',
    'axle_distances',
    'vehicle_type_id',
    'axle_length',
    'license_files',
    'pts_files',
    'lease_files',
    'is_new_brand',
    'is_new_model',
    'is_owner',
    'is_non_standard_number',
    'brand_id',
    'model_id',
    'is_trailer'
  );

  return {
    cabinetVehicles,
    vehicleBrands,
    vehicleModels,
    authentication,

    initialValues: initValues,

    height,
    width,
    length,
    axle_distances,
    vehicle_type_id,
    axle_length,
    license_files,
    pts_files,
    lease_files,
    is_new_brand,
    is_new_model,
    is_owner,
    is_non_standard_number,
    brand_id,
    model_id,
    is_trailer,

    selector
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const onVehicleLoaded = (dispatch, vehicle) => {
    // load models by brand
    dispatch(vehicleModelActions.getAll(parseInt(vehicle.brand_id, 10)));

    // set tab
    dispatch(vehicleActions.setType(parseInt(vehicle.is_trailer, 10)));

    // set vehicle schemas
    dispatch(vehicleActions.setVehicleTypeId(vehicle.vehicle_type_id));
  };

  dispatch(vehicleBrandActions.getAll());

  const modelId = parseInt(ownProps.itemId, 10);
  if (modelId) {
    dispatch(vehicleActions.getItem(modelId, onVehicleLoaded));
  }

  // Get existing users
  dispatch(vehicleActions.getExceptions());

  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    cabinetVehicles,
    vehicleBrands,
    vehicleModels,
    authentication,
    initialValues,
    height,
    length,
    width,
    axle_distances,
    vehicle_type_id,
    axle_length,
    license_files,
    pts_files,
    lease_files,
    is_new_brand,
    is_new_model,
    is_owner,
    is_non_standard_number,
    brand_id,
    model_id,
    is_trailer
  } = stateProps;
  const { currentItem } = cabinetVehicles;

  const { dispatch } = dispatchProps;

  // const load
  const handleNonStandardChange = event => {
    const isNonValue = is_non_standard_number ? 0 : 1;
    dispatch(change('vehicle-car-form', 'is_non_standard_number', isNonValue));
  };
  const handleVehicleTypeChange = (event, value) => {
    dispatch(vehicleActions.setVehicleTypeId(parseInt(value, 10)));
    const axleCount = dictionaryHelper.vehicleTypes.getItemAxleCount(value);
    if (axleCount) {
      dispatch(vehicleActions.setAxleCount(axleCount));
      dispatch(change('vehicle-car-form', 'axle_count', axleCount));
    }
  };
  const handleAxleCountChange = (event, value) => {
    let count = parseInt(value, 10);
    if (isNaN(count) || count < 0) {
      count = 2;
    }
    if (count > 10) {
      count = 10;
    }
    dispatch(vehicleActions.setAxleCount(count));
    dispatch(change('vehicle-car-form', 'axle_count', count));
  };
  const handleVehicleBrandChange = (event, value) => {
    if (value) {
      dispatch(vehicleModelActions.getAll(parseInt(value, 10)));
    }
  };

  const findVehicleModel = (models, id) => {
    for (let i = 0; i < models.length; i++) {
      if (models[i].id === id) {
        return models[i];
      }
    }
    return null;
  };
  const fetchVehicleModelData = model => {
    const result = {
      vehicle_type_id: model.vehicle_type_id,
      axle_count: model.axle_count,
      width: model.width,
      length: model.length,
      height: model.height
    };

    // get axles lengths
    const axles_wheel_count = model.axles_wheel_count.split(',');
    for (let i = 0; i < axles_wheel_count.length; i++) {
      dispatch(
        change('vehicle-car-form', `axle_length[${i}]`, parseFloat(axles_wheel_count[i]).toString())
      );
    }

    // get wheel count
    const distances = model.axles_distance.split(',');
    for (let i = 0; i < distances.length; i++) {
      dispatch(
        change('vehicle-car-form', `axle_distances[${i}]`, parseFloat(distances[i]).toString())
      );
    }

    if (result.axle_count) {
      dispatch(vehicleActions.setAxleCount(result.axle_count));
      dispatch(change('vehicle-car-form', 'axle_count', result.axle_count));
    }

    return result;
  };
  const handleVehicleModelChange = value => {
    // load to form model info

    if (value && value.id >= 0) {
      const vehicleModelData = fetchVehicleModelData(value);
      Object.keys(vehicleModelData).forEach(key =>
        dispatch(change('vehicle-car-form', key, vehicleModelData[key]))
      );
      // change axle count
      dispatch(vehicleActions.setAxleCount(vehicleModelData.axle_count));
    }
  };

  const handleUserFormAdd = () => {
    return dispatch(vehicleActions.userFormAdd());
  };
  const handleUserFormRemove = (index, list_id) => {
    dispatch(change('vehicle-car-form', `userform[${list_id}]`, null));
    return dispatch(vehicleActions.userFormRemove(index));
  };

  const setActiveField = fieldName => {
    return dispatch(vehicleActions.setActiveField(fieldName));
  };

  const handleLicenseFileUpload = createFileUploadHandler(
    dispatch,
    fileConstants.TYPE_LICENSE,
    vehicleActions.uploadLicense,
    { type: vehicleConstants.LICENSE_UPLOAD_FAILURE }
  );

  const handleLicenseFileRemove = id => {
    dispatch(vehicleActions.removeLicenseFile(id));
    dispatch(
      change('vehicle-car-form', 'license_files', license_files.filter(item => item.id !== id))
    );
  };

  const handlePtsFileUpload = createFileUploadHandler(
    dispatch,
    fileConstants.TYPE_PTS,
    vehicleActions.uploadPts,
    { type: vehicleConstants.PTS_UPLOAD_FAILURE }
  );

  const handlePtsFileRemove = id => {
    dispatch(vehicleActions.removePtsFile(id));
    dispatch(change('vehicle-car-form', 'pts_files', pts_files.filter(item => item.id !== id)));
  };

  const handleLeaseFileUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      const values = { type: fileConstants.TYPE_LEASE, myfile: reader.result };
      dispatch(vehicleActions.uploadLease(values, lease_files, dispatch));
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };
  const handleLeaseFileRemove = id => {
    dispatch(vehicleActions.removeLeaseFile(id));
    const newFiles = lease_files.filter(item => item.id !== id);
    dispatch(change('vehicle-car-form', 'lease_files', newFiles));
  };

  return {
    ...ownProps,
    cabinetVehicles,
    vehicleBrands,
    vehicleModels,
    initialValues,
    authentication,
    height,
    length,
    width,
    axle_distances,
    vehicle_type_id,
    axle_length,
    license_files,
    pts_files,
    lease_files,
    is_new_brand,
    is_new_model,
    is_owner,
    is_non_standard_number,
    brand_id,
    model_id,
    is_trailer,

    handleVehicleTypeChange,
    handleVehicleBrandChange,
    handleVehicleModelChange,
    handleAxleCountChange,
    handleNonStandardChange,

    handleUserFormAdd,
    handleUserFormRemove,

    setActiveField,

    handleLicenseFileUpload,
    handleLicenseFileRemove,
    handlePtsFileUpload,
    handlePtsFileRemove,
    handleLeaseFileUpload,
    handleLeaseFileRemove
  };
};

VehicleCarForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VehicleCarForm);

export default VehicleCarForm;
