import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'special-conditions';
};

const getItem = ({ id }) => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};
const setItem = values => {
  values.value = values.value ? 1 : 0;
  console.log('values', values);
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

export const specialConditionService = {
  getItem,
  setItem
};
