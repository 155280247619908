import React from 'react';
import {MainFooterBlock} from '../main/blocks/MainFooterBlock/MainFooterBlock';
import {AccountantRoute} from '../../components/routes/AccountantRoute';
import {CabinetMainMenu} from '../cabinet/blocks/CabinetMainMenu/CabinetMainMenu';
import {CabinetLeftMenu} from '../cabinet/blocks/CabinetLeftMenu/CabinetLeftMenu';
import AccountantMainPage from './main/AccountantMainPage';
import { AccountantPaymentsPage } from './payments/AccountantPaymentsPage';
import { AccountantPaymentCreatePage } from './payments/AccountantPaymentCreatePage';
import {AccountantStatPage} from './stat/AccountantStatPage'
import { AccountantPaymentShowPage } from './payments/AccountantPaymentShowPage';

const AccountantLayout = ({match}) => {
    const links = [
        {
            key: 1,
            path: `${match.url}`,
            title: 'Личный кабинет',
            hasChild: false
        },
        {
            key: 2,
            path: `${match.url}/payments`,
            title: 'Платежи',
            hasChild: true
        },
        {
            key: 3,
            path: `${match.url}/stat`,
            title: 'Статистика',
            hasChild: true
        }
    ];

    return (
        <div className="">
            <div className="cabinet-header">
                <CabinetMainMenu/>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className="cabinet-left-menu">
                            <CabinetLeftMenu links={links}/>
                        </div>
                    </div>

                    <div className="col-md-9 cabinet-content">
                        <AccountantRoute exact path={`${match.url}`} component={AccountantMainPage}/>
                        <AccountantRoute exact path={`${match.url}/payments`} component={AccountantPaymentsPage}/>
                        <AccountantRoute exact path={`${match.url}/payments/create`} component={AccountantPaymentCreatePage}/>
                        <AccountantRoute exact path={`${match.url}/payments/show/:id`} component={AccountantPaymentShowPage}/>
                        <AccountantRoute exact path={`${match.url}/payments/edit/:id`} component={AccountantPaymentShowPage}/>
                        <AccountantRoute exact path={`${match.url}/stat`} component={AccountantStatPage}/>
                    </div>
                </div>
            </div>
            <MainFooterBlock/>
        </div>
    );
};

export default AccountantLayout;
