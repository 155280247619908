import React, { Component } from 'react';
import { Field, formValueSelector, reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import { MuiCheckbox } from '../../../../components/form/MuiCheckbox';
import { specialConditionActions } from '../../../../actions/specialConditionActions';
import { FormFieldNoLabel } from '../../../../components/form/FormFieldNoLabel';
import { FormField } from '../../../../components/form/FormField';
import { fieldValidators } from '../../../../helpers/fieldValidators';
import { FormTextarea } from '../../../../components/form/FormTextarea';

import { Loader } from '../../../../components/Loader';

class SpringForm extends Component {
  componentDidMount() {
    const { currentItem } = this.props;

    if (!currentItem) {
      this.props.dispatch(specialConditionActions.getItem(1));
    }
  }

  render() {
    const {
      enqueueSnackbar,
      handleSubmit,
      submitting,
      currentItem,
      handleChange,
      has_comment
    } = this.props;

    return (
      currentItem && (
        <form
          className="container spring"
          onSubmit={(...args) =>
            handleSubmit(...args).then(() =>
              enqueueSnackbar('Период действия весеннего ограничения обновлен', {
                variant: 'success'
              })
            )
          }
        >
          <p>Период действия весеннего ограничения</p>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="start_date"
                type="date"
                label="Начало периода"
                validate={[fieldValidators.required]}
                component={FormField}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="finish_date"
                type="date"
                label="Конец периода"
                validate={[fieldValidators.required]}
                component={FormField}
              />
            </div>
          </div>
          <Field
            name="has_comment"
            type="checkbox"
            label="Комментарий для заявителей"
            component={MuiCheckbox}
            className="checkbox"
            onChange={handleChange}
          />
          {has_comment ? (
            <Field
              className="form-control"
              name="comment"
              label="Комментарий для заявителя"
              placeholder=""
              component={FormTextarea}
              validate={[fieldValidators.required]}
              row="4"
            />
          ) : (
            ''
          )}
          <Button
            variant="outlined"
            className="btn-add no-margin"
            size="small"
            type="submit"
            disabled={submitting}
          >
            {submitting && <Loader />} <span>Сохранить</span>
          </Button>
        </form>
      )
    );
  }
}

SpringForm = reduxForm({
  form: 'spring-form',
  enableReinitialize: true
})(SpringForm);

const mapStateToProps = state => {
  const { specialConditions } = state;
  const { currentItem } = specialConditions;

  const selector = formValueSelector('spring-form');
  const has_comment = selector(state, 'has_comment');

  return {
    has_comment,
    currentItem,
    initialValues: {
      id: state.specialConditions.currentItem ? state.specialConditions.currentItem.id : '',
      title: state.specialConditions.currentItem ? state.specialConditions.currentItem.title : '',
      value:
        state.specialConditions.currentItem && state.specialConditions.currentItem.value ? 1 : 0,
      start_date: state.specialConditions.currentItem
        ? state.specialConditions.currentItem.start_date
        : 0,
      finish_date: state.specialConditions.currentItem
        ? state.specialConditions.currentItem.finish_date
        : 0,
      has_comment:
        state.specialConditions.currentItem && state.specialConditions.currentItem.has_comment
          ? 1
          : 0,
      comment: state.specialConditions.currentItem
        ? state.specialConditions.currentItem.comment
        : ''
    }
  };
};

const mapDispatchToProps = dispatch => {
  const handleChange = (event, value) => {
    const values = {
      id: 1,
      value
    };
    return dispatch(specialConditionActions.setItem(values));
  };
  return {
    handleChange,
    dispatch
  };
};

SpringForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SpringForm));
export default SpringForm;
