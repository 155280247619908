import React from 'react';
import { Link } from 'react-router-dom';
import { apiConstants } from '../../../../constants/apiConstants';

export const MainFooterBlock = () => {
  return (
    <footer className="footer">
      <div className="row">
        <div className="col-md-5">
          <Link to="/agreement">О нас</Link>
          <a target="_blank" href={`${apiConstants.API_URL}/storage/info/instructions.pdf`}>
            Инструкция
          </a>
          <Link to="/personal">О персональных данных</Link>
          <br />

          <small>
            <span className="text-muted">Email:</span> ais.ktg@tatar.ru
          </small>
          {/* <br />
          <small>
            <span className="text-muted">Техническая поддержка:</span> +7 (843) 5-333-903
          </small> */}
        </div>
        <div className="col-md-7">
          <div className="row">
            <div className="col-md-6">
              <span className="text-muted">По вопросам регистрации ТС и выдаче разрешений:</span>
              <br />
              <small>
                <span className="text-muted">Н.Челны:</span> +7 (855) 2-475-893,{' '}
              </small>
              <small>chelny.ktg@tatar.ru</small>
              <br />
              <small>
                <span className="text-muted">по АПВГК:</span> +7 (843) 5-333-797{' '}
              </small>
            </div>
            <div className="col-md-6">
              <small>
                  <span className="text-muted">Казань:</span> +7 (843) 5-333-903,{' '}
              </small>
              <small>ktg.bdd@tatar.ru</small>
              <br />
              <small>
                <span className="text-muted">Альметьевск:</span> +7 (855) 3-439-066,{' '}
              </small>
              <small>almet.ktg@tatar.ru</small>
              <br />
              <small>
                <span className="text-muted">Нурлат:</span> +7 (843) 4-529-326,{' '}
              </small>
              <small>nurlat.ktg@tatar.ru</small>
            </div>
          </div>
        </div>
        {/* <div className="col-md-2 text-right">

                </div> */}
      </div>
    </footer>
  );
};
