import React, { useContext } from 'react';
import { Formik, Field, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { Loader } from '../../../../../components';
import MaterialInput from '../../../quickApplications/CreateQuickApplication/components/MaterialInput';
import FieldLabel from '../FieldLabel';
import { StateContext } from '../StateContext';
import MaterialSelect from '../../../quickApplications/CreateQuickApplication/components/MaterialSelect';
import { IssuePlaces } from '../../../../../helpers/dictionaryHelper';

const options = [
  {
    value: -1,
    label: 'Все'
  },
  ...IssuePlaces.map(({ id, title }) => ({
    value: id,
    label: title
  }))
];

const StatisticsFilterForm = ({ initialValues, onSubmit, onReset, onClose }) => {
  const {
    statistic: { isLoading }
  } = useContext(StateContext);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        onSubmit(values);
        onClose();
      }}
      render={() => (
        <Form>
          <Grid container direction="column" spacing={16} wrap="nowrap">
            <Grid item>
              <FieldLabel label="по Дате создания разрешения">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Field
                      name="start_date"
                      component={MaterialInput}
                      placeholder="Начало периода"
                      type="date"
                    />
                  </Grid>
                  <Grid item>
                    <span>—</span>
                  </Grid>
                  <Grid item>
                    <Field
                      name="finish_date"
                      component={MaterialInput}
                      placeholder="Конец периода"
                      type="date"
                    />
                  </Grid>
                </Grid>
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="По территориальному управлению">
                <Field
                  name="issue_place"
                  component={MaterialSelect}
                  placeholder="Выберите управление"
                  options={options}
                  menuPortalTarget={document.querySelector('body > div[role=dialog]')}
                />
              </FieldLabel>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="btn-add no-margin"
                type="submit"
                disabled={isLoading}
              >
                {isLoading && <Loader size={24} />} &nbsp; Применить
              </Button>
              &nbsp;
              <Button
                variant="outlined"
                component="span"
                className="info-btn"
                onClick={() => {
                  onReset();
                  onClose();
                }}
              >
                Очистить
              </Button>
            </Grid>
          </Grid>
          <br />
        </Form>
      )}
    />
  );
};

export default StatisticsFilterForm;
