import React from 'react';
import { AdminRoute } from '../../../components/routes/AdminRoute';
import ApplicationsListPage from './ApplicationsListPage';
import { ApplicationViewPage } from '../applications/ApplicationViewPage';
import CreateQuickApplication from './CreateQuickApplication/CreateQuickApplication';
import CopyQuickApplication from './CopyQuickApplication';
import NewApplicationViewPage from '../applications_new/ApplicationViewPageContainer';

const QuickApplicationsLayout = ({ match }) => (
  <React.Fragment>
    <AdminRoute exact path={`${match.url}/`} component={ApplicationsListPage} />
    <AdminRoute exact path={`${match.url}/view/:id`} component={ApplicationViewPage} />
    <AdminRoute exact path={`${match.url}/view_new/:id`} component={NewApplicationViewPage} />
    <AdminRoute exact path={`${match.url}/create`} component={CreateQuickApplication} />
    <AdminRoute exact path={`${match.url}/copy/:id`} component={CopyQuickApplication} />
  </React.Fragment>
);

export default QuickApplicationsLayout;
