import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Close';
import TableRow from '@material-ui/core/TableRow';
import { withSnackbar } from 'notistack';
import { dictionaryHelper } from '../../../../helpers/dictionaryHelper';
import ShowByStatus from '../ShowByStatus';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { roleConstants } from '../../../../constants/roleConstants';
import { Loader } from '../../../../components';
import { applicationService } from '../../../../services/applicationService';

const AgreementsListRow = ({ removeAgreement, agreement, enqueueSnackbar }) => {
  const [isRemoving, setRemoving] = React.useState(false);
  const onRemove = React.useCallback(async () => {
    setRemoving(true);
    try {
      await applicationService.removeAgreement({ id: agreement.id });
      removeAgreement(agreement.id);
      enqueueSnackbar(`Согласование c ${agreement.department.title} удалено!`, {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar('Произошла ошибка при удалении согласования!', { variant: 'error' });
    }
    setRemoving(false);
  }, []);
  return (
    <TableRow key={`agreement-${agreement.id}`}>
      <TableCell>{agreement.department.title}</TableCell>
      <TableCell className="text-right">
        {dictionaryHelper.getAgreementStatus(agreement.status)}
      </TableCell>

      <TableCell className="text-right">
        <ShowByStatus allowedStatus={applicationConstants.AGREEMENT_DECLINED}>
          {agreement.comment}
        </ShowByStatus>
      </TableCell>
      <ShowByStatus
        allowedRoles={[roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER]}
        disallowedStatuses={[
          applicationConstants.STATUS_ACCEPT,
          applicationConstants.STATUS_ACCEPT_WITH_CHANGES,
          applicationConstants.STATUS_ACTIVE
        ]}
      >
        <TableCell className="text-right">
          <Button className="red" onClick={onRemove} disabled={isRemoving}>
            {isRemoving ? <Loader /> : <DeleteIcon />}
          </Button>
        </TableCell>
      </ShowByStatus>
    </TableRow>
  );
};

export default withSnackbar(AgreementsListRow);
