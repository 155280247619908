import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const resourceName = 'processes';

const getProcesses = type => {
  return apiService.call(apiConstants.METHOD_GET, `/${resourceName}/list?type=${type}`, {}, true);
};

export default {
  getProcesses
};
