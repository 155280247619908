import { codeConstants } from '../constants/codeConstants';

const refreshRequest = () => {
  return { type: codeConstants.REFRESH_REQUEST };
};
const refreshSuccess = message => {
  return { type: codeConstants.REFRESH_SUCCESS };
};
const refreshError = error => {
  return { type: codeConstants.REFRESH_ERROR, error };
};

export const codeActions = {
  refreshRequest,
  refreshSuccess,
  refreshError
};
