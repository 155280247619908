import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { urlHelper } from '../../../../helpers/urlHelper';
import { roleConstants } from '../../../../constants/roleConstants';

const DetailsLink = ({ user, item }) => {
  if (user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT && !!item.smev) {
    return (
      <Link to={`${urlHelper.getHomeUrl(user.role_id)}/cooperation/view/${item.id}`}>
        <Button variant="text" className="info-btn" color="primary">
          Подробнее
        </Button>
      </Link>
    );
  }
  return (
    <Link to={`${urlHelper.getHomeUrl(user.role_id)}/applications/view/${item.id}`}>
      <Button variant="text" className="info-btn" color="primary">
        Подробнее
      </Button>
    </Link>
  );
};

export default DetailsLink;
