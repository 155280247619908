import { applicationService } from '../services/applicationService';
import {
  getApplicationId,
  getApplicationMarkers,
  getIsRouteChanged,
  getRouteChangeComment
} from '../selectors/applicationSelectors';

export const GET_APPLICATION_REQUEST = 'GET_NEW_APPLICATION_REQUEST';
export const GET_APPLICATION_SUCCESS = 'GET_NEW_APPLICATION_SUCCESS';
export const GET_APPLICATION_FAILED = 'GET_NEW_APPLICATION_FAILED';

export const CHANGE_STATUS_REQUEST = 'CHANGE_STATUS_REQUEST';
export const CHANGE_STATUS_REQUEST_SUCCESS = 'CHANGE_STATUS_REQUEST_SUCCESS';
export const CHANGE_STATUS_REQUEST_FAILED = 'CHANGE_STATUS_REQUEST_FAILED';

export const APPLICATION_CHANGE_EDIT_ROUTE = 'NEW_APPLICATION_CHANGE_EDIT_ROUTE';
export const APPLICATION_CHANGE_ROUTE_BY_ADMIN = 'NEW_APPLICATION_CHANGE_ROUTE_BY_ADMIN';
export const APPLICATION_CHANGE_MARKET_TEXT = 'NEW_APPLICATION_CHANGE_MARKET_TEXT';
export const APPLICATION_REMOVE_MARKER = 'NEW_APPLICATION_REMOVE_MARKER';
export const APPLICATION_COMMENT_CHANGED = 'NEW_APPLICATION_COMMENT_CHANGED';
export const APPLICATION_UPDATE_PRINT_FORM = 'NEW_APPLICATION_UPDATE_PRINT_FORM';
export const SET_SHOW_DECLINE_MODAL = 'SET_SHOW_DECLINE_MODAL';
export const SET_SHOW_ACTIVATE_MODAL = 'SET_SHOW_ACTIVATE_MODAL';
export const SET_SHOW_PRINT_MODAL = 'SET_SHOW_PRINT_MODAL';
export const APPLICATION_ADD_AGREEMENT = 'NEW_APPLICATION_ADD_AGREEMENT';
export const APPLICATION_REMOVE_AGREEMENT = 'NEW_APPLICATION_REMOVE_AGREEMENT';
export const APPLICATION_CHANGE_PAY_FILES = 'NEW_APPLICATION_CHANGE_PAY_FILES';

export const getApplication = id => async dispatch => {
  dispatch({ type: GET_APPLICATION_REQUEST });
  try {
    const application = await applicationService.getFullItem(id);
    dispatch({ type: GET_APPLICATION_SUCCESS, payload: application.data });
  } catch (e) {
    console.log(e);
    dispatch({ type: GET_APPLICATION_FAILED });
  }
};

export const changeStatusToWork = id => async dispatch => {
  dispatch({ type: CHANGE_STATUS_REQUEST });
  try {
    const response = await applicationService.toWork({ id });
    const application = await applicationService.getFullItem(id);
    dispatch({ type: CHANGE_STATUS_REQUEST_SUCCESS, payload: application.data });
    return Promise.resolve();
  } catch (e) {
    dispatch({ type: CHANGE_STATUS_REQUEST_FAILED });
    return Promise.reject();
  }
};

export const changeEditRoute = isEdit => ({
  type: APPLICATION_CHANGE_EDIT_ROUTE,
  payload: isEdit
});

export const changeRouteByAdmin = (applicationId, waypoints, routeMarkers) => async dispatch => {
  try {
    const application = await applicationService.changeRouteByAdmin(
      applicationId,
      waypoints,
      routeMarkers
    );
    dispatch({ type: APPLICATION_CHANGE_ROUTE_BY_ADMIN, payload: application.data });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
};

export const changeMarkerText = (markerId, newValue) => ({
  type: APPLICATION_CHANGE_MARKET_TEXT,
  payload: {
    markerId,
    newValue
  }
});

export const removeMarker = markerId => ({
  type: APPLICATION_REMOVE_MARKER,
  payload: markerId
});

export const onCommentChange = value => ({
  type: APPLICATION_COMMENT_CHANGED,
  payload: value
});

export const saveApplicationRoute = (id, comment, markers, routeChanged) => async dispatch => {
  dispatch({ type: CHANGE_STATUS_REQUEST });
  try {
    const response = await applicationService.accept(id, comment, markers, routeChanged, 0, 0);
    const application = await applicationService.getFullItem(id);
    dispatch({ type: CHANGE_STATUS_REQUEST_SUCCESS, payload: application.data });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
};

export const setShowDeclineModal = isShow => ({
  type: SET_SHOW_DECLINE_MODAL,
  payload: isShow
});

export const setShowActivateModal = isShow => ({
  type: SET_SHOW_ACTIVATE_MODAL,
  payload: isShow
});

export const setShowPrintModal = isShow => ({
  type: SET_SHOW_PRINT_MODAL,
  payload: isShow
});

export const updateApplication = application => async dispatch => {
  dispatch({ type: CHANGE_STATUS_REQUEST_SUCCESS, payload: application.data });
};

export const acceptApplication = isSpring => async (dispatch, getState) => {
  dispatch({ type: CHANGE_STATUS_REQUEST });
  try {
    const state = getState();
    const id = getApplicationId(state);
    const comment = getRouteChangeComment(state);
    const markers = getApplicationMarkers(state);
    const routeChanged = getIsRouteChanged(state);
    if (routeChanged && (!comment || !markers.map(m => m.text).every(m => m))) {
      return Promise.reject();
    }
    const response = await applicationService.accept(
      id,
      comment,
      markers,
      routeChanged,
      1,
      isSpring
    );
    const application = await applicationService.getFullItem(id);
    dispatch({ type: CHANGE_STATUS_REQUEST_SUCCESS, payload: application.data });
    return Promise.resolve();
  } catch (e) {
    dispatch({ type: CHANGE_STATUS_REQUEST_FAILED });
    return Promise.reject();
  }
};

export const activateApplication = () => async (dispatch, getState) => {
  dispatch({ type: CHANGE_STATUS_REQUEST });
  try {
    const state = getState();
    const id = getApplicationId(state);
    const response = await applicationService.activate(id);
    const application = await applicationService.getFullItem(id);
    dispatch({ type: CHANGE_STATUS_REQUEST_SUCCESS, payload: application.data });
    return Promise.resolve();
  } catch (e) {
    dispatch({ type: CHANGE_STATUS_REQUEST_FAILED });
    return Promise.reject();
  }
};

export const updatePrintForm = values => ({
  type: APPLICATION_UPDATE_PRINT_FORM,
  payload: values
});

export const lockApplication = () => async (dispatch, getState) => {
  dispatch({ type: CHANGE_STATUS_REQUEST });
  try {
    const state = getState();
    const id = getApplicationId(state);
    const response = await applicationService.lock(id);
    const application = await applicationService.getFullItem(id);
    dispatch({ type: CHANGE_STATUS_REQUEST_SUCCESS, payload: application.data });
    return Promise.resolve();
  } catch (e) {
    dispatch({ type: CHANGE_STATUS_REQUEST_FAILED });
    return Promise.reject();
  }
};

export const unlockApplication = () => async (dispatch, getState) => {
  dispatch({ type: CHANGE_STATUS_REQUEST });
  try {
    const state = getState();
    const id = getApplicationId(state);
    const response = await applicationService.unlock(id);
    const application = await applicationService.getFullItem(id);
    dispatch({ type: CHANGE_STATUS_REQUEST_SUCCESS, payload: application.data });
    return Promise.resolve();
  } catch (e) {
    dispatch({ type: CHANGE_STATUS_REQUEST_FAILED });
    return Promise.reject();
  }
};

export const addAgreement = agreement => ({
  type: APPLICATION_ADD_AGREEMENT,
  payload: agreement
});

export const removeAgreement = agreementId => ({
  type: APPLICATION_REMOVE_AGREEMENT,
  payload: agreementId
});

export const changePayFiles = files => ({
  type: APPLICATION_CHANGE_PAY_FILES,
  payload: files
});

export const acceptAgreement = () => async (dispatch, getState) => {
  dispatch({ type: CHANGE_STATUS_REQUEST });
  try {
    const state = getState();
    const id = getApplicationId(state);
    const response = await applicationService.agreementAccept(id);
    const application = await applicationService.getFullItem(id);
    dispatch({ type: CHANGE_STATUS_REQUEST_SUCCESS, payload: application.data });
    return Promise.resolve();
  } catch (e) {
    dispatch({ type: CHANGE_STATUS_REQUEST_FAILED });
    return Promise.reject();
  }
};

export const declineAgreement = () => async (dispatch, getState) => {
  dispatch({ type: CHANGE_STATUS_REQUEST });
  try {
    const state = getState();
    const id = getApplicationId(state);
    const response = await applicationService.agreementDecline({ id });
    const application = await applicationService.getFullItem(id);
    dispatch({ type: CHANGE_STATUS_REQUEST_SUCCESS, payload: application.data });
    return Promise.resolve();
  } catch (e) {
    dispatch({ type: CHANGE_STATUS_REQUEST_FAILED });
    return Promise.reject();
  }
};
