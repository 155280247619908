import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { FormField } from '../../../components/form/FormField';
import { applicationActions } from '../../../actions/applicationActions';
import { Loader } from '../../../components/Loader';
import { renderSelectField } from '../../../components/form/renderSelectField';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';

let ApplicationFilterForm = props => {
  const { handleSubmit, submitting, handleModalClose } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Field name="id" type="text" label="Номер заявления" className="" component={FormField} />
      <Field
        className="form-control"
        name="issue_place_id"
        label="Территориальное управление"
        component={renderSelectField}
      >
        <option key="0" value="">
          Все
        </option>
        {dictionaryHelper.issuePlaces.getList().map(option => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </Field>
      <Field name="form_id" type="text" label="Номер бланка" className="" component={FormField} />
      <Field
        name="name"
        type="text"
        label="Фио заявителя / Название компании"
        className=""
        component={FormField}
      />
      <Field name="number" type="text" label="ГРЗ ТС" className="" component={FormField} />
      <Field
        name="trailer_number"
        type="text"
        label="ГРЗ прицепа"
        className=""
        component={FormField}
      />
      <Field
        name="start_address"
        type="text"
        label="Начальная точка маршрута"
        className=""
        component={FormField}
      />
      <Field
        name="finish_address"
        type="text"
        label="Конечная точка маршрута"
        className=""
        component={FormField}
      />
      <Field
        className="form-control"
        name="trip_type"
        label="Тип поездки"
        component={renderSelectField}
      >
        <option key="0" value="">
          Все
        </option>
        {dictionaryHelper.applicationTypes.getList().map(option => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </Field>
      <Field
        name="admin_name"
        type="text"
        label="ФИО сотрудника, взявшего заявку в работу"
        className=""
        component={FormField}
      />
      <p>
        <strong>Сортировать</strong>
      </p>
      <Field className="form-control" name="sort_column" label="Поле" component={renderSelectField}>
        {dictionaryHelper.applicationSortFields.getList().map(option => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </Field>
      <Field className="form-control" name="sort_type" label="Способ" component={renderSelectField}>
        <option key="1" value="asc">
          По Возрастанию
        </option>
        <option key="2" value="desc">
          По Убыванию
        </option>
      </Field>
      <Button
        variant="contained"
        color="primary"
        className="btn-add no-margin"
        type="submit"
        disabled={submitting}
      >
        {submitting && <Loader />} Применить
      </Button>
      &nbsp;
      <Button variant="outlined" component="span" className="info-btn" onClick={handleModalClose}>
        Отменить
      </Button>
    </form>
  );
};

ApplicationFilterForm = reduxForm({
  form: 'application-admin-filter-form',
  enableReinitialize: false,
  destroyOnUnmount: false
})(ApplicationFilterForm);

const mapStateToProps = state => {
  const { authentication, adminApplications } = state;
  const { user } = authentication;
  return {
    initialValues: {
      department_id: user.department_id,
      status: adminApplications.tabIndex,
      showAll: 0
    }
  };
};

const mapDispatchToProps = dispatch => {
  const handleModalClose = () => {
    dispatch(applicationActions.closeFilterModal());
  };
  return {
    handleModalClose,
    dispatch
  };
};

ApplicationFilterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationFilterForm);
export default ApplicationFilterForm;
