import React from 'react';
import Select from 'react-select';
import { isNumber, get } from 'lodash';

export const renderSelectField = ({
  disabled,
  input,
  label,
  type,
  meta: { touched, error },
  children,
  className
}) => {
  const options = React.Children.map(children, element => {
    if (!React.isValidElement(element)) return [];

    const { value, children: label } = element.props;
    const valueWithType = isNumber(input.value) ? value : `${value}`;
    return { value: valueWithType, label };
  });
  const valueWithLabel = options ? options.find(o => o.value === input.value) : '';
  const placeholder = options ? options.find(o => o.value === '') : '';
  return (
    <div className={`form-group${touched && error ? ' has-error' : ''}`}>
      <div className="row">
        <div className="col-md-4">
          <p className="label-form">{label}</p>
        </div>
        <div className="col-md-8">
          <Select
            {...input}
            isSearchable={false}
            onBlur={() => input.onBlur()}
            isDisabled={disabled || disabled === 'disabled'}
            placeholder={placeholder ? placeholder.label : get(options, '[0].label')}
            value={valueWithLabel}
            options={options}
            onChange={({ value }) => input.onChange(value)}
          />
          {touched && error && <span>{error}</span>}
        </div>
      </div>
    </div>
  );
};
