import React from 'react';
import ShowByStatus from './ShowByStatus';
import { applicationConstants } from '../../../constants/applicationConstants';
import { roleConstants } from '../../../constants/roleConstants';
import Files from './components/Files';
import { fileService } from '../../../services/fileService';
import * as actions from '../../../actions/newApplicationActions';
import { getPayFiles } from '../../../selectors/applicationSelectors';
import { connect } from 'react-redux';

const ApplicationFiles = ({ application, files, changePayFiles }) => (
  <div className="application-action-block">
    <ShowByStatus
      allowedStatuses={[
        applicationConstants.STATUS_ACCEPT_WITH_CHANGES,
        applicationConstants.STATUS_ACCEPT,
        applicationConstants.STATUS_ACTIVE
      ]}
      allowedRoles={[roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER]}
      additionalCondition={files && files.length > 0}
    >
      <div id="file-list">
        <Files files={files || []} uploadLabel="Добавить документ об оплате (до 5 Мб):" />
      </div>
    </ShowByStatus>
    <ShowByStatus
      allowedStatuses={[
        applicationConstants.STATUS_ACCEPT_WITH_CHANGES,
        applicationConstants.STATUS_ACCEPT
      ]}
      allowedRoles={[
        roleConstants.ROLE_FIRMUSER,
        roleConstants.ROLE_FIRM,
        roleConstants.ROLE_INDIVIDUAL
      ]}
    >
      <div id="file-list">
        <Files
          application={application}
          files={files || []}
          uploadLabel="+ Добавить файл"
          removeFile={file => fileService.removeFile(file.id)}
          fileUploader={(id, file) => fileService.uploadApplicationPayNew(id, file)}
          hint="Добавить документ об оплате (до 5 Мб):"
          handleFileRemove={file => changePayFiles(files.filter(f => f.id !== file.id))}
          handleFileAdd={file => changePayFiles([...files, file])}
          isEditable
        />
      </div>
    </ShowByStatus>
  </div>
);

const mapStateToProps = state => ({
  files: getPayFiles(state)
});

const mapDispatchToProps = {
  changePayFiles: actions.changePayFiles
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationFiles);
