import React from 'react';
import { connect } from 'react-redux';
import { Button, TableRow, TableCell } from '@material-ui/core';
import Filter from '@material-ui/icons/FilterList';
import { Field, Formik } from 'formik';
import Select from 'react-select';
import { vehicleModelActions } from '../../../../../actions/vehicleModelActions';
import MaterialInput from '../../../quickApplications/CreateQuickApplication/components/MaterialInput';

const IS_TRAILER_OPTIONS = [
  { value: undefined, label: 'Все' },
  { value: 0, label: 'ТС' },
  { value: 1, label: 'Прицеп' }
];

const VehicleModelsFilterComponent = ({ filters, getPage }) => {
  return (
    <Formik
      initialValues={filters}
      onSubmit={value => getPage(1, value)}
      render={({ values, submitForm, setFieldValue }) => (
        <TableRow>
          <TableCell>
            <Filter />
          </TableCell>
          <TableCell>
            <Field
              placeholder="Тип"
              name="is_trailer"
              component={Select}
              value={IS_TRAILER_OPTIONS.find(({ value }) => value === values.is_trailer)}
              options={IS_TRAILER_OPTIONS}
              onChange={v => setFieldValue('is_trailer', v.value)}
            />
          </TableCell>
          <TableCell colSpan={3}>
            <Field name="brand_title" component={MaterialInput} placeholder="Марка" />
          </TableCell>
          <TableCell colSpan={3}>
            <Field name="model_title" component={MaterialInput} placeholder="Модель" />
          </TableCell>
          <TableCell colSpan={2}>
            <Button
              variant="contained"
              color="primary"
              className="btn-add no-margin"
              type="submit"
              onClick={submitForm}
            >
              Применить
            </Button>
          </TableCell>
        </TableRow>
      )}
    />
  );
};

const mapStateToProps = state => {
  const { vehicleModels } = state;

  return {
    filters: vehicleModels.filters
  };
};

const mapDispatchToProps = {
  getPage: vehicleModelActions.getPage
};

const connectedVehicleModelsFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleModelsFilterComponent);
export { connectedVehicleModelsFilter as VehicleModelsFilter };
