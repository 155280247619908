import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';

import ReportsPageView from './ReportsPageView';
import reportsService from '../../../services/reportsService';
import { departmentService } from '../../../services/departmentService';
import { StateContext } from './components/StateContext';
import { checkEmpty } from './util';
import {STATUS_OPTIONS, TYPE_OPTIONS} from './components/ReportsFilterModal/ReportsFilterForm';

const initialStatisticsFilter = {
  start_date: undefined,
  finish_date: undefined,
  issue_place: -1
};

const ReportsPage = () => {
  const [filter, setFilter] = useState({
    applicationId: undefined,
    fio: undefined,
    grz: undefined,
    create_date_from: undefined,
    create_date_to: undefined,
    accept_date_from: undefined,
    accept_date_to: undefined,
    activate_date_from: undefined,
    activate_date_to: undefined,
    actionTypes: [],
    page: 1,
    perPage: 20,
    type: TYPE_OPTIONS.slice(0, 3),
    privilege: STATUS_OPTIONS
  });

  const [statisticsFilter, setStatisticsFilter] = useState(initialStatisticsFilter);

  const reportMapping = f => ({
    id: checkEmpty(f.id),
    name: checkEmpty(f.name),
    issue_place_id: checkEmpty(f.issue_place_id),
    create_date_from: checkEmpty(f.create_date_from),
    create_date_to: checkEmpty(f.create_date_to),
    accept_date_from: checkEmpty(f.accept_date_from),
    accept_date_to: checkEmpty(f.accept_date_to),
    activate_date_from: checkEmpty(f.activate_date_from),
    activate_date_to: checkEmpty(f.activate_date_to),
    departments: f.departments || [],
    per_page: f.perPage,
    page: f.page,
    type: checkEmpty(f.type) ? f.type.map(t => t.value) : undefined,
    privilege: checkEmpty(f.privilege) ? f.privilege.map(t => t.value) : undefined,
  });

  const statisticsMapping = f => ({
    start_date: checkEmpty(f.start_date),
    finish_date: checkEmpty(f.finish_date),
    issue_place: f.issue_place === -1 ? undefined : f.issue_place
  });

  const statistic = useAsync({
    deferFn: ([a]) => reportsService.getStatistic(a),
    ...statisticsMapping(filter)
  });

  const departments = useAsync({ promiseFn: departmentService.getAll });

  const reportsState = useAsync({
    deferFn: ([a]) => reportsService.getReports(a),
    ...reportMapping(filter)
  });

  const exportState = useAsync({
    deferFn: ([a]) => reportsService.exportReports(a)
  });

  const onExport = () => exportState.run(reportMapping(filter));

  useEffect(() => {
    reportsState.run(reportMapping(filter));
  }, [filter]);

  useEffect(() => {
    statistic.run(statisticsMapping(statisticsFilter));
  }, [statisticsFilter]);

  return (
    <StateContext.Provider value={{ reportsState, departments, exportState, statistic }}>
      <ReportsPageView
        onSubmit={setFilter}
        onExport={onExport}
        filter={filter}
        setFilter={setFilter}
        statisticsFilter={statisticsFilter}
        onStatisticsSubmit={setStatisticsFilter}
        onResetStatistics={() => setStatisticsFilter(initialStatisticsFilter)}
      />
    </StateContext.Provider>
  );
};

export default ReportsPage;
