import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { withSnackbar } from 'notistack';
import { get } from 'lodash';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { applicationActions } from '../../../actions/applicationActions';
import { urlHelper } from '../../../helpers/urlHelper';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { Loader, TablePagination } from '../../../components';
import { getApplicationViewData } from './content/util';
import { applicationConstants } from '../../../constants/applicationConstants';
import { roleConstants } from '../../../constants/roleConstants';
import EmployeeCell from './EmployeeCell';

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  highlight: {
    backgroundColor: '#ffb1b1'
  }
};

let ApplicationsTableList = props => {
  const {
    authentication,
    adminApplications,
    data,
    meta,
    classes,
    handlePageChange,
    handlePerPageChange,
    handleToWork,
    handleCheckAll
  } = props;
  const { user } = authentication;
  const { handleSubmit, submitting } = props;

  const getLinkPrefix = () => {
    return urlHelper.getHomeUrl(user.role_id);
  };

  return (
    <div>
      {adminApplications && adminApplications.loading && <Loader />}
      {adminApplications && adminApplications.error && (
        <span className="text-danger">Ошибка: {adminApplications.error}</span>
      )}
      {adminApplications && adminApplications.items && !adminApplications.loading && (
        <form onSubmit={handleSubmit}>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Field
                      name="towork-all"
                      type="checkbox"
                      label="Отметить все"
                      className="checkbox checkbox_table"
                      component={MuiCheckbox}
                      onChange={handleCheckAll}
                    />
                  </TableCell>
                  <TableCell numeric>
                    <Button className="btn-add" type="submit" disabled={submitting}>
                      {submitting && <Loader />} Взять в работу
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>В работу</TableCell>
                  <TableCell>Заявление №</TableCell>
                  <TableCell>Дата подачи</TableCell>
                  <TableCell>Организация / ФИО</TableCell>
                  {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT && (
                    <TableCell>Территориальное управление</TableCell>
                  )}
                  <TableCell numeric>Действие</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(application => {
                  const viewData = getApplicationViewData(
                    application,
                    applicationConstants.STATUS_NEW,
                    user
                  );
                  return (
                    <TableRow
                      key={`application-${viewData.id}`}
                      classes={{
                        root: viewData.timePassed && classes.highlight
                      }}
                    >
                      <TableCell>
                        <Field
                          name={`towork.${viewData.id}`}
                          type="checkbox"
                          className="checkbox checkbox_table"
                          component={MuiCheckbox}
                        />
                      </TableCell>
                      <TableCell>{viewData.appNumber}</TableCell>
                      <TableCell>{viewData.createdAt}</TableCell>
                      <EmployeeCell userName={viewData.userName} application={application} />
                      {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT && (
                        <TableCell>{viewData.issuePlace}</TableCell>
                      )}
                      <TableCell numeric className="nowrap">
                        {user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT &&
                        !!application.smev ? (
                          <Link to={`${getLinkPrefix()}/cooperation/view/${viewData.id}`}>
                            <Button className="info-btn">Подробнее</Button>
                          </Link>
                        ) : (
                          <Link to={`${getLinkPrefix()}/applications/view/${viewData.id}`}>
                            <Button className="info-btn">Подробнее</Button>
                          </Link>
                        )}
                        <Button className="info-btn" onClick={handleToWork(viewData.id)}>
                          Взять в работу
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
          {adminApplications && adminApplications.items && !adminApplications.loading && (
            <TablePagination
              meta={meta}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerPageChange}
            />
          )}
        </form>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { adminApplications, authentication } = state;

  const selector = formValueSelector('application-admin-filter-form');
  const filterData = selector(
    state,
    'id',
    'issue_place_id',
    'form_id',
    'name',
    'number',
    'trailer_number',
    'start_address',
    'finish_address',
    'trip_type',
    'admin_name',
    'sort_column',
    'sort_type',
    'showAll'
  );

  return {
    adminApplications,
    authentication,
    filterData
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { adminApplications, authentication, filterData } = stateProps;
  const { items } = adminApplications;
  const { dispatch } = dispatchProps;

  const getApplications = ({ page, perPage } = {}) => {
    const values = {
      ...filterData,
      status: adminApplications.tabIndex,
      page: page || adminApplications.meta.current_page,
      per_page: perPage || adminApplications.meta.per_page
    };

    return dispatch(applicationActions.getAdminApplications(values));
  };

  const handlePageChange = page => getApplications({ page });

  const handlePerPageChange = perPage => getApplications({ page: 1, perPage });

  const handleToWork = id => () =>
    dispatch(applicationActions.toWork(id)).catch(error => {
      const message = get(error, 'response.data.error');
      ownProps.enqueueSnackbar(message || 'Произошла ошибка, попробуйте позже!', {
        variant: 'error'
      });
      if (message) getApplications();
    });

  const handleCheckAll = e => {
    const isChecked = e.currentTarget.checked;
    const { data } = ownProps;

    for (let i = 0; i < data.length; i++) {
      dispatch(change('application-towork-form', `towork.${data[i].id}`, isChecked));
    }
  };

  return {
    ...ownProps,
    adminApplications,
    authentication,

    handleToWork,
    handleCheckAll,

    handlePageChange,
    handlePerPageChange
  };
};

ApplicationsTableList = reduxForm({
  form: 'application-towork-form',
  enableReinitialize: true
})(ApplicationsTableList);

export default compose(
  withStyles(styles),
  withSnackbar,
  connect(
    mapStateToProps,
    null,
    mergeProps
  )
)(ApplicationsTableList);
