import React from 'react';

export const LabelField = ({ input, label, className, error, children, onClick }) => (
  <div className={`form-group${error ? ' has-error' : ''}`}>
    <div className="row">
      <div className="col-md-4">
        <p className="label-form">{label}</p>
      </div>
      <div className="col-md-8" onClick={onClick}>
        <input
          className={`form-control ${className || ''}${error ? ' form-control-error' : ''} ${
            children ? 'inline-form-input' : ''
          }`}
          {...input}
        />
        {error && <span>{error}</span>}
        {children}
      </div>
    </div>
  </div>
);
