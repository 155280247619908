import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components/Loader';
import { fieldMasks } from '../../../helpers/fieldMasks';
import { cabinetActions } from '../../../actions/cabinetActions';
import { maskHelper } from '../../../helpers/maskHelper';
import { FormField } from '../../../components/form/FormField';
import { renderSelectField } from '../../../components/form/renderSelectField';

let FirmForm = props => {
  const { handleSubmit, cabinet } = props;

  return (
    <div>
      {cabinet.loading && <Loader />}
      {!cabinet.loading && (
        <form onSubmit={handleSubmit}>
          <Field
            disabled="disabled"
            name="name"
            type="text"
            label="Название организации"
            validate={[fieldValidators.required, fieldValidators.maxLength40]}
            component={FormField}
          />

          <Field
            className="form-control"
            name="legal_form_id"
            component={renderSelectField}
            label="Тип организации"
            validate={[fieldValidators.required]}
            disabled="disabled"
          >
            {cabinet.firm && (
              <option value={cabinet.firm.legal_form_id}>{cabinet.firm.legal_form_title}</option>
            )}
          </Field>

          <Field
            disabled="disabled"
            name="address"
            type="text"
            label="Юридический адрес"
            validate={[fieldValidators.required, fieldValidators.maxLength100]}
            component={FormField}
          />

          <Field
            disabled="disabled"
            name="phone"
            type="text"
            label="Контактный телефон организации"
            validate={[fieldValidators.required, fieldValidators.phoneRus]}
            component={FormField}
            {...fieldMasks.phoneRus}
          />

          <Field
            disabled="disabled"
            name="email"
            type="email"
            label="Email"
            validate={[
              fieldValidators.required,
              fieldValidators.email,
              fieldValidators.maxLength40
            ]}
            component={FormField}
          />
        </form>
      )}
      {cabinet.status}
    </div>
  );
};

FirmForm = reduxForm({
  form: 'cabinet-firm',
  enableReinitialize: true
})(FirmForm);

const mapStateToProps = state => {
  const { user } = state.authentication;
  const { cabinet } = state;

  return {
    user,
    cabinet,
    initialValues: cabinet.firm
      ? {
          name: cabinet.firm.name,
          phone: maskHelper.unmaskPhone(cabinet.firm.phone),
          email: cabinet.firm.email,
          address: cabinet.firm.address
        }
      : null
  };
};
const mapDispatchToProps = dispatch => {
  dispatch(cabinetActions.getFirmUserFirm());

  return {
    dispatch
  };
};

const connectedFirmForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(FirmForm);
export { connectedFirmForm as FirmForm };
