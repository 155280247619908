import React from 'react';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { fieldValidators } from '../../../../../helpers/fieldValidators';
import { Loader } from '../../../../../components/Loader';
import { FormField } from '../../../../../components/form/FormField';
import { FormFieldReg } from '../../../../../components/form/FormFieldReg';
import { privilegeVehicleActions } from '../../../../../actions/privilegeVehicleActions';
import { vehicleModelActions } from '../../../../../actions/vehicleModelActions';

let StatusVehiclesForm = props => {
  const { handleSubmit, submitting, currentItem } = props;

  return (
    <form onSubmit={handleSubmit} className="text-left">
      <Field
        name="privilege_status_id"
        type="hidden"
        label=""
        validate={[fieldValidators.required]}
        component={FormFieldReg}
      />
      <Field
        name="number"
        label="ГРЗ ТС"
        className="full-input text-left"
        component={FormField}
        validate={[fieldValidators.required]}
      />
      <Field
        name="region"
        label="Регион"
        className="full-input"
        component={FormField}
        validate={[fieldValidators.required]}
      />
      <Field name="brand_title" label="Марка" className="full-input" component={FormField} />

      <Field name="model_title" label="Модель" className="full-input" component={FormField} />

      <div>
        {submitting && <Loader />}

        <button type="submit" className="btn" disabled={submitting}>
          {currentItem && currentItem.id ? 'Редактировать' : 'Создать'}
        </button>
      </div>
    </form>
  );
};

StatusVehiclesForm = reduxForm({
  form: 'status-vehicle-form',
  enableReinitialize: true
})(StatusVehiclesForm);

const mapStateToProps = (state, ownProps) => {
  const { privilegeStatuses, privilegeVehicles } = state;
  const { currentItem, currentStatus } = ownProps;

  const selector = formValueSelector('status-vehicle-form');
  const { is_trailer, is_non_standard_number } = selector(
    state,
    'is_trailer',
    'is_non_standard_number'
  );

  return {
    privilegeStatuses,
    privilegeVehicles,
    initialValues: {
      id: currentItem ? currentItem.id : '',
      vehicle_brand_id:
        currentItem && currentItem.vehicle_brand_id ? currentItem.vehicle_brand_id : '',
      brand: currentItem ? currentItem.vehicle_brand_title : '',
      vehicle_model_id:
        currentItem && currentItem.vehicle_model_id ? currentItem.vehicle_model_id : '',
      model: currentItem ? currentItem.vehicle_model_title : '',
      privilege_status_id: currentStatus ? currentStatus.id : '',
      number: currentItem && currentItem.number ? currentItem.number : '',
      region: currentItem && currentItem.region ? currentItem.region : '',
      is_non_standard_number:
        currentItem && currentItem.is_non_standard_number ? currentItem.is_non_standard_number : '',
      non_standard_number:
        currentItem && currentItem.non_standard_number ? currentItem.non_standard_number : '',
      is_trailer: currentItem && currentItem.is_trailer ? currentItem.is_trailer : 0
    },
    is_trailer,
    is_non_standard_number
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    privilegeStatuses,
    privilegeVehicles,
    initialValues,
    is_trailer,
    is_non_standard_number
  } = stateProps;
  const { dispatch } = dispatchProps;

  const handleModalClose = () => {
    dispatch(privilegeVehicleActions.closeFormModal());
  };

  const handleVehicleBrandChange = (event, value) => {
    if (value) {
      dispatch(vehicleModelActions.getAll(parseInt(value, 10)));
    }
  };

  const handleNonStandardChange = event => {
    const isNonValue = is_non_standard_number ? 0 : 1;
    dispatch(change('status-vehicle-form', 'is_non_standard_number', isNonValue));
  };

  return {
    ...ownProps,

    privilegeStatuses,
    privilegeVehicles,
    initialValues,
    is_trailer,
    is_non_standard_number,
    handleNonStandardChange,

    handleModalClose,
    handleVehicleBrandChange
  };
};

StatusVehiclesForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(StatusVehiclesForm);

export default StatusVehiclesForm;
