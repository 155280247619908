import React from 'react'
import {connect} from 'react-redux'
import {dictionaryHelper} from "../../../helpers/dictionaryHelper";
import {fieldValidators} from "../../../helpers/fieldValidators";
import {Loader} from "../../../components";
import Button from '@material-ui/core/Button';
import {history} from "../../../helpers/history";
import MateriaInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput'
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialSelect from '../../admin/quickApplications/CreateQuickApplication/components/MaterialSelect'
import Grid from '@material-ui/core/Grid';
import {Formik, Field, Form} from 'formik';
import {Link} from 'react-router-dom'
import {paymentService} from "../../../services/paymentService";


class EditForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            user_id: null,
        }
    }

    handleCancel = () => {
        history.push(
            history.location.pathname.split('edit').join('show')
        );
    }

    render() {
        const {onSubmit, submitting, error, payment} = this.props

        const date = payment.payment_create_date;
        const initialValues = {
            ...payment,
            payment_create_date: (date ? date : payment.created_at).toString().split(' ')[0]
        }
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={filters => {
                    onSubmit(filters);
                }}
                render={({values, setFieldValue}) => (

                    <Form>

                        <Grid container direction="column" spacing={16} wrap="nowrap">
                            <Grid item>
                                <FieldLabel label="Дата создания" name="payment_create_date">
                                    <Field
                                        name="payment_create_date"
                                        type="date"
                                        disabled={!this.props.edit}
                                        component={MateriaInput}
                                    />
                                </FieldLabel>
                            </Grid>
                            <Grid item>
                                <FieldLabel label="Тип" name="payment_type">
                                    <Field
                                        name="payment_type"
                                        component={MaterialSelect}
                                        isDisabled={true}
                                        options={dictionaryHelper.paymentTypes.map(({id, title}) => ({
                                            value: id,
                                            label: title
                                        }))}
                                    >
                                    </Field>
                                </FieldLabel>
                            </Grid>
                            <Grid item>
                                <FieldLabel label="Организация/ФИО" name="payer.name">
                                    <Field
                                        name="payer.name"
                                        type="text"
                                        disabled={true}
                                        label="Организация/ФИО"
                                        component={MateriaInput}
                                    />
                                </FieldLabel>


                            </Grid>
                            <Grid item>
                                <FieldLabel label="Сумма" name="price">
                                    <Field
                                        className="form-control"
                                        name="price"
                                        type="number"
                                        min="1"
                                        disabled={!this.props.edit}
                                        component={MateriaInput}
                                        validate={[fieldValidators.required]}
                                    />
                                </FieldLabel>

                            </Grid>
                            <Grid item>
                                <FieldLabel label="Сотрудник" name="employee.name">
                                    <Field
                                        name="employee.name"
                                        type="text"
                                        disabled={true}
                                        component={MateriaInput}
                                    />
                                </FieldLabel>

                            </Grid>
                            <Grid item>
                                <FieldLabel label="УИН РИС ГМП" name="uin">
                                    <Field
                                        name="uin"
                                        type="text"
                                        disabled={true}
                                        label="УИН РИС ГМП"
                                        component={MateriaInput}
                                    />
                                </FieldLabel>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" alignItems="center">
                                    <Grid item xs={5} lg={3}>
                                        <span>Счет на оплату</span>
                                    </Grid>
                                    <Grid item xs={7} lg={9}>
                                        <Link to={''}
                                              onClick={(e) => {
                                                  e.preventDefault()
                                                  paymentService.downloadInvoice(payment)
                                              }}

                                              target="_blank"
                                              className="input-link"
                                        >
                                            Счет на оплату
                                        </Link>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item>

                                {this.props.edit && error && <strong>{error}</strong>}
                                {this.props.edit && (<div>
                                    {submitting && <Loader/>}
                                    <button type="submit" className="btn" disabled={submitting}>
                                        Сохранить
                                    </button>

                                    <Button
                                        className="info-btn"
                                        variant="outlined"
                                        color="primary"
                                        type="button"
                                        onClick={this.handleCancel}
                                    >
                                        Отменить
                                    </Button>
                                    <br/>
                                    <br/>
                                </div>)}
                            </Grid>
                        </Grid>
                    </Form>
                )}
            />
        )
    }
}


export default EditForm