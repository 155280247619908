import { stringify } from 'query-string';
import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'vehicle-brands';
};

const getAll = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}`, {}, true);
};

const getPage = (page, filters = {}, sort = {}) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/page?${stringify({ page, ...filters, ...sort })}`,
    {},
    true
  );
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};
const accept = id => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/accept/${id}`, {}, true);
};
const hide = id => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/hide/${id}`, {}, true);
};

const exportXls = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/export`, values, true);
};

export const vehicleBrandService = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,
  accept,
  hide,

  exportXls
};
