import React from 'react';
import { ErrorMessage, Field, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import { DeclineSchema } from '../util/Schema';

const DeclineModal = ({ open, onClose, onSubmit }) => (
  <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
    <Toolbar className="text-center">
      <Typography variant="title" color="inherit" className="flex">
        Отклонение заявки
      </Typography>
      <IconButton color="inherit" onClick={onClose}>
        <CloseIcon className="blue" />
      </IconButton>
    </Toolbar>
    <DialogContent className="dialog text-center">
      <Formik
        initialValues={{ note: '' }}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(data);
          setSubmitting(false);
        }}
        validate={async values => {
          return new Promise((resolve, reject) => {
            validateYupSchema(values, DeclineSchema, false, { head: values }).then(
              () => resolve({}),
              err => reject(yupToFormErrors(err))
            );
          });
        }}
        render={({ isValid, isSubmitting }) => (
          <Form>
            <Grid container direction="column" spacing={16} wrap="nowrap">
              <Grid item>
                <Field
                  component="textarea"
                  name="note"
                  className="form-control"
                  placeholder="Замечания"
                  rows={4}
                />
              </Grid>
              <Grid item className="has-error">
                <ErrorMessage name="note">{msg => <span>{msg}</span>}</ErrorMessage>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={`btn-add no-margin ${!isValid ? 'disabled-button' : ''}`}
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? <CircularProgress /> : 'Отклонить'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      />
    </DialogContent>
  </Dialog>
);

export default DeclineModal;
