import React from 'react';
import Filter from './Filter'
import {Loader} from '../../../components/Loader'
import { Button, Grid } from '@material-ui/core';
import { paymentsActions }from '../../../actions/paymentsActions'
import { connect } from 'react-redux';
import {Link} from 'react-router-dom'
import {roleConstants} from "../../../constants/roleConstants";
import {urlHelper} from "../../../helpers/urlHelper";
import {PaymentsStatTable} from "./PaymentsStatTable";

class AccountantStatPage extends React.Component{

    componentDidMount() {
        const { payments } = this.props;
        if (!payments.stat || payments.stat.length === 0) {
            this.handleFilterChange(payments.filters)
        }
    }
    handleFilterChange = (filter) => {
        this.props.dispatch(paymentsActions.getStat(filter));
    }

    render() {

        const { payments } = this.props;
        return (
            <Grid
                container
                role="main"
                className="container text-left"
                direction="column"
                spacing={16}
                wrap="nowrap"
            >
                <Grid item>
                    <Grid container direction="column" spacing={16}>
                        <Grid item>
                            Статистика
                        </Grid>
                        <Grid item>
                            <Filter currentFilters={payments.filters} onFiltersChange={this.handleFilterChange}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className="">
                    {payments.loading && <Loader />}
                    {payments.error && <span className="text-danger">Ошибка: {payments.error}</span>}
                    {(!payments.error && payments.stat.length > 0) && (<PaymentsStatTable data={payments.stat} meta={payments.meta} />)}
                </Grid>
            </Grid>
        )
    }
}
const ConAccountantStatPage = connect(
    (state) => {
        const { payments } = state;
        return {
            payments,
        };
    },
    (dispatch, props) => {
        return {
            dispatch
        }
    }
)(AccountantStatPage)
export {ConAccountantStatPage as  AccountantStatPage};
