import React from 'react';
import { range } from 'lodash';
import { withStyles } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import ContentContainer from './ContentContainer';
import DetailsLink from './DetailsLink';
import { getApplicationViewData } from './util';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { roleConstants } from '../../../../constants/roleConstants';
import EmployeeCell from '../EmployeeCell';

const styles = {
  highlight: {
    backgroundColor: '#ffb1b1'
  }
};

const Row = ({ viewData, agreement, rowSpan = 1, fill = false, user, application, classes }) => (
  <TableRow
    key={viewData.id}
    classes={{
      root: viewData.timePassed && classes.highlight
    }}
  >
    {fill && <TableCell rowSpan={rowSpan}>{viewData.appNumber}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{viewData.createdAt}</TableCell>}
    {fill && (
      <EmployeeCell userName={viewData.userName} application={application} rowSpan={rowSpan} />
    )}
    {fill && <TableCell rowSpan={rowSpan}>{viewData.eventUser}</TableCell>}
    {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT && (
      <TableCell>{agreement && `${agreement.title}: ${agreement.status}`}</TableCell>
    )}
    {fill && (
      <TableCell rowSpan={rowSpan} numeric className="nowrap">
        <DetailsLink user={user} item={application} />
      </TableCell>
    )}
  </TableRow>
);

const AcceptWithChangesContent = ({ classes }) => (
  <ContentContainer>
    {(items, user) => (
      <React.Fragment>
        <TableHead>
          <TableRow>
            <TableCell>№ заявления</TableCell>
            <TableCell>Взято в работу</TableCell>
            <TableCell>Организация / ФИО</TableCell>
            <TableCell>Сотрудник, взявший в работу</TableCell>
            {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT && (
              <TableCell>Согласование</TableCell>
            )}
            <TableCell numeric>Действие</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(application => {
            const { agreements = [], ...viewData } = getApplicationViewData(
              application,
              applicationConstants.STATUS_ACCEPT_WITH_CHANGES,
              user
            );
            const agreementsLength = agreements.length > 0 ? agreements.length : 1;
            return range(agreementsLength).map(i => (
              <Row
                fill={i === 0}
                rowSpan={agreementsLength}
                viewData={viewData}
                agreement={agreements[i]}
                user={user}
                application={application}
                classes={classes}
              />
            ));
          })}
        </TableBody>
      </React.Fragment>
    )}
  </ContentContainer>
);

export default withStyles(styles)(AcceptWithChangesContent);
