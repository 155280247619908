import React from 'react';
import { connect } from 'react-redux';
import { Button, TableRow, TableCell } from '@material-ui/core';
import { Field, Formik } from 'formik';
import Filter from '@material-ui/icons/FilterList';
import { routeAddressActions } from '../../../../actions/routeAddressActions';
import MaterialInput from '../../quickApplications/CreateQuickApplication/components/MaterialInput';

const RouteAddressFilterComponent = ({ filters, getPage }) => {
  return (
    <Formik
      initialValues={filters}
      onSubmit={value => getPage(1, value)}
      render={({ submitForm }) => (
        <TableRow>
          <TableCell>
            <Filter />
          </TableCell>
          <TableCell>
            <Field name="title" component={MaterialInput} placeholder="Адрес" />
          </TableCell>
          <TableCell />
          <TableCell numeric>
            <Button
              variant="contained"
              color="primary"
              className="btn-add no-margin"
              type="submit"
              onClick={submitForm}
            >
              Применить
            </Button>
          </TableCell>
        </TableRow>
      )}
    />
  );
};

const mapStateToProps = state => {
  const { routeAddresses } = state;

  return {
    filters: routeAddresses.filters
  };
};

const mapDispatchToProps = {
  getPage: routeAddressActions.getPage
};

const connectedRouteAddressFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteAddressFilterComponent);
export { connectedRouteAddressFilter as RouteAddressFilter };
