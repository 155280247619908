import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { roleConstants } from '../../constants/roleConstants';

export const PrivateRoute = ({ role, component: Component, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const renderRoute = props => {
    const userRole = user ? user.role_id : null;
    let hasPermission = user && user.role_id === role;
    if (
      (userRole === roleConstants.ROLE_FIRMUSER ||
        userRole === roleConstants.ROLE_INDIVIDUAL ||
        userRole === roleConstants.ROLE_FIRM) &&
      (role === roleConstants.ROLE_FIRMUSER ||
        role === roleConstants.ROLE_INDIVIDUAL ||
        role === roleConstants.ROLE_FIRM)
    ) {
      hasPermission = true;
    }
    if (
      (userRole === roleConstants.ROLE_OFFICER ||
        userRole === roleConstants.ROLE_DEPARTMENT_AGENT) &&
      (role === roleConstants.ROLE_OFFICER || role === roleConstants.ROLE_DEPARTMENT_AGENT)
    ) {
      hasPermission = true;
    }
    return hasPermission ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    );
  };
  return <Route {...rest} render={renderRoute} />;
};
