import * as yup from 'yup';

import { maxLength, minLengthText } from '../../../../helpers/fieldValidators';

export const DeclineSchema = yup.object().shape({
  note: yup
    .string()
    .required('Пожалуйста заполните причину отклонения')
    .min(12, minLengthText(12))
    .max(1000, maxLength(1000))
});

export const Schema = yup.object().shape({
  special_conditions: yup.string().max(2000, `Должно быть 2000 символов или меньше`),
  note: yup.string().when('result', {
    is: val => val === 2,
    then: yup
      .string()
      .required('Пожалуйста заполните причину отклонения')
      .min(12, minLengthText(12))
      .max(1000, maxLength(1000))
  })
});
