import React from 'react';

import {WeightControlRoute} from '../../../components/routes/WeightControlRoute';
import {ApvgkConnectPage} from "./ApvgkConnectPage";
import {Redirect} from "react-router-dom";
import {ApvgkConnectionsPage} from "./ApvgkConnectionsPage";

const WorkLayout = ({match, location}) => (
    <div>
        <WeightControlRoute exact path={`${match.url}`} component={() => <Redirect
            to={{pathname: `${match.url}/apvgk`, state: {from: location}}}/>}/>
        <WeightControlRoute exact path={`${match.url}/apvgk`} component={ApvgkConnectionsPage}/>
        <WeightControlRoute exact path={`${match.url}/apvgk/add`} component={ApvgkConnectPage}/>
    </div>
);

export default WorkLayout;
