import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { applicationActions } from '../../../actions/applicationActions';
import DeclineForm from './DeclineForm';

const updateSubmit = (values, dispatch) => {
  return dispatch(applicationActions.decline(values));
};

const DeclineModal = props => {
  const { handleDeclineClose, adminApplications } = props;

  return (
    <Dialog
      open={adminApplications.showDeclineModal}
      onClose={handleDeclineClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Отклонение заявки
        </Typography>
        <IconButton color="inherit" onClick={handleDeclineClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        {adminApplications && adminApplications.currentItem && (
          <DeclineForm currentItem={adminApplications.currentItem} onSubmit={updateSubmit} />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { adminApplications } = state;
  return {
    adminApplications
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleDeclineClose: () => {
      dispatch(applicationActions.closeDeclineModal());
    }
  };
};

const connectedDeclineModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeclineModal);
export { connectedDeclineModal as DeclineModal };
