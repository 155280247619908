export const apiConstants = {
  APP_VERSION: '1.6.0',

  API_URL: process.env.REACT_APP_API || '',

  METHOD_GET: 'get',
  METHOD_POST: 'post',
  METHOD_PUT: 'put',
  METHOD_DELETE: 'delete'
};
