import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const MuiCheckbox = ({ disabled, input, label, className, meta: { error, touched } }) => (
  <div>
    <FormControlLabel
      className={className}
      control={<Checkbox disabled={disabled} {...input} className={className} value="1" />}
      label={label}
    />
    <p className="text-danger text-center">{touched && (error && <span>{error}</span>)}</p>
  </div>
);
