import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { userActions } from '../../../actions/userActions';
import { Loader } from '../../../components/Loader';
import TablePagination from '../../../components/TablePagination';
import { roleConstants } from '../../../constants/roleConstants';

const UsersTableList = props => {
  const { data, meta, openUpdateModal, handleChangePage, handleDelete, users } = props;
  const { role } = users.filters;
  const showPosition = role === roleConstants.ROLE_ADMIN || role === roleConstants.ROLE_OFFICER;

  return (
    <div>
      <Paper className="overflow">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              {/*{showPosition && <TableCell>Должность</TableCell>}*/}
              <TableCell>ФИО</TableCell>
              <TableCell>Email</TableCell>
              <TableCell numeric>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(n => {
              return (
                <TableRow key={n.id}>
                  <TableCell>{n.id}</TableCell>
                  {/*{showPosition && <TableCell>{n.position}</TableCell>}*/}
                  <TableCell>{n.name}</TableCell>
                  <TableCell>{n.email}</TableCell>
                  <TableCell numeric>
                    <Button
                      variant="outlined"
                      className="info-btn"
                      size="small"
                      onClick={openUpdateModal(n)}
                    >
                      Редактировать
                    </Button>
                    <Button
                      variant="outlined"
                      className="decline-btn"
                      size="small"
                      onClick={handleDelete(n.id)}
                    >
                      {n.deleting ? <Loader /> : <span>Удалить</span>}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination meta={meta} onChangePage={handleChangePage} />
    </div>
  );
};

const mapStateToProps = state => {
  const { users } = state;

  return {
    users
  };
};

const mapDispatchToProps = dispatch => {
  const openUpdateModal = item => {
    return e => {
      dispatch(userActions.setCurrentItem(item));
      dispatch(userActions.openFormModal());
    };
  };

  const handleDelete = id => {
    return e => dispatch(userActions.delete(id));
  };

  return {
    openUpdateModal,
    handleDelete,
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { users } = stateProps;
  const { dispatch } = dispatchProps;

  const handleChangePage = page => {
    dispatch(userActions.getPage(page, users.filters));
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    handleChangePage
  };
};

const connectedUsersTableList = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UsersTableList);
export { connectedUsersTableList as UsersTableList };
