import React from 'react';
import { get } from 'lodash';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

const ApplicationVehicleInfo = props => {
  const { vehicle, isTrailer, index, handleRemove } = props;
  const status =
    get(vehicle, 'privilege.privilegeStatus.title') || get(vehicle, 'privilege_status_title');
  return (
    <Card>
      <CardContent>
        <div className="text-right close_user">
          <IconButton color="inherit">
            <CloseIcon onClick={() => handleRemove(isTrailer, index)} className="blue" />
          </IconButton>
        </div>
        <br />
        <br />
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Марка</TableCell>
              <TableCell>{vehicle.brandTitle}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Модель</TableCell>
              <TableCell>{vehicle.modelTitle}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ГРЗ ТС</TableCell>
              <TableCell>{vehicle.real_number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Статус</TableCell>
              <TableCell>{status || 'Без особых статусов'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = state => {
  const selector = formValueSelector('application-form');
  const { vehicle, trailers } = selector(state, 'vehicle', 'trailers');
  return {
    vehicle,
    trailers
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { trailers } = stateProps;
  const { dispatch } = dispatchProps;
  const handleRemove = (isTrailer, index) => {
    if (!isTrailer) {
      dispatch(change('application-form', 'vehicle', null));
    } else {
      const newTrailers = trailers.filter((item, i) => i !== index);
      dispatch(change('application-form', 'trailers', newTrailers));
    }
  };

  return {
    dispatch,
    handleRemove,
    ...ownProps
  };
};

const connectedApplicationVehicleInfo = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationVehicleInfo);
export { connectedApplicationVehicleInfo as ApplicationVehicleInfo };
