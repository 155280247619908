import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { urlHelper } from '../../../../helpers/urlHelper';

const DetailsLink = ({ user, item, isQuick }) => (
  <Link
    to={`${urlHelper.getHomeUrl(user.role_id)}/${isQuick ? 'quick_' : ''}applications/view/${
      item.id
    }`}
  >
    <Button variant="text" className="info-btn" color="primary">
      Подробнее
    </Button>
  </Link>
);

export default DetailsLink;
