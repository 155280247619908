import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { privilegeStatusActions } from '../../../../actions/privilegeStatusActions';
import { VehicleModelsTableList } from './StatusTableList';
import { Loader } from '../../../../components/Loader';
import { StatusModal } from './StatusModal';

class StatusBlock extends Component {
  componentDidMount() {
    const { privilegeStatuses } = this.props;

    if (!privilegeStatuses.items || privilegeStatuses.items.length === 0) {
      this.props.dispatch(privilegeStatusActions.getPage(1));
    }
  }

  render() {
    const { privilegeStatuses, openCreateModal } = this.props;

    return (
      <div>
        <main role="main" className="container text-left">
          <br />
          <p>
            Статусы ТС
            <Button aria-label="Добавить" className="btn-add" onClick={openCreateModal}>
              + Создать
            </Button>
          </p>

          <div className="">
            {privilegeStatuses.loading && <Loader />}
            {privilegeStatuses.error && (
              <span className="text-danger">Ошибка: {privilegeStatuses.error}</span>
            )}
            {privilegeStatuses.items && (
              <VehicleModelsTableList
                data={privilegeStatuses.items}
                meta={privilegeStatuses.meta}
              />
            )}
          </div>

          <StatusModal />
          <br />
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { privilegeStatuses } = state;
  return {
    privilegeStatuses
  };
};

const mapDispatchToProps = dispatch => {
  const openCreateModal = e => {
    dispatch(privilegeStatusActions.setCurrentItem(null));
    dispatch(privilegeStatusActions.openFormModal());
  };

  return {
    openCreateModal,
    dispatch
  };
};

const connectedStatusBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusBlock);
export { connectedStatusBlock as StatusBlock };
