import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { privilegeVehicleActions } from '../../../../../actions/privilegeVehicleActions';
import StatusVehiclesForm from './StatusVehiclesForm';

const StatusVehicleModal = props => {
  const { privilegeStatuses, privilegeVehicles, handleSubmit, handleModalClose } = props;

  return (
    <Dialog
      open={privilegeVehicles.showFormModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Добавление тс
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <StatusVehiclesForm
          currentStatus={privilegeStatuses.currentItem}
          currentItem={privilegeVehicles.currentItem}
          onSubmit={handleSubmit}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { privilegeStatuses, privilegeVehicles } = state;

  return {
    privilegeStatuses,
    privilegeVehicles
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { privilegeStatuses, privilegeVehicles } = stateProps;
  const { dispatch } = dispatchProps;

  const handleSubmit = values => dispatch(privilegeVehicleActions.create(values));

  const handleModalClose = () => dispatch(privilegeVehicleActions.closeFormModal());

  return {
    ...ownProps,

    privilegeStatuses,
    privilegeVehicles,

    handleSubmit,
    handleModalClose
  };
};

const connectedStatusVehicleModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(StatusVehicleModal);

export { connectedStatusVehicleModal as StatusVehicleModal };
