import React, { useEffect, useRef, useState } from 'react';
import { debounce, uniqueId } from 'lodash';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import { applicationService } from '../../../../../services/applicationService';

const loadLocation = search => {
  if (search.length < 3) {
    return Promise.resolve([]);
  }
  return applicationService.findLocations(search).then(data => data.features);
};

const loadAndSaveLocation = (search, setSuggestions) => {
  loadLocation(search).then(setSuggestions);
};

const debouncedLoad = debounce(loadAndSaveLocation, 500);

function renderSuggestion(suggestion, { isHighlighted }) {
  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      style={{ whiteSpace: 'normal', height: 'auto' }}
    >
      <span>{suggestion.text}</span>
    </MenuItem>
  );
}

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  divider: {
    height: theme.spacing.unit / 2
  }
});

const LocationAutosuggest = ({ classes, value, onChange, onBlur, onTouched }) => {
  const [suggestions, setSuggestion] = useState('');
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(value.text || '');
  }, [value.text]);
  const id = useRef(uniqueId());

  const onInputChange = (event, { method, newValue }) => {
    onTouched();
    if (method === 'type') {
      setInputValue(newValue);
    }
    if (method === 'click') {
      onChange(newValue);
    }
  };
  return (
    <Autosuggest
      key={`autosuggest-${id.current}`}
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value: query }) => debouncedLoad(query, setSuggestion)}
      onSuggestionsClearRequested={() => setSuggestion([])}
      getSuggestionValue={suggestion => suggestion}
      renderSuggestion={renderSuggestion}
      theme={{
        container: classes.container,
        suggestionsContainerOpen: classes.suggestionsContainerOpen,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion
      }}
      renderSuggestionsContainer={options => (
        <Paper {...options.containerProps} square>
          {options.children}
        </Paper>
      )}
      inputProps={{
        value: inputValue,
        onChange: onInputChange,
        className: 'form-control',
        placeholder: 'Введите адрес',
        onBlur: () => {
          if (onBlur) {
            onBlur(inputValue);
          }
          onTouched();
        }
      }}
    />
  );
};

export default withStyles(styles)(LocationAutosuggest);
