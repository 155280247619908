import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { vehicleModelActions } from '../../../../../actions/vehicleModelActions';
import VehicleModelsForm from './VehicleModelsForm';

const VehicleModelModal = props => {
  const { vehicleModels, handleSubmit, handleModalClose } = props;

  return (
    <Dialog
      open={vehicleModels.showFormModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          {vehicleModels.currentItem && vehicleModels.currentItem.id
            ? 'Редактирование модели'
            : 'Создание модели'}
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <VehicleModelsForm currentItem={vehicleModels.currentItem} onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { vehicleModels } = state;

  return {
    vehicleModels
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { vehicleModels } = stateProps;
  const { dispatch } = dispatchProps;

  const handleSubmit = (values, dispatch) => {
    if (vehicleModels.currentItem && vehicleModels.currentItem.id) {
      // set dropdown values
      return dispatch(vehicleModelActions.update(values));
    }
    return dispatch(vehicleModelActions.create(values));
  };

  const handleModalClose = () => {
    dispatch(vehicleModelActions.closeFormModal());
  };

  return {
    ...ownProps,
    vehicleModels,
    handleSubmit,
    handleModalClose
  };
};

const connectedVehicleModelModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VehicleModelModal);

export { connectedVehicleModelModal as VehicleModelModal };
