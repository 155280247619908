import React, { useState } from 'react';
import Select from 'react-select';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { Loader } from '../../../../components/Loader';
import AgreementsList from './AgreementsList';

const AgreementsForm = ({
  processing,
  agreements,
  departments: { data: departments = [] } = {},
  onSend,
  onRemove,
  changesDisabled = false
}) => {
  const [agreementDepartment, setAgreementDepartment] = useState(departments[0]);
  const disabled = !agreementDepartment || processing;
  if (changesDisabled && agreements.length === 0) {
    return null;
  }
  return (
    <>
      <Grid item>
        <h2 className="h3-title">Согласование с другими ведомствами</h2>
      </Grid>
      {!changesDisabled && (
        <Grid item>
          <Grid container spacing={8}>
            <Grid item xs={12} sm={8}>
              <Select
                name="department_id"
                placeholder="Ведомство для согласования"
                value={agreementDepartment}
                options={departments.map(({ id, title }) => ({ value: id, label: title }))}
                onChange={setAgreementDepartment}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                onClick={() => onSend(agreementDepartment.value)}
                variant="contained"
                className={`btn-add no-margin full-width ${disabled ? 'disabled-button' : ''}`}
                disabled={disabled}
              >
                {processing && <Loader size={24} />} Отправить на согласование
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <AgreementsList agreements={agreements} remove={onRemove} />
      </Grid>
    </>
  );
};

export default AgreementsForm;
