import React from 'react';
import { withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ContentContainer from './ContentContainer';
import Actions from '../components/Actions';
import { CooperationStatus } from '../../../../constants/cooperationConstants';
import { formatHelper } from '../../../../helpers/formatHelper';
import { getDateRange } from '../util/mapping';

const styles = {
  cellWrap: {
    whiteSpace: 'normal',
    workWrap: 'break-word'
  },
  flexRow: {
    display: 'flex'
  }
};

const ReturnedContent = ({ classes }) => (
  <ContentContainer
    col={8}
    status={CooperationStatus.Returned}
    renderHeader={() => (
      <TableRow>
        <TableCell>Номер запроса</TableCell>
        <TableCell className={classes.cellWrap}>Дата запроса</TableCell>
        <TableCell>Заявитель</TableCell>
        <TableCell className={classes.cellWrap}>Наименование организации владельца ТС</TableCell>
        <TableCell className={classes.cellWrap}>№ документа, присвоенный заявителем</TableCell>
        <TableCell align="right">Действие</TableCell>
      </TableRow>
    )}
    renderBody={({ items }) =>
      items.map(item => (
        <TableRow key={item.id} hover>
          <TableCell className="nowrap">{`№${formatHelper.appNumber(item)}`}</TableCell>
          <TableCell>{getDateRange(item.smev)}</TableCell>
          <TableCell>{item.smev.authority_name}</TableCell>
          <TableCell>{item.username}</TableCell>
          <TableCell>{item.smev.output_number}</TableCell>
          <TableCell align="right" className="nowrap">
            <Actions id={item.id} />
          </TableCell>
        </TableRow>
      ))
    }
  />
);

export default withStyles(styles)(ReturnedContent);
