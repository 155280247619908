import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { withSnackbar } from 'notistack';
import { get } from 'lodash';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import { apvgkReportsActions } from '../../../actions/apvgkReportsActions';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { Loader, TablePagination } from '../../../components';
import {styles} from "./ReportsTab";
import TabContainer from './TabContainer'
import {history} from "../../../helpers/history";
import {urlHelper} from "../../../helpers/urlHelper";
import {roleConstants} from "../../../constants/roleConstants";

let NewReportsTab = props => {
    const {
        authentication,
        apvgkReports,
        classes,
        handleToWork,
        handleCheckAll,
    } = props;
    const items = apvgkReports
    const { user } = authentication;
    const { handleSubmit, submitting, paginateProps} = props;
    return (
        <div>
            {items && items.loading && <Loader />}
            {items && items.error && (
                <span className="text-danger">Ошибка: {items.error}</span>
            )}
            {items && items.items && !items.loading && (
                <form onSubmit={handleSubmit}>
                    <Paper className={classes.root}>
                        {user.role_id == roleConstants.ROLE_APVGK && <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Field
                                            name="towork-all"
                                            type="checkbox"
                                            label="Отметить все"
                                            className="checkbox checkbox_table"
                                            component={MuiCheckbox}
                                            onChange={handleCheckAll}
                                        />
                                    </TableCell>
                                    <TableCell align={"right"}>
                                        <Button className="btn-add" type="submit" disabled={submitting}>
                                            {submitting && <Loader />} Взять в работу
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>}
                    </Paper>
                    <TabContainer paginateProps={paginateProps}>
                        {(items) => (
                            <React.Fragment>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>ID взвешивания</TableCell>
                                        <TableCell>АПВГК</TableCell>
                                        <TableCell>Отправлен в СМЭВ</TableCell>
                                        <TableCell align={"right"}><div  style={{paddingRight: "30px"}}>Действие</div></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items ? items.map(viewData => {
                                        return (
                                            <TableRow
                                                key={`application-${viewData.id}`}
                                            >
                                                <TableCell>
                                                    <Field
                                                        name={`towork.${viewData.id}`}
                                                        type="checkbox"
                                                        className="checkbox checkbox_table"
                                                        component={MuiCheckbox}
                                                    />
                                                </TableCell>
                                                <TableCell>{viewData.id}{viewData.bad_grz ? '(без ГРЗ)' :''}</TableCell>
                                                <TableCell>{viewData.apvgk ? viewData.apvgk.equipment_seria_number :''}</TableCell>
                                                <TableCell>{viewData.smev_result && viewData.smev_result.status === 1? 'Отправлен': ''}</TableCell>
                                                <TableCell align={"right"} className="nowrap">
                                                    {user.role_id == roleConstants.ROLE_APVGK && <Button className="info-btn" onClick={handleToWork(viewData.id)}>
                                                        Взять в работу
                                                    </Button>}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }) : null}
                                </TableBody>
                            </React.Fragment>
                        )}
                    </TabContainer>
                </form>
            )}
        </div>
    );
};

const mapStateToProps = state => {
    const { apvgkReports, authentication } = state;
    return {
        apvgkReports,
        authentication,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { apvgkReports, authentication} = stateProps;
    const { dispatch } = dispatchProps;

    const errorHandler = (error) =>{
        console.log(error)
        const message = get(error, 'response.data.error');
        ownProps.enqueueSnackbar(message || 'Произошла ошибка, попробуйте позже!', {
            variant: 'error'
        });
    }
    const handler = (response) =>{
        dispatch(apvgkReportsActions.getApvgkReports({page: 1, status: apvgkReports.tabStatus, ...apvgkReports.filter }))
    }
    const onSubmit = (values, dispatch) => {
        if(!values.towork) return;
        const towork = [];
        Object.entries(values.towork).forEach(([key, value]) => value && towork.push(key))
        return dispatch(apvgkReportsActions.toWorkMultiple(towork)).then(handler, errorHandler);
    };


    const handleToWork = id => () =>
        dispatch(apvgkReportsActions.toWorkMultiple([id])).then(() => {
            history.push(
                `${urlHelper.getHomeUrl(roleConstants.ROLE_APVGK)}/apvgk/${id}`
            );
        }, errorHandler);

    const handleCheckAll = e => {
        const isChecked = e.currentTarget.checked;
        const { apvgkReports } = stateProps;

        for (let i = 0; i < apvgkReports.items.length; i++) {
            dispatch(change('apvgk-reports-towork-form', `towork.${apvgkReports.items[i].id}`, isChecked));
        }
    };

    return {
        ...ownProps,
        apvgkReports,
        authentication,

        handleToWork,
        handleCheckAll,

        onSubmit,
    };
};

NewReportsTab = reduxForm({
    form: 'apvgk-reports-towork-form',
    enableReinitialize: true
})(NewReportsTab);

export default compose(
    withStyles(() => styles),
    withSnackbar,
    connect(
        mapStateToProps,
        null,
        mergeProps
    )
)(NewReportsTab);
