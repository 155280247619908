import React from 'react';
import { OfficerRoute } from '../../../components/routes/OfficerRoute';
import { VehiclesListPage } from '../../admin/vehicles/VehiclesListPage';
import VehicleViewPage from '../../admin/vehicles/VehicleViewPage';

const VehiclesLayout = ({ match }) => (
  <div>
    <OfficerRoute exact path={`${match.url}/`} component={VehiclesListPage} />
    <OfficerRoute exact path={`${match.url}/info/:id`} component={VehicleViewPage} />
  </div>
);

export default VehiclesLayout;
