import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { privilegeStatusActions } from '../../../../actions/privilegeStatusActions';
import StatusForm from './StatusForm';

const StatusModal = props => {
  const { privilegeStatuses, handleSubmit, handleModalClose } = props;

  return (
    <Dialog
      open={privilegeStatuses.showFormModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-left">
        <Typography variant="title" color="inherit" className="flex">
          {privilegeStatuses.currentItem && privilegeStatuses.currentItem.id
            ? 'Редактирование статуса ТС'
            : 'Создание статуса ТС'}
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-left">
        <StatusForm currentItem={privilegeStatuses.currentItem} onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { privilegeStatuses } = state;

  return {
    privilegeStatuses
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { privilegeStatuses } = stateProps;
  const { dispatch } = dispatchProps;

  const handleSubmit = (values, dispatch) => {
    if (privilegeStatuses.currentItem && privilegeStatuses.currentItem.id) {
      // set dropdown values
      return dispatch(privilegeStatusActions.update(values));
    }
    return dispatch(privilegeStatusActions.create(values));
  };

  const handleModalClose = () => {
    dispatch(privilegeStatusActions.closeFormModal());
  };

  return {
    ...ownProps,
    privilegeStatuses,
    handleSubmit,
    handleModalClose
  };
};

const connectedStatusModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(StatusModal);

export { connectedStatusModal as StatusModal };
