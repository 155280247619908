import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core';

import ContentContainer from './ContentContainer';
import DetailsLink from './DetailsLink';
import { getApplicationViewData } from './util';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { roleConstants } from '../../../../constants/roleConstants';
import EmployeeCell from '../EmployeeCell';

const styles = {
  linkCell: {
    padding: '0 10px',
    color: '#0056b3'
  }
};

const AcceptContent = ({ classes }) => (
  <ContentContainer>
    {(items, user) => (
      <React.Fragment>
        <TableHead>
          <TableRow>
            <TableCell>№ заявления</TableCell>
            <TableCell>Дата одобрения</TableCell>
            <TableCell>Организация / ФИО</TableCell>
            <TableCell>Сотрудник, одобривший заявку</TableCell>
            {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT && (
              <TableCell>Оплачено</TableCell>
            )}
            <TableCell numeric>Действие</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(application => {
            const viewData = getApplicationViewData(
              application,
              applicationConstants.STATUS_ACCEPT,
              user
            );
            return (
              <TableRow key={viewData.id}>
                <TableCell>{viewData.appNumber}</TableCell>
                <TableCell>{viewData.createdAt}</TableCell>
                <EmployeeCell userName={viewData.userName} application={application} />
                <TableCell>{viewData.eventUser}</TableCell>
                {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT && (
                  <TableCell
                    component="a"
                    className={classes.linkCell}
                    href={viewData.payFile}
                    target="_blank"
                  >
                    {viewData.payFile && 'Посмотреть'}
                  </TableCell>
                )}
                <TableCell numeric className="nowrap">
                  <DetailsLink user={user} item={application} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </React.Fragment>
    )}
  </ContentContainer>
);

export default withStyles(styles)(AcceptContent);
