import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { compose } from 'redux';
import { useAsync } from 'react-async';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';
import InfoContent from './content/ApplicationDetails/InfoContent';
import TransportSettings from './content/ApplicationDetails/TransportSettings';
import RequestProcessingInProgress from './content/ApplicationDetails/RequestProcessingInProgress';
import { CooperationStatus } from '../../../constants/cooperationConstants';
import { applicationService } from '../../../services/applicationService';
import { Loader } from '../../../components';
import { specialConditionService } from '../../../services/specialConditionService';
import { isContainSpring } from '../../admin/quickApplications/CreateQuickApplication/utils';

const styles = () => ({
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

const CooperationViewPage = ({ match, classes, user, enqueueSnackbar }) => {
  const { id } = match.params;
  const [isSpring, setSpring] = useState(false);

  const {
    data: specialConditions,
    isRejected: conditionsRejected,
    isLoading: conditionsLoading,
    isResolved: conditionsResolved
  } = useAsync({ promiseFn: specialConditionService.getItem, id: 1 });
  const {
    data: response,
    isRejected: dataRejected,
    isLoading: dataLoading,
    isResolved: dataResolved,
    reload: reloadData
  } = useAsync({
    promiseFn: applicationService.getApplication,
    id
  });

  useEffect(() => {
    if (response && specialConditions) {
      const { start_date, finish_date } = response.data;
      setSpring(
        isContainSpring(
          {
            start: moment(start_date, 'DD.MM.YYYY').toISOString(),
            end: moment(finish_date, 'DD.MM.YYYY').toISOString()
          },
          specialConditions.data
        )
      );
    }
  }, [response, specialConditions]);

  const toWork = useAsync({
    deferFn: ([a]) => applicationService.toWorkMultiple(a)
  });

  const onWork = values =>
    toWork.run({ values: { towork: values } }).then(({ data: application }) => {
      if (application) {
        reloadData();
      } else {
        enqueueSnackbar('Не удалось взять запрос в работу!', { variant: 'error' });
      }
    });

  const data = dataResolved
    ? {
        ...response.data,
        status: get(
          response.data.application_agreements.find(a => a.department_id === user.department_id),
          'status'
        )
      }
    : null;

  return (
    <main role="main" className="container">
      {dataResolved && conditionsResolved && (
        <Grid container direction="column" spacing={16}>
          <InfoContent application={data} />
          <TransportSettings application={data} isSpring={isSpring} />
          <RequestProcessingInProgress
            application={response.data}
            reloadData={reloadData}
            isSpring={isSpring}
            status={data.status}
          />
          {data.status === CooperationStatus.New && (
            <Grid item>
              <Button className="btn-add no-margin" onClick={() => onWork([id])}>
                Взять в работу
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {(dataLoading || conditionsLoading) && (
        <div className={classes.loader}>
          <Loader size={45} />
        </div>
      )}
    </main>
  );
};

const mapStateToProps = state => ({
  user: state.authentication.user
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withSnackbar
)(CooperationViewPage);
