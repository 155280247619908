import React from 'react';
import { connect } from 'react-redux';
import RouteContent from './RouteContent';
import ApplicationField from './ApplicationField';
import {
  getApplicationEditingMap,
  getApplicationMarkers,
  isApplicationRouteEditable
} from '../../../selectors/applicationSelectors';
import MapContainer from './MapContainer';
import ShowByStatusMap from './ShowByStatusMap';
import { roleConstants } from '../../../constants/roleConstants';
import { applicationConstants } from '../../../constants/applicationConstants';

function round(distance) {
  return Math.round(distance * 100) / 100;
}

const PRICE_STATUSES_FOR_FIRM = {
  allowedStatuses: [
    applicationConstants.STATUS_ACCEPT,
    applicationConstants.STATUS_ACCEPT_WITH_CHANGES,
    applicationConstants.STATUS_ACTIVE,
    applicationConstants.STATUS_COMPLETE
  ]
};

const ApplicationRoute = ({
  privilegeStatusId,
  route,
  routeMarkers,
  isRouteEditable,
  user,
  editMap
}) => {
  return (
    <div
      className="map row"
      style={{
        display: privilegeStatusId ? 'none' : 'flex'
      }}
    >
      <div className="col-md-12">
        <MapContainer markers={routeMarkers} editable={isRouteEditable} editMap={editMap} />
      </div>
      <div className="col-md-12">
        <h2 className="h2-title mt-4 margin-bottom-10">Маршрут</h2>
        <div className="points_area">
          <div className="points_div">
            <div className="points_line" />
          </div>
          {routeMarkers && (
            <RouteContent
              markers={routeMarkers}
              isRouteEditable={isRouteEditable && editMap}
              userRole={user.role_id}
            />
          )}
        </div>
      </div>
      <ShowByStatusMap
        mapToProps={{
          [roleConstants.ROLE_FIRM]: PRICE_STATUSES_FOR_FIRM,
          [roleConstants.ROLE_FIRMUSER]: PRICE_STATUSES_FOR_FIRM,
          [roleConstants.ROLE_INDIVIDUAL]: PRICE_STATUSES_FOR_FIRM
        }}
      >
        <div className="col-md-10">
          {route && route.distance_info ? (
            <div>
              <ApplicationField
                label="Общая протяженность маршрута"
                value={`${round(route.distance)} км`}
              />
              <ApplicationField
                label="Протяженность дорог с нормативной нагрузкой 11,5 т/ось"
                value={`${round(route.distance_info[1])} км`}
              />
              <ApplicationField
                label="Протяженность дорог с нормативной нагрузкой 10 т/ось"
                value={`${round(route.distance_info[0])} км`}
              />
              <ApplicationField
                label="Протяженность дорог с нормативной нагрузкой 6 т/ось"
                value={`${round(route.distance_info[2])} км`}
              />
            </div>
          ) : (
            <ApplicationField
              label="Движение по региональным дорогам"
              value={`${round(route ? route.distance : 0)} км`}
            />
          )}
        </div>
      </ShowByStatusMap>
    </div>
  );
};

const mapStateToProps = state => ({
  editMap: getApplicationEditingMap(state),
  user: state.authentication.user,
  routeMarkers: getApplicationMarkers(state),
  isRouteEditable: isApplicationRouteEditable(state)
});

export default connect(mapStateToProps)(ApplicationRoute);
