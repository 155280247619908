import React, {useEffect} from 'react';
import {Field} from 'formik';
import Grid from '@material-ui/core/Grid';
import Section from './components/Section';
import SelectBrand from './components/SelectBrand';
import SelectModel from './components/SelectModel';
import FieldLabel from './components/FieldLabel';
import MaterialInput from './components/MaterialInput';
import {createAxles, setTransportInfo} from './utils';
import TransportNumberInput from './components/TransportNumberInput';
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialCheckbox from "./components/MaterialCheckbox";

const RouteLength = ({lines, setFieldValue}) => {
    const [hand, setHand] = React.useState(false);
    const [byType, setByType] = React.useState({});
    useEffect(() => {
        let val = {0: 0, 1: 0, 2: 0};
        (lines || []).forEach((v) => {
            const type = v.commentType || v.type;
            val[type] += v.distance / 1000;
            setFieldValue('distance_info.' + type, val[type])
        });
        setByType(val);
    }, [lines]);
    return (
        <>
            <FieldLabel label={<Field name="is_spring" component={MaterialCheckbox} label="Весенее ограничение"/>}/>
            <Section title="Расчет расстояния маршрута">
                <FieldLabel
                    label={
                        <Field name="handDistance" component={MaterialCheckbox} label="Ручной ввод дистанций"
                               onChange={e => setHand(e.target.checked)}/>
                    }
                />
                {hand && <>
                    <Grid item>
                        <FieldLabel label="Протяженность дорог с нормативной нагрузкой 10 т/ось" name="distance_info.0">
                            <Field
                                name="distance_info.0"
                                component={MaterialInput}
                                fieldType="number"
                                min={0}
                            />
                        </FieldLabel>
                    </Grid>
                    <Grid item>
                        <FieldLabel label="Протяженность дорог с нормативной нагрузкой 11,5 т/ось" name="distance_info.1">
                            <Field
                                name="distance_info.1"
                                component={MaterialInput}
                                fieldType="number"
                                min={0}
                            />
                        </FieldLabel>
                    </Grid>
                    <Grid item>
                        <FieldLabel label="Протяженность дорог с нормативной нагрузкой 6 т/ось" name="distance_info.2">
                            <Field
                                name="distance_info.2"
                                component={MaterialInput}
                                fieldType="number"
                                min={0}
                            />
                        </FieldLabel>
                    </Grid>
                </>}
            </Section>
        </>
    )
};

export default RouteLength;
