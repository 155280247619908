import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import { UsersTableList } from './UsersTableList';
import { UserModal } from './UserModal';
import { Loader } from '../../../components/Loader';
import { userActions } from '../../../actions/userActions';
import { UsersFilter } from './UsersFilter';
import { departmentActions } from '../../../actions/departmentActions';
import { controlPostActions } from '../../../actions/controlPostActions';
import { roleConstants } from '../../../constants/roleConstants';

class UsersBlock extends Component {
  componentDidMount() {
    const { users, departments, controlPosts } = this.props;

    if (!users.items || users.items.length === 0) {
      this.props.dispatch(userActions.getPage(1, { role: roleConstants.ROLE_ADMIN }));
    }
    if (!departments.items || departments.items.length === 0) {
      this.props.dispatch(departmentActions.getAll());
    }
    if (!controlPosts.items || controlPosts.items.length === 0) {
      this.props.dispatch(controlPostActions.getAll());
    }
  }

  render() {
    const { users, openCreateModal, match } = this.props;

    return (
      <Grid
        container
        role="main"
        className="container text-left"
        direction="column"
        spacing={16}
        wrap="nowrap"
      >
        <Grid item>
          <Grid container spacing={16}>
            <Grid item>
              Сотрудники
              <Button aria-label="Добавить" className="btn-add" onClick={openCreateModal}>
                + Добавить
              </Button>
            </Grid>
            <Grid item>
              <UsersFilter />
            </Grid>
          </Grid>
        </Grid>

        <Grid item className="">
          {users.loading && <Loader />}
          {users.error && <span className="text-danger">Ошибка: {users.error}</span>}
          {users.items && <UsersTableList match={match} data={users.items} meta={users.meta} />}
        </Grid>

        <UserModal />
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  const { users, departments, controlPosts } = state;
  return {
    users,
    departments,
    controlPosts
  };
};

const mapDispatchToProps = dispatch => {
  const openCreateModal = e => {
    dispatch(userActions.setCurrentItem(null));
    dispatch(userActions.openFormModal());
  };

  return {
    openCreateModal,
    dispatch
  };
};

const connectedUsersBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersBlock);
export { connectedUsersBlock as UsersBlock };
