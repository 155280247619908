import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get, has, isArray, isEmpty, uniqueId } from 'lodash';
import {change, Field, formValueSelector, reduxForm} from 'redux-form';
import Button from '@material-ui/core/Button';

import { withSnackbar } from 'notistack';
import { applicationActions } from '../../../actions/applicationActions';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { FormField } from '../../../components/form/FormField';
import { FormFieldNoLabel } from '../../../components/form/FormFieldNoLabel';
import { renderSelectField } from '../../../components/form/renderSelectField';
import { renderSelectFieldNoLabel } from '../../../components/form/renderSelectFieldNoLabel';
import { momentHelper } from '../../../helpers/momentHelper';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { formatHelper } from '../../../helpers/formatHelper';
import { applicationConstants } from '../../../constants/applicationConstants';
import { DeclineModal } from './DeclineModal';
import { roleConstants } from '../../../constants/roleConstants';
import { statusHelper } from '../../../helpers/statusHelper';
import { AgreementDeclineModal } from './agreements/AgreementDeclineModal';
import AgreementsList from './AgreementsList';
import AgreementsFormWrapper from './AgreementsFormWrapper';
import { departmentActions } from '../../../actions/departmentActions';
import { FormTextarea } from '../../../components/form/FormTextarea';
import { ApplicationPrintModal } from './ApplicationPrintModal';
import { ControlMarksBlock } from './controlMarks/ControlMarksBlock';
import ApplicationSchema from '../../cabinet/applications/ApplicationSchema';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { applicationHelper } from '../../../helpers/applicationHelper';
import { ApplicationAcceptButton } from './buttons/ApplicationAcceptButton';
import { ApplicationToWorkButton } from './buttons/ApplicationToWorkButton';
import { ApplicationDeclineButton } from './buttons/ApplicationDeclineButton';
import { ApplicationActivateButton } from './buttons/ApplicationActivateButton';
import { AgreementAcceptButton } from './buttons/AgreementAcceptButton';
import { AgreementDeclineButton } from './buttons/AgreementDeclineButton';
import { ApplicationLockButton } from './buttons/ApplicationLockButton';
import { ApplicationUnlockButton } from './buttons/ApplictionUnlockButton';
import { ApplicationSaveButton } from './buttons/ApplicationSaveButton';
import { specialConditionActions } from '../../../actions/specialConditionActions';
import Truncate from '../../../components/Truncate';
import CopyLink from '../quickApplications/content/CopyLink';
import { urlHelper } from '../../../helpers/urlHelper';
import ApplicationRoute from './ApplicationRoute';
import CooperationApplicationInfo from '../../../components/CooperationApplicationInfo';
import ApplicationPrintDoc from './buttons/ApplicationPrintDoc';
import ApplicationCommentButton from './buttons/ApplicationCommentButton';

function roundPrice(price) {
  return Math.round(price * 100) / 100;
}

class ApplicationViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {}
    };
  }

  componentDidMount() {
    const { match, departments, specialConditions, dispatch, authentication } = this.props;

    const handleLoad = (dispatch, application) => {
      if (application.privilege_status_id) {
        return;
      }
      let markers = [];
      if (application && application.route) {
        markers = JSON.parse(application.route.points)
          .filter(a => a.coords && a.coords.lat)
          .map(({ text, coords: { lat, lon } }) => ({
            id: uniqueId('marker_'),
            lat,
            lon,
            text
          }));
      }

      this.props.dispatch(applicationActions.setRouteMarkers(markers));
    };

    this.props.dispatch(
      applicationActions.getFullItem(match.params.id, handleLoad, authentication.user)
    );

    if (!departments.items || departments.items.length === 0) {
      this.props.dispatch(departmentActions.getAll());
    }

    if (!specialConditions.currentItem) {
      dispatch(specialConditionActions.getItem(1));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.adminApplications.routeSaved && this.props.adminApplications.routeSaved) {
      this.props.enqueueSnackbar('Маршрут сохранен', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      });
    }
  }

  render() {
    const {
      regional_price,
      federal_price,
      comment,
      reverse,
      adminApplications,
      handleRemoveMarker,
      handlePrintModalOpen,
      handlePriceChange,
      authentication,
      departments,
      handleActivateModalClose,
      activateApplication,
      setMarkerValue,
      specialConditions
    } = this.props;

    const { user } = authentication;
    const { currentItem } = adminApplications;

    // load
    let escortVehicles = [];
    if (currentItem && currentItem.load && currentItem.load.escort) {
      escortVehicles = currentItem.load.escort;
    }

    let markers = [];
    if (currentItem && currentItem.route) {
      markers = JSON.parse(currentItem.route.points);
    } else {
      markers = [];
    }
    const isQuick = currentItem && currentItem.is_fast;
    const roleId = isQuick ? get(currentItem, 'fast.role_id') : get(currentItem, 'user.role_id');
    const { adminRoute } = adminApplications;
    const routeMarkers = adminRoute.markers;

    const { errors } = this.state;
    const hasFormErrors = routeMarkers.some(({ id }) => errors[id]);
    const setErrors = newErrors => this.setState({ errors: newErrors });
    const updateError = (id, value) => {
      const { [id]: error, ...other } = errors;
      if (!value && !error) setErrors({ ...other, [id]: 'Обязательное поле' });
      else if (value && errors) setErrors(other);
    };

    const isRouteEditable = () => {
      return (
        (currentItem.status === applicationConstants.STATUS_REVIEW ||
          currentItem.status === applicationConstants.STATUS_REPEAT) &&
        (user.role_id === roleConstants.ROLE_ADMIN || user.role_id === roleConstants.ROLE_OFFICER)
      );
    };

    const agreementAccepted = () => {
      if (!currentItem) {
        return false;
      }
      for (let i = 0; i < currentItem.application_agreements.length; i++) {
        if (currentItem.application_agreements[i].department_id === user.department_id) {
          return (
            currentItem.application_agreements[i].status === applicationConstants.AGREEMENT_ACCEPTED
          );
        }
      }
      return false;
    };

    const isSpringAvailable = () => {
      const specialDateItem = specialConditions.currentItem;
      if (!specialDateItem || !currentItem) {
        return false;
      }

      const startDateObj = momentHelper.getDateFromPicker(
        momentHelper.dotFormatToYmd(currentItem.start_date)
      );
      const finishDateObj = momentHelper.getDateFromPicker(
        momentHelper.dotFormatToYmd(currentItem.finish_date)
      );

      const springStartDate = momentHelper.getDateFromPicker(specialDateItem.start_date);
      const springFinishDate = momentHelper.getDateFromPicker(specialDateItem.finish_date);

      return !(startDateObj > springFinishDate || finishDateObj < springStartDate);
    };

    const isSpring = () => {
      return applicationHelper.checkSpring(currentItem, specialConditions.currentItem);
    };

    const springDisabled =
      currentItem &&
      !(
        currentItem.status === applicationConstants.STATUS_REVIEW ||
        currentItem.status === applicationConstants.STATUS_REPEAT
      );

    const adminOrOfficer =
      user.role_id === roleConstants.ROLE_ADMIN || user.role_id === roleConstants.ROLE_OFFICER;

    const hasLoadFiles =
      has(currentItem, 'files.load_files') && !isEmpty(currentItem.files.load_files);
    const axlesInfo =
      currentItem && currentItem.load ? JSON.parse(currentItem.load.axles_info) : null;
    const isLoadArray = axlesInfo ? isArray(axlesInfo[0].permissible_load) : false;

    const isNew =
      currentItem &&
      (currentItem.status === applicationConstants.STATUS_NEW ||
        currentItem.status === applicationConstants.STATUS_REVIEW ||
        currentItem.status === applicationConstants.STATUS_REPEAT);

    const showStandard = currentItem && (isNew || currentItem.is_spring === 0);
    const showSpring = currentItem && (isNew || currentItem.is_spring === 1);
    return (
      <main role="main" className="container application-container">
        {currentItem && (
          <div>
            <h2 className="h2-title mb-0">Заявление №{formatHelper.appNumber(currentItem)}</h2>
            <h2 className="application-header">
              {currentItem.status === applicationConstants.STATUS_ACTIVE &&
                adminOrOfficer && [
                  <Button className=" no-margin btn info-btn" onClick={handlePrintModalOpen}>
                    Печатать
                  </Button>,
                  <ApplicationPrintModal application={currentItem} />
                ]}
              {/* <span className="margin-left" /> */}
              <ApplicationToWorkButton
                cls="btn-add no-margin"
                application={currentItem}
                user={user}
              />
              <ApplicationSaveButton
                cls="btn-add no-margin"
                application={currentItem}
                user={user}
                haveOtherErrors={hasFormErrors}
                comment={comment}
              />
              <ApplicationAcceptButton
                cls="btn-add  no-margin"
                application={currentItem}
                user={user}
                haveOtherErrors={hasFormErrors}
                saveRoute
              />
              <ApplicationDeclineButton cls="decline-btn" application={currentItem} user={user} />
              <ApplicationCommentButton
                status={currentItem ? currentItem.status : null}
                comment={currentItem ? currentItem.comment : null}
              />
              <ApplicationActivateButton
                cls="btn-add no-margin"
                application={currentItem}
                user={user}
              />
              {/* Согласование ведомствами */}
              <AgreementAcceptButton
                cls="btn-add no-margin"
                application={currentItem}
                user={user}
              />
              <AgreementDeclineButton cls="decline-btn" application={currentItem} user={user} />
              <ApplicationLockButton cls="decline-btn" application={currentItem} user={user} />
              <ApplicationUnlockButton
                cls="btn-add no-margin"
                application={currentItem}
                user={user}
              />
              <ApplicationPrintDoc application={currentItem} />
              {currentItem && currentItem.is_fast === 1 && adminOrOfficer && (
                <CopyLink item={currentItem} user={user} />
              )}
            </h2>
            <div className="">
              {currentItem.status === applicationConstants.STATUS_ACTIVE && adminOrOfficer && (
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      className="form-control"
                      name="app_form_id"
                      label="Номер бланка"
                      placeholder=""
                      disabled="disabled"
                      component={FormField}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="">
              <div className="blue_frame">
                <p>
                  <strong>Статус:</strong> {statusHelper.getStatusText(user.role_id, currentItem)}{' '}
                </p>
                {currentItem.status === applicationConstants.STATUS_DECLINE &&
                  user.role_id === roleConstants.ROLE_ADMIN && [
                    <div key="reason">
                      <strong>Причина отклонения: </strong>
                      {currentItem.comment}
                    </div>,
                    <br />
                  ]}
                {adminOrOfficer &&
                  (currentItem.status === applicationConstants.STATUS_REVIEW ||
                    currentItem.status === applicationConstants.STATUS_REPEAT) &&
                  applicationHelper.needWeightAgreement(currentItem) &&
                  !applicationHelper.overweightAgreed(currentItem) && (
                    <span className="danger">Необходимо согласовать разрешение с ГДС</span>
                  )}
                {adminOrOfficer &&
                  (currentItem.status === applicationConstants.STATUS_REVIEW ||
                    currentItem.status === applicationConstants.STATUS_REPEAT) &&
                  applicationHelper.needSizeAgreement(currentItem) &&
                  !applicationHelper.oversizeAgreed(currentItem) && (
                    <p>
                      <span key="1" className="danger">
                        Необходимо согласовать разрешение с ГИБДД
                      </span>
                    </p>
                  )}
                {/* springWarning */}
                {applicationHelper.checkBeforeSpring(
                  currentItem,
                  specialConditions.currentItem
                ) && (
                  <p>
                    <span key="2" className="danger">
                      Данное разрешение не действует в период весеннего ограничения при условии
                      превышения допустимых нагрузок в размере 6 тонн/ось
                    </span>
                  </p>
                )}
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Вид перевозки</p>
                </div>
                <div className="col-md-6">
                  <Field
                    className="form-control"
                    name="load_type_id"
                    label="Вид перевозки"
                    disabled="disabled"
                    component={renderSelectFieldNoLabel}
                    validate={[fieldValidators.required]}
                  >
                    {currentItem && (currentItem.route || currentItem.privilege_status_id) ? (
                      <option>
                        {dictionaryHelper.routeTypes.getItemTitle(
                          currentItem.privilege_status_id ? 2 : currentItem.route.type_id
                        )}
                      </option>
                    ) : (
                      ''
                    )}
                  </Field>
                </div>
                <div className="col-md-1">
                  <p>Год</p>
                </div>
                <div className="col-md-3">
                  <Field
                    className="form-control"
                    name="year"
                    label="Год"
                    placeholder="Год"
                    disabled="disabled"
                    component={FormFieldNoLabel}
                  />
                </div>

                {/* 2 */}

                <div className="col-md-2">
                  <p>Количество поездок. Осталось / всего</p>
                </div>
                <div className="col-md-2">
                  <Field
                    className="form-control"
                    name="runs_count"
                    label="Количество поездок. Потрачено / всего"
                    placeholder="10"
                    disabled="disabled"
                    component={FormFieldNoLabel}
                  />
                </div>
                <div className="col-md-1">
                  <p>Период&nbsp;с</p>
                </div>
                <div className="col-md-3">
                  <Field
                    className="form-control"
                    name="start_date"
                    type="date"
                    label="Период поездок с"
                    disabled="disabled"
                    component={FormFieldNoLabel}
                  />
                </div>
                <div className="col-md-1">
                  <p>по</p>
                </div>
                <div className="col-md-3">
                  <Field
                    className="form-control"
                    name="finish_date"
                    type="date"
                    label="по"
                    disabled="disabled"
                    component={FormFieldNoLabel}
                  />
                </div>
              </div>
              {!currentItem.is_smev && <ApplicationRoute
                routeMarkers={routeMarkers}
                application={currentItem}
                errors={errors}
                handleRemoveMarker={handleRemoveMarker}
                isRouteEditable={isRouteEditable()}
                setMarkerValue={setMarkerValue}
                user={user}
                updateError={updateError}
              />}
              {(currentItem.is_smev || currentItem.privilege_status_id) && (
                <div className="map row">
                  <div className="col-md-12">
                    <p>
                      <strong>Информация о маршруте</strong>
                    </p>
                    <div className="privilege_status">
                      <Truncate maxCount={500}>
                        {currentItem.privilegeStatus ? currentItem.privilegeStatus.route_info
                          : currentItem.route && currentItem.route.text_route}
                      </Truncate>
                    </div>
                  </div>
                </div>
              )}

              <br />
              <div className="row">
                <div className="col-md-10">
                  {adminApplications.adminRoute.routeChanged && [
                    <Field
                      disabled={!adminApplications.editMap}
                      className="form-control"
                      name="comment"
                      label="Комментарий по маршруту"
                      placeholder=""
                      component={FormTextarea}
                      validate={[fieldValidators.required]}
                      row="4"
                    />
                  ]}
                </div>
              </div>
              <div className="row">
                <div className="col-md-10">
                  <Field
                    className="form-control"
                    name="vehicle_info"
                    label="Транспортное средство"
                    placeholder="(Марка, модель, ГРЗ)"
                    disabled="disabled"
                    component={FormField}
                    link={`${urlHelper.getHomeUrl(user.role_id)}/vehicles/info/${get(
                      currentItem,
                      'vehicle_id'
                    )}`}
                  />
                  {currentItem.trailers &&
                    currentItem.trailers.map((trailer, index) => (
                      <Field
                        className="form-control"
                        key={`trailer-block-${index}`}
                        name={`trailers_info[${index}]`}
                        label="Прицеп"
                        placeholder="(Марка, модель, ГРЗ)"
                        disabled="disabled"
                        component={FormField}
                        link={`${urlHelper.getHomeUrl(user.role_id)}/vehicles/info/${get(
                          trailer,
                          'id'
                        )}`}
                      />
                    ))}

                  {!currentItem.is_smev && <Field
                    className="form-control"
                    name="load_info"
                    label="Характеристики груза"
                    placeholder="(Наименование, габариты, масса)"
                    disabled="disabled"
                    component={FormField}
                  />}
                  {currentItem.is_smev ? <Field
                      className="form-control"
                      placeholder={currentItem.username}
                      input={{value:currentItem.username}}
                      label="Наименование организации владельца ТС и адрес"
                      disabled="disabled"
                      component={FormField}
                    />
                    : <Field
                    className="form-control"
                    name="user_name"
                    label={
                      roleId === roleConstants.ROLE_INDIVIDUAL
                        ? 'ФИО заявителя (физ лицо)'
                        : 'Наименование организации владельца транспортного средства'
                    }
                    placeholder="АО ТАТАВТОДОР АПАСТОВСКИЙ Ф-Л"
                    disabled="disabled"
                    component={FormField}
                  />}
                  {(roleId === roleConstants.ROLE_FIRM || isQuick) && (
                    <Field
                      className="form-control"
                      name="user_address"
                      label="Адрес владельца транспортного средства"
                      placeholder=""
                      disabled="disabled"
                      component={FormField}
                    />
                  ) || ''}
                  {!isQuick && !currentItem.is_smev && (
                    <Field
                      className="form-control"
                      name="user_phone"
                      label="Телефон владельца транспортного средства"
                      placeholder="+79172637435"
                      disabled="disabled"
                      component={FormField}
                    />
                  ) ||''}

                  <p>
                    <strong>Параметры ТС (автопоезда)</strong>
                  </p>

                  {!hasLoadFiles && (
                    <div className="car-character-block nowrap overflow">
                      <ApplicationSchema currentItem={currentItem} />
                    </div>
                  )}

                  <br />

                  {hasLoadFiles && (
                    <div className="row">
                      <div className="col-md-4">Схема расположения груза</div>
                      <div className="col-md-8">
                        {get(currentItem, 'files.load_files', []).map(({ id, name, url }) => (
                          <div key={id}>
                            <a target="_blank" href={url}>
                              {name}
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <br />

                  <Field
                    className="form-control"
                    name="weight"
                    label="Общая масса ТС"
                    placeholder=""
                    disabled="disabled"
                    component={FormField}
                  />
                  <Field
                    className="form-control"
                    name="axles_lengths"
                    label="Расстояние между осями"
                    placeholder=""
                    disabled="disabled"
                    component={FormField}
                  />
                  <Field
                    className="form-control"
                    name="axles_load"
                    label="Нагрузка на оси"
                    placeholder=""
                    disabled="disabled"
                    component={FormField}
                  />
                  {isLoadArray ? (
                    <React.Fragment>
                      {showStandard && (
                        <>
                          <Field
                            className="form-control"
                            name="permissible_load0"
                            label="Допустимая нагрузка на оси (10 т/ось)"
                            placeholder=""
                            disabled="disabled"
                            component={FormField}
                          />
                          <Field
                            className="form-control"
                            name="permissible_load1"
                            label="Допустимая нагрузка на оси (11,5 т/ось)"
                            placeholder=""
                            disabled="disabled"
                            component={FormField}
                          />
                          <Field
                            className="form-control"
                            name="permissible_load2"
                            label="Допустимая нагрузка на оси (6 т/ось)"
                            placeholder=""
                            disabled="disabled"
                            component={FormField}
                          />
                        </>
                      )}
                      {isSpring() && showSpring && (
                        <React.Fragment>
                          <Field
                            className="form-control"
                            name="permissible_load_spring0"
                            label="Допустимая нагрузка на оси (Весна 10 т/ось)"
                            placeholder=""
                            disabled="disabled"
                            component={FormField}
                          />
                          <Field
                            className="form-control"
                            name="permissible_load_spring1"
                            label="Допустимая нагрузка на оси (Весна 11,5 т/ось)"
                            placeholder=""
                            disabled="disabled"
                            component={FormField}
                          />
                          <Field
                            className="form-control"
                            name="permissible_load_spring2"
                            label="Допустимая нагрузка на оси (Весна 6 т/ось)"
                            placeholder=""
                            disabled="disabled"
                            component={FormField}
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {showStandard && (
                        <Field
                          className="form-control"
                          name="permissible_load"
                          label="Допустимая нагрузка на оси"
                          placeholder=""
                          disabled="disabled"
                          component={FormField}
                        />
                      )}
                      {isSpring() && showSpring && (
                        <Field
                          className="form-control"
                          name="permissible_load_spring"
                          label="Допустимая нагрузка на оси (Весна)"
                          placeholder=""
                          disabled="disabled"
                          component={FormField}
                        />
                      )}
                    </React.Fragment>
                  )}
                  <Field
                    className="form-control"
                    name="wheelsCount"
                    label="Скатность"
                    placeholder=""
                    disabled="disabled"
                    component={FormField}
                  />
                  <Field
                    className="form-control"
                    name="wheels"
                    label="Количество колес"
                    placeholder=""
                    disabled="disabled"
                    component={FormField}
                  />
                  <div className="row">
                    <div className="col-md-3">
                      <Field
                        className="form-control"
                        name="length"
                        label="Длина"
                        placeholder=""
                        disabled="disabled"
                        component={FormField}
                      />
                    </div>
                    <div className="col-md-3">
                      <Field
                        className="form-control"
                        name="width"
                        label="Ширина"
                        placeholder=""
                        disabled="disabled"
                        component={FormField}
                      />
                    </div>
                    <div className="col-md-3">
                      <Field
                        className="form-control"
                        name="height"
                        label="Высота"
                        placeholder=""
                        disabled="disabled"
                        component={FormField}
                      />
                    </div>
                  </div>
                  <Field
                    className="form-control"
                    name=""
                    label="Вид сопровождения"
                    disabled="disabled"
                    component={renderSelectField}
                  >
                    <option>
                      {dictionaryHelper.escorts.getItemTitle(
                        currentItem.load ? currentItem.load.escort_count : 0
                      )}
                    </option>
                  </Field>

                  {escortVehicles.length > 0 &&
                    escortVehicles.map((escortVehicle, index) => (
                      <div key={index} className="row text-right">
                        <div className="col-md-2 text-left">Автомобиль прикрытия {index + 1}</div>
                        <div className="col-md-3">
                          <Field
                            name={`escort[${index}][brand]`}
                            label="Марка"
                            placeholder=""
                            component={FormField}
                            disabled="disabled"
                          />
                        </div>
                        <div className="col-md-3">
                          <Field
                            name={`escort[${index}][model]`}
                            label="Модель"
                            placeholder=""
                            component={FormField}
                            disabled="disabled"
                          />
                        </div>
                        <div className="col-md-3">
                          <Field
                            name={`escort[${index}][number]`}
                            label="ГРЗ"
                            placeholder=""
                            component={FormField}
                            disabled="disabled"
                          />
                        </div>
                      </div>
                    ))}
                  <CooperationApplicationInfo
                    smev={currentItem ? currentItem.smev : null}
                    is_smev={currentItem ? currentItem.is_smev : false}
                  />
                  <p>
                    <strong>Особые условия движения</strong>
                  </p>
                  {currentItem && currentItem.privilegeStatus && (
                    <div>
                      <Field
                        className="form-control"
                        name="privilege_title"
                        label="Название льготы"
                        placeholder="Льготник на ЧМ"
                        disabled="disabled"
                        component={FormField}
                      />
                      <div className="row">
                        <div className="col-md-5">
                          <Field
                            className="form-control"
                            name="privilege_start_date"
                            label="Период действия с"
                            type="date"
                            disabled="disabled"
                            component={FormField}
                          />
                        </div>
                        <div className="col-md-5">
                          <Field
                            className="form-control"
                            name="privilege_finish_date"
                            label="по"
                            type="date"
                            disabled="disabled"
                            component={FormField}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {currentItem && !currentItem.privilegeStatus && (
                    <Field
                      className="form-control"
                      name="status"
                      label="Статус"
                      placeholder=""
                      value="Без особого статуса"
                      disabled="disabled"
                      component={FormField}
                    />
                  )}
                  {currentItem && get(currentItem, 'dates.is_penalty') && (
                    <React.Fragment>
                      <br />
                      <p>
                        <strong>Штрафник</strong>
                      </p>
                      <Field
                        className="form-control"
                        name="penalty_post"
                        label="№ ПВК"
                        disabled="disabled"
                        component={FormField}
                      />
                      <Field
                        className="form-control"
                        name="penalty_number"
                        label="№ акта"
                        disabled="disabled"
                        component={FormField}
                      />
                      <Field
                        className="form-control"
                        name="penalty_place"
                        label="Штрафстоянка"
                        disabled="disabled"
                        component={FormField}
                      />
                      <div id="file-list">
                        {get(currentItem, 'files.penalty_files', []).map(file => (
                          <div className="form-group" key={file.id}>
                            <div className="row">
                              <div className="col-md-4">
                                <p className="label-form">Акт</p>
                              </div>
                              <div className="col-md-8">
                                <a target="_blank" href={file.url}>
                                  {file.name}
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  )}
                  <br />

                  <p>
                    <strong>Пункт выдачи</strong>
                  </p>
                  <Field
                    className="form-control"
                    name=""
                    label="Территориальное управление ГБУ БДД"
                    disabled="disabled"
                    component={renderSelectField}
                  >
                    <option>
                      {currentItem.dates
                        ? dictionaryHelper.issuePlaces.getItemTitle(
                            currentItem.dates.issue_place_id
                          )
                        : 'Не выбран'}
                    </option>
                  </Field>

                  {user.role_id !== roleConstants.ROLE_WEIGHT_CONTROL && (
                    <>
                      {currentItem.price_info ? (
                        <>
                          <p>
                            <strong>Стоимость разрешения</strong>
                          </p>
                          {showStandard && (
                            <>
                              <FormField
                                className="form-control"
                                disabled="disabled"
                                label="Стоимость движения по дорогам с нормативной нагрузкой 10 т/ось"
                                input={{
                                  value: roundPrice(get(currentItem, 'price_info.price[0]', 0))
                                }}
                                meta={{}}
                              />
                              <FormField
                                className="form-control"
                                disabled="disabled"
                                label="Стоимость движения по дорогам с нормативной нагрузкой 11,5 т/ось"
                                input={{
                                  value: roundPrice(get(currentItem, 'price_info.price[1]', 0))
                                }}
                                meta={{}}
                              />
                              <FormField
                                className="form-control"
                                disabled="disabled"
                                label="Стоимость движения по дорогам с нормативной нагрузкой 6 т/ось"
                                input={{
                                  value: roundPrice(get(currentItem, 'price_info.price[2]', 0))
                                }}
                                meta={{}}
                              />
                            </>
                          )}
                          {isSpring() && showSpring && (
                            <>
                              {currentItem.price_info && currentItem.price_info.spring_price && (
                                <>
                                  <FormField
                                    className="form-control"
                                    disabled="disabled"
                                    label="Стоимость движения по дорогам с нормативной нагрузкой 10 т/ось (весна)"
                                    input={{
                                      value: roundPrice(currentItem.price_info.spring_price[0])
                                    }}
                                    meta={{}}
                                  />
                                  <FormField
                                    className="form-control"
                                    disabled="disabled"
                                    label="Стоимость движения по дорогам с нормативной нагрузкой 11,5 т/ось (весна)"
                                    input={{
                                      value: roundPrice(currentItem.price_info.spring_price[1])
                                    }}
                                    meta={{}}
                                  />
                                  <FormField
                                    className="form-control"
                                    disabled="disabled"
                                    label="Стоимость движения по дорогам с нормативной нагрузкой 6 т/ось (весна)"
                                    input={{
                                      value: roundPrice(currentItem.price_info.spring_price[2])
                                    }}
                                    meta={{}}
                                  />
                                </>
                              )}
                            </>
                          )}
                          <FormField
                            className="form-control"
                            disabled="disabled"
                            label="Стоимость госпошлниы"
                            input={{ value: roundPrice(currentItem.price_info.duty) }}
                            meta={{}}
                          />
                          <FormField
                            className="form-control"
                            disabled="disabled"
                            label="Стоимость бланка"
                            input={{ value: roundPrice(currentItem.price_info.form) }}
                            meta={{}}
                          />
                        </>
                      ) : (
                        showStandard && (
                          <Field
                            className="form-control"
                            name="price"
                            label="Стоимость разрешения"
                            disabled="disabled"
                            component={FormField}
                            validate={[fieldValidators.required]}
                          />
                        )
                      )}
                      <p>
                        <strong>Итого:</strong>
                      </p>
                      {showStandard && (
                        <Field
                          className="form-control"
                          name="price"
                          label="Стоимость разрешения"
                          disabled="disabled"
                          component={FormField}
                          validate={[fieldValidators.required]}
                        />
                      )}
                      {isSpring() && showSpring && (
                        <>
                          <Field
                            className="form-control"
                            name="spring_price"
                            label="Стоимость разрешения (весна)"
                            disabled="disabled"
                            component={FormField}
                          />
                        </>
                      )}
                    </>
                  )}
                  {(currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
                    currentItem.status === applicationConstants.STATUS_ACCEPT ||
                    currentItem.status === applicationConstants.STATUS_ACTIVE) &&
                    currentItem.admin && (
                      <div>
                        <p>
                          <strong>Разрешение выдано</strong>
                        </p>
                        <Field
                          className="form-control"
                          name="admin_name"
                          label="Сотрудник, одобривший разрешение"
                          disabled="disabled"
                          component={FormField}
                        />

                        <Field
                          className="form-control"
                          name="accept_date"
                          label="Дата одобрения"
                          disabled="disabled"
                          component={FormField}
                        />
                      </div>
                    )}
                  {/* TODO: left here */}

                  {/* Declined */}
                  {currentItem.status === applicationConstants.STATUS_DECLINE &&
                    user.role_id === roleConstants.ROLE_ADMIN && [
                      <p>
                        <strong>Дополнительная информация</strong>
                      </p>,
                      <div key="fio">
                        <Field
                          className="form-control"
                          name="admin_name"
                          label="Сотрудник, отклонивший заявку"
                          disabled="disabled"
                          component={FormField}
                        />
                      </div>
                    ]}

                  <AgreementsList application={currentItem} />
                  <br />

                  {adminOrOfficer &&
                    (currentItem.status === applicationConstants.STATUS_REVIEW ||
                      currentItem.status === applicationConstants.STATUS_REPEAT) && (
                      <AgreementsFormWrapper
                        formName={`application-agreements-form-${currentItem.id}`}
                        application={currentItem}
                        departments={departments ? departments.items : []}
                      />
                    )}

                  <ControlMarksBlock application={currentItem} user={user} reverse={reverse} />

                  {user.role_id !== roleConstants.ROLE_WEIGHT_CONTROL &&
                    currentItem &&
                    currentItem.permit &&
                    (currentItem.status === applicationConstants.STATUS_ACCEPT ||
                      currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
                      currentItem.status === applicationConstants.STATUS_ACTIVE) && (
                      <div>
                        <p>
                          <strong>Оплата разрешения</strong>
                        </p>
                        <p>
                          <a
                            target="_blank"
                            href={
                              currentItem && currentItem.permit && currentItem.permit.invoice_link
                            }
                          >
                            Счет на оплату
                          </a>
                          <hr />
                        </p>
                      </div>
                    )}

                  {/* Action buttons */}
                  <div className="application-action-block">
                    {/* Список файлов */}
                    {(currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
                      currentItem.status === applicationConstants.STATUS_ACCEPT ||
                      currentItem.status === applicationConstants.STATUS_ACTIVE) &&
                      user.role_id !== roleConstants.ROLE_WEIGHT_CONTROL &&
                      has(currentItem, 'files.pay_files') &&
                      !isEmpty(currentItem.files.pay_files) && (
                        <div id="file-list">
                          {get(currentItem, 'files.pay_files', []).map(({ id, name, url }) => (
                            <div key={id}>
                              <a target="_blank" href={url}>
                                {name}
                              </a>
                              <br />
                              <br />
                            </div>
                          ))}
                        </div>
                      )}
                    {hasFormErrors && (
                      <p>
                        <span className="danger">Неверно заполнены поля формы!</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <DeclineModal />
        <AgreementDeclineModal />
        <ConfirmationModal
          title="Подтверждение активации"
          description="Вы уверены, что данное разрешение оплачено Заявителем?"
          handleSubmit={activateApplication}
          isShow={adminApplications.showActivateModal}
          handleClose={handleActivateModalClose}
        />
      </main>
    );
  }
}

function setPermissibleLoads(axlesInfo, initialValues) {
  let permissible_load = '';
  let permissible_load_spring = '';
  let permissible_load0 = '';
  let permissible_load1 = '';
  let permissible_load2 = '';

  let permissible_load_spring0 = '';
  let permissible_load_spring1 = '';
  let permissible_load_spring2 = '';
  const isLoadArray = isArray(axlesInfo[0].permissible_load);
  const trim = load => load.substring(0, load.length - 3);
  axlesInfo.forEach(axleInfo => {
    if (isLoadArray) {
      permissible_load0 += `${axleInfo.permissible_load[0]}т x `;
      permissible_load1 += `${axleInfo.permissible_load[1]}т x `;
      permissible_load2 += `${axleInfo.permissible_load[2]}т x `;
      if (axleInfo.permissible_load_spring) {
        permissible_load_spring0 += `${axleInfo.permissible_load_spring[0]}т x `;
        permissible_load_spring1 += `${axleInfo.permissible_load_spring[1]}т x `;
        permissible_load_spring2 += `${axleInfo.permissible_load_spring[2]}т x `;
      }
    } else {
      permissible_load += `${axleInfo.permissible_load}т x `;
      if (axleInfo.permissible_load_spring) {
        permissible_load_spring += `${axleInfo.permissible_load_spring}т x `;
      }
    }
  });
  if (isLoadArray) {
    initialValues.permissible_load0 = trim(permissible_load0);
    initialValues.permissible_load1 = trim(permissible_load1);
    initialValues.permissible_load2 = trim(permissible_load2);
    if (permissible_load_spring0) {
      initialValues.permissible_load_spring0 = trim(permissible_load_spring0);
      initialValues.permissible_load_spring1 = trim(permissible_load_spring1);
      initialValues.permissible_load_spring2 = trim(permissible_load_spring2);
    }
  } else {
    initialValues.permissible_load = trim(permissible_load);
    if (permissible_load_spring) {
      initialValues.permissible_load_spring = trim(permissible_load_spring);
    }
  }
}

const mapStateToProps = state => {
  const { adminApplications, authentication, departments, specialConditions } = state;
  const { currentItem, adminRoute } = adminApplications;
  const isQuick = currentItem && currentItem.is_fast;

  let loadName = '';
  if (currentItem && currentItem.load) {
    loadName =
      currentItem.load.name && currentItem.load.name != null ? `${currentItem.load.name}, ` : '';
    loadName += dictionaryHelper.loadTypes.getItemTitle(currentItem.load.type_id);
    if (currentItem.load.load_weight && currentItem.load.load_weight != null) {
      loadName += `, ${currentItem.load.load_weight}т`;
    }
  }

  const totalPrice = currentItem
    ? (parseFloat(currentItem.federal_price) + parseFloat(currentItem.regional_price)).toFixed(2)
    : 0;

  const employee = get(currentItem, 'employee.name');
  const initialValues = {
    id: currentItem ? currentItem.id : 0,
    vehicle_id: get(currentItem, 'vehicle_id') || get(currentItem, 'vehicle.id'),
    vehicle_info:
      currentItem && currentItem.vehicle
        ? `${currentItem.vehicle.brandTitle}, ${currentItem.vehicle.modelTitle}, ${
            currentItem.vehicle.real_number
          }`
        : '',
    apvgk_list: currentItem && currentItem.route ? (currentItem.route.apvgks ||[]).map(a => {
      return {
        id: a.id,
        direction: a.pivot ? a.pivot.direction: undefined
      }
    }) : [],

    year: currentItem ? momentHelper.getYearFromDotFormat(currentItem.finish_date) : '',
    runs_count:
      currentItem && currentItem.dates
        ? `${currentItem.dates.runs_count - currentItem.dates.runs_used}/${
            currentItem.dates.runs_count
          }`
        : '',
    start_date: currentItem
      ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.start_date)
      : '',
    finish_date: currentItem
      ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.finish_date)
      : '',

    load_info: currentItem && currentItem.load ? loadName : '',

    user_name: isQuick
      ? get(currentItem, 'fast.name')
      : `${get(currentItem, 'user.name', '')}${employee ? ` (${employee})` : ''}`,
    user_address: isQuick
      ? get(currentItem, 'fast.address')
      : get(currentItem, 'user.firm.address', ''),
    user_phone: isQuick ? null : get(currentItem, 'user.phone'),

    //
    weight: currentItem && currentItem.load ? `${currentItem.load.weight} т` : '',

    length: currentItem && currentItem.load ? `${currentItem.load.length} м` : '',
    width: currentItem && currentItem.load ? `${currentItem.load.width} м` : '',
    height: currentItem && currentItem.load ? `${currentItem.load.height} м` : '',

    trailers_info: [],

    // privilege
    privilege_title:
      currentItem && currentItem.privilegeStatus ? currentItem.privilegeStatus.title : '',
    privilege_start_date:
      currentItem && currentItem.privilegeStatus
        ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.privilegeStatus.start_date)
        : '',
    privilege_finish_date:
      currentItem && currentItem.privilegeStatus
        ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.privilegeStatus.finish_date)
        : '',

    price: currentItem ? currentItem.price : 0,
    spring_price: currentItem ? currentItem.spring_price : 0,
    admin_name: currentItem && currentItem.admin ? currentItem.admin.name : '',
    accept_date: currentItem ? momentHelper.changeYmdHisTodmY(currentItem.accept_date) : '',

    distance: currentItem && currentItem.route ? currentItem.route.distance : 0,
    federal_distance: currentItem && currentItem.route ? currentItem.route.federal_distance : 0,
    regional_distance: currentItem && currentItem.route ? currentItem.route.regional_distance : 0,

    app_form_id: currentItem ? currentItem.form_id : '',
    comment: '',
    route_info:
      currentItem && currentItem.privilegeStatus ? currentItem.privilegeStatus.route_info : '',
    reverse: false,

    // spring_block
    federal_price: currentItem ? currentItem.federal_price : '',
    regional_price: currentItem ? currentItem.regional_price : '',
    is_penalty: currentItem ? get(currentItem, 'dates.is_penalty') : '',
    penalty_post: currentItem ? get(currentItem, 'dates.penalty_post.title') : '',
    penalty_number: currentItem ? get(currentItem, 'dates.penalty_number') : '',
    penalty_place: currentItem ? get(currentItem, 'dates.penalty_place') : '',
    total_price: totalPrice,
    status: 'Без особого статуса'
  };

  // trailers
  if (currentItem && currentItem.trailers) {
    for (let i = 0; i < currentItem.trailers.length; i++) {
      initialValues.trailers_info[i] = `${currentItem.trailers[i].brandTitle}, ${
        currentItem.trailers[i].modelTitle
      }, ${currentItem.trailers[i].real_number}`;
    }
  }

  // axles lengths
  if (currentItem && currentItem.load && currentItem.load.axles_info) {
    const axlesInfo = JSON.parse(currentItem.load.axles_info);
    let axles_info = '';
    for (let i = 0; i < axlesInfo.length - 1; i++) {
      axles_info += `${axlesInfo[i].distance}м x `;
    }
    initialValues.axles_lengths = axles_info.substring(0, axles_info.length - 3);
  }
  // axles loads
  if (currentItem && currentItem.load && currentItem.load.axles_info) {
    const axlesInfo = JSON.parse(currentItem.load.axles_info);
    setPermissibleLoads(axlesInfo, initialValues);
    let axles_info = '';
    const wheelsCount = axlesInfo.map(a => a.wheel_count).join(' x ');
    const wheels = axlesInfo.map(a => a.wheels).join(' x ');
    for (let i = 0; i < axlesInfo.length; i++) {
      axles_info += `${axlesInfo[i].axle_load}т x `;
    }
    initialValues.axles_load = axles_info.substring(0, axles_info.length - 3);
    initialValues.wheelsCount = wheelsCount;
    initialValues.wheels = wheels;
  }

  let escortVehicles = [];
  if (currentItem && currentItem.load && currentItem.load.escort) {
    escortVehicles = currentItem.load.escort;
    initialValues.escort = escortVehicles;
  }

  // selectors
  const selector = formValueSelector('application-view-form');
  const comment = selector(state, 'comment');
  const reverse = selector(state, 'reverse');
  const regional_price = selector(state, 'regional_price');
  const federal_price = selector(state, 'federal_price');

  return {
    specialConditions,
    adminApplications,
    authentication,
    departments,
    initialValues,

    comment,
    reverse,
    regional_price,
    federal_price,
    currentItem
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { adminApplications, comment, reverse } = stateProps;
  const { adminRoute, routing } = adminApplications;
  const { markers, routeChanged } = adminRoute;

  const handleRemoveMarker = index => {
    console.log('waypoints before remove', routing.getWaypoints());
    const newWaypoints = routing.spliceWaypoints(index, 1);
    // routing.setWaypoints(newWaypoints);
    console.log('waypoints after remove', routing.getWaypoints());
    routing.route();
  };

  const handlePrintModalOpen = () => {
    dispatch(applicationActions.openPrintModal());
  };

  const handleActivateModalClose = () => {
    dispatch(applicationActions.closeActivateModal());
  };

  const activateApplication = () => {
    dispatch(applicationActions.activate(adminApplications.currentItem.id));
  };

  const setMarkerValue = (index, value) => {
    console.log('setMarkerValue', value);
    dispatch(change('application-view-form', `route[${index}]`, value));
    // dispatch(routeAddressActions.setValue(index, value));
    dispatch(applicationActions.setMarkerValue(index, value));
  };

  const handlePriceChange = (regional_price, federal_price) => {
    let regPrice = parseFloat(regional_price);
    if (isNaN(regPrice)) {
      regPrice = 0;
    }
    let fedPrice = parseFloat(federal_price);
    if (isNaN(fedPrice)) {
      fedPrice = 0;
    }
    const sum = (regPrice + fedPrice).toFixed(2);
    dispatch(change('application-view-form', 'total_price', sum));
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,

    handleRemoveMarker,

    handlePrintModalOpen,
    handleActivateModalClose,
    activateApplication,
    setMarkerValue,
    handlePriceChange
  };
};

ApplicationViewPage = reduxForm({
  form: 'application-view-form',
  enableReinitialize: true
})(ApplicationViewPage);

const connectedApplicationViewPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withSnackbar(ApplicationViewPage));
export { connectedApplicationViewPage as ApplicationViewPage };
