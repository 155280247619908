import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { applicationActions } from '../../../../actions/applicationActions';
import { roleConstants } from '../../../../constants/roleConstants';

class AgreementAcceptButton extends Component {
  render() {
    const { application, user, handleAgreementAccept, cls } = this.props;

    const agreementAccepted = () => {
      if (!application) {
        return false;
      }
      for (let i = 0; i < application.application_agreements.length; i++) {
        if (application.application_agreements[i].department_id === user.department_id) {
          return (
            application.application_agreements[i].status === applicationConstants.AGREEMENT_ACCEPTED
          );
        }
      }
      return false;
    };

    return (application.status === applicationConstants.STATUS_REVIEW ||
      application.status === applicationConstants.STATUS_REPEAT) &&
      user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT &&
      !agreementAccepted() ? (
      <Button className={cls} onClick={handleAgreementAccept(application.id, user.department_id)}>
        Одобрить согласование
      </Button>
    ) : (
      ''
    );
  }
}

const mapStateToProps = state => {
  const { adminApplications } = state;

  return {
    adminApplications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;

  const handleAgreementAccept = (application_id, department_id) => {
    return e => dispatch(applicationActions.agreementAccept(application_id, department_id));
  };
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,

    handleAgreementAccept
  };
};

const connectedAgreementAcceptButton = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AgreementAcceptButton);
export { connectedAgreementAcceptButton as AgreementAcceptButton };
