import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class MuiCabinetPasswordField extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      showPassword: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  render() {
    const {
      showPasswordVisible,
      icon,
      input,
      label,
      className,
      meta: { touched, error },
      ...custom
    } = this.props;
    return (
      <div className="row">
        <div className="col-md-4">
          <p className="label-form">{label}</p>
        </div>
        <div className="col-md-8">
          <TextField
            type={this.state.showPassword ? 'text' : 'password'}
            placeholder={label}
            className={className}
            helperText={touched && error}
            fullWidth
            InputProps={{
              endAdornment: showPasswordVisible ? (
                <InputAdornment className="no_border" position="end">
                  <IconButton aria-label="Toggle password visibility" onClick={() => this.toggle()}>
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : (
                ''
              ),
              disableUnderline: true
            }}
            {...input}
            {...custom}
          />
        </div>
      </div>
    );
  }
}

export default MuiCabinetPasswordField;
