import React from 'react';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import moment from 'moment';
import FieldLabel from '../../components/FieldLabel';
import Input from '../../components/Input';
import Truncate from '../../../../../components/Truncate/Truncate';
import {
  CooperationRequestStatus,
  CooperationStatus,
  getStatusName,
  isAgreedStatus
} from '../../../../../constants/cooperationConstants';
import { mapInfo } from '../../util/mapping';

const styles = {
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#323c47'
  },
  status: {
    border: '1px solid #0290ff',
    borderRadius: 5,
    padding: '10px 20px'
  }
};

const InfoContent = ({ application, classes }) => {
  const cooperation = mapInfo(application);
  return (
    <>
      <Grid item>
        <h2 className={classes.title}>Заявление №{cooperation.id}</h2>
      </Grid>
      <Grid item>
        <div className={classes.status}>
          <strong>Статус:</strong> {getStatusName(application)}
        </div>
      </Grid>
      {cooperation.status === CooperationStatus.InProgress &&
        cooperation.events &&
        cooperation.events.ADMINGBU_APP_TOWORK && (
          <Grid item>
            <FieldLabel
              label="Взят в работу"
              labelProps={{ xs: 12, sm: 4 }}
              childrenProps={{ xs: 12, sm: 8 }}
            >
              <Input
                value={moment(cooperation.events.ADMINGBU_APP_TOWORK.created_at).format(
                  'DD.MM.YYYY HH:mm'
                )}
              />
            </FieldLabel>
          </Grid>
        )}
      <Grid item>
        <FieldLabel
          label="Наименование заявителя (органа, направившего заявку)"
          labelProps={{ xs: 12, sm: 4 }}
          childrenProps={{ xs: 12, sm: 8 }}
        >
          <Input value={cooperation.applicantName} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Номер документа, присвоенный заявителем"
          labelProps={{ xs: 12, sm: 4 }}
          childrenProps={{ xs: 12, sm: 8 }}
        >
          <Grid container spacing={16} wrap="nowrap">
            <Grid item>
              <Input value={cooperation.applicantId} />
            </Grid>
            <Grid item>
              <FieldLabel label="Дата" labelProps={{ xs: 4 }} childrenProps={{ xs: 8 }}>
                <Input value={cooperation.date} />
              </FieldLabel>
            </Grid>
          </Grid>
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Наименование организации владельца ТС"
          labelProps={{ xs: 12, sm: 4 }}
          childrenProps={{ xs: 12, sm: 8 }}
        >
          <Input value={cooperation.transportOwnerAddress} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel label="Согласуемый маршрут движения">
          <div className="privilege_status">
            <Truncate maxCount={500}>{cooperation.routeString}</Truncate>
          </div>
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="В период"
          labelProps={{ xs: 12, sm: 2 }}
          childrenProps={{ xs: 12, sm: 10 }}
        >
          <Grid container spacing={16} wrap="nowrap">
            <Grid item>
              <FieldLabel label="c" labelProps={{ xs: 2 }} childrenProps={{ xs: 10 }}>
                <Input value={cooperation.period.start} />
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="по" labelProps={{ xs: 2 }} childrenProps={{ xs: 10 }}>
                <Input value={cooperation.period.end} />
              </FieldLabel>
            </Grid>
          </Grid>
        </FieldLabel>
      </Grid>
      <Grid item>
        {isAgreedStatus(cooperation) ? (
          <FieldLabel
            label="Количество поездок (осталось / всего)"
            labelProps={{ xs: 4, lg: 2 }}
            childrenProps={{ xs: 8, lg: 2 }}
          >
            <Input value={cooperation.tripCount} />
          </FieldLabel>
        ) : (
          <FieldLabel
            label="Количество поездок"
            labelProps={{ xs: 4, lg: 2 }}
            childrenProps={{ xs: 8, lg: 2 }}
          >
            <Input value={cooperation.allTripCount} />
          </FieldLabel>
        )}
      </Grid>
      <Grid item>
        <FieldLabel
          label="Вид перевозки"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 5 }}
        >
          <Input value={cooperation.tripType} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Тип перевозки"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 5 }}
        >
          <Input value={cooperation.locationType} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="ТС"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 5 }}
        >
          <Input value={cooperation.transportName} />
        </FieldLabel>
      </Grid>
      {cooperation.trailers.map(({ id, info }) => (
        <Grid key={id} item>
          <FieldLabel
            label="Прицеп"
            labelProps={{ xs: 12, sm: 4, lg: 2 }}
            childrenProps={{ xs: 12, sm: 8, lg: 5 }}
          >
            <Input value={info} />
          </FieldLabel>
        </Grid>
      ))}
      <Grid item>
        <FieldLabel
          label="Характеристики груза"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 5 }}
        >
          <Input value={cooperation.loadName} />
        </FieldLabel>
      </Grid>
    </>
  );
};

export default withStyles(styles)(InfoContent);
