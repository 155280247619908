import { isArray } from 'lodash';

const BANNER_KEY = 'banner';

export function getBannerKey(name) {
  return `${BANNER_KEY}_${name}`;
}

function getBannerUsers(name) {
  try {
    const value = JSON.parse(localStorage.getItem(getBannerKey(name)));
    return isArray(value) ? value : [];
  } catch (e) {
    return [];
  }
}

export function getShowBanner(name, userId) {
  const users = getBannerUsers(name);
  return !users.includes(userId);
}

export function setHideBanner(name, userId) {
  return localStorage.setItem(
    getBannerKey(name),
    JSON.stringify([...getBannerUsers(name), userId])
  );
}
