import React, { useState, useEffect } from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Toolbar,
  Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TablePagination from '../../../components/TablePagination';
import { vehicleService } from '../../../services/vehicleService';
import VehicleAccessFilters from './VehicleAccessFilters';
import { Loader } from '../../../components';
import { vehicleConstants } from '../../../constants/vehicleConstants';
import { userService } from '../../../services/userService';

const VehicleAccess = ({ user, enqueueSnackbar }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filters, setFilters] = useState({});
  const [response, setResponse] = useState({});
  const open = () => setShow(true);
  const close = () => setShow(false);
  async function fetch(page = 1, newFilters = filters) {
    try {
      setError(false);
      setLoading(true);
      const resp = await vehicleService.getCabinetPage(vehicleConstants.STATUS_ALL, {
        ...newFilters,
        page
      });
      setResponse(resp);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }
  useEffect(() => {
    if (show) {
      fetch();
    }
  }, [show]);

  const updateFilters = newFilters => {
    setFilters(newFilters);
    fetch(1, newFilters);
  };

  const haveNoItems = response && response.data && response.data.length === 0 && !isLoading;

  const onChange = async (vehicle, access, canEdit) => {
    try {
      await userService.updateAccess(user.id, vehicle.id, access, access ? canEdit : 0);
      enqueueSnackbar(`Доступ к ${vehicle.real_number} обновлен для ${user.name}`, {
        variant: 'success'
      });
      const { firm_users = [] } = vehicle;
      const newUser = {
        id: user.id,
        can_edit: access ? canEdit : 0,
        access
      };
      let newFirmUsers = [...firm_users];
      if (!access) {
        newFirmUsers = newFirmUsers.filter(u => u.id !== user.id);
      } else {
        newFirmUsers.push(newUser);
      }
      newFirmUsers = newFirmUsers.map(u => (u.id === user.id ? newUser : u));
      const updatedVehicle = {
        ...vehicle,
        firm_users: newFirmUsers
      };
      setResponse({
        ...response,
        data: response.data.map(d => (d.id === vehicle.id ? updatedVehicle : d))
      });
    } catch (e) {
      enqueueSnackbar('Произошла ошибка, попробуйте позже!', { variant: 'error' });
    }
  };

  return (
    <>
      <Button variant="outlined" className="btn-add  no-margin-left" onClick={open}>
        Доступ к ТС
      </Button>
      <Dialog open={show} onClose={close} aria-labelledby="form-dialog-title" maxWidth="xl">
        <Toolbar className="text-left">
          <Typography variant="title" color="inherit" className="flex">
            Доступ к ТС для {user.name}
          </Typography>
          <IconButton color="inherit" onClick={close}>
            <CloseIcon className="blue" />
          </IconButton>
        </Toolbar>

        <DialogContent className="dialog">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Тип ТС</TableCell>
                <TableCell>Марка</TableCell>
                <TableCell>Модель</TableCell>
                <TableCell>ГРЗ</TableCell>
                <TableCell>Доступ к ТС</TableCell>
                <TableCell>Редактирование ТС</TableCell>
              </TableRow>
              <VehicleAccessFilters setFilters={updateFilters} filters={filters} />
            </TableHead>
            <TableBody>
              {(isLoading || error || haveNoItems) && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    {!error && !isLoading && haveNoItems && <span>Объекты не найдены</span>}
                    {error && <span className="text-danger">Ошибка при загрузке данных</span>}
                    {isLoading && <Loader />}
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                response.data &&
                response.data.map(vehicle => {
                  const firmUser = vehicle.firm_users.find(u => u.id === user.id);
                  return (
                    <TableRow key={vehicle.id}>
                      <TableCell>{vehicle.is_trailer ? 'Прицеп' : 'ТС'}</TableCell>
                      <TableCell>{vehicle.brandTitle}</TableCell>
                      <TableCell>{vehicle.modelTitle}</TableCell>
                      <TableCell>{vehicle.real_number}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={!!firmUser}
                          onChange={(_, checked) =>
                            onChange(vehicle, checked ? 1 : 0, firmUser ? firmUser.can_edit : 0)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Checkbox
                          disabled={!firmUser}
                          checked={!!firmUser && firmUser.can_edit === 1}
                          onChange={(_, checked) =>
                            onChange(vehicle, firmUser ? 1 : 0, checked ? 1 : 0)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          {!isLoading && (
            <TablePagination onChangePage={page => fetch(page)} meta={response.meta} />
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VehicleAccess;
