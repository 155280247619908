import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { departmentActions } from '../../../actions/departmentActions';
import { specialConditionActions } from '../../../actions/specialConditionActions';

const DictionaryLoader = ({
  departments,
  specialConditions,
  loadDepartments,
  loadSpecialConditions
}) => {
  useEffect(() => {
    if (!departments || departments.length === 0) {
      loadDepartments();
    }
  }, [departments]);
  useEffect(() => {
    if (!specialConditions) {
      loadSpecialConditions(1);
    }
  }, [specialConditions]);
  return <div />;
};

const mapDispatchToProps = {
  loadDepartments: departmentActions.getAll,
  loadSpecialConditions: specialConditionActions.getItem
};

const mapStateToProps = state => ({
  departments: state.departments.items,
  specialConditions: state.specialConditions.currentItem
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DictionaryLoader);
