import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import { connect } from 'react-redux';
import { applicationActions } from '../../actions/applicationActions';
import { Routing } from './Routing';
import RestrictedArea from './RestrictedArea';
import RouteLines from '../RouteLines';
import DrawMarkers from './DrawMarkers';
import ApvgksLayer from './ApvksLayer';

const stamenTonerTiles = 'https://tile.aisktg.ru/tatarstan/{z}/{x}/{y}.png';
const stamenTonerAttr = '';
const mapCenter = [55.211, 50.634];
const zoomLevel = 7;

class MapContainer extends Component {
  componentDidMount() {
    const { setMap } = this.props;

    setTimeout(() => {
      setMap(this.map);
    }, 1000);
  }

  render() {
    const { markers, editable, editMap } = this.props;
    return (
      <div>
        <Map center={mapCenter} zoom={zoomLevel} ref={map => (this.map = map)}>
          <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
          {editable && editMap ? (
            <Routing map={this.map} markers={markers} />
          ) : (
            <React.Fragment>
              <RouteLines steps={markers} center={mapCenter} />
              <DrawMarkers markers={markers} />
            </React.Fragment>
          )}
          <RestrictedArea />
          <ApvgksLayer />
        </Map>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { adminApplications } = state;

  return {
    adminApplications,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  const setMap = map => {
    if (map) {
      dispatch(applicationActions.setMap(map));
    }
  };

  return {
    dispatch,
    setMap
  };
};

const connectedMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapContainer);
export { connectedMapContainer as MapContainer };
