import React from 'react';
import {CabinetMainMenuLink} from '../CabinetMainMenu/CabinetMainMenuLink';

export const CabinetLeftMenu = ({links}) => {
    const user = JSON.parse(localStorage.getItem('user'));
    return (
        <div className="">
            <ul className="">
                {links.map(link => (!link.role || link.role === user.role_id) ? (
                    <CabinetMainMenuLink {...link} />
                )  : null)}
            </ul>
        </div>
    );
};
