import React from 'react';

import { connect } from 'react-redux';
import { MainFooterBlock } from '../main/blocks/MainFooterBlock/MainFooterBlock';
import { CabinetMainMenu } from '../cabinet/blocks/CabinetMainMenu/CabinetMainMenu';
import { CabinetLeftMenu } from '../cabinet/blocks/CabinetLeftMenu/CabinetLeftMenu';
import AdminMainPage from '../admin/main/AdminMainPage';
import { WeightControlRoute } from '../../components/routes/WeightControlRoute';
import ApplicationsLayout from './applications/ApplicationsLayout';
import PostPickForm from './PostPickForm';
import { authActions } from '../../actions/authActions';
import { controlPostActions } from '../../actions/controlPostActions';
import CooperationViewPage from './applications/CooperationViewPage';
import WorkLayout from "./work/WorkLayout";

let WeightControlLayout = props => {
  const { match, controlPosts } = props;

  const links = [
    { key: '1', path: `${match.url}`, title: 'Личный кабинет' },
    {
      key: '4',
      path: `${match.url}/applications`,
      title: 'Проверка разрешений',
      hasChild: true
    },
    {
      key: '5',
      path: `${match.url}/work`,
      title: 'Рабочая смена',
      hasChild: true
    }
  ];

  const isPostSet = () => {
    return controlPosts.post_id > 0;
  };

  const submitPost = (values, dispatch) => {
    return dispatch(controlPostActions.choosePost(values));
  };
  return isPostSet() ? (
    <div className="">
      <div className="cabinet-header">
        <CabinetMainMenu />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className=" cabinet-left-menu">
              <CabinetLeftMenu links={links} />
            </div>
          </div>

          <div className="col-md-9 cabinet-content">
            <WeightControlRoute exact path={`${match.url}`} component={AdminMainPage} />
            <WeightControlRoute path={`${match.url}/applications`} component={ApplicationsLayout} />
            <WeightControlRoute path={`${match.url}/work`} component={WorkLayout} />
            <WeightControlRoute
              path={`${match.url}/cooperation/view/:id`}
              component={CooperationViewPage}
            />
          </div>
        </div>
      </div>
      <MainFooterBlock />
    </div>
  ) : (
    <PostPickForm onSubmit={submitPost} />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { controlPosts } = state;

  return {
    controlPosts,
    ...ownProps
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};

WeightControlLayout = connect(
  mapStateToProps,
  mapDispatchToProps
)(WeightControlLayout);

export default WeightControlLayout;
