import React from 'react';
import { AdminRoute } from '../../../components/routes/AdminRoute';
import { VehiclesListPage } from './VehiclesListPage';
import VehicleViewPage from './VehicleViewPage';

const VehiclesLayout = ({ match }) => (
  <div>
    <AdminRoute exact path={`${match.url}/`} component={VehiclesListPage} />
    <AdminRoute exact path={`${match.url}/info/:id`} component={VehicleViewPage} />
  </div>
);

export default VehiclesLayout;
