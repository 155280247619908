import { get, uniqueId, range, chain, flatMap, isNumber, set, cloneDeep } from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { axlesCalculator } from '../../../../helpers/axlesCalculator';
import { vehicleBrandService } from '../../../../services/vehicleBrandService';
import { vehicleModelService } from '../../../../services/vehicleModelService';

const moment = extendMoment(Moment);
const normalize = arr => arr.filter(a => a !== undefined).map(a => Number.parseFloat(a));

export function setTransportInfo(model, prefix, setFieldValue, axleLoads, values) {
  if (model.id < 0) {
    setFieldValue(`${prefix}.axlesCount`, 0);
    setFieldValue(`${prefix}.axles`, []);
  }
  setFieldValue(`${prefix}.axlesCount`, model.axle_count);
  const axleDistances = model.axles_distance
    .split(',')
    .map(d => d.trim())
    .map(Number.parseFloat);

  const wheels = model.axles_wheel_count
    .split(',')
    .map(d => d.trim())
    .map(Number.parseFloat);
  const types = model.vehicle_axles.map(m => m.type_id);
  const axleWheels = model.vehicle_axles.map(m => m.wheel_count);
  const axles = range(0, model.axle_count).map((a, index) => ({
    id: uniqueId(),
    distance: axleDistances[index] || 0,
    wheelsCount: '',
    suspension: types[index],
    sloppiness: wheels[index]
  }));
  setFieldValue(`${prefix}.axles`, axles);
  recomputeLoad(prefix, { axles }, setFieldValue, axleLoads, values);
}

export function createAxles(count) {
  return range(0, Number.parseFloat(count)).map(() => ({
    id: uniqueId()
  }));
}

export function getTotalWeight(transport, trailers) {
  return chain([...(transport.axles || []), ...flatMap(trailers, trailer => trailer.axles || [])])
    .map(a => +(a.load || 0))
    .sumBy()
    .value();
}

export const recomputeLoad = (prefix, updates, setFieldValue, axleLoads, values) => {
  const newValues = cloneDeep(values);
  set(newValues, prefix, updates);
  const { transport, trailers } = newValues;
  const axles = [...(transport.axles || []), ...flatMap(trailers, trailer => trailer.axles || [])];
  const [allowed, springAllowed] = axlesCalculator.calculateAxlesAllowedWeightsBoth(
    // скатность
    normalize(axles.map(a => a.sloppiness)),
    // расстояния
    normalize(axles.map(a => a.distance)),
    // подвеска
    normalize(axles.map(a => a.suspension)),
    // количество колес
    normalize(axles.map(a => a.wheelsCount)),
    axleLoads
  );
  let diff = 0;
  setFieldValue(
    'transport.axles',
    (transport.axles || []).map(a => {
      diff += 1;
      return {
        ...a,
        permissibleLoad: allowed[diff - 1],
        permissibleLoadSpring: springAllowed[diff - 1]
      };
    })
  );
  (trailers || []).forEach((trailer, index) => {
    const newAxles = (trailer.axles || []).map(a => {
      diff += 1;
      return {
        ...a,
        permissibleLoad: allowed[diff - 1],
        permissibleLoadSpring: springAllowed[diff - 1]
      };
    });
    setFieldValue(`trailers[${index}].axles`, newAxles);
  });
};

export function getTotalMaxWeight(transport, trailers) {
  return chain([
    ...[transport.maxWeight || 0],
    ...flatMap(trailers, trailer => trailer.maxWeight || [])
  ])
    .map(a => Number.parseFloat(a || '0'))
    .sumBy()
    .value();
}

async function getBrand(transport) {
  const { brand } = transport;
  if (isNumber(brand)) {
    return brand;
  }
  const newBrand = await vehicleBrandService.create({ title: brand });
  return newBrand.id;
}

async function getModel(transport, brand, isTrailer) {
  const { model } = transport;
  if (isNumber(model)) {
    return model;
  }
  const newModel = await vehicleModelService.create({
    title: model,
    brand_id: brand,
    is_trailer: isTrailer ? 1 : 0,
    size: '1*1*1',
    vehicle_type_id: 1,
    axle_count: transport.axlesCount,
    axles_distance: transport.axles.map(a => a.distance).join(', '),
    axles_wheel_count: transport.axles.map(a => a.sloppiness).join(', ')
  });
  return newModel.id;
}

async function toTransportApi(transport, isTrailer) {
  const brand = await getBrand(transport);
  const model = await getModel(transport, brand, isTrailer);
  return {
    brand_id: brand,
    model_id: model,
    number: transport.number,
    regions: transport.region,
    axle_count: transport.axlesCount,
    pts_weight: transport.initialWeight,
    pts_max_weight: transport.maxWeight
  };
}

const getRestrictionRange = restrictionDates => {
  if (!restrictionDates) {
    return null;
  }
  const { start, end } = restrictionDates;
  if (!start || !end) {
    return null;
  }
  const startMoment = moment(start);
  const endMoment = moment(end);
  return moment.range(startMoment, endMoment);
};

export const isContainSpring = (restrictionDates, springCondition) => {
  const restrictionRange = getRestrictionRange(restrictionDates);
  if (!restrictionRange) {
    return false;
  }
  const startMoment = moment(springCondition.start_date).startOf('d');
  const endMoment = moment(springCondition.finish_date).endOf('d');
  return moment.range(startMoment, endMoment).overlaps(restrictionRange, { adjacent: true });
};

export async function transformToRequest(data) {
  const axles = [
    ...(data.transport.axles || []),
    ...flatMap(data.trailers, trailer => trailer.axles || [])
  ];
  return {
    role_id: data.type,
    name: data.name,
    rd_number: data.rd_number,
    distance_info: data.distance_info,
    is_spring: data.is_spring? 1 : 0,
    address: data.address,
    inn: data.inn + '',

    vehicle: await toTransportApi(data.transport),

    trailers: await Promise.all(data.trailers.map(t => toTransportApi(t, true))),

    route_type_id: data.routeType,
    start_date: moment(data.restrictionDates.start).format('YYYY-MM-DD'),
    finish_date: moment(data.restrictionDates.end).format('YYYY-MM-DD'),
    runs_count: data.tripCount,

    load_type_id: data.loadType,
    length: data.dimensions.length,
    width: data.dimensions.width,
    height: data.dimensions.height,
    axles_count: axles.length,
    weight: getTotalWeight(data.transport, data.trailers),
    axles_info: axles.map((axle, index) => ({
      distance: axle.distance || axles[index - 1].distance,
      // permissible_load: axle.permissibleLoad,
      axle_load: axle.load,
      type_id: axle.suspension,
      wheel_count: axle.sloppiness,
      wheels: axle.wheelsCount
    })),
    use_privilege_status: data.route.useStatus ? 1 : 0,
    privilege_status_id: data.route.useStatus ? data.route.status : undefined,
    markers: !data.route.useStatus
      ? data.route.steps
          .filter(s => s && s.coords)
          .map(s => ({
            ...s,
            coords: {
              ...s.coords,
              lon: s.coords.lng
            }
          }))
      : undefined,
    escort_count: data.escort.length,
    escort: data.escort.map(escort => ({
      brand: escort.brand,
      model: escort.model,
      number: escort.number,
      regions: escort.region
    })),

    is_penalty: data.isPenalty ? 1 : 0,
    penalty_number: data.penalty.actNumber,
    penalty_place: data.penalty.parking,
    penalty_post_id: data.penalty.controlPost,
    penalty_files: data.isPenalty && get(data, 'penalty.act.name') ? [data.penalty.act] : [],

    issue_place_id: data.issuePlaces,
    location_type: data.tripType,
    load_name: data.loadName
  };
}

export const isStatusAvailable = (dates, status) => {
  const restrictionRange = getRestrictionRange(dates);
  if (!restrictionRange) {
    return true;
  }
  const startDate = moment(status.start_date, 'DD.MM.YYYY').format('YYYY-MM-DD');
  const finishDate = moment(status.finish_date, 'DD.MM.YYYY').format('YYYY-MM-DD');
  const statusRange = moment.range(startDate, finishDate);
  return statusRange.contains(restrictionRange.start) && statusRange.contains(restrictionRange.end);
};
