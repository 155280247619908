import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Star from '@material-ui/icons/Star';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { change, formValueSelector } from 'redux-form';
import { applicationConstants } from '../../../constants/applicationConstants';
import { ApplicationsTab } from './ApplicationsTab';
import { ApplicationFilterModal } from './ApplicationFilterModal';
import { applicationActions } from '../../../actions/applicationActions';
import { urlHelper } from '../../../helpers/urlHelper';

class ApplicationsListPage extends Component {
  componentDidMount() {
    const { userApplications } = this.props;
    const { tabIndex } = userApplications;

    this.props.dispatch(applicationActions.getUserFiltered(tabIndex, []));
  }

  render() {
    const { handleModalOpen, handleTabChange, userApplications, authentication } = this.props;
    const { tabIndex } = userApplications;
    const { user } = authentication;

    return (
      <main role="main" className="container">
        <div className="">
          <h2 className="h2-title">
            История заявлений
            {((new Date().getTime() < (new Date('2023-01-01 00:00')).getTime())) && (
                <Link to={`${urlHelper.getHomeUrl(user.role_id)}/applications/create/step/vehicle`}>
                  <Button size="small" color="primary" className="btn-add" type="button">
                    + Создать заявление
                  </Button>
                </Link>)}
            <Link to={`${urlHelper.getHomeUrl(user.role_id)}/applications/templates`}>
              <Button color="primary" className="info-btn" type="button">
                <Star className="star_icon" /> Мои шаблоны
              </Button>
            </Link>
          </h2>
        </div>

        <div className="">
          <Button
            className="no-margin info-btn"
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleModalOpen}
          >
            Фильтровать
          </Button>

          <ApplicationFilterModal />
        </div>
        <br />
        <AppBar position="static" color="default" className="tabs_header">
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className="tab_style"
            variant="fullWidth"
          >
            <Tab
              classes={{ selected: 'tab_active' }}
              label="Новые"
              value={applicationConstants.STATUS_NEW}
            />
            <Tab
              classes={{ selected: 'tab_active' }}
              label="Ожидает подтверждения"
              value={applicationConstants.STATUS_REVIEW}
            />
            <Tab
              classes={{ selected: 'tab_active' }}
              label="Отклоненные"
              value={applicationConstants.STATUS_DECLINE}
            />
            <Tab
              classes={{ selected: 'tab_active' }}
              label="Одобренные"
              value={applicationConstants.STATUS_ACCEPT}
            />
            <Tab
              classes={{ selected: 'tab_active' }}
              label="Активные"
              value={applicationConstants.STATUS_ACTIVE}
            />
          </Tabs>
        </AppBar>

        {/* {userApplications.loading && <Loader />} */}
        {/* <div className=""> */}
        {/* <br/> */}
        {/* {items.map(item => <div key={item.id}><ApplicationCard application={item}/><br/></div>)} */}
        {/* </div> */}
        <ApplicationsTab />
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { userApplications, authentication } = state;
  const selector = formValueSelector('application-filter-form');
  const filterData = selector(state, 'start_date', 'finish_date', 'id', 'number');
  return {
    userApplications,
    authentication,
    filterData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { userApplications, authentication, filterData } = stateProps;
  const { dispatch } = dispatchProps;

  const handleTabChange = (event, tabIndex) => {
    if (tabIndex !== userApplications.tabIndex) {
      dispatch(applicationActions.setTab(tabIndex));
      const values = filterData;
      values.page = 1;
      dispatch(applicationActions.getUserFiltered(tabIndex, values));

      // set status for filter form
      dispatch(change('application-cabinet-filter-form', 'status', tabIndex));
    }
  };

  const handleModalOpen = () => {
    dispatch(applicationActions.openFilterModal());
  };

  return {
    ...ownProps,
    dispatch,
    userApplications,
    authentication,

    handleModalOpen,
    handleTabChange
  };
};

const connectedApplicationsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationsListPage);
export { connectedApplicationsPage as ApplicationsListPage };
