import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Add, Remove} from "@material-ui/icons";
import {Grid} from "@material-ui/core";
import {Field} from "formik";
import MaterialInput from "./MaterialInput";
import FieldLabel from "./FieldLabel";

export const FormikAddRemoveFormField = (props) => {
  const {
    setFieldValue,
    values,
    min,
    max,
    meta
  }= props;
  const ref = React.createRef();
  return (
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={1} className="text-center">
          <IconButton
              size="small"
              style={{padding:8}}
              onClick={() => {
                setFieldValue('tripCount', Math.max(min, values.tripCount-1))
              }}
          >
            <Remove/>
          </IconButton>
        </Grid>
        <Grid item xs={10} className="text-left">
          <Field
              name="tripCount"
              component={MaterialInput}
              type="number"
              ref={ref}
              max={max}
              min={min}
          />
        </Grid>
        <Grid item xs={1} className="text-center">
          <IconButton
              size="small"
              style={{padding:8}}
              onClick={() => {
                setFieldValue('tripCount', Math.min(max, values.tripCount+1))
              }}
          >
            <Add/>
          </IconButton>
        </Grid>
      </Grid>
  );
};
