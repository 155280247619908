import { coefficientConstants } from '../constants/coefficientConstants';
import { authConstants } from '../constants/authConstants';

const createItem = (items, item) => {
  items.unshift(item);
  return items;
};
const updateItem = (items, item) => {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== item.id) {
      result.push(items[i]);
    } else {
      result.push(item);
    }
  }
  return result;
};
const initialState = {
  showFormModal: false,
  currentItem: {},
  meta: { per_page: 7, total: 0, current_page: 1 },
  items: []
};

export const coefficients = (state = initialState, action) => {
  switch (action.type) {
    // Get page
    case coefficientConstants.GET_PAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case coefficientConstants.GET_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.coefficients.data,
        meta: action.coefficients.meta
      };
    case coefficientConstants.GET_PAGE_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Get all
    case coefficientConstants.GET_ALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case coefficientConstants.GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.coefficients.data
      };
    case coefficientConstants.GET_ALL_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Create
    case coefficientConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: createItem(state.items, action.coefficient),
        meta: {
          ...state.meta,
          total: state.meta.total + 1
        },
        showFormModal: false
      };

    // Update
    case coefficientConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: updateItem(state.items, action.coefficient),
        showFormModal: false
      };

    // Delete
    case coefficientConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(coefficient =>
          coefficient.id === action.id ? { ...coefficient, deleting: true } : coefficient
        )
      };
    case coefficientConstants.DELETE_SUCCESS:
      // remove deleted HeaderMenu from state
      return {
        ...state,
        loading: false,
        items: state.items.filter(coefficient => coefficient.id !== action.id),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    case coefficientConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to HeaderMenu
      return {
        ...state,
        loading: false,
        items: state.items.map(coefficient => {
          if (coefficient.id === action.id) {
            // make copy of HeaderMenu without 'deleting:true' property
            const { deleting, ...coefficientCopy } = coefficient;
            // return copy of HeaderMenu with 'deleteError:[error]' property
            return { ...coefficientCopy, deleteError: action.error };
          }

          return coefficient;
        })
      };

    // Get item
    case coefficientConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case coefficientConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.coefficient.data
      };
    case coefficientConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // form modal
    case coefficientConstants.FORM_MODAL_OPEN:
      return {
        ...state,
        showFormModal: true
      };
    case coefficientConstants.FORM_MODAL_CLOSE:
      return {
        ...state,
        showFormModal: false
      };
    case coefficientConstants.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item
      };

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
