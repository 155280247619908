import React from 'react';

import { OfficerRoute } from '../../../components/routes/OfficerRoute';
import { ApplicationsListPage } from '../../admin/applications/ApplicationsListPage';
import { ApplicationViewPage } from '../../admin/applications/ApplicationViewPage';
import NewApplicationViewPage from '../../admin/applications_new/ApplicationViewPageContainer';

const ApplicationsLayout = ({ match }) => (
  <div>
    <OfficerRoute exact path={`${match.url}/`} component={ApplicationsListPage} />
    <OfficerRoute exact path={`${match.url}/view/:id`} component={ApplicationViewPage} />
    <OfficerRoute exact path={`${match.url}/view_new/:id`} component={NewApplicationViewPage} />
  </div>
);

export default ApplicationsLayout;
