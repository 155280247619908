import React from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { withSnackbar } from 'notistack';
import { getFile } from '../../../../helpers/fileUploadHelper';
import { Loader } from '../../../../components';

async function processFile(fileEvent, handleFileAdd, enqueueSnackbar, application, uploadFile) {
  try {
    const file = await getFile(fileEvent);
    const result = await uploadFile(application.id, file);
    handleFileAdd(result.data);
    enqueueSnackbar(`Файл "${file.name}" успешно загружен! `, {
      variant: 'success'
    });
  } catch (e) {
    enqueueSnackbar(`Ошибка при загрузке файла`, {
      variant: 'error'
    });
    console.log(e);
  }
}

const Files = ({
  application,
  files,
  handleFileRemove,
  handleFileAdd,
  enqueueSnackbar,
  isEditable,
  title,
  fileUploader,
  uploadLabel = 'Прикрепить Документ',
  showNoFiles = false,
  removeFile,
  hint = ''
}) => {
  const [isUploading, setUploading] = React.useState(false);
  const uploadFile = async event => {
    setUploading(true);
    try {
      await Promise.all(
        Array.from(event.target.files).map(eventFile =>
          processFile(eventFile, handleFileAdd, enqueueSnackbar, application, fileUploader)
        )
      );
    } catch (e) {
      enqueueSnackbar(`Ошибка при загрузке файлов`, {
        variant: 'error'
      });
      console.log(e);
    }
    setUploading(false);
  };
  const onFileRemove = async file => {
    try {
      await removeFile(file);
      enqueueSnackbar(`Файл ${file.name} удален!`, {
        variant: 'success'
      });
      handleFileRemove(file);
    } catch (e) {
      enqueueSnackbar('Ошибка при удалении файла!', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      {title && (
        <Grid item>
          <h2 className="h3-title">{title}</h2>
        </Grid>
      )}
      {files.length === 0 && showNoFiles && (
        <Grid item>
          <div>Нет прикрепленных документов</div>
        </Grid>
      )}
      <Grid item>
        {files.map(file => (
          <Grid key={file.id} container spacing={8} alignItems="center">
            <Grid item>
              <a target="_blank" href={file.url ? file.url : ''}>
                {file.name ? file.name : ''}
              </a>
            </Grid>
            {handleFileRemove && (
              <Grid item>
                <IconButton color="inherit" onClick={() => onFileRemove(file)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      {isEditable && (
        <>
          {hint && (
            <Grid item className="margin-bottom-10">
              {hint}
            </Grid>
          )}
          <Grid item>
            <input
              className="file_input"
              id="cooperation-upload-button"
              type="file"
              onChange={uploadFile}
              multiple
            />
            <label className="file_btn" htmlFor="cooperation-upload-button">
              <Button
                variant="contained"
                component="span"
                className="btn"
                disabled={isUploading ? 'disabled' : undefined}
              >
                {isUploading && <Loader size={24} />}
                &nbsp; {uploadLabel}
              </Button>
            </label>
          </Grid>
        </>
      )}
      <br />
    </>
  );
};

export default withSnackbar(Files);
