import { isArray, sortBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Marker, Polyline, Tooltip } from 'react-leaflet';
import { applicationService } from '../services/applicationService';

const getLineColor = r => {
  switch (r.type) {
    case 0:
      return 'red';
    case 1:
      return 'blue';
    case 2:
      return 'green';
    default:
      return 'red';
  }
};

function getDistance(point, coords) {
  if (!isArray(coords) || !coords || coords.length < 2 || !point) {
    return Number.MAX_VALUE;
  }
  const { lat, lng } = point;
  const [coordLat, coordLng] = coords;
  return Math.sqrt(Math.pow(lat - coordLat, 2) + Math.pow(lng - coordLng, 2));
}

function getClosestCoords(step, coords) {
  if (!coords || coords.length === 0) {
    return Number.MAX_VALUE;
  }
  const start = coords[0];
  const end = coords[coords.length - 1];
  return sortBy([start, end], c => getDistance(step.coords, c))[0];
}

function getClosest(step, routeLines) {
  if (!routeLines || routeLines.length === 0) {
    return null;
  }
  const lines = routeLines.map(l => ({
    ...l,
    closestCoords: getClosestCoords(step, l.coords)
  }));
  console.log(lines);
  console.log(lines.map(l => l.closestCoords));
  console.log(step.coords);
  console.log(sortBy(lines, c => getDistance(step.coords, c.closestCoords)));
  const closest = sortBy(lines, c => getDistance(step.coords, c.closestCoords))[0];
  if (getDistance(step, closest.closestCoords) < 0.01) {
    return null;
  }

  return closest;
}

const RouteLines = ({ steps, center, setLines }) => {
  const [routeLines, setRouteLines] = useState([]);
  const lines = useMemo(
    () =>
      steps
        .filter(({ coords = {} }) => coords.lat)
        .map(({ coords }) => [coords.lat, coords.lng || coords.lon]),
    [steps]
  );
  const filteredSteps = steps
    .filter(({ coords = {} }) => coords.lat && (coords.lng || coords.lat))
    .map(s => ({
      ...s,
      coords: {
        lat: s.coords.lat,
        lng: s.coords.lng || s.coords.lon
      }
    }));
  useEffect(() => {
    async function loadRoutes() {
      const l = (await applicationService.getMapRoutes(lines)).filter(line => {
        if (line.type !== undefined && line.type !== null) {
          return true;
        }
        const start = line.coords[0];
        const end = line.coords[line.coords.length - 1];
        const marker = filteredSteps.find(
            step => getDistance(step.coords, start) < 0.01 || getDistance(step.coords, end) < 0.01
        );
        return !marker;
      });
      setRouteLines(l);
      setLines && setLines(l);
    }
    if (lines.length > 1) {
      loadRoutes();
    } else {
      setRouteLines([]);
    }
  }, [lines]);

  const stepToClosestCoord = filteredSteps
    .map(step => ({
      ...step,
      closest: getClosest(step, routeLines)
    }))
    .filter(c => !!c.closest);
  return (
    <React.Fragment>
      {routeLines.map(r => (
        <Polyline color={getLineColor(r)} positions={r.coords} key={r.id} />
      ))}
      {routeLines.length === 0 && steps.length > 0 && center && (
        <Marker position={center} opacity={0}>
          <Tooltip permanent direction="center">
            <span>Пожалуйста, подождите. Данные обновляются</span>
          </Tooltip>
        </Marker>
      )}
      {stepToClosestCoord.map(r => (
        <Polyline
          color="grey"
          stroke="dashed"
          positions={[r.closest.closestCoords, [r.coords.lat, r.coords.lng]]}
          key={r.id}
          dashArray="6 8"
        />
      ))}
    </React.Fragment>
  );
};

export default RouteLines;
