import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components';
import { authActions } from '../../../actions/authActions';
import { FormField } from '../../../components/form/FormField';

let UserMainInfoForm = props => {
  const { error, handleSubmit, submitting, cabinet } = props;
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Field
          name="name"
          type="text"
          label="Полное имя (ФИО)"
          validate={[fieldValidators.required, fieldValidators.maxLength60]}
          component={FormField}
        />
        <Field
          name="email"
          type="email"
          label="Email"
          validate={[fieldValidators.required, fieldValidators.email, fieldValidators.maxLength40]}
          component={FormField}
        />
        {error && <strong>{error}</strong>}
        <p>{cabinet.infoStatus}</p>

        <div className="row">
          <div className="text-left">
            <Button className="btn-add" type="submit" disabled={submitting}>
              {submitting && <Loader />} Сохранить
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

UserMainInfoForm = reduxForm({
  form: 'cabinet-main',
  enableReinitialize: true
})(UserMainInfoForm);

const mapStateToProps = state => {
  const { user } = state.authentication;
  const { cabinet } = state;

  const initialValues = user
    ? {
        name: user.name,
        email: user.email
      }
    : null;

  return {
    user,
    cabinet,
    initialValues
  };
};
const mapDispatchToProps = dispatch => {
  return {
    togglePasswordVisible: () => {
      dispatch(authActions.togglePasswordVisible());
    }
  };
};

const connectedUserMainInfoForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMainInfoForm);
export { connectedUserMainInfoForm as UserMainInfoForm };
