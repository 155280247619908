import React from 'react';
import { isEmpty, isFinite } from 'lodash';
import TableCellMaterial from '@material-ui/core/TableCell';

const TableCell = ({ children, ...props }) => {
  const empty = isEmpty(children) && !isFinite(children);
  return (
    <TableCellMaterial align={empty ? 'center' : 'inherit'} {...props}>
      {empty ? '-' : children}
    </TableCellMaterial>
  );
};

export default TableCell;
