import React from 'react';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components/Loader';
import { authActions } from '../../../actions/authActions';
import { fieldMasks } from '../../../helpers/fieldMasks';
import { FormField } from '../../../components/form/FormField';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { cabinetActions } from '../../../actions/cabinetActions';
import { maskHelper } from '../../../helpers/maskHelper';
import { legalFormActions } from '../../../actions/legalFormActions';
import { regAppConstants } from '../../../constants/regAppConstants';
import { renderSelectField } from '../../../components/form/renderSelectField';

let FirmForm = props => {
  const { error, handleSubmit, submitting, legalForms, cabinet, openConfirmation, onlyKpp, kppConfirmation} = props;

  return (
    <div>
      {cabinet.loading && <Loader />}
      {!cabinet.loading && (
        <form onSubmit={handleSubmit}>
            {onlyKpp ? (<Field
                name="kpp_org"
                type="text"
                label="КПП организации"
                validate={[fieldValidators.required, fieldValidators.exactLength9]}
                component={FormField}
                {...fieldMasks.kppFirm}
            />) :( <React.Fragment>
          <Field
            name="name"
            type="text"
            label="Название организации"
            validate={[fieldValidators.required, fieldValidators.maxLength100]}
            component={FormField}
          />
            <Field
                name="inn_org"
                type="text"
                label="ИНН организации"
                validate={[fieldValidators.required, fieldValidators.exactLength10]}
                component={FormField}
                {...fieldMasks.innFirm}
            />
            <Field
                name="kpp_org"
                type="text"
                label="КПП организации"
                validate={[fieldValidators.required, fieldValidators.exactLength9]}
                component={FormField}
                {...fieldMasks.kppFirm}
            />
          <Field
            className="form-control"
            name="legal_form_id"
            component={renderSelectField}
            label="Тип организации"
            validate={[fieldValidators.required]}
          >
            {legalForms.items
              ? legalForms.items.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.title}
                  </option>
                ))
              : null}
          </Field>

          <Field
            name="address"
            type="text"
            label="Юридический адрес"
            validate={[fieldValidators.required, fieldValidators.maxLength100]}
            component={FormField}
          />
          <Field
            name="executive_fio"
            type="text"
            label="Фио руководителя"
            validate={[fieldValidators.required, fieldValidators.maxLength100]}
            component={FormField}
          />
          <Field
            name="executive_position"
            type="text"
            label="Должность руководителя"
            validate={[fieldValidators.required, fieldValidators.maxLength100]}
            component={FormField}
          />

          <Field
            className="form-control"
            name="reason_key"
            component={renderSelectField}
            label="Действует на основании"
            validate={[fieldValidators.required]}
          >
            {regAppConstants.REASONS.map(option => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </Field>

          <Field
            name="bank_name"
            type="text"
            label="Наименование банка"
            validate={[fieldValidators.required, fieldValidators.maxLength100]}
            component={FormField}
          />
          <Field
            name="bank_inn"
            type="text"
            label="ИНН банка"
            validate={[fieldValidators.required, fieldValidators.exactLength10]}
            component={FormField}
            {...fieldMasks.innFirm}
          />
          <Field
            name="correspondent_account"
            type="text"
            label="Корреспондентский счет"
            validate={[fieldValidators.required, fieldValidators.exactLength20]}
            component={FormField}
            {...fieldMasks.account}
          />
          <Field
            name="bank_bik"
            type="text"
            label="БИК банка"
            validate={[fieldValidators.required, fieldValidators.exactLength9]}
            component={FormField}
            {...fieldMasks.bik}
          />
          <Field
            name="account"
            type="text"
            label="Расчетный счет"
            validate={[fieldValidators.required, fieldValidators.exactLength20]}
            component={FormField}
            {...fieldMasks.account}
          />
          <Field
            name="phone"
            type="text"
            label="Контактный телефон организации"
            validate={[fieldValidators.required, fieldValidators.phoneRus]}
            component={FormField}
            {...fieldMasks.phoneRus}
          />
          <Field
            name="phone_static"
            type="text"
            label="Городской номер телефона"
            component={FormField}
          />
          <Field
            name="contact_fio"
            type="text"
            label="ФИО контактного лица"
            validate={[fieldValidators.required, fieldValidators.maxLength100]}
            component={FormField}
          />
          <Field
            name="email"
            type="email"
            label="Email"
            validate={[
              fieldValidators.required,
              fieldValidators.email,
              fieldValidators.maxLength40
            ]}
            component={FormField}
          /></React.Fragment>)}

          {error && (
            <div className="has-error">
              <span>{error}</span>
              <br />
              <br />
            </div>
          )}

          <div className="row">
            <div className="= text-left">
              <Button
                className="btn-add"
                type="button"
                onClick={e => {
                  return onlyKpp ? kppConfirmation() : openConfirmation();
                }}
                disabled={submitting}
              >
                {submitting && <Loader />} Сохранить
              </Button>
            </div>
          </div>
        </form>
      )}
      {cabinet.status}
    </div>
  );
};

FirmForm = reduxForm({
  form: 'cabinet-firm',
  enableReinitialize: true
})(FirmForm);

const mapStateToProps = state => {
  const { user } = state.authentication;
  const { cabinet, legalForms } = state;

  const data = cabinet.info ? cabinet.info : null;

  const selector = formValueSelector('cabinet-firm');

  return {
    user,
    cabinet,
    legalForms,
    initialValues: data
      ? {
          name: data.name,
          phone: maskHelper.unmaskPhone(data.phone),
          email: data.email,

          legal_form_id: data.firm ? data.firm.legal_form_id : null,
          inn_org: data.firm ? data.firm.inn_org : '',
          kpp_org: data.firm ? data.firm.kpp_org : '',
          address: data.firm ? data.firm.address : null,
          executive_fio: data.firm ? data.firm.executive_fio : null,
          executive_position: data.firm ? data.firm.executive_position : null,
          reason_key: data.firm ? data.firm.reason_key : null,
          contact_phone: data.firm ? data.firm.contact_phone : null,
          phone_static: data.firm ? data.firm.phone_static : null,
          contact_fio: data.firm ? data.firm.contact_fio : null,
          inn_files: data.firm ? data.firm.inn_files : [],

          bank_name:
            data.firm && data.firm.paymentDetails ? data.firm.paymentDetails.bank_name : null,
          bank_inn:
            data.firm && data.firm.paymentDetails ? data.firm.paymentDetails.bank_inn : null,
          bank_bik:
            data.firm && data.firm.paymentDetails ? data.firm.paymentDetails.bank_bik : null,
          account: data.firm && data.firm.paymentDetails ? data.firm.paymentDetails.account : null,
          correspondent_account:
            data.firm && data.firm.paymentDetails
              ? data.firm.paymentDetails.correspondent_account
              : null
        }
      : null
  };
};
const mapDispatchToProps = dispatch => {
  // const onDataLoaded = (dispatch, data) => {
  //     dispatch(cabinetActions.setUploadInns(data.data.firm.inn_files));
  // };

  dispatch(legalFormActions.getAll());
  // dispatch(cabinetActions.getInfo(onDataLoaded));

  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { cabinet, legalForms, initialValues } = stateProps;
  const { dispatch } = dispatchProps;

  const removeIdFromFileList = (idsList, id) => {
    let ids = idsList.split(',');
    ids = ids.filter(item => item !== id);
    return ids.join(',');
  };

  const togglePasswordVisible = () => {
    dispatch(authActions.togglePasswordVisible());
  };

  const openConfirmation = () => {
    dispatch(cabinetActions.dataConfirmationOpen());
  };

  return {
    ...ownProps,
    cabinet,
    legalForms,
    initialValues,
    togglePasswordVisible,
    openConfirmation
  };
};

const connectedFirmForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FirmForm);
export { connectedFirmForm as FirmForm };
