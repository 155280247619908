import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormFieldNoLabel } from '../../../components/form/FormFieldNoLabel';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components/Loader';

let NewPasswordForm = props => {
  const { error, handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="password"
        type="password"
        placeholder="Новый пароль"
        validate={[fieldValidators.required, fieldValidators.minLength8]}
        component={FormFieldNoLabel}
      />

      <Field
        name="password_confirmation"
        type="password"
        placeholder="Повторите пароль"
        validate={fieldValidators.passwordsMatch}
        component={FormFieldNoLabel}
      />
      {error && <strong>{error}</strong>}
      <div>
        <button
          type="submit"
          variant="contained"
          color="primary"
          className="btn lbutton"
          disabled={submitting}
        >
          {submitting && <Loader />} Подтвердить
        </button>
      </div>
    </form>
  );
};

NewPasswordForm = reduxForm({
  form: 'new-password-code', // a unique identifier for this form
  enableReinitialize: true
})(NewPasswordForm);

NewPasswordForm = connect(state => ({
  initialValues: {
    token: state.restorePassword.restoreToken
  }
}))(NewPasswordForm);

export default NewPasswordForm;
