import React from 'react';
import { get, has, isArray, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import ApplicationSchema from './ApplicationSchema';
import ApplicationSectionTitle from './ApplicationSectionTitle';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { applicationHelper } from '../../../helpers/applicationHelper';
import ApplicationField from './ApplicationField';
import { getIsNew } from './utils';

function getAxlesLength(axlesInfo) {
  if (axlesInfo) {
    let axlesLength = '';
    for (let i = 0; i < axlesInfo.length - 1; i += 1) {
      axlesLength += `${axlesInfo[i].distance}м x `;
    }
    return axlesLength.substring(0, axlesLength.length - 3);
  }
  return '';
}

function setPermissibleLoads(axlesInfo, values) {
  let permissible_load = '';
  let permissible_load_spring = '';
  let permissible_load0 = '';
  let permissible_load1 = '';
  let permissible_load2 = '';

  let permissible_load_spring0 = '';
  let permissible_load_spring1 = '';
  let permissible_load_spring2 = '';
  const isLoadArray = isArray(axlesInfo[0].permissible_load);
  const trim = load => load.substring(0, load.length - 3);
  axlesInfo.forEach(axleInfo => {
    if (isLoadArray) {
      permissible_load0 += `${axleInfo.permissible_load[0]}т x `;
      permissible_load1 += `${axleInfo.permissible_load[1]}т x `;
      permissible_load2 += `${axleInfo.permissible_load[2]}т x `;
      if (axleInfo.permissible_load_spring) {
        permissible_load_spring0 += `${axleInfo.permissible_load_spring[0]}т x `;
        permissible_load_spring1 += `${axleInfo.permissible_load_spring[1]}т x `;
        permissible_load_spring2 += `${axleInfo.permissible_load_spring[2]}т x `;
      }
    } else {
      permissible_load += `${axleInfo.permissible_load}т x `;
      if (axleInfo.permissible_load_spring) {
        permissible_load_spring += `${axleInfo.permissible_load_spring}т x `;
      }
    }
  });
  if (isLoadArray) {
    values.permissible_load0 = trim(permissible_load0);
    values.permissible_load1 = trim(permissible_load1);
    values.permissible_load2 = trim(permissible_load2);
    if (permissible_load_spring0) {
      values.permissible_load_spring0 = trim(permissible_load_spring0);
      values.permissible_load_spring1 = trim(permissible_load_spring1);
      values.permissible_load_spring2 = trim(permissible_load_spring2);
    }
  } else {
    values.permissible_load = trim(permissible_load);
    if (permissible_load_spring) {
      values.permissible_load_spring = trim(permissible_load_spring);
    }
  }
}

function getAdditionalLoadInfo(axlesInfo) {
  const additionalLoadInfo = {};
  if (axlesInfo) {
    let axlesLoad = '';
    setPermissibleLoads(axlesInfo, additionalLoadInfo);
    const wheelsCount = axlesInfo.map(a => a.wheel_count).join(' x ');
    const wheels = axlesInfo.map(a => a.wheels).join(' x ');
    for (let i = 0; i < axlesInfo.length; i += 1) {
      axlesLoad += `${axlesInfo[i].axle_load}т x `;
    }
    additionalLoadInfo.axles_load = axlesLoad.substring(0, axlesLoad.length - 3);
    additionalLoadInfo.wheelsCount = wheelsCount;
    additionalLoadInfo.wheels = wheels;
  }
  return additionalLoadInfo;
}

const ApplicationTransportsInfo = ({ application, specialConditions }) => {
  const { load, axlesInfo } = application;
  if (!axlesInfo || axlesInfo.length === 0 || !axlesInfo[0]) {
    return null;
  }
  const hasLoadFiles =
    has(application, 'files.load_files') && !isEmpty(application.files.load_files);
  const isSpring =
    specialConditions && applicationHelper.checkSpring(application, specialConditions.currentItem);
  const additionalLoadInfo = getAdditionalLoadInfo(axlesInfo);
  const isLoadArray = isArray(axlesInfo[0].permissible_load);
  const isNew = getIsNew(application);

  const showStandard = isNew || application.is_spring === 0;
  const showSpring = isNew || application.is_spring === 1;
  const escortVehicles = load.escort || [];
  return (
    <div>
      <ApplicationSectionTitle>Параметры ТС (автопоезда)</ApplicationSectionTitle>

      {!hasLoadFiles && (
        <div className="car-character-block nowrap overflow">
          <ApplicationSchema vehicle={application.vehicle} trailers={application.trailers} />
        </div>
      )}
      <br />
      {hasLoadFiles && (
        <div className="row">
          <div className="col-md-4">Схема расположения груза</div>
          <div className="col-md-8">
            {get(application, 'files.load_files', []).map(({ id, name, url }) => (
              <div key={id}>
                <a target="_blank" href={url}>
                  {name}
                </a>
              </div>
            ))}
          </div>
        </div>
      )}

      <br />
      <ApplicationField value={`${load.weight} т`} label="Общая масса ТС" placeholder="" />
      <ApplicationField
        value={getAxlesLength(axlesInfo)}
        label="Расстояние между осями"
        placeholder=""
      />
      <ApplicationField
        value={additionalLoadInfo.axles_load}
        label="Нагрузка на оси"
        placeholder=""
      />
      {isLoadArray ? (
        <React.Fragment>
          {showStandard && (
            <>
              <ApplicationField
                value={additionalLoadInfo.permissible_load0}
                label="Допустимая нагрузка на оси (10 т/ось)"
                placeholder=""
              />
              <ApplicationField
                value={additionalLoadInfo.permissible_load2}
                label="Допустимая нагрузка на оси (6 т/ось)"
                placeholder=""
              />
              <ApplicationField
                value={additionalLoadInfo.permissible_load1}
                label="Допустимая нагрузка на оси (11,5 т/ось)"
                placeholder=""
              />
            </>
          )}
          {isSpring && showSpring && (
            <React.Fragment>
              <ApplicationField
                value={additionalLoadInfo.permissible_load_spring0}
                label="Допустимая нагрузка на оси (Весна 10 т/ось)"
                placeholder=""
              />
              <ApplicationField
                value={additionalLoadInfo.permissible_load_spring2}
                label="Допустимая нагрузка на оси (Весна 6 т/ось)"
                placeholder=""
              />
              <ApplicationField
                value={additionalLoadInfo.permissible_load_spring1}
                label="Допустимая нагрузка на оси (Весна 11,5 т/ось)"
                placeholder=""
              />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {showStandard && (
            <ApplicationField
              value={additionalLoadInfo.permissible_load}
              label="Допустимая нагрузка на оси"
              placeholder=""
            />
          )}
          {isSpring && showSpring && (
            <ApplicationField
              value={additionalLoadInfo.permissible_load_spring}
              label="Допустимая нагрузка на оси (Весна)"
              placeholder=""
            />
          )}
        </React.Fragment>
      )}
      <ApplicationField value={additionalLoadInfo.wheelsCount} label="Скатность" placeholder="" />
      <ApplicationField
        value={additionalLoadInfo.wheels}
        label="Количество колес"
        placeholder=""
      />
      <div className="row">
        <div className="col-md-3">
          <ApplicationField value={`${load.length} м`} label="Длина" placeholder="" />
        </div>
        <div className="col-md-3">
          <ApplicationField value={`${load.width} м`} label="Ширина" placeholder="" />
        </div>
        <div className="col-md-3">
          <ApplicationField value={`${load.height} м`} label="Высота" placeholder="" />
        </div>
      </div>
      <ApplicationField
        value={dictionaryHelper.escorts.getItemTitle(load ? load.escort_count : 0)}
        label="Вид сопровождения"
        placeholder=""
      />
      {escortVehicles.map((escortVehicle, index) => (
        <div key={escortVehicle.id} className="row text-right">
          <div className="col-md-2 text-left">Автомобиль прикрытия {index + 1}</div>
          <div className="col-md-3">
            <ApplicationField label="Марка" placeholder="" value={escortVehicle.brand} />
          </div>
          <div className="col-md-3">
            <ApplicationField label="Модель" placeholder="" value={escortVehicle.model} />
          </div>
          <div className="col-md-3">
            <ApplicationField label="Модель" placeholder="" value={escortVehicle.number} />
          </div>
          <br />
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  specialConditions: state.specialConditions
});

export default connect(mapStateToProps)(ApplicationTransportsInfo);
