import axios from 'axios';
import { apiService, handleError, handleResponse, handleUrl, prepareConfig } from './apiService';
import { apiConstants } from '../constants/apiConstants';
import { fileConstants } from '../constants/fileConstants';

const uploadInn = values => {
  return apiService.call(apiConstants.METHOD_POST, '/files/upload-inn', values, true);
};

const uploadLicense = values => {
  return apiService.call(apiConstants.METHOD_POST, '/files/upload-license', values, true);
};
const uploadPts = values => {
  return apiService.call(apiConstants.METHOD_POST, '/files/upload-pts', values, true);
};

const uploadLease = values => {
  return apiService.call(apiConstants.METHOD_POST, '/files/upload-lease', values, true);
};

const uploadStatus = values => {
  return apiService.call(apiConstants.METHOD_POST, '/files/upload-status', values, true);
};

const uploadApplicationLoad = values => {
  return apiService.call(apiConstants.METHOD_POST, '/files/upload-load', values, true);
};

const uploadApplicationPay = (application_id, values) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/files/upload-pay/${application_id}`,
    values,
    true
  );
};

const uploadApplicationPayNew = (application_id, file) => {
  const fileInfo = {
    type: fileConstants.TYPE_APPLICATION_PAY,
    myfile: file.file,
    name: file.name
  };
  const url = `/files/upload-pay/${application_id}`;
  return axios
    .post(handleUrl(url), fileInfo, prepareConfig(true, {}))
    .then(handleResponse)
    .catch(handleError);
};

const uploadDocumentFile = (application_id, file) => {
  const data = new FormData();
  data.append('file', file);
  const url = `/files/in-work/${application_id}`;
  return axios
    .post(handleUrl(url), data, prepareConfig(true, {}))
    .then(handleResponse)
    .catch(handleError);
};

const uploadFastPenalty = values => {
  return apiService.call(apiConstants.METHOD_POST, '/files/upload-fast-penalty', values, true);
};

const uploadPenalty = (id, values) => {
  return apiService.call(apiConstants.METHOD_POST, `/files/upload-penalty/${id}`, values, true);
};

const removeFile = id => {
  return apiService.call(apiConstants.METHOD_POST, `/files/remove/${id}`, {}, true);
};

const removeDocumentFile = file => {
  return apiService.call(apiConstants.METHOD_DELETE, `/file/delete/${file.source}`, {}, true);
};

export const fileService = {
  uploadInn,
  uploadLicense,
  uploadPts,
  uploadLease,
  uploadStatus,
  uploadApplicationLoad,
  uploadApplicationPay,
  uploadApplicationPayNew,
  uploadFastPenalty,
  uploadPenalty,
  uploadDocumentFile,
  removeFile,
  removeDocumentFile
};
