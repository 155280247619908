import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'users';
};

const getPage = (page, { role, department, email, name }) => {
  const values = {
    email,
    name,
    role_id: role,
    department_id: department
  };
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}?page=${page}`,
    values,
    true
  );
};

const getAll = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}`, {}, true);
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_PUT, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};

const getMyInfo = () => {
  return apiService.call(apiConstants.METHOD_GET, '/user', {}, true);
};

const saveMyInfo = values => {
  return apiService.call(apiConstants.METHOD_PUT, '/user', values, true);
};

const changePassword = values => {
  return apiService.call(apiConstants.METHOD_PUT, '/user/change-password', values, true);
};

const getFirmUsers = (page = 1, filters = {}) => {
  return apiService.call(apiConstants.METHOD_POST, `/firm-users/page?page=${page}`, filters, true);
};

const createFirmUser = user => {
  return apiService.call(apiConstants.METHOD_PUT, `/firm-users`, user, true);
};

const deleteFirmUser = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/firm-users/${id}`, {}, true);
};

const updateFirmUser = (id, user) => {
  return apiService.call(apiConstants.METHOD_POST, `/firm-users/${id}`, user, true);
};

const updateAccess = (user_id, vehicle_id, access, can_edit) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/firm-users/access`,
    { user_id, vehicle_id, access, can_edit },
    true
  );
};

const getFirmUserFirmInfo = () => {
  return apiService.call(apiConstants.METHOD_GET, '/user/my-firm', {}, true);
};
const getExceptions = () => {
  return apiService.call(apiConstants.METHOD_GET, '/user/exceptions', {}, true);
};

const getFirmFull = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/firm/${id}`, {}, true);
};

const changeEmail = (id, email) => {
  return apiService.call(apiConstants.METHOD_PUT, `/users/${id}`, { email }, true);
};

export const userService = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,

  getMyInfo,
  saveMyInfo,
  changePassword,
  changeEmail,

  getFirmUsers,
  createFirmUser,
  deleteFirmUser,
  updateFirmUser,
  updateAccess,
  getFirmUserFirmInfo,
  getExceptions,

  getFirmFull
};
