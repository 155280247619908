import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import { applicationConstants } from '../../../constants/applicationConstants';
import ApplicationFilterModal from './ApplicationFilterModal';
import { DeclineModal } from './DeclineModal';
import { applicationActions } from '../../../actions/applicationActions';
import { roleConstants } from '../../../constants/roleConstants';
import { departmentActions } from '../../../actions/departmentActions';
import { AgreementDeclineModal } from './agreements/AgreementDeclineModal';
import { ApplicationsTableTab } from './ApplicationsTableTab';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import ReviewContent from './content/ReviewContent';
import AcceptWithChangesContent from './content/AcceptWithChangesContent';
import DeclineContent from './content/DeclineContent';
import AcceptContent from './content/AcceptContent';
import ActiveContent from './content/ActiveContent';
import CompleteContent from './content/CompleteContent';
import RemovedContent from './content/RemovedContent';

const TabContentComponent = {
  [applicationConstants.STATUS_NEW]: ApplicationsTableTab,
  [applicationConstants.STATUS_REVIEW]: ReviewContent,
  [applicationConstants.STATUS_DECLINE]: DeclineContent,
  [applicationConstants.STATUS_ACCEPT_WITH_CHANGES]: AcceptWithChangesContent,
  [applicationConstants.STATUS_ACCEPT]: AcceptContent,
  [applicationConstants.STATUS_ACTIVE]: ActiveContent,
  [applicationConstants.STATUS_COMPLETE]: CompleteContent,
  [applicationConstants.STATUS_REMOVED]: RemovedContent
};

class ApplicationsListPage extends Component {
  constructor(props) {
    super(props);
    this.handleShowAllChange = this.handleShowAllChange.bind(this);
  }

  componentDidMount() {
    const { adminApplications, departments, fetchApplications } = this.props;

    if (!adminApplications.items || adminApplications.items.length === 0) {
      fetchApplications({
        tab: adminApplications.tabIndex,
        per_page: applicationConstants.DEFAULT_PER_PAGE
      });
    }

    if (!departments.items || departments.items.length === 0) {
      this.props.dispatch(departmentActions.getAll());
    }
  }

  handleShowAllChange(e) {
    const { checked } = e.target;
    const showAll = checked ? 1 : 0;
    const { fetchApplications, adminApplications, changeShowAll } = this.props;
    changeShowAll(showAll);
    let tab = adminApplications.tabIndex;
    if (
      !checked &&
      (tab === applicationConstants.STATUS_COMPLETE || tab === applicationConstants.STATUS_REMOVED)
    ) {
      tab = applicationConstants.STATUS_NEW;
    }
    fetchApplications({ tab, showAll });
  }

  render() {
    const {
      adminApplications,
      fetchApplications,
      handleModalOpen,
      authentication,
      handleActivateModalClose,
      activateApplication,
      showAll
    } = this.props;
    const { user } = authentication;
    const { tabIndex } = adminApplications;
    const TabContent = TabContentComponent[tabIndex];

    return (
      <main role="main" className="container text-center">
        <div className="kyc">
          <Grid container alignItems="flex-end" justify="space-between">
            <Grid item>
              <h2 className="h2-title">Заявления</h2>
            </Grid>
            {(user.role_id === roleConstants.ROLE_OFFICER ||
              user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT) && (
              <Grid item>
                <FormControlLabel
                  labelPlacement="start"
                  label={<Typography color="textSecondary">Показать все разрешения</Typography>}
                  control={
                    <Switch
                      color="primary"
                      checked={showAll === 1}
                      onChange={this.handleShowAllChange}
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
          <div className="text-left">
            <Button
              className="no-margin info-btn"
              variant="outlined"
              color="primary"
              type="button"
              onClick={handleModalOpen}
            >
              Фильтровать
            </Button>
            <br />
            <ApplicationFilterModal />
          </div>
          <br />
          <AppBar position="static" color="default" className="tabs_header">
            <Tabs
              value={tabIndex}
              onChange={(event, tab) => fetchApplications({ tab })}
              indicatorColor="primary"
              textColor="primary"
              className="tab_style"
              variant="fullWidth"
            >
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Новые"
                value={applicationConstants.STATUS_NEW}
              />
              <Tab
                classes={{ selected: 'tab_active' }}
                label="В Работе"
                value={applicationConstants.STATUS_REVIEW}
              />
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Отклоненные"
                value={applicationConstants.STATUS_DECLINE}
              />
              {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT && (
                <Tab
                  classes={{ selected: 'tab_active' }}
                  label="Доработанные"
                  value={applicationConstants.STATUS_ACCEPT_WITH_CHANGES}
                />
              )}
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Одобренные"
                value={applicationConstants.STATUS_ACCEPT}
              />
              {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT && (
                <Tab
                  classes={{ selected: 'tab_active' }}
                  label="Активные"
                  value={applicationConstants.STATUS_ACTIVE}
                />
              )}
              {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT &&
                (user.role_id === roleConstants.ROLE_ADMIN || showAll) && (
                  <Tab
                    classes={{ selected: 'tab_active' }}
                    label="Завершенные"
                    value={applicationConstants.STATUS_COMPLETE}
                  />
                )}
              {user.role_id !== roleConstants.ROLE_DEPARTMENT_AGENT &&
                (user.role_id === roleConstants.ROLE_ADMIN || showAll) && (
                  <Tab
                    classes={{ selected: 'tab_active' }}
                    label="Удаленные"
                    value={applicationConstants.STATUS_REMOVED}
                  />
                )}
            </Tabs>
          </AppBar>

          {TabContent && <TabContent />}

          <DeclineModal />
          <AgreementDeclineModal />
          <ConfirmationModal
            title="Подтверждение активации"
            description="Вы уверены, что данное разрешение оплачено Заявителем?"
            handleSubmit={activateApplication}
            isShow={adminApplications.showActivateModal}
            handleClose={handleActivateModalClose}
          />
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { adminApplications, authentication, departments } = state;

  const selector = formValueSelector('application-admin-filter-form');
  const filterData = selector(
    state,
    'id',
    'issue_place_id',
    'form_id',
    'name',
    'number',
    'trailer_number',
    'start_address',
    'finish_address',
    'trip_type',
    'admin_name',
    'sort_column',
    'sort_type',
    'showAll'
  );

  return {
    adminApplications,
    authentication,
    departments,
    filterData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { adminApplications, authentication, departments, filterData } = stateProps;
  const { dispatch } = dispatchProps;

  const fetchApplications = ({ tab, ...props }) => {
    dispatch(applicationActions.setTab(tab));
    dispatch(
      applicationActions.getAdminApplications({
        ...filterData,
        page: 1,
        status: tab,
        department_id: authentication.user.department_id,
        per_page: adminApplications.meta.per_page,
        ...props
      })
    );

    dispatch(change('application-admin-filter-form', 'status', tab));
  };

  const handleModalOpen = () => {
    dispatch(applicationActions.openFilterModal());
  };

  const handleActivateModalClose = () => {
    dispatch(applicationActions.closeActivateModal());
  };

  const activateApplication = () => {
    dispatch(applicationActions.activate(adminApplications.currentItem.id));
  };

  const changeShowAll = showAll => {
    dispatch(change('application-admin-filter-form', 'showAll', showAll));
  };

  return {
    ...ownProps,
    dispatch,
    adminApplications,
    authentication,
    departments,
    showAll: filterData.showAll,
    handleModalOpen,
    fetchApplications,
    changeShowAll,

    handleActivateModalClose,
    activateApplication
  };
};

const connectedApplicationsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationsListPage);
export { connectedApplicationsPage as ApplicationsListPage };
