import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';

import ApvgkReportsPageView from './ApvgkReportsPageView';
import reportsService from '../../../services/reportsService';
import { departmentService } from '../../../services/departmentService';
import { StateContext } from './StateContext';
import { checkEmpty } from '../reports/util';
import {apvgkReportsService} from "../../../services/apvgkReportsService";


const ReportsPage = () => {

  const initialStatisticsFilter = {
      min_date: '',
      max_date: '',
  };
  const [filter, setFilter] = useState(initialStatisticsFilter);


  const statistic = useAsync({
    deferFn: ([a]) => apvgkReportsService.getStatistic(filter),
  });

  const departments = useAsync({ promiseFn: departmentService.getAll });


  const exportState = useAsync({
    deferFn: ([a]) => reportsService.exportReports(a)
  });



  useEffect(() => {
    statistic.run({});
  }, [filter]);

  return (
    <StateContext.Provider value={{ departments, exportState, statistic}}>
      <ApvgkReportsPageView
          statisticsFilter={filter}
          onStatisticsSubmit={setFilter}
          onResetStatistics={() => setFilter(initialStatisticsFilter)}/>
    </StateContext.Provider>
  );
};

export default ReportsPage;
