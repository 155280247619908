import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormTextarea } from '../../../components/form/FormTextarea';
import { Loader } from '../../../components/Loader';

let DeclineForm = props => {
  const { error, handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field name="note" type="textarea" label="Замечания" row="4" component={FormTextarea} />

      {error && <strong>{error}</strong>}
      <div>
        {submitting && <Loader />}
        <button name="action" value="decline" className="btn" type="submit" disabled={submitting}>
          Отклонить
        </button>
      </div>
    </form>
  );
};

DeclineForm = reduxForm({
  form: 'application-decline',
  enableReinitialize: true,
  destroyOnUnmount: false
})(DeclineForm);

DeclineForm = connect(state => ({
  initialValues: {
    id: state.adminApplications.currentItem ? state.adminApplications.currentItem.id : '',
    note: state.adminApplications.currentItem ? state.adminApplications.currentItem.note : '',
    status: 1
  }
}))(DeclineForm);

export default DeclineForm;
