import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

const SignPluginCheck = ({ show, onClose }) => (
  <Dialog open={show} onClose={onClose} aria-labelledby="form-dialog-title">
    <Toolbar className="text-center">
      <Typography variant="title" color="inherit" className="flex">
        Для Рассмотрения заявки требуется КриптоПро ЭЦП Browser plug-in
      </Typography>
      <IconButton color="inherit" onClick={onClose}>
        <CloseIcon className="blue" />
      </IconButton>
    </Toolbar>
    <DialogContent className="dialog">
      Инструкцию по установке Вы можете найти по следующим ссылкам:
      <br />
      <ul>
        <li>
          <a
            href="https://cpdn.cryptopro.ru/default.asp?url=/content/cades/plugin-installation-windows.html"
            target="_blank"
          >
            КриптоПро ЭЦП Browser plug-in в Windows
          </a>
        </li>
        <li>
          <a
            href="https://cpdn.cryptopro.ru/default.asp?url=/content/cades/plugin-installation-unix.html"
            target="_blank"
          >
            КриптоПро ЭЦП Browser plug-in в *nix
          </a>
        </li>
        <li>
          <a
            href="https://cpdn.cryptopro.ru/default.asp?url=/content/cades/plugin-installation-macos.html"
            target="_blank"
          >
            КриптоПро ЭЦП Browser plug-in в Apple macOS
          </a>
        </li>
      </ul>
    </DialogContent>
  </Dialog>
);

export default SignPluginCheck;
