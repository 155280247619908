import React from 'react';

import { MainFooterBlock } from '../main/blocks/MainFooterBlock/MainFooterBlock';
import { CabinetMainMenu } from '../cabinet/blocks/CabinetMainMenu/CabinetMainMenu';
import { CabinetLeftMenu } from '../cabinet/blocks/CabinetLeftMenu/CabinetLeftMenu';
import AdminMainPage from '../admin/main/AdminMainPage';
import { DepartmentRoute } from '../../components/routes/DepartmentRoute';
import ApplicationsLayout from './applications/ApplicationsLayout';
import CooperationApplicationView from './applications/CooperationApplicationView';

const DepartmentLayout = ({ match }) => {
  const links = [
    { key: '1', path: `${match.url}`, title: 'Личный кабинет' },
    {
      key: '4',
      path: `${match.url}/applications`,
      title: 'Модерация заявлений',
      hasChild: true
    }
  ];

  return (
    <div className="">
      <div className="cabinet-header">
        <CabinetMainMenu />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className=" cabinet-left-menu">
              <CabinetLeftMenu links={links} />
            </div>
          </div>

          <div className="col-md-9 cabinet-content">
            <DepartmentRoute exact path={`${match.url}`} component={AdminMainPage} />
            <DepartmentRoute path={`${match.url}/applications`} component={ApplicationsLayout} />
            <DepartmentRoute
              path={`${match.url}/cooperation/view/:id`}
              component={CooperationApplicationView}
            />
          </div>
        </div>
      </div>
      <MainFooterBlock />
    </div>
  );
};

export default DepartmentLayout;
