import React from 'react';
import { Field } from 'formik';
import { range } from 'lodash';
import { IconButton, Grid } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { LoadTypes } from '../../../../helpers/dictionaryHelper';
import FieldLabel from './components/FieldLabel';
import { NameByTripType, TripTypes, Types } from './types';
import MaterialSelect, { convertToOptions } from './components/MaterialSelect';
import MaterialInput from './components/MaterialInput';
import Section from './components/Section';
import { isStatusAvailable } from './utils';
import {FormikAddRemoveFormField} from "./components/FormikAddRemoveNumberField";

const checkStatus = (field, date, values, setFieldValue) => {
  const {
    route: { statusEntity },
    restrictionDates = {}
  } = values;
  if (!statusEntity) {
    return;
  }
  if (!isStatusAvailable({ ...restrictionDates, [field]: date }, statusEntity)) {
    setFieldValue('route.start_date', '');
    setFieldValue('route.finish_date', '');
    setFieldValue('route.route_info', '');
    setFieldValue('route.statusEntity', null);
    setFieldValue('route.status', {});
  }
};

const GeneralInfo = ({ values, setFieldValue }) => (
  <Section title="Общая информация">
    <Grid item>
      <FieldLabel label="Номер РДС" name="rd_number">
        <Field
          name="rd_number"
          component={MaterialInput}
          placeholder="Номер РДС"
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Заявитель*" name="type">
        <Field
          name="type"
          component={MaterialSelect}
          placeholder="Выберите тип заявителя"
          options={[
            { value: Types.Individual, label: 'Физическое лицо' },
            { value: Types.Company, label: 'Юридическое лицо' }
          ]}
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel
        name="name"
        label={values.type === Types.Individual ? 'ФИО заявителя*' : 'Название организации*'}
      >
        <Field name="name" component={MaterialInput} />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Адрес заявителя" name="address">
        <Field name="address" component={MaterialInput} />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="ИНН заявителя" name="inn">
        <Field
          name="inn"
          component={MaterialInput}
          type="number"
          maxLength={values.type === Types.Individual ? 12 : 10}
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Тип поездки*" name="tripType">
        <Field
          name="tripType"
          component={MaterialSelect}
          placeholder="Выберите тип поездки*"
          options={Object.values(TripTypes).map(type => ({
            value: type,
            label: NameByTripType[type]
          }))}
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Период действия разрешения*" name="restrictionDates">
        <Grid container spacing={8} alignItems="center">
          <Grid item xs={1} className="text-left">
            <span>с</span>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="restrictionDates.start"
              component={MaterialInput}
              type="date"
              afterChange={start => checkStatus('start', start, values, setFieldValue)}
              required="required"
            />
          </Grid>
          <Grid item xs={1} className="text-center">
            <span>по</span>
          </Grid>
          <Grid item xs={5}>
            <Field
              name="restrictionDates.end"
              component={MaterialInput}
              type="date"
              afterChange={end => checkStatus('end', end, values, setFieldValue)}
              required="required"
            />
          </Grid>
        </Grid>
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Количество поездок" name="tripCount">
          {values.route.useStatus && values.route.status  > 0
            ? (
              <Field
                  placeholder="Выберите количество поездок"
                  name="tripCount"
                  component={MaterialSelect}
                  options={range(1, 11).map(v => ({ label: v, value: v }))}
              />
          ) : <FormikAddRemoveFormField setFieldValue={setFieldValue} values={values} min={1} max={2000000}/>}
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Характер Груза*" name="loadType">
        <Field
          name="loadType"
          component={MaterialSelect}
          options={convertToOptions(LoadTypes)}
          placeholder="Выберите характер груза"
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Наименование груза" name="loadName">
        <Field name="loadName" component={MaterialInput} />
      </FieldLabel>
    </Grid>
  </Section>
);

export default GeneralInfo;
