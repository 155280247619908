import { applicationConstants } from '../constants/applicationConstants';
import { momentHelper } from './momentHelper';

const MAX_WEIGHT_NO_AGREEMENT = 60;
const MAX_AXLE_WEIGHT_NO_AGREEMENT = 12;
const OVERWEIGHT_DEPARTMENT_ID = 2;

const MAX_LENGTH_AGREEMENT_SINGLE = 12;
const MAX_LENGTH_AGREEMENT_TRAIN = 20;

const MAX_WIDTH_AGREEMENT = 2.55;
const MAX_HEIGHT_AGREEMENT = 4;
const OVERSIZE_DEPARTMENT_ID = 1;

const dateFromStr = dateStr => {
  if (!dateStr) {
    return null;
  }
  const parts = dateStr.split('.');
  const formattedStr = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return Date.parse(formattedStr);
};

const checkDatesValid = application => {
  const nowDate = new Date();
  // let startDate = dateFromStr(application.start_date);
  const finishDate = dateFromStr(application.finish_date) + 60 * 60 * 24 * 1000; // +1 day to include last app's date

  if (finishDate >= nowDate) {
    return true;
  }
  return false;
};

const checkRunsExist = application => {
  const { dates } = application;
  if (!dates) {
    return true;
  }

  return dates.runs_used < dates.runs_count;
};

const needWeightAgreement = application => {
  if (!application.load) {
    return false;
  }
  if (application.load.weight > MAX_WEIGHT_NO_AGREEMENT) {
    return true;
  }

  const axlesInfo = JSON.parse(application.load.axles_info);
  for (let i = 0; i < axlesInfo.length; i++) {
    const axleLoad = parseFloat(axlesInfo[i].axle_load);
    if (!isNaN(axleLoad) && axleLoad > MAX_AXLE_WEIGHT_NO_AGREEMENT) {
      return true;
    }
  }

  return false;
};

const overweightAgreed = application => {
  if (!application.application_agreements) {
    return false;
  }
  for (let i = 0; i < application.application_agreements.length; i++) {
    if (
      application.application_agreements[i].department_id === OVERWEIGHT_DEPARTMENT_ID &&
      application.application_agreements[i].status === applicationConstants.AGREEMENT_ACCEPTED
    ) {
      return true;
    }
  }

  return false;
};

const withRDCNumber = application => application.fast && application.fast.rd_number;
const needSizeAgreement = application => {
  if (!application.load || withRDCNumber(application)) {
    return false;
  }

  let maxLength = MAX_LENGTH_AGREEMENT_SINGLE;
  if (application.trailers && application.trailers.length > 0) {
    maxLength = MAX_LENGTH_AGREEMENT_TRAIN;
  }

  return (
    application.load.length > maxLength ||
    application.load.width > MAX_WIDTH_AGREEMENT ||
    application.load.height > MAX_HEIGHT_AGREEMENT
  );
};

const oversizeAgreed = application => {
  if (!application.application_agreements) {
    return false;
  }
  for (let i = 0; i < application.application_agreements.length; i++) {
    if (
      application.application_agreements[i].department_id === OVERSIZE_DEPARTMENT_ID &&
      application.application_agreements[i].status === applicationConstants.AGREEMENT_ACCEPTED
    ) {
      return true;
    }
  }

  return false;
};

const allAgreementsAccepted = (agreements = []) =>
  agreements &&
  agreements.every(
    ({ status }) =>
      status === applicationConstants.AGREEMENT_ACCEPTED_WITH_CHANGES ||
      status === applicationConstants.AGREEMENT_ACCEPTED
  );

const checkCrossSpring = (application, specialDateItem) => {
  if (!specialDateItem || !application) {
    return false;
  }

  const startDateObj = momentHelper.getDateFromPicker(
    momentHelper.dotFormatToYmd(application.start_date)
  );
  const finishDateObj = momentHelper.getDateFromPicker(
    momentHelper.dotFormatToYmd(application.finish_date)
  );

  const springStartDate = momentHelper.getDateFromPicker(specialDateItem.start_date);
  const springFinishDate = momentHelper.getDateFromPicker(specialDateItem.finish_date);

  return !(startDateObj > springFinishDate || finishDateObj < springStartDate);
};

const checkSpring = (application, specialDateItem) => {
  if (!specialDateItem || !application) {
    return false;
  }
  if (application.is_spring) return true;

  const startDateObj = momentHelper.getDateFromPicker(
    momentHelper.dotFormatToYmd(application.start_date)
  );
  const finishDateObj = momentHelper.getDateFromPicker(
    momentHelper.dotFormatToYmd(application.finish_date)
  );

  const springStartDate = momentHelper.getDateFromPicker(specialDateItem.start_date);
  const springFinishDate = momentHelper.getDateFromPicker(specialDateItem.finish_date);

  return startDateObj >= springStartDate && startDateObj <= springFinishDate;
};

const checkBeforeSpring = (application, specialDateItem) => {
  if (!specialDateItem || !application) {
    return false;
  }

  const startDateObj = momentHelper.getDateFromPicker(
    momentHelper.dotFormatToYmd(application.start_date)
  );
  const finishDateObj = momentHelper.getDateFromPicker(
    momentHelper.dotFormatToYmd(application.finish_date)
  );

  const springStartDate = momentHelper.getDateFromPicker(specialDateItem.start_date);
  const springFinishDate = momentHelper.getDateFromPicker(specialDateItem.finish_date);

  return startDateObj < springStartDate && finishDateObj >= springStartDate;
};

export const applicationHelper = {
  checkDatesValid,
  checkRunsExist,

  needWeightAgreement,
  overweightAgreed,

  needSizeAgreement,
  oversizeAgreed,

  allAgreementsAccepted,

  checkCrossSpring,
  checkSpring,
  checkBeforeSpring,
  withRDCNumber,
};
