import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components/Loader';
import { authActions } from '../../../actions/authActions';
import { FormField } from '../../../components/form/FormField';
import { cabinetActions } from '../../../actions/cabinetActions';
import MuiCabinetPasswordField from '../../../components/form/MuiCabinetPasswordField';

let ChangePasswordForm = props => {
  const { error, handleSubmit, submitting, cabinet } = props;
  return (
    <div>
      <br />
      <p>
        <strong>Сменить пароль</strong>
      </p>
      {cabinet.showPasswordChange && (
        <form onSubmit={handleSubmit}>
          <Field
            name="password_old"
            type="password"
            label="Старый пароль"
            validate={[fieldValidators.required]}
            component={FormField}
          />

          <Field
            name="password"
            label="Новый пароль"
            validate={[
              fieldValidators.required,
              fieldValidators.minLength8,
              fieldValidators.maxLength40
            ]}
            component={MuiCabinetPasswordField}
            showPasswordVisible="true"
            className="cabinet_pass"
          />

          <Field
            name="password_confirmation"
            label="Повторите пароль"
            validate={fieldValidators.passwordsMatch}
            component={MuiCabinetPasswordField}
            showPasswordVisible="true"
            className="cabinet_pass"
          />

          {error && <strong>{error}</strong>}

          <div className="row">
            <div className="text-left">
              <Button className="btn-add" type="submit" disabled={submitting}>
                {submitting && <Loader />} Сменить пароль
              </Button>
            </div>
          </div>
        </form>
      )}
      {cabinet.passwordStatus}
    </div>
  );
};

ChangePasswordForm = reduxForm({
  form: 'cabinet-password'
})(ChangePasswordForm);

const mapStateToProps = state => {
  const { cabinet } = state;
  return {
    cabinet
  };
};
const mapDispatchToProps = dispatch => {
  return {
    togglePasswordVisible: () => {
      dispatch(authActions.togglePasswordVisible());
    }
  };
};

const connectedChangePasswordForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordForm);
export { connectedChangePasswordForm as ChangePasswordForm };
