import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import ApplicationField from './ApplicationField';
import { roleConstants } from '../../../constants/roleConstants';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { urlHelper } from '../../../helpers/urlHelper';
import { getApplicationRoleId } from '../../../selectors/applicationSelectors';

function getVehicleTitle(vehicle) {
  if (!vehicle) {
    return '';
  }
  return `${vehicle.brandTitle}, ${vehicle.modelTitle}, ${vehicle.real_number}`;
}

function getLoadName(load) {
  let loadName = '';
  if (load) {
    loadName = load.name ? `${load.name}, ` : '';
    loadName += dictionaryHelper.loadTypes.getItemTitle(load.type_id);
    if (load.load_weight) {
      loadName += `, ${load.load_weight}т`;
    }
  }
  return loadName;
}

function getName(isQuick, fast, user, employee) {
  return isQuick
    ? get(fast, 'name')
    : `${get(user, 'name', '')}${employee ? ` (${employee.name})` : ''}`;
}

function getUserAddress(isQuick, fast, user) {
  return isQuick ? fast.address : user.firm.address;
}

const ApplicationBaseTransportInfo = ({
  application: { vehicle, trailers, load, is_fast, fast, user, employee },
  role,
  applicationRole
}) => (
  <div>
    <ApplicationField
      label="Транспортное средство"
      placeholder="(Марка, модель, ГРЗ)"
      value={getVehicleTitle(vehicle)}
      link={`${urlHelper.getHomeUrl(role)}/vehicles/info/${vehicle.id}`}
    />
    {trailers &&
      trailers.map(trailer => (
        <ApplicationField
          key={trailer.id}
          label="Прицеп"
          placeholder="(Марка, модель, ГРЗ)"
          value={getVehicleTitle(trailer)}
          link={`${urlHelper.getHomeUrl(role)}/vehicles/info/${get(trailer, 'id')}`}
        />
      ))}
    <ApplicationField
      label="Характеристики груза"
      placeholder="(Наименование, габариты, масса)"
      value={getLoadName(load)}
    />
    <ApplicationField
      label={
        applicationRole === roleConstants.ROLE_INDIVIDUAL
          ? 'ФИО заявителя (физ лицо)'
          : 'Наименование организации владельца транспортного средства'
      }
      placeholder="АО ТАТАВТОДОР АПАСТОВСКИЙ Ф-Л"
      value={getName(is_fast, fast, user, employee)}
    />
    {(applicationRole === roleConstants.ROLE_FIRM || is_fast) && (
      <ApplicationField
        label="Адрес владельца транспортного средства"
        placeholder=""
        value={getUserAddress(is_fast, fast, user)}
      />
    )}
    {!is_fast && (
      <ApplicationField
        label="Телефон владельца транспортного средства"
        placeholder="+79172637435"
        value={user.phone}
      />
    )}
  </div>
);

const mapStateToProps = state => ({
  role: state.authentication.user.role_id,
  applicationRole: getApplicationRoleId(state)
});

export default connect(mapStateToProps)(ApplicationBaseTransportInfo);
