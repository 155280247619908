import { legalFormConstants } from '../constants/legalFormConstants';
import { legalFormService } from '../services/legalFormService';

const getPage = page => {
  const request = page => {
    return { type: legalFormConstants.GETPAGE_REQUEST, page };
  };

  const success = legalForms => {
    return { type: legalFormConstants.GETPAGE_SUCCESS, legalForms };
  };

  const failure = error => {
    return { type: legalFormConstants.GETPAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(page));

    legalFormService
      .getPage(page)
      .then(legalForms => dispatch(success(legalForms)), error => dispatch(failure(error)));
  };
};

const getAll = () => {
  const request = () => {
    return { type: legalFormConstants.GETALL_REQUEST };
  };

  const success = legalForms => {
    return { type: legalFormConstants.GETALL_SUCCESS, legalForms };
  };

  const failure = error => {
    return { type: legalFormConstants.GETALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    legalFormService
      .getAll()
      .then(legalForms => dispatch(success(legalForms)), error => dispatch(failure(error)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: legalFormConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: legalFormConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: legalFormConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    legalFormService
      .delete(id)
      .then(legalForm => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: legalFormConstants.LOAD_REQUEST, id };
  };

  const success = legalForm => {
    return { type: legalFormConstants.LOAD_SUCCESS, legalForm };
  };

  const failure = error => {
    return { type: legalFormConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    legalFormService
      .getItem(id)
      .then(legalForm => dispatch(success(legalForm)), error => dispatch(failure(error)));
  };
};

const create = values => {
  const request = value => {
    return { type: legalFormConstants.CREATE_REQUEST, value };
  };

  const success = legalForm => {
    return { type: legalFormConstants.CREATE_SUCCESS, legalForm };
  };

  return dispatch => {
    dispatch(request(values));

    return legalFormService.create(values).then(legalForm => dispatch(success(legalForm)));
  };
};

const update = values => {
  const request = value => {
    return { type: legalFormConstants.UPDATE_REQUEST, value };
  };

  const success = legalForm => {
    return { type: legalFormConstants.UPDATE_SUCCESS, legalForm };
  };

  return dispatch => {
    dispatch(request(values));

    return legalFormService.update(values).then(legalForm => dispatch(success(legalForm)));
  };
};

const openFormModal = () => {
  return { type: legalFormConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: legalFormConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: legalFormConstants.SET_CURRENT_ITEM, item };
};

export const legalFormActions = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,

  openFormModal,
  closeFormModal,
  setCurrentItem
};
