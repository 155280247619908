import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const resourceName = 'audition';

const getAudits = ({
  fio,
  date_from,
  date_to,
  type,
  app_id,
  real_number,
  event,
  posts,
  per_page,
  page
} = {}) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${resourceName}/list`,
    {
      app_id,
      fio,
      real_number,
      date_from,
      date_to,
      type,
      page,
      per_page,
      event: event.map(({ value }) => value),
      posts: posts.map(({ value }) => value)
    },
    true
  );
};

const exportAudits = ({
  fio,
  date_from,
  date_to,
  type,
  app_id,
  real_number,
  event,
  posts
} = {}) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${resourceName}/export`,
    {
      app_id,
      fio,
      real_number,
      date_from,
      date_to,
      type,
      event: event.map(({ value }) => value),
      posts: posts.map(({ value }) => value)
    },
    true
  );
};

const getEventTypes = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${resourceName}/directory`, {}, true);
};

export default {
  getAudits,
  exportAudits,
  getEventTypes
};
