import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { vehicleActions } from '../../../actions/vehicleActions';
import { vehicleConstants } from '../../../constants/vehicleConstants';
import VehicleCarForm from './VehicleCarForm';

const createSubmit = (values, dispatch) => {
  return dispatch(vehicleActions.create(values));
};

const VehiclesCreatePage = props => {
  const { handleTabChange, cabinetVehicles } = props;

  return (
    <div className="inner-page">
      <h2 className="h2-title">Регистрация транспортных средств</h2>

      <div className="row">
        <div className="col-md-9">
          <div className="tc_type">
            <span
              className={
                cabinetVehicles.typeIndex === vehicleConstants.TAB_CAR
                  ? 'active_tc cursor'
                  : 'cursor'
              }
              onClick={e => {
                handleTabChange(vehicleConstants.TAB_CAR);
              }}
            >
              Транспортное средство
            </span>
            <span
              className={
                cabinetVehicles.typeIndex === vehicleConstants.TAB_TRAILER
                  ? 'active_tc cursor'
                  : 'cursor'
              }
              onClick={e => {
                handleTabChange(vehicleConstants.TAB_TRAILER);
              }}
            >
              Прицеп (полуприцеп)
            </span>
          </div>
        </div>
      </div>
      <VehicleCarForm onSubmit={createSubmit} />
    </div>
  );
};

const mapStateToProps = state => {
  const { cabinetVehicles } = state;
  return {
    cabinetVehicles
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(vehicleActions.resetCurrentItem());

  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { cabinetVehicles } = stateProps;
  const { dispatch } = dispatchProps;

  const handleTabChange = typeIndex => {
    if (typeIndex !== cabinetVehicles.typeIndex) {
      dispatch(vehicleActions.setType(typeIndex));
      dispatch(change('vehicle-car-form', 'is_trailer', typeIndex));

      // reset all values
      resetVehicleCarForm();
    }
  };

  const resetVehicleCarForm = () => {
    dispatch(change('vehicle-car-form', 'brand_id', ''));
    dispatch(change('vehicle-car-form', 'model_id', ''));
    dispatch(change('vehicle-car-form', 'vehicle_type_id', ''));

    dispatch(change('vehicle-car-form', 'is_owner', ''));
    dispatch(change('vehicle-car-form', 'owner_name', ''));

    dispatch(change('vehicle-car-form', 'number', ''));
    dispatch(change('vehicle-car-form', 'regions', ''));
    dispatch(change('vehicle-car-form', 'non_standard_number', ''));
    dispatch(change('vehicle-car-form', 'is_non_standard_number', 0));

    dispatch(change('vehicle-car-form', 'pts_number', ''));
    dispatch(change('vehicle-car-form', 'sts_number', ''));
    dispatch(change('vehicle-car-form', 'pts_weight', ''));
    dispatch(change('vehicle-car-form', 'pts_max_weight', ''));

    dispatch(change('vehicle-car-form', 'length', ''));
    dispatch(change('vehicle-car-form', 'width', ''));
    dispatch(change('vehicle-car-form', 'height', ''));

    dispatch(change('vehicle-car-form', 'axle_count', 2));

    dispatch(change('vehicle-car-form', 'license_files', []));
    dispatch(change('vehicle-car-form', 'pts_files', []));
    dispatch(change('vehicle-car-form', 'lease_files', []));

    for (let i = 0; i <= 5; i++) {
      dispatch(change('vehicle-car-form', `axle_distances[${i}]`, ''));

      dispatch(change('vehicle-car-form', `axle_length[${i}]`, ''));
      dispatch(change('vehicle-car-form', `axle_type[${i}]`, ''));
      dispatch(change('vehicle-car-form', `axle_main[${i}]`, ''));
    }
  };

  return {
    ...ownProps,
    cabinetVehicles,
    handleTabChange
  };
};

const connectedVehiclesCreatePage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VehiclesCreatePage);
export { connectedVehiclesCreatePage as VehiclesCreatePage };
