import { createSelector } from 'reselect';

export const getVehicleBrandsState = state => state.vehicleBrands;

export const getIsVehicleBrandsLoading = createSelector(
  getVehicleBrandsState,
  state => state.loading
);

export const getVehicleBrands = createSelector(
  getVehicleBrandsState,
  state => state.selectItems
);

export const getVehicleBrandsError = createSelector(
  getVehicleBrandsState,
  state => state.error
);

export const getVehicleBrandsFetched = createSelector(
  getVehicleBrandsState,
  state => state.fetched
);
