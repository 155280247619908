import { departmentConstants } from '../constants/departmentConstants';
import { departmentService } from '../services/departmentService';

const getPage = page => {
  const request = page => {
    return { type: departmentConstants.GET_PAGE_REQUEST, page };
  };

  const success = departments => {
    return { type: departmentConstants.GET_PAGE_SUCCESS, departments };
  };

  const failure = error => {
    return { type: departmentConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(page));

    departmentService
      .getPage(page)
      .then(departments => dispatch(success(departments)), error => dispatch(failure(error)));
  };
};

const getAll = () => {
  const request = () => {
    return { type: departmentConstants.GETALL_REQUEST };
  };

  const success = departments => {
    return { type: departmentConstants.GETALL_SUCCESS, departments };
  };

  const failure = error => {
    return { type: departmentConstants.GETALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    departmentService
      .getAll()
      .then(departments => dispatch(success(departments)), error => dispatch(failure(error)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: departmentConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: departmentConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: departmentConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    departmentService.delete(id).then(
      data => {
        if (data.success) {
          dispatch(success(id));
        } else {
          dispatch(failure(id, 'Не может быть удалено!'));
        }
      },
      error => dispatch(failure(id, error))
    );
  };
};

const getItem = id => {
  const request = id => {
    return { type: departmentConstants.LOAD_REQUEST, id };
  };

  const success = department => {
    return { type: departmentConstants.LOAD_SUCCESS, department };
  };

  const failure = error => {
    return { type: departmentConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    departmentService
      .getItem(id)
      .then(department => dispatch(success(department)), error => dispatch(failure(error)));
  };
};

const create = values => {
  const request = value => {
    return { type: departmentConstants.CREATE_REQUEST, value };
  };

  const success = department => {
    return { type: departmentConstants.CREATE_SUCCESS, department };
  };

  return dispatch => {
    dispatch(request(values));

    return departmentService.create(values).then(department => dispatch(success(department)));
  };
};

const update = values => {
  const request = value => {
    return { type: departmentConstants.UPDATE_REQUEST, value };
  };

  const success = department => {
    return { type: departmentConstants.UPDATE_SUCCESS, department };
  };

  return dispatch => {
    dispatch(request(values));

    return departmentService.update(values).then(department => dispatch(success(department)));
  };
};

const openFormModal = () => {
  return { type: departmentConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: departmentConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: departmentConstants.SET_CURRENT_ITEM, item };
};

const closeErrorModal = () => {
  return { type: departmentConstants.CLOSE_ERROR_MODAL };
};

export const departmentActions = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,

  openFormModal,
  closeFormModal,
  setCurrentItem,

  closeErrorModal
};
