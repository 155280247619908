import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import VehiclesForm from './VehiclesForm';
import { vehicleActions } from '../../../actions/vehicleActions';

const DeclineModal = props => {
  const { handleDeclineClose, adminVehicles, declineSubmit } = props;

  return (
    <Dialog
      open={adminVehicles.showDeclineModal}
      onClose={handleDeclineClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Отклонение заявки
        </Typography>
        <IconButton color="inherit" onClick={handleDeclineClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        {adminVehicles && adminVehicles.currentItem && (
          <VehiclesForm currentItem={adminVehicles.currentItem} onSubmit={declineSubmit} />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { adminVehicles, authentication } = state;
  return {
    adminVehicles,
    authentication
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleDeclineClose: () => {
      dispatch(vehicleActions.closeDeclineModal());
    }
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { authentication } = stateProps;
  const { user } = authentication;

  const declineSubmit = (values, dispatch) => {
    return dispatch(vehicleActions.decline(values, user.role_id));
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,

    declineSubmit
  };
};

const connectedDeclineModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DeclineModal);
export { connectedDeclineModal as DeclineModal };
