import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field, Form, Formik } from 'formik';
import MaterialInput from './CreateQuickApplication/components/MaterialInput';
import FieldLabel from './CreateQuickApplication/components/FieldLabel';
import MaterialSelect from './CreateQuickApplication/components/MaterialSelect';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';

const Filter = ({ currentFilters, onFiltersChange }) => {
  const [open, setOpen] = useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const onCancel = () => {
    handleClose();
  };

  return (
    <div>
      <Button
        className="no-margin info-btn"
        variant="outlined"
        color="primary"
        type="button"
        onClick={handleClickOpen}
      >
        Фильтровать
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <Toolbar>
          <Typography variant="title" color="inherit" className="flex">
            Фильтровать
          </Typography>
          <IconButton color="inherit" onClick={onCancel}>
            <CloseIcon className="blue" />
          </IconButton>
        </Toolbar>
        <DialogContent>
          <Formik
            initialValues={currentFilters}
            onSubmit={values => {
              onFiltersChange(values);
              handleClose();
            }}
            validateOnChange={false}
            validateOnBlur={false}
            render={({ values, setFieldValue }) => (
              <Form>
                <Grid container direction="column" spacing={16} wrap="nowrap">
                  <Grid item>
                    <FieldLabel withoutError label="Номер РДС">
                      <Field name="rd_number" component={MaterialInput} placeholder="Номер РДС" />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="Номер заявления">
                      <Field name="id" component={MaterialInput} placeholder="Номер заявления" />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="Территориальное управление">
                      <Field
                        placeholder="Территориальное управление"
                        name="issue_place_id"
                        component={MaterialSelect}
                        options={dictionaryHelper.issuePlaces.getList().map(option => ({
                          value: option.id,
                          label: option.title
                        }))}
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="Номер бланка">
                      <Field name="form_id" component={MaterialInput} placeholder="Номер бланка" />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="Фио заявителя / Название компании">
                      <Field
                        name="name"
                        component={MaterialInput}
                        placeholder="Фио заявителя / Название компании"
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="ГРЗ ТС">
                      <Field name="number" component={MaterialInput} placeholder="ГРЗ ТС" />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="ГРЗ прицепа">
                      <Field
                        name="trailer_number"
                        component={MaterialInput}
                        placeholder="ГРЗ прицепа"
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="Начальная точка маршрута">
                      <Field
                        name="start_address"
                        component={MaterialInput}
                        placeholder="Начальная точка маршрута"
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="Конечная точка маршрута">
                      <Field
                        name="finish_address"
                        component={MaterialInput}
                        placeholder="Конечная точка маршрута"
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="Тип поездки">
                      <Field
                        placeholder="Тип поездки"
                        name="trip_type"
                        component={MaterialSelect}
                        options={dictionaryHelper.applicationTypes.getList().map(option => ({
                          value: option.id,
                          label: option.title
                        }))}
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <FieldLabel withoutError label="ФИО сотрудника, взявшего заявку в работу">
                      <Field
                        name="admin_name"
                        component={MaterialInput}
                        placeholder="ФИО сотрудника, взявшего заявку в работу"
                      />
                    </FieldLabel>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className="btn-add no-margin"
                      type="submit"
                    >
                      Применить
                    </Button>
                    <Button
                      variant="outlined"
                      component="span"
                      className="info-btn"
                      onClick={onCancel}
                    >
                      Очистить
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Filter;
