import { axleLoadConstants } from '../constants/axleLoadConstants';
import { axleLoadService } from '../services/axleLoadService';

const getPage = (page, tabIndex) => {
  const request = page => {
    return { type: axleLoadConstants.GET_PAGE_REQUEST, page };
  };

  const success = axleLoads => {
    return { type: axleLoadConstants.GET_PAGE_SUCCESS, axleLoads };
  };

  const failure = error => {
    return { type: axleLoadConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(page));

    axleLoadService
      .getPage(page, tabIndex)
      .then(axleLoads => dispatch(success(axleLoads)), error => dispatch(failure(error)));
  };
};

const getAll = () => {
  const request = () => {
    return { type: axleLoadConstants.GET_ALL_REQUEST };
  };

  const success = axleLoads => {
    return { type: axleLoadConstants.GET_ALL_SUCCESS, axleLoads };
  };

  const failure = error => {
    return { type: axleLoadConstants.GET_ALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    axleLoadService
      .getAll()
      .then(axleLoads => dispatch(success(axleLoads)), error => dispatch(failure(error)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: axleLoadConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: axleLoadConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: axleLoadConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    axleLoadService
      .delete(id)
      .then(axleLoad => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: axleLoadConstants.LOAD_REQUEST, id };
  };

  const success = axleLoad => {
    return { type: axleLoadConstants.LOAD_SUCCESS, axleLoad };
  };

  const failure = error => {
    return { type: axleLoadConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    axleLoadService
      .getItem(id)
      .then(axleLoad => dispatch(success(axleLoad)), error => dispatch(failure(error)));
  };
};

const create = values => {
  const request = value => {
    return { type: axleLoadConstants.CREATE_REQUEST, value };
  };

  const success = axleLoad => {
    return { type: axleLoadConstants.CREATE_SUCCESS, axleLoad };
  };

  return dispatch => {
    dispatch(request(values));

    return axleLoadService.create(values).then(axleLoad => dispatch(success(axleLoad)));
  };
};

const update = values => {
  const request = value => {
    return { type: axleLoadConstants.UPDATE_REQUEST, value };
  };

  const success = axleLoad => {
    return { type: axleLoadConstants.UPDATE_SUCCESS, axleLoad };
  };

  return dispatch => {
    dispatch(request(values));

    return axleLoadService.update(values).then(axleLoad => dispatch(success(axleLoad)));
  };
};

const openFormModal = () => {
  return { type: axleLoadConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: axleLoadConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: axleLoadConstants.SET_CURRENT_ITEM, item };
};

export const axleLoadActions = {
  getPage,
  getAll,
  getItem,
  update,

  openFormModal,
  closeFormModal,
  setCurrentItem
};
