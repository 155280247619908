import React from 'react';
import { connect } from 'react-redux';
import { isNil } from 'lodash';

function check(allowedArray, disallowedArray, allowed, value) {
  let isValid = true;
  if (allowedArray.length > 0) {
    isValid = isValid && allowedArray.includes(value);
  }
  if (!isNil(allowed)) {
    isValid = isValid && allowed === value;
  }
  if (disallowedArray.length > 0) {
    isValid = isValid && !disallowedArray.includes(value);
  }
  return isValid;
}

const ShowByRole = ({
  disallowedRoles = [],
  allowedRoles = [],
  allowedRole = null,
  additionalCondition = true,
  role,
  children
}) => {
  if (!role) {
    return null;
  }
  const isValid = check(allowedRoles, disallowedRoles, allowedRole, role);
  if (!isValid || !additionalCondition) {
    return null;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const mapStateToProps = state => ({
  role:
    state && state.authentication && state.authentication.user
      ? state.authentication.user.role_id
      : null
});

export default connect(mapStateToProps)(ShowByRole);
