import React, { Component } from 'react';
import { change, Field, formValueSelector, reduxForm, FieldArray} from 'redux-form';
import { connect } from 'react-redux';
import {get, uniqueId} from 'lodash';

import { FormField } from '../../../../components/form/FormField';
import { FormFieldReg } from '../../../../components/form/FormFieldReg';
import { fieldValidators } from '../../../../helpers/fieldValidators';
import { Loader } from '../../../../components/Loader';
import { privilegeStatusActions } from '../../../../actions/privilegeStatusActions';
import { momentHelper } from '../../../../helpers/momentHelper';
import { fileConstants } from '../../../../constants/fileConstants';
import { FormTextarea } from '../../../../components/form/FormTextarea';
import FileUploader from '../../../../components/form/FileUploader';
import { privilegeStatusConstants } from '../../../../constants/privilegeStatusConstants';
import { createFileUploadHandler } from '../../../../helpers/fileUploadHelper';
import {renderSelectFieldNoLabel} from "../../../../components/form/renderSelectFieldNoLabel";
import IconButton from "@material-ui/core/IconButton";
import {Remove} from "@material-ui/icons";
import {apvgkService} from "../../../../services/apvgkService";
import ApvgkPlatformsFieldComponent from "./ApvgkPlatformsFieldComponent";

class StatusForm extends Component {
  constructor() {
    super();
    this.state = {
      apvgkList: [],
    }
    if (this.state.apvgkList.length == 0) {
      apvgkService.getPage(1, 1000).then((r) => {
        this.setState({
          apvgkList: r.data,
        })
      });
    }
  }
  render() {
    const {
      privilegeStatuses,
      error,
      handleSubmit,
      submitting,
      currentItem,
      handleFileUpload,
      handleFileRemove,
      files
    } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <Field
              className="form-control"
              name="title"
              component={FormFieldReg}
              placeholder="Название статуса"
              validate={[fieldValidators.required]}
            />
          </div>
          <div className="col-md-12">
            <Field
              className="form-control"
              name="route_info"
              component={FormTextarea}
              label="Описание маршрута"
              validate={[fieldValidators.required]}
            />
          </div>

          <FileUploader
            className="col-md-10"
            entity="privilege"
            inputClassName="file_input"
            buttonText="Прикрепить документ"
            fileList={files}
            handleFileChange={handleFileUpload}
            handleFileRemove={handleFileRemove}
            hasError={privilegeStatuses.uploadFileError}
            uploaders={privilegeStatuses.fileUploaders}
            multiple
          />

          <div className="col-md-6">
            <Field
              name="start_date"
              type="date"
              label="Дата начала"
              placeholder="дд.мм.гггг"
              validate={[fieldValidators.required]}
              component={FormField}
            />
          </div>
          <div className="col-md-6">
            <Field
              name="finish_date"
              type="date"
              label="Дата окончания"
              placeholder="дд.мм.гггг"
              validate={[fieldValidators.required]}
              component={FormField}
            />
          </div>


          {!currentItem|| !currentItem.id ?
              <div className="col-md-6">
                <Field
                    name="coefficient"
                    type="number"
                    label="Коэффициент вреда"
                    placeholder="0,5"
                    validate={[fieldValidators.required, fieldValidators.minValue0, fieldValidators.maxValue1]}
                    component={FormField}
                />
              </div> : null
          }
          <div className="col-md-12">
            <FieldArray name="apvgk_list" component={ApvgkPlatformsFieldComponent} apvgkList={this.state.apvgkList} twoDirections={true}/>
          </div>
        </div>
        <br />

        {error && <strong>{error}</strong>}
        <div>
          {submitting && <Loader />}
          <button type="submit" className="btn" disabled={submitting}>
            {currentItem && currentItem.id ? 'Редактировать' : 'Создать'}
          </button>
        </div>
      </form>
    );
  }
}

StatusForm = reduxForm({
  form: 'privilege-status-form', // a unique identifier for this form
  enableReinitialize: true
})(StatusForm);

const mapStateToProps = state => {
  const { privilegeStatuses } = state;
  const { currentItem } = privilegeStatuses;

  const selector = formValueSelector('privilege-status-form');
  const files = selector(state, 'files');

  return {
    privilegeStatuses,
    files,
    initialValues: {
      id: currentItem ? currentItem.id : '',
      title: currentItem ? currentItem.title : '',
      route_info: currentItem ? currentItem.route_info : '',
      user_id: currentItem ? currentItem.user_id : '',
      coefficient: currentItem ? currentItem.coefficient : 0,
      apvgk_list: (currentItem ? currentItem.apvgk || [] : []).map(a => {
        return {
          id: a.id,
          direction: a.pivot ? a.pivot.direction: undefined
        }
      }),
      start_date: currentItem
        ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.start_date)
        : '',
      finish_date: currentItem
        ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.finish_date)
        : '',
      files: get(currentItem, 'files.privilege_status_files', [])
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { privilegeStatuses, initialValues, files } = stateProps;
  const { dispatch } = dispatchProps;

  const handleFileUpload = createFileUploadHandler(
    dispatch,
    fileConstants.TYPE_STATUS,
    privilegeStatusActions.uploadFile,
    { type: privilegeStatusConstants.FILE_UPLOAD_FAILURE }
  );
  const handleFileRemove = id => {
    // dispatch(privilegeStatusActions.removeFile(id));
    const newFiles = files.filter(item => item.id !== id);
    dispatch(change('privilege-status-form', 'files', newFiles));
  };

  return {
    ...ownProps,

    privilegeStatuses,
    initialValues,
    files,

    dispatch,

    handleFileUpload,
    handleFileRemove
  };
};

StatusForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(StatusForm);
export default StatusForm;
