import { authConstants } from '../constants/authConstants';

export const registration = (
  state = { showRegisterStep1: false, showRegisterStep2: false },
  action
) => {
  switch (action.type) {
    case authConstants.REGISTER_REQUEST:
      return {
        ...state,
        registering: true
      };
    case authConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registering: false
      };
    case authConstants.REGISTER_FAILURE:
      return {};

    // Register modals
    case authConstants.REGISTER_STEP1_OPEN:
      return {
        ...state,
        showRegisterStep1: true
      };
    case authConstants.REGISTER_STEP1_CLOSE:
      return {
        ...state,
        showRegisterStep1: false
      };
    case authConstants.REGISTER_STEP2_OPEN:
      return {
        ...state,
        showRegisterStep2: true
      };
    case authConstants.REGISTER_STEP2_CLOSE:
      return {
        ...state,
        showRegisterStep2: false
      };
    default:
      return state;
  }
};
