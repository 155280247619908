import React, { useState, useContext } from 'react';
import { get, isEmpty, range, sum } from 'lodash';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCellMaterial from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';

import { Loader } from '../../../components';
import { StateContext } from './StateContext';
import {apvgkReportsConstants} from "../../../constants/apvgkReportsConstants";
import Filter from "./Filter";
import {ApvgkReportsListPage} from "../../apvgk/reports/ApvgkReportsListPage";
import {apiService} from "../../../services/apiService";
import {apiConstants} from "../../../constants/apiConstants";
import DateFilter from "../../apvgk/reports/DateFilter";

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

const doDum = rows => Math.floor(sum(rows) * 100) / 100;


const StatisticsRow = ({ data, name }) => (
  <TableRow>
    <TableCellMaterial>{data.name}</TableCellMaterial>
    <TableCellMaterial>{data.total}</TableCellMaterial>
    <TableCellMaterial>{data.stat[apvgkReportsConstants.STATUS_NEW]}</TableCellMaterial>
    <TableCellMaterial>{data.stat[apvgkReportsConstants.STATUS_IN_WORK]}</TableCellMaterial>
    <TableCellMaterial>{data.stat[apvgkReportsConstants.STATUS_DECLINE]}</TableCellMaterial>
    <TableCellMaterial>{data.stat[apvgkReportsConstants.STATUS_ACCEPT]}</TableCellMaterial>
  </TableRow>
);

const ApvgkReportsPageView = ({
  classes,
  statisticsFilter,
  onStatisticsSubmit
}) => {
  const { reportsState, statistic } = useContext(StateContext);
  let result = {name:'Итого', stat:[0,0,0,0], total: 0};
  if (statistic && statistic.data) statistic.data.data.map((v) => {
    v.stat.map((v,i) => result.stat[i] += v)
    result.total += v.total;
  })
  return (
    <main role="main" className="container full-height">
      <Grid container direction="column" spacing={16}>
        <Grid item container spacing={16} style={{padding: 25}}>
          <Grid item>
            <h2 className="h2-title no-margin">Статистика</h2>
          </Grid>
          <Grid item>
              <Grid container direction="column" spacing={16}>
                  <Grid item>
                    <Filter currentFilters={statisticsFilter} onFiltersChange={onStatisticsSubmit}/>
                  </Grid>
              </Grid>
          </Grid>
          <Grid item>
              <Grid container direction="column" spacing={16}>
                  <Grid item>
                    <DateFilter onFiltersChange={(params) => {
                        return apiService.downloadFile(`${apiConstants.API_URL}/apvgk/stat/daily?`+(new URLSearchParams(params)).toString(), `Отчет АСВГК.xlsx`)
                    }}/>
                  </Grid>
              </Grid>
          </Grid>
          <Grid item>
              <Grid container direction="column" spacing={16}>
                  <Grid item>
                    <DateFilter title="Месячный отчет" onFiltersChange={(params) => {
                        return apiService.downloadFile(`${apiConstants.API_URL}/apvgk/stat/month?`+(new URLSearchParams(params)).toString(), `Мониторинг работы АПВГК.xlsx`)
                    }}/>
                  </Grid>
              </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={16}>
              <Grid item>
                  <Link

                      color="primary"
                      to={{}}
                      onClick={() => {
                      return apiService.downloadFile(`${apiConstants.API_URL}/apvgk/stat`, `Статистика обработки.xlsx`)
                  }}
                  >
                      Скачать отчет
                  </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Paper className={classes.root}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellMaterial>АПВГК</TableCellMaterial>
                    <TableCellMaterial>Количество актов</TableCellMaterial>
                    <TableCellMaterial>Новые</TableCellMaterial>
                    <TableCellMaterial>В работе</TableCellMaterial>
                    <TableCellMaterial>Отклоненные</TableCellMaterial>
                    <TableCellMaterial>Одобренные</TableCellMaterial>
                  </TableRow>
                </TableHead>
                <TableBody style={{ position: 'relative', minHeight: 200 }}>
                  {statistic.isLoading || !statistic.isSettled ? (
                    <TableRow>
                      <TableCellMaterial colSpan={10} align="center" style={{ padding: 32 }}>
                        <Loader size={45} />
                      </TableCellMaterial>
                    </TableRow>
                  ) : (
                    <React.Fragment>
                      {statistic.data.data.map((v, i) => (<StatisticsRow key={i} data={v}/>))}
                      {statistic.data.data.length > 0 && (<StatisticsRow key="-1" data={result}/>)}
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

      </Grid>
      <Grid item>
        <ApvgkReportsListPage/>
      </Grid>
    </main>
  );
};

export default withStyles(styles)(ApvgkReportsPageView);
