import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useAsync } from 'react-async';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { get } from 'lodash';

import InfoContent from '../../admin/cooperation/content/ApplicationDetails/InfoContent';
import RequestProcessing from '../../admin/cooperation/content/ApplicationDetails/RequestProcessing';
import TransportSettings from '../../admin/cooperation/content/ApplicationDetails/TransportSettings';
import { CooperationCompletedStatuses } from '../../../constants/cooperationConstants';
import { applicationService } from '../../../services/applicationService';
import { Loader } from '../../../components';
import { specialConditionService } from '../../../services/specialConditionService';
import { isContainSpring } from '../../admin/quickApplications/CreateQuickApplication/utils';
import ControlMarksBlock from './ControlMarksBlock';

const styles = () => ({
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

const CooperationViewPage = ({ match, classes, enqueueSnackbar }) => {
  const { id } = match.params;
  const [isSpring, setSpring] = useState(false);

  const {
    data: specialConditions,
    isRejected: conditionsRejected,
    isLoading: conditionsLoading,
    isResolved: conditionsResolved
  } = useAsync({ promiseFn: specialConditionService.getItem, id: 1 });
  const {
    data: response,
    isRejected: dataRejected,
    isLoading: dataLoading,
    isResolved: dataResolved,
    reload: reloadData
  } = useAsync({ promiseFn: applicationService.getApplication, id });
  const isCompleted =
    response && response.data && CooperationCompletedStatuses.includes(response.data.status);

  useEffect(() => {
    if (response && specialConditions) {
      const { start_date, finish_date, is_spring } = response.data;
      if (isCompleted) {
        setSpring(is_spring === 1);
      } else {
        setSpring(
          isContainSpring(
            {
              start: moment(start_date, 'DD.MM.YYYY').toISOString(),
              end: moment(finish_date, 'DD.MM.YYYY').toISOString()
            },
            specialConditions.data
          )
        );
      }
    }
  }, [response, specialConditions, isCompleted]);

  return (
    <main role="main" className="container">
      {dataResolved && conditionsResolved && (
        <Grid container direction="column" spacing={16}>
          <InfoContent application={response.data} />
          <TransportSettings
            application={response.data}
            isSpring={isSpring}
            isCompleted={isCompleted}
          />
          <RequestProcessing application={response.data} withoutMarks />
          <ControlMarksBlock
            controlMarks={get(response, 'data.control_marks', [])}
            applicationId={get(response, 'data.id')}
            handleAddControlMark={reloadData}
          />
        </Grid>
      )}
      {(dataLoading || conditionsLoading) && (
        <div className={classes.loader}>
          <Loader size={45} />
        </div>
      )}
    </main>
  );
};

export default compose(
  withStyles(styles),
  withSnackbar
)(CooperationViewPage);
