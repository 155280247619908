import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Field, Form, Formik} from 'formik';
import MaterialInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput';
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';


const DateFilter = ({onFiltersChange, title}) => {
    const [open, setOpen] = useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    useEffect(() => {
    }, [])
    return (
        <div>
            <Link

                color="primary"
                to={{}}
                onClick={handleClickOpen}
            >
                {!!title ? title : "Ежедневный отчет"}
            </Link>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Фильтровать
                    </Typography>
                    <IconButton color="inherit" onClick={handleClose}>
                        <CloseIcon className="blue" />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <Formik
                        onSubmit={values => {
                            onFiltersChange(values);
                            handleClose();
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        render={({ values, setFieldValue }) => (
                            <Form>
                                <Grid container direction="column" spacing={16} wrap="nowrap">
                                    <Grid item>
                                        <FieldLabel label="Дата" name="min_date">
                                            <Field
                                                name="date"
                                                min="2022-06-01"
                                                component={MaterialInput}
                                                type="date"
                                            />
                                        </FieldLabel>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="btn-add no-margin"
                                            type="submit"
                                        >
                                            Cкачать
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DateFilter;
