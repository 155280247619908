import React from 'react';
import { OfficerRoute } from '../../../components/routes/OfficerRoute';
import { RegAppsListPage } from '../../admin/regApps/RegAppsListPage';
import RegAppsFirmPage from '../../admin/regApps/RegAppsFirmPage';

const RegAppsLayout = ({ match }) => (
  <div>
    <OfficerRoute exact path={`${match.url}/`} component={RegAppsListPage} />
    <OfficerRoute path={`${match.url}/firm/:id`} component={RegAppsFirmPage} />
  </div>
);

export default RegAppsLayout;
