import React from 'react';
import { ApplicationMyVehiclesModal } from './ApplicationMyVehiclesModal';
import { applicationActions } from '../../../../../actions/applicationActions';
import { ApplicationStepVehicleForm } from './ApplicationStepVehicleForm';
import { urlHelper } from '../../../../../helpers/urlHelper';

const vehicleSubmit = (values, dispatch) => {
  return dispatch(applicationActions.saveVehicleStep(values));
};

const ApplicationStepVehicle = props => {
  const { match } = props;

  const id = urlHelper.getIdFromApplicationStepUrl(match.path);

  return (
    <main role="main" className="container">
      <ApplicationStepVehicleForm id={id} onSubmit={vehicleSubmit} />
      <ApplicationMyVehiclesModal />
    </main>
  );
};

export default ApplicationStepVehicle;
