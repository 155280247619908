import React from 'react';
import { DepartmentBlock } from './DepartmentBlock';
import { DepartmentErrorModal } from './DepartmentDeleteErrorModal';

const DepartmentsPage = props => {
  return (
    <div>
      <DepartmentBlock />
      <DepartmentErrorModal />
    </div>
  );
};

export default DepartmentsPage;
