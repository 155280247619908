import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Grid, Button } from '@material-ui/core';
import Select from 'react-select';
import FieldLabel from '../reports/components/FieldLabel';
import MaterialInput from '../quickApplications/CreateQuickApplication/components/MaterialInput';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { roleConstants } from '../../../constants/roleConstants';

const ROLE_OPTIONS = dictionaryHelper.userRoles.getAdminList().map(({ id, title }) => ({
  value: id,
  label: title
}));

const UsersFilterForm = ({ initialValues, departments, user, onSubmit, onReset, onClose }) => {
  const DEPARTMENTS_OPTIONS = departments.items.map(({ id, title }) => ({
    value: id,
    label: title
  }));
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={filters => {
        onSubmit(filters);
        onClose();
      }}
      render={({ values, setFieldValue }) => (
        <Form>
          <Grid container direction="column" spacing={16} wrap="nowrap">
            <Grid item>
              <FieldLabel label="Роль пользователя">
                <Field
                  placeholder="Выберите роль пользователя"
                  name="role"
                  component={Select}
                  isClearable
                  value={ROLE_OPTIONS.find(({ value }) => value === values.role)}
                  options={ROLE_OPTIONS}
                  onChange={v => setFieldValue('role', v.value)}
                />
              </FieldLabel>
            </Grid>
            {user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT && (
              <Grid item>
                <FieldLabel label="Ведомство">
                  <Field
                    placeholder="Выберите ведомство"
                    name="department"
                    component={Select}
                    isClearable
                    value={DEPARTMENTS_OPTIONS.find(({ value }) => value === values.role)}
                    options={DEPARTMENTS_OPTIONS}
                    onChange={v => setFieldValue('department', v.value)}
                  />
                </FieldLabel>
              </Grid>
            )}
            <Grid item>
              <FieldLabel label="По ФИО" name="name">
                <Field name="name" component={MaterialInput} placeholder="По ФИО" />
              </FieldLabel>
            </Grid>
            <Grid item>
              <FieldLabel label="Email" name="email">
                <Field name="email" component={MaterialInput} placeholder="По email" />
              </FieldLabel>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="btn-add no-margin"
                type="submit"
              >
                Применить
              </Button>
              &nbsp;
              <Button variant="outlined" component="span" className="info-btn" onClick={onReset}>
                Сбросить
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    />
  );
};

export default UsersFilterForm;
