import React from 'react';
import Grid from '@material-ui/core/Grid';

import FieldLabel from '../../components/FieldLabel';
import Input from '../../components/Input';
import { mapTransport } from '../../util/mapping';

const TransportSettings = ({ application, isSpring }) => {
  const { axlesData, ...transport } = mapTransport(application);
  return (
    <>
      <Grid item>
        <h2 className="h3-title">Параметры ТС</h2>
      </Grid>
      <Grid item>
        <Grid container spacing={16}>
          <Grid item xs={12} md={3}>
            <FieldLabel label="Длина" labelProps={{ xs: 5 }} childrenProps={{ xs: 7 }}>
              <Input value={transport.length} />
            </FieldLabel>
          </Grid>
          <Grid item xs={12} md={3}>
            <FieldLabel label="Ширина" labelProps={{ xs: 5 }} childrenProps={{ xs: 7 }}>
              <Input value={transport.width} />
            </FieldLabel>
          </Grid>
          <Grid item xs={12} md={3}>
            <FieldLabel label="Высота" labelProps={{ xs: 5 }} childrenProps={{ xs: 7 }}>
              <Input value={transport.height} />
            </FieldLabel>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Общая масса ТС"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 7 }}
        >
          <Input value={transport.weight} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Расстояние между осями"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 7 }}
        >
          <Input value={axlesData.axlesDistances} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Скатность"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 7 }}
        >
          <Input value={axlesData.wheelsCount} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Тип подвески"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 7 }}
        >
          <Input value={axlesData.type} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Количество колес"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 7 }}
        >
          <Input value={axlesData.wheels} />
        </FieldLabel>
      </Grid>
      {axlesData.permissibleLoads ? (
        <>
          <Grid item>
            <FieldLabel
              label="Допустимая нагрузка на оси (10 т/ось)"
              labelProps={{ xs: 12, sm: 4 }}
              childrenProps={{ xs: 12, sm: 8, lg: 5 }}
            >
              <Input value={axlesData.permissibleLoads[0]} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Допустимая нагрузка на оси (6 т/ось)"
              labelProps={{ xs: 12, sm: 4 }}
              childrenProps={{ xs: 12, sm: 8, lg: 5 }}
            >
              <Input value={axlesData.permissibleLoads[1]} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Допустимая нагрузка на оси (11,5 т/ось)"
              labelProps={{ xs: 12, sm: 4 }}
              childrenProps={{ xs: 12, sm: 8, lg: 5 }}
            >
              <Input value={axlesData.permissibleLoads[2]} />
            </FieldLabel>
          </Grid>
          {isSpring && axlesData.permissibleLoadsSpring && (
            <>
              <Grid item>
                <FieldLabel
                  label="Допустимая нагрузка на оси (Весна 10 т/ось)"
                  labelProps={{ xs: 12, sm: 4 }}
                  childrenProps={{ xs: 12, sm: 8, lg: 5 }}
                >
                  <Input value={axlesData.permissibleLoadsSpring[0]} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Допустимая нагрузка на оси (Весна 6 т/ось)"
                  labelProps={{ xs: 12, sm: 4 }}
                  childrenProps={{ xs: 12, sm: 8, lg: 5 }}
                >
                  <Input value={axlesData.permissibleLoadsSpring[1]} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Допустимая нагрузка на оси (Весна 11,5 т/ось)"
                  labelProps={{ xs: 12, sm: 4 }}
                  childrenProps={{ xs: 12, sm: 8, lg: 5 }}
                >
                  <Input value={axlesData.permissibleLoadsSpring[2]} />
                </FieldLabel>
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          <Grid item>
            <FieldLabel
              label="Допустимая нагрузка на оси"
              labelProps={{ xs: 12, sm: 4 }}
              childrenProps={{ xs: 12, sm: 8, lg: 5 }}
            >
              <Input value={axlesData.permissibleLoad} />
            </FieldLabel>
          </Grid>
          {isSpring && (
            <Grid item>
              <FieldLabel
                label="Допустимая нагрузка на оси (Весна)"
                labelProps={{ xs: 12, sm: 4 }}
                childrenProps={{ xs: 12, sm: 8, lg: 5 }}
              >
                <Input value={axlesData.permissibleLoadSpring} />
              </FieldLabel>
            </Grid>
          )}
        </>
      )}
      <Grid item>
        <FieldLabel
          label="Нагрузка на оси"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 7 }}
        >
          <Input value={axlesData.axlesLoad} />
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel
          label="Вид сопровождения"
          labelProps={{ xs: 12, sm: 4, lg: 2 }}
          childrenProps={{ xs: 12, sm: 8, lg: 7 }}
        >
          <Input value={transport.escort} />
        </FieldLabel>
      </Grid>
      {transport.files.length > 0 && (
        <Grid item>
          <FieldLabel
            label="Документы"
            labelProps={{ xs: 12, sm: 4, lg: 2 }}
            childrenProps={{ xs: 12, sm: 8, lg: 7 }}
          >
            <Grid container spacing={8}>
              {transport.files.map(file => (
                <Grid item key={file.url}>
                  <a target="_blank" href={file.url}>
                    {file.name}
                  </a>
                </Grid>
              ))}
            </Grid>
          </FieldLabel>
        </Grid>
      )}
    </>
  );
};

export default TransportSettings;
