import {paymentsConstants} from '../constants/paymentsConstants';
import {authConstants} from '../constants/authConstants'

const createItem = (items, item) => {
    items.unshift(item);
    return items;
};
const updateItem = (items, item) => {
    const result = [];
    for (let i = 0; i < items.length; i++) {
        if (items[i].id !== item.id) {
            result.push(items[i]);
        } else {
            result.push(item);
        }
    }
    return result;
};

const initialState = {
    filters: {
        date_last: '',
        date_start: '',
        payment_type: null,
        user_id: null,
    },
    stat:[],
    currentItem: null,
    showFormModal: false
};

export const payments = (state = initialState, action) => {
    switch (action.type) {
        // Get page
        case paymentsConstants.GET_PAGE_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                filters: action.filters
            };
        case paymentsConstants.GET_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payments.data,
                meta: action.payments
            };
        case paymentsConstants.GET_PAGE_FAILURE:
            return {
                // error: action.error.toString()
                ...state,
                loading: false,
                error: 'Не удалось загрузить список!'
            };
        case paymentsConstants.GETSTAT_REQUEST:
            return {
                ...state,
                loading: true,
                error: '',
                filters: action.filter
            };
        case paymentsConstants.GETSTAT_SUCCESS:
            return {
                ...state,
                loading: false,
                stat: action.stat.data.statistic,
                meta: action.stat
            };
        case paymentsConstants.GETSTAT_FAILURE:
            return {
                // error: action.error.toString()
                ...state,
                loading: false,
                error: 'Не удалось загрузить статистику!'
            };

        // Delete
        case paymentsConstants.DELETE_REQUEST:
            return {
                ...state,
            };
        case paymentsConstants.DELETE_SUCCESS:
            return {
                ...state,
            };
        case paymentsConstants.DELETE_FAILURE:
            return {
                ...state,
                currentItem: {...state.currentItem, deleteError: action.error},
            };

        // Get item
        case paymentsConstants.LOAD_REQUEST:
            return {
                ...state,
                loading: true,
                error: ''
            };
        case paymentsConstants.LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                currentItem: action.payment.data
            };
        case paymentsConstants.LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: 'Не удалось загрузить элемент!'
            };

        // reset current item
        case paymentsConstants.RESET_CURRENT_ITEM:
            return {
                ...state,
                currentItem: null
            };

        // create
        case paymentsConstants.CREATE_REQUEST:
            return {
                ...state,
                saveStatus: ''
            };
        case paymentsConstants.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                saveStatus: 'Платеж успешно добавлен!',
                meta: {
                    ...state.meta,
                    total: state.meta.total + 1
                },
                showFormModal: false
            };

        // update
        case paymentsConstants.UPDATE_REQUEST:
            return {
                ...state,
                saveStatus: ''
            };
        case paymentsConstants.UPDATE_SUCCESS:
            return {
                ...state,
                saveStatus: 'Платеж успешно отредактирован!',
                loading: false,
                items: updateItem(state.items, action.payment),
                showFormModal: false
            };

        // form modal
        case paymentsConstants.FORM_MODAL_OPEN:
            return {
                ...state,
                showFormModal: true,
                saveStatus: ''
            };
        case paymentsConstants.FORM_MODAL_CLOSE:
            return {
                ...state,
                showFormModal: false
            };
        case paymentsConstants.SET_CURRENT_ITEM:
            return {
                ...state,
                currentItem: action.item
            };

        case authConstants.LOGOUT:
            return initialState;

        default:
            return state;
    }
};
