import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'accountant/payment';
};

const getPage = (page, values) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}?page=${page}`,
    values,
    true
  );
};

const getAll = () => {
    return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}`, {}, true);
};
const getStat = (filter) => {
    return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/statistic`, filter, true);
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = (id, reason) => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/${id}`, {_method: 'DELETE', reason}, true, {}, false);
};
const downloadInvoice = (payment) => {
    return apiService.downloadFile(`/${getResourceName()}/${payment.id}/invoice`,"Счет на оплату платежа "+payment.id +
        (payment.payment_type === 0 ? '.docx' : ".xlsx"));
}


export const paymentService = {
  getPage,
  getAll,
  getItem,
  create,
  getStat,
  update,
  downloadInvoice,
  delete: _delete,
};
