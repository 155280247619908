import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {Field, Form, Formik} from 'formik';
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialSelect from "../../admin/quickApplications/CreateQuickApplication/components/MaterialSelect";
import {apvgkReportsService} from "../../../services/apvgkReportsService";
import {history} from "../../../helpers/history";
import {urlHelper} from "../../../helpers/urlHelper";
import {roleConstants} from "../../../constants/roleConstants";


const GrzSaveDialog = ({list, report}) => {
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);
    const [imageDataURL, setImageDataURL] = useState(null);
    const [file, setFile] = useState(null);

    const onPaste = (event) => {
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const imageFile = items[i].getAsFile();
                processImage(imageFile);
            }
        }
    };

    const processImage = (imageFile) => {
        setFile(imageFile);
        const reader = new FileReader();
        reader.onload = (event) => {
            setImageDataURL(event.target.result);
        };
        reader.readAsDataURL(imageFile);
    };


    const saveGrz = (( values) => {
        if (!report.id) return;
        return new Promise((res,rej) => apvgkReportsService.saveGrz(report.id, {...values, img:file}).then((r) => {
            res(r);
            history.push(`${urlHelper.getHomeUrl(roleConstants.ROLE_APVGK)}/apvgk/`);
        }, rej))});


    return (
        <>
            <Link
                disabled={load}
                to={{}}
                onClick={() => {setOpen(true)}}
            >
                Передать на обработку
            </Link>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Укажите номер ТС
                    </Typography>
                    <IconButton color="inherit" onClick={() => setOpen(false)}>
                        <CloseIcon className="blue"/>
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <Formik
                        initialValues={{grz:''}}
                        onSubmit={values => {
                            setLoad(true);
                            saveGrz(values).catch((e) => console.log(e)).finally(() => {
                                setLoad(false);
                            });
                            setOpen(false);
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        render={({values, setFieldValue}) => (
                            <Form>
                                <Grid container direction="column" spacing={16} wrap="nowrap">
                                    <Grid item>
                                        <FieldLabel label="ГРЗ ТС" name="grz">
                                            <Field
                                                name="grz"
                                                type="text"
                                                min="20"
                                                component={MaterialSelect}
                                                options={list}
                                            />
                                        </FieldLabel>
                                    </Grid>

                                    <Grid item>
                                        {file && <FieldLabel label="Фото ТС" name="grz">
                                            {imageDataURL && <img src={imageDataURL} width="300px" alt="Pasted Image"/>}
                                        </FieldLabel>}
                                        <br/>
                                        <div className="paste-container"
                                            ref={(ref) => {
                                                if (ref) ref.addEventListener('paste', onPaste);
                                            }}>
                                            Нажмите на этот текст и Ctrl+V для вставки изображения
                                        </div>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="btn-add no-margin"
                                            type="submit"
                                        >
                                            Передать
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default GrzSaveDialog;
