import React from 'react';
import { IconButton } from '@material-ui/core';

import DetailsLink from './DetailsLink';
import DeclineReasonDialog from './DeclineReason';
import CopyLink from './CopyLink';

const ApplicationActions = ({ user, item, showDecline }) => (
  <React.Fragment>
    <IconButton />
    <DetailsLink user={user} item={item} isQuick />
    {showDecline && <DeclineReasonDialog item={item} />}
    <CopyLink user={user} item={item} />
  </React.Fragment>
);

export default ApplicationActions;
