import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormField } from '../../../components/form/FormField';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components/Loader';
import { renderSelectField } from '../../../components/form/renderSelectField';
import { roleConstants } from '../../../constants/roleConstants';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';

const optionallyRequired = (value, values) => {
  if (values.smev_enabled) {
    return value ? undefined : 'Обязательное поле!';
  }
  return undefined;
};

const UsersForm = ({
  error,
  handleSubmit,
  submitting,
  currentItem,
  users,
  departments,
  role_id,
  smevEnabled
}) => {
  const role = parseInt(role_id, 10);
  const isAdmin = role === roleConstants.ROLE_ADMIN;
  const isOfficer = role === roleConstants.ROLE_OFFICER;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        className="form-control"
        name="role_id"
        component={renderSelectField}
        label="Тип пользователя"
        validate={[fieldValidators.required]}
      >
        {dictionaryHelper.userRoles.getAdminList().map(option => (
          <option key={option.id} value={option.id}>
            {option.title}
          </option>
        ))}
      </Field>
      {parseInt(role_id, 10) === roleConstants.ROLE_DEPARTMENT_AGENT && (
        <Field
          className="form-control"
          name="department_id"
          component={renderSelectField}
          label="Ведомство"
          validate={[fieldValidators.required]}
        >
          {departments.items.map(option => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </Field>
      )}
      <Field
        name="name"
        type="text"
        label="ФИО"
        validate={[fieldValidators.required]}
        component={FormField}
      />
      <Field
        name="email"
        type="text"
        label="Email"
        validate={[fieldValidators.required, fieldValidators.email]}
        component={FormField}
      />
      {/*{(isAdmin || isOfficer) && (*/}
      {/*  <>*/}
      {/*    <Field*/}
      {/*      name="smev_enabled"*/}
      {/*      label="Доступ к межведомственному взаимодействию"*/}
      {/*      type="checkbox"*/}
      {/*      className="checkbox"*/}
      {/*      component={MuiCheckbox}*/}
      {/*    />*/}
      {/*    <Field*/}
      {/*      name="position"*/}
      {/*      type="text"*/}
      {/*      label="Должность"*/}
      {/*      validate={[optionallyRequired]}*/}
      {/*      component={FormField}*/}
      {/*      disabled={!smevEnabled}*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}
      {error && <strong>{error}</strong>}
      <div>
        {submitting && <Loader />}
        <button type="submit" className="btn" disabled={submitting}>
          {currentItem ? 'Редактировать' : 'Создать'}
        </button>
        <br />
        <br />
        <p>{users && users.saveStatus}</p>
      </div>
    </form>
  );
};

export default connect(
  state => {
    const { users, departments, controlPosts } = state;
    const { currentItem } = users;

    const selector = formValueSelector('user-form');
    const role_id = parseInt(selector(state, 'role_id'), 10);
    const smevEnabled = selector(state, 'smev_enabled');

    return {
      initialValues: {
        id: currentItem ? currentItem.id : '',
        role_id: currentItem ? currentItem.role_id : roleConstants.ROLE_ADMIN,
        department_id: currentItem ? currentItem.department_id : null,
        name: currentItem ? currentItem.name : '',
        email: currentItem ? currentItem.email : '',
        smev_enabled: currentItem ? currentItem.smev_enabled : false,
        position: currentItem ? currentItem.position : ''
      },
      users,
      departments,
      controlPosts,
      role_id,
      smevEnabled
    };
  },
  dispatch => ({ dispatch })
)(
  reduxForm({
    form: 'user-form',
    enableReinitialize: true
  })(UsersForm)
);
