import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { VehicleModelsFilter } from './VehicleModelsFilter';
import { vehicleModelActions } from '../../../../../actions/vehicleModelActions';
import { VehicleModelsTableList } from './VehicleModelsTableList';
import { Loader } from '../../../../../components/Loader';
import { VehicleModelModal } from './VehicleModelModal';

class VehicleModelsBlock extends Component {
  componentDidMount() {
    const { vehicleModels } = this.props;

    if (!vehicleModels.items || vehicleModels.items.length === 0) {
      this.props.dispatch(vehicleModelActions.getPage(1, vehicleModels.filters || {}));
    }
  }

  render() {
    const { vehicleModels, openCreateModal } = this.props;

    return (
      <div>
        <p>
          Справочник моделей тс / прицепов
          <Button aria-label="Добавить" className="btn-add" onClick={openCreateModal}>
            + Создать
          </Button>
        </p>

        <div className="">
          {vehicleModels.loading && <Loader />}
          {vehicleModels.error && (
            <span className="text-danger">Ошибка: {vehicleModels.error}</span>
          )}
          {vehicleModels.items && (
            <VehicleModelsTableList data={vehicleModels.items} meta={vehicleModels.meta} />
          )}
        </div>

        <VehicleModelModal />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { vehicleModels } = state;
  return {
    vehicleModels
  };
};

const mapDispatchToProps = dispatch => {
  const openCreateModal = e => {
    dispatch(vehicleModelActions.setCurrentItem(null));
    dispatch(vehicleModelActions.openFormModal());
  };

  return {
    openCreateModal,
    dispatch
  };
};

const connectedVehicleModelsBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleModelsBlock);
export { connectedVehicleModelsBlock as VehicleModelsBlock };
