import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';

const ReasonModal = ({isShow, onClose, modelId, report}) => {
    return (
        <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="md">
            <Toolbar className="text-center">
                <Typography variant="title" color="inherit" className="flex">
                    {'Причина отклонения:'}
                </Typography>
                <IconButton color="inherit" onClick={onClose}>
                    <CloseIcon className="blue"/>
                </IconButton>
            </Toolbar>

            <DialogContent className="text-center">
                <Typography variant="title" color="inherit" className="flex">
                    {report.reason || 'Unknown reason' + report.id}
                </Typography>
            </DialogContent>
        </Dialog>

    )
}

export default ReasonModal