import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { Popup, Tooltip, Marker } from 'react-leaflet';
import 'leaflet-routing-machine';
import 'leaflet-control-geocoder';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import { connect } from 'react-redux';
import { applicationActions } from '../../actions/applicationActions';
import { applicationConstants } from '../../constants/applicationConstants';
import { roleConstants } from '../../constants/roleConstants';
import { Geocoder, Router } from './Geocoder';

class Routing extends Component {
  static propTypes = {
    map: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.map = props.map;

    this.state = {
      routingPopUp: null,
      loading: false
    };
    this.initTimer = null;

    this.initializeRouting = this.initializeRouting.bind(this);
    this.destroyRouting = this.destroyRouting.bind(this);
  }

  componentDidMount() {
    const { map, adminApplications } = this.props;
    const { currentItem } = adminApplications;
    this.initTimer = setTimeout(() => this.initializeRouting(map, currentItem), 3000);
  }

  componentWillUnmount() {
    this.destroyRouting();
  }

  initializeRouting(map, application) {
    const { markers, routeMarkers, authentication, adminApplications } = this.props;
    const { currentItem } = adminApplications;
    const { user } = authentication;
    const editable =
      (currentItem.status === applicationConstants.STATUS_REVIEW ||
        currentItem.status === applicationConstants.STATUS_REPEAT) &&
      (user.role_id === roleConstants.ROLE_ADMIN || user.role_id === roleConstants.ROLE_OFFICER);
    console.log('calculateEditableStatus', currentItem.status);
    console.log('calculateEditable', editable);

    if (this.props.map) {
      console.log('create route markers', markers);
      const plan = new L.Routing.Plan(
        markers.map(marker => L.latLng(marker.coords.lat, marker.coords.lon)),
        {
          routeWhileDragging: false,
          draggableWaypoints: editable,
          geocoder: new Geocoder()
        }
      );
      console.log('creating routing, editable =', editable);
      this.routing = L.Routing.control({
        plan,
        router: new Router(),
        // router: L.Routing.mapbox(applicationConstants.MAPBOX_KEY),
        lineOptions: {
          addWaypoints: editable
        },
        fitSelectedRoutes: false
      });

      // detect route changes by admin
      const that = this;
      this.routing.on('waypointschanged', function(e) {
        const { waypoints } = e;
        console.log('waypointschanged', waypoints);
        that.handlePointsChanged(application, waypoints);
      });

      this.routing.on('routingstart', () => {
        this.setState({ loading: true, position: this.map.props.center });
      });

      this.routing.on('routesfound routingerror', () => {
        this.setState({ loading: false, position: this.map.props.center });
      });

      this.props.map.leafletElement.addControl(this.routing);

      // send plan to
      this.props.dispatch(applicationActions.setPlan(plan));
      this.props.dispatch(applicationActions.setRouting(this.routing));
    }
  }

  handlePointsChanged(application, waypoints) {
    const { routeMarkers } = this.props;

    this.props.dispatch(
      applicationActions.changeRouteByAdmin(application.id, waypoints, routeMarkers, data => {})
    );
  }

  destroyRouting() {
    if (this.initTimer) {
      clearTimeout(this.initTimer);
    }
    if (this.props.map && this.routing) {
      this.props.map.leafletElement.removeControl(this.routing);
    }
  }

  render() {
    const { loading, position } = this.state;
    return (
      <React.Fragment>
        {loading && (
          <Marker position={position} opacity={0}>
            <Tooltip permanent direction="center">
              <span>Пожалуйста, подождите. Данные обновляются</span>
            </Tooltip>
          </Marker>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { adminApplications, authentication } = state;
  const { adminRoute, currentItem } = adminApplications;
  const { user } = authentication;
  const { markers } = adminRoute;

  return {
    adminApplications,
    authentication,
    routeMarkers: markers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;

  const setMap = map => {
    dispatch(applicationActions.setMap(map));
  };

  return {
    ...ownProps,
    ...stateProps,
    dispatch,
    setMap
  };
};

const connectedRouting = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Routing);
export { connectedRouting as Routing };
