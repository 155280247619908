import React from 'react';

import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { applicationActions } from '../../../../actions/applicationActions';

const ApplicationRestoreButton = ({ application, handleRestore, cls }) =>
  !application.loading &&
  application.removed && (
    <Button className={cls} onClick={() => handleRestore(application.id)}>
      Восстановить
    </Button>
  );

export default connect(
  undefined,
  {
    handleRestore: applicationActions.restore
  }
)(ApplicationRestoreButton);
