import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { change, reduxForm, submit } from 'redux-form';
import { isEmpty } from 'lodash';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { vehicleActions } from '../../../actions/vehicleActions';
import { Loader } from '../../../components/Loader';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { roleConstants } from '../../../constants/roleConstants';
import { vehicleConstants } from '../../../constants/vehicleConstants';
import InfoModal from '../../../components/modals/InfoModal';
import TablePagination from '../../../components/TablePagination';
import VehiclesFilterForm from './VehicleFilterForm';
import { useMountEffect } from '../../../helpers/hooks';
import VehicleAccessModal from './VehicleAccessModal';

const VehiclesTableList = props => {
  const {
    authentication,
    vehicles,
    getVehicles,
    deleteVehicle,
    closeErrorModal,
    changeForm,
    submitForm,
    values,
    handleSubmit
  } = props;

  const { items, tabIndex, meta, loading, error } = vehicles;

  useMountEffect(() => getVehicles(tabIndex, values));

  const handleChangePage = page => {
    changeForm('vehicle-filter-form', 'page', page);
    setTimeout(() => {
      submitForm('vehicle-filter-form');
    }, 200);
  };
  const checkPrivilege = vehicle => {
    if (vehicle.confirmation_status !== vehicleConstants.STATUS_ACCEPT) {
      return dictionaryHelper.vehicleStatuses.getItemTitle(vehicle.confirmation_status);
    }
    const result = vehicles.privilegeVehicles.find(item => {
      return item.real_number === vehicle.real_number;
    });
    if (result && result.privilegeStatus) {
      return result.privilegeStatus.title;
    }
    return 'Без особых статусов';
  };

  const haveNoItems = isEmpty(items);
  const acceptedTab = tabIndex === vehicleConstants.STATUS_ACCEPT;

  return (
    <form onSubmit={handleSubmit}>
      <Paper className="overflow">
        <InfoModal
          isOpen={vehicles.showErrorModal}
          onClose={closeErrorModal}
          title="Ошибка удаления"
          text={vehicles.errorModalText}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Тип</TableCell>
              <TableCell>Марка</TableCell>
              <TableCell>Модель</TableCell>
              <TableCell>ГРЗ</TableCell>
              {acceptedTab && <TableCell>Статус</TableCell>}
              <TableCell numeric colSpan={4}>
                Действие
              </TableCell>
            </TableRow>
            <VehiclesFilterForm submitting={loading} extraCell={acceptedTab} />
          </TableHead>
          <TableBody>
            {(loading || error || haveNoItems) && (
              <TableRow>
                <TableCell align="center" colSpan={acceptedTab ? 7 : 6}>
                  {!error && !loading && haveNoItems && <span>Объекты не найдены</span>}
                  {error && <span className="text-danger">Ошибка: {error}</span>}
                  {loading && <Loader />}
                </TableCell>
              </TableRow>
            )}
            {!loading &&
              !error &&
              items.map((vehicle, index) => (
                <TableRow key={vehicle.id}>
                  <TableCell>{(meta.current_page - 1) * meta.per_page + index + 1}</TableCell>
                  <TableCell>{vehicle.is_trailer ? 'Прицеп' : 'ТС'}</TableCell>
                  <TableCell>{vehicle.brandTitle}</TableCell>
                  <TableCell>{vehicle.modelTitle}</TableCell>
                  <TableCell>{vehicle.real_number}</TableCell>
                  {acceptedTab && (
                    <TableCell
                      className={dictionaryHelper.vehicleStatuses.getItemClass(
                        vehicle.confirmation_status
                      )}
                    >
                      {checkPrivilege(vehicle)}
                    </TableCell>
                  )}
                  <TableCell numeric className="nowrap" colSpan={4}>
                    <Grid container direction="row" wrap="nowrap" spacing={8}>
                      {authentication.user.role_id === roleConstants.ROLE_FIRMUSER &&
                        !vehicle.can_edit && (
                          <Grid item>
                            <Link to={`vehicles/info/${vehicle.id}`}>
                              <Button variant="outlined" className="info-btn" size="small">
                                Подробнее
                              </Button>
                            </Link>
                          </Grid>
                        )}
                      {authentication.user.role_id !== roleConstants.ROLE_FIRMUSER ||
                      vehicle.can_edit ? (
                        <Grid item>
                          <Link to={`vehicles/update/${vehicle.id}`}>
                            <Button variant="outlined" className="info-btn" size="small">
                              Редактировать
                            </Button>
                          </Link>
                        </Grid>
                      ) : (
                        ''
                      )}
                      &nbsp;&nbsp;
                      {authentication.user.role_id !== roleConstants.ROLE_FIRMUSER && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            className="decline-btn"
                            size="small"
                            onClick={() => deleteVehicle(vehicle.id)}
                          >
                            {vehicle.deleting ? <Loader /> : <span>Удалить</span>}
                          </Button>
                        </Grid>
                      )}
                      {authentication.user.role_id === roleConstants.ROLE_FIRM && (
                        <Grid item>
                          <VehicleAccessModal vehicle={vehicle} />
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination meta={meta} onChangePage={handleChangePage} />
      </Paper>
    </form>
  );
};

export default connect(
  state => ({
    vehicles: state.cabinetVehicles,
    authentication: state.authentication
  }),
  {
    getVehicles: vehicleActions.getCabinetPage,
    deleteVehicle: vehicleActions.delete,
    changeForm: change,
    submitForm: submit,
    closeErrorModal: vehicleActions.closeErrorModal
  }
)(
  reduxForm({
    form: 'vehicle-filter-form',
    enableReinitialize: true,
    onSubmit: (values, dispatch, { vehicles, getVehicles }) =>
      getVehicles(vehicles.tabIndex, values)
  })(VehiclesTableList)
);
