/* eslint react/destructuring-assignment: 0 */
import React, { Component } from 'react';
import { compose } from 'redux';
import Async from 'react-async';
import { withSnackbar } from 'notistack';
import { has, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Badge, Button, Grid, Popover, CircularProgress, withStyles } from '@material-ui/core';

import ProgressList from './ProgressList';
import processesService from '../../services/processesService';
import { ProcessingStatus } from '../../constants/fileConstants';

const styles = {
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
};

const compareByCreatedAt = (a, b) => moment(b.created_at).diff(moment(a.created_at));

const isNeedToWatch = responseItems =>
  responseItems.some(
    ({ status }) => status === ProcessingStatus.NEW || status === ProcessingStatus.PROCESSING
  );

class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.updater = React.createRef();
    this.state = {
      items: [],
      newProcess: false,
      anchorEl: null
    };
  }

  componentWillUnmount() {
    if (this.updater.current) clearInterval(this.updater.current);
  }

  showInfo = () => this.props.enqueueSnackbar('Нет запущенных процессов', { variant: 'warning' });

  showCommonError = (e) =>
  {console.log(e)
    this.props.enqueueSnackbar('Произошла ошибка, попробуйте позже!', { variant: 'error' });}

  stopToWatch = () => {
    clearInterval(this.updater.current);
    this.updater.current = undefined;
  };

  startToWatch = fetchProcesses => {
    this.updater.current = setInterval(
      () => this.getProcesses(this.props.type, fetchProcesses),
      3000
    );
  };

  openPopover = (event, fetchProcesses) => {
    const value = event.currentTarget;
    this.getProcesses(this.props.type, fetchProcesses)
      .then(v => {
        if (has(v, 'data')) {
          const responseItems = v.data;
          if (isEmpty(responseItems)) {
            this.showInfo();
          } else {
            this.setState({ newProcess: false });
            this.setState({ anchorEl: value });
          }
        } else this.showCommonError(3);
      })
      .catch(this.showCommonError);
  };

  closePopover = () => {
    this.setState({ anchorEl: null });
    if (this.updater.current) this.stopToWatch();
  };

  getProcesses = (type, fetchProcesses) =>
    fetchProcesses(type).then(v => {
      if (has(v, 'data')) {
        const responseItems = v.data;
        if (!isEmpty(responseItems)) {
          this.setState({ items: responseItems });
          const needToWatch = isNeedToWatch(responseItems);
          if (this.updater.current) {
            if (!needToWatch) this.stopToWatch();
          } else if (needToWatch) {
            this.startToWatch(fetchProcesses);
          }
        }
      } else if (this.state.anchorEl) {
        this.showCommonError(2);
        this.closePopover();
      }
      return v;
    });

  exportFile = () =>
    this.props
      .onExport()
      .then(v =>
        has(v, 'process_id') ? this.setState({ newProcess: true }) : this.showCommonError(1)
      )
      .catch(this.showCommonError);

  render() {
    const { classes } = this.props;
    const { anchorEl, newProcess, items } = this.state;

    const id = anchorEl ? 'simple-popover' : undefined;

    return (
      <Async deferFn={([a]) => processesService.getProcesses(a)}>
        {({ isLoading, run: fetchProcesses }) => (
          <Grid item>
            <Grid container direction="row" spacing={16}>
              <Grid item>
                <Button
                  className="btn-add"
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={this.exportFile}
                >
                  Скачать данные
                </Button>
              </Grid>
              <Grid item>
                <Grid container className={classes.wrapper}>
                  <Grid item>
                    <Badge invisible={!newProcess} color="secondary" variant="dot">
                      <Button
                        className="btn-add no-margin-left"
                        variant="outlined"
                        color="primary"
                        aria-describedby={id}
                        type="button"
                        disabled={isLoading}
                        onClick={e => this.openPopover(e, fetchProcesses)}
                      >
                        Прогресс
                      </Button>
                    </Badge>
                  </Grid>
                  <Grid item>
                    {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Grid>
                </Grid>
                <Popover
                  id={id}
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={this.closePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                >
                  <ProgressList items={items.sort(compareByCreatedAt)} />
                </Popover>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Async>
    );
  }
}

ProgressBar.propTypes = {
  type: PropTypes.number.isRequired,
  onExport: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
};

export default compose(
  withStyles(styles),
  withSnackbar
)(ProgressBar);
