import { vehicleConstants } from '../constants/vehicleConstants';
import { roleConstants } from '../constants/roleConstants';
import { authConstants } from '../constants/authConstants';

const findItem = (items, id) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      return items[i];
    }
  }
  return null;
};

const removeItem = (items, id) => {
  return items.filter(item => item.id !== id);
};

const initialState = {
  tabIndex: vehicleConstants.STATUS_NEW,
  roleIndex: roleConstants.ROLE_INDIVIDUAL,
  tabs: [
    [{}, {}], // individual and firm data for status = new
    [{}, {}],
    [{}, {}],
    [{}, {}]
  ],
  showDeclineModal: false,
  showReasonModal: false,
  showDetailsModal: false,
  meta: {},
  items: [],
  currentItem: null,
  statusLoading: false
};

export const adminVehicles = (state = initialState, action) => {
  switch (action.type) {
    case vehicleConstants.SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex
      };
    case vehicleConstants.SET_ROLE_INDEX:
      return {
        ...state,
        roleIndex: action.roleIndex
      };

    case vehicleConstants.GET_BY_STATUSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case vehicleConstants.GET_BY_STATUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.vehicles.data,
        meta: action.vehicles.meta
      };
    case vehicleConstants.GET_BY_STATUSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить список заявок!'
      };

    case vehicleConstants.ADMIN_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case vehicleConstants.ADMIN_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.response.data,
        meta: action.response.meta
      };
    case vehicleConstants.ADMIN_FILTER_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить список заявок!'
      };

    case vehicleConstants.GET_PAGE_BY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case vehicleConstants.GET_PAGE_BY_STATUS_SUCCESS:
      return {
        ...state,
        items: action.vehicles.data,
        meta: action.vehicles.meta,
        loading: false
      };
    case vehicleConstants.GET_PAGE_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false
        // error: 'Не удалось загрузить список!'
      };

    // Get item
    case vehicleConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case vehicleConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.vehicle.data
      };
    case vehicleConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    case vehicleConstants.TO_WORK_REQUEST:
      return {
        ...state,
        statusLoading: true,
        items: state.items.map(item => {
          if (item.id === action.id) {
            item.loading = true;
          }
          return item;
        })
      };
    case vehicleConstants.TO_WORK_SUCCESS:
      return {
        ...state,
        items: removeItem(state.items, action.vehicle.id),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
          confirmation_status: action.vehicle.confirmation_status
        }
      };
    case vehicleConstants.TO_WORK_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    case vehicleConstants.ACCEPT_REQUEST:
      return {
        ...state,
        statusLoading: true
      };
    case vehicleConstants.ACCEPT_SUCCESS:
      return {
        ...state,
        items: removeItem(state.items, action.id),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
          confirmation_status: action.vehicle.confirmation_status,
          note: action.vehicle.note
        }
      };
    case vehicleConstants.ACCEPT_FAILURE:
      return {
        ...state,
        statusLoading: false
      };
    case vehicleConstants.DECLINE_REQUEST:
      return {
        ...state,
        statusLoading: true
      };
    case vehicleConstants.DECLINE_SUCCESS:
      return {
        ...state,
        items: removeItem(state.items, action.id),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
          confirmation_status: action.vehicle.confirmation_status,
          note: action.vehicle.note
        }
      };
    case vehicleConstants.DECLINE_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    // decline modal
    case vehicleConstants.DECLINE_CURRENT_ITEM_OPEN:
      return {
        ...state,
        showDeclineModal: true
      };
    case vehicleConstants.DECLINE_OPEN:
      return {
        ...state,
        showDeclineModal: true,
        currentItem: findItem(state.items, action.id)
      };
    case vehicleConstants.DECLINE_CLOSE:
      return {
        ...state,
        showDeclineModal: false
      };

    // reason modal
    case vehicleConstants.REASON_OPEN:
      return {
        ...state,
        showReasonModal: true,
        currentItem: findItem(state.items, action.id)
      };
    case vehicleConstants.REASON_CLOSE:
      return {
        ...state,
        showReasonModal: false
      };

    // reason modal
    case vehicleConstants.DETAILS_OPEN:
      return {
        ...state,
        showDetailsModal: true,
        currentItem: findItem(state.items, action.id)
      };
    case vehicleConstants.DETAILS_CLOSE:
      return {
        ...state,
        showDetailsModal: false
      };
    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
