import React from 'react';
import Button from '@material-ui/core/Button';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { formatHelper } from '../../../../helpers/formatHelper';
import ContentContainer from './ContentContainer';
import DetailsLink from './DetailsLink';
import { getApplicationViewData } from './util';
import { applicationConstants } from '../../../../constants/applicationConstants';
import EmployeeCell from '../EmployeeCell';

class DeclineReasonDialog extends React.Component {
  state = {
    show: false
  };

  toggleDialog = () => {
    this.setState(state => ({ show: !state.show }));
  };

  render() {
    const { show } = this.state;
    const { item } = this.props;
    return (
      <React.Fragment>
        <Dialog onClose={this.toggleDialog} aria-labelledby="simple-dialog-title" open={show}>
          <DialogTitle id="simple-dialog-title">
            Причина отклонения для №{formatHelper.appNumber(item)}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{item.comment || 'Причина не указана'}</DialogContentText>
          </DialogContent>
        </Dialog>
        <Button variant="text" className="info-btn" onClick={this.toggleDialog}>
          Причина отклонения
        </Button>
      </React.Fragment>
    );
  }
}

const DeclineContent = () => (
  <ContentContainer>
    {(items, user) => (
      <React.Fragment>
        <TableHead>
          <TableRow>
            <TableCell>№ заявления</TableCell>
            <TableCell>Дата отклонения</TableCell>
            <TableCell>Организация / ФИО</TableCell>
            <TableCell>Сотрудник, отклонивший заявку</TableCell>
            <TableCell numeric>Действие</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(application => {
            const { id, appNumber, createdAt, userName, eventUser } = getApplicationViewData(
              application,
              applicationConstants.STATUS_DECLINE,
              user
            );
            return (
              <TableRow key={id}>
                <TableCell>{appNumber}</TableCell>
                <TableCell>{createdAt}</TableCell>
                <EmployeeCell application={application} userName={userName} />
                <TableCell>{eventUser}</TableCell>
                <TableCell numeric className="nowrap">
                  <DetailsLink user={user} item={application} />
                  <DeclineReasonDialog item={application} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </React.Fragment>
    )}
  </ContentContainer>
);

export default DeclineContent;
