import React from 'react';
import { AdminRoute } from '../../../components/routes/AdminRoute';
import { ApplicationsListPage } from './ApplicationsListPage';
import { ApplicationViewPage } from './ApplicationViewPage';
import NewApplicationViewPage from '../applications_new/ApplicationViewPageContainer';

const ApplicationsLayout = ({ match }) => (
  <React.Fragment>
    <AdminRoute exact path={`${match.url}/`} component={ApplicationsListPage} />
    <AdminRoute exact path={`${match.url}/view/:id`} component={ApplicationViewPage} />
    <AdminRoute exact path={`${match.url}/view_new/:id`} component={NewApplicationViewPage} />
  </React.Fragment>
);

export default ApplicationsLayout;
