import { uniqueId } from 'lodash';
import {
  APPLICATION_ADD_AGREEMENT,
  APPLICATION_CHANGE_EDIT_ROUTE,
  APPLICATION_CHANGE_MARKET_TEXT,
  APPLICATION_CHANGE_PAY_FILES,
  APPLICATION_CHANGE_ROUTE_BY_ADMIN,
  APPLICATION_COMMENT_CHANGED,
  APPLICATION_REMOVE_AGREEMENT,
  APPLICATION_REMOVE_MARKER,
  APPLICATION_UPDATE_PRINT_FORM,
  CHANGE_STATUS_REQUEST,
  CHANGE_STATUS_REQUEST_FAILED,
  CHANGE_STATUS_REQUEST_SUCCESS,
  GET_APPLICATION_FAILED,
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  SET_SHOW_ACTIVATE_MODAL,
  SET_SHOW_DECLINE_MODAL,
  SET_SHOW_PRINT_MODAL
} from '../actions/newApplicationActions';

const initialState = {
  data: null,
  isError: false,
  isLoading: false,
  isChangingStatus: false,
  isEditingMap: false,
  isRouteChanged: false,
  routeChangeComment: '',
  modals: {
    declineModal: false,
    activateModal: false,
    printModal: false
  }
};

function getMarkers(points) {
  return points.map(({ text, lat, lon, lng }) => ({
    id: uniqueId('marker_'),
    lat,
    lon,
    text,
    coords: {
      lat,
      lon: lon || lng,
      lng: lng || lon
    }
  }));
}

function parseMarkers(route) {
  return JSON.parse(route.points)
    .filter(a => a.coords && a.coords.lat)
    .map(({ text, coords: { lat, lon, lng } }) => ({
      id: uniqueId('marker_'),
      lat,
      lon,
      text,
      coords: {
        lat,
        lon: lon || lng,
        lng: lng || lon
      }
    }));
}

function updateData(state, dataUpdate) {
  return {
    ...state,
    data: {
      ...state.data,
      ...dataUpdate(state.data)
    }
  };
}

function updateModal(state, updater) {
  return {
    ...state,
    modals: {
      ...state.modals,
      ...updater
    }
  };
}

function handleData(application, oldApplication) {
  let markers = [];
  let axlesInfo = [];
  if (application && application.route) {
    markers = parseMarkers(application.route);
  } else {
    markers = oldApplication ? oldApplication.markers : [];
  }
  if (application && application.load) {
    axlesInfo = JSON.parse(application.load.axles_info);
  }
  return {
    ...application,
    markers,
    axlesInfo
  };
}

export const applicationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_APPLICATION_REQUEST:
      return {
        ...state,
        data: null,
        isError: false,
        isLoading: true
      };
    case GET_APPLICATION_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        isLoading: false
      };
    case GET_APPLICATION_SUCCESS:
      return {
        ...state,
        data: handleData(payload),
        isError: false,
        isLoading: false
      };
    case CHANGE_STATUS_REQUEST:
      return {
        ...state,
        isChangingStatus: true
      };
    case CHANGE_STATUS_REQUEST_FAILED:
      return {
        ...state,
        isChangingStatus: false
      };
    case CHANGE_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        data: {
          ...state.data,
          ...handleData(payload, state.data)
        }
      };
    case APPLICATION_CHANGE_EDIT_ROUTE:
      return {
        ...state,
        isEditingMap: payload
      };
    case APPLICATION_CHANGE_ROUTE_BY_ADMIN:
      return {
        ...state,
        data: {
          ...state.data,
          price: payload.price,
          spring_price: payload.spring_price,
          markers: getMarkers(payload.points),
          route: {
            ...state.data.route,
            distance: payload.distance,
            distance_info: payload.distance_info
          }
        },
        isRouteChanged: true
      };
    case APPLICATION_CHANGE_MARKET_TEXT:
      return {
        ...state,
        data: {
          ...state.data,
          markers: state.data.markers.map(marker => {
            if (marker.id === payload.markerId) {
              return { ...marker, text: payload.newValue };
            }
            return marker;
          })
        },
        isRouteChanged: true
      };
    case APPLICATION_REMOVE_MARKER:
      return {
        ...state,
        data: {
          ...state.data,
          markers: state.data.markers.filter(marker => marker.id !== payload)
        },
        isRouteChanged: true
      };
    case APPLICATION_COMMENT_CHANGED:
      return { ...state, routeChangeComment: payload };
    case SET_SHOW_DECLINE_MODAL:
      return updateModal(state, { declineModal: payload });
    case SET_SHOW_ACTIVATE_MODAL:
      return updateModal(state, { activateModal: payload });
    case SET_SHOW_PRINT_MODAL:
      return updateModal(state, { printModal: payload });
    case APPLICATION_UPDATE_PRINT_FORM:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      };
    case APPLICATION_ADD_AGREEMENT:
      return updateData(state, application => ({
        application_agreements: [...(application.application_agreements || []), payload]
      }));
    case APPLICATION_REMOVE_AGREEMENT:
      return updateData(state, application => ({
        application_agreements: (application.application_agreements || []).filter(
          agreement => agreement.id !== payload
        )
      }));
    case APPLICATION_CHANGE_PAY_FILES:
      return updateData(state, application => ({
        files: {
          ...(application.files || {}),
          pay_files: payload
        }
      }));
    default:
      return state;
  }
};
