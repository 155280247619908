import { userConstants } from '../constants/userConstants';
import { roleConstants } from '../constants/roleConstants';
import { authConstants } from '../constants/authConstants';

const createItem = (items, item) => {
  items.unshift(item);
  return items;
};
const updateItem = (items, item) => {
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].id !== item.id) {
      result.push(items[i]);
    } else {
      result.push(item);
    }
  }
  return result;
};

const initialState = {
  filters: {
    role: roleConstants.ROLE_ADMIN
  },
  departmentFilter: null,
  showFormModal: false
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    // Get page
    case userConstants.GET_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        filters: action.filters
      };
    case userConstants.GET_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.users.data,
        meta: action.users.meta
      };
    case userConstants.GET_PAGE_FAILURE:
      return {
        // error: action.error.toString()
        ...state,
        loading: false,
        error: 'Не удалось загрузить список!'
      };

    // Delete
    case userConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(user => (user.id === action.id ? { ...user, deleting: true } : user))
      };
    case userConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(user => user.id !== action.id),
        meta: {
          ...state.meta,
          total: state.meta.total - 1
        }
      };
    case userConstants.DELETE_FAILURE:
      return {
        ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            const { deleting, ...userCopy } = user;
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        })
      };

    // Get item
    case userConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case userConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.user.data
      };
    case userConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    case userConstants.USER_CHANGE_EMAIL:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          email: action.payload
        }
      };

    // reset current item
    case userConstants.RESET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: null
      };

    // create
    case userConstants.CREATE_REQUEST:
      return {
        ...state,
        saveStatus: ''
      };
    case userConstants.CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        saveStatus: 'Пользователь успешно добавлен!',
        items:
          parseInt(state.filters.role, 10) === action.user.role_id
            ? createItem(state.items, action.user)
            : state.items,
        meta:
          parseInt(state.filters.role, 10) === action.user.role_id
            ? {
                ...state.meta,
                total: state.meta.total + 1
              }
            : state.meta,
        showFormModal: false
      };

    // update
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        saveStatus: ''
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        saveStatus: 'Пользователь успешно отредактирован!',
        loading: false,
        items: updateItem(state.items, action.user),
        showFormModal: false
      };

    // form modal
    case userConstants.FORM_MODAL_OPEN:
      return {
        ...state,
        showFormModal: true,
        saveStatus: ''
      };
    case userConstants.FORM_MODAL_CLOSE:
      return {
        ...state,
        showFormModal: false
      };
    case userConstants.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item
      };

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
