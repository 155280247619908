import React from 'react';
import { Link } from 'react-router-dom';

const MainMenuLink = props => {
  const { path, title, img, imgClass, menuClass, isExternal } = props;
  const isActive = props.location === path;
  const className = `nav-item${isActive}` ? ' active' : '';
  const linkTitle = img ? <img alt={title} className={imgClass} src={img} /> : title;

  return (
    <li className={className} key={path}>
      {isExternal ? (
        <a className="nav-link" target="_blank" href={path}>
          {title}
        </a>
      ) : (
        <Link className={`nav-link ${menuClass}`} to={path}>
          {linkTitle}
        </Link>
      )}
    </li>
  );
};

export default MainMenuLink;
