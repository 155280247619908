import React from 'react';
import { ClientRoute } from '../../../../components/routes/ClientRoute';
import ApplicationStepVehicle from './vehicle/ApplicationStepVehicle';
import ApplicationSteps from './ApplicationSteps';
import ApplicationStepLoad from './load/ApplicationStepLoad';
import ApplicationStepRoute from './route/ApplicationStepRoute';
import ApplicationStepDates from './dates/ApplicationStepDates';
import {connect} from 'react-redux'
import {roleConstants} from "../../../../constants/roleConstants";
import {KppEditModal} from "./KppEditModal";

const ApplicationsStepLayout = ({ match, ...props }) => {
  console.log('ApplicationStepLayoutId', match.params.id);
  const {user} = props
  const [showKppModal, setShowKppModal] = React.useState(user.role_id === roleConstants.ROLE_FIRM && (!user.firm || !user.firm.kpp_org))
  return (
    <div>
      <ApplicationSteps />
      <KppEditModal show={showKppModal} handleModalClose={() => {
          setShowKppModal(false)
      }}/>
      <ClientRoute path={`${match.url}/vehicle`} component={ApplicationStepVehicle} />
      <ClientRoute path={`${match.url}/load`} component={ApplicationStepLoad} />
      <ClientRoute path={`${match.url}/route`} component={ApplicationStepRoute} />
      <ClientRoute path={`${match.url}/dates`} component={ApplicationStepDates} />
    </div>
  );
};
const mapStateToProps = state => {
    const { user } = state.authentication;
    return {
        user
    };
};


const connectedApplicationsStepLayout = connect(
    mapStateToProps,
)(ApplicationsStepLayout);
export { connectedApplicationsStepLayout as ApplicationsStepLayout };
