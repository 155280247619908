import { vehicleBrandConstants } from '../constants/vehicleBrandConstants';
import { vehicleBrandService } from '../services/vehicleBrandService';

const getAll = () => {
  const request = () => {
    return { type: vehicleBrandConstants.GET_ALL_REQUEST };
  };

  const success = vehicleBrands => {
    return { type: vehicleBrandConstants.GET_ALL_SUCCESS, vehicleBrands };
  };

  const failure = error => {
    return { type: vehicleBrandConstants.GET_ALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    vehicleBrandService.getAll().then(
      vehicleBrands => {
        dispatch(success(vehicleBrands));
        dispatch(success(vehicleBrands));
      },
      error => dispatch(failure(error))
    );
  };
};

const getPage = (page, filters, sort) => {
  const request = { type: vehicleBrandConstants.GET_PAGE_REQUEST, page, filters, sort };

  const success = vehicleBrands => {
    return { type: vehicleBrandConstants.GET_PAGE_SUCCESS, vehicleBrands };
  };

  const failure = error => {
    return { type: vehicleBrandConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request);

    vehicleBrandService
      .getPage(page, filters, sort)
      .then(vehicleBrands => dispatch(success(vehicleBrands)), error => dispatch(failure(error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: vehicleBrandConstants.LOAD_REQUEST, id };
  };

  const success = vehicleBrand => {
    return { type: vehicleBrandConstants.LOAD_SUCCESS, vehicleBrand };
  };

  const failure = error => {
    return { type: vehicleBrandConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleBrandService
      .getItem(id)
      .then(vehicleBrand => dispatch(success(vehicleBrand)), error => dispatch(failure(error)));
  };
};

const create = values => {
  const request = value => {
    return { type: vehicleBrandConstants.CREATE_REQUEST, value };
  };

  const success = vehicleBrand => {
    return { type: vehicleBrandConstants.CREATE_SUCCESS, vehicleBrand };
  };

  const failure = error => {
    return { type: vehicleBrandConstants.CREATE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    return vehicleBrandService.create(values).then(vehicleBrand => dispatch(success(vehicleBrand)));
  };
};

const update = values => {
  const request = value => {
    return { type: vehicleBrandConstants.UPDATE_REQUEST, value };
  };

  const success = vehicleBrand => {
    return { type: vehicleBrandConstants.UPDATE_SUCCESS, vehicleBrand };
  };

  return dispatch => {
    dispatch(request(values));

    return vehicleBrandService.update(values).then(vehicleBrand => dispatch(success(vehicleBrand)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: vehicleBrandConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: vehicleBrandConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: vehicleBrandConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleBrandService.delete(id).then(
      data => {
        if (data.success) {
          dispatch(success(id));
        } else {
          dispatch(failure(id, 'Не может быть удалено!'));
        }
      },
      error => dispatch(failure(id, error))
    );
  };
};

const accept = id => {
  const request = id => {
    return { type: vehicleBrandConstants.ACCEPT_REQUEST, id };
  };

  const success = id => {
    return { type: vehicleBrandConstants.ACCEPT_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: vehicleBrandConstants.ACCEPT_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleBrandService.accept(id).then(
      data => {
        if (data.success) {
          dispatch(success(id));
        } else {
          dispatch(failure(id, 'Не может быть утверждено!'));
        }
      },
      error => dispatch(failure(id, error))
    );
  };
};

const hide = id => {
  const request = id => {
    return { type: vehicleBrandConstants.HIDE_REQUEST, id };
  };

  const success = id => {
    return { type: vehicleBrandConstants.HIDE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: vehicleBrandConstants.HIDE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleBrandService.hide(id).then(
      data => {
        if (data.success) {
          dispatch(success(id));
        } else {
          dispatch(failure(id, 'Не может быть отклонено!'));
        }
      },
      error => dispatch(failure(id, error))
    );
  };
};

const openFormModal = () => {
  return { type: vehicleBrandConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: vehicleBrandConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: vehicleBrandConstants.SET_CURRENT_ITEM, item };
};

const closeErrorModal = () => {
  return { type: vehicleBrandConstants.CLOSE_ERROR_MODAL };
};

const uploadBrandsXls = (values, privilege_status_id) => {
  const request = data => {
    return { type: vehicleBrandConstants.EXPORT_UPLOAD_REQUEST, data };
  };
  const success = vehicleBrands => {
    return { type: vehicleBrandConstants.EXPORT_UPLOAD_SUCCESS, vehicleBrands };
  };

  return dispatch => {
    dispatch(request(values));

    return vehicleBrandService
      .exportXls(values, privilege_status_id)
      .then(response => dispatch(success(response)))
      .catch(err => {
        console.log('Upload error', err);
      });
  };
};

export const vehicleBrandActions = {
  getAll,
  getPage,
  getItem,
  create,
  update,
  delete: _delete,
  accept,
  hide,

  openFormModal,
  closeFormModal,
  setCurrentItem,

  closeErrorModal,

  uploadBrandsXls
};
