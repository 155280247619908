import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { controlPostActions } from '../../../../actions/controlPostActions';

const ControlPostErrorModal = props => {
  const { controlPosts, handleErrorClose } = props;

  return (
    <Dialog
      open={controlPosts.showDeleteError}
      onClose={handleErrorClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Удаление ведомства
        </Typography>
        <IconButton color="inherit" onClick={handleErrorClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <span>Ведомство не может быть удалено, т.к. есть сотрудники, привязанные к нему</span>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { controlPosts } = state;
  return {
    controlPosts
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleErrorClose: () => {
      dispatch(controlPostActions.closeErrorModal());
    }
  };
};

const connectedControlPostErrorModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlPostErrorModal);
export { connectedControlPostErrorModal as ControlPostErrorModal };
