import React from 'react';
import ApplicationNumber from './ApplicationNumber';
import ApplicationActions from './ApplicationActions';
import ApplicationFormId from './ApplicationFormId';
import ApplicationStatus from './ApplicationStatus';
import ApplicationBaseInfo from './ApplicationBaseInfo';
import ApplicationRoute from './ApplicationRoute';
import ApplicationTransportsInfo from './ApplicationTransportsInfo';
import ApplicationBaseTransportInfo from './ApplicationBaseTransportInfo';
import SpecialConditions from './SpecialConditions';
import IssuePlace from './IssuePlace';
import ApplicationPriceInfo from './ApplicationPriceInfo';
import ApplicationAcceptance from './ApplicationAcceptance';
import ApplicationDeclineInfo from './ApplicationDeclineInfo';
import ApplicationAgreements from './ApplicationAgreements';
import ApplicationBillInfo from './ApplicationBillInfo';
import ApplicationFiles from './ApplicationFiles';
import ApplicationRouteComment from './ApplicationRouteComment';
import ControlMarksBlock from './controlMarks/ControlMarksBlock';
import ApplicationFirmPrice from './ApplicationFirmPrice';

const ApplicationViewPage = ({ application }) => {
  return (
    <main role="main" className="container application-container new-application-container">
      <ApplicationNumber application={application} />
      <ApplicationActions application={application} />
      <ApplicationFormId formId={application.form_id} />
      <ApplicationStatus application={application} />
      <ApplicationBaseInfo application={application} />
      <ApplicationRoute
        privilegeStatusId={application.privilege_status_id}
        route={application.route}
      />
      <ApplicationRouteComment />
      <div className="row">
        <div className="col-md-10">
          <ApplicationBaseTransportInfo application={application} />
          <ApplicationTransportsInfo application={application} />
          <SpecialConditions application={application} />
          <IssuePlace application={application} />
          <ApplicationPriceInfo application={application} />
          <ApplicationAcceptance admin={application.admin} acceptDate={application.accept_date} />
          <ApplicationDeclineInfo admin={application.admin} />
          <ApplicationAgreements application={application} />
          <ControlMarksBlock />
          <ApplicationFirmPrice application={application} />
          <ApplicationBillInfo application={application} />
          <ApplicationFiles application={application} />
        </div>
      </div>
    </main>
  );
};

export default ApplicationViewPage;
