import React from 'react';
import Button from '@material-ui/core/Button';

const ToWork = ({ action }) => (
  <Button variant="text" className="info-btn" color="primary" onClick={action}>
    Взять в работу
  </Button>
);

export default ToWork;
