import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Select from 'react-select';
import { withSnackbar } from 'notistack';
import { Loader } from '../../../../components';
import { applicationService } from '../../../../services/applicationService';
import { getApplicationId } from '../../../../selectors/applicationSelectors';
import * as actions from '../../../../actions/newApplicationActions';

const defaultOption = { value: 0, label: 'Ведомство для согласования' };

const AgreementsForm = ({ applicationId, departments, addAgreement, enqueueSnackbar }) => {
  const [submitting, setSubmitting] = useState(false);
  const [department, setDepartment] = useState(defaultOption);
  const onSubmit = React.useCallback(async () => {
    setSubmitting(true);
    try {
      const response = await applicationService.sendToDepartment({
        id: applicationId,
        values: { department_id: department.value }
      });
      if (response.data) {
        addAgreement(response.data);
        enqueueSnackbar(`Разрешение отправлено на согласование в ${department.label}`, {
          variant: 'success'
        });
        setDepartment(defaultOption);
      } else {
        enqueueSnackbar('Произошла ошибка при отправке на согласование', { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar('Произошла ошибка при отправке на согласование', { variant: 'error' });
    }
    setSubmitting(false);
  }, [department]);
  return (
    <div className="row">
      <div className="col-md-8">
        <Select
          isSearchable={false}
          value={department}
          disabled={submitting}
          options={[
            defaultOption,
            ...departments.map(option => ({
              label: option.title,
              value: option.id
            }))
          ]}
          onChange={newValue => setDepartment(newValue)}
        />
      </div>
      <div className="col-md-4 text-right">
        <Button
          variant="outlined"
          color="primary"
          className="info-btn no-margin full-width"
          type="submit"
          disabled={submitting || department.value === 0}
          onClick={onSubmit}
        >
          {submitting && <Loader />} Отправить на согласование
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  departments: state.departments.items || [],
  applicationId: getApplicationId(state)
});

const mapDispatchToProps = {
  addAgreement: actions.addAgreement
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AgreementsForm));
