import React from 'react';
import { DropdownList } from 'react-widgets';

export const renderDropdownList = ({
  input,
  data,
  valueField,
  textField,
  meta: { touched, error }
}) => (
  <div>
    <DropdownList
      {...input}
      onBlur={() => input.onBlur()}
      value={input.value || ''} // requires value to be an array
      data={data}
      valueField={valueField}
      textField={textField}
      onChange={input.onChange}
      filter="contains"
    />
    {touched && error && <span>{error}</span>}
  </div>
);
