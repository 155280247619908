import { authConstants } from '../constants/authConstants';
import { cabinetConstants } from '../constants/cabinetConstants';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.user
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false
      };
    case authConstants.LOGOUT:
      return {};
    case authConstants.PASSWORD_VISIBLE_TOGGLE:
      return {
        ...state,
        showLoginPassword: !state.showLoginPassword
      };
      case authConstants.SAVE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.user.email,
          inn: action.user.inn,
          name: action.user.name,
          confirmation_status: action.user.confirmation_status,
          firm: {
              ...state.user.firm,
              ...action.user.firm
          },
        }
      };

    case authConstants.EMAIL_CONFIRMATION_SUCCESS:
      return {
        loggingIn: false,
        loggedIn: true,
        user: action.user
      };

    case cabinetConstants.GET_INFO_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          confirmation_status: action.data.confirmation_status
        }
      };
    default:
      return state;
  }
};
