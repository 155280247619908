import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import VehiclesTableList from './VehiclesTableList';
import { vehicleActions } from '../../../actions/vehicleActions';

const TabContainer = props => {
  return <Typography component="div">{props.children}</Typography>;
};

const VehiclesTab = props => {
  const { adminVehicles } = props;

  const filterSubmit = (values, dispatch) => {
    return dispatch(vehicleActions.adminFilter(values));
  };
  return (
    <div>
      <TabContainer>
        <VehiclesTableList
          onSubmit={filterSubmit}
          data={adminVehicles.items}
          meta={adminVehicles.meta}
        />
      </TabContainer>
    </div>
  );
};

const mapStateToProps = state => {
  const { adminVehicles } = state;
  return {
    adminVehicles
  };
};

const connectedVehiclesTab = connect(mapStateToProps)(VehiclesTab);
export { connectedVehiclesTab as VehiclesTab };
