import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'privilege-statuses';
};

const getPage = page => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/page?page=${page}`,
    {},
    true
  );
};

const getAll = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}`, {}, true);
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};

// vehicles
const getVehiclesPage = (status_id, page) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/vehicles/${status_id}page=${page}`,
    {},
    true
  );
};

const createVehicle = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}vehicles`, values, true);
};

const updateVehicle = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/vehicles/${values.id}`,
    values,
    true
  );
};

const deleteVehicle = id => {
  return apiService.call(
    apiConstants.METHOD_DELETE,
    `/${getResourceName()}/vehicles/${id}`,
    {},
    true
  );
};

const checkStatus = number => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/check?number=${number}`,
    {},
    true
  );
};

export const privilegeStatusService = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,

  checkStatus,

  // vehicles
  getVehiclesPage,
  createVehicle,
  updateVehicle,
  deleteVehicle
};
