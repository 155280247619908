import React from 'react';
import {connect} from 'react-redux'
import {paymentsActions} from "../../../actions/paymentsActions";
import EditForm from './EditForm'
import {Button, Grid} from '@material-ui/core';
import {Loader} from "../../../components";
import {SubmissionError} from 'redux-form';
import {history} from "../../../helpers/history";
import {urlHelper} from "../../../helpers/urlHelper";
import DeleteModal from './DeleteModal'
import {roleConstants} from "../../../constants/roleConstants";

class AccountantPaymentShowPage extends React.Component {
    state = {
        editMode: false,
        showDelete: false
    }

    componentDidMount() {
        const {match: {params: {id}}, currentItem, dispatch} = this.props
        this.setState({
            editMode: this.props.match.path.toString().indexOf('/edit/') > -1
        })
        if (!currentItem && id) {
            dispatch(paymentsActions.getItem(id))
        }
    }

    handleEdit = () => {
        history.push(
            `${urlHelper.getHomeUrl(roleConstants.ROLE_ACCOUNTANT)}/payments/edit/` + this.props.match.params.id
        );
    }
    handleDelete = () => {
        this.setState({...this.state, showDelete: true})
    }

    render() {
        const {currentItem, loading} = this.props
        return (

            <Grid
                container
                role="main"
                className="container text-left"
                direction="column"
                spacing={16}
                wrap="nowrap"
            >
                <Grid item>
                    <Grid container spacing={16}>
                        <Grid item>
                            Платеж {currentItem && `№ ${currentItem.id}  `}
                            {!this.state.editMode && (
                                    <button
                                        className="btn"
                                        color="primary"
                                        type="button"
                                        onClick={this.handleEdit}
                                    >
                                        Изменить
                                    </button>
                            )}

                            {!this.state.editMode && (
                                        <Button
                                            className=" decline-btn"
                                            variant="outlined"
                                            type="button"
                                            onClick={this.handleDelete}
                                        >
                                            Аннулировать
                                        </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className="">
                    {(!currentItem) ? (
                        <Loader size={45}/>
                    ) : (
                        <EditForm payment={currentItem} onSubmit={this.props.handleSubmit} edit={this.state.editMode}/>
                    )}
                </Grid>
                {this.state.showDelete && (
                    <DeleteModal
                        modelId={currentItem.id}
                        onClose={() => {this.setState({...this.state, showDelete:false})}}/>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.payments
    }
}
const mapDispatchToProps = (dispatch, props) => {
    const handleSubmit = (values) => {
        if (!values.id) return null;
        return dispatch(paymentsActions.update({
            id: values.id,
            price: values.price,
            payment_create_date: values.payment_create_date ? values.payment_create_date : values.created_at
        })).then((r) => {
            alert('Данные сохранены')
        }).catch((e) => {
            if (e instanceof SubmissionError) {
                const key = Object.keys(e.errors)[0]
                alert(e.errors[key].toString())
            }
        })
    }
    return {
        handleSubmit,
        dispatch
    };
}
const ConAccountantPaymentShowPage = connect(mapStateToProps, mapDispatchToProps)(AccountantPaymentShowPage)
export {ConAccountantPaymentShowPage as AccountantPaymentShowPage}
