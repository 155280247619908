import React from 'react';
import Place from '@material-ui/icons/Place';
import Lens from '@material-ui/icons/FiberManualRecord';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

export const ApplicationRoute = props => {
  const { application } = props;

  const maxLength = 25;
  const routeInfo = application.privilegeStatus ? application.privilegeStatus.route_info : '';
  let routePrefix = routeInfo;
  if (routeInfo.length > maxLength) {
    routePrefix = `${routeInfo.substring(0, maxLength)}...`;
  }

  return (
    <Table>
      <TableBody>
        {!application.privilege_status_id && (
          <TableRow>
            <TableCell>
              <Lens className="place_icons blue_icon" /> {application.start_address}
            </TableCell>
          </TableRow>
        )}
        {!application.privilege_status_id && (
          <TableRow>
            <TableCell>
              <Place className="place_icons red_icon" /> {application.finish_address}
            </TableCell>
          </TableRow>
        )}

        {application.privilege_status_id && (
          <TableRow>
            <TableCell>Маршрут</TableCell>
            <TableCell numeric>{routePrefix}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default ApplicationRoute;
