import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import { apiConstants } from '../../constants/apiConstants';
import mainLogo from './img/logo.png';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div className="cabinet-header">
            <header>
              <nav className="navbar navbar-expand-md">
                <div className="row">
                  <div className="col-md-8">
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item active">
                        <a className="nav-link undefined" href="/">
                          <img src={mainLogo} className="logo" />
                        </a>
                      </li>
                      <li className="nav-item active">
                        <a className="nav-link logo-title-inner" href="/">
                          АИС КТГ
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </header>
          </div>
          <div className="text-center">
            <h1>Произошла ошибка!</h1>
            <h4 className="back">
              <a href="/">
                <BackIcon /> <span>Главная страница</span>
              </a>
            </h4>
          </div>
          <footer className="footer">
            <div className="row">
              <div className="col-md-3">
                <span className="text-muted">Email:</span> ais.ktg@tatar.ru
                {/* <br />
                <span className="text-muted">Техническая поддержка:</span>{' '}
                <small>+7 (843) 5-333-813</small> */}
              </div>
              <div className="col-md-5">
                <span className="text-muted">По вопросам регистрации ТС и выдаче разрешений:</span>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <small>Казань: +7 (843) 5-333-797</small>
                    <br />
                    <small>Н.Челны: +7 (855) 2-770-917</small>
                  </div>
                  <div className="col-md-6">
                    <small>Альметьевск: +7 (855) 3-439-066</small>
                    <br />
                    <small>Нурлат: +7 (843) 4-529-326</small>
                  </div>
                </div>
              </div>
              <div className="col-md-5 text-right">
                <a href="/agreement">О нас</a>
                <a target="_blank" href={`${apiConstants.API_URL}/storage/info/instructions.pdf`}>
                  Инструкция
                </a>
                <a href="/personal">О персональных данных</a>
              </div>
            </div>
          </footer>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
