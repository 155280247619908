import { cabinetConstants } from '../constants/cabinetConstants';
import { authConstants } from '../constants/authConstants';

const removeFile = (files, id) => {
  return files.filter(item => item.id !== id);
};

const initialState = {
  showPasswordChange: true,
  showChangeDataModal: false,
  innFiles: [],
  infoStatus: '',
  passwordStatus: '',
  message: ''
};

export const cabinet = (state = initialState, action) => {
  switch (action.type) {
    case cabinetConstants.PASSWORD_CHANGE_TOGGLE:
      return {
        ...state,
        showPasswordChange: !state.showPasswordChange
      };
    // Get info
    case cabinetConstants.GET_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cabinetConstants.GET_INFO_SUCCESS:
      return {
        ...state,
        info: action.data,
        loading: false
      };

    // Get user info
    case cabinetConstants.GET_FIRMUSER_FIRM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cabinetConstants.GET_FIRMUSER_FIRM_SUCCESS:
      return {
        ...state,
        firm: action.data,
        loading: false
      };

    case cabinetConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить информацию!'
      };

    // Save info
    case cabinetConstants.SAVE_INFO_REQUEST:
      return {
        ...state,
        showChangeDataModal: false,
        infoStatus: ''
      };
    case cabinetConstants.SAVE_INFO_SUCCESS:
      return {
        ...state,
        infoStatus: 'Данные успешно сохранены!'
      };
    case cabinetConstants.SAVE_INFO_FAILURE:
      return {
        ...state,
        infoStatus: 'Не удалось сохранить данные!'
      };

    // Change password
    case cabinetConstants.PASSWORD_CHANGE_REQUEST:
      return {
        ...state,
        passwordStatus: ''
      };
    case cabinetConstants.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        passwordStatus: 'Пароль успешно изменен!'
      };
    case cabinetConstants.PASSWORD_CHANGE_FAILURE:
      return {
        ...state,
        passwordStatus: 'Не удалось сохранить пароль!'
      };

    case cabinetConstants.INN_UPLOAD_SUCCESS:
      return {
        ...state,
        innFiles: [...state.innFiles, action.data]
      };
    case cabinetConstants.INN_REMOVE:
      return {
        ...state,
        innFiles: removeFile(state.innFiles, action.id)
      };
    case cabinetConstants.SET_UPLOAD_INNS:
      return {
        ...state,
        innFiles: action.files
      };

    case cabinetConstants.DATA_CONFIRMATION_OPEN:
      return {
        ...state,
        showChangeDataModal: true
      };
    case cabinetConstants.DATA_CONFIRMATION_CLOSE:
      return {
        ...state,
        showChangeDataModal: false
      };

    case cabinetConstants.RESET_STATUSES:
      return {
        ...state,
        infoStatus: '',
        passwordStatus: ''
      };

    case authConstants.SAVE_USER_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          data: action.user
        }
      };

    case authConstants.SET_MESSAGE:
      return {
        ...state,
        message: action.message
      };

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
