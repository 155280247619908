export const cabinetConstants = {
  PASSWORD_CHANGE_TOGGLE: 'CABINET_PASSWORD_CHANGE_TOGGLE',

  GET_INFO_REQUEST: 'CABINET_GET_INFO_REQUEST',
  GET_INFO_SUCCESS: 'CABINET_GET_INFO_SUCCESS',
  GET_INFO_FAILURE: 'CABINET_GET_INFO_FAILURE',

  SAVE_INFO_REQUEST: 'CABINET_SAVE_INFO_REQUEST',
  SAVE_INFO_SUCCESS: 'CABINET_SAVE_INFO_SUCCESS',
  SAVE_INFO_FAILURE: 'CABINET_SAVE_INFO_FAILURE',

  PASSWORD_CHANGE_REQUEST: 'CABINET_PASSWORD_CHANGE_REQUEST',
  PASSWORD_CHANGE_SUCCESS: 'CABINET_PASSWORD_CHANGE_SUCCESS',
  PASSWORD_CHANGE_FAILURE: 'CABINET_PASSWORD_CHANGE_FAILURE',

  INN_UPLOAD_REQUEST: 'CABINET_INN_UPLOAD_REQUEST',
  INN_UPLOAD_SUCCESS: 'CABINET_INN_UPLOAD_SUCCESS',
  INN_UPLOAD_FAILURE: 'CABINET_INN_UPLOAD_FAILURE',

  INN_REMOVE: 'CABINET_INN_REMOVE',
  SET_UPLOAD_INNS: 'CABINET_SET_UPLOAD_INNS',

  GET_FIRMUSER_FIRM_REQUEST: 'CABINET_GET_FIRMUSER_FIRM_REQUEST',
  GET_FIRMUSER_FIRM_SUCCESS: 'CABINET_GET_FIRMUSER_FIRM_SUCCESS',
  GET_FIRMUSER_FIRM_FAILURE: 'CABINET_GET_FIRMUSER_FIRM_FAILURE',

  DATA_CONFIRMATION_OPEN: 'CABINET_DATA_CONFIRMATION_OPEN',
  DATA_CONFIRMATION_CLOSE: 'CABINET_DATA_CONFIRMATION_CLOSE',

  RESET_STATUSES: 'CABINET_RESET_STATUSES'
};
