import React, {Component} from 'react';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {change} from 'redux-form';
import {apvgkReportsConstants} from '../../../constants/apvgkReportsConstants';
import {ReportsTab} from './ReportsTab';
import {apvgkReportsActions} from '../../../actions/apvgkReportsActions';
import Filter from './Filter'
import Grid from '@material-ui/core/Grid';
import {Link} from "react-router-dom";
import {roleConstants} from "../../../constants/roleConstants";


class ApvgkReportsListPage extends Component {
    state = {
        filter: {},
    }
    componentDidMount() {
        const { apvgkReports } = this.props;

        if (apvgkReports.reasons.length == 0 ) {
            this.props.dispatch(apvgkReportsActions.getReasons())
        }
        if (!apvgkReports.items || apvgkReports.items.length === 0) {
            this.props.dispatch(apvgkReportsActions.getApvgkReports({page: 1,  ...apvgkReports.filter, status: apvgkReports.tabStatus || apvgkReportsConstants.STATUS_NEW,}));
        }
    }
    handleFilterChange = (values, pdf) => {
        if (values) {
            values.page = 1;
            this.setState({filter: values})
        }
        this.props.dispatch(apvgkReportsActions.getApvgkReports({status: this.props.apvgkReports.tabStatus, ...this.state.filter, ['export']:!!pdf }))
    }
    render() {
        const { apvgkReports, handleTabChange, handlePageChange , handlePerPageChange , user} = this.props;
        const { tabIndex } = apvgkReports;
        return (
            <div>

                <Grid
                    container
                    role="main"
                    className="text-left"
                    direction="column"
                    spacing={16}
                    wrap="nowrap"
                >
                    <Grid item container spacing={16} style={{padding: 25}}>
                        <Grid item>
                            <h2 className="h2-title no-margin">Проверка нарушений</h2>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={16}>
                                <Grid item>
                                    <Filter currentFilters={apvgkReports.filter} reasons={apvgkReports.reasons} onFiltersChange={this.handleFilterChange}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        {user && user.role_id == roleConstants.ROLE_ADMIN && tabIndex >= apvgkReportsConstants.STATUS_DECLINE&& <Grid item>
                            <Grid container direction="column" spacing={16}>
                                <Grid item>
                                    <Link

                                        color="primary"
                                        to={{}}
                                        onClick={() => this.handleFilterChange(null, 1)}
                                    >
                                        Скачать отчет
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>
                <main role="main" className="container text-center">
                    <div className="kyc">
                        <AppBar position="static" color="default" className="tabs_header">
                            <Tabs
                                value={tabIndex}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                className="tab_style"
                                variant="fullWidth"
                            >
                                <Tab
                                    classes={{ selected: 'tab_active' }}
                                    label="Новые"
                                    disabled={apvgkReports.loading}
                                    value={apvgkReportsConstants.STATUS_NEW}
                                />
                                <Tab
                                    classes={{ selected: 'tab_active' }}
                                    label="В работе"
                                    disabled={apvgkReports.loading}
                                    value={apvgkReportsConstants.STATUS_IN_WORK}
                                />
                                <Tab
                                    classes={{ selected: 'tab_active' }}
                                    label="Отклоненные"
                                    disabled={apvgkReports.loading}
                                    value={apvgkReportsConstants.STATUS_DECLINE}
                                />
                                <Tab
                                    classes={{ selected: 'tab_active' }}
                                    label="Одобренные"
                                    disabled={apvgkReports.loading}
                                    value={apvgkReportsConstants.STATUS_ACCEPT}
                                />
                            </Tabs>
                        </AppBar>
                        <ReportsTab tabIndex={tabIndex} paginateProps={{
                            handlePageChange,
                            handlePerPageChange,
                        }}/>
                    </div>
                </main>
        </div>
        );
    }
}

const mapStateToProps = state => {
    const { apvgkReports, authentication } = state;
    return {
        apvgkReports, authentication
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { apvgkReports, authentication} = stateProps;
    const { dispatch } = dispatchProps;
    const statusesByIndex = [
        apvgkReportsConstants.STATUS_NEW,
        apvgkReportsConstants.STATUS_IN_WORK,
        apvgkReportsConstants.STATUS_DECLINE,
        apvgkReportsConstants.STATUS_ACCEPT,
    ]
    const updateApvgkReports = (filter) => {
        const values = {
            page: 1,
            ...apvgkReports.filter,
            ...filter,
        }
        return dispatch(apvgkReportsActions.getApvgkReports(values));
    }
    const handleTabChange = (event, tabIndex) => {
        if (tabIndex !== apvgkReports.tabIndex) {
            dispatch(apvgkReportsActions.setTab(tabIndex, statusesByIndex[tabIndex]));
            // set filter form value
            dispatch(change('apvgk-reports-filter-form', 'status', tabIndex));
            updateApvgkReports({
                status: statusesByIndex[tabIndex],
                page: 1,
            })
        }
    };
    const handlePageChange = (page) => {updateApvgkReports({page})};
    const handlePerPageChange = (per_page) => {updateApvgkReports({per_page})};

    return {
        ...ownProps,
        dispatch,
        apvgkReports,
        handleTabChange,
        handlePageChange,
        handlePerPageChange,
        user:authentication.user,
    };
};

const connectedRegAppsPage = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ApvgkReportsListPage);

export { connectedRegAppsPage as ApvgkReportsListPage };
