import React from 'react';
import { sum } from 'lodash';
import { Grid } from '@material-ui/core';
import Input from './Input';
import FieldLabel from './FieldLabel';

function roundPrice(price) {
  return Math.round(price * 100) / 100;
}

const Damage = ({ priceInfo, isSpring, hideRegular }) => {
  if (!priceInfo) {
    return null;
  }
  return (
    <>
      {!hideRegular && (
        <>
          <Grid item>
            <FieldLabel
              label="Стоимость движения по дорогам с нормативной нагрузкой 10 т/ось"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={roundPrice(priceInfo.price[0])} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Стоимость движения по дорогам с нормативной нагрузкой 11,5 т/ось"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={roundPrice(priceInfo.price[1])} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Стоимость движения по дорогам с нормативной нагрузкой 6 т/ось"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={roundPrice(priceInfo.price[2])} />
            </FieldLabel>
          </Grid>
        </>
      )}
      {isSpring && (
        <>
          <Grid item>
            <FieldLabel
              label="Стоимость движения по дорогам с нормативной нагрузкой 10 т/ось (Весна)"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={roundPrice(priceInfo.spring_price[0])} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Стоимость движения по дорогам с нормативной нагрузкой 11,5 т/ось (Весна)"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={roundPrice(priceInfo.spring_price[1])} />
            </FieldLabel>
          </Grid>
          <Grid item>
            <FieldLabel
              label="Стоимость движения по дорогам с нормативной нагрузкой 6 т/ось (Весна)"
              labelProps={{ xs: 4, lg: 3 }}
              childrenProps={{ xs: 8, lg: 2 }}
            >
              <Input value={roundPrice(priceInfo.spring_price[2])} />
            </FieldLabel>
          </Grid>
        </>
      )}
      {!hideRegular && (
        <Grid item>
          <FieldLabel label="Итого" labelProps={{ xs: 4, lg: 3 }} childrenProps={{ xs: 8, lg: 2 }}>
            <Input value={roundPrice(sum(priceInfo.price))} />
          </FieldLabel>
        </Grid>
      )}
      {isSpring && (
        <Grid item>
          <FieldLabel
            label="Итого (Весна)"
            labelProps={{ xs: 4, lg: 3 }}
            childrenProps={{ xs: 8, lg: 2 }}
          >
            <Input value={roundPrice(sum(priceInfo.spring_price))} />
          </FieldLabel>
        </Grid>
      )}
    </>
  );
};

export default Damage;
