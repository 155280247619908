import React from 'react';
import { Button, Dialog, Toolbar, IconButton, Typography, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import VehicleAccessModalList from './VehicleAccessModalList';

const VehicleAccessModal = ({ vehicle }) => {
  const [show, setShow] = React.useState(false);
  const closeModal = () => setShow(false);
  return (
    <>
      <Dialog open={show} onClose={closeModal} aria-labelledby="form-dialog-title" maxWidth="xl">
        <Toolbar className="text-left">
          <Typography variant="title" color="inherit" className="flex">
            Доступ к ТС {vehicle.real_number}
          </Typography>
          <IconButton color="inherit" onClick={closeModal}>
            <CloseIcon className="blue" />
          </IconButton>
        </Toolbar>

        <DialogContent className="dialog">
          <VehicleAccessModalList vehicle={vehicle} onClose={closeModal} />
        </DialogContent>
      </Dialog>
      <Button variant="outlined" className="btn-add" size="small" onClick={() => setShow(true)}>
        Доступ к ТС
      </Button>
    </>
  );
};

export default VehicleAccessModal;
