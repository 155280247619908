import { uniqueId } from 'lodash';
import { applicationService } from './applicationService';
import {apiService} from "./apiService";
import {apiConstants} from "../constants/apiConstants";
import CertificateObj from '../helpers/CertificateObj'
// пример подписи о проверки подписи для файла
// https://docs.cryptopro.ru/cades/plugin/plugin-samples/plugin-samples-fileapi
window.loadCadesplugin();



function fetchCerts(certSubjectName) {
  return new Promise((resolve, reject) => {
    window.cadesplugin.async_spawn(
      function*(args) {
        try {
          const oStore = yield window.cadesplugin.CreateObjectAsync('CAdESCOM.Store');
          yield oStore.Open(
            window.cadesplugin.CADESCOM_CONTAINER_STORE,
            window.cadesplugin.CAPICOM_MY_STORE,
            window.cadesplugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED
          );

          const certs = yield oStore.Certificates;
          const count = yield certs.Count;
          const certsToResolve = [];
          if (count === 0) {
            args[1]([]);
            return;
          }
          for (let i = 1; i <= count; i++) {
            let cert;
            try {
              cert = yield certs.Item(i);
            } catch (ex) {
              console.error(ex);
              break;
            }

            try {
              const oPrivateKey = yield cert.PrivateKey;
              var certObj = new CertificateObj(cert);
              const sPrivateKeyLink = yield oPrivateKey.UniqueContainerName;
              certsToResolve.push({
                label: certObj.GetCertString(),
                id: uniqueId(),
                cert
              });
            } catch (ex) {
              console.error(ex);
              break;
            }
          }

          yield oStore.Close();

          args[1](certsToResolve);
        } catch (e) {
          args[2](`Failed to create signature. Error: ${window.cadesplugin.getLastError(e)}`);
        }
      },
      certSubjectName,
      resolve,
      reject
    );
  });
}

const hasPlugin = async () => {
  if (!window.cadesplugin) {
    return false;
  }
  try {
    await window.cadesplugin;
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

async function getCerts() {
  const plugin = await hasPlugin();
  if (!plugin) {
    return [];
  }

  return fetchCerts('CertName');
}

async function getParams(cert) {
  return new Promise((resolve, reject) => {
    window.cadesplugin.async_spawn(
      function*(args) {
        const oPublicKey = yield cert.PublicKey();
        const oAlgorithm = yield oPublicKey.Algorithm;
        const algoOid = yield oAlgorithm.Value;
        console.log(algoOid);
        let signMethod = '';
        let digestMethod = '';
        if (algoOid === '1.2.643.7.1.1.1.1') {
          // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 256 бит
          signMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-256';
          digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-256';
        } else if (algoOid === '1.2.643.7.1.1.1.2') {
          // алгоритм подписи ГОСТ Р 34.10-2012 с ключом 512 бит
          signMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102012-gostr34112012-512';
          digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34112012-512';
        } else if (algoOid === '1.2.643.2.2.19') {
          // алгоритм ГОСТ Р 34.10-2001
          signMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102001-gostr3411';
          digestMethod = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr3411';
        }
        args[2]([signMethod, digestMethod]);
      },
      cert,
      null,
      resolve,
      reject
    );
  });
}

async function signXml(cert, certExport, applicationId, data, filesSignature) {
  const [signMethod, digestMethod] = await getParams(cert);
  const xml = await applicationService.getEcpData(
    applicationId,
    certExport,
    signMethod,
    digestMethod,
    data,
    filesSignature
  );
  return new Promise((resolve, reject) => {
    window.cadesplugin.async_spawn(
      function*(args) {
        try {
          const oSigner = yield window.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
          yield oSigner.propset_Certificate(cert);
          yield oSigner.propset_CheckCertificate(true);

          // Создаем объект CAdESCOM.SignedXML
          const oSignedXML = yield window.cadesplugin.CreateObjectAsync('CAdESCOM.SignedXML');
          yield oSignedXML.propset_Content(xml);

          yield oSignedXML.propset_SignatureType(window.cadesplugin.CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE);

          const sSignedMessage = yield oSignedXML.Sign(oSigner);
          console.log(sSignedMessage);
          args[2](sSignedMessage);
        } catch (e) {
          console.log(e);
          args[3](`Failed to create signature. Error: ${window.cadesplugin.getLastError(e)}`);
        }
      },
      cert,
      null,
      resolve,
      reject
    );
  });
}

function sign(cert, dataToSign) {
  return new Promise((resolve, reject) => {
    window.cadesplugin.async_spawn(
      function*(args) {
        try {
          const oSigner = yield window.cadesplugin.CreateObjectAsync('CAdESCOM.CPSigner');
          yield oSigner.propset_Certificate(cert);

          const oSignedData = yield window.cadesplugin.CreateObjectAsync(
            'CAdESCOM.CadesSignedData'
          );
          yield oSignedData.propset_Content(dataToSign);

          const sSignedMessage = yield oSignedData.SignCades(oSigner, window.cadesplugin.CADESCOM_CADES_BES);
          console.log(sSignedMessage);
          args[2](sSignedMessage);
        } catch (e) {
          console.log(e);
          args[3](`Failed to create signature. Error: ${window.cadesplugin.getLastError(e)}`);
        }
      },
      cert,
      dataToSign,
      resolve,
      reject
    );
  });
}

function signFile(content, cert) {
    return new Promise((resolve, reject) => {
        window.cadesplugin.async_spawn(
            function*(args) {
                try {
                    var errormes = "";
                    try {
                        var oSigner = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
                    } catch (err) {
                        errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
                        throw errormes;
                    }
                    var oSigningTimeAttr = yield window.cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");

                    var CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
                    yield oSigningTimeAttr.propset_Name(CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME);
                    var oTimeNow = new Date();
                    yield oSigningTimeAttr.propset_Value(oTimeNow);
                    var attr = yield oSigner.AuthenticatedAttributes2;
                    yield attr.Add(oSigningTimeAttr);


                    var oDocumentNameAttr = yield window.cadesplugin.CreateObjectAsync("CADESCOM.CPAttribute");
                    var CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
                    yield oDocumentNameAttr.propset_Name(CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME);
                    yield oDocumentNameAttr.propset_Value("Акт взвешивания");
                    yield attr.Add(oDocumentNameAttr);

                    if (oSigner) {
                        yield oSigner.propset_Certificate(cert);
                    }
                    else {
                        errormes = "Failed to create CAdESCOM.CPSigner";
                        throw errormes;
                    }

                    var oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
                    var CADES_BES = 1;

                    var dataToSign = content;
                    console.log({dataToSign})
                    if (dataToSign) {
                        // Данные на подпись ввели
                        yield oSignedData.propset_ContentEncoding(1); //CADESCOM_BASE64_TO_BINARY
                        yield oSignedData.propset_Content(dataToSign);
                    }
                    // yield oSigner.propset_Options(window.cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY);
                    var Signature = yield oSignedData.SignCades(oSigner, CADES_BES, true);
                    args[2](Signature);
                } catch (e) {
                    console.error(e);
                    args[3](`Failed to create signature. Error: ${window.cadesplugin.getLastError(e)}`);
                }
            },
            cert,
            content,
            resolve,
            reject
        );
    });
}

function getHash(content) {
  return new Promise((resolve, reject) => {
    window.cadesplugin.async_spawn(
      function*(args) {
        try {
          const oHashedData = yield window.cadesplugin.CreateObjectAsync('CAdESCOM.HashedData');

          // Алгоритм хэширования нужно указать до того, как будут переданы данные
          yield oHashedData.propset_Algorithm(window.cadesplugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411);

          // Указываем кодировку данных
          // Кодировка должна быть указана до того, как будут переданы сами данные
          yield oHashedData.propset_DataEncoding(window.cadesplugin.CADESCOM_BASE64_TO_BINARY);

          // Передаем данные
          yield oHashedData.Hash(content);

          // Получаем хэш-значение
          const sHashValue = yield oHashedData.Value;
          args[2](sHashValue);
        } catch (e) {
          console.error(e);
          args[3](`При создании подписи произошла ошибка : ${window.cadesplugin.getLastError(e)}`);
        }
      },
      null,
      content,
      resolve,
      reject
    );
  });
}

function downloadFileContent(file, asBlob = true) {
    return apiService
        .call(apiConstants.METHOD_GET, file.url, undefined, true, asBlob ? { responseType: 'blob' } : {}, false)
        .then(
            response =>
                new Promise(resolve => {
                    let file = 'act.pdf';
                    try {
                        file = response.headers['content-disposition'].split('=')[1].split('"')[1];
                    } catch (e) {console.log(e);}
                    if (!asBlob) {
                        resolve([response.data, file]);
                        return;
                    }
                    const reader = new window.FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = () => {
                        const header = ';base64,';
                        const sFileData = reader.result;
                        const sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);
                        resolve([sBase64Data, file]);
                    };
                })
        )
        .catch(e => console.log(e));
}
const signCheck = function (fileSignature, content) {
    return new Promise(function(resolve, reject){
        window.cadesplugin.async_spawn(function *(args) {
            var oSignedData = yield window.cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
            try {
                yield oSignedData.propset_ContentEncoding(window.cadesplugin.CADESCOM_BASE64_TO_BINARY);
                yield oSignedData.propset_Content(content);
                try {
                    oSignedData.VerifyCades(fileSignature, window.cadesplugin.CADESCOM_CADES_BES, true);
                    args[1](true)
                    return
                } catch (err) {
                    alert("При проверке подписи произошла ошибка: " + window.cadesplugin.getLastError(err));
                }
                args[1](false)
            }
            catch (e) {
                args[2]("При создании подписи произошла ошибка: " + window.cadesplugin.getLastError(e));
            }
        }, fileSignature, resolve, reject);
    });
}

export const SignService = {
  hasPlugin,
  getCerts,
  sign,
  signXml,
  signFile,
  getHash,
  downloadFileContent,
  signCheck
};
