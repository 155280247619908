import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { change } from 'redux-form';
import { regAppConstants } from '../../../constants/regAppConstants';
import { RegAppsTab } from './RegAppsTab';
import { regAppActions } from '../../../actions/regAppActions';

class RegAppsListPage extends Component {
  componentDidMount() {
    const { regApps } = this.props;

    if (!regApps.items || regApps.items.length === 0) {
      this.props.dispatch(regAppActions.getPageByStatus(1, regApps.tabIndex, regApps.roleIndex));
    }
  }

  render() {
    const { regApps, handleTabChange } = this.props;
    const { tabIndex } = regApps;

    return (
      <main role="main" className="container text-center">
        <div className="kyc">
          <h2 className="h2-title">Заявки на регистрацию</h2>

          <AppBar position="static" color="default" className="tabs_header">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className="tab_style"
              variant="fullWidth"
            >
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Новые"
                value={regAppConstants.STATUS_NEW}
              />
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Отклоненные"
                value={regAppConstants.STATUS_DECLINE}
              />
              <Tab
                classes={{ selected: 'tab_active' }}
                label="На рассмотрении"
                value={regAppConstants.STATUS_REVIEW}
              />
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Активные"
                value={regAppConstants.STATUS_ACCEPT}
              />
            </Tabs>
          </AppBar>

          <RegAppsTab />
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { regApps } = state;
  return {
    regApps
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { regApps } = stateProps;
  const { dispatch } = dispatchProps;

  const handleTabChange = (event, tabIndex) => {
    if (tabIndex !== regApps.tabIndex) {
      dispatch(regAppActions.setTab(tabIndex));
      dispatch(regAppActions.getPageByStatus(1, tabIndex, regApps.roleIndex));
      // set filter form value
      dispatch(change('application-admin-filter-form', 'status', tabIndex));
    }
  };

  return {
    ...ownProps,
    dispatch,
    regApps,
    handleTabChange
  };
};

const connectedRegAppsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(RegAppsListPage);

export { connectedRegAppsPage as RegAppsListPage };
