import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm, submit } from 'redux-form';
import { isEmpty } from 'lodash';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { privilegeVehicleActions } from '../../../../../actions/privilegeVehicleActions';
import { Loader } from '../../../../../components/Loader';
import TablePagination from '../../../../../components/TablePagination';
import StatusVehiclesFilterForm from './StatusVehicleFilterForm';
import { useMountEffect } from '../../../../../helpers/hooks';

const StatusVehiclesTableList = ({
  statusId,
  vehicles,
  getVehicles,
  deleteVehicle,
  changeForm,
  submitForm,
  values,
  handleSubmit
}) => {
  const { items, meta, loading, error } = vehicles;

  useMountEffect(() => getVehicles(statusId, values));

  const handleChangePage = page => {
    changeForm('status-vehicle-admin-filter-form', 'page', page);
    setTimeout(() => {
      submitForm('status-vehicle-admin-filter-form');
    }, 200);
  };

  const haveNoItems = isEmpty(items);

  return (
    <form onSubmit={handleSubmit}>
      <Paper className="overflow">
        {items && (
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>№</TableCell>
                  <TableCell>ГРЗ</TableCell>
                  <TableCell>Марка</TableCell>
                  <TableCell>Модель</TableCell>
                  <TableCell numeric>Действие</TableCell>
                </TableRow>
                <StatusVehiclesFilterForm submitting={loading} />
              </TableHead>
              <TableBody>
                {(loading || error || haveNoItems) && (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      {!error && !loading && haveNoItems && <span>Объекты не найдены</span>}
                      {error && <span className="text-danger">Ошибка: {error}</span>}
                      {loading && <Loader />}
                    </TableCell>
                  </TableRow>
                )}
                {!loading &&
                  !error &&
                  items.map((vehicle, index) => (
                    <TableRow key={vehicle.id}>
                      <TableCell>{(meta.current_page - 1) * meta.per_page + index + 1}</TableCell>
                      <TableCell>{vehicle.real_number}</TableCell>
                      <TableCell>{vehicle.brandTitle}</TableCell>
                      <TableCell>{vehicle.modelTitle}</TableCell>
                      <TableCell numeric>
                        <Button
                          variant="outlined"
                          className="decline-btn"
                          size="small"
                          onClick={() => deleteVehicle(vehicle.id)}
                        >
                          {vehicle.deleting ? <Loader /> : <span>Удалить</span>}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        )}
      </Paper>
      <TablePagination meta={meta} onChangePage={handleChangePage} />
    </form>
  );
};

export default connect(
  state => ({
    vehicles: state.privilegeVehicles
  }),
  {
    getVehicles: privilegeVehicleActions.getVehicles,
    deleteVehicle: privilegeVehicleActions.delete,
    changeForm: change,
    submitForm: submit
  }
)(
  reduxForm({
    form: 'status-vehicle-admin-filter-form',
    enableReinitialize: true,
    onSubmit: (values, dispatch, { statusId, getVehicles }) => getVehicles(statusId, values)
  })(StatusVehiclesTableList)
);
