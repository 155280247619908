import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import moment from 'moment';
import CreateQuickApplication from './CreateQuickApplication';
import { Loader } from '../../../components';
import { applicationService } from '../../../services/applicationService';
import { privilegeVehicleService } from '../../../services/privilegeVehicleService';

function parseJSON(info) {
  try {
    return JSON.parse(info);
  } catch (e) {
    return [];
  }
}

async function getVehicles(privilegeStatus) {
  if (!privilegeStatus) {
    return [];
  }
  const res = await privilegeVehicleService.getVehicles(privilegeStatus.id, 1, 1000);
  return res.data || [];
}

async function parseFromServer(application) {
  const { fast, load, dates, route, privilegeStatus, vehicle, trailers, files } = application;
  const axles = parseJSON(load.axles_info);
  const status_vehicles = await getVehicles(privilegeStatus);
  const points = parseJSON(get(route, 'points', ''));
  let axleOffset = vehicle.axle_count;
  const transformed = {
    type: fast.role_id,
    name: fast.name,
    address: fast.address || '',
    inn: fast.inn || '',
    tripType: application.location_type,
    tripCount: dates.runs_count || '',
    restrictionDates: {},
    loadType: load.type_id,
    is_fast: application.fast && application.fast.id,
    is_spring: application.is_spring,
    loadName: load.name || '',
    transport: {
      brand: vehicle.brand_id,
      model: vehicle.model_id,
      region: vehicle.regions,
      number: vehicle.real_number.replace(vehicle.regions, '').trim(),
      axlesCount: vehicle.axle_count,
      initialWeight: vehicle.pts_weight,
      maxWeight: Number.parseFloat(vehicle.pts_max_weight),
      axles: axles.slice(0, vehicle.axle_count).map(axle => ({
        load: axle.axle_load,
        distance: axle.distance,
        permissibleLoad: axle.permissible_load,
        permissibleLoadSpring: axle.permissible_load_spring,
        suspension: axle.type_id,
        sloppiness: axle.wheel_count,
        wheelsCount: axle.wheels
      }))
    },
    trailers: (trailers || []).map(trailer => {
      const t = {
        brand: trailer.brand_id,
        model: trailer.model_id,
        region: trailer.regions,
        number: trailer.real_number.replace(trailer.regions, '').trim(),
        axlesCount: trailer.axle_count,
        initialWeight: trailer.pts_weight,
        maxWeight: Number.parseFloat(trailer.pts_max_weight),
        axles: axles.slice(axleOffset, axleOffset + trailer.axle_count).map(axle => ({
          load: axle.axle_load,
          distance: axle.distance,
          permissibleLoad: axle.permissible_load,
          permissibleLoadSpring: axle.permissible_load_spring,
          suspension: axle.type_id,
          sloppiness: axle.wheel_count,
          wheelsCount: axle.wheels
        }))
      };
      axleOffset += trailer.axle_count;
      return t;
    }),
    dimensions: { length: load.length, width: load.width, height: load.height },
    route: {
      status: privilegeStatus ? privilegeStatus.id : '',
      start_date: privilegeStatus
        ? moment(privilegeStatus.start_date, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : '',
      finish_date: privilegeStatus
        ? moment(privilegeStatus.finish_date, 'DD.MM.YYYY').format('YYYY-MM-DD')
        : '',
      route_info: get(privilegeStatus, 'route_info', ''),
      statusEntity: privilegeStatus,
      useStatus: !!privilegeStatus,
      steps:
        !privilegeStatus || !points || !points.length
          ? points.map(point => ({
              coords: {
                lat: get(point, 'coords.lat'),
                lng: get(point, 'coords.lng') || get(point, 'coords.lon'),
                lon: get(point, 'coords.lon')
              },
              text: point.text
            }))
          : [{ text: '' }, { text: '' }],
      status_vehicles
    },
    vehicleCorrect: true,
    totalWeight: '',
    routeType: route ? route.type_id : '',
    isPenalty: dates.is_penalty,
    penalty: {
      controlPost: dates.penalty_post_id,
      actNumber: dates.penalty_number,
      parking: dates.penalty_place,
      act: get(files, 'penalty_files[0]', null)
    },
    issuePlaces: dates.issue_place_id,
    escortCount: (load.escort || []).length,
    escort: load.escort.map(e => ({
      brand: e.brand,
      model: e.model,
      number: e.number
    }))
  };
  console.log(transformed);
  return transformed;
}

const CopyQuickApplication = ({ match }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialsValue] = useState({});
  const applicationId = match.params.id;
  useEffect(() => {
    applicationService.getFullItem(applicationId).then(async res => {
      const parsed = await parseFromServer(res.data);
      setInitialsValue(parsed);
      setIsLoading(false);
    });
  }, []);
  if (isLoading) {
    return (
      <div className="full-screen-loader-container">
        <Loader />
      </div>
    );
  }
  return <CreateQuickApplication initialValues={initialValues} />;
};

export default withRouter(CopyQuickApplication);
