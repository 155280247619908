import React from 'react';
import Grid from '@material-ui/core/Grid';
import { isObject } from 'lodash';
import { ErrorMessage } from 'formik';
import { withStyles } from '@material-ui/core';

const styles = {
  children: {
    width: '100%'
  }
};

const FieldLabel = ({ label, children, name, classes }) => (
  <Grid container direction="column">
    <Grid item className="has-error">
      {name && (
        <ErrorMessage name={name}>
          {msg =>
            isObject(msg) ? (
              Object.values(msg).map(m => (
                <span style={{ display: 'block' }} key={m}>
                  {m}
                </span>
              ))
            ) : (
              <span>{msg}</span>
            )
          }
        </ErrorMessage>
      )}
    </Grid>
    <Grid item>
      <span>{label}</span>
    </Grid>
    <Grid item className={classes.children}>
      {children}
    </Grid>
  </Grid>
);

export default withStyles(styles)(FieldLabel);
