import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { useAsync } from 'react-async';
import { Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { Button, CircularProgress, Grid } from '@material-ui/core';

import { isArray } from 'lodash';
import FieldLabel from '../../components/FieldLabel';
import Input from '../../components/Input';
import AgreementsForm from '../../components/AgreementsForm';
import { RouteMap } from '../ProcessRouteMap';
import DeclineModal from '../../components/DeclineModal';
import { applicationService } from '../../../../../services/applicationService';
import { Schema } from '../../util/Schema';
import { departmentService } from '../../../../../services/departmentService';
import { CooperationStatus } from '../../../../../constants/cooperationConstants';
import smevService from '../../../../../services/smevService';

function toPaths(paths) {
  return paths.map(path => ({
    id: path.id,
    text: path.name || path.name_prefix,
    steps: path.points.map(step => ({
      text: step.name,
      coords: {
        lat: +step.lat,
        lng: +(step.lon || step.lng)
      }
    }))
  }));
}

function getPoints(savedRoutes, points) {
  if (!points || points === '[]') {
    return [
      {
        useSaved: false,
        steps: [{ text: '' }, { text: '' }]
      }
    ];
  }
  const parsed = JSON.parse(points) || [];
  return parsed.map(path => ({
    useSaved: !isArray(path),
    ...(isArray(path)
      ? {
          steps: path.map(part => ({
            text: part.name,
            coords: part.coords
          }))
        }
      : savedRoutes.find(route => route.id === path.id_list))
  }));
}

const RequestProcessingInProgress = ({
  application,
  isSpring,
  reloadData,
  enqueueSnackbar,
  status
}) => {
  const { id, admin } = application;
  const [declineModal, setDeclineModal] = useState(false);
  const [agreements, setAgreements] = useState(application.application_agreements);
  const [savedRoutes, setSavedRoutes] = useState(null);
  useEffect(() => {
    async function fetchSaved() {
      const response = await smevService.getRoutesList();
      setSavedRoutes(toPaths(response));
    }
    fetchSaved();
  }, []);

  const { data: departments } = useAsync({ promiseFn: departmentService.getAll });
  const decline = useAsync({ deferFn: ([a]) => applicationService.agreementDecline(a) });
  const accept = useAsync({
    deferFn: ([a]) =>
      applicationService.agreementAccept(
        a.id,
        a.comment,
        a.waypoints,
        a.routeChanged,
        a.changeStatus,
        a.isSpring
      )
  });

  const onDecline = ({ note, special_conditions }) =>
    decline.run({ id, note, special_conditions, status: 1 }).then(({ data }) => {
      if (data) {
        reloadData();
        setDeclineModal(false);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      } else {
        enqueueSnackbar('Не удалось отклонить заявку, пожалуйста повторите позднее', {
          variant: 'error'
        });
      }
    });

  // TODO
  const onAccept = values =>
    accept
      .run({
        id,
        comment: null,
        waypoints: null,
        routeChanged: 0,
        changeStatus: 1,
        isSpring,
        price: 0,
        federal_distance: 0,
        regional_distance: 0,
        federal_price: 0,
        regional_price: 0
      })
      .then(({ data }) => {
        if (data) {
          reloadData();
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          enqueueSnackbar('Не удалось обработать заявку, пожалуйста повторите позднее', {
            variant: 'error'
          });
        }
      });

  const onSubmit = data => {
    return data.result !== 2 ? onAccept(data) : onDecline(data);
  };

  if (!savedRoutes) {
    return null;
  }

  return (
    <>
      <Formik
        initialValues={{
          special_conditions: '',
          agreements: [],
          result: 4,
          paths: savedRoutes ? getPoints(savedRoutes, application.route.points) : []
        }}
        isInitialValid
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(data);
          setSubmitting(false);
        }}
        validate={async values => {
          return new Promise((resolve, reject) => {
            validateYupSchema(values, Schema, false, { head: values }).then(
              () => resolve({}),
              err => reject(yupToFormErrors(err))
            );
          });
        }}
        render={({ values, isValid, isSubmitting, setFieldTouched, setFieldValue }) => (
          <Form>
            <Grid container direction="column" spacing={16} wrap="nowrap">
              <Grid item>
                <h2 className="h3-title">Обработка запроса</h2>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Сотрудник, взявший в работу"
                  labelProps={{ xs: 12, sm: 4, lg: 2 }}
                  childrenProps={{ xs: 12, sm: 8, lg: 5 }}
                >
                  <Input value={admin.name} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Должность сотрудника"
                  labelProps={{ xs: 12, sm: 4, lg: 2 }}
                  childrenProps={{ xs: 12, sm: 8, lg: 5 }}
                >
                  <Input value={admin.position} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <h2 className="h4-title">Согласованный маршрут</h2>
              </Grid>
              <Grid item>
                <RouteMap values={values} setFieldTouched={setFieldTouched} options={savedRoutes} />
              </Grid>
              <AgreementsForm agreements={agreements} departments={departments} />
              {status === CooperationStatus.InProgress && (
                <Grid item>
                  <Grid container spacing={16}>
                    <Grid item>
                      <Button className="btn-add no-margin" onClick={() => setDeclineModal(true)}>
                        Отклонить
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className={`btn-add no-margin ${!isValid ? 'disabled-button' : ''}`}
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                        {isSubmitting ? <CircularProgress /> : 'Согласовать'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      />
      <DeclineModal
        open={declineModal}
        onClose={() => setDeclineModal(false)}
        onSubmit={onDecline}
      />
    </>
  );
};

export default withSnackbar(RequestProcessingInProgress);
