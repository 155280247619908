import React from 'react';
import { connect } from 'react-redux';
import { Button, TableRow, TableCell } from '@material-ui/core';
import Filter from '@material-ui/icons/FilterList';
import { Field, Formik } from 'formik';
import { vehicleBrandActions } from '../../../../../actions/vehicleBrandActions';
import MaterialInput from '../../../quickApplications/CreateQuickApplication/components/MaterialInput';

const VehicleBrandFilterComponent = ({ filters, getPage }) => {
  return (
    <Formik
      initialValues={filters}
      onSubmit={value => getPage(1, value)}
      render={({ submitForm }) => (
        <TableRow>
          <TableCell>
            <Filter />
          </TableCell>
          <TableCell>
            <Field name="title" component={MaterialInput} placeholder="Название" />
          </TableCell>
          <TableCell numeric>
            <Button
              variant="contained"
              color="primary"
              className="btn-add no-margin"
              type="submit"
              onClick={submitForm}
            >
              Применить
            </Button>
          </TableCell>
        </TableRow>
      )}
    />
  );
};

const mapStateToProps = state => {
  const { vehicleBrands } = state;

  return {
    filters: vehicleBrands.filters
  };
};

const mapDispatchToProps = {
  getPage: vehicleBrandActions.getPage
};

const connectedVehicleBrandFilter = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleBrandFilterComponent);
export { connectedVehicleBrandFilter as VehicleBrandFilter };
