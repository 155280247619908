import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { FormField } from '../../../components/form/FormField';
import { Loader } from '../../../components/Loader';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { vehicleActions } from '../../../actions/vehicleActions';
import VehiclesAxlesTable from './VehicleAxlesTable';
import { DeclineModal } from './DeclineModal';
import { userConstants } from '../../../constants/userConstants';
import { vehicleConstants } from '../../../constants/vehicleConstants';
import { roleConstants } from '../../../constants/roleConstants';
import { renderSelectField } from '../../../components/form/renderSelectField';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { FormFieldReg } from '../../../components/form/FormFieldReg';

let VehicleViewPage = props => {
  const {
    handleSubmit,
    adminVehicles,
    handleAccept,
    authentication,
    handleDeclineModal,
    handleToWork
  } = props;
  const { currentItem } = adminVehicles;

  const getBrandsSelectItems = () => {
    return currentItem ? (
      <option key={currentItem.brand_id} value={currentItem.brand_id}>
        {currentItem.brandTitle}
      </option>
    ) : (
      ''
    );
  };
  const getModelSelectItems = () => {
    return currentItem ? (
      <option key={currentItem.model_id} value={currentItem.model_id}>
        {currentItem.modelTitle}
      </option>
    ) : (
      ''
    );
  };
  const getVehicleTypeSelectItems = () => {
    return currentItem ? (
      <option key={currentItem.vehicle_type_id} value={currentItem.vehicle_type_id}>
        {dictionaryHelper.vehicleTypes.getItemTitle(currentItem.vehicle_type_id)}
      </option>
    ) : (
      ''
    );
  };

  const getConfirmationStatus = statusKey => {
    switch (statusKey) {
      case userConstants.CONFIRMAtION_STATUS_NEW:
        return 'Новая заявка';
      case userConstants.CONFIRMAtION_STATUS_DECLINE:
        return 'Заявка отклонена';
      case userConstants.CONFIRMAtION_STATUS_REVIEW:
        return 'На рассмотрении';
      case userConstants.CONFIRMAtION_STATUS_ACCEPT:
        return 'Заявка принята';
      default:
        return '';
    }
  };

  return (
    <div>
      {currentItem && (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-9">
              <h2 className="h2-title">Подробно о ТС</h2>
              <div className="blue_frame">
                <p>
                  <strong>Статус заявки:</strong>{' '}
                  {currentItem && getConfirmationStatus(currentItem.confirmation_status)}
                  <br />
                  {currentItem &&
                    currentItem.confirmation_status === vehicleConstants.STATUS_DECLINE && (
                      <span>Причина отклонения: {currentItem.note}</span>
                    )}
                </p>
              </div>

              <Field
                className="form-control"
                name="name"
                label="Заявитель"
                placeholder=""
                disabled="disabled"
                component={FormField}
              />

              <Field
                className="form-control"
                name="title"
                label="Марка ТС"
                readonly="true"
                component={renderSelectField}
                disabled="disabled"
              >
                {getBrandsSelectItems()}
              </Field>

              <Field
                className="form-control"
                name="model"
                label="Модель"
                component={renderSelectField}
                disabled="disabled"
              >
                {getModelSelectItems()}
              </Field>
              <Field
                className="form-control"
                name="typetc"
                label="Тип ТС"
                component={renderSelectField}
                disabled="disabled"
              >
                {getVehicleTypeSelectItems()}
              </Field>

              <div className="text-left">
                <Field
                  name="is_owner"
                  type="checkbox"
                  label="Владельцем ТС является заявитель"
                  className="checkbox"
                  component={MuiCheckbox}
                  disabled="disabled"
                />
              </div>

              <Field
                className="form-control"
                name="owner_name"
                label="Владелец ТС"
                placeholder="ФИО"
                disabled="disabled"
                component={FormField}
              />
            </div>
          </div>
          <div className="row text-left">
            <div className="col-md-3">Документ, подтверждающий право владения</div>
            <div className="col-md-6">
              {get(currentItem, 'files.license_files', []).map(({ id, name, url }) => (
                <div key={id}>
                  <a target="_blank" href={url}>
                    {name}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <br />
          <div className="row text-left">
            <div className="col-md-3">
              <strong>ГРЗ ТС</strong>
            </div>
            <div className="col-md-2">
              <Field
                name="real_number"
                placeholder="номер"
                className="form-control full-input"
                component={FormFieldReg}
                disabled="disabled"
              />
            </div>
            <div className="col-md-2" />
            <div className="col-md-5" />
          </div>
          <div className="row">
            <div className="col-md-9">
              <p>
                <strong>Данные ПТС</strong>
              </p>

              <Field
                name="pts_number"
                label="Номер ПТС/ПСМ"
                placeholder=""
                className="form-control"
                component={FormField}
                disabled="disabled"
              />
              <Field
                name="sts_number"
                label="Номер свидетельства о регистрации тс"
                placeholder=""
                className="form-control"
                component={FormField}
                disabled="disabled"
              />

              <Field
                name="pts_weight"
                label="Масса транспорта без нагрузки (т)"
                placeholder="10 тонн"
                className="form-control"
                component={FormField}
                disabled="disabled"
              />

              <Field
                name="pts_max_weight"
                label="Разрешенная максимальная масса ТС (т)"
                placeholder="20 тонн"
                className="form-control"
                component={FormField}
                disabled="disabled"
              />
            </div>
          </div>
          <div className="row text-left">
            <div className="col-md-3">
              Копии данных ПТС или свидетельства о регистрации в формате pdf, jpeg
            </div>
            <div className="col-md-6">
              {get(currentItem, 'files.pts_files', []).map(({ id, name, url }) => (
                <div key={id}>
                  <a target="_blank" href={url}>
                    {name}
                  </a>
                </div>
              ))}
            </div>
          </div>

          <br />
          <div className="row">
            <div className="col-md-12">
              <p>
                <strong>Габариты</strong>
              </p>
              <div className="row">
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-7">Длина (м)</div>
                    <div className="col-md-5">
                      <Field
                        name="length"
                        placeholder="5 м"
                        className="form-control"
                        component={FormFieldReg}
                        disabled="disabled"
                      />
                    </div>
                    <div className="col-md-7">Ширина (м)</div>
                    <div className="col-md-5">
                      <Field
                        name="width"
                        placeholder="2 м"
                        className="form-control"
                        component={FormFieldReg}
                        disabled="disabled"
                      />
                    </div>
                    <div className="col-md-7">Высота (м)</div>
                    <div className="col-md-5">
                      <Field
                        name="height"
                        // label="Высота"
                        placeholder="2 м"
                        className="form-control"
                        component={FormFieldReg}
                        disabled="disabled"
                      />
                    </div>
                    <div className="col-md-7">Количество осей</div>
                    <div className="col-md-5">
                      <Field
                        name="axle_count"
                        placeholder="3"
                        className="form-control"
                        component={FormFieldReg}
                        disabled="disabled"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-9 nowrap overflow">
                  {dictionaryHelper.vehicleTypes.getItemImgFront(
                    currentItem ? currentItem.vehicle_type_id : null
                  ) && (
                    <img
                      alt="Car Front"
                      className="car-character"
                      src={dictionaryHelper.vehicleTypes.getItemImgFront(
                        currentItem.vehicle_type_id
                      )}
                    />
                  )}
                  {dictionaryHelper.vehicleTypes.getItemImgBody(
                    currentItem ? currentItem.vehicle_type_id : null
                  ) && (
                    <img
                      alt="Cat Body"
                      className="car-character"
                      src={dictionaryHelper.vehicleTypes.getItemImgBody(
                        currentItem.vehicle_type_id
                      )}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row text-left">
            <div className="col-md-9">
              <p>
                <strong>Характеристики осей</strong>
              </p>
              <VehiclesAxlesTable
                axles={currentItem && currentItem.vehicle_axles ? currentItem.vehicle_axles : []}
              />
              <br />

              <p>
                <strong>Расстояние между осями</strong>
              </p>
              {currentItem &&
                currentItem.vehicle_axles &&
                currentItem.vehicle_axles.map((vehicleAxle, index) => {
                  return (
                    index > 0 && (
                      <Field
                        key={index}
                        name={`axle_distances[${index - 1}]`}
                        label={`Расстояние ${index}`}
                        placeholder={vehicleAxle.distance}
                        className="mini-input"
                        component={FormField}
                        disabled="disabled"
                      />
                    )
                  );
                })}

              {adminVehicles.statusLoading && <Loader />}
              {!adminVehicles.statusLoading && (
                <div>
                  {/* admin buttons */}
                  {currentItem.confirmation_status === vehicleConstants.STATUS_NEW && (
                    <button
                      type="button"
                      className="btn no-margin"
                      onClick={e => {
                        handleToWork(currentItem ? currentItem.id : 0);
                      }}
                    >
                      Взять в работу
                    </button>
                  )}
                  &nbsp;&nbsp;
                  {/* admin buttons */}
                  {((authentication.user.role_id === roleConstants.ROLE_ADMIN &&
                    (currentItem.confirmation_status === vehicleConstants.STATUS_REVIEW ||
                      currentItem.confirmation_status === vehicleConstants.STATUS_DECLINE)) ||
                    (authentication.user.role_id === roleConstants.ROLE_OFFICER &&
                      currentItem.confirmation_status === vehicleConstants.STATUS_REVIEW)) && (
                    <button
                      type="button"
                      className="btn no-margin"
                      onClick={e => {
                        handleAccept(currentItem ? currentItem.id : 0);
                      }}
                    >
                      Принять
                    </button>
                  )}
                  &nbsp;&nbsp;
                  {((authentication.user.role_id === roleConstants.ROLE_ADMIN &&
                    (currentItem.confirmation_status === vehicleConstants.STATUS_REVIEW ||
                      currentItem.confirmation_status === vehicleConstants.STATUS_ACCEPT)) ||
                    (authentication.user.role_id === roleConstants.ROLE_OFFICER &&
                      currentItem.confirmation_status === vehicleConstants.STATUS_REVIEW)) && (
                    <button
                      type="button"
                      className="btn-reject"
                      onClick={e => {
                        handleDeclineModal(currentItem ? currentItem.id : 0);
                      }}
                    >
                      Отклонить
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </form>
      )}
      {!currentItem && <Loader />}
      <DeclineModal />
    </div>
  );
};

VehicleViewPage = reduxForm({
  form: 'vehicle-form', // a unique identifier for this form
  enableReinitialize: true
})(VehicleViewPage);

const mapStateToProps = state => {
  const { adminVehicles, authentication } = state;
  const { currentItem } = adminVehicles;

  const initValues = {
    id: currentItem ? currentItem.id : '',
    name: currentItem ? currentItem.user_name : '',
    vehicle_type_id: currentItem ? currentItem.vehicle_type_id : '',

    is_owner: currentItem ? currentItem.is_owner : '',
    owner_name: currentItem ? currentItem.owner_name : '',

    real_number: currentItem ? currentItem.real_number : '',
    regions: currentItem ? currentItem.regions : '',

    pts_number: currentItem ? currentItem.pts_number : '',
    sts_number: currentItem ? currentItem.sts_number : '',
    pts_weight: currentItem ? currentItem.pts_weight : '',
    pts_max_weight: currentItem ? currentItem.pts_max_weight : '',

    length: currentItem ? currentItem.length : '',
    width: currentItem ? currentItem.width : '',
    height: currentItem ? currentItem.height : '',

    axle_count: currentItem ? currentItem.axle_count : ''
  };
  initValues.axle_length = [];
  initValues.axle_type = [];
  initValues.axle_main = [];
  initValues.axle_distances = [];
  initValues.axle_wheels = [];
  if (currentItem && currentItem.vehicle_axles) {
    for (let i = 0; i < currentItem.vehicle_axles.length; i++) {
      initValues.axle_length[i] = currentItem.vehicle_axles[i].wheel_count;
      initValues.axle_type[i] = currentItem.vehicle_axles[i].type_id;
      initValues.axle_main[i] = currentItem.vehicle_axles[i].is_lifting;
      initValues.axle_distances[i] = currentItem.vehicle_axles[i].distance;
      initValues.axle_wheels[i] = currentItem.vehicle_axles[i].wheels;
    }
  }

  return {
    adminVehicles,
    authentication,
    initialValues: initValues
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  // instead onDidMount, get vehicle brands, models, types
  dispatch(vehicleActions.getItem(ownProps.match.params.id));

  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { adminVehicles, authentication, initialValues } = stateProps;
  const { user } = authentication;
  const { dispatch } = dispatchProps;

  const handleDeclineModal = id => {
    dispatch(vehicleActions.openCurrentDeclineModal());
  };
  const handleAccept = id => {
    dispatch(vehicleActions.accept(id, user.role_id));
  };
  const handleToWork = id => {
    return dispatch(vehicleActions.toWork(id));
  };
  return {
    ...ownProps,
    adminVehicles,
    initialValues,
    authentication,
    handleToWork,

    handleAccept,
    handleDeclineModal
  };
};

VehicleViewPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VehicleViewPage);

export default VehicleViewPage;
