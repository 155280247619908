import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import PersonOutline from '@material-ui/icons/PersonOutline';
import { change, reduxForm } from 'redux-form';
import { regAppActions } from '../../../actions/regAppActions';
import { roleConstants } from '../../../constants/roleConstants';
import { regAppConstants } from '../../../constants/regAppConstants';
import { DeclineModal } from './DeclineModal';
import { ReasonModal } from './ReasonModal';
import { Loader } from '../../../components/Loader';
import { momentHelper } from '../../../helpers/momentHelper';
import RegAppsFilterForm from './RegAppsFilterForm';
import TablePagination from '../../../components/TablePagination';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
    // display: 'none'
  }
});

let RegAppsTableList = props => {
  const {
    regApps,
    data,
    meta,
    classes,
    handlePageChange,
    handleRoleChange,
    handleAccept,
    handleDeclineModal,
    handleReasonModal,
    handleLock,
    handleUnlock,
    handleSubmit,
    submitting
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Paper className={classes.root}>
        <div className="text-left role-div">
          <span
            className={
              regApps.roleIndex === roleConstants.ROLE_INDIVIDUAL ? 'active_role cursor' : 'cursor'
            }
            onClick={e => {
              handleRoleChange(roleConstants.ROLE_INDIVIDUAL);
            }}
          >
            Физические лица{' '}
          </span>
          <span
            className={
              regApps.roleIndex === roleConstants.ROLE_FIRM ? 'active_role cursor' : 'cursor'
            }
            onClick={e => {
              handleRoleChange(roleConstants.ROLE_FIRM);
            }}
          >
            Юридические лица
          </span>
        </div>
        {regApps && regApps.loading && <Loader />}
        {regApps && regApps.error && <span className="text-danger">Ошибка: {regApps.error}</span>}
        {regApps && regApps.items && !regApps.loading && (
          <div>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {/* general */}
                  <TableCell numeric />
                  <TableCell>
                    {regApps.roleIndex === roleConstants.ROLE_INDIVIDUAL
                      ? 'ФИО'
                      : 'Название организации'}
                  </TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Телефон</TableCell>

                  {/* individual fields */}
                  {regApps.roleIndex === roleConstants.ROLE_INDIVIDUAL && (
                    <TableCell>ИНН</TableCell>
                  )}

                  {/* firm fields */}
                  {regApps.roleIndex === roleConstants.ROLE_FIRM && (
                    <TableCell numeric>Инн юр лица</TableCell>
                  )}
                    {regApps.roleIndex === roleConstants.ROLE_FIRM && (
                        <TableCell numeric>Кпп юр лица</TableCell>
                    )}
                  {/* {regApps.roleIndex === roleConstants.ROLE_FIRM && <TableCell numeric>Форма юр лица</TableCell>} */}

                  {regApps.roleIndex === roleConstants.ROLE_FIRM && (
                    <TableCell numeric>Контактное лицо</TableCell>
                  )}
                  {/* {regApps.roleIndex === roleConstants.ROLE_FIRM && <TableCell numeric>Контактный телефон</TableCell>} */}

                  <TableCell>Дата заявки</TableCell>
                  <TableCell numeric>Действие</TableCell>
                </TableRow>
                <RegAppsFilterForm />
              </TableHead>
              <TableBody>
                {data.map(n => {
                  return (
                    <TableRow key={n.id}>
                      {/* Общие поля */}
                      <TableCell component="th" scope="row" numeric>
                        {regApps.roleIndex === roleConstants.ROLE_FIRM && (
                          <Link to={`reg-apps/firm/${n.id}`}>
                            <PersonOutline />
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>{n.name}</TableCell>
                      <TableCell
                        className={`nowrap ${
                          parseInt(n.email_confirmed, 10) > 0 ? 'success' : 'danger'
                        }`}
                      >
                        {n.email}
                      </TableCell>
                      <TableCell>{n.phone}</TableCell>
                      <TableCell>{n.inn}</TableCell>

                      {/* Firm */}
                      {regApps.roleIndex === roleConstants.ROLE_FIRM && (
                        <TableCell>{n.firm && n.firm.kpp_org}</TableCell>
                      )}
                      {regApps.roleIndex === roleConstants.ROLE_FIRM && (
                        <TableCell>{!n.firm? '' : n.firm.contact_fio}</TableCell>
                      )}
                      {/* {regApps.roleIndex === roleConstants.ROLE_FIRM && <TableCell numeric>{n.firm && n.firm.contact_phone}</TableCell>} */}

                      <TableCell>{momentHelper.getLocalDatetime(n.updated_at)}</TableCell>

                      <TableCell numeric className="nowrap">
                        {regApps.tabIndex === regAppConstants.STATUS_DECLINE && (
                          <Button
                            variant="outlined"
                            className="info-btn"
                            size="small"
                            onClick={e => {
                              handleReasonModal(n.id);
                            }}
                          >
                            Причина
                          </Button>
                        )}
                        {regApps.tabIndex === regAppConstants.STATUS_ACCEPT && n.locked === 0 && (
                          <Button
                            variant="outlined"
                            className="decline-btn"
                            size="small"
                            onClick={e => {
                              handleLock(n.id);
                            }}
                          >
                            Заблокировать
                          </Button>
                        )}
                        {regApps.tabIndex === regAppConstants.STATUS_ACCEPT && n.locked === 1 && (
                          <Button
                            variant="outlined"
                            className="decline-btn"
                            size="small"
                            onClick={e => {
                              handleUnlock(n.id);
                            }}
                          >
                            Разблокировать
                          </Button>
                        )}

                        {(regApps.tabIndex === regAppConstants.STATUS_NEW ||
                          regApps.tabIndex === regAppConstants.STATUS_REVIEW) && (
                          <div>
                            <Button
                              variant="outlined"
                              className="accept-btn"
                              size="small"
                              onClick={e => {
                                handleAccept(n.id);
                              }}
                            >
                              Принять
                            </Button>
                            <Button
                              variant="outlined"
                              className="decline-btn"
                              size="small"
                              onClick={e => {
                                handleDeclineModal(n.id);
                              }}
                            >
                              Отклонить
                            </Button>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}

        <DeclineModal />
        <ReasonModal />
      </Paper>
      <TablePagination meta={meta} onChangePage={handlePageChange} />
    </form>
  );
};

const mapStateToProps = state => {
  const { regApps } = state;
  return {
    regApps
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { regApps } = stateProps;
  const { dispatch } = dispatchProps;

  const initialValues = {
    role: regApps.roleIndex,
    status: regApps.tabIndex
  };
  const handleRoleChange = roleIndex => {
    if (roleIndex !== regApps.roleIndex) {
      dispatch(regAppActions.setRole(roleIndex));
      dispatch(regAppActions.getPageByStatus(1, regApps.tabIndex, roleIndex));
      dispatch(change('application-admin-filter-form', 'role', roleIndex));
    }
  };
  const handlePageChange = page => {
    dispatch(regAppActions.getPageByStatus(page, regApps.tabIndex, regApps.roleIndex));
  };
  const handleAccept = id => {
    dispatch(regAppActions.accept(id));
  };
  const handleDeclineModal = id => {
    dispatch(regAppActions.openDeclineModal(id));
  };
  const handleReasonModal = id => {
    dispatch(regAppActions.openReasonModal(id));
  };
  const handleLock = id => {
    dispatch(regAppActions.lock(id));
  };
  const handleUnlock = id => {
    dispatch(regAppActions.unlock(id));
  };

  return {
    ...ownProps,
    regApps,
    initialValues,

    handleRoleChange,
    handlePageChange,
    handleAccept,
    handleDeclineModal,
    handleReasonModal,

    handleLock,
    handleUnlock
  };
};

RegAppsTableList = reduxForm({
  form: 'application-admin-filter-form',
  enableReinitialize: true,
  destroyOnUnmount: false
})(RegAppsTableList);

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    null,
    mergeProps
  )
)(RegAppsTableList);
