import { find, uniqueId } from 'lodash';
import { change } from 'redux-form';

import { applicationConstants } from '../constants/applicationConstants';
import { applicationService } from '../services/applicationService';
import { vehicleService } from '../services/vehicleService';
import { history } from '../helpers/history';
import { fileService } from '../services/fileService';
import { urlHelper } from '../helpers/urlHelper';
import { momentHelper } from '../helpers/momentHelper';
import { roleConstants } from '../constants/roleConstants';
import { vehicleConstants } from '../constants/vehicleConstants';
import { getOldFiles } from '../helpers/fileUploadHelper';

const openFilterModal = () => {
  return { type: applicationConstants.FILTER_MODAL_OPEN };
};
const closeFilterModal = () => {
  return { type: applicationConstants.FILTER_MODAL_CLOSE };
};

const openPrintModal = () => {
  return { type: applicationConstants.PRINT_MODAL_OPEN };
};
const closePrintModal = () => {
  return { type: applicationConstants.PRINT_MODAL_CLOSE };
};

const openMyVehiclesModal = () => {
  return { type: applicationConstants.MY_VEHICLES_MODAL_OPEN };
};
const closeMyVehiclesModal = () => {
  return { type: applicationConstants.MY_VEHICLES_MODAL_CLOSE };
};

const getUserFiltered = (tabIndex, values, page = 1) => {
  if (!values || !values.per_page) {
    // eslint-disable-next-line no-param-reassign
    values.per_page = applicationConstants.DEFAULT_PER_PAGE;
  }
  const request = () => {
    return { type: applicationConstants.GET_USER_FILTERED_REQUEST };
  };

  const success = applications => {
    return {
      type: applicationConstants.GET_USER_FILTERED_SUCCESS,
      applications
    };
  };

  const failure = error => {
    return { type: applicationConstants.GET_USER_FILTERED_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    applicationService
      .getUserFiltered(tabIndex, values, page)
      .then(applications => dispatch(success(applications)), error => dispatch(failure(error)));
  };
};

const getAdminApplications = values => {
  if (!values || !values.per_page) {
    values.per_page = applicationConstants.DEFAULT_PER_PAGE;
  }

  const request = values => {
    return { type: applicationConstants.GET_ADMIN_APPLICATIONS_REQUEST, values };
  };

  const success = applications => {
    return {
      type: applicationConstants.GET_ADMIN_APPLICATIONS_SUCCESS,
      applications
    };
  };

  const failure = error => {
    return { type: applicationConstants.GET_ADMIN_APPLICATIONS_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    applicationService
      .getAdminApplications(values)
      .then(applications => dispatch(success(applications)), error => dispatch(failure(error)));
  };
};

const getSearchVehiclesPage = page => {
  const request = page => {
    return { type: applicationConstants.GET_VEHICLE_SEARCH_REQUEST, page };
  };

  const success = (vehicles, page) => {
    return {
      type: applicationConstants.GET_VEHICLE_SEARCH_SUCCESS,
      vehicles,
      page
    };
  };

  const failure = error => {
    return { type: applicationConstants.GET_VEHICLE_SEARCH_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(page));

    // vehicles search removed
    vehicleService
      .getCabinetPage(page, vehicleConstants.STATUS_ACCEPT)
      .then(vehicles => dispatch(success(vehicles, page)), error => dispatch(failure(error)));
  };
};

const getSearchVehiclesAll = () => {
  const request = () => {
    return { type: applicationConstants.GET_VEHICLE_SEARCH_REQUEST };
  };

  const success = vehicles => {
    return {
      type: applicationConstants.GET_VEHICLE_SEARCH_SUCCESS,
      vehicles
    };
  };

  const failure = error => {
    return { type: applicationConstants.GET_VEHICLE_SEARCH_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    // vehicles search removed
    vehicleService
      .getCabinetAll(vehicleConstants.STATUS_ACCEPT)
      .then(vehicles => dispatch(success(vehicles)), error => dispatch(failure(error)));
  };
};

const setApplicationVehicle = vehicle => {
  return { type: applicationConstants.SET_APPLICATION_VEHICLE, vehicle };
};
const setApplicationVehicleType = (isTrailer, index) => {
  return {
    type: applicationConstants.SET_APPLICATION_VEHICLE_TYPE,
    isTrailer,
    index
  };
};
const addApplicationTrailer = () => {
  return { type: applicationConstants.ADD_APPLICATION_TRAILER };
};

const saveVehicleStep = tmpValues => {
  const request = values => {
    return { type: applicationConstants.SAVE_VEHICLE_STEP_REQUEST };
  };

  const success = application => {
    return {
      type: applicationConstants.SAVE_VEHICLE_STEP_SUCCESS,
      application
    };
  };

  const failure = error => {
    return { type: applicationConstants.SAVE_VEHICLE_STEP_FAILURE, error };
  };

  const values = {
    ...tmpValues,
    penalty_index: tmpValues.is_penalty ? tmpValues.penalty_index || '' : undefined,
    penalty_number: tmpValues.is_penalty ? tmpValues.penalty_number || '' : undefined,
    penalty_place: tmpValues.is_penalty ? tmpValues.penalty_place || '' : undefined,
    penalty_post_id: tmpValues.is_penalty ? tmpValues.penalty_post_id || '' : undefined
  };

  return dispatch => {
    dispatch(request(values));

    if (!values.id) {
      applicationService.saveVehicleStep(values).then(
        application => {
          dispatch(success(application));
          history.push(
            `${urlHelper.getHomeUrl(values.role_id)}/applications/update/${
              application.id
            }/step/load`
          );
        },
        error => dispatch(failure(error))
      );
    } else {
      applicationService.updateVehicleStep(values.id, values).then(
        application => {
          dispatch(success(application));
          history.push(
            `${urlHelper.getHomeUrl(values.role_id)}/applications/update/${
              application.id
            }/step/load`
          );
        },
        error => dispatch(failure(error))
      );
    }
  };
};

const getItem = (id, onSuccess = false) => {
  const request = id => {
    return { type: applicationConstants.LOAD_REQUEST, id };
  };

  const success = application => {
    return { type: applicationConstants.LOAD_SUCCESS, application };
  };

  const failure = error => {
    return { type: applicationConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.getItem(id).then(
      application => {
        dispatch(success(application));
        if (onSuccess) {
          onSuccess(dispatch, application.data);
        }
      },
      error => dispatch(failure(error))
    );
  };
};

const getLoadItem = (id, onSuccess = false) => {
  const request = id => {
    return { type: applicationConstants.LOAD_LOAD_REQUEST, id };
  };

  const success = (application, load) => {
    return { type: applicationConstants.LOAD_LOAD_SUCCESS, load, application };
  };

  const failure = error => {
    return { type: applicationConstants.LOAD_LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.getLoadItem(id).then(
      response => {
        const { application, load } = response.data;
        const safeLoad = load;
        dispatch(success(application, load));
        if (onSuccess) {
          onSuccess(dispatch, application, safeLoad);
        }
      },
      error => dispatch(failure(error))
    );
  };
};

const getRouteItem = (id, onSuccess = false) => {
  const request = id => {
    return { type: applicationConstants.LOAD_ROUTE_REQUEST, id };
  };

  const success = (application, route) => {
    return {
      type: applicationConstants.LOAD_ROUTE_SUCCESS,
      route,
      application
    };
  };

  const failure = error => {
    return { type: applicationConstants.LOAD_ROUTE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.getRouteItem(id).then(
      response => {
        dispatch(success(response.data.application, response.data.route));
        // if(onSuccess){
        //     onSuccess(dispatch, response.data.application, response.data.route);
        // }
      },
      error => dispatch(failure(error))
    );
  };
};
const getDatesItem = (id, onSuccess = false) => {
  const request = id => {
    return { type: applicationConstants.LOAD_DATES_REQUEST, id };
  };

  const success = (application, load, route, dates) => {
    return {
      type: applicationConstants.LOAD_DATES_SUCCESS,
      application,
      load,
      route,
      dates
    };
  };

  const failure = error => {
    return { type: applicationConstants.LOAD_DATES_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.getDatesItem(id).then(
      response => {
        dispatch(
          success(
            response.data.application,
            response.data.load,
            response.data.route,
            response.data.dates
          )
        );
        if (onSuccess) {
          onSuccess(
            dispatch,
            response.data.application,
            response.data.load,
            response.data.route,
            response.data.dates
          );
        }
      },
      error => dispatch(failure(error))
    );
  };
};

const resetItem = () => {
  return { type: applicationConstants.RESET_CURRENT_ITEM };
};

const withdraw = id => {
  const request = id => {
    return { type: applicationConstants.WITHDRAW_REQUEST, id };
  };

  const success = application => {
    return {
      type: applicationConstants.WITHDRAW_SUCCESS,
      application: application.data
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.WITHDRAW_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.withdraw(id).then(
      application => {
        dispatch(success(application));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const setTab = tabIndex => {
  return { type: applicationConstants.SET_TAB_INDEX, tabIndex };
};

const toWork = id => {
  const request = id => {
    return { type: applicationConstants.TO_WORK_REQUEST, id };
  };

  const success = application => {
    return {
      type: applicationConstants.TO_WORK_SUCCESS,
      application: application.data
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.TO_WORK_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    return applicationService.toWork({ id }).then(
      ({ data: application }) => {
        dispatch(success(application));
        return application;
      },
      error => {
        dispatch(failure(id, error));
        return Promise.reject(error);
      }
    );
  };
};

const accept = (
  id,
  comment = null,
  waypoints = null,
  routeChanged = 0,
  changeStatus = 1,
  isSpring = 0,
  apvgk_list: null
) => {
  const request = id => {
    return { type: applicationConstants.ACCEPT_REQUEST, id };
  };

  const success = application => {
    return {
      type: applicationConstants.ACCEPT_SUCCESS,
      application: application.data,
      changeStatus
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.ACCEPT_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.accept(id, comment, waypoints, routeChanged, changeStatus, isSpring, 0, undefined, '', '', apvgk_list).then(
      application => {
        dispatch(
          change(
            'application-view-form',
            'accept_date',
            momentHelper.changeYmdHisTodmY(application.data.accept_date)
          )
        );
        dispatch(success(application));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const activate = id => {
  const request = id => {
    return { type: applicationConstants.ACTIVATE_REQUEST, id };
  };

  const success = application => {
    return {
      type: applicationConstants.ACTIVATE_SUCCESS,
      application: application.data
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.ACTIVATE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.activate(id).then(
      application => {
        dispatch(success(application));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const decline = values => {
  const request = values => {
    return { type: applicationConstants.DECLINE_REQUEST, values };
  };

  const success = application => {
    return {
      type: applicationConstants.DECLINE_SUCCESS,
      application: application.data
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.DECLINE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(values));

    applicationService.decline(values).then(
      application => {
        dispatch(success(application));
      },
      error => {
        dispatch(failure(values.id, error));
      }
    );
  };
};

const restore = id => {
  const request = id => {
    return { type: applicationConstants.RESTORE_REQUEST, id };
  };

  const success = response => {
    return {
      type: applicationConstants.RESTORE_SUCCESS,
      application: response.data
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.RESTORE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.restore(id).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const lock = id => {
  const request = id => {
    return { type: applicationConstants.LOCK_REQUEST, id };
  };

  const success = response => {
    return {
      type: applicationConstants.LOCK_SUCCESS,
      application: response.data
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.LOCK_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.lock(id).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};
const unlock = id => {
  const request = id => {
    return { type: applicationConstants.UNLOCK_REQUEST, id };
  };

  const success = response => {
    return {
      type: applicationConstants.UNLOCK_SUCCESS,
      application: response.data
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.UNLOCK_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.unlock(id).then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const openDeclineModal = id => {
  return { type: applicationConstants.DECLINE_OPEN, id };
};
const openCurrentDeclineModal = () => {
  return { type: applicationConstants.DECLINE_CURRENT_ITEM_OPEN };
};
const closeDeclineModal = () => {
  return { type: applicationConstants.DECLINE_CLOSE };
};

const openActivateModal = id => {
  return { type: applicationConstants.ACTIVATE_OPEN, id };
};
const openCurrentActivateModal = () => {
  return { type: applicationConstants.ACTIVATE_CURRENT_ITEM_OPEN };
};
const closeActivateModal = () => {
  return { type: applicationConstants.ACTIVATE_CLOSE };
};

const setLoadAxles = axles => {
  return { type: applicationConstants.SET_LOAD_AXLES, axles };
};
const setEscort = escort => {
  return { type: applicationConstants.SET_ESCORT, escort };
};
const setEditableDistances = distances => {
  return { type: applicationConstants.SET_EDITABLE_DISTANCES, distances };
};

const setRouteMarkers = markers => {
  return { type: applicationConstants.SET_ROUTE_MARKERS, markers };
};

const saveLoadStep = tmpValues => {
  const request = values => {
    return { type: applicationConstants.SAVE_LOAD_STEP_REQUEST };
  };

  const success = load => {
    return { type: applicationConstants.SAVE_LOAD_STEP_SUCCESS, load };
  };

  const failure = error => {
    return { type: applicationConstants.SAVE_LOAD_STEP_FAILURE, error };
  };

  const values = {
    ...tmpValues,
    penalty_index: tmpValues.is_penalty ? tmpValues.penalty_index || '' : undefined,
    penalty_number: tmpValues.is_penalty ? tmpValues.penalty_number || '' : undefined,
    penalty_place: tmpValues.is_penalty ? tmpValues.penalty_place || '' : undefined,
    penalty_post_id: tmpValues.is_penalty ? tmpValues.penalty_post_id || '' : undefined
  };

  return dispatch => {
    dispatch(request(values));

    applicationService.saveLoadStep(values).then(
      load => {
        dispatch(success(load));
        history.push(
          `${urlHelper.getHomeUrl(values.role_id)}/applications/update/${
            load.application_id
          }/step/route`
        );
      },
      error => dispatch(failure(error))
    );
  };
};

// application
const uploadLoadFile = (values, uploaderId) => {
  const request = values => {
    return {
      type: applicationConstants.LOAD_UPLOAD_REQUEST,
      uploaderId,
      name: values.name
    };
  };
  const success = data => {
    return { type: applicationConstants.LOAD_UPLOAD_SUCCESS, data, uploaderId };
  };
  const failure = () => {
    return { type: applicationConstants.LOAD_UPLOAD_FAILURE, uploaderId };
  };

  return (dispatch, getState) => {
    dispatch(request(values));

    return fileService
      .uploadApplicationLoad(values)
      .then(response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));
        }
        const form = 'application-load-form';
        const oldFiles = getOldFiles(form, getState, 'load_files');
        dispatch(change(form, 'load_files', [...oldFiles, response.data]));

        return response.data;
      })
      .catch(err => {
        dispatch(failure());
        console.log('Upload error', err);
      });
  };
};
const removeLoadFile = id => {
  return { type: applicationConstants.LOAD_REMOVE, id };
};

// application
const uploadPenaltyFile = (id, values, uploaderId) => {
  const request = values => {
    return {
      type: applicationConstants.LOAD_PENALTY_REQUEST,
      uploaderId,
      name: values.name
    };
  };
  const success = data => {
    return { type: applicationConstants.LOAD_PENALTY_SUCCESS, data, uploaderId };
  };
  const failure = () => {
    return { type: applicationConstants.LOAD_PENALTY_FAILURE, uploaderId };
  };

  return (dispatch, getState) => {
    dispatch(request(values));

    return fileService
      .uploadPenalty(id, values)
      .then(response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));
        }
        const form = 'application-dates-form';
        const oldFiles = getOldFiles(form, getState, 'penalty_files');
        dispatch(change(form, 'penalty_files', [...oldFiles, response.data]));

        return response.data;
      })
      .catch(err => {
        dispatch(failure());
        console.log('Upload error', err);
      });
  };
};
const removePenaltyFile = id => {
  return { type: applicationConstants.LOAD_REMOVE, id };
};

const uploadPayFile = (application_id, values, uploaderId) => {
  const request = values => {
    return {
      type: applicationConstants.PAY_UPLOAD_REQUEST,
      uploaderId,
      name: values.name
    };
  };
  const success = data => {
    return { type: applicationConstants.PAY_UPLOAD_SUCCESS, data, application_id, uploaderId };
  };
  const failure = () => {
    return { type: applicationConstants.PAY_UPLOAD_FAILURE, uploaderId };
  };

  return (dispatch, getState) => {
    dispatch(request(values));

    return fileService
      .uploadApplicationPay(application_id, values)
      .then(response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));
        }
        const form = 'application-view-form';
        const oldFiles = getOldFiles(form, getState);

        dispatch(change(form, 'pay_files', [...oldFiles, response.data]));
        return response.data;
      })
      .catch(err => {
        dispatch(failure());
        console.log('Upload error', err);
      });
  };
};
const removePayFile = id => {
  const request = data => {
    return { type: applicationConstants.PAY_FILE_REMOVE_REQUEST, data };
  };
  const success = data => {
    return { type: applicationConstants.PAY_FILE_REMOVE_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request(id));

    return fileService
      .removeFile(id)
      .then(response => {
        dispatch(success(id));

        return id;
      })
      .catch(err => {
        console.log('Remove file error', err);
      });
  };
};

const uploadApplicationPayFile = (application_id, values, existingFiles, dispatch) => {
  if (!existingFiles) {
    existingFiles = [];
  }
  const request = data => {
    return { type: applicationConstants.PAY_UPLOAD_REQUEST, data };
  };
  const success = data => {
    return { type: applicationConstants.PAY_UPLOAD_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request(application_id, values));

    return fileService
      .uploadApplicationPay(application_id, values)
      .then(response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));

          // set new files list
          // existingFiles.push(response.data);
          const new_files = [...existingFiles, response.data];
          dispatch(change('application-view-form', 'pay_files', new_files));
        }

        return response.data;
      })
      .catch(err => {
        console.log('Upload error', err);
      });
  };
};

const removeApplicationPayFile = id => {
  const request = data => {
    return { type: applicationConstants.PAY_FILE_REMOVE_REQUEST, data };
  };
  const success = data => {
    return { type: applicationConstants.PAY_FILE_REMOVE_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request(id));

    return fileService
      .removeFile(id)
      .then(response => {
        dispatch(success(id));

        return id;
      })
      .catch(err => {
        console.log('Remove file error', err);
      });
  };
};

// route
const saveRouteStep = values => {
  const request = values => {
    return { type: applicationConstants.SAVE_ROUTE_STEP_REQUEST };
  };

  const success = route => {
    return { type: applicationConstants.SAVE_ROUTE_STEP_SUCCESS, route };
  };

  const failure = error => {
    return { type: applicationConstants.SAVE_ROUTE_STEP_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    applicationService.saveRouteStep(values).then(
      route => {
        dispatch(success(route));
        history.push(
          `${urlHelper.getHomeUrl(values.role_id)}/applications/update/${route.application_id ||
            route.id}/step/dates`
        );
      },
      error => dispatch(failure(error))
    );
  };
};

// dates
const saveDatesStep = values => {
  const request = values => {
    return { type: applicationConstants.SAVE_DATES_STEP_REQUEST };
  };

  const success = dates => {
    return { type: applicationConstants.SAVE_DATES_STEP_SUCCESS, dates };
  };

  const failure = error => {
    return { type: applicationConstants.SAVE_DATES_STEP_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    applicationService.saveDatesStep(values).then(
      dates => {
        dispatch(success(dates));
        history.push(
          `${urlHelper.getHomeUrl(values.role_id)}/applications/view/${dates.application_id}`
        );
      },
      error => dispatch(failure(error))
    );
  };
};

// location
const searchLocation = (index, value) => {
  const request = value => {
    return { type: applicationConstants.SEARCH_LOCATION_REQUEST };
  };

  const success = (index, suggestions) => {
    // return {type: applicationConstants.SEARCH_LOCATION_SUCCESS, index, suggestions}
    return { type: applicationConstants.SET_SUGGESTIONS, index, suggestions };
  };

  const failure = error => {
    return { type: applicationConstants.SEARCH_LOCATION_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(value));

    applicationService.findLocations(value).then(
      suggestions => {
        dispatch(success(index, suggestions.features));
      },
      error => dispatch(failure(error))
    );
  };
};
const setLocation = (index, value) => {
  return { type: applicationConstants.SET_LOCATION, index, value };
};
const setCoords = (index, value) => {
  return { type: applicationConstants.SET_COORDS, index, value };
};
const setSuggestions = (index, suggestions) => {
  return { type: applicationConstants.SET_SUGGESTIONS, index, suggestions };
};

const setMapCoords = coords => {
  return { type: applicationConstants.SET_MAP_COORDS, coords };
};
const setMapZoom = zoom => {
  return { type: applicationConstants.SET_MAP_ZOOM, zoom };
};

const addMarker = (coords = null) => {
  const formattedCoords = coords ? { lat: coords.lat, lon: coords.lng } : null;
  return { type: applicationConstants.ADD_MARKER, coords: formattedCoords };
};
const removeMarker = index => {
  return { type: applicationConstants.REMOVE_MARKER, index };
};

const getFullItem = (id, onSuccess = false, user = null) => {
  const request = id => {
    return { type: applicationConstants.LOAD_FULL_REQUEST, id };
  };

  const success = application => {
    return { type: applicationConstants.LOAD_FULL_SUCCESS, application };
  };

  const failure = error => {
    return { type: applicationConstants.LOAD_FULL_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.getFullItem(id).then(
      application => {
        const data = {
          ...application.data,
          apvgk: (application.data.apvgk || []).map(a => a.id),
          status:
            user && user.department_id
              ? application.data.application_agreements.find(
                  a => a.department_id === user.department_id
                ).status
              : application.data.status
        };
        dispatch(success({ ...application, data }));
        if (onSuccess) {
          onSuccess(dispatch, data);
        }
      },
      error => dispatch(failure(error))
    );
  };
};

const _delete = id => {
  const request = id => {
    return { type: applicationConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: applicationConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService
      .delete(id)
      .then(application => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};

const deleteTemplate = id => {
  const request = id => {
    return { type: applicationConstants.DELETE_TEMPLATE_REQUEST, id };
  };

  const success = id => {
    return { type: applicationConstants.DELETE_TEMPLATE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.DELETE_TEMPLATE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService
      .delete(id)
      .then(application => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};

const getUserTemplates = () => {
  const request = () => {
    return { type: applicationConstants.GET_USER_TEMPLATES_REQUEST };
  };

  const success = applications => {
    return {
      type: applicationConstants.GET_USER_TEMPLATES_SUCCESS,
      applications
    };
  };

  const failure = error => {
    return { type: applicationConstants.GET_USER_TEMPLATES_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    applicationService
      .getUserTemplates()
      .then(applications => dispatch(success(applications)), error => dispatch(failure(error)));
  };
};

const useTemplate = (id, role_id = roleConstants.ROLE_INDIVIDUAL) => {
  const request = id => {
    return { type: applicationConstants.USE_TEMPLATE_REQUEST, id };
  };

  const success = id => {
    return { type: applicationConstants.USE_TEMPLATE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.USE_TEMPLATE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.useTemplate(id).then(
      response => {
        dispatch(success(id));
        history.push(
          `${urlHelper.getHomeUrl(role_id)}/applications/update/${response.data.id}/step/dates`
        );
      },
      error => dispatch(failure(id, error))
    );
  };
};

const toAdmin = id => {
  const request = id => {
    return { type: applicationConstants.TO_ADMIN_REQUEST, id };
  };

  const success = (id, application) => {
    return { type: applicationConstants.TO_ADMIN_SUCCESS, id, application };
  };

  const failure = error => {
    return { type: applicationConstants.TO_ADMIN_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(id));

    return applicationService
      .toAdmin(id)
      .then(
        application => dispatch(success(id, application.data)),
        error => dispatch(failure(error))
      );
  };
};

const getControlFiltered = values => {
  const request = values => {
    return { type: applicationConstants.GET_CONTROL_FILTERED_REQUEST };
  };

  const success = applications => {
    return {
      type: applicationConstants.GET_CONTROL_FILTERED_SUCCESS,
      applications
    };
  };

  const failure = error => {
    return { type: applicationConstants.GET_CONTROL_FILTERED_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    applicationService
      .getControlFiltered(values)
      .then(applications => dispatch(success(applications)), error => dispatch(failure(error)));
  };
};

const getControlByRDMUUID= values => {
  const request = values => {
    return { type: applicationConstants.GET_CONTROL_BYUUID_REQUEST };
  };

  const success = application => {
    return {
      type: applicationConstants.GET_CONTROL_BYUUID_SUCCESS,
      application
    };
  };

  const failure = error => {
    return { type: applicationConstants.GET_CONTROL_BYUUID_REQUEST, error };
  };

  return dispatch => {
    dispatch(request(values));

    applicationService
        .getControlByRDMUUID(values)
        .then(app => dispatch(success(app)), error => dispatch(failure(error)));
  };
};

const setMap = map => {
  return { type: applicationConstants.SET_MAP, map };
};
const setPlan = plan => {
  return { type: applicationConstants.SET_PLAN, plan };
};
const setRouting = routing => {
  return { type: applicationConstants.SET_ROUTING, routing };
};
const setMarkerValue = (index, value) => {
  return { type: applicationConstants.SET_MARKER_VALUE, index, value };
};

const addControlMark = (id, reverse, post_id) => {
  const request = (id, reverse) => {
    return { type: applicationConstants.ADD_CONTROL_MARK_REQUEST, id };
  };

  const success = response => {
    return {
      type: applicationConstants.ADD_CONTROL_MARK_SUCCESS,
      control_mark: response.data.mark,
      runs_used: response.data.runs_used,
      runs_count: response.data.runs_count
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.ADD_CONTROL_MARK_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id, reverse));

    applicationService.addControlMark(id, reverse, post_id).then(
      response => {
        if (response.data.success) {
          dispatch(success(response));
          dispatch(
            change(
              'application-view-form',
              'runs_count',
              `${response.data.runs_count - response.data.runs_used}/${response.data.runs_count}`
            )
          );
        } else {
          dispatch(failure(id, response.data.error));
        }
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const sendToDepartment = (id, values) => {
  const request = (id, values) => {
    return {
      type: applicationConstants.SEND_TO_DEPARTMENT_REQUEST,
      id,
      values
    };
  };

  const success = application_agreement => {
    return {
      type: applicationConstants.SEND_TO_DEPARTMENT_SUCCESS,
      application_agreement: application_agreement.data
    };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.SEND_TO_DEPARTMENT_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id, values));

    applicationService.sendToDepartment({ id, values }).then(
      application_agreement => {
        dispatch(success(application_agreement));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const removeAgreement = id => {
  const request = id => {
    return { type: applicationConstants.REMOVE_AGREEMENT_REQUEST, id };
  };

  const success = id => {
    return { type: applicationConstants.REMOVE_AGREEMENT_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.REMOVE_AGREEMENT_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService
      .removeAgreement({ id })
      .then(agreement => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};

const openAgreementDeclineModal = (id, department_id) => {
  return {
    type: applicationConstants.AGREEMENT_DECLINE_OPEN,
    id,
    department_id
  };
};
const openCurrentAgreementDeclineModal = () => {
  return { type: applicationConstants.AGREEMENT_DECLINE_CURRENT_ITEM_OPEN };
};
const closeAgreementDeclineModal = () => {
  return { type: applicationConstants.AGREEMENT_DECLINE_CLOSE };
};

const agreementAccept = id => {
  const request = id => {
    return { type: applicationConstants.AGREEMENT_ACCEPT_REQUEST, id };
  };

  const success = agreement => {
    return { type: applicationConstants.AGREEMENT_ACCEPT_SUCCESS, agreement };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.AGREEMENT_ACCEPT_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    applicationService.agreementAccept(id).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

const changeRouteByAdmin = (application_id, waypoints, routeMarkers, handleRecalculate) => {
  const request = () => {
    return { type: applicationConstants.CHANGE_ROUTE_ADMIN_REQUEST };
  };

  const success = data => {
    const markers = data.points.map(({ lat, lon, text }) => {
      const point = { lat, lon, text: text || '' };
      const marker = find(routeMarkers, point);
      return {
        id: marker ? marker.id : uniqueId('marker_'),
        ...point
      };
    });
    return {
      type: applicationConstants.CHANGE_ROUTE_ADMIN_SUCCESS,
      data,
      markers
    };
  };

  const failure = () => {
    return { type: applicationConstants.CHANGE_ROUTE_ADMIN_FAILURE };
  };

  return dispatch => {
    dispatch(request());

    applicationService.changeRouteByAdmin(application_id, waypoints, routeMarkers).then(
      response => {
        handleRecalculate(response.data);
        dispatch(success(response.data));
      },
      error => {
        dispatch(failure());
      }
    );
  };
};

const agreementDecline = values => {
  const request = values => {
    return { type: applicationConstants.AGREEMENT_DECLINE_REQUEST, values };
  };

  const success = agreement => {
    return { type: applicationConstants.AGREEMENT_DECLINE_SUCCESS, agreement };
  };

  const failure = (id, error) => {
    return { type: applicationConstants.AGREEMENT_DECLINE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(values));

    applicationService.agreementDecline(values).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        dispatch(failure(values.id, error));
      }
    );
  };
};

const getLocationByCoords = (lat, lon, index = null, move = false) => {
  const request = () => {
    return { type: applicationConstants.GET_COORDS_LOCATION_REQUEST };
  };

  const success = (data, index, move) => {
    return {
      type: applicationConstants.GET_COORDS_LOCATION_SUCCESS,
      data,
      index,
      move
    };
  };

  const failure = error => {
    return { type: applicationConstants.GET_COORDS_LOCATION_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    applicationService.getCoordsLocation(lat, lon).then(
      response => {
        if (response.data) {
          dispatch(success(response.data, index, move));
        } else {
          dispatch(failure('Точка находится в запрещенной зоне'));
        }
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
};

const toWorkMultiple = values => {
  const request = () => {
    return { type: applicationConstants.TO_WORK_MULTIPLE_REQUEST };
  };

  const success = data => {
    return { type: applicationConstants.TO_WORK_MULTIPLE_SUCCESS, data };
  };

  const failure = error => {
    return { type: applicationConstants.TO_WORK_MULTIPLE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    applicationService.toWorkMultiple({ values }).then(
      response => {
        dispatch(success(response.data));
      },
      error => {
        dispatch(failure(error));
      }
    );
  };
};

const printPdf = values => {
  const request = () => {
    return { type: applicationConstants.PRINT_PDF_REQUEST };
  };

  const success = data => {
    return {
      type: applicationConstants.PRINT_PDF_SUCCESS,
      data,
      form_id: values.form_id
    };
  };

  const failure = error => {
    return { type: applicationConstants.PRINT_PDF_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    applicationService.printPdf(values).then(
      response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));
          window.open(response.data.url, '_blank');
        } else {
          dispatch(failure('Не удалось распечатать заявление! Возможно, заполнены не все данные!'));
        }
      },
      error => {
        dispatch(failure('Номер бланка уже был использован ранее!'));
      }
    );
  };
};

const toggleEditMap = editMap => ({
  type: applicationConstants.TOGGLE_EDIT_MAP,
  payload: editMap
});

export const applicationActions = {
  openFilterModal,
  closeFilterModal,

  openPrintModal,
  closePrintModal,

  openMyVehiclesModal,
  closeMyVehiclesModal,

  getUserFiltered,
  getAdminApplications,

  getSearchVehiclesPage,
  getSearchVehiclesAll,
  setApplicationVehicle,
  setApplicationVehicleType,
  addApplicationTrailer,

  saveVehicleStep,
  getItem,
  getLoadItem,
  getRouteItem,
  getDatesItem,

  resetItem,

  withdraw,
  toAdmin,
  toWork,
  accept,
  activate,
  decline,
  restore,

  lock,
  unlock,

  setTab,

  openDeclineModal,
  closeDeclineModal,
  openCurrentDeclineModal,

  openActivateModal,
  closeActivateModal,
  openCurrentActivateModal,

  // load step
  setLoadAxles,
  setEscort,
  saveLoadStep,
  setEditableDistances,

  uploadLoadFile,
  removeLoadFile,

  uploadPayFile,
  removePayFile,

  uploadApplicationPayFile,
  removeApplicationPayFile,

  // route step
  saveRouteStep,

  // dates step
  saveDatesStep,

  // geolocation
  searchLocation,
  setLocation,
  setCoords,
  setSuggestions,

  setMapCoords,
  setMapZoom,

  addMarker,
  removeMarker,

  setRouteMarkers,

  // full info: load, route, dates
  getFullItem,

  delete: _delete,
  deleteTemplate,

  // templates
  getUserTemplates,
  useTemplate,

  // control
  getControlFiltered,
  getControlByRDMUUID,

  // map
  setMap,
  setPlan,
  setRouting,
  setMarkerValue,

  // control marks
  addControlMark,

  // agreements
  sendToDepartment,
  removeAgreement,

  openAgreementDeclineModal,
  closeAgreementDeclineModal,
  openCurrentAgreementDeclineModal,
  agreementAccept,
  agreementDecline,

  changeRouteByAdmin,
  getLocationByCoords,

  toWorkMultiple,

  printPdf,
  uploadPenaltyFile,
  removePenaltyFile,

  toggleEditMap
};
