import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormField } from '../../../../components/form/FormField';
import { fieldValidators } from '../../../../helpers/fieldValidators';
import { Loader } from '../../../../components/Loader';

let RouteAddressForm = props => {
  const { error, handleSubmit, submitting, currentItem } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="title"
        type="text"
        label="Название"
        validate={[fieldValidators.required]}
        component={FormField}
      />

      {error && <strong>{error}</strong>}
      <div>
        {submitting && <Loader />}
        <button type="submit" className="btn" disabled={submitting}>
          {currentItem ? 'Редактировать' : 'Создать'}
        </button>
      </div>
    </form>
  );
};

RouteAddressForm = reduxForm({
  form: 'route-address-form',
  enableReinitialize: true
})(RouteAddressForm);

RouteAddressForm = connect(state => ({
  initialValues: {
    id: state.routeAddresses.currentItem ? state.routeAddresses.currentItem.id : '',
    title: state.routeAddresses.currentItem ? state.routeAddresses.currentItem.title : ''
  }
}))(RouteAddressForm);

export default RouteAddressForm;
