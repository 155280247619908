import { regAppConstants } from '../constants/regAppConstants';
import { roleConstants } from '../constants/roleConstants';
import { dictionaryHelper } from '../helpers/dictionaryHelper';
import { authConstants } from '../constants/authConstants';

const findItem = (items, id) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      return items[i];
    }
  }
  return null;
};

const removeItem = (items, id) => {
  return items.filter(item => item.id !== id);
};

const initialState = {
  tabIndex: regAppConstants.STATUS_NEW,
  roleIndex: roleConstants.ROLE_INDIVIDUAL,
  tabs: [
    [{}, {}], // individual and firm data for status = new
    [{}, {}],
    [{}, {}],
    [{}, {}]
  ],
  showDeclineModal: false,
  showReasonModal: false,
  meta: dictionaryHelper.getEmptyMeta(),
  items: [],
  currentItem: null
};

export const regApps = (state = initialState, action) => {
  switch (action.type) {
    case regAppConstants.SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex
      };
    case regAppConstants.SET_ROLE_INDEX:
      return {
        ...state,
        // roleIndex: roleIdToRoleTabNum(action.roleIndex)
        roleIndex: action.roleIndex
      };

    case regAppConstants.GET_BY_STATUSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case regAppConstants.GET_BY_STATUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.regApps.data,
        meta: action.regApps.meta
      };
    case regAppConstants.GET_BY_STATUSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить список заявок!'
      };

    case regAppConstants.GET_PAGE_BY_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case regAppConstants.GET_PAGE_BY_STATUS_SUCCESS:
      return {
        ...state,
        items: action.regApps.data,
        meta: action.regApps.meta,
        loading: false
      };
    case regAppConstants.GET_PAGE_BY_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить список заявок!'
      };

    // Get item
    case regAppConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case regAppConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.regApp.data
      };
    case regAppConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // confirm/decline @todo remove move regApp to another tab on status change
    case regAppConstants.ACCEPT_SUCCESS:
      return {
        ...state,
        items: removeItem(state.items, action.id)
      };
    case regAppConstants.DECLINE_SUCCESS:
      return {
        ...state,
        items: removeItem(state.items, action.id)
      };

    case regAppConstants.LOCK_SUCCESS:
      const { items } = state;
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === action.id) {
          items[i].locked = 1;
        }
      }
      return {
        ...state,
        items
      };
    case regAppConstants.UNLOCK_SUCCESS:
      const items1 = state.items;
      for (let i = 0; i < items1.length; i++) {
        if (items1[i].id === action.id) {
          items1[i].locked = 0;
        }
      }
      return {
        ...state,
        items: items1
      };

    // decline modal
    case regAppConstants.DECLINE_OPEN:
      return {
        ...state,
        showDeclineModal: true,
        currentItem: findItem(state.items, action.id)
      };
    case regAppConstants.DECLINE_CLOSE:
      return {
        ...state,
        showDeclineModal: false
      };

    // reason modal
    case regAppConstants.REASON_OPEN:
      return {
        ...state,
        showReasonModal: true,
        currentItem: findItem(state.items, action.id)
      };
    case regAppConstants.REASON_CLOSE:
      return {
        ...state,
        showReasonModal: false
      };

    case regAppConstants.ADMIN_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };
    case regAppConstants.ADMIN_FILTER_SUCCESS:
      return {
        ...state,
        items: action.response.data,
        meta: action.response.meta,
        loading: false
      };

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
