import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { urlHelper } from '../../../../helpers/urlHelper';

const DetailsLink = ({ user, item }) => (
  <Link to={`${urlHelper.getHomeUrl(user.role_id)}/quick_applications/copy/${item.id}`}>
    <Button variant="text" className="info-btn" color="primary">
      Создать копию
    </Button>
  </Link>
);

export default DetailsLink;
