import React, { useRef, useState } from 'react';
import ConfirmationModal from './modals/ConfirmationModal';

const DoActionWithConfirm = ({ action, children, title, description, needsConfirmation }) => {
  const [showConfirm, changeShowConfirm] = useState(false);
  const actionProps = useRef(null);
  const showConfirmModal = () => {
    changeShowConfirm(!showConfirm);
  };

  const closeConfirmModal = () => changeShowConfirm(false);

  const actionWithConfirmation = needsConfirmation
    ? (...props) => {
        showConfirmModal(...props);
        actionProps.current = props;
      }
    : action;

  const onSubmit = () => action(...actionProps.current);
  return (
    <React.Fragment>
      {children(actionWithConfirmation)}
      <ConfirmationModal
        handleSubmit={onSubmit}
        handleClose={closeConfirmModal}
        isShow={showConfirm}
        title={title}
        description={description}
      />
    </React.Fragment>
  );
};

export default DoActionWithConfirm;
