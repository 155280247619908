import React from 'react';
import Button from '@material-ui/core/Button';
import { fileConstants } from '../../../../../constants/fileConstants';

const FileUpload = ({
  field, // { name, value, onChange, onBlur }
  form,
  upload,
  buttonText,
  id,
  ...props
}) => (
  <React.Fragment>
    <input
      className="hidden-file-input"
      type="file"
      id={id}
      onChange={e => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = async () => {
          const fileInfo = {
            type: fileConstants.TYPE_PENALTY,
            myfile: reader.result,
            name: file.name
          };
          const res = await upload(fileInfo);
          console.log(res.data)
          form.setFieldValue(field.name, res.data);
        };

        reader.readAsDataURL(file);
      }}
    />
    <label htmlFor={id}>
      <Button variant="contained" component="span" className="btn">
        {buttonText}
      </Button>
    </label>
  </React.Fragment>
);

export default FileUpload;
