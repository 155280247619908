import React from 'react';
import {Loader} from '../../../components/Loader'
import { Button, Grid } from '@material-ui/core';
import { paymentsActions }from '../../../actions/paymentsActions'
import { connect } from 'react-redux';
import { PaymentsTableList } from './PaymentsTableList';
import {Link} from 'react-router-dom'
import {roleConstants} from "../../../constants/roleConstants";
import {urlHelper} from "../../../helpers/urlHelper";
import Filter from "../stat/Filter";

class AccountantPaymentsPage extends React.Component{
    componentDidMount() {
        const { payments } = this.props;
        if (!payments.items || payments.items.length === 0) {
            this.props.dispatch(paymentsActions.getPage(1));
        }
    }
    handleFilterChange = (filter) => {
        this.props.dispatch(paymentsActions.getPage(1, filter));
    }
    render() {
        const {payments} = this.props
        return (
            <Grid
                container
                role="main"
                className="container text-left"
                direction="column"
                spacing={16}
                wrap="nowrap"
            >
                <Grid item>
                    <Grid container spacing={16}>
                        <Grid item>
                            Платежи
                            <Link to={`${urlHelper.getHomeUrl(roleConstants.ROLE_ACCOUNTANT)}/payments/create/`}>
                                <Button aria-label="Создать" className="btn-add">
                                    Создать
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item>
                            <Filter onFiltersChange={this.handleFilterChange} payment_type={true}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item className="">
                    {payments.loading && <Loader />}
                    {payments.error && <span className="text-danger">Ошибка: {payments.error}</span>}
                    {payments.items && <PaymentsTableList data={payments.items} meta={payments.meta} />}
                </Grid>

                {/*<UserModal />*/}
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    const { payments } = state;
    return {
        payments,
    };
};

const mapDispatchToProps = dispatch => {

    return {
        dispatch
    };
};

const connectedAccountantPaymentsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountantPaymentsPage);
export { connectedAccountantPaymentsPage as AccountantPaymentsPage };


