import React, { useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { useAsync } from 'react-async';
import { connect } from 'react-redux';
import { TableHead, TableRow, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell/TableCell';

import { Loader, TablePagination } from '../../../../components';
import { applicationService } from '../../../../services/applicationService';
import { StateContext } from '../../reports/components/StateContext';
import { useDidUpdateEffect } from '../../cooperation/util/useWithQuery';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  },
  loaderWrapper: {
    minHeight: 300
  }
});

const ContentContainer = ({ user, classes, renderHeader, renderBody, status, col }) => {
  const { sort, filters, meta, setMeta } = useContext(StateContext);

  const transformedSort = !sort.sort_column ? {} : sort;
  const { data: res, run, isLoading, isSettled, isRejected, isResolved } = useAsync({
    deferFn: ([params]) =>
      applicationService.getAdminApplications({
        status,
        isFast: true,
        ...filters,
        ...transformedSort,
        ...params
      })
  });

  useDidUpdateEffect(() => {
    setMeta({ ...meta, page: 0 });
  }, [filters, sort]);

  useEffect(() => {
    run(meta);
  }, [meta]);

  return (
    <div>
      {isLoading && (
        <Grid className={classes.loaderWrapper} container alignItems="center" justify="center">
          <Loader />
        </Grid>
      )}
      {isSettled && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>{renderHeader()}</TableHead>
            <TableBody>
              {isRejected && (
                <TableRow>
                  <TableCell align="center" colSpan={col}>
                    <span className="text-danger">Ошибка: Не удалось загрузить данные</span>
                  </TableCell>
                </TableRow>
              )}
              {isResolved && isEmpty(res.data) && (
                <TableRow>
                  <TableCell align="center" colSpan={col}>
                    Заявки не найдены
                  </TableCell>
                </TableRow>
              )}
              {isResolved && !isEmpty(res.data) && renderBody(res.data, user)}
            </TableBody>
          </Table>
        </Paper>
      )}
      {isResolved && (
        <TablePagination
          options={[10, 20, 50]}
          meta={res.meta}
          onChangePage={page => setMeta({ ...meta, page })}
          onChangeRowsPerPage={perPage => setMeta({ ...meta, page: 0, per_page: perPage })}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.authentication.user
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(ContentContainer);
