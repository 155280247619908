import React from 'react';
import { connect } from 'react-redux';
import ShowByStatus from './ShowByStatus';

const ShowByStatusMap = ({ mapToProps, role, children }) => {
  const props = mapToProps[role];
  return props ? <ShowByStatus {...props}>{children}</ShowByStatus> : children;
};

const mapStateToProps = state => ({
  role: state.authentication.user.role_id
});

export default connect(mapStateToProps)(ShowByStatusMap);
