import React from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';

import ReportsFilterForm from './ReportsFilterForm';

const ReportsFilterModal = ({ initialValues, onSubmit, isOpen, setOpen }) => {
  const closeModal = () => setOpen(false);
  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <Toolbar className="text-left">
        <Typography variant="title" color="inherit" className="flex">
          Фильтровать
        </Typography>
        <IconButton color="inherit" onClick={closeModal}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>
      <DialogContent className="dialog">
        <ReportsFilterForm initialValues={initialValues} onSubmit={onSubmit} onClose={closeModal} />
      </DialogContent>
    </Dialog>
  );
};

export default ReportsFilterModal;
