import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { SortDirection } from '../Sort';

function SortableColumn({ children, column, sort, onSortChange }) {
  const isActive = sort.sort_column === column;
  function handleRequestSort() {
    const isDesc = isActive && sort.sort_type === 'desc';
    onSortChange({
      sort_type: isDesc ? SortDirection.Asc : SortDirection.Desc,
      sort_column: column
    });
  }
  return (
    <TableCell sortDirection={isActive ? sort.sort_type : false}>
      <TableSortLabel active={isActive} direction={sort.sort_type} onClick={handleRequestSort}>
        {children}
      </TableSortLabel>
    </TableCell>
  );
}

export default SortableColumn;
