import React from 'react';
import 'react-widgets/dist/css/react-widgets.css';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm } from 'redux-form';
import { RegisterStep1Modal } from './RegisterStep1Modal';
import { RegisterStep2Modal } from './RegisterStep2Modal';
import { regAppConstants } from '../../../constants/regAppConstants';

let RegisterForm = props => {
  const { error, handleSubmit, submitting } = props;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <RegisterStep1Modal error={error} handleSubmit={handleSubmit} submitting={submitting} />
        <RegisterStep2Modal error={error} handleSubmit={handleSubmit} submitting={submitting} />
      </form>
    </div>
  );
};

RegisterForm = reduxForm({
  form: 'register'
})(RegisterForm);

const selector = formValueSelector('register');
const mapStateToProps = state => {
  const roleValue = selector(state, 'role_id');
  return {
    roleValue,
    initialValues: {
      role_id: regAppConstants.ROLE_VALUES.individual,
      reason: regAppConstants.REASONS[0].id
    }
  };
};

RegisterForm = connect(mapStateToProps)(RegisterForm);

export default RegisterForm;
