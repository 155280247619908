import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AccountCircle from '@material-ui/icons/AccountCircle';
import VpnKey from '@material-ui/icons/VpnKey';

export const MuiTextField = ({
  icon,
  input,
  label,
  className,
  meta: { touched, error },
  showPasswordVisible,
  changePasswordVisible,
  passwordVisible,
  ...custom
}) => (
  <TextField
    placeholder={label}
    className={className}
    helperText={touched && error}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          {icon === 'login' && <AccountCircle />}
          {icon === 'password' && <VpnKey />}
        </InputAdornment>
      ),
      endAdornment: showPasswordVisible ? (
        <InputAdornment position="end">
          <IconButton aria-label="Toggle password visibility" onClick={changePasswordVisible}>
            {passwordVisible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ) : (
        ''
      ),
      disableUnderline: true
    }}
    {...input}
    {...custom}
  />
);
