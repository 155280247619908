import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import {connect} from 'react-redux';
import { isNumber, get } from 'lodash';
import _ from 'lodash'
import {regAppService} from "../../../services/regAppService";
const USER_ACCEPT_STATUS =3;

class UserFormField extends Component {
    state = {name: '', inputValue: null, options: []};
    promiseOptions = name =>
        new Promise(async resolve => {
                let response = null;
                await regAppService.getPageByStatus(1, USER_ACCEPT_STATUS, '', {name})
                    .then((r) => {response = r;})
                const options = response.data
                    .filter((v) => v.id > 0)
                    .map((v) => Object.assign({}, v, {label: v.name}));
                this.setState({...this.state, options})
                resolve(options);
        });

    render() {
        const {selectOptions, meta, link, form} = this.props
        const handleSelectChange = (v) => {
            this.props.input.onChange(v.id)
            this.setState({...this.state, inputValue: v.name && v.id ? v : null})
        }
        const inputField =  (
            <div>
                <AsyncSelect
                    {...this.props}
                    cacheOptions
                    loadOptions={this.promiseOptions}
                    defaultOptions
                    value={this.state.options.find((option) => option.id === selectOptions.inputValue)}
                    placeholder={selectOptions.placeholder}
                    // onInputChange={this.handleInputChange}
                    onChange={handleSelectChange}
                    isLoading={false}
                    loadingMessage={() => 'Загрузка'}
                    noOptionsMessage={() => 'Совпадений нет'}
                />

            </div>
        );
        return (
            <div className={`form-group${meta && meta.touched && meta.error ? ' has-error' : ''}`}>
                <div className="row">
                    {selectOptions.placeholder && <div className="col-md-4">
                        <p className="label-form">{selectOptions.placeholder}</p>
                    </div>}
                    <div className={`col-md-${selectOptions.placeholder ? 8 : 12} input-link-container`}>
                        {link && <a href={link} target="_blank" className="input-link" />}
                        {inputField}
                        {meta && meta.touched && (meta.error && <span>{meta.error}</span>)}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}
const mapDispatchToProps = (dispatch, props) => {
    return {
    }
}

const ConnectedUserFormField = connect(mapStateToProps, mapDispatchToProps)(UserFormField)
export {ConnectedUserFormField as UserFormField}