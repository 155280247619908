import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

import StatisticsFilterForm from './StatisticsFilterForm';

const StatisticsFilterModal = ({ initialValues, onSubmit, onResetStatistics }) => {
  const [isOpen, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  return (
    <React.Fragment>
      <Button
        className="no-margin info-btn"
        variant="outlined"
        color="primary"
        type="button"
        onClick={() => setOpen(true)}
      >
        Фильтровать
      </Button>
      <Dialog open={isOpen} aria-labelledby="form-dialog-title">
        <Toolbar className="text-left">
          <Typography variant="title" color="inherit" className="flex">
            Фильтровать
          </Typography>
          <IconButton color="inherit" onClick={closeModal}>
            <CloseIcon className="blue" />
          </IconButton>
        </Toolbar>
        <DialogContent className="dialog">
          <StatisticsFilterForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            onReset={onResetStatistics}
            onClose={closeModal}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default StatisticsFilterModal;
