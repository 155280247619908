import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Field, formValueSelector, submit } from 'redux-form';
import Button from '@material-ui/core/Button';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { Loader } from '../../../components/Loader';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { fieldMasks } from '../../../helpers/fieldMasks';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { authActions } from '../../../actions/authActions';

const roleValues = {
  individual: '3',
  firm: '4'
};

const RegisterStep2Modal = props => {
  const {
    error,
    submitting,
    showRegisterStep2,
    handleStep2Close,
    handleToStep1,
    submitForm,
    roleValue
  } = props;
  return (
    <Dialog
      className="dialog-window"
      open={showRegisterStep2}
      onClose={handleStep2Close}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <IconButton color="inherit" onClick={handleToStep1}>
          <a className="arrow_back blue">
            <KeyboardBackspace />
          </a>
        </IconButton>
        <Typography variant="title" color="inherit" className="flex">
          Регистрация
        </Typography>
        <IconButton color="inherit" onClick={handleStep2Close}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        {/* <a className="arrow_back" onClick={handleToStep1}><KeyboardBackspace /></a> */}
        {/* Поля юр лица */}
        {roleValue === roleValues.firm && (
          <div>
            <h4>Контактные данные</h4>
            <small>Контактный телефон организации</small>
            <Field
              name="phone"
              type="text"
              label="Контактный телефон организации"
              validate={[fieldValidators.required, fieldValidators.phoneRus]}
              component={FormFieldReg}
              {...fieldMasks.phoneRus}
            />
            <small>Городской номер телефона</small>
            <Field
              name="phone_static"
              type="text"
              label="Городской номер телефона"
              component={FormFieldReg}
            />
            <Field
              name="contact_fio"
              type="text"
              placeholder="ФИО контактного лица"
              validate={[fieldValidators.required, fieldValidators.maxLength100]}
              component={FormFieldReg}
            />
            <Field
              name="email"
              type="email"
              placeholder="Email"
              validate={[
                fieldValidators.required,
                fieldValidators.email,
                fieldValidators.maxLength40
              ]}
              component={FormFieldReg}
            />
            <Field
              name="password"
              type="password"
              placeholder="Пароль"
              validate={[
                fieldValidators.required,
                fieldValidators.minLength8,
                fieldValidators.maxLength40
              ]}
              component={FormFieldReg}
            />

            <Field
              name="password_confirmation"
              type="password"
              placeholder="Повторите пароль"
              validate={fieldValidators.passwordsMatch}
              component={FormFieldReg}
            />

            {error && <strong>{error}</strong>}

            <div className="text-left">
              <Field
                name="remember"
                type="checkbox"
                label="Согласие на обработку персональных данных"
                className="checkbox"
                component={MuiCheckbox}
                validate={fieldValidators.required}
              />
            </div>
            <div>
              {submitting && <Loader />}
              <Button
                className="btn"
                onClick={submitForm}
                variant="contained"
                color="primary"
                disabled={submitting}
              >
                Зарегистрироваться
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const selector = formValueSelector('register');
const mapStateToProps = state => {
  const { showRegisterStep2 } = state.registration;
  const roleValue = selector(state, 'role_id');

  return {
    roleValue,
    showRegisterStep2
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleToStep1: () => {
      dispatch(authActions.openRegisterStep1Modal());
      dispatch(authActions.closeRegisterStep2Modal());
    },
    handleStep2Open: () => {
      dispatch(authActions.openRegisterStep2Modal());
    },
    handleStep2Close: () => {
      dispatch(authActions.closeRegisterStep2Modal());
    },
    submitForm: () => {
      dispatch(submit('register'));
    }
  };
};

const connectedRegisterStep2Modal = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterStep2Modal);
export { connectedRegisterStep2Modal as RegisterStep2Modal };
