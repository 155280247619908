import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'vehicles';
};

const getAdminPage = (page, status) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/status/${status}?page=${page}`,
    {},
    true
  );
};

const adminUpdate = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/set-status/${values.id}`,
    values,
    true
  );
};

const toWork = id => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/to-work/${id}`,
    { id },
    true
  );
};
const accept = id => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/accept/${id}`,
    { id },
    true
  );
};
const decline = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/decline/${values.id}`,
    values,
    true
  );
};

const getPage = page => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}?page=${page}`, {}, true);
};

const getForUserPage = (user, page, filters = {}) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/user/${user.id}?page=${page}`,
    filters,
    true
  );
};

const getCabinetPage = (tabIndex = '', values = {}) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/user/${tabIndex}`,
    values,
    true
  );
};
const getCabinetAll = (tabIndex = '') => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/user/all/${tabIndex}`,
    {},
    true
  );
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const saveFirmUsers = (id, firmUsers) => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${id}/firm_users`,
    firmUsers,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};

const getSearchPage = page => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/search?page=${page}`,
    {},
    true
  );
};

const checkStatuses = numbers => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/check-statuses`,
    { numbers },
    true
  );
};

const adminFilter = values => {
  const page = parseInt(values.page);
  let pageSuffix = '';
  if (page && !isNaN(page)) {
    pageSuffix = `?page=${page}`;
  }

  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/admin-filter/${values.status}${pageSuffix}`,
    values,
    true
  );
};

const getFirmVehicles = (firm, filter) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/firm/${firm}`,
    filter,
    true
  );
};

export const vehicleService = {
  getAdminPage,
  toWork,
  accept,
  decline,

  adminUpdate,

  getPage,
  getCabinetPage,
  getCabinetAll,
  getItem,
  saveFirmUsers,
  create,
  update,
  delete: _delete,

  // apps
  getSearchPage,
  getForUserPage,

  // privilege statuses
  checkStatuses,

  adminFilter,

  getFirmVehicles
};
