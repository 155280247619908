import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { vehicleActions } from '../../../actions/vehicleActions';

const DetailsModal = props => {
  const { handleDetailsClose, adminVehicles } = props;

  return (
    <Dialog
      open={adminVehicles.showDetailsModal}
      onClose={handleDetailsClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Подробная информация о заявке
        </Typography>
        <IconButton color="inherit" onClick={handleDetailsClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <span>Модель: {adminVehicles.currentItem && adminVehicles.currentItem.modelTitle}</span>
        <span>Номер: {adminVehicles.currentItem && adminVehicles.currentItem.number}</span>
        ...
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { adminVehicles } = state;
  return {
    adminVehicles
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleDetailsClose: () => {
      dispatch(vehicleActions.closeDetailsModal());
    }
  };
};

const connectedDetailsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsModal);
export { connectedDetailsModal as DetailsModal };
