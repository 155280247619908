import { vehicleConstants } from '../constants/vehicleConstants';

const initialState = {
  items: [],
  error: undefined,
  loading: false,
  meta: {}
};

export const adminFirmVehicles = (state = initialState, action) => {
  switch (action.type) {
    case vehicleConstants.GET_FIRM_VEHICLES_RESET:
      return initialState;
    case vehicleConstants.GET_FIRM_VEHICLES_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true
      };
    case vehicleConstants.GET_FIRM_VEHICLES_FAILURE:
      return {
        ...state,
        error: 'Не удалось загрузить список!',
        loading: false
      };
    case vehicleConstants.GET_FIRM_VEHICLES_SUCCESS:
      return {
        ...state,
        items: action.vehicles.data,
        meta: action.vehicles.meta,
        error: undefined,
        loading: false
      };
    default:
      return state;
  }
};
