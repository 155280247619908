import { change, formValueSelector } from 'redux-form';
import { privilegeStatusService } from '../services/privilegeStatusService';
import { privilegeStatusConstants } from '../constants/privilegeStatusConstants';
import { fileService } from '../services/fileService';
import { applicationConstants as applcationConstants } from '../constants/applicationConstants';
import { getOldFiles } from '../helpers/fileUploadHelper';

const getPage = page => {
  const request = page => {
    return { type: privilegeStatusConstants.GET_PAGE_REQUEST, page };
  };

  const success = privilegeStatuses => {
    return {
      type: privilegeStatusConstants.GET_PAGE_SUCCESS,
      privilegeStatuses
    };
  };

  const failure = error => {
    return { type: privilegeStatusConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(page));

    privilegeStatusService
      .getPage(page)
      .then(
        privilegeStatuses => dispatch(success(privilegeStatuses)),
        error => dispatch(failure(error))
      );
  };
};

const getAll = () => {
  const request = () => {
    return { type: privilegeStatusConstants.GET_ALL_REQUEST };
  };

  const success = privilegeStatuses => {
    return {
      type: privilegeStatusConstants.GET_ALL_SUCCESS,
      privilegeStatuses
    };
  };

  const failure = error => {
    return { type: privilegeStatusConstants.GET_ALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    privilegeStatusService
      .getAll()
      .then(
        privilegeStatuses => dispatch(success(privilegeStatuses)),
        error => dispatch(failure(error))
      );
  };
};

const _delete = id => {
  const request = id => {
    return { type: privilegeStatusConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: privilegeStatusConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: privilegeStatusConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    privilegeStatusService
      .delete(id)
      .then(privilegeStatus => dispatch(success(id)), error => dispatch(failure(id, error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: privilegeStatusConstants.LOAD_REQUEST, id };
  };

  const success = privilegeStatus => {
    return { type: privilegeStatusConstants.LOAD_SUCCESS, privilegeStatus };
  };

  const failure = error => {
    return { type: privilegeStatusConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    privilegeStatusService
      .getItem(id)
      .then(
        privilegeStatus => dispatch(success(privilegeStatus)),
        error => dispatch(failure(error))
      );
  };
};

const create = values => {
  const request = value => {
    return { type: privilegeStatusConstants.CREATE_REQUEST, value };
  };

  const success = privilegeStatus => {
    return { type: privilegeStatusConstants.CREATE_SUCCESS, privilegeStatus };
  };

  return dispatch => {
    dispatch(request(values));

    return privilegeStatusService
      .create(values)
      .then(privilegeStatus => dispatch(success(privilegeStatus)));
  };
};

const update = values => {
  const request = value => {
    return { type: privilegeStatusConstants.UPDATE_REQUEST, value };
  };

  const success = privilegeStatus => {
    return { type: privilegeStatusConstants.UPDATE_SUCCESS, privilegeStatus };
  };

  return dispatch => {
    dispatch(request(values));

    return privilegeStatusService
      .update(values)
      .then(privilegeStatus => dispatch(success(privilegeStatus)));
  };
};

const openFormModal = () => {
  return { type: privilegeStatusConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: privilegeStatusConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: privilegeStatusConstants.SET_CURRENT_ITEM, item };
};

const uploadFile = (values, uploaderId) => {
  const request = values => {
    return {
      type: privilegeStatusConstants.FILE_UPLOAD_REQUEST,
      uploaderId,
      name: values.name
    };
  };
  const success = data => {
    return {
      type: privilegeStatusConstants.FILE_UPLOAD_SUCCESS,
      data,
      uploaderId
    };
  };
  const failure = () => {
    return { type: privilegeStatusConstants.FILE_UPLOAD_FAILURE, uploaderId };
  };

  return (dispatch, getState) => {
    dispatch(request(values));

    return fileService
      .uploadStatus(values)
      .then(response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));
        }
        const statusForm = 'privilege-status-form';
        const oldFiles = getOldFiles(statusForm, getState);

        dispatch(change(statusForm, 'files', [...oldFiles, response.data]));

        return response.data;
      })
      .catch(err => {
        dispatch(failure());
        console.log('Upload error', err);
      });
  };
};
const removeFile = id => {
  return { type: privilegeStatusConstants.FILE_REMOVE, id };
};

const checkStatus = number => {
  const request = number => {
    return { type: applcationConstants.CHECK_STATUS_REQUEST, number };
  };
  const success = status => {
    return { type: applcationConstants.CHECK_STATUS_SUCCESS, status };
  };

  return dispatch => {
    dispatch(request(number));

    return privilegeStatusService
      .checkStatus(number)
      .then(response => {
        dispatch(success(response.data));

        return response.data;
      })
      .catch(err => {
        console.log('Check status error', err);
      });
  };
};

export const privilegeStatusActions = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,

  openFormModal,
  closeFormModal,
  setCurrentItem,

  uploadFile,
  removeFile,

  checkStatus
};
