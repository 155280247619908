import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { get, has, isArray, isEmpty } from 'lodash';

import { Map, TileLayer } from 'react-leaflet';
import StartPointIcon from '@material-ui/icons/FiberManualRecord';
import PointIcon from '@material-ui/icons/Adjust';
import EndPointIcon from '@material-ui/icons/Room';

import { applicationActions } from '../../../actions/applicationActions';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { FormField } from '../../../components/form/FormField';
import { FormFieldNoLabel } from '../../../components/form/FormFieldNoLabel';
import { renderSelectField } from '../../../components/form/renderSelectField';
import { renderSelectFieldNoLabel } from '../../../components/form/renderSelectFieldNoLabel';
import { momentHelper } from '../../../helpers/momentHelper';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { formatHelper } from '../../../helpers/formatHelper';
import { applicationConstants } from '../../../constants/applicationConstants';
import { Loader } from '../../../components/Loader';
import { roleConstants } from '../../../constants/roleConstants';
import { fileConstants } from '../../../constants/fileConstants';
import { urlHelper } from '../../../helpers/urlHelper';
import ControlMarkCard from '../../admin/applications/controlMarks/ControlMarkCard';
import { statusHelper } from '../../../helpers/statusHelper';
import { FormTextareaLabel } from '../../../components/form/FormTextareaLabel';
import FileUploader from '../../../components/form/FileUploader';
import ApplicationSchema from './ApplicationSchema';
import { specialConditionActions } from '../../../actions/specialConditionActions';
import { applicationHelper } from '../../../helpers/applicationHelper';
import { createFileUploadHandler } from '../../../helpers/fileUploadHelper';
import Truncate from '../../../components/Truncate';
import RestrictedArea from '../../../components/map/RestrictedArea';
import DrawMarkers from '../../../components/map/DrawMarkers';
import CooperationApplicationInfo from '../../../components/CooperationApplicationInfo';
import ApvgksLayer from '../../../components/map/ApvksLayer';
import ApplicationPrintDoc from '../../admin/applications/buttons/ApplicationPrintDoc';

class ApplicationViewPage extends Component {
  componentDidMount() {
    const { match, specialConditions, dispatch } = this.props;
    this.props.dispatch(applicationActions.getFullItem(match.params.id));

    if (!specialConditions.currentItem) {
      dispatch(specialConditionActions.getItem(1));
    }
  }

  render() {
    const {
      userApplications,
      handleToAdmin,
      handleToDraft,
      handleFileUpload,
      handleFileRemove,
      authentication,
      specialConditions
    } = this.props;
    const { user } = authentication;
    const { currentItem } = userApplications;
    const { actionLoading } = userApplications;

    // load
    let escortVehicles = [];
    if (currentItem && currentItem.load && currentItem.load.escort) {
      escortVehicles = currentItem.load.escort;
    }

    // map
    // const stamenTonerTiles = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
    const stamenTonerTiles = 'https://tile.aisktg.ru/tatarstan/{z}/{x}/{y}.png';
    const stamenTonerAttr = '';
    const mapCenter = [55.211, 50.634];
    const zoomLevel = 7;

    let markers = [];
    if (currentItem && currentItem.route) {
      markers = JSON.parse(currentItem.route.points);
    } else {
      markers = [];
    }

    const isSpringAvailable = () => {
      const specialDateItem = specialConditions.currentItem;
      if (!specialDateItem || !currentItem) {
        return false;
      }

      const startDateObj = momentHelper.getDateFromPicker(
        momentHelper.dotFormatToYmd(currentItem.start_date)
      );
      const finishDateObj = momentHelper.getDateFromPicker(
        momentHelper.dotFormatToYmd(currentItem.finish_date)
      );

      const springStartDate = momentHelper.getDateFromPicker(specialDateItem.start_date);
      const springFinishDate = momentHelper.getDateFromPicker(specialDateItem.finish_date);

      return !(startDateObj > springFinishDate || finishDateObj < springStartDate);
    };

    const hasLoadFiles =
      has(currentItem, 'files.load_files') && !isEmpty(currentItem.files.load_files);

    const isNew =
      currentItem &&
      (currentItem.status === applicationConstants.STATUS_NEW ||
        currentItem.status === applicationConstants.STATUS_REVIEW);

    const showStandard = currentItem && (isNew || currentItem.is_spring === 0);
    const showSpring = currentItem && (isNew || currentItem.is_spring === 1);

    return (
      <main role="main" className="container application-container">
        {currentItem && (
          <div>
            <h2 className="h2-title application-header">
              Заявление
              {(currentItem.status !== applicationConstants.STATUS_NEW ||
                (currentItem.status === applicationConstants.STATUS_NEW &&
                  !currentItem.is_draft)) &&
                ` №${formatHelper.appNumber(currentItem)}`}
              <span className="margin-left" />
              {actionLoading && <Loader />}
              {!actionLoading &&
              currentItem.is_draft &&
              // user has access to remove
              (user.role_id !== roleConstants.ROLE_FIRMUSER ||
                currentItem.employee_id === user.id) ? (
                <button
                  name="action"
                  value="send"
                  className="btn"
                  type="button"
                  onClick={e => handleToAdmin(currentItem.id)}
                >
                  Отправить на согласование
                </button>
              ) : (
                ''
              )}
              {!actionLoading &&
              !currentItem.is_draft &&
              (currentItem.status === applicationConstants.STATUS_NEW ||
                currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
                currentItem.status === applicationConstants.STATUS_REPEAT) ? (
                <button
                  name="action"
                  value="to_draft"
                  className="btn"
                  type="button"
                  onClick={e => handleToDraft(currentItem.id)}
                >
                  Вернуть на доработку
                </button>
              ) : (
                ''
              )}
              {currentItem.status === applicationConstants.STATUS_DECLINE ? (
                <Link
                  to={`${urlHelper.getHomeUrl(user.role_id)}/applications/update/${
                    currentItem.id
                  }/step/vehicle`}
                >
                  <button name="action" value="update" className="btn" type="button">
                    Редактировать
                  </button>
                </Link>
              ) : (
                ''
              )}
              <ApplicationPrintDoc application={currentItem}/>
            </h2>
            <div className="blue_frame">
              <p>
                <strong>Статус:</strong> {statusHelper.getStatusText(user.role_id, currentItem)}
              </p>
              {currentItem.status === applicationConstants.STATUS_DECLINE ? (
                <div>
                  <div>
                    <strong>Причина отклонения: </strong>
                    {currentItem.comment}
                  </div>
                  <br />
                </div>
              ) : (
                ''
              )}
              {/* springWarning */}
              {applicationHelper.checkBeforeSpring(currentItem, specialConditions.currentItem) && (
                <p>
                  <span key="2" className="danger">
                    Данное разрешение не действует в период весеннего ограничения при условии
                    превышения допустимых нагрузок в размере 6 тонн/ось
                  </span>
                </p>
              )}
            </div>

            <div className="">
              <div className="row">
                <div className="col-md-2">
                  <p>Вид перевозки</p>
                </div>
                <div className="col-md-6">
                  <Field
                    className="form-control"
                    name="load_type_id"
                    label="Вид перевозки"
                    disabled="disabled"
                    component={renderSelectFieldNoLabel}
                    validate={[fieldValidators.required]}
                  >
                    {currentItem && (currentItem.route || currentItem.privilege_status_id) ? (
                      <option>
                        {dictionaryHelper.routeTypes.getItemTitle(
                          currentItem.privilege_status_id ? 2 : currentItem.route.type_id
                        )}
                      </option>
                    ) : (
                      ''
                    )}
                  </Field>
                </div>
                <div className="col-md-1">
                  <p>Год</p>
                </div>
                <div className="col-md-3">
                  <Field
                    className="form-control"
                    name="year"
                    label="Год"
                    placeholder="Год"
                    disabled="disabled"
                    component={FormFieldNoLabel}
                    validate={[fieldValidators.required]}
                  />
                </div>

                {/* 2 */}

                <div className="col-md-2">
                  <p>Количество поездок. Осталось / всего</p>
                </div>
                <div className="col-md-2">
                  <Field
                    className="form-control"
                    name="runs_count"
                    label="Количество поездок"
                    placeholder="10"
                    disabled="disabled"
                    component={FormFieldNoLabel}
                    validate={[fieldValidators.required]}
                  />
                </div>
                <div className="col-md-1">
                  <p>Период&nbsp;с</p>
                </div>
                <div className="col-md-3">
                  <Field
                    className="form-control"
                    name="start_date"
                    type="date"
                    label="Период поездок с"
                    disabled="disabled"
                    component={FormFieldNoLabel}
                    validate={[fieldValidators.required]}
                  />
                </div>
                <div className="col-md-1">
                  <p>по</p>
                </div>
                <div className="col-md-3">
                  <Field
                    className="form-control"
                    name="finish_date"
                    type="date"
                    label="по"
                    disabled="disabled"
                    component={FormFieldNoLabel}
                    validate={[fieldValidators.required]}
                  />
                </div>
              </div>

              {!currentItem.privilege_status_id && (
                <div className="map row">
                  <div className="col-md-12">
                    <Map center={mapCenter} zoom={zoomLevel}>
                      <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
                      <RestrictedArea />
                      <ApvgksLayer />
                      <DrawMarkers markers={markers} />
                    </Map>
                  </div>
                  <div className="col-md-12">
                    <div>
                      <div>
                        <h2 className="h2-title margin-bottom-10">Маршрут</h2>
                        <p>Движение по региональным дорогам</p>
                        {(currentItem.status === applicationConstants.STATUS_ACCEPT ||
                          currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
                          currentItem.status === applicationConstants.STATUS_ACTIVE ||
                          currentItem.status === applicationConstants.STATUS_COMPLETE) && (
                          <>
                            {currentItem.price_info ? (
                              <>
                                <p>
                                  Общая протяженность маршрута{' '}
                                  <strong>
                                    {currentItem.route ? currentItem.route.distance : 0}
                                  </strong>{' '}
                                  км
                                </p>
                              </>
                            ) : (
                              <>
                                <p>
                                  Протяженность маршрута{' '}
                                  <strong>
                                    {currentItem.route ? currentItem.route.distance : 0}
                                  </strong>{' '}
                                  км
                                </p>
                              </>
                            )}
                          </>
                        )}

                        <div className="points_area">
                          <div className="points_div">
                            <div className="points_line" />
                          </div>
                          {markers.map((marker, index) => {
                            return (
                              <div className="row" key={`autosuggest-row-${index}`}>
                                <div className="col-md-2 points-date">
                                  {index === 0 && <StartPointIcon />}
                                  {index > 0 && index < markers.length - 1 && <PointIcon />}
                                  {index === markers.length - 1 && <EndPointIcon className="red" />}
                                </div>
                                <div className="col-md-10">
                                  <Field
                                    className="form-control"
                                    name={`route[${index}]`}
                                    label=""
                                    placeholder=""
                                    disabled="disabled"
                                    component={FormFieldNoLabel}
                                    validate={[fieldValidators.required]}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {currentItem.privilege_status_id && (
                <div className="map row">
                  <div className="col-md-12">
                    <p>
                      <strong>Информация о маршруте</strong>
                    </p>

                    <div className="privilege_status">
                      <Truncate maxCount={500}>
                        {currentItem.privilegeStatus && currentItem.privilegeStatus.route_info}
                      </Truncate>
                    </div>
                    {/* <Field className="form-control"
                                       name="route_info"
                                       label="Информация о маршруте"
                                       disabled="disabled"
                                       component={FormTextareaLabel}
                                /> */}
                  </div>
                </div>
              )}
              <br />

              <div className="row">
                <div className="col-md-10">
                  <Field
                    className="form-control"
                    name="vehicle_info"
                    label="Транспортное средство"
                    placeholder="(Марка, модель, ГРЗ)"
                    disabled="disabled"
                    component={FormField}
                    validate={[fieldValidators.required]}
                    link={`${urlHelper.getHomeUrl(user.role_id)}/vehicles/update/${get(
                      currentItem,
                      'vehicle_id'
                    )}`}
                  />
                  {currentItem &&
                    currentItem.trailers &&
                    currentItem.trailers.map((trailer, index) => (
                      <Field
                        key={`trailer-${index}`}
                        className="form-control"
                        name={`trailers_info[${index}]`}
                        label="Прицеп"
                        placeholder="(Марка, модель, ГРЗ)"
                        disabled="disabled"
                        component={FormField}
                        validate={[fieldValidators.required]}
                        link={`${urlHelper.getHomeUrl(user.role_id)}/vehicles/update/${get(
                          trailer,
                          'id'
                        )}`}
                      />
                    ))}

                  <Field
                    className="form-control"
                    name="load_info"
                    label="Характеристики груза"
                    placeholder="(Наименование, габариты, масса)"
                    disabled="disabled"
                    component={FormField}
                    validate={[fieldValidators.required]}
                  />
                  <Field
                    className="form-control"
                    name="user_name"
                    label={
                      currentItem.user && currentItem.user.role_id === roleConstants.ROLE_INDIVIDUAL
                        ? 'ФИО заявителя (физ лицо)'
                        : 'Наименование организации владельца транспортного средства'
                    }
                    placeholder="АО ТАТАВТОДОР АПАСТОВСКИЙ Ф-Л"
                    disabled="disabled"
                    component={FormField}
                    validate={[fieldValidators.required]}
                  />
                  {currentItem &&
                    currentItem.user &&
                    currentItem.user.role_id === roleConstants.ROLE_FIRM && (
                      <Field
                        className="form-control"
                        name="user_address"
                        label="Адрес владельца транспортного средства"
                        placeholder=""
                        disabled="disabled"
                        component={FormField}
                        validate={[fieldValidators.required]}
                      />
                    )}
                  <Field
                    className="form-control"
                    name="user_phone"
                    label="Телефон владельца транспортного средства"
                    placeholder="+79172637435"
                    disabled="disabled"
                    component={FormField}
                    validate={[fieldValidators.required]}
                  />

                  <p>
                    <strong>Параметры ТС (автопоезда)</strong>
                  </p>

                  {!hasLoadFiles && (
                    <div className="car-character-block nowrap overflow">
                      <ApplicationSchema currentItem={currentItem} />
                    </div>
                  )}

                  <br />

                  {hasLoadFiles && (
                    <div className="row">
                      <div className="col-md-4">Схема расположения груза</div>
                      <div className="col-md-8">
                        {get(currentItem, 'files.load_files', []).map(({ id, name, url }) => (
                          <div key={id}>
                            <a target="_blank" href={url}>
                              {name}
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <br />

                  <Field
                    className="form-control"
                    name="weight"
                    label="Общая масса ТС"
                    placeholder=""
                    disabled="disabled"
                    component={FormField}
                    validate={[fieldValidators.required]}
                  />
                  <Field
                    className="form-control"
                    name="axles_lengths"
                    label="Расстояние между осями"
                    placeholder="2м х 3м х 8м х 2м"
                    disabled="disabled"
                    component={FormField}
                    validate={[fieldValidators.required]}
                  />
                  <Field
                    className="form-control"
                    name="axles_load"
                    label="Нагрузка на оси"
                    placeholder="2м х 3м х 8м х 2м"
                    disabled="disabled"
                    component={FormField}
                    validate={[fieldValidators.required]}
                  />
                  {showStandard && (
                    <Field
                      className="form-control"
                      name="permissible_load"
                      label="Допустимая нагрузка на оси"
                      placeholder=""
                      disabled="disabled"
                      component={FormField}
                    />
                  )}
                  {applicationHelper.checkSpring(currentItem, specialConditions.currentItem) &&
                    showSpring && (
                      <Field
                        className="form-control"
                        name="permissible_load_spring"
                        label="Допустимая нагрузка на оси (Весна)"
                        placeholder=""
                        disabled="disabled"
                        component={FormField}
                      />
                    )}
                  <Field
                    className="form-control"
                    name="wheelsCount"
                    label="Скатность"
                    placeholder=""
                    disabled="disabled"
                    component={FormField}
                  />
                  <Field
                    className="form-control"
                    name="wheels"
                    label="Количество колес"
                    placeholder=""
                    disabled="disabled"
                    component={FormField}
                  />
                  <div className="row">
                    <div className="col-md-3">
                      <Field
                        className="form-control"
                        name="length"
                        label="Длина"
                        placeholder="8м"
                        disabled="disabled"
                        component={FormField}
                        validate={[fieldValidators.required]}
                      />
                    </div>
                    <div className="col-md-3">
                      <Field
                        className="form-control"
                        name="width"
                        label="Ширина"
                        placeholder="8м"
                        disabled="disabled"
                        component={FormField}
                        validate={[fieldValidators.required]}
                      />
                    </div>
                    <div className="col-md-3">
                      <Field
                        className="form-control"
                        name="height"
                        label="Высота"
                        placeholder="8м"
                        disabled="disabled"
                        component={FormField}
                        validate={[fieldValidators.required]}
                      />
                    </div>
                  </div>

                  <Field
                    className="form-control"
                    name="escort_count"
                    label="Вид сопровождения"
                    disabled="disabled"
                    component={renderSelectField}
                    validate={[fieldValidators.required]}
                  >
                    <option>
                      {dictionaryHelper.escorts.getItemTitle(
                        currentItem.load ? currentItem.load.escort_count : 0
                      )}
                    </option>
                  </Field>

                  {escortVehicles.length > 0 &&
                    escortVehicles.map((escortVehicle, index) => (
                      <div key={index} className="row text-right">
                        <div className="col-md-2 text-left">Автомобиль прикрытия {index + 1}</div>
                        <div className="col-md-3">
                          <Field
                            name={`escort[${index}][brand]`}
                            label="Марка"
                            placeholder=""
                            component={FormField}
                            disabled="disabled"
                          />
                        </div>
                        <div className="col-md-3">
                          <Field
                            name={`escort[${index}][model]`}
                            label="Модель"
                            placeholder=""
                            component={FormField}
                            disabled="disabled"
                          />
                        </div>
                        <div className="col-md-3">
                          <Field
                            name={`escort[${index}][number]`}
                            label="ГРЗ"
                            placeholder=""
                            component={FormField}
                            disabled="disabled"
                          />
                        </div>
                      </div>
                    ))}
                  <CooperationApplicationInfo
                    smev={currentItem ? currentItem.smev : null}
                    is_smev={currentItem ? currentItem.is_smev : false}
                  />
                  <p>
                    <strong>Особые условия движения</strong>
                  </p>
                  {currentItem && currentItem.privilegeStatus && (
                    <div>
                      <Field
                        className="form-control"
                        name="privilege_title"
                        label="Название льготы"
                        placeholder="Льготник на ЧМ"
                        disabled="disabled"
                        component={FormField}
                        validate={[fieldValidators.required]}
                      />
                      <div className="row">
                        <div className="col-md-5">
                          <Field
                            className="form-control"
                            name="privilege_start_date"
                            label="Период действия с"
                            type="date"
                            disabled="disabled"
                            component={FormField}
                            validate={[fieldValidators.required]}
                          />
                        </div>
                        <div className="col-md-5">
                          <Field
                            className="form-control"
                            name="privilege_finish_date"
                            label="по"
                            type="date"
                            disabled="disabled"
                            component={FormField}
                            validate={[fieldValidators.required]}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {currentItem && !currentItem.privilegeStatus && (
                    <div className="border">
                      <b>Без особого статуса</b>
                    </div>
                  )}
                  {currentItem && get(currentItem, 'dates.is_penalty') && (
                    <React.Fragment>
                      <br />
                      <p>
                        <strong>Штрафник</strong>
                      </p>
                      <Field
                        className="form-control"
                        name="penalty_post"
                        label="№ ПВК"
                        disabled="disabled"
                        component={FormField}
                      />
                      <Field
                        className="form-control"
                        name="penalty_number"
                        label="№ акта"
                        disabled="disabled"
                        component={FormField}
                      />
                      <Field
                        className="form-control"
                        name="penalty_place"
                        label="Штрафстоянка"
                        disabled="disabled"
                        component={FormField}
                      />
                      <div id="file-list">
                        {get(currentItem, 'files.penalty_files', []).map(file => (
                          <div className="form-group" key={file.id}>
                            <div className="row">
                              <div className="col-md-4">
                                <p className="label-form">Акт</p>
                              </div>
                              <div className="col-md-8">
                                <a target="_blank" href={file.url}>
                                  {file.name}
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  )}
                  <br />

                  <p>
                    <strong>Пункт выдачи</strong>
                  </p>
                  <Field
                    className="form-control"
                    name=""
                    label="Территориальное управление ГБУ БДД"
                    disabled="disabled"
                    component={renderSelectField}
                    validate={[fieldValidators.required]}
                  >
                    <option>
                      {currentItem.dates
                        ? dictionaryHelper.issuePlaces.getItemTitle(
                            currentItem.dates.issue_place_id
                          )
                        : 'Не выбран'}
                    </option>
                  </Field>
                  <br />

                  {(currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
                    currentItem.status === applicationConstants.STATUS_ACCEPT ||
                    currentItem.status === applicationConstants.STATUS_ACTIVE) &&
                    currentItem.admin && (
                      <div>
                        <p>
                          <strong>
                            Разрешение выдано{' '}
                            {currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES
                              ? ' с изменениями'
                              : ''}
                          </strong>
                        </p>
                        {currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES && (
                          <Field
                            className="form-control"
                            name="comment"
                            label="Комментарий к заявке"
                            disabled="disabled"
                            component={FormTextareaLabel}
                            validate={[fieldValidators.required]}
                          />
                        )
                        // <p> {currentItem.comment}</p>
                        }
                        <Field
                          className="form-control"
                          name="admin_name"
                          label="Сотрудник, одобривший разрешение"
                          disabled="disabled"
                          component={FormField}
                          validate={[fieldValidators.required]}
                        />

                        <Field
                          className="form-control"
                          name="accept_date"
                          label="Дата одобрения"
                          disabled="disabled"
                          component={FormField}
                          validate={[fieldValidators.required]}
                        />
                      </div>
                    )}

                  {(currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
                    currentItem.status === applicationConstants.STATUS_ACCEPT ||
                    currentItem.status === applicationConstants.STATUS_ACTIVE) && (
                    <>
                      {currentItem.price_info ? (
                        <>
                          <Field
                            className="form-control"
                            name="price"
                            label="Стоимость разрешения"
                            disabled="disabled"
                            component={FormField}
                            validate={[fieldValidators.required]}
                          />
                        </>
                      ) : (
                        <Field
                          className="form-control"
                          name="price"
                          label="Стоимость разрешения"
                          disabled="disabled"
                          component={FormField}
                          validate={[fieldValidators.required]}
                        />
                      )}
                    </>
                  )
                  // <p><strong>Цена: {currentItem.price}</strong></p>
                  }
                  {(currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
                    currentItem.status === applicationConstants.STATUS_ACCEPT ||
                    currentItem.status === applicationConstants.STATUS_ACTIVE) &&
                    currentItem &&
                    currentItem.permit && (
                      <div>
                        <p>
                          <strong>Оплата разрешения</strong>
                        </p>
                        <p>
                          <a
                            target="_blank"
                            href={
                              currentItem && currentItem.permit && currentItem.permit.invoice_link
                            }
                          >
                            Счет на оплату
                          </a>
                        </p>
                      </div>
                    )}
                  {currentItem &&
                    (currentItem.status === applicationConstants.STATUS_ACCEPT ||
                      currentItem.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES) &&
                    currentItem.permit && (
                      <div>
                        <p>Добавить документ об оплате (до 5 Мб):</p>
                        <FileUploader
                          className=""
                          entity="pay"
                          inputClassName="file_input"
                          buttonText="+ Добавить файл"
                          fileList={get(currentItem, 'files.pay_files', [])}
                          handleFileChange={handleFileUpload}
                          handleFileRemove={handleFileRemove}
                          hasError={userApplications.uploadPayError}
                          uploaders={userApplications.payUploaders}
                          multiple
                        />
                      </div>
                    )}
                  <div>
                    {currentItem.status === applicationConstants.STATUS_DECLINE ? (
                      <div>
                        {/* <div><strong>Причина отклонения: </strong>{currentItem.comment}</div>, */}
                        <p>
                          <strong>Дополнительная информация</strong>
                        </p>
                        <Field
                          className="form-control"
                          name="admin_name"
                          label="Сотрудник, отклонивший заявку"
                          disabled="disabled"
                          component={FormField}
                          validate={[fieldValidators.required]}
                        />
                        <br />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  {/* Отметки о проездах */}
                  {currentItem &&
                    currentItem.control_marks &&
                    currentItem.control_marks.map((item, index) => {
                      return [<ControlMarkCard controlMark={item} index={index} />, <br />];
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { userApplications, authentication, specialConditions } = state;
  const { currentItem } = userApplications;

  let loadName = '';
  if (currentItem && currentItem.load) {
    loadName =
      currentItem.load.name && currentItem.load.name != null ? `${currentItem.load.name}, ` : '';
    loadName += dictionaryHelper.loadTypes.getItemTitle(currentItem.load.type_id);
    if (currentItem.load.load_weight && currentItem.load.load_weight != null) {
      loadName += `, ${currentItem.load.load_weight}т`;
    }
  }

  const initialValues = {
    id: currentItem ? currentItem.id : 0,
    vehicle_info:
      currentItem && currentItem.vehicle
        ? `${currentItem.vehicle.brandTitle}, ${currentItem.vehicle.modelTitle}, ${
            currentItem.vehicle.real_number
          }`
        : '',

    year: currentItem ? momentHelper.getYearFromDotFormat(currentItem.finish_date) : '',
    runs_count:
      currentItem && currentItem.dates
        ? `${currentItem.dates.runs_count - currentItem.dates.runs_used}/${
            currentItem.dates.runs_count
          }`
        : '',
    start_date: currentItem
      ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.start_date)
      : '',
    finish_date: currentItem
      ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.finish_date)
      : '',

    load_info: currentItem && currentItem.load ? loadName : '',

    user_name: currentItem && currentItem.user ? currentItem.user.name : '',
    user_address:
      currentItem && currentItem.user && currentItem.user.firm ? currentItem.user.firm.address : '',
    user_phone: currentItem && currentItem.user ? currentItem.user.phone : '',

    //
    price: currentItem ? currentItem.real_price : 0,
    admin_name: currentItem && currentItem.admin ? currentItem.admin.name : '',
    accept_date: currentItem ? momentHelper.changeYmdHisTodmY(currentItem.accept_date) : '',
    comment: currentItem ? currentItem.comment : '',

    //
    weight: currentItem && currentItem.load ? `${currentItem.load.weight} т` : '',

    length: currentItem && currentItem.load ? `${currentItem.load.length} м` : '',
    width: currentItem && currentItem.load ? `${currentItem.load.width} м` : '',
    height: currentItem && currentItem.load ? `${currentItem.load.height} м` : '',

    trailers_info: [],
    // privilege

    privilege_title:
      currentItem && currentItem.privilegeStatus ? currentItem.privilegeStatus.title : '',
    privilege_start_date:
      currentItem && currentItem.privilegeStatus
        ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.privilegeStatus.start_date)
        : '',
    privilege_finish_date:
      currentItem && currentItem.privilegeStatus
        ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.privilegeStatus.finish_date)
        : '',
    pay_files: get(currentItem, 'files.pay_files', []),
    is_penalty: currentItem ? get(currentItem, 'dates.is_penalty') : '',
    penalty_post: currentItem ? get(currentItem, 'dates.penalty_post.title') : '',
    penalty_number: currentItem ? get(currentItem, 'dates.penalty_number') : '',
    penalty_place: currentItem ? get(currentItem, 'dates.penalty_place') : '',
    route_info:
      currentItem && currentItem.privilegeStatus ? currentItem.privilegeStatus.route_info : '',
    smev: currentItem ? currentItem.smev : null,
    is_smev: currentItem ? currentItem.is_smev : false
  };

  // trailers
  if (currentItem && currentItem.trailers) {
    for (let i = 0; i < currentItem.trailers.length; i++) {
      initialValues.trailers_info[i] = `${currentItem.trailers[i].brandTitle}, ${
        currentItem.trailers[i].modelTitle
      }, ${currentItem.trailers[i].real_number}`;
    }
  }

  // axles lengths
  if (currentItem && currentItem.load && currentItem.load.axles_info) {
    const axlesInfo = JSON.parse(currentItem.load.axles_info);
    let axles_info = '';
    for (let i = 0; i < axlesInfo.length - 1; i++) {
      axles_info += `${axlesInfo[i].distance}м x `;
    }
    initialValues.axles_lengths = axles_info.substring(0, axles_info.length - 3);
  }
  // axles loads
  if (currentItem && currentItem.load && currentItem.load.axles_info) {
    const axlesInfo = JSON.parse(currentItem.load.axles_info);
    const isLoadArray = isArray(axlesInfo[0].permissible_load);
    const trim = load => load.substring(0, load.length - 3);
    let axles_info = '';
    let permissible_load = '';
    let permissible_load_spring = '';
    const wheelsCount = axlesInfo.map(a => a.wheel_count).join(' x ');
    const wheels = axlesInfo.map(a => a.wheels).join(' x ');
    for (let i = 0; i < axlesInfo.length; i++) {
      axles_info += `${axlesInfo[i].axle_load}т x `;
      permissible_load += `${
        isLoadArray ? axlesInfo[i].permissible_load[0] : axlesInfo[i].permissible_load
      }т x `;
      if (axlesInfo[i].permissible_load_spring) {
        permissible_load_spring += `${
          isLoadArray
            ? axlesInfo[i].permissible_load_spring[0]
            : axlesInfo[i].permissible_load_spring
        }т x `;
      }
    }
    initialValues.axles_load = axles_info.substring(0, axles_info.length - 3);
    initialValues.permissible_load = permissible_load.substring(0, permissible_load.length - 3);
    initialValues.wheelsCount = wheelsCount;
    initialValues.wheels = wheels;
    if (permissible_load_spring) {
      initialValues.permissible_load_spring = permissible_load_spring.substring(
        0,
        permissible_load_spring.length - 3
      );
    } else {
      permissible_load_spring = permissible_load;
    }
  }

  // markers
  let markers = [];
  if (currentItem && currentItem.route) {
    markers = JSON.parse(currentItem.route.points);
  } else {
    markers = [];
  }

  initialValues.route = [];
  for (let i = 0; i < markers.length; i++) {
    initialValues.route[i] = markers[i].text;
  }

  let escortVehicles = [];
  if (currentItem && currentItem.load && currentItem.load.escort) {
    escortVehicles = currentItem.load.escort;
    initialValues.escort = escortVehicles;
  }

  return {
    specialConditions,
    authentication,
    userApplications,
    initialValues
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { match } = ownProps;
  const { dispatch } = dispatchProps;

  const handleToAdmin = id => {
    dispatch(applicationActions.toAdmin(id));
  };
  const handleToDraft = id => {
    dispatch(applicationActions.withdraw(id));
  };

  const handleFileUpload = createFileUploadHandler(
    dispatch,
    fileConstants.TYPE_APPLICATION_PAY,
    (fileInfo, uploaderId) =>
      applicationActions.uploadPayFile(match.params.id, fileInfo, uploaderId),
    { type: applicationConstants.PAY_UPLOAD_FAILURE }
  );

  const handleFileRemove = id => {
    dispatch(applicationActions.removePayFile(id));
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    handleToAdmin,
    handleToDraft,
    handleFileUpload,
    handleFileRemove
  };
};

ApplicationViewPage = reduxForm({
  form: 'application-view-form',
  enableReinitialize: true
})(ApplicationViewPage);

const connectedApplicationViewPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationViewPage);
export { connectedApplicationViewPage as ApplicationViewPage };
