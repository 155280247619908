import { authConstants } from '../constants/authConstants';
import { history } from '../helpers/history';
import { authService } from '../services/authService';
import { codeActions } from './codeActions';
import { urlHelper } from '../helpers/urlHelper';
import { authHelper } from '../helpers/authHelper';

const togglePasswordVisible = () => {
  return { type: authConstants.PASSWORD_VISIBLE_TOGGLE };
};

const login = values => {
  const request = user => {
    return { type: authConstants.LOGIN_REQUEST, user };
  };
  const success = user => {
    return { type: authConstants.LOGIN_SUCCESS, user };
  };
  return dispatch => {
    dispatch(request({ values }));

    return authService.login(values).then(response => {
      const user = response.data;
      dispatch(success(user));
      authHelper.storeUser(user);
      history.push(urlHelper.getInitUrl(user.role_id));

      return user;
    });
  };
};

const logout = () => {
  authService.logout();
  return { type: authConstants.LOGOUT };
};

const setConfirmationMessage = message => {
  return { type: authConstants.SET_MESSAGE, message };
};

const register = user => {
  const request = user => {
    return { type: authConstants.REGISTER_REQUEST, user };
  };

  const success = user => {
    return { type: authConstants.REGISTER_SUCCESS, user };
  };
  const loginSuccess = user => {
    return { type: authConstants.LOGIN_SUCCESS, user };
  };

  return dispatch => {
    dispatch(request(user));

    return authService.register(user).then(response => {
      const user = response.data;

      // handle registration complete
      dispatch(success(user));
      // handle login as new registered HeaderMenu
      dispatch(loginSuccess(user));
      // set message, that you need to confirm password
      dispatch(
        setConfirmationMessage(
          'Вы успешно зарегистрировались в системе. На ваш адрес электронной почты отправлено сообщение для подтверждения регистрации.'
        )
      );

      authHelper.storeUser(user);
      history.push(urlHelper.getInitUrl(user.role_id));

      return user;
    });
  };
};

const openRestoreModal = () => {
  return { type: authConstants.PASSWORD_RESTORE_OPEN };
};
const closeRestoreModal = () => {
  return { type: authConstants.PASSWORD_RESTORE_CLOSE };
};
const toggleRestoreModal = () => {
  return { type: authConstants.PASSWORD_RESTORE_TOGGLE };
};
const toggleNewModal = token => {
  return { type: authConstants.PASSWORD_NEW_TOGGLE, token };
};

const openRegisterStep1Modal = () => {
  return { type: authConstants.REGISTER_STEP1_OPEN };
};
const closeRegisterStep1Modal = () => {
  return { type: authConstants.REGISTER_STEP1_CLOSE };
};

const openRegisterStep2Modal = () => {
  return { type: authConstants.REGISTER_STEP2_OPEN };
};
const closeRegisterStep2Modal = () => {
  return { type: authConstants.REGISTER_STEP2_CLOSE };
};

const confirmRestore = (values, dispatch) => {
  const request = data => {
    return { type: authConstants.PASSWORD_CODE_REQUEST, data };
  };
  const success = data => {
    return { type: authConstants.PASSWORD_CODE_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request({ values }));

    return authService.confirmRestore(values).then(response => {
      const { data } = response;
      if (data && data.token) {
        dispatch(success(data));
      }

      return data;
    });
  };
};

const restorePassword = (values, dispatch) => {
  const request = data => {
    return { type: authConstants.PASSWORD_REQUEST_REQUEST, data };
  };
  const success = data => {
    return { type: authConstants.PASSWORD_REQUEST_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request({ values }));

    return authService.requestRestore(values).then(response => {
      const { data } = response;
      if (data && data.success) {
        dispatch(success(data));
        dispatch(codeActions.refreshSuccess());
      }

      return data;
    });
  };
};

const changePassword = (values, dispatch) => {
  const request = data => {
    return { type: authConstants.PASSWORD_CHANGE_REQUEST, data };
  };
  const success = data => {
    return { type: authConstants.PASSWORD_CHANGE_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request({ values }));

    return authService.changePassword(values).then(response => {
      const { data } = response;
      if (data && data.success) {
        dispatch(success(data));
      }

      return data;
    });
  };
};

const confirmEmail = (confirmation_code, dispatch) => {
  const values = { confirmation_code };
  const request = data => {
    return { type: authConstants.EMAIL_CONFIRMATION_REQUEST, data };
  };
  const success = user => {
    return { type: authConstants.EMAIL_CONFIRMATION_SUCCESS, user };
  };

  return dispatch => {
    dispatch(request({ values }));

    return authService
      .emailConfirmation(values)
      .then(response => {
        if (response.data.id) {
          const user = response.data;
          dispatch(success(user));
          dispatch(setConfirmationMessage(''));
          authHelper.storeUser(user);
          history.push(urlHelper.getInitUrl(user.role_id));

          return user;
        }

        return null;
      })
      .catch(e => {});
  };
};

export const authActions = {
  togglePasswordVisible,
  login,
  logout,

  register,

  openRestoreModal,
  closeRestoreModal,
  toggleRestoreModal,

  toggleNewModal,

  openRegisterStep1Modal,
  closeRegisterStep1Modal,

  openRegisterStep2Modal,
  closeRegisterStep2Modal,

  restorePassword,
  confirmRestore,
  changePassword,

  confirmEmail,
  setConfirmationMessage
};
