import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { formValueSelector, reduxForm } from 'redux-form';
import Table from '@material-ui/core/Table';
import { applicationActions } from '../../../actions/applicationActions';
import { Loader } from '../../../components/Loader';
import { ApplicationCard } from './ApplicationCard';
import TablePagination from '../../../components/TablePagination';

const TabContainer = props => {
  return <Typography component="div">{props.children}</Typography>;
};

let ApplicationsTab = props => {
  const { userApplications, handlePageChange, handlePerPageChange } = props;
  const { meta } = userApplications;

  return (
    <Paper className="border paper text-center">
      <TabContainer>
        {userApplications.loading && <Loader />}
        {!userApplications.loading && userApplications.items.length === 0 && (
          <p>Заявки не найдены</p>
        )}
        {userApplications.items &&
          userApplications.items.map(item => (
            <div key={item.id} className="app_card">
              <ApplicationCard application={item} />
            </div>
          ))}
        <Table>
          <TablePagination
            meta={meta}
            options={[5, 10, 20, 50]}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handlePerPageChange}
          />
        </Table>
      </TabContainer>
    </Paper>
  );
};
ApplicationsTab = reduxForm({
  form: 'application-page-count-form',
  enableReinitialize: false,
  destroyOnUnmount: false
})(ApplicationsTab);

const mapStateToProps = state => {
  const { userApplications } = state;

  const selector = formValueSelector('application-filter-form');
  const filterData = selector(state, 'start_date', 'finish_date', 'id', 'number');

  return {
    userApplications,
    filterData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { userApplications, filterData } = stateProps;
  const { tabIndex } = userApplications;
  const { dispatch } = dispatchProps;

  const handlePageChange = page => {
    const values = filterData;
    values.page = page;
    dispatch(applicationActions.getUserFiltered(tabIndex, values, page));
  };

  const handlePerPageChange = perPage => {
    const values = filterData;
    values.page = 1;
    values.per_page = perPage;
    dispatch(applicationActions.getUserFiltered(tabIndex, values, values.page));
  };

  return {
    ...ownProps,
    userApplications,

    dispatch,
    handlePageChange,
    handlePerPageChange
  };
};

const connectedApplicationsTab = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationsTab);
export { connectedApplicationsTab as ApplicationsTab };
