import React from 'react';
import { AdminRoute } from '../../../components/routes/AdminRoute';
import { RegAppsListPage } from './RegAppsListPage';
import RegAppsFirmPage from './RegAppsFirmPage';

const RegAppsLayout = ({ match }) => (
  <div>
    <AdminRoute exact path={`${match.url}/`} component={RegAppsListPage} />
    <AdminRoute path={`${match.url}/firm/:id`} component={RegAppsFirmPage} />
  </div>
);

export default RegAppsLayout;
