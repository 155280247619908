import React from 'react';
import Wait from '@material-ui/icons/Schedule';
import Delete from '@material-ui/icons/HighlightOff';
import Check from '@material-ui/icons/CheckCircle';
import PanoramaFishEye from '@material-ui/icons/PanoramaFishEye';
import ReportProblem from '@material-ui/icons/ReportProblem';
import { applicationHelper } from './applicationHelper';
import { applicationConstants } from '../constants/applicationConstants';
import { dictionaryHelper } from './dictionaryHelper';
import { roleConstants } from '../constants/roleConstants';

const getStatusText = (role_id, application) => {
  // user statuses
  if (application.is_locked) {
    return 'Заблокировано';
  }
  if (
    role_id === roleConstants.ROLE_INDIVIDUAL ||
    role_id === roleConstants.ROLE_FIRM ||
    role_id === roleConstants.ROLE_FIRMUSER
  ) {
    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      !applicationHelper.checkDatesValid(application)
    ) {
      return 'Просрочено';
    }
    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      !applicationHelper.checkRunsExist(application)
    ) {
      return 'Истрачено количество поездок';
    }
    if (
      (application.status === applicationConstants.STATUS_NEW && !application.is_draft) ||
      application.status === applicationConstants.STATUS_REVIEW
    ) {
      return 'Ожидает подтверждения';
    }
    return dictionaryHelper.applicationStatuses.getItemTitle(application.status);
  }

  // admin statuses
  if (
    role_id === roleConstants.ROLE_ADMIN ||
    role_id === roleConstants.ROLE_OFFICER ||
    role_id === roleConstants.ROLE_DEPARTMENT_AGENT ||
    role_id === roleConstants.ROLE_APVGK ||
    role_id === roleConstants.ROLE_WEIGHT_CONTROL
  ) {
    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      !applicationHelper.checkDatesValid(application)
    ) {
      return 'Просрочено';
    }
    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      !applicationHelper.checkRunsExist(application)
    ) {
      return 'Истрачено количество поездок';
    }
    return dictionaryHelper.applicationStatuses.getItemTitle(application.status);
  }

  return dictionaryHelper.applicationStatuses.getItemTitle(application.status);
};

const getStatusIcon = (role_id, application) => {
  if (application.is_locked) {
    return <ReportProblem className="round_icon red" />;
  }

  // user statuses
  if (
    role_id === roleConstants.ROLE_INDIVIDUAL ||
    role_id === roleConstants.ROLE_FIRM ||
    role_id === roleConstants.ROLE_FIRMUSER
  ) {
    // Новое
    if (application.status === applicationConstants.STATUS_NEW && application.is_draft) {
      return <PanoramaFishEye className="round_icon blue" />;
    }

    // Ожидание
    if (
      application.status === applicationConstants.STATUS_REVIEW ||
      application.status === applicationConstants.STATUS_REPEAT ||
      (applicationConstants.STATUS_NEW === application.status && !application.is_draft)
    ) {
      return <Wait className="round_icon yellow" />;
    }

    // Отклонено
    if (application.status === applicationConstants.STATUS_DECLINE) {
      return <Delete className="round_icon red" />;
    }

    // Принято
    if (
      application.status === applicationConstants.STATUS_ACCEPT ||
      application.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES
    ) {
      return <PanoramaFishEye className="round_icon green" />;
    }

    // Активно
    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      applicationHelper.checkDatesValid(application) &&
      applicationHelper.checkRunsExist(application)
    ) {
      return <Check className="round_icon green" />;
    }

    // Просрочено
    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      !applicationHelper.checkDatesValid(application)
    ) {
      return <ReportProblem className="round_icon red" />;
    }

    // Потрачено
    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      !applicationHelper.checkRunsExist(application)
    ) {
      return <ReportProblem className="round_icon red" />;
    }
  }

  // admin statuses
  if (
    role_id === roleConstants.ROLE_ADMIN ||
    role_id === roleConstants.ROLE_OFFICER ||
    role_id === roleConstants.ROLE_DEPARTMENT_AGENT ||
    role_id === roleConstants.ROLE_WEIGHT_CONTROL
  ) {
    if (application.status === applicationConstants.STATUS_NEW) {
      return <PanoramaFishEye className="round_icon blue" />;
    }

    if (
      application.status === applicationConstants.STATUS_REVIEW ||
      application.status === applicationConstants.STATUS_REPEAT
    ) {
      return <Wait className="round_icon yellow" />;
    }

    if (application.status === applicationConstants.STATUS_DECLINE) {
      return <Delete className="round_icon red" />;
    }

    if (application.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES) {
      return <Check className="round_icon yellow" />;
    }

    if (application.status === applicationConstants.STATUS_ACCEPT) {
      return <PanoramaFishEye className="round_icon green" />;
    }

    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      applicationHelper.checkDatesValid(application) &&
      applicationHelper.checkRunsExist(application)
    ) {
      return <Check className="round_icon green" />;
    }

    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      !applicationHelper.checkDatesValid(application)
    ) {
      return <ReportProblem className="round_icon red" />;
    }

    // Потрачено
    if (
      application.status === applicationConstants.STATUS_ACTIVE &&
      !applicationHelper.checkRunsExist(application)
    ) {
      return <ReportProblem className="round_icon red" />;
    }
  }
};

export const statusHelper = {
  getStatusText,
  getStatusIcon
};
