import * as yup from 'yup';
import moment from 'moment';
import { Types } from './types';
import { getTotalMaxWeight, getTotalWeight } from './utils';

yup.addMethod(yup.boolean, 'vehicles', function create(args) {
  return this.test('vehicles', args, function validate() {
    if (!this.parent.route.status_vehicles || !this.parent.transport) {
      return false;
    }
    const {
      route: { status_vehicles },
      transport,
      trailers
    } = this.parent;

    const checkTransport = t =>
      !!status_vehicles.find(v => v.real_number === `${t.number} ${t.region}`);

    return checkTransport(transport) && trailers.every(checkTransport);
  });
});

yup.addMethod(yup.date, 'isValid', function create(args) {
  return this.test('isValid', args, function validate(value) {
    return moment(value).isValid();
  });
});

const TransportSchema = {
  brand: yup
    .string()
    .max(100, 'Не может превышать 100 символов')
    .required('Обязательное поле'),
  model: yup
    .string()
    .max(100, 'Не может превышать 100 символов')
    .required('Обязательное поле'),
  number: yup
    .string()
    .required('Обязательное поле')
    .max(10, 'буквы верхнего регистра и цифры (до 10 символов)'),
  region: yup
    .number()
    .typeError('Обязательное поле')
    .required('Обязательное поле')
    .min(10, 'от 2 до 3 цифр')
    .max(999, 'от 2 до 3 цифр'),
  axlesCount: yup
    .number()
    .typeError('Должно быть числом')
    .min(1, '>0')
    .required('Обязательное поле'),
  initialWeight: yup
    .number()
    .typeError('Должно быть числом')
    .min(1, '>0')
    .required('Обязательное поле'),
  maxWeight: yup
    .number()
    .typeError('Должно быть числом')
    .min(1, '>0')
    .required('Обязательное поле'),
  axles: yup.array().of(
    yup.object().shape({
      distance: yup
        .number()
        .test('axles-distance', 'Обязательное поле', function(value) {
          const values = this.options.context.head;
          if (!values) {
            return true;
          }
          const { transport, trailers } = values;
          const lastTrailerIndex = trailers.length - 1;
          const lastTrailer = trailers.length > 0 ? trailers[lastTrailerIndex] : null;
          if (
            !lastTrailer &&
            this.path === `transport.axles[${transport.axles.length - 1}].distance`
          ) {
            return true;
          }
          if (
            lastTrailer &&
            lastTrailer.axles &&
            this.path ===
              `trailers[${lastTrailerIndex}].axles[${lastTrailer.axles.length - 1}].distance`
          ) {
            return true;
          }
          return !!value;
        })
        .typeError('Должно быть числом'),
      suspension: yup
        .number()
        .typeError('Должно быть числом')
        .required('Обязательное поле'),
      sloppiness: yup
        .number()
        .typeError('Должно быть числом')
        .required('Обязательное поле'),
      wheelsCount: yup
        .number()
        .typeError('Должно быть числом')
        .required('Обязательное поле'),
      load: yup
        .number()
        .typeError('Должно быть числом')
        .required('Обязательное поле'),
      // permissibleLoad: yup.number().required('Обязательное поле'),
      // permissibleLoadSpring: yup.number().required('Обязательное поле')
    })
  )
};

export const Schema = yup.object().shape({
  type: yup.number().required('Обязательное поле'),
  name: yup
    .string()
    .max(200, 'Не может превышать 200 символов')
    .required('Обязательное поле'),
  address: yup.string().max(200, 'Не может превышать 200 символов'),
  inn: yup.string().when('type', {
    is: val => val === Types.Individual,
    then: yup.string().test('length', '12 цифр', function(value) {
      return !value || value.length === 12;
    }),
    otherwise: yup
      .string()
      .typeError('Должно быть числом')
      .test('length', '10 цифр', function(value) {
        return !value || value.length === 10;
      })
  }),
  tripType: yup.number().required('Обязательное поле'),
  restrictionDates: yup.object().shape({
    start: yup
      .date()
      .typeError('Укажите дату начала')
      .required('Укажите дату начала')
      .isValid('Укажите дату начала')
      .min(
        moment()
          .startOf('d')
          .toDate(),
        'Дата начала не может быть ранее сегодня'
      )
      .max(
        moment()
          .add(3, 'M')
          .toDate(),
        'Не может быть позднее 3 месяцев'
      )
      .isValid('Укажите дату окончания'),
    end: yup
      .date()
      .typeError('Укажите дату окончания')
      .required('Укажите дату окончания')
      .when('start', (start, schema) => {
        return schema
          .typeError('Укажите дату окончания')
          .required('Укажите дату окончания')
          .min(
            moment(start)
              .endOf('d')
              .toDate(),
            'Должно быть позже начала'
          )
          .max(
            moment(start)
              .add(3, 'M')
              .toDate(),
            'Максимальный срок действия разрешения - 3 месяца'
          )
          .isValid('Укажите дату окончания');
      })
  }),
  tripCount: yup.number().min(1, 'Должно быть больше 0'),
  loadType: yup.number().required('Обязательное поле'),
  loadName: yup.string().max(150, 'Не может превышать 150 символов'),
  transport: yup.object().shape(TransportSchema),
  trailers: yup.array().of(yup.object().shape(TransportSchema)),
  dimensions: yup.object().shape({
    length: yup
      .number()
      .typeError('Должно быть числом')
      .required('Длина - Обязательное поле')
      .min(1, 'Длина должна быть >0'),
    width: yup
      .number()
      .typeError('Должно быть числом')
      .required('Ширина - Обязательное поле')
      .min(1, 'Ширина должна быть >0'),
    height: yup
      .number()
      .typeError('Должно быть числом')
      .required('Высота - Обязательное поле')
      .min(1, 'Высота должна быть >0')
  }),
  route: yup.object().shape({
    status: yup.string().test('status', 'Обязательное поле', function(value) {
      const values = this.options.context.head;
      if (!values) {
        return true;
      }
      const { route } = values;
      if (!route.useStatus) {
        return true;
      }
      return !!value;
    }),
    status_vehicles: yup.array().when('route.useStatus', {
      is: true,
      then: yup.array()
    }),
    steps: yup.array(
      yup.object().shape({
        text: yup.string().test('coors', 'Обязательное поле', function(value) {
          const values = this.options.context.head;
          if (!values) {
            return true;
          }
          const { route } = values;
          if (route.useStatus) {
            return true;
          }
          return value && value.length > 0;
        })
      })
    )
  }),
  vehicleCorrect: yup.boolean().when(['route.useStatus', 'route.status', 'route.status_vehicles'], {
    is: (useStatus, status, status_vehicles) => useStatus && status && status_vehicles,
    then: yup.boolean().vehicles('Невозможно использовать статус с выбранными ТС')
  }),
  totalWeight: yup
    .number()
    .test(
      'total-weight',
      'Общая масса не может превышать максимальную разрешенную массу автопоезда',
      function totalWeightCheck() {
        if (!this.parent.transport) {
          return false;
        }
        const { transport, trailers, isPenalty } = this.parent;
        const totalWeight = getTotalWeight(transport, trailers);
        const maxTotalWeight = getTotalMaxWeight(transport, trailers);

        return maxTotalWeight >= totalWeight || isPenalty;
      }
    ),
  routeType: yup.string().required('Обязательное поле'),
  penalty: yup.object().when('isPenalty', {
    is: true,
    then: yup.object().shape({
      controlPost: yup.number().required('Обязательное поле'),
      actNumber: yup
        .number()
        .typeError('Должно быть числом')
        .required('Обязательное поле')
        .max(10 ** 9, 'Не более 10 цифр'),
      parking: yup
        .string()
        .required('Обязательное поле')
        .max(150, 'Не более 150 симполов')
    })
  }),
  issuePlaces: yup.string().required('Обязательное поле')
});
