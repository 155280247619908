import React, { Component } from 'react';
import { connect } from 'react-redux';
import { axleLoadActions } from '../../../../actions/axleLoadActions';
import { AxleLoadTableList } from './AxleLoadTableList';
import { Loader } from '../../../../components/Loader';
import { AxleLoadModal } from './AxleLoadModal';

class AxleLoadBlock extends Component {
  componentDidMount() {
    const { axleLoads, specialConditions } = this.props;
    const { tabIndex } = specialConditions;

    if (!axleLoads.items || axleLoads.items.length === 0) {
      this.props.dispatch(axleLoadActions.getPage(1, tabIndex));
    }
  }

  render() {
    const { axleLoads } = this.props;

    return (
      <div>
        <main role="main" className="container text-left">
          <p>Нагрузки на оси</p>
          <div className="">
            {axleLoads.loading && <Loader />}
            {axleLoads.error && <span className="text-danger">Ошибка: {axleLoads.error}</span>}
            {axleLoads.items && <AxleLoadTableList data={axleLoads.items} meta={axleLoads.meta} />}
          </div>
          <AxleLoadModal />
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { axleLoads, specialConditions } = state;
  return {
    axleLoads,
    specialConditions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const connectedAxleLoadBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(AxleLoadBlock);
export { connectedAxleLoadBlock as AxleLoadBlock };
