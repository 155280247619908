import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { MuiTextField } from '../../../components/form/MuiTextField';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components/Loader';
import MuiPasswordField from '../../../components/form/MuiPasswordField';

let LoginForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        type="text"
        label="Email"
        className="input-style"
        validate={[fieldValidators.required, fieldValidators.email]}
        component={MuiTextField}
        icon="login"
      />
      <br />

      <Field
        name="password"
        label="Пароль"
        className="input-style"
        validate={[
          fieldValidators.required,
          fieldValidators.minLength8,
          fieldValidators.maxLength40
        ]}
        component={MuiPasswordField}
        icon="password"
        showPasswordVisible="true"
      />
      <br />
      <br />
      <div className="row">
        <div className="col-md-6">
          <Field
            name="remember"
            type="checkbox"
            label="Запомнить меня"
            className="checklogin"
            component={MuiCheckbox}
          />
        </div>
        <div className="col-md-6 text-right">
          <Button
            variant="contained"
            color="primary"
            className="login"
            type="submit"
            disabled={submitting}
          >
            {submitting && <Loader />} Войти
          </Button>
        </div>
      </div>
    </form>
  );
};

LoginForm = reduxForm({
  form: 'login'
})(LoginForm);

const mapStateToProps = state => {
  const { showLoginPassword } = state.authentication;

  return {
    showLoginPassword
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};

LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);

export default LoginForm;
