import React from 'react';

import { WeightControlRoute } from '../../../components/routes/WeightControlRoute';
import { ApplicationsListPage } from './ApplicationsListPage';
import { ApplicationViewPage } from '../../admin/applications/ApplicationViewPage';
import NewApplicationViewPage from '../../admin/applications_new/ApplicationViewPageContainer';
import {ApplicationsRDMPage} from "./ApplicationsRDMPage";

const ApplicationsLayout = ({ match }) => (
  <div>
    <WeightControlRoute exact path={`${match.url}/`} component={ApplicationsListPage} />
    <WeightControlRoute exact path={`${match.url}/rdm`} component={ApplicationsRDMPage} />
    <WeightControlRoute exact path={`${match.url}/view/:id`} component={ApplicationViewPage} />
    <WeightControlRoute
      exact
      path={`${match.url}/view_new/:id`}
      component={NewApplicationViewPage}
    />
  </div>
);

export default ApplicationsLayout;
