import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Loader } from '../../../components';
import { getApplicationId, getIsShowDeclineModal } from '../../../selectors/applicationSelectors';
import * as actions from '../../../actions/newApplicationActions';
import { applicationService } from '../../../services/applicationService';

const DeclineModal = ({
  setShowDeclineModal,
  showDeclineModal,
  enqueueSnackbar,
  updateApplication,
  applicationId
}) => {
  const onClose = () => setShowDeclineModal(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [value, setValue] = React.useState('');
  const onDecline = React.useCallback(async () => {
    setSubmitting(true);
    try {
      const response = await applicationService.decline({ id: applicationId, note: value });
      setSubmitting(false);
      enqueueSnackbar('Разрешение отклонено!', { variant: 'success' });
      onClose();
      updateApplication(response.data);
    } catch (e) {
      setSubmitting(false);
      enqueueSnackbar('Ошибка при отклонении заявления!', { variant: 'error' });
    }
  }, [value]);
  return (
    <Dialog open={showDeclineModal} onClose={onClose} aria-labelledby="form-dialog-title">
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Отклонение заявки
        </Typography>
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <textarea
          disabled={submitting}
          value={value}
          onChange={e => setValue(e.target.value)}
          className="form-control"
          placeholder="Замечания"
          rows="4"
        />
        <br />
        <div>
          {submitting && <Loader />}
          <button
            onClick={onDecline}
            name="action"
            value="decline"
            className="btn"
            type="submit"
            disabled={submitting || !value}
          >
            Отклонить
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  showDeclineModal: getIsShowDeclineModal(state),
  applicationId: getApplicationId(state)
});

const mapDispatchToProps = {
  setShowDeclineModal: actions.setShowDeclineModal,
  updateApplication: actions.updateApplication
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DeclineModal));
