import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { controlPostActions } from '../../../../actions/controlPostActions';
import ControlPostsForm from './ControlPostForm';

const ControlPostModal = props => {
  const { controlPosts, handleSubmit, handleModalClose } = props;

  return (
    <Dialog
      open={controlPosts.showFormModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          {controlPosts.currentItem
            ? 'Редактирование поста весового контроля'
            : 'Создание поста весового контроля'}
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-left">
        <ControlPostsForm currentItem={controlPosts.currentItem} onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { controlPosts } = state;

  return {
    controlPosts
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { controlPosts } = stateProps;
  const { dispatch } = dispatchProps;

  const handleSubmit = (values, dispatch) => {
    if (controlPosts.currentItem && controlPosts.currentItem.id) {
      return dispatch(controlPostActions.update(values));
    }
    return dispatch(controlPostActions.create(values));
  };

  const handleModalClose = () => {
    dispatch(controlPostActions.closeFormModal());
  };

  return {
    ...ownProps,
    controlPosts,
    handleSubmit,
    handleModalClose
  };
};

const connectedControlPostModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ControlPostModal);

export { connectedControlPostModal as ControlPostModal };
