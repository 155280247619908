import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'control-posts';
};

const getPage = page => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/page?page=${page}`,
    {},
    true
  );
};

const getAll = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}`, {}, true);
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};
const connectApvgk = values => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/${values.control_post_id}/apvgk/${values.apvgk_id}/connect`,
    values,
    true
  );
};
const connectApvgkList = post_id => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/${post_id}/apvgk/link-list`,
      {},
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};

export const controlPostService = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  connectApvgk,
  connectApvgkList,
  delete: _delete
};
