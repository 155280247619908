import qs from 'qs';
import React, { useEffect, useRef } from 'react';
import { isNil } from 'lodash';
import LocationHolder from './locationHolder';

function updateQuery(query) {
  if (window.history.pushState) {
    const newURL = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${query}`;
    window.history.pushState({ path: newURL }, '', newURL);
  }
}

export const useWithQuery = (key, defaultValue, parse = v => v) => {
  const params = qs.parse(window.location.search.slice(1));
  const path = window.location.pathname.split('/').pop();
  const initialValue = isNil(params[key])
    ? LocationHolder.get(path, key) || defaultValue
    : parse(params[key]);
  const [state, setState] = React.useState(initialValue);
  useEffect(() => {
    LocationHolder.set(path, key, state);
    updateQuery(
      qs.stringify({
        ...qs.parse(window.location.search.slice(1)),
        [key]: state
      })
    );
  }, [state]);
  return [state, setState];
};

export function useDidUpdateEffect(fn, deps) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) fn();
    else didMountRef.current = true;
  }, deps);
}
