import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { renderSelectFieldReg } from '../../../components/form/renderSelectFieldReg';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { vehicleActions } from '../../../actions/vehicleActions';

const UserForm = props => {
  const { handleRemove, item, handleIsExistingUserChange, cabinetVehicles } = props;

  return (
    <Card style={{ overflow: 'visible' }}>
      <CardContent>
        <div className="text-right close_user">
          <IconButton color="inherit">
            <CloseIcon onClick={e => handleRemove(item.id, item.list_id)} className="blue" />
          </IconButton>
        </div>
        <div className="text-left checkbox-div">
          <Field
            name={`userform[${item.list_id}][is_existing_user]`}
            type="checkbox"
            label="Выбрать существующего сотрудника"
            className="checkbox"
            component={MuiCheckbox}
            onChange={(e, value) => handleIsExistingUserChange(item.id, value)}
          />
          {item.is_existing_user && (
            <Field
              name={`userform[${item.list_id}][existing_user_id]`}
              label="Список пользователей"
              component={renderSelectFieldReg}
              className="form-control"
              validate={[fieldValidators.required]}
            >
              {cabinetVehicles.firmUsers
                ? cabinetVehicles.firmUsers.map(user => (
                    <option key={user.id} value={user.id}>
                      {user.email}
                    </option>
                  ))
                : ''}
            </Field>
          )}
        </div>
        <hr />

        {!item.is_existing_user && (
          <div>
            <p>Добавить нового сотрудника</p>

            <Field
              name={`userform[${item.list_id}][email]`}
              placeholder="Логин"
              component={FormFieldReg}
              validate={[fieldValidators.required, fieldValidators.exceptions]}
            />
            <Field
              name={`userform[${item.list_id}][password]`}
              placeholder="Пароль"
              type="password"
              component={FormFieldReg}
              validate={[fieldValidators.required, fieldValidators.minLength8]}
            />
          </div>
        )}

        <div className="text-left">
          <Field
            name={`userform[${item.list_id}][user_edit_access]`}
            type="checkbox"
            label="Доступ к редактированию данных ТС"
            className="checkbox"
            component={MuiCheckbox}
          />
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { cabinetVehicles } = state;

  return {
    cabinetVehicles
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const handleIsExistingUserChange = (id, value) => {
    dispatch(vehicleActions.changeIsExistingNewUser(id, value));
  };

  return {
    handleIsExistingUserChange
  };
};

const connectedUserForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserForm);
export { connectedUserForm as UserForm };
