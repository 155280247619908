import { authConstants } from '../constants/authConstants';

export const restorePassword = (
  state = { showRestoreModal: false, showNewModal: false },
  action
) => {
  switch (action.type) {
    case authConstants.PASSWORD_RESTORE_TOGGLE:
      const newState = Object.assign({}, state);
      newState.showRestoreModal = !newState.showRestoreModal;
      return newState;
    case authConstants.PASSWORD_RESTORE_OPEN:
      return {
        ...state,
        showRestoreModal: true,
        restoreFinished: false,
        restoreMessage: ''
      };
    case authConstants.PASSWORD_RESTORE_CLOSE:
      return {
        ...state,
        showRestoreModal: false
      };

    case authConstants.PASSWORD_NEW_TOGGLE:
      return {
        ...state,
        showNewModal: !state.showNewModal,
        restoreToken: action.token
      };
    case authConstants.PASSWORD_REQUEST_REQUEST:
      return {
        ...state,
        restoreMessage: ''
      };
    case authConstants.PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        restoreFinished: true,
        restoreMessage: 'Ваше письмо успешно отправлено!'
      };
    case authConstants.PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        newMessage: 'Пароль успешно изменен!'
      };
    default:
      return state;
  }
};
