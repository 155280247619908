import L from 'leaflet';
import { applicationService } from '../../services/applicationService';

export class Geocoder {
  geocode(query, cb, context) {
    console.log('geocode', query, cb, context);
    const params = this.options.geocodingQueryParams;
    if (
      typeof params.proximity !== 'undefined' &&
      params.proximity.hasOwnProperty('lat') &&
      params.proximity.hasOwnProperty('lng')
    ) {
      params.proximity = `${params.proximity.lng},${params.proximity.lat}`;
    }
    // not required
  }

  suggest(query, cb, context) {
    return this.geocode(query, cb, context);
  }

  reverse(location, scale, cb, context) {
    console.log('reverse', location, scale, cb, context);
    applicationService.plainGetCoordsLocation(location.lat, location.lng).then(response => {
      console.log(response);
      const results = [];
      if (response && response.length) {
        response.forEach(feature => {
          results.push({
            name: feature.text,
            center: L.latLng(feature.coords.lat, feature.coords.lng)
          });
        });
      }
      cb.call(context, results);
    });
  }
}

function distance(point1, point2) {
  // eslint-disable-next-line no-restricted-properties
  return Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2);
}

export class Router {
  async route(waypoints, callback, context) {
    const wps = waypoints.map(wp => L.routing.waypoint(wp.latLng, wp.name, wp.options));
    const response = await applicationService.getMapRoutes(
      waypoints.map(({ latLng }) => [latLng.lat, latLng.lng])
    );
    const coordinates = [];
    const pathCoords = response.map(r => r.coords);
    pathCoords.forEach((coords, index) => {
      const c = coords;
      const prev = index > 0 ? pathCoords[index - 1] : null;
      if (
        prev &&
        distance(c[0], prev[prev.length - 1]) > distance(c[c.length - 1], prev[prev.length - 1])
      ) {
        coordinates.push(...c.reverse());
      } else {
        coordinates.push(...c);
      }
    });
    const result = [
      {
        coordinates,
        waypoints: wps,
        properties: { isSimplified: true },
        name: response.map(route => route.name).join(' - '),
        inputWaypoints: wps,
        summary: {
          totalTime: 0,
          totalDistance: 0
        },
        instructions: []
      }
    ];
    // const result = response.map(route => {
    //   const routeCoords = route.coords.map(coords => L.latLng(coords[0], coords[1]));
    //   return {
    //     properties: { isSimplified: true },
    //     name: route.name,
    //     coordinates: routeCoords,
    //     inputWaypoints: wps,
    //     summary: {
    //       totalTime: route.distance / 60,
    //       totalDistance: route.distance
    //     },
    //     instructions: []
    //   };
    // });
    console.log(result);
    callback.call(context, null, result);
  }
}
