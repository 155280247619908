import React from 'react';
import ShowByStatus from './ShowByStatus';
import { applicationConstants } from '../../../constants/applicationConstants';
import ApplicationField from './ApplicationField';
import { roleConstants } from '../../../constants/roleConstants';

const ApplicationFormId = ({ formId }) => {
  return (
    <ShowByStatus
      allowedStatus={applicationConstants.STATUS_ACTIVE}
      allowedRoles={[roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER]}
    >
      <div className="row">
        <div className="col-md-6">
          <ApplicationField label="Номер бланка" value={formId} />
        </div>
      </div>
    </ShowByStatus>
  );
};

export default ApplicationFormId;
