import React from 'react';
import { MainMenu } from '../MainMenu/MainMenu';
import { UserMenu } from '../HeaderMenu/UserMenu';

export const MainHeaderBlock = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-md bg-light">
        <div className="row">
          <div className="col-md-8">
            <MainMenu />
          </div>
          <div className="col-md-4">
            <UserMenu />
          </div>
        </div>
      </nav>
    </header>
  );
};
