import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';

import AuditPageView from './AuditPageView';
import auditService from '../../../services/auditService';
import { StateContext } from './components/StateContext';
import { checkEmpty } from './util';
import { controlPostService } from '../../../services/controlPostService';

const AuditPage = () => {
  const [filter, setFilter] = useState({
    applicationId: undefined,
    fio: undefined,
    grz: undefined,
    from: undefined,
    to: undefined,
    actionTypes: [],
    posts: [],
    page: 1,
    perPage: 20
  });

  const eventTypesState = useAsync({ promiseFn: auditService.getEventTypes });

  const controlPostsState = useAsync({ promiseFn: controlPostService.getAll });

  const auditMapping = f => ({
    fio: checkEmpty(f.fio),
    date_from: checkEmpty(f.from),
    date_to: checkEmpty(f.to),
    app_id: checkEmpty(f.app_id),
    real_number: checkEmpty(f.grz),
    event: f.actionTypes || [],
    posts: f.posts || [],
    per_page: f.perPage,
    page: f.page
  });

  const auditState = useAsync({
    deferFn: ([a]) => auditService.getAudits(a),
    ...auditMapping(filter)
  });

  const exportState = useAsync({
    deferFn: ([a]) => auditService.exportAudits(a)
  });

  const onExport = () => exportState.run(auditMapping(filter));

  const onSubmit = data => setFilter(data);

  useEffect(() => {
    auditState.run(auditMapping(filter));
  }, [filter]);

  return (
    <StateContext.Provider value={{ auditState, eventTypesState, controlPostsState }}>
      <AuditPageView
        onSubmit={onSubmit}
        onExport={onExport}
        filter={filter}
        setFilter={setFilter}
      />
    </StateContext.Provider>
  );
};

export default AuditPage;
