import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import { createPages } from './paginationHelper';
import { applicationConstants } from '../../constants/applicationConstants';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing.unit * 2.5
  },
  buttonRoot: {
    padding: theme.spacing.unit / 2,
    minWidth: 32
  },
  buttonLabel: {
    fontSize: 12
  }
});

const TablePaginationActions = ({
  classes,
  count,
  page: currentPage,
  rowsPerPage,
  onChangePage
}) => {
  const goToPage = page => event => {
    if (page !== currentPage) {
      onChangePage(event, page);
    }
  };

  const pageCount = Math.ceil(count / rowsPerPage);
  return (
    <div className={classes.root}>
      <IconButton
        onClick={goToPage(currentPage - 1)}
        disabled={currentPage === 0}
        aria-label="Предыдущая страница"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {createPages(currentPage + 1, pageCount).map(page => (
        <Button
          classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabel
          }}
          className={classes.button}
          disabled={page === '...'}
          key={page}
          onClick={goToPage(page - 1)}
          color={page - 1 === currentPage ? 'primary' : 'default'}
        >
          {page}
        </Button>
      ))}
      <IconButton
        onClick={goToPage(currentPage + 1)}
        disabled={currentPage >= pageCount - 1}
        aria-label="Следующая страница"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  );
};

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions
);

const tableStyles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

const Pagination = ({
  onChangePage,
  onChangeRowsPerPage = null,
  meta,
  classes,
  options = [5, 10, 20]
}) => (
  <TableFooter className={classes.container}>
    <TableRow>
      <TablePagination
        rowsPerPageOptions={onChangeRowsPerPage ? options : [meta.per_page]}
        count={meta.total || 0}
        rowsPerPage={meta.per_page || applicationConstants.DEFAULT_PER_PAGE}
        page={meta.current_page - 1}
        onChangePage={(e, p) => onChangePage(p + 1)}
        onChangeRowsPerPage={
          onChangeRowsPerPage ? e => onChangeRowsPerPage(e.target.value) : undefined
        }
        labelRowsPerPage=""
        ActionsComponent={TablePaginationActionsWrapped}
      />
    </TableRow>
  </TableFooter>
);

export default withStyles(tableStyles)(Pagination);
