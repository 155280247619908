import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import TablePagination from '../../../components/TablePagination';
import { userService } from '../../../services/userService';
import UserModal from './UserModal';
import VehicleAccess from './VehicleAccess';
import UsersFilter from './UsersFilter';
import { Loader } from '../../../components';

const UsersLayout = ({ enqueueSnackbar }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [filters, setFilters] = useState({});
  const [response, setResponse] = useState({});

  async function fetch(page = 1, newFilters = filters, per_page = 20) {
    try {
      setError(false);
      setLoading(true);
      const resp = await userService.getFirmUsers(page, { per_page, ...newFilters });
      setResponse(resp);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }

  const onFiltersChange = async newFilters => {
    setFilters(filters);
    fetch(1, newFilters, response.meta.per_page);
  };

  useEffect(() => {
    fetch(1);
  }, []);

  const isAppliesToFilter = user => {
    const { email, name } = filters;
    let applies = true;
    if (!!name && !user.name.toLowerCase().includes(name.toLowerCase())) {
      applies = false;
    }
    if (!!email && !user.email.toLowerCase().includes(email.toLowerCase())) {
      applies = false;
    }
    return applies;
  };

  const users = response && response.data ? response.data : [];

  const usersToShow = users;
  const meta = (response && response.meta) || { page: 1, per_page: 20 };

  const updateUsers = newUsers => {
    const filtered = newUsers.filter(isAppliesToFilter);
    setResponse({ ...response, data: filtered });
  };

  const onRemove = user => async () => {
    try {
      await userService.deleteFirmUser(user.id);
      updateUsers(users.filter(u => u.id !== user.id));
    } catch (e) {
      enqueueSnackbar('Произошла ошибка, попробуйте позже!', { variant: 'error' });
    }
  };

  const onAdd = user => {
    const newUsers = [user, ...users];
    updateUsers(newUsers);
  };

  const onUpdate = user => {
    const newUsers = users.map(u => (u.id === user.id ? user : u));
    updateUsers(newUsers);
  };

  const haveNoItems = response && response.data && response.data.length === 0 && !isLoading;

  return (
    <main role="main" className="container text-center kyc">
      <div className="kyc">
        <h2 className="h2-title">
          Сотрудники
          <UserModal enqueueSnackbar={enqueueSnackbar} onAdd={onAdd}>
            {open => (
              <Button aria-label="Добавить" className="btn-add" onClick={open}>
                + Добавить
              </Button>
            )}
          </UserModal>
        </h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Наименование</TableCell>
              <TableCell>Email</TableCell>
              <TableCell colSpan={2}>Действия</TableCell>
            </TableRow>
            <UsersFilter filters={filters} setFilters={onFiltersChange} />
          </TableHead>
          <TableBody>
            {(isLoading || error || haveNoItems) && (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  {!error && !isLoading && haveNoItems && <span>Объекты не найдены</span>}
                  {error && <span className="text-danger">Ошибка при загрузке данных</span>}
                  {isLoading && <Loader />}
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              !haveNoItems &&
              !error &&
              usersToShow.map(user => {
                return (
                  <TableRow key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell colSpan={2}>
                      <Grid container direction="row" spacing={8} wrap="nowrap" justify="flex-end">
                        <Grid item>
                          <UserModal
                            enqueueSnackbar={enqueueSnackbar}
                            onUpdate={onUpdate}
                            initial={user}
                          >
                            {open => (
                              <Button variant="outlined" className="info-btn" onClick={open}>
                                Редактировать
                              </Button>
                            )}
                          </UserModal>
                        </Grid>
                        {/* <Grid item> */}
                        {/*  <Button */}
                        {/*    variant="outlined" */}
                        {/*    className="decline-btn" */}
                        {/*    onClick={onRemove(user)} */}
                        {/*  > */}
                        {/*    Удалить */}
                        {/*  </Button> */}
                        {/* </Grid> */}
                        <Grid item>
                          <VehicleAccess user={user} enqueueSnackbar={enqueueSnackbar} />
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {!isLoading && (
          <TablePagination
            onChangePage={page => fetch(page, filters, response.meta.per_page)}
            onChangeRowsPerPage={per_page => fetch(1, filters, per_page)}
            meta={meta}
          />
        )}
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  const { user } = state.authentication;

  return {
    authUser: user
  };
};

export default withSnackbar(connect(mapStateToProps)(UsersLayout));
