import React from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import * as actions from '../../../../actions/newApplicationActions';
import AgreementsListRow from './AgreementsListRow';

const AgreementsList = ({ application, removeAgreement }) => {
  if (!application.application_agreements || !application.application_agreements.length) {
    return null;
  }
  return (
    <Table>
      <TableBody>
        {application.application_agreements.map(agreement => (
          <AgreementsListRow agreement={agreement} removeAgreement={removeAgreement} />
        ))}
      </TableBody>
      <br />
    </Table>
  );
};

const mapDispatchToProps = {
  removeAgreement: actions.removeAgreement
};

export default connect(
  undefined,
  mapDispatchToProps
)(AgreementsList);
