import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { getShowBanner, setHideBanner } from './BannerStorage';

const ReleaseNotesBanner = ({ name, heading, userId, children }) => {
  const [showBanner, setShowBanner] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  React.useEffect(() => {
    setShowBanner(getShowBanner(name, userId));
  }, []);
  const onDismiss = () => {
    setShowBanner(false);
    setHideBanner(name, userId);
  };
  const onClose = () => setShowModal(false);

  if (!showBanner || window.location.pathname === '/') {
    return null;
  }
  return (
    <div className="alert alert-warning alert-dismissible" role="alert">
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <h5 className="alert-heading no-margin-bottom">
            <Grid container direction="row" alignItems="center" spacing={16}>
              <Grid item>{heading}</Grid>
              <Grid item>
                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  onClick={() => setShowModal(true)}
                  aria-label="Подробнее"
                >
                  Подробнее
                </button>
              </Grid>
            </Grid>
          </h5>
        </Grid>
        <Grid item>
          <IconButton color="inherit" onClick={onDismiss}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Dialog open={showModal} onClose={onClose} aria-labelledby="form-dialog-title">
        <Toolbar className="text-center">
          <Typography variant="title" color="inherit" className="flex">
            {heading}
          </Typography>
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon className="blue" />
          </IconButton>
        </Toolbar>
        <DialogContent className="dialog">{children}</DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => ({
  userId: state.authentication.user.id
});

export default connect(mapStateToProps)(ReleaseNotesBanner);
