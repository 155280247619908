import { chain } from 'lodash';
import { stringify } from 'query-string';
import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'route-addresses';
};

const getPage = (page, filters = {}) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/page?${stringify({ page, ...filters })}`,
    {},
    true
  );
};

const getAll = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}`, {}, true);
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};

const findLocations = searchText => {
  const url = `/${getResourceName()}/search/${searchText}`;
  return apiService.call(apiConstants.METHOD_GET, url, {}, true);
};

export const routeAddressService = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,
  findLocations
};
