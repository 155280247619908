import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import RestorePasswordForm from './RestorePasswordForm';
import { authActions } from '../../../actions/authActions';

const restorePasswordSubmit = (values, dispatch) => {
  return dispatch(authActions.restorePassword(values));
};

const RestoreModal = props => {
  const { showRestoreModal, handleRestoreClose, restoreMessage, restoreFinished } = props;

  return (
    <Dialog
      open={showRestoreModal}
      onClose={handleRestoreClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Восстановление пароля
        </Typography>
        <IconButton color="inherit" onClick={handleRestoreClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        {!restoreFinished && (
          <p>
            На вашу почту будет отправлено письмо
            <br /> со сменой пароля
          </p>
        )}
        {!restoreFinished && <RestorePasswordForm onSubmit={restorePasswordSubmit} />}

        {restoreMessage}

        {restoreFinished && (
          <div>
            <br />
            <Button
              variant="contained"
              color="primary"
              className="btn lbutton"
              onClick={handleRestoreClose}
            >
              Закрыть
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { showRestoreModal, restoreMessage, restoreFinished } = state.restorePassword;
  return {
    showRestoreModal,
    restoreMessage,
    restoreFinished
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleRestoreClose: () => {
      dispatch(authActions.closeRestoreModal());
    }
  };
};

const connectedLoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RestoreModal);
export { connectedLoginPage as RestoreModal };
