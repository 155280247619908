import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { paymentsActions } from '../../../actions/paymentsActions';
import TablePagination from '../../../components/TablePagination';
import {dictionaryHelper} from '../../../helpers/dictionaryHelper'
import {roleConstants} from "../../../constants/roleConstants";
import {urlHelper} from "../../../helpers/urlHelper";
import {history} from "../../../helpers/history";

const PaymentsTableList = props => {
    const { data, meta, openShowPage, handleChangePage, handleDelete, payments } = props;
    let types = [];
    dictionaryHelper.paymentTypes.forEach((v, k) => {
      types[v.id] = v.title
    })
    return (
        <div>
            <Paper className="overflow">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Дата</TableCell>
                            <TableCell>Организация / ФИО</TableCell>
                            <TableCell>Тип</TableCell>
                            {/*<TableCell>Количество</TableCell>*/}
                            <TableCell>Сумма</TableCell>
                            <TableCell ></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(n => {
                            return (
                                <TableRow key={n.id}>
                                    <TableCell>{n.id}</TableCell>
                                    <TableCell>{n.created_at}</TableCell>
                                    <TableCell>{n.payer.name}</TableCell>
                                    <TableCell>{types[n.payment_type]}</TableCell>
                                    {/*<TableCell>{n.count}</TableCell>*/}
                                    <TableCell>{n.price}</TableCell>
                                    <TableCell >
                                        <Button
                                            variant="outlined"
                                            className="info-btn"
                                            size="small"
                                            onClick={openShowPage(n)}
                                        >
                                            Подробнее
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
            <TablePagination meta={meta} onChangePage={handleChangePage} />
        </div>
    );
};

const mapStateToProps = state => {
    const { payments } = state;

    return {
        payments
    };
};

const mapDispatchToProps = dispatch => {
    const openShowPage = item => {
        return async e => {
            await dispatch(paymentsActions.setCurrentItem(item));
            history.push(`${urlHelper.getHomeUrl(roleConstants.ROLE_ACCOUNTANT)}/payments/show/${item.id}`)
        };
    };

    return {
        openShowPage,
        dispatch
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { payments } = stateProps;
    const { dispatch } = dispatchProps;

    const handleChangePage = page => {
        dispatch(paymentsActions.getPage(page, payments.filters));
    };

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        handleChangePage
    };
};

const connectedPaymentsTableList = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PaymentsTableList);
export { connectedPaymentsTableList as PaymentsTableList };
