import React from 'react';

export const FormFieldNoLabel = ({
  disabled,
  input,
  className,
  placeholder,
  type,
  meta: { touched, error }
}) => (
  <div className={`form-group${touched && error ? ' has-error' : ''}`}>
    <input
      disabled={disabled}
      className={`form-control ${className}${touched && error ? ' form-control-error' : ''}`}
      {...input}
      placeholder={placeholder}
      type={type}
    />
    {touched && (error && <span>{error}</span>)}
  </div>
);
