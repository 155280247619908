import React from 'react';
import { connect } from 'react-redux';
import { MainFooterBlock } from '../main/blocks/MainFooterBlock/MainFooterBlock';
import VehiclesLayout from './vehicles/VehiclesLayout';
import { CabinetLeftMenu } from '../cabinet/blocks/CabinetLeftMenu/CabinetLeftMenu';
import { CabinetMainMenu } from '../cabinet/blocks/CabinetMainMenu/CabinetMainMenu';
import { FirmuserRoute } from '../../components/routes/FirmuserRoute';
import CabinetMainPage from './main/CabinetMainPage';
import { ApplicationsLayout } from '../cabinet/applications/ApplicationsLayout';

let FirmuserLayout = props => {
  const { match } = props;

  const links = [
    {
      key: '1',
      path: `${match.url}`,
      title: 'Личный кабинет',
      hasChild: false
    },
    {
      key: '2',
      path: `${match.url}/vehicles`,
      title: 'Тс и прицепы',
      hasChild: true
    },
    {
      key: '3',
      path: `${match.url}/applications`,
      title: 'Разрешения',
      hasChild: true
    }
  ];

  return (
    <div className="">
      <div className="cabinet-header">
        <CabinetMainMenu />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className=" cabinet-left-menu">
              <CabinetLeftMenu links={links} />
            </div>
          </div>

          <div className="col-md-9 cabinet-content">
            <FirmuserRoute exact path={`${match.url}`} component={CabinetMainPage} />
            <FirmuserRoute path={`${match.url}/vehicles`} component={VehiclesLayout} />
            <FirmuserRoute path={`${match.url}/applications`} component={ApplicationsLayout} />
          </div>
        </div>
      </div>
      <MainFooterBlock />
    </div>
  );
};

const mapStateToProps = state => {
  const { authentication } = state;
  return {
    authentication
  };
};

FirmuserLayout = connect(mapStateToProps)(FirmuserLayout);
export default FirmuserLayout;
