import React from 'react'
import {fieldValidators} from "../../../helpers/fieldValidators";
import {Loader} from "../../../components/index";
import Button from '@material-ui/core/Button';
import MateriaInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput'
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialSelect from '../../admin/quickApplications/CreateQuickApplication/components/MaterialSelect'
import Grid from '@material-ui/core/Grid';

import {Field, Form, Formik, validateYupSchema, yupToFormErrors} from 'formik';
import {apvgkReportsConstants} from "../../../constants/apvgkReportsConstants";
import EcpField from './EcpField'
import {Schema} from './util/Schema';
import {apvgkReportsService} from "../../../services/apvgkReportsService";
import {Link} from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {WithError} from "../../admin/quickApplications/CreateQuickApplication/Axles";
import GrzCheckDialog from "./GrzCheckDialog";


const TrackTypes = [
    {value: "1.1", label: "Легковые автомобили"},
    {value: "1.2", label: "Легковые фургоны"},
    {value: "1.3", label: "Легковые автомобили с одноосным прицепом"},
    {value: "1.4", label: "Легковые автомобили с двухосным прицепом"},
    {value: "2.5", label: "Одиночный грузовой двухосный автомобиль"},
    {value: "3.7", label: "Одиночный грузовой трехосный автомобиль"},
    {value: "4.8", label: "Одиночный грузовой четырехосный автомобиль"},
    {value: "4.9", label: "Одиночный грузовой четырехосный автомобиль"},
    {value: "5.10", label: "Одиночный грузовой пятиосный автомобиль"},
    {value: "6.11", label: "Автопоезда прицепные трехосные (2+1)"},
    {value: "6.12", label: "Автопоезда седельные трехосные (2+1)"},
    {value: "7.13", label: "Автопоезда прицепные четырехосные (2+2)"},
    {value: "7.14", label: "Автопоезда прицепные четырехосные (2+2)"},
    {value: "7.15", label: "Автопоезда сидельные четырехосные (2+2)"},
    {value: "7.16", label: "Автопоезда сидельные четырехосные (3+1)"},
    {value: "8.17", label: "Автопоезда прицепные пятиосные (2+3)"},
    {value: "8.18", label: "Автопоезда прицепные пятиосные (3+2)"},
    {value: "8.19", label: "Автопоезда седельные пятиосные (2+3)"},
    {value: "8.20", label: "Автопоезда седельные пятиосные (3+2)"},
    {value: "9.21", label: "Автопоезда прицепные шестиосные (3+3)"},
    {value: "9.22", label: "Автопоезда седельные шестиосные (3+3)"},
    {value: "9.23", label: "Автопоезда прицепные шестиосные (4+2)"},
    {value: "9.24", label: "Автопоезда семиосные и более"},
    {value: "10.25", label: "Автобусы двухосные одиночные"},
    {value: "11.26", label: "Автобусы трехосные одиночные"},
    {value: "12.27", label: "Прочие нестандартные ТС (сельскохозяйственная, строительная и прочая техника)"}
];
const dimensionsLabels = {
    track_length: 'Длина',
    track_width: 'Ширина',
    track_height: 'Высота',
}
class EditForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cert : null,
            tab: props.report.application_id || -1,
            grzLoad: false
        }
    }


    render() {
        const {onSubmit, submitting, error, report, onReject, onCheckGrz} = this.props

        let sloppiness = [];
        report.info.groups && report.info.groups.map &&report.info.groups.map((v) => sloppiness.push(...v.axles));
        const initialValues = {
            ...report,
            sloppiness: sloppiness.map((v) => v.wheel_count),
            categories: report.apvgk_violation ? report.apvgk_violation.TrackCategory + '.' + report.apvgk_violation.TrackSubCategory : '',
            dimensions: {
                track_length: 0,
                track_width: 0,
                track_height: 0,
            }
        }
        const label =
            report.status === apvgkReportsConstants.STATUS_DECLINE ? 'Дата отклонения акта взвешивания' : (
                report.status === apvgkReportsConstants.STATUS_ACCEPT ? 'Дата одобрения акта взвешивания'
                    : 'Дата взятия акта в работу'
            )
        const userLabel =
            report.status === apvgkReportsConstants.STATUS_DECLINE ? 'ФИО сотрудника, отклонившего акт' : (
                report.status === apvgkReportsConstants.STATUS_ACCEPT ? 'ФИО сотрудника, одобрившего акт'
                    : 'ФИО сотрудника, взявшего акт в работу'
            )
        const badGrz = !!report.bad_grz;
        const getPdfLinkQuery = (values) => {
            let data = {application_id: this.state.tab > 0 ? this.state.tab : null};
            values.sloppiness.forEach((v,i) => {
                data['sloppiness['+i+']'] = v;
            })
            Object.keys(values.dimensions).forEach((v) => {
                if (values.dimensions[v] > 0) data['dimensions['+v+']'] = values.dimensions[v];
            })
            data['categories'] = values.categories;
            return(new URLSearchParams(data)).toString();
        }
        const inWork = report.status == apvgkReportsConstants.STATUS_IN_WORK;
        const apps = (report.applications||[])
            .concat(report.smev_result && report.smev_result.applications || []);
        return (
            <Formik
                initialValues={{...initialValues, ecp: undefined,}}
                onSubmit={async (data, { validateForm, setErrors, setSubmitting }) => {
                    data.application_id = this.state.tab > 0 ? this.state.tab : null;
                    setSubmitting(true);
                    await onSubmit(data);
                    setSubmitting(false);
                }}
                validate={async values => {
                    return new Promise((resolve, reject) => {
                        validateYupSchema(values, Schema, false, { head: values }).then(
                            () => resolve({}),
                            err => reject(yupToFormErrors(err))
                        );
                    });
                }}
                render={({values, setFieldValue}) => (

                    <Form>

                        <Grid container direction="column" spacing={16} wrap="nowrap">
                            <Grid item>
                                <FieldLabel label="id взвешивания" name="id">
                                    <Field
                                        name="id"
                                        type="text"
                                        disabled={!this.props.edit}
                                        component={MateriaInput}
                                    />
                                </FieldLabel>
                            </Grid>
                            <Grid item>
                                <FieldLabel label="id акта" name="apvgk_violation.IDBetamount">
                                    <Field
                                        name="apvgk_violation.IDBetamount"
                                        type="text"
                                        disabled={!this.props.edit}
                                        component={MateriaInput}
                                    />
                                </FieldLabel>
                            </Grid>
                            <Grid item>
                                <FieldLabel label="Дата создания акта" name="date_create">
                                    <Field
                                        name="date_create"
                                        type="text"
                                        disabled={!this.props.edit}
                                        component={MateriaInput}
                                    />
                                </FieldLabel>
                            </Grid>
                            <Grid item>
                                <FieldLabel label="Наименование АПВГК" name="apvgk_violation.Equipment.EquipmentName">
                                    <Field
                                        name="apvgk_violation.Equipment.EquipmentName"
                                        type="text"
                                        disabled={!this.props.edit}
                                        label="Организация/ФИО"
                                        component={MateriaInput}
                                    />
                                </FieldLabel>


                            </Grid>
                            <Grid item>
                                <FieldLabel label="Направление АПВГК" name="apvgk_violation.Equipment.EquipmentName">
                                    <Field
                                        className="form-control"
                                        name="apvgk_violation.PlatformId"
                                        type="text"
                                        disabled={!this.props.edit}
                                        component={MateriaInput}
                                        validate={[fieldValidators.required]}
                                    />
                                </FieldLabel>

                            </Grid>
                            <Grid item>
                                <FieldLabel label="Место фиксации" name="apvgk_violation.Equipment.EquipmentName">
                                    <Field
                                        name="apvgk_violation.Equipment.Place"
                                        type="text"
                                        disabled={!this.props.edit}
                                        component={MateriaInput}
                                    />
                                </FieldLabel>

                            </Grid>
                            {!badGrz && <Grid item>
                                <FieldLabel label="ГРЗ ТС" name="apvgk_violation.id">
                                    <Field
                                        name="apvgk_violation.TrackStateNumber"
                                        type="text"
                                        disabled={!this.props.edit}
                                        label="ГРЗ ТС"
                                        clearable={true}
                                        component={MateriaInput}
                                    />
                                </FieldLabel>
                            </Grid>}
                            {badGrz && <Grid item>
                                <FieldLabel label="ГРЗ ТС" name="">
                                    <GrzCheckDialog onCheck={onCheckGrz} report={report}/>
                                </FieldLabel>
                            </Grid>}
                            {!badGrz && <><Grid item>
                                <FieldLabel label="№ разрешения, сопоставленного с данным актом взвешивания" name="application_id">
                                    <div className="col-md-8 input-link-container">
                                        <Link to={!report.application_id ? '#' :'/apvgk-user/applications/view/'+report.application_id}>
                                            {report.application_id ? 'Разрешение: '+ report.application_id : 'Разрешение не сопоставлено'}
                                        </Link>
                                    </div>
                                </FieldLabel>
                            </Grid>
                            <Grid item>
                                <FieldLabel label="№ разрешения, в котором проставлена отметка о проезде" name="used_run_application_id">
                                    <div className="col-md-8 input-link-container">
                                        <Link to={!report.used_run_application_id ? '#' : '/apvgk-user/applications/view/'+report.used_run_application_id}>
                                            {report.used_run_application_id ? 'Разрешение: '+ report.used_run_application_id : 'Отметок по нарушению нет'}
                                        </Link>
                                    </div>
                                </FieldLabel>
                            </Grid></>}

                            {inWork && !badGrz &&
                            <>

                            <Grid item className="apvgk-sloppiness">
                                <FieldLabel label="Скатность*">
                                    <Grid container spacing={8} alignItems="flex-start">
                                        {sloppiness.map((v,i) => (
                                            <WithError name={`sloppiness[${i}]`} index={i+1}>
                                                <Field
                                                    name={`sloppiness[${i}]`}
                                                    component={MaterialSelect}
                                                    placeholder=""
                                                    options={[{ value: 1, label: '1' }, { value: 2, label: '2' }]}
                                                    getValue={f => f.value}
                                                    afterChange={() => {}}
                                                />
                                            </WithError>
                                        ))}
                                    </Grid>
                                </FieldLabel>
                            </Grid>
                            <Grid item>
                                <FieldLabel label="Габариты*">
                                    <Grid container spacing={8} alignItems="flex-start">
                                        {Object.keys(values.dimensions).map((v,i) => (
                                            <FieldLabel label={dimensionsLabels[v]} name={`dimensions[${v}]`}>
                                                <Field
                                                    type="text"
                                                    name={`dimensions[${v}]`}
                                                    component={MateriaInput}
                                                />
                                            </FieldLabel>
                                        ))}
                                    </Grid>
                                </FieldLabel>
                            </Grid>

                            <Grid item className="apvgk-categories">
                                <FieldLabel label="Категория ТС">
                                    <Field
                                      name={`categories`}
                                      component={MaterialSelect}
                                      placeholder=""
                                      options={TrackTypes}
                                      getValue={f => f.value}
                                      afterChange={() => {}}
                                    />
                                </FieldLabel>
                            </Grid>
                            </>}
                            {!badGrz && inWork && apps && apps.length > 0 &&
                            <AppBar position="static" color="default" className="tabs_header">
                                <Tabs
                                    value={this.state.tab}
                                    onChange={(e, newValue) => {
                                        this.setState({...this.state, tab:newValue});
                                    }}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    className="tab_style"
                                    variant="fullWidth"
                                >
                                    {([{id:-1}].concat(apps))
                                        .map((v) => (
                                         <Tab key={v.id} classes={{ selected: 'tab_active' }}
                                             label={v.id == -1 ? 'Без разрешения' : ('№'  + (v.id !== undefined ? v.id : (v+'(СМЭВ)')))}
                                             value={v.id || v} />
                                    ))}
                                </Tabs>
                            </AppBar>}
                            {this.state.tab > 0 && <Grid item>
                                <div>
                                    <Grid item>
                                        <FieldLabel label="Ссылка на разрешение" name="application_link">
                                            <div className="col-md-8 input-link-container">
                                                <Link to={'/apvgk-user/applications/view/' + this.state.tab}
                                                      target="_blank"
                                                      className="input-link"
                                                >
                                                    Разрешение №{this.state.tab}
                                                </Link>
                                            </div>
                                        </FieldLabel>
                                    </Grid>
                                </div>

                            </Grid>}
                            {report.user.id > 0 && <Grid item>
                                <div>
                                    <Grid item>
                                        <FieldLabel label="Ссылка на файл сгенерированного акта взвешивания"
                                                    name="application_id">
                                            <div className="col-md-8 input-link-container">
                                                <Link to={''}
                                                      onClick={(e) => {
                                                          e.preventDefault()
                                                          apvgkReportsService.downloadFile(report.id, inWork , getPdfLinkQuery(values))
                                                      }}
                                                      target="_blank"
                                                      className="input-link"
                                                >
                                                    Ссылка на файл
                                                </Link>
                                            </div>
                                        </FieldLabel>
                                    </Grid>
                                </div>
                            </Grid>}
                            {report.url_sig && !badGrz && (<Grid item>
                                    <div>
                                        <Grid item>
                                            <FieldLabel label="Ссылка на файл подписи акта" name="application_id">
                                                <div className="col-md-8 input-link-container">

                                                    <Link to={''}
                                                          onClick={(e) => {
                                                              e.preventDefault()
                                                              apvgkReportsService.downloadSigFile(report.id, false)
                                                          }}
                                                          target="_blank"
                                                    >
                                                        Ссылка на файл
                                                    </Link>
                                                </div>
                                            </FieldLabel>
                                        </Grid>
                                    </div>

                                </Grid>
                            )}
                            <Grid item>
                                <FieldLabel label={userLabel} name="user.name">
                                    <Field
                                        name="user.name"
                                        type="text"
                                        disabled={!this.props.edit}
                                        component={MateriaInput}
                                    />
                                </FieldLabel>
                            </Grid>

                            <Grid item>
                                <FieldLabel label={label} name="date_update">
                                    <Field
                                        name="date_update"
                                        type="text"
                                        disabled={!this.props.edit}
                                        component={MateriaInput}
                                    />
                                </FieldLabel>
                            </Grid>

                            {inWork&&!badGrz &&(
                                <Field name="ecp">
                                    {({field, form, meta}) => (<EcpField
                                        dataUrl={`/${apvgkReportsService.getResourceName()}/${report.id}/pdf?${getPdfLinkQuery(values)}`}
                                        required={true}
                                        field={field}
                                        form={form}
                                        meta={meta}
                                    />)}
                                </Field>
                            )}
                            {inWork && (<Grid item>
                                {this.props.edit && error && <strong>{error}</strong>}
                                {submitting && <Loader/>}
                                {!badGrz && <button type="submit" className="btn" disabled={submitting || !values.ecp || !values.ecp.sig}>
                                    Принять
                                </button>}

                                <Button
                                    className="decline-btn"
                                    variant="outlined"
                                    color="primary"
                                    type="button"
                                    onClick={() => onReject({application_id: this.state.tab > 0 ? this.state.tab : null})}
                                >
                                    Отклонить
                                </Button>
                                <br/>
                                <br/>
                            </Grid>)}
                        </Grid>
                    </Form>
                )}
            />
        )
    }
}


export default EditForm