import React from 'react';
import { ClientRoute } from '../../../components/routes/ClientRoute';
import { VehiclesListPage } from './VehiclesListPage';
import { VehiclesUpdatePage } from './VehiclesUpdatePage';
import { VehiclesCreatePage } from './VehiclesCreatePage';

const VehiclesLayout = ({ match }) => (
  <div>
    <ClientRoute exact path={`${match.url}/`} component={VehiclesListPage} />
    <ClientRoute exact path={`${match.url}/create`} component={VehiclesCreatePage} />
    <ClientRoute exact path={`${match.url}/update/:id`} component={VehiclesUpdatePage} />
  </div>
);

export default VehiclesLayout;
