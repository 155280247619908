import { controlPostConstants } from '../constants/controlPostConstants';
import { controlPostService } from '../services/controlPostService';

const getPage = page => {
  const request = page => {
    return { type: controlPostConstants.GET_PAGE_REQUEST, page };
  };

  const success = controlPosts => {
    return { type: controlPostConstants.GET_PAGE_SUCCESS, controlPosts };
  };

  const failure = error => {
    return { type: controlPostConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(page));

    controlPostService
      .getPage(page)
      .then(controlPosts => dispatch(success(controlPosts)), error => dispatch(failure(error)));
  };
};

const getAll = () => {
  const request = () => {
    return { type: controlPostConstants.GET_ALL_REQUEST };
  };

  const success = controlPosts => {
    return { type: controlPostConstants.GET_ALL_SUCCESS, controlPosts };
  };

  const failure = error => {
    return { type: controlPostConstants.GET_ALL_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    controlPostService
      .getAll()
      .then(controlPosts => dispatch(success(controlPosts)), error => dispatch(failure(error)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: controlPostConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: controlPostConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: controlPostConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    controlPostService.delete(id).then(
      data => {
        if (data.success) {
          dispatch(success(id));
        } else {
          dispatch(failure(id, 'Не может быть удалено!'));
        }
      },
      error => dispatch(failure(id, error))
    );
  };
};

const getItem = id => {
  const request = id => {
    return { type: controlPostConstants.LOAD_REQUEST, id };
  };

  const success = controlPost => {
    return { type: controlPostConstants.LOAD_SUCCESS, controlPost };
  };

  const failure = error => {
    return { type: controlPostConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    controlPostService
      .getItem(id)
      .then(controlPost => dispatch(success(controlPost)), error => dispatch(failure(error)));
  };
};

const create = values => {
  const request = value => {
    return { type: controlPostConstants.CREATE_REQUEST, value };
  };

  const success = controlPost => {
    return { type: controlPostConstants.CREATE_SUCCESS, controlPost };
  };

  return dispatch => {
    dispatch(request(values));

    return controlPostService.create(values).then(controlPost => dispatch(success(controlPost)));
  };
};

const update = values => {
  const request = value => {
    return { type: controlPostConstants.UPDATE_REQUEST, value };
  };

  const success = controlPost => {
    return { type: controlPostConstants.UPDATE_SUCCESS, controlPost };
  };

  return dispatch => {
    dispatch(request(values));

    return controlPostService.update(values).then(controlPost => dispatch(success(controlPost)));
  };
};

const openFormModal = () => {
  return { type: controlPostConstants.FORM_MODAL_OPEN };
};
const closeFormModal = () => {
  return { type: controlPostConstants.FORM_MODAL_CLOSE };
};
const setCurrentItem = item => {
  return { type: controlPostConstants.SET_CURRENT_ITEM, item };
};

const closeErrorModal = () => {
  return { type: controlPostConstants.CLOSE_ERROR_MODAL };
};

const choosePost = values => {
  localStorage.setItem('post_id', values.control_post_id);
  return { type: controlPostConstants.CHOOSE_POST, id: values.control_post_id };
};

export const controlPostActions = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,

  openFormModal,
  closeFormModal,
  setCurrentItem,

  closeErrorModal,

  choosePost
};
