import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { FormField } from '../../../components/form/FormField';
import { Loader } from '../../../components/Loader';
import { fieldValidators } from '../../../helpers/fieldValidators';

let ApplicationPrintForm = props => {
  const { handleSubmit, submitting, adminApplications } = props;
  const { currentItem } = adminApplications;

  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="form_id"
        type="text"
        label="Номер бланка"
        className=""
        component={FormField}
        validators={fieldValidators.required}
        disabled={currentItem && currentItem.form_id ? 'disabled' : null}
      />

      <Button
        variant="contained"
        color="primary"
        className="btn-add no-margin"
        type="submit"
        disabled={submitting}
      >
        {submitting && <Loader />} Печать
      </Button>
    </form>
  );
};

ApplicationPrintForm = reduxForm({
  form: 'application-admin-print-form',
  enableReinitialize: true,
  destroyOnUnmount: false
})(ApplicationPrintForm);

const mapStateToProps = state => {
  const { adminApplications } = state;
  return {
    adminApplications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { adminApplications } = stateProps;

  return {
    ...ownProps,
    ...dispatchProps,
    ...stateProps,
    initialValues: {
      application_id: adminApplications.currentItem ? adminApplications.currentItem.id : '',
      form_id: adminApplications.currentItem ? adminApplications.currentItem.form_id : ''
    }
  };
};

ApplicationPrintForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationPrintForm);
export default ApplicationPrintForm;
