import React from 'react';
import { OfficerRoute } from '../../../components/routes/OfficerRoute';
import ApplicationsListPage from '../../admin/quickApplications/ApplicationsListPage';
import { ApplicationViewPage } from '../../admin/applications/ApplicationViewPage';
import CreateQuickApplication from '../../admin/quickApplications/CreateQuickApplication/CreateQuickApplication';
import CopyQuickApplication from '../../admin/quickApplications/CopyQuickApplication';

const QuickApplicationsLayout = ({ match }) => (
  <div>
    <OfficerRoute exact path={`${match.url}/`} component={ApplicationsListPage} />
    <OfficerRoute exact path={`${match.url}/view/:id`} component={ApplicationViewPage} />
    <OfficerRoute exact path={`${match.url}/create`} component={CreateQuickApplication} />
    <OfficerRoute exact path={`${match.url}/copy/:id`} component={CopyQuickApplication} />
  </div>
);

export default QuickApplicationsLayout;
