import React from 'react';
import { connect } from 'react-redux';
import { statusHelper } from '../../../helpers/statusHelper';
import { applicationConstants } from '../../../constants/applicationConstants';
import { roleConstants } from '../../../constants/roleConstants';
import { applicationHelper } from '../../../helpers/applicationHelper';
import ShowByStatus from './ShowByStatus';

const ApplicationStatus = ({ application, specialConditions, user }) => {
  return (
    <div className="blue_frame">
      <p>
        <strong>Статус:</strong> {statusHelper.getStatusText(user.role_id, application)}{' '}
      </p>
      <ShowByStatus
        allowedStatus={applicationConstants.STATUS_DECLINE}
        allowedRoles={[
          roleConstants.ROLE_ADMIN,
          roleConstants.ROLE_FIRM,
          roleConstants.ROLE_FIRMUSER,
          roleConstants.ROLE_INDIVIDUAL
        ]}
      >
        <div key="reason">
          <strong>Причина отклонения: </strong>
          {application.comment}
        </div>
        <br />
      </ShowByStatus>
      <ShowByStatus
        allowedRoles={[roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER]}
        allowedStatuses={[applicationConstants.STATUS_REVIEW, applicationConstants.STATUS_REPEAT]}
      >
        {applicationHelper.needWeightAgreement(application) &&
          !applicationHelper.overweightAgreed(application) && (
            <span className="danger">Необходимо согласовать разрешение с ГДС</span>
          )}
        {applicationHelper.needSizeAgreement(application) &&
          !applicationHelper.oversizeAgreed(application) && (
            <p>
              <span className="danger">Необходимо согласовать разрешение с ГИБДД</span>
            </p>
          )}
      </ShowByStatus>
      <ShowByStatus
        allowedRoles={[roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER]}
        allowedStatuses={[applicationConstants.STATUS_REVIEW, applicationConstants.STATUS_REPEAT]}
        additionalCondition={
          applicationHelper.needWeightAgreement(application) &&
          !applicationHelper.overweightAgreed(application)
        }
      >
        <span className="danger">Необходимо согласовать разрешение с ГДС</span>
      </ShowByStatus>
      {applicationHelper.checkBeforeSpring(application, specialConditions) && (
        <p>
          <span className="danger">
            Данное разрешение не действует в период весеннего ограничения при условии превышения
            допустимых нагрузок в размере 6 тонн/ось
          </span>
        </p>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  specialConditions: state.specialConditions.currentItem,
  user: state.authentication.user
});

export default connect(mapStateToProps)(ApplicationStatus);
