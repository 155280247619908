import React from 'react';
import { get, uniqueId } from 'lodash';
import Select from 'react-select';
import { FieldArray } from 'formik';
import StartPointIcon from '@material-ui/icons/FiberManualRecord';
import PointIcon from '@material-ui/icons/Adjust';
import EndPointIcon from '@material-ui/icons/Room';
import { Grid } from '@material-ui/core';
import { applicationService } from '../../../../../services/applicationService';
import MaterialInput from '../../../../admin/quickApplications/CreateQuickApplication/components/MaterialInput';

const getStepByCoords = async latlng => {
  if (!latlng) {
    return {
      id: uniqueId(),
      isEmpty: false
    };
  }
  const { lat, lng } = latlng;
  const response = await applicationService.getCoordsLocation(lat, lng);
  const { data } = response;
  return {
    id: uniqueId(),
    isEmpty: false,
    text: data.text,
    coords: latlng
  };
};

const SinglePath = ({ values, arrayHelpers, options, name, onChange, removeStep, index }) => {
  const { steps, useSaved, saved, id } = get(values, name);
  const routeOptions = React.useMemo(
    () =>
      options.map(option => ({
        value: option,
        label: option.text
      })),
    [options]
  );

  return (
    <Grid container direction="column" spacing={8} wrap="nowrap">
      <Grid item>
        <Grid container spacing={16}>
          <Grid item>
            <h4 className="h4-title">Отрезок {index + 1}</h4>
          </Grid>
        </Grid>
      </Grid>
      {useSaved && (
        <Select
          className="react-select-container_multiline"
          classNamePrefix="react-select_multiline"
          name="tripType"
          placeholder="Маршрут"
          value={routeOptions.find(option => id && option.value.id === id)}
          options={routeOptions}
          onChange={value => onChange({ saved: value })}
          isDisabled
        />
      )}
      {!useSaved && (
        <>
          <Grid item>
            <Grid container direction="column" spacing={8} className="points_area">
              <div className="points_div points_line-map">
                <div className="points_line" />
              </div>
              {steps.map((step, ind) => (
                <React.Fragment>
                  <Grid item>
                    <Grid container spacing={8} alignItems="center" justify="space-between">
                      <Grid
                        item
                        sm={1}
                        className="map-point"
                        container
                        alignItems="center"
                        justify="flex-start"
                      >
                        {ind === 0 && <StartPointIcon />}
                        {ind > 0 && ind < steps.length - 1 && <PointIcon />}
                        {ind === steps.length - 1 && <EndPointIcon className="red" />}
                      </Grid>
                      <Grid item sm={10}>
                        <MaterialInput value={steps[ind].text} disabled />
                      </Grid>
                      <Grid item sm={1} />
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const SinglePathContainer = ({ name, ...props }) => (
  <FieldArray
    name={`${name}.steps`}
    render={arrayHelpers => <SinglePath name={name} arrayHelpers={arrayHelpers} {...props} />}
  />
);

export default SinglePathContainer;
