import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ErrorMessage } from 'formik';
import { isObject } from 'lodash';

const FieldLabel = ({ label, children, name, withoutError }) => (
  <Grid container direction="row" alignItems="center">
    <Grid item xs={5} lg={3}>
      <span>{label}</span>
    </Grid>
    <Grid item xs={7} lg={withoutError ? 9 : 6}>
      {children}
    </Grid>
    {!withoutError && (
      <Grid item xs={12} lg={3} className="has-error">
        <div className="margin_2">
          {name && (
            <ErrorMessage name={name}>
              {msg =>
                isObject(msg) ? (
                  Object.values(msg).map(m => (
                    <span style={{ display: 'block' }} key={m}>
                      {m}
                    </span>
                  ))
                ) : (
                  <span>{msg}</span>
                )
              }
            </ErrorMessage>
          )}
        </div>
      </Grid>
    )}
  </Grid>
);

export default FieldLabel;
