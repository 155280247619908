import React from 'react';
import ReactSelect from 'react-select';

const Select = props => {
  const { onChange, allOption, allowSelectAll, options, value } = props;
  if (allowSelectAll) {
    if (value.length === options.length) {
      return (
        <ReactSelect
          {...props}
          value={[allOption]}
          onChange={selected => onChange(selected ? selected.slice(1) : [])}
        />
      );
    }

    return (
      <ReactSelect
        {...props}
        options={[allOption, ...options]}
        onChange={selected => {
          if (
            selected &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return props.onChange(props.options);
          }
          return props.onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect {...props} />;
};

export default Select;
