import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Autosuggest from 'react-autosuggest';
import StartPointIcon from '@material-ui/icons/FiberManualRecord';
import PointIcon from '@material-ui/icons/Adjust';
import EndPointIcon from '@material-ui/icons/Room';
import DeleteIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles/index';

import { roleConstants } from '../../../../constants/roleConstants';
import { routeAddressActions } from '../../../../actions/routeAddressActions';

const styles = {
  addressAutosuggest: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15
  }
};

const Route = ({
  index,
  marker: { suggestions, text },
  length,
  isRouteEditable,
  userRole,

  removeMarker,
  setMarkerValue,

  classes,

  searchLocation,
  setSuggestions,
  setValue,
  error,
  setError
}) => {
  useEffect(() => {
    if (!text) setError(text);
  });

  return (
    <div className="row points">
      <div className="col-md-1 points-date">
        {index === 0 && <StartPointIcon />}
        {index > 0 && index < length - 1 && <PointIcon />}
        {index === length - 1 && <EndPointIcon className="red" />}
      </div>
      <div className="col-md-9">
        <div className={classes.addressAutosuggest}>
          <Autosuggest
            suggestions={suggestions || []}
            onSuggestionsFetchRequested={({ value }) => {
              if (value.length >= 3) searchLocation(index, value);
            }}
            onSuggestionsClearRequested={() => setSuggestions(index, [])}
            getSuggestionValue={suggestion => suggestion}
            renderSuggestion={({ title }) => <span>{title || ''}</span>}
            inputProps={{
              onBlur: () => setError(text),
              onChange: (event, { method, newValue }) => {
                let val = '';
                if (method === 'click') {
                  val = newValue.title;
                }
                if (method === 'type') {
                  val = newValue;
                }
                setError(val);
                setValue(index, val);
                setMarkerValue(index, val);
              },
              value: text || '',
              placeholder: 'Введите адрес',
              disabled: !isRouteEditable
            }}
          />
          {error && (
            <div className="has-error">
              <span>{error}</span>
            </div>
          )}
        </div>
      </div>
      {userRole !== roleConstants.ROLE_WEIGHT_CONTROL && length > 2 && isRouteEditable && (
        <div className="col-md-1 points" onClick={() => removeMarker(index)}>
          <span>
            <DeleteIcon />
          </span>{' '}
        </div>
      )}
    </div>
  );
};

const RouteContent = ({ markers, errors, updateError, ...props }) =>
  markers.map((marker, index) => (
    <Route
      key={marker.id}
      index={index}
      marker={marker}
      length={markers.length}
      error={errors[marker.id]}
      setError={value => updateError(marker.id, value)}
      {...props}
    />
  ));

export default compose(
  withStyles(styles),
  connect(
    undefined,
    {
      searchLocation: routeAddressActions.searchLocation,
      setSuggestions: routeAddressActions.setSuggestions,
      setValue: routeAddressActions.setValue
    }
  )
)(RouteContent);
