import { chain, flatten, isArray, isString } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';
import { applicationConstants } from '../constants/applicationConstants';

const getResourceName = () => {
  return 'applications';
};
const getAgreementsResourceName = () => {
  return 'application-agreements';
};

const getUserFiltered = (tabIndex, values, page = 1) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/user/status/${tabIndex}?page=${page}`,
    values,
    true
  );
};

const getAdminApplications = ({ department_id, isFast, isSmev, ...values }) => {
  const status = values.status ? values.status : 0;
  const page = values.page ? values.page : 1;

  const departmentPart = department_id ? `&department_id=${department_id}` : '';
  const fastPart = isFast ? `&is_fast=${isFast === true ? 1 : 0}` : '';
  const smevPart = isSmev ? `&is_smev=${isSmev === true ? 1 : 0}` : '';

  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/admin/${status}?page=${page}${departmentPart}${fastPart}${smevPart}`,
    values,
    true
  );
};

const getControlFiltered = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/control`, values, true);
};
const getControlByRDMUUID = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/control/rdm`, values, true);
};

const getStatusPage = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/admin`, {}, true);
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};

const saveVehicleStep = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/vehicle`, values, true);
};

const updateVehicleStep = (id, values) => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${id}/vehicle`,
    values,
    true
  );
};

const withdraw = id => {
  return apiService.call(apiConstants.METHOD_PUT, `/${getResourceName()}/${id}/withdraw`, {}, true);
};
const toAdmin = id => {
  return apiService.call(apiConstants.METHOD_PUT, `/${getResourceName()}/${id}/to-admin`, {}, true);
};

const toWork = ({ id }) => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${id}/to-work`,
    {},
    true,
    {},
    false
  );
};
const accept = (
  id,
  comment = null,
  waypoints = null,
  routeChanged = 0,
  changeStatus = 1,
  is_spring = 0,
  is_smev = 0,
  special_conditions = undefined,
  ecp = '',
  signed_xml = '',
  apvgk_list = null,
) => {
  const values = {
    comment,
    waypoints,
    routeChanged,
    changeStatus,
    is_spring,
    is_smev,
    special_conditions,
    ecp,
    signed_xml,
    apvgk_list
  };
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${id}/accept`,
    values,
    true
  );
};
const activate = id => {
  return apiService.call(apiConstants.METHOD_PUT, `/${getResourceName()}/${id}/activate`, {}, true);
};
const decline = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}/decline`,
    values,
    true
  );
};
const setAdditionalInfoRequired = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}/need_attachments`,
    values,
    true
  );
};
const restore = id => {
  return apiService.call(apiConstants.METHOD_PUT, `/${getResourceName()}/${id}/restore`, {}, true);
};

const lock = id => {
  return apiService.call(apiConstants.METHOD_PUT, `/${getResourceName()}/${id}/lock`, {}, true);
};
const unlock = id => {
  return apiService.call(apiConstants.METHOD_PUT, `/${getResourceName()}/${id}/unlock`, {}, true);
};

const getLoadItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}/load`, {}, true);
};
const saveLoadStep = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}/load`,
    values,
    true
  );
};

const getRouteItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}/route`, {}, true);
};
const saveRouteStep = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}/route`,
    values,
    true
  );
};

const getDatesItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}/dates`, {}, true);
};
const saveDatesStep = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}/dates`,
    values,
    true
  );
};

const transformLocations = data => {
  const features = chain(data)
    .flatMap(d => d.items)
    .map(item => ({
      coords: {
        lat: item.point.lat,
        lon: item.point.lon,
        lng: item.point.lon
      },
      address: item.address,
      text: item.label,
      title: item.label
    }))
    .value();

  return {
    features
  };
};

const MAP_TOKEN = 'MAP_TOKEN';

const refreshMapToken = async () => {
  try {
    const response = await apiService.call(apiConstants.METHOD_GET, '/gradoservice/auth', {}, true);
    const { token, ttl } = isString(response.data) ? JSON.parse(response.data) : response.data;
    const tokenData = { token, validUntil: moment().add(ttl, 's') };
    window.localStorage.setItem(MAP_TOKEN, JSON.stringify(tokenData));
    return tokenData;
  } catch (e) {
    return null;
  }
};

const getToken = async () => {
  const tokenItem = window.localStorage.getItem(MAP_TOKEN);
  let parsedTokenItem;
  if (!tokenItem) {
    parsedTokenItem = await refreshMapToken();
  } else {
    parsedTokenItem = JSON.parse(tokenItem);
    if (moment(parsedTokenItem.validUntil).isBefore(moment())) {
      parsedTokenItem = await refreshMapToken();
    }
  }
  if (!parsedTokenItem) {
    return null;
  }
  return parsedTokenItem.token;
};

const findLocations = async searchText => {
  const token = await getToken();
  const url = `${applicationConstants.MAP_URL}/geocoding?query=${searchText}&token=${token}`;
  const data = await apiService.call(apiConstants.METHOD_GET, url, {}, false);
  return transformLocations(data);
};

async function getMapRoutes(lines) {
  try {
    const token = await getToken();
    const linesQuery = lines.map(l => l.reverse().join(',')).join(';');
    const url = `https://tile.aisktg.ru/api/routing/${linesQuery}?token=${token}`;
    const response = await axios.get(url);
    const { data } = response;
    const TYPE_SPRING_ROAD = 2;
    return chain(data)
      .map(d => ({
        id: d.Id,
        name: d.Name,
        type: d.TypeRoute,
        comment: (d.CommentText || ''),
        commentType: (d.CommentText || '').split(' ').findIndex((v) => v == '6') !== -1
            ? TYPE_SPRING_ROAD
            : d.TypeRoute,
        coords: chain(d.GeoJson.coordinates)
          .flatMap(c => (isArray(c[0]) ? c : [c]))
          .map(coord => coord.reverse())
          .value(),
        distance: d.RouteLength
      }))
      .value();
  } catch (e) {
    return [];
  }
}

async function getRestrictedAreas() {
  try {
    const url = `${
      applicationConstants.MAP_URL
    }/service/wfs?service=WFS&request=GetFeature&version=1.0.0&typeName=workspace:goroda_vw&outputFormat=json`;
    const data = await apiService.call(apiConstants.METHOD_GET, url, {}, false);
    return data.features.map(f => ({
      name: f.properties.nazvanie || f.id,
      coordinates: flatten(flatten(f.geometry.coordinates)).map(coord => coord.reverse())
    }));
  } catch (e) {
    return [];
  }
}

async function getApvkgs() {
  try {
    const token = await getToken();
    const url = `${
      applicationConstants.MAP_URL
    }/service/wfs?service=WFS&request=GetFeature&version=1.0.0&typeName=workspace:avtomaticheskii_vesovoi_kontro1609140529199_vw&outputFormat=json&token=${token}`;
    const data = await apiService.call(apiConstants.METHOD_GET, url, {}, false);
    return data.features.map(f => ({
      name: f.properties.nazvanie || f.id,
      coordinates: f.geometry.coordinates[0].reverse()
    }));
  } catch (e) {
    console.log(e);
    return [];
  }
}

const getFullItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}/full`, {}, true);
};

const getApplication = ({ id }) => getFullItem(id);

const getUserTemplates = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/templates`, {}, true);
};
const useTemplate = id => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/use-template/${id}`,
    {},
    true
  );
};

const addControlMark = (id, reverse, post_id) => {
  const values = {
    reverse,
    post_id
  };
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/${id}/add-control-mark`,
    values,
    true
  );
};

const sendToDepartment = ({ id, values }) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/${id}/send-to-department`,
    values,
    true
  );
};
const removeAgreement = ({ id }) => {
  return apiService.call(
    apiConstants.METHOD_DELETE,
    `/${getResourceName()}/remove-agreement/${id}`,
    {},
    true
  );
};

const agreementAccept = id => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getAgreementsResourceName()}/${id}/accept`,
    {},
    true
  );
};
const agreementDecline = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getAgreementsResourceName()}/${values.id}/decline`,
    values,
    true
  );
};

const changeRouteByAdmin = (application_id, waypoints, routeMarkers, segments) => {
  const values = { waypoints, routeMarkers, segments };
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/${application_id}/change-route-info`,
    values,
    true
  );
};

async function checkIsAllowed(lat, lng) {
  try {
    const url = `${
      applicationConstants.MAP_URL
    }/service/wfs?service=WFS&request=GetFeature&version=1.0.0&typeName=workspace:goroda_vw&outputFormat=json&CQL_FILTER=INTERSECTS(the_geom, POINT(${lng} ${lat}))`;
    const data = await apiService.call(apiConstants.METHOD_GET, url, {}, false);
    return data.features && data.features.length === 0;
  } catch (e) {
    console.log(e);
    return true;
  }
}

const getCoordsLocation = async (lat, lon) => {
  const token = await getToken();
  const isAllowed = await checkIsAllowed(lat, lon);
  if (!isAllowed) {
    return { data: null };
  }
  const url = `${applicationConstants.MAP_URL}/geocoding?lon=${lon}&lat=${lat}&token=${token}`;
  const data = await apiService.call(apiConstants.METHOD_GET, url, {}, true);
  const locations = transformLocations(data);
  return {
    data: locations.features[0]
  };
};

const plainGetCoordsLocation = async (lat, lon) => {
  const token = await getToken();
  const url = `${applicationConstants.MAP_URL}/geocoding?lon=${lon}&lat=${lat}&token=${token}`;
  const data = await apiService.call(apiConstants.METHOD_GET, url, {}, true);
  const locations = transformLocations(data);
  return locations.features;
};

const toWorkMultiple = ({ values }) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/to-work-multiple`,
    values,
    true
  );
};

const printPdf = values => {
  const id = values.application_id;
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${getResourceName()}/${id}/print-pdf`,
    values,
    true
  );
};

const resetStatus = values => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/${values.id}/reset-status`,
    values,
    true
  );
};

function removeTagValue(xml, tag, closeTag, emptyTag) {
  const start = xml.indexOf(tag);
  const end = xml.indexOf(closeTag);
  const value = xml.substring(start, end + closeTag.length);
  return xml.replace(value, emptyTag);
}

const getEcpData = (
  applicationId,
  certExport,
  signMethod,
  digestMethod,
  submitData,
  filesSignature
) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/cooperation/${applicationId}/get-data-for-ecp`,
    {
      b64cert: certExport,
      signMethod,
      digestMethod,
      application_data: {
        status: submitData.result,
        comment: submitData.comment,
        special_conditions: submitData.special_conditions
      },
      files_signatures: filesSignature
    },
    true
  );
};

const sendEcpData = (applicationId, signed_xml) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/cooperation/${applicationId}/get-data-for-ecp`,
    { signed_xml },
    true
  );
};

const createInvoice = (applicationId, isSpring) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/cooperation/${applicationId}/get-invoice?is_spring=${isSpring ? 1 : 0}`,
    undefined,
    true
  );
};

const deleteInvoice = applicationId => {
  return apiService.call(
    apiConstants.METHOD_DELETE,
    `/applications/${applicationId}/invoice `,
    undefined,
    true
  );
};

export const applicationService = {
  getUserFiltered,
  getAdminApplications,

  getStatusPage,
  getItem,
  create,
  update,
  delete: _delete,

  saveVehicleStep,
  updateVehicleStep,

  withdraw,
  toAdmin,

  // admin
  toWork,
  accept,
  activate,
  decline,
  restore,
  resetStatus,
  setAdditionalInfoRequired,

  lock,
  unlock,

  // loadStep
  getLoadItem,
  saveLoadStep,

  // routeStep
  getRouteItem,
  saveRouteStep,

  // datesStep
  getDatesItem,
  saveDatesStep,

  // route
  findLocations,
  getMapRoutes,
  getRestrictedAreas,
  getApvkgs,
  checkIsAllowed,
  plainGetCoordsLocation,

  // full info
  getFullItem,
  getApplication,

  getUserTemplates,
  useTemplate,

  getControlFiltered,
  getControlByRDMUUID,

  addControlMark,

  sendToDepartment,
  removeAgreement,

  // agreements
  agreementAccept,
  agreementDecline,

  changeRouteByAdmin,
  getCoordsLocation,

  toWorkMultiple,

  printPdf,

  getEcpData,
  sendEcpData,
  createInvoice,
  deleteInvoice
};
