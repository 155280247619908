import React from 'react';
import Paper from '@material-ui/core/Paper';
import { AdminRoute } from '../../../components/routes/AdminRoute';
import DepartmentsPage from './department/DepartmentsPage';
import { AdminPanelMenu } from '../panel/AdminPanelMenu';

import { UsersBlock } from './UsersBlock';
import ControlPostsPage from './controlPost/ControlPostaPage';

const UsersLayout = props => {
  const { match } = props;

  const usersMenuLinks = [
    { key: 1, path: match.url, title: 'Сотрудники' },
    {
      key: 2,
      path: `${match.url}/departments`,
      title: 'Ведомства для согласования'
    },
    {
      key: 3,
      path: `${match.url}/control-posts`,
      title: 'Посты весового контроля'
    }
  ];

  return (
    <main role="main" className="container">
      <div className="">
        <h2 className="h2-title">Учетные записи сотрудников</h2>

        <AdminPanelMenu links={usersMenuLinks} />
        <Paper>
          <br />
          <AdminRoute exact path={`${match.url}`} component={UsersBlock} />
          <AdminRoute path={`${match.url}/departments`} component={DepartmentsPage} />
          <AdminRoute path={`${match.url}/control-posts`} component={ControlPostsPage} />
          <br />
        </Paper>
      </div>
    </main>
  );
};

export default UsersLayout;
