export const axleLoadConstants = {
  GET_PAGE_REQUEST: 'AXLE_LOAD_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'AXLE_LOAD_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'AXLE_LOAD_GET_PAGE_FAILURE',

  GET_ALL_REQUEST: 'AXLE_LOAD_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'AXLE_LOAD_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'AXLE_LOAD_GET_ALL_FAILURE',

  LOAD_REQUEST: 'AXLE_LOAD_LOAD_REQUEST',
  LOAD_SUCCESS: 'AXLE_LOAD_LOAD_SUCCESS',
  LOAD_FAILURE: 'AXLE_LOAD_LOAD_FAILURE',

  UPDATE_REQUEST: 'AXLE_LOAD_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'AXLE_LOAD_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'AXLE_LOAD_UPDATE_FAILURE',

  FORM_MODAL_OPEN: 'AXLE_LOAD_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'AXLE_LOAD_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'AXLE_LOAD_SET_CURRENT_ITEM'
};
