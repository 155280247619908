import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { routeAddressActions } from '../../../../actions/routeAddressActions';
import RouteAddresssForm from './RouteAddressForm';

const RouteAddressModal = props => {
  const { routeAddresses, handleSubmit, handleModalClose } = props;

  return (
    <Dialog
      open={routeAddresses.showFormModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          {routeAddresses.currentItem ? 'Редактирование адреса' : 'Создание адреса'}
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <RouteAddresssForm currentItem={routeAddresses.currentItem} onSubmit={handleSubmit} />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { routeAddresses } = state;

  return {
    routeAddresses
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { routeAddresses } = stateProps;
  const { dispatch } = dispatchProps;

  const handleSubmit = (values, dispatch) => {
    if (routeAddresses.currentItem && routeAddresses.currentItem.id) {
      return dispatch(routeAddressActions.update(values));
    }
    return dispatch(routeAddressActions.create(values));
  };

  const handleModalClose = () => {
    dispatch(routeAddressActions.closeFormModal());
  };

  return {
    ...ownProps,
    routeAddresses,
    handleSubmit,
    handleModalClose
  };
};

const connectedRouteAddressModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(RouteAddressModal);

export { connectedRouteAddressModal as RouteAddressModal };
