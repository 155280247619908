import React from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, Toolbar, Typography, IconButton, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { userActions } from '../../../actions/userActions';
import UsersFilterForm from './UsersFilterForm';

const UsersFilterComponent = ({ users, filters, getPage, departments, user }) => {
  const [showFilter, setShowFilters] = React.useState(false);
  const close = () => setShowFilters(false);
  const open = () => setShowFilters(true);
  return (
    <>
      <Dialog open={showFilter} aria-labelledby="form-dialog-title">
        <Toolbar className="text-left">
          <Typography variant="title" color="inherit" className="flex">
            Фильтровать
          </Typography>
          <IconButton color="inherit" onClick={close}>
            <CloseIcon className="blue" />
          </IconButton>
        </Toolbar>
        <DialogContent className="dialog">
          <UsersFilterForm
            initialValues={filters}
            departments={departments}
            user={user}
            onSubmit={filter => getPage(1, filter)}
            onReset={() => {
              getPage(1, {});
              close();
            }}
            onClose={close}
          />
        </DialogContent>
      </Dialog>
      <Button className="btn info-btn" onClick={open} disabled={users.loading}>
        Фильтровать
      </Button>
    </>
  );
};

const mapStateToProps = state => {
  const { users, departments, authentication } = state;

  return {
    users,
    departments,
    filters: users.filters,
    user: authentication.user
  };
};

const mapDispatchToProps = {
  getPage: userActions.getPage
};

const connectedUsersFilterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersFilterComponent);
export { connectedUsersFilterForm as UsersFilter };
