import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { change, reduxForm, submit } from 'redux-form';
import { vehicleActions } from '../../../actions/vehicleActions';
import { vehicleConstants } from '../../../constants/vehicleConstants';
import { DeclineModal } from './DeclineModal';
import { ReasonModal } from './ReasonModal';
import { Loader } from '../../../components/Loader';
import { DetailsModal } from './DetailsModal';
import { momentHelper } from '../../../helpers/momentHelper';
import VehicleFilterForm from './VehicleFilterForm';
import TablePagination from '../../../components/TablePagination';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
    // display: 'none'
  }
});

let VehiclesTableList = props => {
  const {
    adminVehicles,
    authentication,
    data,
    meta,
    classes,
    handlePageChange,
    handleToWork,
    handleAccept,
    handleDeclineModal,
    handleReasonModal,
    handleSubmit,
    submitting
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Paper className={classes.root}>
        {adminVehicles && adminVehicles.loading && <Loader />}
        {adminVehicles && adminVehicles.error && (
          <span className="text-danger">Ошибка: {adminVehicles.error}</span>
        )}
        {adminVehicles && adminVehicles.items && !adminVehicles.loading && (
          <div>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {/* general */}
                  <TableCell numeric>№</TableCell>
                  <TableCell>Дата и время</TableCell>
                  <TableCell>Заявитель</TableCell>
                  <TableCell>Тип</TableCell>
                  <TableCell>Марка</TableCell>
                  <TableCell>Модель</TableCell>
                  <TableCell>Номер</TableCell>
                  {adminVehicles.tabIndex === vehicleConstants.STATUS_DECLINE && (
                    <TableCell>Сотрудник, отклонивший заявку</TableCell>
                  )}
                  {adminVehicles.tabIndex === vehicleConstants.STATUS_ACCEPT && (
                    <TableCell>Сотрудник, одобривший заявку</TableCell>
                  )}
                  {adminVehicles.tabIndex === vehicleConstants.STATUS_REVIEW && (
                    <TableCell>Сотрудник, взявший заявку в работу</TableCell>
                  )}
                  <TableCell numeric>Действие</TableCell>
                </TableRow>
                <VehicleFilterForm />
              </TableHead>
              <TableBody>
                {data.map(n => {
                  return (
                    <TableRow key={n.id}>
                      {/* Общие поля */}
                      <TableCell component="th" scope="row" numeric>
                        {n.id}
                      </TableCell>
                      <TableCell>{momentHelper.getLocalDatetime(n.updated_at)}</TableCell>
                      <TableCell className="nowrap">{n.user_name}</TableCell>
                      <TableCell className="nowrap">{n.is_trailer ? 'Прицеп' : 'ТС'}</TableCell>
                      <TableCell>{n.brandTitle}</TableCell>
                      <TableCell>{n.modelTitle}</TableCell>
                      <TableCell>{n.real_number}</TableCell>

                      {(adminVehicles.tabIndex === vehicleConstants.STATUS_REVIEW ||
                        adminVehicles.tabIndex === vehicleConstants.STATUS_ACCEPT ||
                        adminVehicles.tabIndex === vehicleConstants.STATUS_DECLINE) && (
                        <TableCell>{n.admin_name ? n.admin_name : ''}</TableCell>
                      )}

                      <TableCell numeric className="nowrap">
                        <Link to={`vehicles/info/${n.id}`}>
                          <Button variant="outlined" className="info-btn" size="small">
                            Подробнее
                          </Button>
                        </Link>
                        {adminVehicles.tabIndex === vehicleConstants.STATUS_NEW && (
                          <Button className="info-btn" onClick={e => handleToWork(n.id)}>
                            Взять в работу
                          </Button>
                        )}

                        {adminVehicles.tabIndex === vehicleConstants.STATUS_DECLINE && (
                          <Button
                            variant="outlined"
                            className="info-btn"
                            size="small"
                            onClick={e => {
                              handleReasonModal(n.id);
                            }}
                          >
                            Причина
                          </Button>
                        )}

                        {adminVehicles.tabIndex === vehicleConstants.STATUS_ACCEPT && (
                          <Button
                            variant="outlined"
                            className="decline-btn"
                            size="small"
                            onClick={e => {
                              handleDeclineModal(n.id);
                            }}
                          >
                            Отклонить
                          </Button>
                        )}

                        {// adminVehicles.tabIndex === vehicleConstants.STATUS_NEW ||
                        adminVehicles.tabIndex === vehicleConstants.STATUS_REVIEW && ( // ||
                          // (
                          //     adminVehicles.tabIndex === vehicleConstants.STATUS_DECLINE &&
                          //     authentication.user.role_id === roleConstants.ROLE_ADMIN
                          // )
                          <span>
                            <Button
                              variant="outlined"
                              className="accept-btn"
                              size="small"
                              onClick={e => {
                                handleAccept(n.id);
                              }}
                            >
                              Принять
                            </Button>
                            <Button
                              variant="outlined"
                              className="decline-btn"
                              size="small"
                              onClick={e => {
                                handleDeclineModal(n.id);
                              }}
                            >
                              Отклонить
                            </Button>
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}

        <DeclineModal />
        <ReasonModal />
        <DetailsModal />
      </Paper>
      <TablePagination meta={meta} onChangePage={handlePageChange} />
    </form>
  );
};

const mapStateToProps = state => {
  const { adminVehicles, authentication } = state;
  return {
    adminVehicles,
    authentication
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { adminVehicles, authentication } = stateProps;
  const { dispatch } = dispatchProps;

  const handlePageChange = page => {
    // dispatch(vehicleActions.getAdminPage(page + 1, adminVehicles.tabIndex, adminVehicles.roleIndex));
    dispatch(change('vehicle-admin-filter-form', 'page', page));
    setTimeout(() => {
      dispatch(submit('vehicle-admin-filter-form'));
    }, 300);
  };
  const handleToWork = id => {
    return dispatch(vehicleActions.toWork(id));
  };
  const handleAccept = id => {
    dispatch(vehicleActions.accept(id));
  };
  const handleDeclineModal = id => {
    dispatch(vehicleActions.openDeclineModal(id));
  };
  const handleReasonModal = id => {
    dispatch(vehicleActions.openReasonModal(id));
  };
  const initialValues = {
    status: adminVehicles.tabIndex
  };
  return {
    ...ownProps,
    adminVehicles,
    initialValues,
    handlePageChange,
    handleToWork,
    handleAccept,
    handleDeclineModal,
    handleReasonModal,
    authentication
  };
};

VehiclesTableList = reduxForm({
  form: 'vehicle-admin-filter-form',
  enableReinitialize: true,
  destroyOnUnmount: false
})(VehiclesTableList);

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    null,
    mergeProps
  )
)(VehiclesTableList);
