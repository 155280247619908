import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import {Field, Form, Formik, validateYupSchema, yupToFormErrors} from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MateriaInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput'
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import {paymentService} from "../../../services/paymentService";

const DeleteModal = ({isShow, onClose, modelId}) => {
    const [reason, setReason] = useState('')
    return (
        <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="md">
            <Toolbar className="text-center">
                <Typography variant="title" color="inherit" className="flex">
                    {'Укажите причину аннулирования'}
                </Typography>
                <IconButton color="inherit" onClick={onClose}>
                    <CloseIcon className="blue"/>
                </IconButton>
            </Toolbar>

            <DialogContent className="text-center">
                <Formik
                    onSubmit={async (data, {setSubmitting}) => {
                        setSubmitting(true);
                        try {
                            await paymentService.delete(modelId, data.reason);
                            onClose();
                        } catch (e) {
                            if (e.response && e.response.data.errorMessage) alert(e.response.data.errorMessage)
                            else alert("Произошла ошибка при попытке аннулирования")
                        }
                        setSubmitting(false);
                    }}
                    isInitialValid
                    initialValues={
                        {reason}
                    }
                >
                    {({values, isValid, isSubmitting, setFieldValue}) => (
                        <Form>
                            <Grid container direction="column" spacing={16} wrap="nowrap" justify={"center"}>
                                <Grid item className={"text-left"}>
                                    <FieldLabel label="Причина*" name="reason">
                                        <Field name="reason" component={MateriaInput}/>
                                    </FieldLabel>
                                </Grid>
                                <Grid item>
                                    <Button className="btn" disabled={isSubmitting || !isValid} type="submit">
                                        Аннулировать
                                    </Button>
                                    <br/>
                                </Grid>
                            </Grid>

                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>

    )
}

export default DeleteModal