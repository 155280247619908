import React from 'react';

import { applicationActions } from '../../../../../actions/applicationActions';
import { urlHelper } from '../../../../../helpers/urlHelper';
import { ApplicationStepDatesForm } from './ApplicationStepDatesForm';

const vehicleSubmit = (values, dispatch) => {
  return dispatch(applicationActions.saveDatesStep(values));
};

const ApplicationStepDates = props => {
  const { match } = props;

  const id = urlHelper.getIdFromApplicationStepUrl(match.path);

  return (
    <main role="main" className="container">
      <ApplicationStepDatesForm id={id} onSubmit={vehicleSubmit} />
    </main>
  );
};

export default ApplicationStepDates;
