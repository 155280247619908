import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { declOfNum } from '../../../../helpers/formatHelper';

const styles = {
  highlight: {
    backgroundColor: 'rgba(2, 144, 255, 0.2)',
    color: 'rgb(2, 144, 255)'
  }
};

const SelectToolbar = ({ count, takeToWork, classes }) => {
  return (
    <Toolbar className={{ [classes.highlight]: count > 0 }}>
      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          {count > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {count} {declOfNum(count, ['запрос выбран', 'запроса выбрано', 'запросов выбрано'])}
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle">
              Выберите запросы
            </Typography>
          )}
        </Grid>
        <Grid item>
          {count > 0 && (
            <Button onClick={takeToWork} className="btn-add" type="submit">
              Взять в работу
            </Button>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default withStyles(styles)(SelectToolbar);
