import React from 'react';
import { FirmForm } from './FirmForm';

const cabinetSubmit = (values, dispatch) => {};

const CabinetMainPage = props => {
  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-md-9">
          <h2 className="h2-title">Личный кабинет</h2>

          <FirmForm onSubmit={cabinetSubmit} />
        </div>
      </div>
    </main>
  );
};

export default CabinetMainPage;
