import React, { useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Loader } from '../../../../components';
import TablePagination from '../../../../components/TablePagination/TablePagination';
import { getRoadType } from '../coefficient/CoefficientTableList';
import { Grid } from '@material-ui/core';
import { momentHelper } from '../../../../helpers/momentHelper';

const RowSection = ({ name, value }) => (
  <div style={{ marginBottom: 8, marginTop: 8, display: 'flex' }}>
    <b style={{ marginRight: 8 }}>{name}:</b> {value}
  </div>
);

const Row = ({ item, onDelete, onEdit }) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>{item.name}</TableCell>
        <TableCell>{item.equipment_name}</TableCell>
        <TableCell>{item.code}</TableCell>
        <TableCell>{item.equipment_seria_number}</TableCell>
        <TableCell>Стационарный</TableCell>
        <TableCell>{item.owner}</TableCell>
        <TableCell>
          <Grid container spacing={8}>
            <Grid item>
              <Button
                variant="outlined"
                className="info-btn no-margin-left"
                size="small"
                onClick={() => setOpen(!open)}
              >
                <span>{open ? 'Скрыть' : 'Подробнее'}</span>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className="info-btn no-margin-left"
                size="small"
                onClick={() => onEdit(item)}
              >
                <span>Редактировать</span>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className="decline-btn no-margin-left"
                size="small"
                disabled={item.deleting}
                onClick={() => onDelete(item.id)}
              >
                {item.deleting ? <Loader /> : <span>Удалить</span>}
              </Button>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow style={!open ? { height: 0 } : {}}>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            margin: open ? 16 : 0,
            border: open ? undefined : 0
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <RowSection name="Муниципальный район РТ" value={item.district} />
            <RowSection
              name="Географические координаты (широта / долгота)"
              value={`${item.latitude} / ${item.longitude}`}
            />
            <RowSection
              name="Свидетельство об утверждении типа измерения (номер / срок действия)"
              value={`${item.certificate_number} / ${momentHelper.formatDate(
                item.certificate_expired_date
              )}`}
            />
            <RowSection
              name="Свидетельство о поверке оборудования (номер / дата / срок действия)"
              value={`${item.verification_number} / ${momentHelper.formatDate(
                item.verification_date
              )} / ${momentHelper.formatDate(item.verification_expired_date)}`}
            />
            <RowSection
              name="Информация о проверке соотвествия места установки (номер / дата)"
              value={`${item.place_check_number} / ${momentHelper.formatDate(
                item.place_check_date
              )}`}
            />
            <RowSection
              name="Информация о дороге"
              value={
                <div>
                  <div>значение: {getRoadType(item.road_type)}</div>
                  <div>номер: {item.road_number}</div>
                  <div>место фиксации: {item.road_place}</div>
                  <div>направление 1: {item.road_direction1_name}</div>
                  <div>направление 2: {item.road_direction2_name}</div>
                  <div>максимально допустимая нагрузка на одиночную ось: {item.road_load}</div>
                </div>
              }
            />
            <RowSection
              name="Доступ к ftp"
              value={
                <div>
                  <div>{item.ftp_address}</div>
                  <div>логин: {item.ftp_login}</div>
                  <div>пароль: *******</div>
                </div>
              }
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const ApvgkPanel = ({
  data,
  meta,
  isLoading,
  onDelete,
  onChangeRowsPerPage,
  onPageChange,
  onEdit,
  onAdd
}) => (
  <Paper>
    <main role="main" className="container text-left">
      <br />
      <p>
        АПВГК
        <Button aria-label="Добавить" className="btn-add" onClick={onAdd}>
            + Добавить
        </Button>
      </p>
      <div>
        <Paper className="overflow">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Наименование АПВГК</TableCell>
                <TableCell>Наименование оборудования</TableCell>
                <TableCell>Код</TableCell>
                <TableCell>Заводской номер</TableCell>
                <TableCell>Тип оборудования</TableCell>
                <TableCell>Владелец</TableCell>
                <TableCell numeric>Действие</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <Loader />
                  </TableCell>
                </TableRow>
              )}
              {!isLoading && data.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <span>Объекты не найдены</span>
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                data.map(item => (
                  <Row key={item.id} item={item} onDelete={onDelete} onEdit={onEdit} />
                ))}
            </TableBody>
          </Table>
        </Paper>
        <TablePagination
          meta={meta}
          onChangePage={onPageChange}
          onChangeRowsPerPage={onChangeRowsPerPage}
          options={[10, 25, 50]}
        />
      </div>
    </main>
  </Paper>
);

export default ApvgkPanel;
