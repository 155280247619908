import { stringify } from 'query-string';
import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'vehicle-models';
};

const getAll = brand_id => {
  const suffix = brand_id ? `?brand_id=${brand_id}` : '';
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}${suffix}`, {}, true);
};

const getPage = (page, filters = {}, sort = {}) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/page?${stringify({ page, ...filters, ...sort })}`,
    {},
    true
  );
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const create = values => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}`, values, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

const _delete = id => {
  return apiService.call(apiConstants.METHOD_DELETE, `/${getResourceName()}/${id}`, {}, true);
};

const accept = id => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/accept/${id}`, {}, true);
};
const hide = id => {
  return apiService.call(apiConstants.METHOD_POST, `/${getResourceName()}/hide/${id}`, {}, true);
};

export const vehicleModelService = {
  getPage,
  getAll,
  getItem,
  create,
  update,
  delete: _delete,
  accept,
  hide
};
