import React, { useContext } from 'react';
import { compose } from 'redux';
import { useAsync } from 'react-async';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

import Actions from '../components/Actions';
import ContentContainer from './ContentContainer';
import { CooperationStatus } from '../../../../constants/cooperationConstants';
import SelectToolbar from '../components/SelectToolbar';
import { StateContext } from '../components/StateContext';
import { applicationService } from '../../../../services/applicationService';
import { formatHelper } from '../../../../helpers/formatHelper';
import { expireStyles, getDateRange } from '../util/mapping';

const styles = {
  cellWrap: {
    whiteSpace: 'normal',
    workWrap: 'break-word'
  }
};

function NewContent({ enqueueSnackbar, classes }) {
  const { selected, setSelected } = useContext(StateContext);
  const toWork = useAsync({
    deferFn: ([a]) => applicationService.toWorkMultiple(a)
  });

  const onWork = (values, refresh) =>
    toWork
      .run({ values: { towork: values.map(value => String(value)) } })
      .then(({ data: application }) => {
        if (application) {
          refresh();
          setSelected([]);
        } else {
          enqueueSnackbar('Не удалось взять запрос в работу!', { variant: 'error' });
        }
      });

  const handleSelectAllClick = (event, items) => {
    if (event.target.checked) {
      const newSelecteds = items.map(({ id }) => id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  return (
    <ContentContainer
      col={8}
      status={CooperationStatus.New}
      renderPreHeader={({ refresh }) => (
        <SelectToolbar count={selected.length} takeToWork={() => onWork(selected, refresh)} />
      )}
      renderHeader={({ items }) => (
        <TableRow>
          <TableCell>
            <Checkbox
              className="checkbox"
              color="primary"
              inputProps={{ 'aria-label': 'select all desserts' }}
              indeterminate={selected.length > 0 && selected.length < items.length}
              checked={selected.length === items.length}
              onChange={e => handleSelectAllClick(e, items)}
            />
          </TableCell>
          <TableCell>Номер запроса</TableCell>
          <TableCell className={classes.cellWrap}>Дата запроса / Срок ответа</TableCell>
          <TableCell>Заявитель</TableCell>
          <TableCell className={classes.cellWrap}>Наименование организации владельца ТС</TableCell>
          <TableCell
            style={{
              whiteSpace: 'normal',
              wordWrap: 'break-word'
            }}
          >
            № документа, присвоенный заявителем
          </TableCell>
          <TableCell align="right">Действие</TableCell>
        </TableRow>
      )}
      renderBody={({ items, refresh }) => {
        return items.map((item, index) => {
          const isItemSelected = isSelected(item.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <TableRow
              key={item.id}
              onClick={event => handleClick(event, item.id)}
              selected={isItemSelected}
              role="checkbox"
              aria-checked={isItemSelected}
              hover
              {...expireStyles(item.smev)}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  className="checkbox"
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
              <TableCell className="nowrap">{`№${formatHelper.appNumber(item)}`}</TableCell>
              <TableCell>{getDateRange(item.smev)}</TableCell>
              <TableCell>{item.smev.authority_name}</TableCell>
              <TableCell>{item.username}</TableCell>
              <TableCell>{item.smev.output_number}</TableCell>
              <TableCell align="right" className="nowrap" onClick={e => e.stopPropagation()}>
                <Actions id={item.id} action={() => onWork([item.id], refresh)} />
              </TableCell>
            </TableRow>
          );
        });
      }}
    />
  );
}

export default compose(
  withStyles(styles),
  withSnackbar
)(NewContent);
