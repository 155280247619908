import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import { getIsShowActivateModal } from '../../../selectors/applicationSelectors';
import * as actions from '../../../actions/newApplicationActions';

const ApplicationActivateConfirm = ({
  showActivateModal,
  activateApplication,
  setShowActivateModal,
  enqueueSnackbar
}) => {
  const onClose = () => setShowActivateModal(false);
  return (
    <ConfirmationModal
      title="Подтверждение активации"
      description="Вы уверены, что данное разрешение оплачено Заявителем?"
      handleSubmit={() => {
        onClose();
        activateApplication()
          .then(() => enqueueSnackbar('Заявление активировано', { variant: 'success' }))
          .catch(() =>
            enqueueSnackbar('Произошла ошибка при активации заявления', { variant: 'error' })
          );
      }}
      isShow={showActivateModal}
      handleClose={onClose}
    />
  );
};

const mapStateToProps = state => ({
  showActivateModal: getIsShowActivateModal(state)
});

const mapDispatchToProps = {
  setShowActivateModal: actions.setShowActivateModal,
  activateApplication: actions.activateApplication
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ApplicationActivateConfirm));
