import React from 'react';
import { Field, FieldArray } from 'formik';
import { uniqueId, range } from 'lodash';
import Grid from '@material-ui/core/Grid';
import Section from './components/Section';
import MaterialInput from './components/MaterialInput';
import FieldLabel from './components/FieldLabel';
import { EscortTitle } from '../../../../helpers/dictionaryHelper';
import TransportNumberInput from './components/TransportNumberInput';

const computeEscortCount = (length, width, height) => {
  if (width > 0 && width <= 3.5) {
    if (length >= 25 && length <= 40) {
      return 2;
    }
    if (length > 40) {
      return 3;
    }
  }

  if (width > 3.5 && width <= 4.5) {
    if (length <= 40) {
      return 2;
    }
    if (length > 40) {
      return 3;
    }
  }
  if (width >= 4.5 && width <= 5) {
    return 3;
  }

  if (height >= 4.5) {
    return 1;
  }
  // @todo spec if width > 5
  if (width > 5) {
    return 3;
  }

  return 0;
};

const Escort = ({ values, setFieldValue }) => {
  const { length, height, width } = values.dimensions;
  React.useEffect(() => {
    const escortCount = computeEscortCount(
      Number.parseFloat(length || '0'),
      Number.parseFloat(width || '0'),
      Number.parseFloat(height || '0')
    );
    setFieldValue('escortCount', escortCount);
    setFieldValue(
      'escort',
      range(0, escortCount).map((_, index) => ({
        id: -uniqueId(),
        ...(values.escort[index] || {})
      }))
    );
  }, [length, width, height]);
  return (
    <Section title="Сопровождение">
      <Grid item>
        <FieldLabel label="Габариты автопоезда*" name="dimensions">
          <Grid container alignItems="left">
            <Grid item xs={2} className="text-left sizes">
              <span>длина</span>
            </Grid>
            <Grid item xs={2} className>
              <Field fieldType="number" name="dimensions.length" component={MaterialInput} />
            </Grid>
            <Grid item xs={2} className="text-center sizes">
              <span>ширина</span>
            </Grid>
            <Grid item xs={2}>
              <Field fieldType="number" name="dimensions.width" component={MaterialInput} />
            </Grid>
            <Grid item xs={2} className="text-center sizes">
              <span>высота</span>
            </Grid>
            <Grid item xs={2}>
              <Field fieldType="number" name="dimensions.height" component={MaterialInput} />
            </Grid>
          </Grid>
        </FieldLabel>
      </Grid>
      <Grid item>
        <FieldLabel label="Сопровождение">
          <span className="border">{EscortTitle[values.escortCount].title}</span>
        </FieldLabel>
      </Grid>
      <FieldArray
        name="escort"
        render={() => (
          <React.Fragment>
            {values.escort.map((escort, index) => (
              <React.Fragment key={escort.id}>
                <Grid item>
                  <FieldLabel label={<b>{`Автомобиль прикрытия ${index + 1}`}</b>} />
                </Grid>
                <Grid item>
                  <FieldLabel label="Марка автомобиля прикрытия">
                    <Field name={`escort[${index}].brand`} component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="Модель автомобиля прикрытия">
                    <Field name={`escort[${index}].model`} component={MaterialInput} />
                  </FieldLabel>
                </Grid>
                <Grid item>
                  <FieldLabel label="ГРЗ автомобиля прикрытия">
                    <Field name={`escort[${index}].number`} component={TransportNumberInput} />
                  </FieldLabel>
                </Grid>
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      />
    </Section>
  );
};

export default Escort;
