import React from 'react';
import { Link, withRouter } from 'react-router-dom';

let ApplicationSteps = ({ match, location }) => {
  const links = [
    { path: `${match.url}/vehicle`, title: 'Выбор ТС и прицепа' },
    { path: `${match.url}/load`, title: 'Задание характеристик груза' },
    { path: `${match.url}/route`, title: 'Задание<br/> маршрута' },
    { path: `${match.url}/dates`, title: 'Выбор срока<br/> действия' }
  ];

  return (
    <div className="row text-center steps">
      <div className={`col-md-2${location.pathname === links[0].path ? ' active_step' : ''}`}>
        <Link to={links[0].path}>
          Выбор ТС
          <br /> и прицепа
        </Link>
      </div>
      <div className={`col-md-1${location.pathname === links[0].path ? ' active_step' : ''}`}>
        <hr />
      </div>
      <div className={`col-md-2${location.pathname === links[1].path ? ' active_step' : ''}`}>
        {match.params.id && <Link to={links[1].path}>Задание характеристик груза</Link>}
        {!match.params.id && <div>Задание характеристик груза</div>}
      </div>
      <div className={`col-md-1${location.pathname === links[1].path ? ' active_step' : ''}`}>
        <hr />
      </div>
      <div className={`col-md-2${location.pathname === links[2].path ? ' active_step' : ''}`}>
        {match.params.id && (
          <Link to={links[2].path}>
            Задание
            <br /> маршрута
          </Link>
        )}
        {!match.params.id && (
          <div>
            Задание
            <br /> маршрута
          </div>
        )}
      </div>
      <div className={`col-md-1${location.pathname === links[2].path ? ' active_step' : ''}`}>
        <hr />
      </div>
      <div
        className={`col-md-2${location.pathname === links[3].path ? ' active_step' : ''} last_step`}
      >
        {match.params.id && (
          <Link to={links[3].path}>
            Выбор срока
            <br /> действия
          </Link>
        )}
        {!match.params.id && (
          <div>
            Выбор срока
            <br /> действия
          </div>
        )}
      </div>
    </div>
  );
};

ApplicationSteps = withRouter(ApplicationSteps);
export default ApplicationSteps;
