import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { TableRow } from 'material-ui';
import TableCell from '@material-ui/core/TableCell';
import Filter from '@material-ui/icons/FilterList';
import { Loader } from '../../../components/Loader';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { renderSelectFieldNoLabel } from '../../../components/form/renderSelectFieldNoLabel';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { vehicleConstants } from '../../../constants/vehicleConstants';

let VehiclesFilterForm = props => {
  const { vehicles, handleSubmit, submitting, adminVehicles } = props;

  return (
    <TableRow>
      <TableCell>
        <Filter />
      </TableCell>
      <TableCell>
        <Field
          name="date"
          type="date"
          label="Дата и время"
          className="form_input"
          component={FormFieldReg}
        />
      </TableCell>

      <TableCell>
        <Field
          name="user_name"
          type="text"
          label="Заявитель"
          className="form_input"
          component={FormFieldReg}
        />
      </TableCell>

      <TableCell className="select-type">
        <Field className="" name="type" label="Тип ТС" component={renderSelectFieldNoLabel}>
          <option key="0" value="">
            Тип тс
          </option>
          {dictionaryHelper.isTrailer.getList().map(option => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </Field>
      </TableCell>

      <TableCell>
        <Field
          name="brand"
          type="text"
          label="Марка"
          className="form_input"
          component={FormFieldReg}
        />
      </TableCell>

      <TableCell>
        <Field
          name="model"
          type="text"
          label="Модель"
          className="form_input"
          component={FormFieldReg}
        />
      </TableCell>

      <TableCell>
        <Field
          name="number"
          type="text"
          label="Номер"
          className="form_input"
          component={FormFieldReg}
        />
      </TableCell>

      {(adminVehicles.tabIndex === vehicleConstants.STATUS_REVIEW ||
        adminVehicles.tabIndex === vehicleConstants.STATUS_ACCEPT ||
        adminVehicles.tabIndex === vehicleConstants.STATUS_DECLINE) && (
        <TableCell>
          <Field
            name="admin_name"
            type="text"
            label=""
            className="form_input"
            component={FormFieldReg}
          />
        </TableCell>
      )}

      <TableCell numeric>
        <Button
          variant="contained"
          color="primary"
          className="btn-add"
          type="submit"
          disabled={submitting}
        >
          {submitting && <Loader />} Применить
        </Button>
      </TableCell>
    </TableRow>
  );
};

const mapStateToProps = state => {
  const { authentication, vehicles, adminVehicles } = state;
  const { user } = authentication;
  return {
    vehicles,
    adminVehicles,
    initialValues: {
      department_id: user.department_id
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

VehiclesFilterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclesFilterForm);
export default VehiclesFilterForm;
