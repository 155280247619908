export const routeAddressConstants = {
  GET_PAGE_REQUEST: 'ROUTE_ADDRESS_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'ROUTE_ADDRESS_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'ROUTE_ADDRESS_GET_PAGE_FAILURE',

  GET_ALL_REQUEST: 'ROUTE_ADDRESS_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'ROUTE_ADDRESS_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'ROUTE_ADDRESS_GET_ALL_FAILURE',

  LOAD_REQUEST: 'ROUTE_ADDRESS_LOAD_REQUEST',
  LOAD_SUCCESS: 'ROUTE_ADDRESS_LOAD_SUCCESS',
  LOAD_FAILURE: 'ROUTE_ADDRESS_LOAD_FAILURE',

  CREATE_REQUEST: 'ROUTE_ADDRESS_CREATE_REQUEST',
  CREATE_SUCCESS: 'ROUTE_ADDRESS_CREATE_SUCCESS',
  CREATE_FAILURE: 'ROUTE_ADDRESS_CREATE_FAILURE',

  UPDATE_REQUEST: 'ROUTE_ADDRESS_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'ROUTE_ADDRESS_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'ROUTE_ADDRESS_UPDATE_FAILURE',

  DELETE_REQUEST: 'ROUTE_ADDRESS_DELETE_REQUEST',
  DELETE_SUCCESS: 'ROUTE_ADDRESS_DELETE_SUCCESS',
  DELETE_FAILURE: 'ROUTE_ADDRESS_DELETE_FAILURE',

  FORM_MODAL_OPEN: 'ROUTE_ADDRESS_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'ROUTE_ADDRESS_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'ROUTE_ADDRESS_SET_CURRENT_ITEM',

  SET_SUGGESTIONS: 'ROUTE_ADDRESS_SET_SUGGESTIONS',
  SET_VALUE: 'ROUTE_ADDRESS_SUGGESTIONS',
  SEARCH_LOCATION_REQUEST: 'ROUTE_ADDRESS_SEARCH_LOCATION_REQUEST',
  SEARCH_LOCATION_SUCCESS: 'ROUTE_ADDRESS_SEARCH_LOCATION_SUCCESS',
  SEARCH_LOCATION_FAILURE: 'ROUTE_ADDRESS_SEARCH_LOCATION_FAILURE'
};
