import { get } from 'lodash';

import { applicationConstants } from '../constants/applicationConstants';
import { authConstants } from '../constants/authConstants';

const findFirstEmptyCoords = markers => {
  for (let i = 0; i < markers.length; i++) {
    if (!markers[i].coords) {
      return i;
    }
  }
  return null;
};
const findFirstEmptyText = markers => {
  for (let i = 0; i < markers.length; i++) {
    if (!markers[i].text) {
      return i;
    }
  }
  return null;
};

const addCoordsMarkerToList = (markers, coords) => {
  const firstEmpty = findFirstEmptyCoords(markers);
  if (firstEmpty === null) {
    return [
      ...markers,
      {
        ...emptyMarker,
        coords
      }
    ];
  }

  return markers.map((marker, index) => {
    return index === firstEmpty
      ? {
          ...marker,
          coords,
          toUpdate: true
        }
      : marker;
  });
};

const addMarkerToList = (markers, newMarker) => {
  let replaceIndex = null;
  for (let i = 0; i < markers.length && replaceIndex === null; i++) {
    if (!markers[i].isEmpty) {
      replaceIndex = i;
    }
  }

  if (replaceIndex !== null) {
    const newArray = [];
    for (let i = 0; i < replaceIndex && i < markers.length; i++) {
      newArray.push(markers[i]);
    }
    newArray.push(newMarker);
    for (let i = replaceIndex + 1; i < markers.length; i++) {
      newArray.push(markers[i]);
    }
    return newArray;
  }

  return [...markers, newMarker];
};

const parseSuggestions = mapBoxSuggestions => {
  if (mapBoxSuggestions.length > 0) {
    return mapBoxSuggestions.map(suggestion => ({
      lat: suggestion.coords.lat,
      lon: suggestion.coords.lon,
      display_name: suggestion.title
    }));
  }

  return [];
};

const setRouteMarkers = route => {
  let markers = [];
  if (route) {
    markers = JSON.parse(route.points);
  } else {
    markers = [];
  }

  if (markers.length === 0) {
    return [emptyMarker, emptyMarker];
  }
  for (let i = 0; i < markers.length; i++) {
    if (!markers[i].text) {
      markers[i].text = '';
    }
  }
  return markers;
};

const emptyMarker = {
  suggestions: [],
  text: '',
  coords: null,
  isEmpty: true
};

const initialState = {
  tabIndex: applicationConstants.STATUS_NEW,
  actionLoading: false,
  loading: false,
  showFormModal: false,
  showMyVehiclesModal: false,

  currentItem: null,
  meta: { per_page: 7, total: 0, current_page: 1 },
  items: [],
  rdmapplication: null,
  templates: [],

  searchVehicles: {
    meta: { per_page: 7, total: 0, current_page: 1 },
    items: []
  },

  pickStep: {
    isTrailer: false,
    trailerIndex: 0,
    vehicle: null,
    trailers: [{ id: 0 }],
    status: { id: 0 }
  },

  loadStep: {
    load: null,
    loadAxles: [],
    escort: [],
    editableDistances: []
  },

  routeStep: {
    route: null,
    markers: [emptyMarker, emptyMarker],
    mapCenter: [55.211, 50.634],
    zoomLevel: 7
  },

  datesStep: {
    dates: null
  },

  uploadLoadError: '',
  loadUploaders: [],

  uploadPenaltyError: '',
  penaltyUploaders: [],

  uploadPayError: '',
  payUploaders: []
};

export const userApplications = (state = initialState, action) => {
  switch (action.type) {
    case applicationConstants.SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex
      };
    // Get filtered list
    case applicationConstants.GET_USER_FILTERED_REQUEST:
      return {
        ...state,
        loading: true,
        items: []
      };
    case applicationConstants.GET_USER_FILTERED_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.applications.data,
        meta: action.applications.meta,
        showFormModal: false
      };
    case applicationConstants.GET_USER_FILTERED_FAILURE:
      return {
        ...state,
        loading: false
      };

    // Get filtered list
    case applicationConstants.GET_CONTROL_FILTERED_REQUEST:
      return {
        ...state,
        loading: true
      };
    case applicationConstants.GET_CONTROL_FILTERED_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.applications.data,
        showFormModal: false
      };
    case applicationConstants.GET_CONTROL_FILTERED_FAILURE:
      return {
        ...state,
        loading: false
      };

    // Get by uuid
    case applicationConstants.GET_CONTROL_BYUUID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case applicationConstants.GET_CONTROL_BYUUID_SUCCESS:
      return {
        ...state,
        loading: false,
        rdmapplication: action.application,
      };
    case applicationConstants.GET_CONTROL_BYUUID_FAILURE:
      return {
        ...state,
        loading: false
      };

    // Get item
    case applicationConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case applicationConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.application.data
      };
    case applicationConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // form modal
    case applicationConstants.FILTER_MODAL_OPEN:
      return {
        ...state,
        showFormModal: true
      };
    case applicationConstants.FILTER_MODAL_CLOSE:
      return {
        ...state,
        showFormModal: false
      };

    case applicationConstants.MY_VEHICLES_MODAL_OPEN:
      return {
        ...state,
        showMyVehiclesModal: true
      };
    case applicationConstants.MY_VEHICLES_MODAL_CLOSE:
      return {
        ...state,
        showMyVehiclesModal: false
      };

    // Get vehicle search list
    case applicationConstants.GET_VEHICLE_SEARCH_REQUEST:
      return {
        ...state,
        vehicleLoading: true
      };
    case applicationConstants.GET_VEHICLE_SEARCH_SUCCESS:
      return {
        ...state,
        vehicleLoading: false,
        searchVehicles: {
          items: action.vehicles.data,
          meta: action.vehicles.meta
        }
      };
    case applicationConstants.GET_VEHICLE_SEARCH_FAILURE:
      return {
        ...state,
        vehicleLoading: false
      };

    // Pick vehicle
    case applicationConstants.SET_APPLICATION_IS_TRAILER:
      return {
        ...state,
        pickStep: {
          ...state.pickStep,
          isTrailer: action.isTrailer
        }
      };
    case applicationConstants.SET_APPLICATION_TRAILER_INDEX:
      return {
        ...state,
        pickStep: {
          ...state.pickStep,
          isTrailer: 1,
          trailerIndex: action.index
        }
      };

    // set vehicle or trailer with index
    case applicationConstants.SET_APPLICATION_VEHICLE:
      return {
        ...state,
        pickStep: {
          ...state.pickStep,
          vehicle: action.vehicle
        }
      };
    case applicationConstants.SET_APPLICATION_VEHICLE_TYPE:
      return {
        ...state,
        pickStep: {
          ...state.pickStep,
          isTrailer: action.isTrailer,
          trailerIndex: action.index
        }
      };
    case applicationConstants.ADD_APPLICATION_TRAILER:
      return {
        ...state,
        pickStep: {
          ...state.pickStep,
          trailers: [...state.pickStep.trailers, { id: 1 }]
        }
      };

    case applicationConstants.RESET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: null,
        routeStep: {
          ...state.routeStep
        }
      };
    case applicationConstants.LOAD_FULL_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.application.data
      };

    case applicationConstants.WITHDRAW_REQUEST:
      return {
        ...state,
        actionLoading: true
      };
    case applicationConstants.WITHDRAW_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        currentItem: action.application,
        items: state.items.filter(item => {
          return item.id !== action.application.id;
        })
      };
    case applicationConstants.WITHDRAW_FAILURE:
      return {
        ...state,
        actionLoading: false
      };

    case applicationConstants.TO_ADMIN_REQUEST:
      return {
        ...state,
        actionLoading: true
      };
    case applicationConstants.TO_ADMIN_SUCCESS:
      return {
        ...state,
        actionLoading: false,
        currentItem: action.application,
        items: state.items.map(item => {
          if (item.id === action.application.id) {
            return action.application;
          }
          return item;
        })
      };
    case applicationConstants.TO_ADMIN_FAILURE:
      return {
        ...state,
        actionLoading: false
      };

    case applicationConstants.CHECK_STATUS_REQUEST:
      return {
        ...state,
        pickStep: {
          ...state.pickStep,
          status: { id: 0 }
        }
      };
    case applicationConstants.CHECK_STATUS_SUCCESS:
      return {
        ...state,
        pickStep: {
          ...state.pickStep,
          status: action.status
        }
      };

    // load Step
    case applicationConstants.LOAD_LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case applicationConstants.LOAD_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loadStep: {
          ...state.loadStep,
          load: action.load
        },
        currentItem: action.application
      };
    case applicationConstants.LOAD_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // route Step
    case applicationConstants.LOAD_ROUTE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case applicationConstants.LOAD_ROUTE_SUCCESS:
      return {
        ...state,
        loading: false,
        routeStep: {
          ...state.routeStep,
          route: action.route,
          markers: setRouteMarkers(action.route)
        },
        currentItem: action.application
      };
    case applicationConstants.LOAD_ROUTE_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // dates Step
    case applicationConstants.LOAD_DATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case applicationConstants.LOAD_DATES_SUCCESS:
      return {
        ...state,
        loading: false,
        datesStep: {
          ...state.datesStep,
          dates: action.dates,
          load: action.load,
          route: action.route
        },
        currentItem: action.application
      };
    case applicationConstants.LOAD_DATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    case applicationConstants.SET_LOAD_AXLES:
      return {
        ...state,
        loadStep: {
          ...state.loadStep,
          loadAxles: action.axles
        }
      };
    case applicationConstants.SET_ESCORT:
      return {
        ...state,
        loadStep: {
          ...state.loadStep,
          escort: action.escort
        }
      };

    case applicationConstants.SET_EDITABLE_DISTANCES:
      return {
        ...state,
        loadStep: {
          ...state.loadStep,
          editableDistances: action.distances
        }
      };

    // locations
    case applicationConstants.SET_ROUTE_MARKERS:
      return {
        ...state,
        routeStep: {
          ...state.routeStep,
          markers: action.markers
        }
      };

    case applicationConstants.SET_SUGGESTIONS:
      return {
        ...state,
        routeStep: {
          ...state.routeStep,
          markers: state.routeStep.markers.map((marker, index) =>
            index === action.index
              ? { ...marker, suggestions: parseSuggestions(action.suggestions) }
              : marker
          )
        }
      };
    case applicationConstants.SET_LOCATION:
      return {
        ...state,
        routeStep: {
          ...state.routeStep,
          markers: state.routeStep.markers.map((marker, index) =>
            index === action.index ? { ...marker, text: action.value } : marker
          )
        }
      };
    case applicationConstants.SET_COORDS:
      return {
        ...state,
        routeStep: {
          ...state.routeStep,
          markers: state.routeStep.markers.map((marker, index) =>
            index === action.index
              ? {
                  ...marker,
                  coords: { lat: action.value.lat, lon: action.value.lon }
                }
              : marker
          )
        }
      };

    case applicationConstants.SET_MAP_COORDS:
      return {
        ...state,
        routeStep: {
          ...state.routeStep
        }
      };
    case applicationConstants.SET_MAP_ZOOM:
      return {
        ...state,
        routeStep: {
          ...state.routeStep,
          mapZoom: action.zoom
        }
      };

    // map
    case applicationConstants.ADD_MARKER:
      return {
        ...state,
        routeStep: {
          ...state.routeStep,
          markers: addCoordsMarkerToList(state.routeStep.markers, action.coords)
          // markers: [
          //     ...state.routeStep.markers,
          //     newMarker
          // ]
        }
      };
    case applicationConstants.REMOVE_MARKER:
      return {
        ...state,
        routeStep: {
          ...state.routeStep,
          markers: state.routeStep.markers.filter((marker, idx) => idx !== action.index)
        }
      };

    case applicationConstants.GET_COORDS_LOCATION_SUCCESS:
      const toUpdateIndex = state.routeStep.markers.findIndex(m => m.toUpdate);
      const firstEmptyMarker =
        toUpdateIndex < 0 ? findFirstEmptyText(state.routeStep.markers) : toUpdateIndex;
      const replaceIndex =
        firstEmptyMarker === null ? state.routeStep.markers.length - 1 : firstEmptyMarker;
      return {
        ...state,
        routeStep: {
          ...state.routeStep,
          markers: state.routeStep.markers.map((item, index) => {
            const markerIndex = action.index === null ? replaceIndex : action.index;
            return index !== markerIndex
              ? item
              : {
                  ...item,
                  toUpdate: false,
                  isEmpty: false,
                  text: action.data.text,
                  coords: action.move ? action.data.coords : state.routeStep.markers[index].coords
                };
          })
        }
      };

    // Remove
    // Delete
    case applicationConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(application =>
          application.id === action.id ? { ...application, deleting: true } : application
        )
      };
    case applicationConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.filter(user => user.id !== action.id)
      };
    case applicationConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        items: state.items.map(application => {
          if (application.id === action.id) {
            // make copy of HeaderMenu without 'deleting:true' property
            const { deleting, ...applicationCopy } = application;
            // return copy of HeaderMenu with 'deleteError:[error]' property
            return { ...applicationCopy, deleteError: action.error };
          }

          return application;
        })
      };

    case applicationConstants.DELETE_TEMPLATE_REQUEST:
      return {
        ...state,
        templates: state.templates.map(application =>
          application.id === action.id ? { ...application, deleting: true } : application
        )
      };
    case applicationConstants.DELETE_TEMPLATE_SUCCESS:
      // remove deleted HeaderMenu from state
      return {
        ...state,
        templates: state.templates.filter(user => user.id !== action.id)
      };
    case applicationConstants.DELETE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        templates: state.templates.map(application => {
          if (application.id === action.id) {
            // make copy of HeaderMenu without 'deleting:true' property
            const { deleting, ...applicationCopy } = application;
            // return copy of HeaderMenu with 'deleteError:[error]' property
            return { ...applicationCopy, deleteError: action.error };
          }

          return application;
        })
      };

    case applicationConstants.GET_USER_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case applicationConstants.GET_USER_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.applications.data
      };
    case applicationConstants.GET_USER_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case applicationConstants.LOAD_UPLOAD_REQUEST:
      return {
        ...state,
        uploadLoadError: false,
        loadUploaders: [
          ...state.loadUploaders,
          {
            uploaderId: action.uploaderId,
            name: action.name
          }
        ]
      };
    case applicationConstants.LOAD_UPLOAD_FAILURE:
      return {
        ...state,
        uploadLoadError: true,
        loadUploaders: state.loadUploaders.filter(item => item.uploaderId !== action.uploaderId)
      };
    case applicationConstants.LOAD_PENALTY_SUCCESS:
      return {
        ...state,
        uploadPenaltyError: false,
        penaltyUploaders: state.penaltyUploaders.filter(
          item => item.uploaderId !== action.uploaderId
        )
      };
    case applicationConstants.LOAD_PENALTY_FAILURE:
      return {
        ...state,
        uploadPenaltyError: true,
        penaltyUploaders: (state.penaltyUploaders || []).filter(
          item => item.uploaderId !== action.uploaderId
        )
      };

    case applicationConstants.LOAD_UPLOAD_SUCCESS:
      return {
        ...state,
        loadUploaders: state.loadUploaders.filter(item => item.uploaderId !== action.uploaderId)
      };
    case applicationConstants.LOAD_REMOVE: {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          files: state.currentItem.files
            ? {
                ...state.currentItem.files,
                load_files: get(state, 'currentItem.load_files', []).filter(
                  item => item.id !== action.id
                )
              }
            : state.currentItem.files
        }
      };
    }

    case applicationConstants.PAY_UPLOAD_REQUEST:
      return {
        ...state,
        uploadLoadError: false,
        payUploaders: [
          ...state.payUploaders,
          {
            uploaderId: action.uploaderId,
            name: action.name
          }
        ]
      };
    case applicationConstants.PAY_UPLOAD_FAILURE:
      return {
        ...state,
        uploadLoadError: true,
        payUploaders: state.payUploaders.filter(item => item.uploaderId !== action.uploaderId)
      };

    case applicationConstants.PAY_UPLOAD_SUCCESS:
      return {
        ...state,
        currentItem: state.currentItem
          ? {
              ...state.currentItem,
              files: {
                ...state.currentItem.files,
                pay_files: [...get(state, 'currentItem.files.pay_files', []), action.data]
              }
            }
          : state.currentItem,
        payUploaders: state.payUploaders.filter(item => item.uploaderId !== action.uploaderId),
        items: (state.items || []).map(item =>
          item.id === action.application_id
            ? {
                ...item,
                files: {
                  ...item.files,
                  pay_files: [...get(item, 'files.pay_files', []), action.data]
                }
              }
            : item
        )
      };
    case applicationConstants.PAY_FILE_REMOVE_SUCCESS: {
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          files: state.currentItem.files
            ? {
                ...state.currentItem.files,
                pay_files: get(state, 'currentItem.files.pay_files', []).filter(
                  item => item.id !== action.data
                )
              }
            : state.currentItem.files
        }
      };
    }

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
