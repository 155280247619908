import React from 'react';
import { Field } from 'formik';
import Grid from '@material-ui/core/Grid';
import Section from './components/Section';
import SelectBrand from './components/SelectBrand';
import SelectModel from './components/SelectModel';
import FieldLabel from './components/FieldLabel';
import MaterialInput from './components/MaterialInput';
import { createAxles, setTransportInfo } from './utils';
import TransportNumberInput from './components/TransportNumberInput';

const TransportInfo = ({ values, setFieldValue, setFieldTouched, axleLoads }) => (
  <Section title="Информация о ТС">
    <Grid item>
      <FieldLabel label="Марка ТС*" name="transport.brand">
        <Field
          name="transport.brand"
          component={SelectBrand}
          afterSelect={() => setFieldValue('transport.model', null)}
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Модель ТС*" name="transport.model">
        <Field
          name="transport.model"
          component={SelectModel}
          brand={values.transport.brand}
          isDisabled={!values.transport.brand}
          afterSelect={model => {
            if (model.id < 1) {
              return;
            }
            setTransportInfo(model, `transport`, setFieldValue, axleLoads, values);
          }}
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="ГРЗ ТС*" name="transport.number">
        <Field name="transport.number" component={TransportNumberInput} />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Регион*" name="transport.region">
        <Field
          name="transport.region"
          component={MaterialInput}
          fieldType="number"
          disableDecimal
          maxLength={3}
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Количество осей*" name="transport.axlesCount">
        <Field
          name="transport.axlesCount"
          component={MaterialInput}
          fieldType="number"
          step="1"
          min={0}
          afterChange={v => {
            const value = v ? Number.parseFloat(v) : '';
            setFieldValue('transport.axles', v ? createAxles(value) : []);
            setFieldValue('transport.axlesCount', value);
          }}
          disableDecimal
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Масса ТС без груза*" name="transport.initialWeight">
        <Field
          name="transport.initialWeight"
          component={MaterialInput}
          fieldType="number"
          min={0}
        />
      </FieldLabel>
    </Grid>
    <Grid item>
      <FieldLabel label="Разрешенная максимальная масса*" name="transport.maxWeight">
        <Field
          name="transport.maxWeight"
          component={MaterialInput}
          fieldType="number"
          min={0}
          afterChange={() => setFieldTouched('totalWeight', true, true)}
        />
      </FieldLabel>
    </Grid>
  </Section>
);

export default TransportInfo;
