import React from 'react';

export const FormFieldReg = ({
  readonly,
  disabled,
  input,
  className,
  placeholder,
  type,
  meta: { touched, error }
}) => (
  <div className={`form-group${touched && error ? ' has-error' : ''}`}>
    <div className="">
      <input
        disabled={disabled}
        readOnly={readonly}
        className={`form-control ${className}${touched && error ? ' form-control-error' : ''}`}
        {...input}
        placeholder={placeholder}
        type={type}
      />
      {touched && (error && <span>{error}</span>)}
    </div>
  </div>
);
