import { applicationConstants } from '../constants/applicationConstants';
import { roleConstants } from '../constants/roleConstants';
import { authConstants } from '../constants/authConstants';
import { routeAddressConstants } from '../constants/routeAddressConstants';

const findItem = (items, id) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      return items[i];
    }
  }
  return null;
};

const removeItem = (items, id) => {
  return items.filter(item => item.id !== id);
};

const initialState = {
  tabIndex: applicationConstants.STATUS_NEW,
  roleIndex: roleConstants.ROLE_INDIVIDUAL,
  tabs: [
    [{}, {}], // individual and firm data for status = new
    [{}, {}],
    [{}, {}],
    [{}, {}]
  ],
  showActivateModal: false,
  showDeclineModal: false,
  showAgreementDeclineModal: false,
  showReasonModal: false,
  showDetailsModal: false,
  meta: {},
  items: [],
  currentItem: null,
  statusLoading: false,

  showFilterModal: false,
  showPrintModal: false,

  map: null,
  plan: null,
  routing: null,
  addingMark: false,

  markError: '',

  adminRoute: {
    routeChanged: false,
    waypoints: [],
    markers: [],
    apvgks: [],
  },

  printLink: null,
  printError: null,

  routeSaved: false,
  editMap: false
};

const setNewRoute = (oldMarkers, newMarkers) => {
  if (!oldMarkers || oldMarkers.length === 0) {
    return newMarkers;
  }
  const firstText = oldMarkers[0].text;
  const lastText = oldMarkers[oldMarkers.length - 1].text;

  if (newMarkers.length > 0) {
    newMarkers[0].text = firstText;
    newMarkers[newMarkers.length - 1].text = lastText;
  }

  return newMarkers;
};

export const adminApplications = (state = initialState, action) => {
  switch (action.type) {
    case applicationConstants.SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex
      };
    case applicationConstants.SET_ROLE_INDEX:
      return {
        ...state,
        roleIndex: action.roleIndex
      };

    case applicationConstants.GET_BY_STATUSES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case applicationConstants.GET_BY_STATUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.applications.data,
        meta: action.applications.meta
      };
    case applicationConstants.GET_BY_STATUSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить список заявок!'
      };

    // Get item
    case applicationConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case applicationConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.application.data,
        adminRoute: {
          ...state.adminRoute,
          routeChanged: false
        }
      };
    case applicationConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // Get item
    case applicationConstants.LOAD_FULL_REQUEST:
      return {
        ...state,
        currentItem: null,
        loading: true,
        adminRoute: {
          apvgks: [],
          routeChanged: false,
          waypoints: [],
          markers: []
        }
      };
      case applicationConstants.LOAD_FULL_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.application.data,
        adminRoute: {...state.adminRoute, apvgks: (action.application.data.route||{}).apvgks || []}
      };
    case applicationConstants.LOAD_FULL_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    // confirm/decline @todo remove move application to another tab on status change
    case applicationConstants.ACCEPT_REQUEST:
      return {
        ...state,
        statusLoading: true,
        routeSaved: false
      };
    case applicationConstants.ACCEPT_SUCCESS:
      return {
        ...state,
        editMap: false,
        items: removeItem(state.items, action.application.id),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
          status: action.application.status,
          comment: action.application.comment,
          is_spring: action.application.is_spring,
          permit: action.application.permit
        },
        plan: state.plan
          ? {
              ...state.plan,
              options: {
                ...state.plan.options,
                draggableWaypoints: false
              }
            }
          : null,
        routing: state.routing
          ? {
              ...state.routing,
              options: {
                ...state.routing.options,
                fitSelectedRoutes: false,
                lineOptions: {
                  ...state.routing.options.lineOptions,
                  addWaypoints: false
                }
              }
            }
          : null,
        routeSaved: action.changeStatus ? state.routeSaved : true
      };
    case applicationConstants.ACCEPT_FAILURE:
      return {
        ...state,
        statusLoading: false
      };
    case applicationConstants.ACTIVATE_REQUEST:
      return {
        ...state,
        statusLoading: true
      };
    case applicationConstants.ACTIVATE_SUCCESS:
      return {
        ...state,
        items: removeItem(state.items, action.application.id),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
          status: action.application.status,
          comment: action.application.comment,
          start_date: action.application.start_date,
          finish_date: action.application.finish_date
        },
        showActivateModal: false
      };
    case applicationConstants.ACTIVATE_FAILURE:
      return {
        ...state,
        statusLoading: false
      };
    case applicationConstants.DECLINE_REQUEST:
      return {
        ...state,
        statusLoading: true
      };
    case applicationConstants.DECLINE_SUCCESS:
      return {
        ...state,
        items: removeItem(state.items, action.application.id),
        statusLoading: false,
        showDeclineModal: false,
        currentItem: {
          ...state.currentItem,
          status: action.application.status,
          comment: action.application.comment
        }
      };
    case applicationConstants.DECLINE_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    case applicationConstants.RESTORE_REQUEST:
      return {
        ...state,
        statusLoading: true
      };
    case applicationConstants.RESTORE_SUCCESS:
      return {
        ...state,
        items: removeItem(state.items, action.application.id),
        statusLoading: false
      };
    case applicationConstants.RESTORE_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    // decline modal
    case applicationConstants.DECLINE_CURRENT_ITEM_OPEN:
      return {
        ...state,
        showDeclineModal: true
      };
    case applicationConstants.DECLINE_OPEN:
      return {
        ...state,
        showDeclineModal: true,
        currentItem:
          state.currentItem && action.id === state.currentItem.id
            ? state.currentItem
            : findItem(state.items, action.id)
      };
    case applicationConstants.DECLINE_CLOSE:
      return {
        ...state,
        showDeclineModal: false
      };

    // activate modal
    case applicationConstants.ACTIVATE_CURRENT_ITEM_OPEN:
      return {
        ...state,
        showActivateModal: true
      };
    case applicationConstants.ACTIVATE_OPEN:
      return {
        ...state,
        showActivateModal: true,
        currentItem:
          state.currentItem && action.id === state.currentItem.id
            ? state.currentItem
            : findItem(state.items, action.id)
      };
    case applicationConstants.ACTIVATE_CLOSE:
      return {
        ...state,
        showActivateModal: false
      };

    case applicationConstants.AGREEMENT_DECLINE_CURRENT_ITEM_OPEN:
      return {
        ...state,
        showAgreementDeclineModal: true
      };
    case applicationConstants.AGREEMENT_DECLINE_OPEN:
      return {
        ...state,
        showAgreementDeclineModal: true,
        currentItem:
          state.currentItem && action.id === state.currentItem.id
            ? state.currentItem
            : findItem(state.items, action.id)
      };
    case applicationConstants.AGREEMENT_DECLINE_CLOSE:
      return {
        ...state,
        showAgreementDeclineModal: false
      };

    // reason modal
    case applicationConstants.REASON_OPEN:
      return {
        ...state,
        showReasonModal: true,
        currentItem: findItem(state.items, action.id)
      };
    case applicationConstants.REASON_CLOSE:
      return {
        ...state,
        showReasonModal: false
      };

    // reason modal
    case applicationConstants.DETAILS_OPEN:
      return {
        ...state,
        showDetailsModal: true,
        currentItem: findItem(state.items, action.id)
      };
    case applicationConstants.DETAILS_CLOSE:
      return {
        ...state,
        showDetailsModal: false
      };

    case applicationConstants.TO_WORK_REQUEST:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            item.loading = true;
          }
          return item;
        })
      };
    case applicationConstants.TO_WORK_SUCCESS:
      return {
        ...state,
        items: removeItem(state.items, action.application.id),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
          ...action.application
        }
      };
    case applicationConstants.TO_WORK_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    case applicationConstants.TO_WORK_MULTIPLE_SUCCESS:
      const ids = action.data;
      return {
        ...state,
        items: state.items.filter(item => ids.indexOf(item.id) === -1)
      };

    case applicationConstants.LOCK_REQUEST:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            item.loading = true;
          }
          return item;
        })
      };
    case applicationConstants.LOCK_SUCCESS:
      return {
        ...state,
        items: state.items.map(item =>
          action.application.id === item.id
            ? {
                ...item,
                loading: false,
                is_locked: action.application.is_locked
              }
            : item
        ),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,

          is_locked: action.application.is_locked
        }
      };
    case applicationConstants.LOCK_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    case applicationConstants.UNLOCK_REQUEST:
      return {
        ...state,
        items: state.items.map(item => {
          if (item.id === action.id) {
            item.loading = true;
          }
          return item;
        })
      };
    case applicationConstants.UNLOCK_SUCCESS:
      return {
        ...state,
        items: state.items.map(item =>
          action.application.id === item.id
            ? {
                ...item,
                is_locked: action.application.is_locked,
                loading: false
              }
            : item
        ),
        statusLoading: false,
        currentItem: {
          ...state.currentItem,
          is_locked: action.application.is_locked
        }
      };
    case applicationConstants.UNLOCK_FAILURE:
      return {
        ...state,
        statusLoading: false
      };

    // filter modal
    case applicationConstants.FILTER_MODAL_OPEN:
      return {
        ...state,
        showFilterModal: true
      };
    case applicationConstants.FILTER_MODAL_CLOSE:
      return {
        ...state,
        showFilterModal: false
      };

    // print modal
    case applicationConstants.PRINT_MODAL_OPEN:
      return {
        ...state,
        showPrintModal: true
      };
    case applicationConstants.PRINT_MODAL_CLOSE:
      return {
        ...state,
        showPrintModal: false
      };

    case applicationConstants.PRINT_PDF_REQUEST:
      return {
        ...state,
        printLink: null,
        printError: null
      };
    case applicationConstants.PRINT_PDF_SUCCESS:
      return {
        ...state,
        printLink: action.data.url,
        currentItem: {
          ...state.currentItem,
          form_id: action.form_id
        }
      };
    case applicationConstants.PRINT_PDF_FAILURE:
      return {
        ...state,
        printError: action.error
      };

    case applicationConstants.GET_ADMIN_APPLICATIONS_REQUEST:
      return {
        ...state,
        showFilterModal: false,
        loading: true,
        items: []
      };
    case applicationConstants.GET_ADMIN_APPLICATIONS_SUCCESS:
      return {
        ...state,
        items: action.applications.data,
        meta: action.applications.meta,
        loading: false
      };
    case applicationConstants.GET_ADMIN_APPLICATIONS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case applicationConstants.GET_USER_FILTERED_FAILURE:
      return {
        ...state,
        loading: false
      };
    case applicationConstants.SET_MAP:
      return {
        ...state,
        map: action.map
      };
    case applicationConstants.SET_PLAN:
      return {
        ...state,
        plan: action.plan
      };
    case applicationConstants.SET_ROUTING:
      return {
        ...state,
        routing: action.routing
      };

    case applicationConstants.ADD_CONTROL_MARK_REQUEST:
      return {
        ...state,
        addingMark: true,
        markError: ''
      };
    case applicationConstants.ADD_CONTROL_MARK_SUCCESS:
      return {
        ...state,
        addingMark: false,
        currentItem: {
          ...state.currentItem,
          control_marks: [...state.currentItem.control_marks, action.control_mark],
          dates: {
            ...state.currentItem.dates,
            runs_used: action.runs_used
          }
        }
      };
    case applicationConstants.ADD_CONTROL_MARK_FAILURE:
      return {
        ...state,
        addingMark: false,
        markError: action.error
      };

    case applicationConstants.SET_MARKER_VALUE:
      return {
        ...state,
        adminRoute: {
          ...state.adminRoute,
          markers: state.adminRoute.markers.map((marker, index) => {
            return index === action.index ? { ...marker, text: action.value } : marker;
          })
        }
      };
      case applicationConstants.CHANGE_APVGKS_VALUE:
        return {
            ...state,
            currentItem: {
                ...state.currentItem,
                route: {
                    ...state.currentItem.route,
                    apvgks: action.value,
                }
            },
            adminRoute: {
                ...state.adminRoute,
                routeChanged: true,
            },
        };
    case routeAddressConstants.SET_SUGGESTIONS:
      return {
        ...state,
        adminRoute: {
          ...state.adminRoute,
          markers: state.adminRoute.markers.map((marker, index) => {
            return index === action.index
              ? {
                  ...marker,
                  suggestions: action.suggestions
                }
              : marker;
          }),
          routeChanged: true
        }
      };

    case applicationConstants.SEND_TO_DEPARTMENT_SUCCESS:
      if (action.application_agreement) {
        return {
          ...state,
          currentItem:
            state.currentItem && state.currentItem.application_agreements
              ? {
                  ...state.currentItem,
                  application_agreements: [
                    ...state.currentItem.application_agreements,
                    action.application_agreement
                  ]
                }
              : state.currentItem,
          items: [
            ...state.items.map(item => {
              return item.id === action.application_agreement.application_id
                ? {
                    ...item,
                    application_agreements: [
                      ...item.application_agreements,
                      action.application_agreement
                    ]
                  }
                : item;
            })
          ]
        };
      }
      return state;

    case applicationConstants.REMOVE_AGREEMENT_SUCCESS:
      return {
        ...state,
        currentItem:
          state.currentItem && state.currentItem.application_agreements
            ? {
                ...state.currentItem,
                application_agreements: state.currentItem.application_agreements.filter(
                  agreement => agreement.id !== action.id
                )
              }
            : state.currentItem,
        items: [
          ...state.items.map(item => {
            return {
              ...item,
              application_agreements: item.application_agreements.filter(
                agreement => agreement.id !== action.id
              )
            };
          })
        ]
      };

    case applicationConstants.AGREEMENT_ACCEPT_SUCCESS:
      if (action.agreement) {
        return {
          ...state,
          items: state.items.filter(item => item.id !== action.agreement.application_id),
          currentItem:
            state.currentItem && state.currentItem.id === action.agreement.application_id
              ? {
                  ...state.currentItem,
                  status: action.agreement.status,
                  application_agreements: state.currentItem.application_agreements.map(
                    agreement => {
                      return agreement.id === action.agreement.id ? action.agreement : agreement;
                    }
                  )
                }
              : state.currentItem
        };
      }
      return state;

    case applicationConstants.AGREEMENT_DECLINE_SUCCESS:
      if (action.agreement) {
        return {
          ...state,
          showAgreementDeclineModal: false,
          items: state.items.filter(item => item.id !== action.agreement.application_id),
          currentItem:
            state.currentItem.id === action.agreement.application_id
              ? {
                  ...state.currentItem,
                  status: applicationConstants.STATUS_DECLINE,
                  application_agreements: state.currentItem.application_agreements.map(
                    agreement => {
                      return agreement.id === action.agreement.id ? action.agreement : agreement;
                    }
                  )
                }
              : state.currentItem
        };
      }
      return state;

    case applicationConstants.CHANGE_ROUTE_ADMIN_SUCCESS:
      return {
        ...state,
        currentItem: {
          ...state.currentItem,
          price: action.data.price,
          spring_price: action.data.spring_price,
          route: {
            ...state.currentItem.route,
            distance: action.data.distance,
            distance_info: action.data.distance_info
          }
        },
        adminRoute: {
          ...state.adminRoute,
          routeChanged: true,
          waypoints: action.markers,
          markers: action.markers
        }
      };

    case applicationConstants.SET_ROUTE_MARKERS:
      return {
        ...state,
        adminRoute: {
          ...state.adminRoute,
          markers: setNewRoute(state.adminRoute.markers, action.markers)
        }
      };
    case applicationConstants.TOGGLE_EDIT_MAP:
      return {
        ...state,
        editMap: action.payload
      };
    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
