import { createSelector } from 'reselect';
import { get } from 'lodash';
import { applicationConstants } from '../constants/applicationConstants';
import { roleConstants } from '../constants/roleConstants';
import { applicationHelper } from '../helpers/applicationHelper';

export const getApplicationState = state => state.application;
export const getUser = state => state.authentication.user;
export const getUserRole = state => state.authentication.user.role_id;
export const getSpecialConditions = state => state.specialConditions.currentItem;

export const getIsApplicationLoading = createSelector(
  getApplicationState,
  state => state.isLoading
);

export const getIsApplicationError = createSelector(
  getApplicationState,
  state => state.error
);

export const getApplicationData = createSelector(
  [getApplicationState, getUser],
  (state, user) => {
    console.log(user);
    const application = state.data;
    if (user && user.department_id && application) {
      return {
        ...state.data,
        status: application.application_agreements.find(a => a.department_id === user.department_id)
          .status
      };
    }
    return application;
  }
);

export const getApplicationRoleId = createSelector(
  getApplicationData,
  application =>
    application.is_fast ? get(application, 'fast.role_id') : get(application, 'user.role_id')
);

export const getApplicationStatus = createSelector(
  getApplicationData,
  state => state.status
);

export const getApplicationRoute = createSelector(
  getApplicationData,
  state => state.route
);

export const getApplicationMarkers = createSelector(
  getApplicationData,
  state => state.markers
);

export const getApplicationChangingStatus = createSelector(
  getApplicationState,
  state => state.isChangingStatus
);

export const getApplicationEditingMap = createSelector(
  getApplicationState,
  state => state.isEditingMap
);

export const isApplicationRouteEditable = createSelector(
  [getApplicationStatus, getUserRole],
  (status, role) =>
    [applicationConstants.STATUS_REVIEW, applicationConstants.STATUS_REPEAT].includes(status) &&
    [roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER].includes(role)
);

export const getIsRouteChanged = createSelector(
  getApplicationState,
  state => state.isRouteChanged
);

export const getRouteChangeComment = createSelector(
  getApplicationState,
  state => state.routeChangeComment
);

export const getApplicationId = createSelector(
  getApplicationData,
  state => state.id
);

export const getApplicationModals = createSelector(
  getApplicationState,
  state => state.modals
);

export const getIsShowDeclineModal = createSelector(
  getApplicationModals,
  state => state.declineModal
);

export const getNeedSizeAgreement = createSelector(
  getApplicationData,
  application => applicationHelper.needSizeAgreement(application)
);

export const getOversizeAgreed = createSelector(
  getApplicationData,
  application => applicationHelper.oversizeAgreed(application)
);

export const getIsSpring = createSelector(
  [getApplicationData, getSpecialConditions],
  (application, specialConditions) => applicationHelper.checkSpring(application, specialConditions)
);

export const getIsShowActivateModal = createSelector(
  getApplicationModals,
  modals => modals.activateModal
);

export const getIsShowPrintModal = createSelector(
  getApplicationModals,
  modals => modals.printModal
);

export const getIsDatesValid = createSelector(
  getApplicationData,
  application => applicationHelper.checkDatesValid(application)
);

export const getIsRunsExists = createSelector(
  getApplicationData,
  application => applicationHelper.checkRunsExist(application)
);

export const getIsAllAgreementsAccepted = createSelector(
  getApplicationData,
  application => applicationHelper.allAgreementsAccepted(application.application_agreements)
);

export const getControlMarks = createSelector(
  getApplicationData,
  application => application.control_marks || []
);

export const getPayFiles = createSelector(
  getApplicationData,
  application => get(application, 'files.pay_files') || []
);
