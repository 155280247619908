import { apiService } from './apiService';

const register = values => {
  return apiService.call('post', '/register', values);
};
const confirmReg = values => {
  return apiService.call('post', '/register/code-confirmation', values);
};
const login = values => {
  return apiService.call('post', '/login', values);
};
const logout = () => {
  localStorage.removeItem('user');
};

const requestRestore = values => {
  return apiService.call('post', '/restore-password/change-request', values);
};

const confirmRestore = values => {
  return apiService.call('post', '/restore-password/code-confirmation', values);
};

const changePassword = values => {
  return apiService.call('post', '/restore-password/new-password', values);
};

const emailConfirmation = values => {
  return apiService.call('post', '/email-confirmation', values);
};

export const authService = {
  register,
  confirmReg,
  login,
  logout,
  requestRestore,
  confirmRestore,
  changePassword,

  emailConfirmation
};
