import React from 'react';
import { connect } from 'react-redux';
import { roleConstants } from '../../../constants/roleConstants';
import { applicationHelper } from '../../../helpers/applicationHelper';
import ApplicationSectionTitle from './ApplicationSectionTitle';
import ApplicationField from './ApplicationField';
import { getIsNew } from './utils';
import ShowByStatus from './ShowByStatus';

function roundPrice(price) {
  if (price === undefined) {
    return '--';
  }
  return Math.round(price * 100) / 100;
}

const ApplicationPriceInfo = ({ application, role, specialConditions }) => {
  if (role === roleConstants.ROLE_WEIGHT_CONTROL) {
    return null;
  }
  const isNew = getIsNew(application);

  const showStandard = isNew || application.is_spring === 0;
  const showSpring = isNew || application.is_spring === 1;
  const isSpring = applicationHelper.checkSpring(application, specialConditions.currentItem);
  return (
    <ShowByStatus
      allowedRoles={[
        roleConstants.ROLE_ADMIN,
        roleConstants.ROLE_OFFICER,
        roleConstants.ROLE_DEPARTMENT_AGENT
      ]}
    >
      {application.price_info && application.price_info.price && showStandard ? (
        <>
          <ApplicationSectionTitle>Стоимость разрешения</ApplicationSectionTitle>
          <ApplicationField
            label="Стоимость движения по дорогам с нормативной нагрузкой 10 т/ось"
            value={roundPrice(application.price_info.price[0])}
          />
          <ApplicationField
            label="Стоимость движения по дорогам с нормативной нагрузкой 11,5 т/ось"
            value={roundPrice(application.price_info.price[1])}
          />
          <ApplicationField
            label="Стоимость движения по дорогам с нормативной нагрузкой 6 т/ось"
            value={roundPrice(application.price_info.price[2])}
          />
          {isSpring && showSpring && (
            <>
              {application.price_info && application.price_info.spring_price && (
                <>
                  <ApplicationField
                    label="Стоимость движения по дорогам с нормативной нагрузкой 10 т/ось (весна)"
                    value={roundPrice(application.price_info.spring_price[0])}
                  />
                  <ApplicationField
                    label="Стоимость движения по дорогам с нормативной нагрузкой 11,5 т/ось (весна)"
                    value={roundPrice(application.price_info.spring_price[1])}
                  />
                  <ApplicationField
                    label="Стоимость движения по дорогам с нормативной нагрузкой 6 т/ось (весна)"
                    value={roundPrice(application.price_info.spring_price[2])}
                  />
                </>
              )}
            </>
          )}
          <ApplicationField
            label="Стоимость госпошлниы"
            value={roundPrice(application.price_info.duty)}
          />
          <ApplicationField
            label="Стоимость бланка"
            value={roundPrice(application.price_info.form)}
          />
        </>
      ) : (
        showStandard && (
          <ApplicationField label="Стоимость разрешения" value={application.price || 0} />
        )
      )}
      <ApplicationSectionTitle>Итого:</ApplicationSectionTitle>
      <ApplicationField value={application.price || 0} label="Стоимость разрешения" />
      {isSpring && showSpring && (
        <ApplicationField
          value={application.spring_price || 0}
          label="Стоимость разрешения (весна)"
        />
      )}
    </ShowByStatus>
  );
};

const mapStateToProps = state => ({
  role: state.authentication.user.role_id,
  specialConditions: state.specialConditions
});

export default connect(mapStateToProps)(ApplicationPriceInfo);
