import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { ArrowBack } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';

import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { userActions } from '../../../actions/userActions';
import { urlHelper } from '../../../helpers/urlHelper';
import { useMountEffect } from '../../../helpers/hooks';
import RegAppsVehiclesList from './RegAppsVehiclesList';
import { LabelField } from '../../../components/form/LabelField';
import EditableEmail from './components/EditableEmail';

const RegAppsFirmPage = ({
  currentItem,
  firmId,
  getFirmInfo,
  authentication,
  handleSubmit,
  changeEmail
}) => {
  const { user } = authentication;

  useMountEffect(() => getFirmInfo(firmId));

  const fields = {
    inn_org: get(currentItem, 'firm.inn_org', ''),
    kpp_org: get(currentItem, 'firm.kpp_org', ''),
    address: get(currentItem, 'firm.address', ''),
    executive_fio: get(currentItem, 'firm.executive_fio', ''),
    executive_position: get(currentItem, 'firm.executive_position', ''),
    bank_name: get(currentItem, 'firm.paymentDetails.bank_name', ''),
    bank_inn: get(currentItem, 'firm.paymentDetails.bank_inn', ''),
    correspondent_account: get(currentItem, 'firm.paymentDetails.correspondent_account', ''),
    bank_bik: get(currentItem, 'firm.paymentDetails.bank_bik', ''),
    account: get(currentItem, 'firm.paymentDetails.account', ''),
    contact_phone: get(currentItem, 'firm.contact_phone', ''),
    phone_statis: get(currentItem, 'firm.account', ''),
    contact_fio: get(currentItem, 'firm.contact_fio', ''),
    email: get(currentItem, 'email', ''),
    reason:
      currentItem && currentItem.firm
        ? dictionaryHelper.firmReasons.getItemTitle(currentItem.firm.reason_key)
        : ''
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2 className="h2-title">
        <Link to={`${urlHelper.getHomeUrl(user.role_id)}/reg-apps`}>
          <IconButton color="primary">
            <ArrowBack />
          </IconButton>
        </Link>
        {currentItem && currentItem.name},{' '}
        {currentItem && currentItem.firm && currentItem.firm.legal_form_title}
      </h2>

      <LabelField input={{ value: fields.inn_org, disabled: true }} label="ИНН организации" />
      <LabelField input={{ value: fields.kpp_org, disabled: true }} label="КПП организации" />
      <LabelField input={{ value: fields.address, disabled: true }} label="Юридический адрес" />
      <LabelField
        input={{ value: fields.executive_fio, disabled: true }}
        label="ФИО руководителя"
      />
      <LabelField
        input={{ value: fields.executive_position, disabled: true }}
        label="Должность руководителя"
      />
      <LabelField input={{ value: fields.reason, disabled: true }} label="Действует на основании" />
      <LabelField input={{ value: fields.bank_name, disabled: true }} label="Наименование банка" />
      <LabelField input={{ value: fields.bank_inn, disabled: true }} label="ИНН банка" />
      <LabelField
        input={{ value: fields.correspondent_account, disabled: true }}
        label="Корреспондетский счет банка"
      />
      <LabelField input={{ value: fields.bank_bik, disabled: true }} label="БИК банка" />
      <LabelField input={{ value: fields.account, disabled: true }} label="Расчетный счет банка" />
      <LabelField
        input={{ value: fields.contact_phone, disabled: true }}
        label="Контактный телефон организации"
      />
      <LabelField
        input={{ value: fields.phone_static, disabled: true }}
        label="Городской номер телефона"
      />
      <LabelField
        input={{ value: fields.contact_fio, disabled: true }}
        label="ФИО контактного лица"
      />
      <EditableEmail email={fields.email} id={firmId} onChange={changeEmail} />
      <RegAppsVehiclesList firmId={firmId} user={user} />
    </form>
  );
};

const mapStateToProps = (state, { match }) => {
  const { users, authentication } = state;
  const { currentItem } = users;

  return {
    currentItem,
    authentication,
    firmId: match.params.id
  };
};

export default compose(
  connect(
    mapStateToProps,
    {
      getFirmInfo: userActions.getFirmFull,
      changeEmail: userActions.changeEmail
    }
  )
)(RegAppsFirmPage);
