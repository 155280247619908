import { specialConditionConstants } from '../constants/specialConditionConstants';
import { authConstants } from '../constants/authConstants';

const initialState = {
  currentItem: null,
  loading: false,
  tabIndex: specialConditionConstants.TAB_DEFAULT
};

export const specialConditions = (state = initialState, action) => {
  switch (action.type) {
    case specialConditionConstants.SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex
      };
    case specialConditionConstants.LOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case specialConditionConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.specialCondition.data
      };
    case specialConditionConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    case specialConditionConstants.SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.item
      };

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
