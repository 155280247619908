import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { vehicleModelActions } from '../../../../../actions/vehicleModelActions';
import { Loader } from '../../../../../components/Loader';
import { dictionaryHelper } from '../../../../../helpers/dictionaryHelper';
import TablePagination from '../../../../../components/TablePagination';
import SortableColumn from '../../../quickApplications/content/SortableColumn';
import { VehicleModelsFilter } from './VehicleModelsFilter';

const VehicleModelsTableList = props => {
  const {
    data,
    meta,
    vehicleModels,
    handleChangePage,
    handleDelete,
    handleAccept,
    handleHide,
    openUpdateModal
  } = props;

  return (
    <div>
      <Paper className="overflow">
        <Table>
          <TableHead className="wrap">
            <TableRow>
              <TableCell>№</TableCell>
              <SortableColumn
                column="is_trailer"
                sort={vehicleModels.sort || {}}
                onSortChange={sort => handleChangePage(1, vehicleModels.filters, sort)}
              >
                ТС / Прицеп
              </SortableColumn>
              <SortableColumn
                column="brand"
                sort={vehicleModels.sort || {}}
                onSortChange={sort => handleChangePage(1, vehicleModels.filters, sort)}
              >
                Марка
              </SortableColumn>
              <SortableColumn
                column="model"
                sort={vehicleModels.sort || {}}
                onSortChange={sort => handleChangePage(1, vehicleModels.filters, sort)}
              >
                Модель
              </SortableColumn>
              <TableCell>Тип</TableCell>
              {/* <TableCell>Виден</TableCell> */}
              <TableCell>Количество осей</TableCell>
              <TableCell>Расстояние между осями</TableCell>
              <TableCell>Скатность колес</TableCell>
              <TableCell>длина*ширина*высота</TableCell>

              <TableCell numeric>Действие</TableCell>
            </TableRow>
            <VehicleModelsFilter />
          </TableHead>
          <TableBody>
            {data.map((n, index) => {
              return (
                <TableRow key={n.id}>
                  <TableCell>{(meta.current_page - 1) * meta.per_page + index + 1}</TableCell>
                  <TableCell>{n.is_trailer ? 'Прицеп' : 'ТС'}</TableCell>
                  <TableCell>{n.brandTitle}</TableCell>
                  <TableCell>{n.title}</TableCell>
                  <TableCell>
                    {dictionaryHelper.vehicleTypes.getItemTitle(n.vehicle_type_id)}
                  </TableCell>
                  {/* <TableCell>{n.hidden ? 'Нет' : 'Да'}</TableCell> */}

                  <TableCell>{n.axle_count}</TableCell>
                  <TableCell>{n.axles_distance}</TableCell>
                  <TableCell>{n.axles_wheel_count}</TableCell>
                  <TableCell>{`${n.length}*${n.width}*${n.height}`}</TableCell>

                  <TableCell numeric className="nowrap">
                    {!n.accepted ? (
                      <Button
                        variant="outlined"
                        className="accept-btn"
                        size="small"
                        onClick={handleAccept(n.id)}
                      >
                        {n.accepting ? <Loader /> : <span>Утвердить</span>}
                      </Button>
                    ) : (
                      ''
                    )}
                    {!n.accepted ? (
                      <Button
                        variant="outlined"
                        className="accept-btn"
                        size="small"
                        onClick={handleHide(n.id)}
                      >
                        {n.accepting ? <Loader /> : <span>Отклонить</span>}
                      </Button>
                    ) : (
                      ''
                    )}
                    <Button
                      variant="outlined"
                      className="info-btn"
                      size="small"
                      onClick={openUpdateModal(n)}
                    >
                      <span>Редактировать</span>
                    </Button>

                    <Button
                      variant="outlined"
                      className="decline-btn"
                      size="small"
                      onClick={handleDelete(n.id)}
                    >
                      {n.deleting ? <Loader /> : <span>Удалить</span>}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        meta={meta}
        onChangePage={page => handleChangePage(page, vehicleModels.filters, vehicleModels.sort)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { vehicleModels } = state;

  return {
    vehicleModels
  };
};

const mapDispatchToProps = dispatch => {
  const handleChangePage = (page, filters, sort) => {
    dispatch(vehicleModelActions.getPage(page, filters, sort));
  };

  const handleDelete = id => {
    return e => dispatch(vehicleModelActions.delete(id));
  };

  const openUpdateModal = item => {
    return e => {
      dispatch(vehicleModelActions.setCurrentItem(item));
      dispatch(vehicleModelActions.openFormModal());
    };
  };

  const handleAccept = id => {
    return e => dispatch(vehicleModelActions.accept(id));
  };

  const handleHide = id => {
    return e => dispatch(vehicleModelActions.hide(id));
  };

  return {
    handleChangePage,
    handleDelete,
    handleAccept,
    handleHide,
    openUpdateModal
  };
};

const connectedVehicleModelsTableList = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleModelsTableList);
export { connectedVehicleModelsTableList as VehicleModelsTableList };
