import React, { Component } from 'react';
import L from 'leaflet';
import { Tooltip, Marker } from 'react-leaflet';
import 'leaflet-routing-machine';
import 'leaflet-control-geocoder';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import { connect } from 'react-redux';
import { Geocoder, Router } from '../../../components/map/Geocoder';
import * as actions from '../../../actions/newApplicationActions';

class Routing extends Component {
  constructor(props) {
    super(props);

    this.map = props.map;

    this.state = {
      routingPopUp: null,
      loading: false
    };
    this.initTimer = null;

    this.initializeRouting = this.initializeRouting.bind(this);
    this.destroyRouting = this.destroyRouting.bind(this);
  }

  componentDidMount() {
    const { map, application } = this.props;
    this.initTimer = setTimeout(() => this.initializeRouting(map, application), 3000);
  }

  componentDidUpdate(prevProps) {
    const { markers } = this.props;
    if (prevProps.markers.length > markers.length) {
      const newMarkersIds = markers.map(marker => marker.id);
      const removedIndex = prevProps.markers.findIndex(
        marker => !newMarkersIds.includes(marker.id)
      );
      this.routing.spliceWaypoints(removedIndex, 1);
      this.routing.route();
    }
  }

  componentWillUnmount() {
    this.destroyRouting();
  }

  initializeRouting(map, application) {
    const { markers, editable } = this.props;

    if (this.props.map) {
      const plan = new L.Routing.Plan(
        markers.map(marker => L.latLng(marker.coords.lat, marker.coords.lon)),
        {
          routeWhileDragging: false,
          draggableWaypoints: editable,
          geocoder: new Geocoder()
        }
      );
      this.routing = L.Routing.control({
        plan,
        router: new Router(),
        // router: L.Routing.mapbox(applicationConstants.MAPBOX_KEY),
        lineOptions: {
          addWaypoints: editable
        }
      });

      // detect route changes by admin
      const that = this;
      this.routing.on('waypointschanged', function(e) {
        const { waypoints } = e;
        that.handlePointsChanged(application, waypoints);
      });

      this.routing.on('routingstart', () => {
        this.setState({ loading: true, position: this.map.props.center });
      });

      this.routing.on('routesfound routingerror', () => {
        this.setState({ loading: false, position: this.map.props.center });
      });

      this.props.map.leafletElement.addControl(this.routing);
    }
  }

  handlePointsChanged(application, waypoints) {
    const { markers, changeRouteByAdmin } = this.props;
    changeRouteByAdmin(application.id, waypoints, markers);
  }

  destroyRouting() {
    if (this.initTimer) {
      clearTimeout(this.initTimer);
    }
    if (this.props.map && this.routing) {
      this.props.map.leafletElement.removeControl(this.routing);
    }
  }

  render() {
    const { loading, position } = this.state;
    return (
      <React.Fragment>
        {loading && (
          <Marker position={position} opacity={0}>
            <Tooltip permanent direction="center">
              <span>Пожалуйста, подождите. Данные обновляются</span>
            </Tooltip>
          </Marker>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  changeRouteByAdmin: actions.changeRouteByAdmin
};

export default connect(
  undefined,
  mapDispatchToProps
)(Routing);
