export const coefficientConstants = {
  GET_PAGE_REQUEST: 'COEFFICIENT_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'COEFFICIENT_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'COEFFICIENT_GET_PAGE_FAILURE',

  GET_ALL_REQUEST: 'COEFFICIENT_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'COEFFICIENT_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'COEFFICIENT_GET_ALL_FAILURE',

  LOAD_REQUEST: 'COEFFICIENT_LOAD_REQUEST',
  LOAD_SUCCESS: 'COEFFICIENT_LOAD_SUCCESS',
  LOAD_FAILURE: 'COEFFICIENT_LOAD_FAILURE',

  CREATE_REQUEST: 'COEFFICIENT_CREATE_REQUEST',
  CREATE_SUCCESS: 'COEFFICIENT_CREATE_SUCCESS',
  CREATE_FAILURE: 'COEFFICIENT_CREATE_FAILURE',

  UPDATE_REQUEST: 'COEFFICIENT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'COEFFICIENT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'COEFFICIENT_UPDATE_FAILURE',

  DELETE_REQUEST: 'COEFFICIENT_DELETE_REQUEST',
  DELETE_SUCCESS: 'COEFFICIENT_DELETE_SUCCESS',
  DELETE_FAILURE: 'COEFFICIENT_DELETE_FAILURE',

  FORM_MODAL_OPEN: 'COEFFICIENT_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'COEFFICIENT_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'COEFFICIENT_SET_CURRENT_ITEM'
};
