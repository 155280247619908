import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const AdminPanelMenu = ({ location, links }) => {
  return (
    <div className="">
      {links.map(link => (
        <Link key={link.path} to={link.path}>
          <Button
            className={`btn_tab${location.pathname === link.path ? ' active_btn_tab' : ''}`}
            key={link.path}
          >
            {link.title}
          </Button>
        </Link>
      ))}
    </div>
  );
};

const connectedAdminPanelMenu = withRouter(AdminPanelMenu);
export { connectedAdminPanelMenu as AdminPanelMenu };
