import Button from '@material-ui/core/Button';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import ControlMarkCard from '../../admin/applications/controlMarks/ControlMarkCard';
import { applicationService } from '../../../services/applicationService';

const ControlMarksBlock = ({
  controlMarks,
  applicationId,
  postId,
  handleAddControlMark,
  enqueueSnackbar
}) => {
  const [isAddingMark, setAddingMark] = React.useState(false);
  const [isReverse, setReverse] = React.useState(false);
  const onAdd = async () => {
    setAddingMark(true);
    try {
      const response = await applicationService.addControlMark(applicationId, isReverse, postId);
      setAddingMark(false);
      if (response.data.success) {
        enqueueSnackbar('Отметка добавлена!', { variant: 'success' });
        handleAddControlMark();
      } else {
        enqueueSnackbar(response.data.error, { variant: 'error' });
      }
    } catch (e) {
      setAddingMark(false);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };
  return (
    <div>
      {(controlMarks || []).map((item, index) => (
        <React.Fragment key={item.id}>
          <ControlMarkCard controlMark={item} index={index} />
          <br />
        </React.Fragment>
      ))}

      {!isAddingMark && (
        <div>
          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                checked={isReverse}
                onChange={e => setReverse(e.target.checked)}
                className="checkbox"
              />
            }
            label="Тс движется в обратном направлении"
          />
          <br />
          <Button className="btn-add no-margin" onClick={onAdd}>
            Добавить отметку о проезде
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  postId: state.controlPosts.post_id
});

export default connect(mapStateToProps)(withSnackbar(ControlMarksBlock));
