import React from 'react';
import NewApplicationViewPage from '../admin/applications_new/ApplicationViewPageContainer';
import {ApvgkRoute} from "../../components/routes/ApvgkRoute";
import {ApplicationViewPage} from "../admin/applications/ApplicationViewPage";
import {ApplicationsListPage} from "../control/applications/ApplicationsListPage";

const ApplicationsLayout = ({ match }) => (
  <div>
    <ApvgkRoute exact path={`${match.url}/`} component={ApplicationsListPage} />
    <ApvgkRoute exact path={`${match.url}/view/:id`} component={ApplicationViewPage} />

  </div>
);

export default ApplicationsLayout;
