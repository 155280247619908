import { get, isArray, isEmpty, isNil } from 'lodash';
import moment from 'moment';

import { dictionaryHelper } from '../../../../helpers/dictionaryHelper';
import { formatHelper } from '../../../../helpers/formatHelper';
import { LocationType } from '../../../../constants/cooperationConstants';

const getLoadName = ({ name, load_weight }) => {
  return `${name ? `${name}, ` : ''}${load_weight ? `${load_weight}т` : ''}`;
};

const getTransportName = ({ brandTitle, modelTitle, real_number, pts_weight: weight }) => {
  return `${brandTitle ? `${brandTitle}, ` : ''}${
    modelTitle ? `${modelTitle}, ` : ''
  }${real_number || ''} ${!isNil(weight) ? weight + 'т': ''}` ;
};

const getTripType = ({ route, privilege_status_id }) => {
  return route || privilege_status_id
    ? dictionaryHelper.routeTypes.getItemTitle(privilege_status_id ? 2 : route.type_id)
    : undefined;
};

const formatOutputDate = date => {
  const splittedDate = date.split('-');
  return `${splittedDate[2]}.${splittedDate[1]}.${splittedDate[0]}`;
};

export const getDateRange = ({ output_date, answer_at }) => {
  const start = moment(output_date).format('DD.MM.YYYY');
  const end = moment(answer_at).format('DD.MM.YYYY');
  return `${start}/${end}`;
};

export const mapInfo = ({ smev, vehicle, dates, load, trailers = [], route, ...application }) => {
  return {
    id: formatHelper.appNumber(application),
    status: application.status,
    applicantName: smev.authority_name,
    applicantId: smev.output_number,
    date: formatOutputDate(smev.output_date),
    transportOwnerAddress: application.username,
    routeString: route ? route.text_route : undefined,
    period: {
      start: application.start_date,
      end: application.finish_date
    },
    comment: application.comment,
    tripCount: dates ? dates.runs_count - dates.runs_used : undefined,
    tripType: getTripType({ route, privilege_status_id: application.privilege_status_id }),
    transportName: vehicle ? getTransportName(vehicle) : undefined,
    loadName: load ? getLoadName(load) : undefined,
    locationType: LocationType[application.location_type],
    trailers: trailers.map(trailer => ({
      id: trailer.id,
      info: getTransportName(trailer)
    }))
  };
};

const getLoads = (axlesInfo, param) =>
  axlesInfo.reduce(
    (acc, axle, index) =>
      isEmpty(acc)
        ? axle[param].map(load => `${load}т${index !== axlesInfo.length - 1 ? ' x ' : ''}`)
        : acc.map(
            (load, i) => `${load}${axle[param][i]}т${index !== axlesInfo.length - 1 ? ' x ' : ''}`
          ),
    []
  );

const parseAxlesData = axlesData => {
  const axlesInfo = JSON.parse(axlesData);
  const isLoadArray = axlesInfo ? isArray(axlesInfo[0].permissible_load) : false;
  const loads = {};

  if (isLoadArray) {
    loads.permissibleLoads = getLoads(axlesInfo, 'permissible_load');
    loads.permissibleLoadsSpring = getLoads(axlesInfo, 'permissible_load_spring');
  } else {
    loads.permissibleLoad = axlesInfo
      .map(({ permissible_load }) => `${permissible_load}т`)
      .join(' x ');
    loads.permissibleLoadSpring = axlesInfo
      .map(({ permissible_load_spring }) => `${permissible_load_spring}т`)
      .join(' x ');
  }

  return {
    axlesDistances: axlesInfo
      .filter(({ distance }, index) => index !== axlesInfo.length - 1 || distance !== 0)
      .map(({ distance }) => `${distance}м`)
      .join(' x '),
    axlesLoad: axlesInfo.map(({ axle_load }) => `${axle_load}т`).join(' x '),
    wheelsCount: axlesInfo.map(({ wheel_count }) => wheel_count).join(' x '),
    wheels: axlesInfo.map(({ wheels }) => wheels).join(' x '),
    type: axlesInfo.map(axle => dictionaryHelper.axleTypes.getItemTitle(axle.type_id)).join(' x '),
    ...loads
  };
};

export const mapTransport = ({ load, ...application }) => {
  return {
    length: load ? `${load.length} м` : undefined,
    width: load ? `${load.width} м` : undefined,
    height: load ? `${load.height} м` : undefined,
    weight: load ? `${load.weight} т` : undefined,
    axlesData: load && load.axles_info ? parseAxlesData(load.axles_info) : undefined,
    escort: get(load, 'escort.info', 'Без сопровожденя'),
    files: get(application, 'files.pay_files', [])
  };
};

export const mapProcessing = ({ route, ...application }) => {
  return {
    id: `№${formatHelper.appNumber(application)}`,
    agreements: application.application_agreements,
    routeString: route ? route.text_route : undefined,
    acceptDate: application.accept_date,
    acceptedBy: get(application, 'admin.name'),
    acceptedByPosition: get(application, 'admin.position'),
    specialConditions: application.special_conditions,
    rejectReason: application.comment,
    commonDistance: get(route, 'distance', 0),
    regionalDistance: get(route, 'distance_info[0]', 0),
    federalDistance: get(route, 'distance_info[1]', 0),
    municipalDistance: get(route, 'distance_info[2]', 0),
    damage: application.damage,
    points: route ? JSON.parse(route.points) : [],
    marks: get(application, 'control_marks', []).map(m => ({
      date: m.updated_at,
      name: get(m, 'control_post.title'),
      gdrName: m.user.name,
      direction: m.reverse ? 'В обратную сторону' : 'По маршруту',
      active: m.active ? 'Да' : 'Нет'
    }))
  };
};

export const isExpires = ({ answer_at }) => {
  return moment().isAfter(
    moment(answer_at)
      .startOf('d')
      .add(-1, 'd')
  );
};

export function expireStyles(item) {
  if (isExpires(item)) {
    return { style: { backgroundColor: '#ffb1b1' } };
  }
  return {};
}
