import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { FormField } from '../../../../../components/form/FormField';
import { fieldValidators } from '../../../../../helpers/fieldValidators';
import { Loader } from '../../../../../components/Loader';

let VehicleBrandsForm = props => {
  const { error, handleSubmit, submitting, currentItem } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="title"
        type="text"
        label="Название"
        validate={[fieldValidators.required]}
        component={FormField}
      />

      {error && <strong>{error}</strong>}
      <div>
        {submitting && <Loader />}
        <button type="submit" className="btn" disabled={submitting}>
          {currentItem ? 'Редактировать' : 'Создать'}
        </button>
      </div>
    </form>
  );
};

VehicleBrandsForm = reduxForm({
  form: 'vehicle-brand-form', // a unique identifier for this form
  enableReinitialize: true
})(VehicleBrandsForm);

VehicleBrandsForm = connect(state => ({
  initialValues: {
    id: state.vehicleBrands.currentItem ? state.vehicleBrands.currentItem.id : '',
    title: state.vehicleBrands.currentItem ? state.vehicleBrands.currentItem.title : ''
  }
}))(VehicleBrandsForm);

export default VehicleBrandsForm;
