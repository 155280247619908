import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import ControlMarkCard from './ControlMarkCard';
import { getControlMarks } from '../../../../selectors/applicationSelectors';
import ApplicationSectionTitle from '../ApplicationSectionTitle';

const ControlMarksBlock = ({ controlMarks }) => {
  if (controlMarks.length === 0) {
    return null;
  }
  return (
    <div>
      <ApplicationSectionTitle>Отметки о проезде</ApplicationSectionTitle>
      <br />
      <Grid container direction="column" spacing={8}>
        {controlMarks.map((item, index) => (
          <Grid item key={item.id}>
            <ControlMarkCard controlMark={item} index={index} />
          </Grid>
        ))}
      </Grid>
      <br />
    </div>
  );
};

const mapStateToProps = state => ({
  controlMarks: getControlMarks(state)
});

export default connect(mapStateToProps)(ControlMarksBlock);
