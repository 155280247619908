import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { applicationActions } from '../../../actions/applicationActions';
import { ApplicationFilterModal } from './ApplicationFilterModal';
import { ApplicationCard } from './ApplicationCard';

class ApplicationsListPage extends Component {
  componentDidMount() {
    const { userApplications } = this.props;
  }

  render() {
    const { handleModalOpen, userApplications } = this.props;
    const { items } = userApplications;

    return (
      <main role="main" className="container">
        <div className="">
          <h2 className="h2-title">Проверка разрешений</h2>
        </div>

        <div className="">
          <Button
            className="no-margin info-btn"
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleModalOpen}
          >
            Поиск
          </Button>
          <Button
              className="no-margin info-btn"
              variant="outlined"
              color="primary"
              type="button"
              onClick={() => window.location = window.location.origin + '/qr'}
          >
            Сканировать
          </Button>

          <ApplicationFilterModal />
        </div>

        <div className="">
          <br />
          {items.map(item => (
            <div key={item.id}>
              <ApplicationCard application={item} />
              <br />
            </div>
          ))}
          {items.length === 0 ? 'По данному ТС не обнаружено действующих разрешений' : ''}
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { userApplications } = state;

  return {
    userApplications
  };
};

const mapDispatchToProps = dispatch => {
  const handleModalOpen = () => {
    dispatch(applicationActions.openFilterModal());
  };

  return {
    handleModalOpen,
    dispatch
  };
};

const connectedApplicationsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationsListPage);
export { connectedApplicationsPage as ApplicationsListPage };
