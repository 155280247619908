import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { privilegeStatusActions } from '../../../../actions/privilegeStatusActions';
import TablePagination from '../../../../components/TablePagination';
import DoActionWithConfirm from '../../../../components/DoActionWithConfirm';

const VehicleModelsTableList = props => {
  const { data, meta, handleChangePage, handleDelete, openUpdateModal } = props;

  return (
    <div>
      <Paper className="overflow">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Название статуса</TableCell>
              <TableCell>Документ</TableCell>
              {/* <TableCell>Организация</TableCell> */}
              <TableCell>Дата начала</TableCell>
              <TableCell>Дата окончания</TableCell>
              <TableCell numeric>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((n, index) => {
              return (
                <TableRow key={n.id}>
                  <TableCell>{(meta.current_page - 1) * meta.per_page + index + 1}</TableCell>
                  <TableCell>{n.title}</TableCell>
                  <TableCell>
                    {get(n, 'files.privilege_status_files', []).map(({ id, name, url }) => (
                      <div key={id}>
                        <a target="_blank" href={url}>
                          {name}
                        </a>
                      </div>
                    ))}
                  </TableCell>
                  {/* <TableCell>{n.user && n.user.name}</TableCell> */}
                  <TableCell>{n.start_date}</TableCell>
                  <TableCell>{n.finish_date}</TableCell>
                  <TableCell numeric className="nowrap">
                    <DoActionWithConfirm
                      title="Подтверждение удаление статуса"
                      action={handleDelete}
                      description={`Вы уверены, что хотите удалить статус "${n.title}"?`}
                      needsConfirmation
                    >
                      {onDelete => (
                        <Button
                          variant="outlined"
                          className="decline-btn"
                          size="small"
                          onClick={() => onDelete(n.id)}
                        >
                          <span>Удалить</span>
                        </Button>
                      )}
                    </DoActionWithConfirm>
                    <Link to={`/admin/panel/statuses/${n.id}/ts`}>
                      <Button variant="outlined" className="info-btn" size="small">
                        <span>Список тс</span>
                      </Button>
                    </Link>
                    <Button
                      variant="outlined"
                      className="info-btn"
                      size="small"
                      onClick={openUpdateModal(n)}
                    >
                      <span>Редактировать</span>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination meta={meta} onChangePage={handleChangePage} />
    </div>
  );
};

const mapStateToProps = state => {
  const { vehicleModels } = state;

  return {
    vehicleModels
  };
};

const mapDispatchToProps = dispatch => {
  const handleChangePage = page => {
    dispatch(privilegeStatusActions.getPage(page));
  };

  const handleDelete = id => dispatch(privilegeStatusActions.delete(id));

  const openUpdateModal = item => {
    return e => {
      dispatch(privilegeStatusActions.setCurrentItem(item));
      dispatch(privilegeStatusActions.openFormModal());
    };
  };

  return {
    handleChangePage,
    handleDelete,
    openUpdateModal
  };
};

const connectedVehicleModelsTableList = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleModelsTableList);
export { connectedVehicleModelsTableList as VehicleModelsTableList };
