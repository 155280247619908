import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Field, reduxForm } from 'redux-form';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Autosuggest from 'react-autosuggest';
import DeleteIcon from '@material-ui/icons/Close';
import StartPointIcon from '@material-ui/icons/FiberManualRecord';
import PointIcon from '@material-ui/icons/Adjust';
import EndPointIcon from '@material-ui/icons/Room';
import { withSnackbar } from 'notistack';
import { applicationActions } from '../../../../../actions/applicationActions';
import { fieldValidators } from '../../../../../helpers/fieldValidators';
import { renderSelectFieldReg } from '../../../../../components/form/renderSelectFieldReg';
import { dictionaryHelper } from '../../../../../helpers/dictionaryHelper';
import { applicationConstants } from '../../../../../constants/applicationConstants';
import Truncate from '../../../../../components/Truncate';
import RestrictedArea from '../../../../../components/map/RestrictedArea';
import { applicationService } from '../../../../../services/applicationService';
import ApvgksLayer from '../../../../../components/map/ApvksLayer';

function getSuggestionValue(suggestion) {
  return suggestion;
}

function renderSuggestion(suggestion) {
  return <span>{suggestion.display_name}</span>;
}

class ApplicationStepRouteForm extends Component {
  componentDidMount() {
    const { setMap } = this.props;
    setTimeout(() => {
      setMap(this.map);
    }, 1000);
    // setTimeout(initializeMapListeners, 2000);

    const { dispatch, id } = this.props;

    const emptyMarker = {
      suggestions: [],
      text: '',
      coords: null,
      isEmpty: true
    };

    if (id) {
      dispatch(applicationActions.getRouteItem(id));
    }
  }

  render() {
    const stamenTonerTiles = 'https://tile.aisktg.ru/tatarstan/{z}/{x}/{y}.png';
    // const stamenTonerTiles = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
    const stamenTonerAttr = '';

    const {
      handleSubmit,
      userApplications,
      onLocationPickerChange,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      addMarker,
      addMapMarker,
      removeMarker,
      handleMarkerMove
    } = this.props;
    const { routeStep, currentItem } = userApplications;
    const { markers, mapCenter, zoomLevel } = routeStep;

    return (
      <main role="main" className="container">
        {currentItem && (
          <form onSubmit={handleSubmit}>
            {(!currentItem.privilege_status_id || currentItem.privilegeStatus &&currentItem.privilegeStatus.coefficient > 0) && (
              <div className="row">
                <div className="col-md-12">
                  <Map
                    center={mapCenter}
                    zoom={zoomLevel}
                    ref={map => {
                      this.map = map;
                    }}
                    onClick={addMapMarker}
                  >
                    <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
                    <RestrictedArea />
                    <ApvgksLayer />
                    {markers.map((marker, index) => {
                      const position =
                        marker.coords && marker.coords.lat
                          ? [marker.coords.lat, marker.coords.lon]
                          : null;
                      const textPre =
                        index === 0
                          ? 'Отправление:'
                          : index === markers.length - 1
                          ? 'Прибытие:'
                          : 'Промежуточная точка:';
                      return position ? (
                        <Marker
                          key={`marker-${index}`}
                          position={position}
                          draggable
                          onDragend={e =>
                            handleMarkerMove(e.target.getLatLng(), e.target.options.alt)
                          }
                          alt={index}
                        >
                          <Popup>
                            <span>
                              {textPre} {marker.text}
                            </span>
                          </Popup>
                        </Marker>
                      ) : (
                        ''
                      );
                    })}
                  </Map>
                </div>
                <div className="col-md-12">
                  <div className="card_overflow">
                    <div>
                      <h2 className="h2-title mt-4 margin-bottom-10">Задание маршрута</h2>
                      <p>Движение по региональным дорогам</p>
                      <div className="points_area">
                        <div className="points_div">
                          <div className="points_line" />
                        </div>
                        {markers.map((marker, index) => {
                          if (typeof marker.suggestions === 'undefined' || !marker.suggestions) {
                            marker.suggestions = [];
                          }
                          const onChange = (event, data) => {
                            console.log('onChange', data);
                            return onLocationPickerChange(index, event, data);
                          };
                          const inputProps = {
                            placeholder: 'Введите адрес',
                            value: marker.text,
                            onChange
                          };
                          const fetchSuggestions = ({ value }) => {
                            return onSuggestionsFetchRequested(index, {
                              value
                            });
                          };
                          const clearSuggestions = () => {
                            return onSuggestionsClearRequested(index);
                          };

                          return (
                            <div className="row points mb-3" key={`autosuggest-row-${index}`}>
                              <div className="col-md-1 points-date">
                                {index === 0 && <StartPointIcon />}
                                {index > 0 && index < markers.length - 1 && <PointIcon />}
                                {index === markers.length - 1 && <EndPointIcon className="red" />}
                              </div>
                              <div className="col-md-10">
                                <Autosuggest
                                  key={`autosuggest-${index}`}
                                  suggestions={marker.suggestions}
                                  onSuggestionsFetchRequested={fetchSuggestions}
                                  onSuggestionsClearRequested={clearSuggestions}
                                  getSuggestionValue={getSuggestionValue}
                                  renderSuggestion={renderSuggestion}
                                  inputProps={inputProps}
                                />
                              </div>
                              <div className="col-md-1">
                                <span onClick={() => removeMarker(index)}>
                                  <DeleteIcon />
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <Button className="info-btn no-margin" onClick={addMarker}>
                        + Добавить пункт назначения
                      </Button>
                      <br />
                      <br />
                      <div className="inline-select">
                        <p>
                          <strong>Вид перевозки</strong>
                        </p>
                        <Field
                          className="form-control"
                          name="type_id"
                          component={renderSelectFieldReg}
                          validate={[fieldValidators.required]}
                        >
                          <option key="0" value="">
                            Выберите тип поездки
                          </option>
                          {dictionaryHelper.routeTypes.getList().map(option => (
                            <option key={option.id} value={option.id}>
                              {option.title}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {currentItem.privilege_status_id && (
              <div className="row">
                <div className="col-md-12">
                  <div className="privilege_status">
                    <Truncate maxCount={500}>
                      {currentItem.privilegeStatus && currentItem.privilegeStatus.route_info}
                    </Truncate>
                  </div>
                  {/* <Field className="form-control"
                                   name="route_info"
                                   component={FormTextarea}
                                   label="Описание маршрута"
                                   disabled="disabled"
                                   row="20"
                            /> */}
                </div>
              </div>
            )}
            <br />
            <Button className="btn-add no-margin" type="submit">
              Продолжить
            </Button>
          </form>
        )}
      </main>
    );
  }
}

ApplicationStepRouteForm = reduxForm({
  form: 'application-route-form', // a unique identifier for this form
  enableReinitialize: true
})(ApplicationStepRouteForm);

const mapStateToProps = state => {
  const { userApplications, authentication } = state;
  const { currentItem, routeStep } = userApplications;
  const { route, markers } = routeStep;
  const { user } = authentication;

  return {
    userApplications,
    initialValues: {
      id: currentItem ? currentItem.id : 0,
      type_id: route ? route.type_id : '',
      markers,
      role_id: user.role_id,
      route_info:
        currentItem && currentItem.privilegeStatus ? currentItem.privilegeStatus.route_info : '',
      privilege_status_id: currentItem ? currentItem.privilege_status_id : null
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const onLocationPickerChange = async (index, event, { newValue, method }) => {
    if (method === 'type') {
      dispatch(applicationActions.setLocation(index, newValue));
    }
    if (method === 'click') {
      const isAllowed = await applicationService.checkIsAllowed(newValue.lat, newValue.lon);
      if (!isAllowed) {
        ownProps.enqueueSnackbar('Точка находится в запрещенной зоне', { variant: 'error' });
        return;
      }
      dispatch(applicationActions.setCoords(index, newValue));
      dispatch(applicationActions.setLocation(index, newValue.display_name));

      // set map location
      dispatch(applicationActions.setMapCoords([newValue.lat, newValue.lon]));
    }
  };

  const onSuggestionsFetchRequested = (index, { value }) => {
    if (value.length >= 3) {
      dispatch(applicationActions.searchLocation(index, value));
    }
  };

  const onSuggestionsClearRequested = index => {
    dispatch(applicationActions.setSuggestions(index, []));
  };

  const addMarker = () => {
    dispatch(applicationActions.addMarker());
  };

  const addMapMarker = async e => {
    const isAllowed = await applicationService.checkIsAllowed(e.latlng.lat, e.latlng.lng);
    if (!isAllowed) {
      ownProps.enqueueSnackbar('Точка находится в запрещенной зоне', { variant: 'error' });
      return;
    }
    dispatch(applicationActions.addMarker(e.latlng));
    // dispatch request to get location by coords
    // todo get markers length
    dispatch(applicationActions.getLocationByCoords(e.latlng.lat, e.latlng.lng, null));
  };

  const removeMarker = index => {
    dispatch(applicationActions.removeMarker(index));
  };

  const setMap = map => {
    dispatch(applicationActions.setMap(map));
  };

  const handleMarkerMove = async (latLng, index) => {
    console.log('handleMarkerMove Lat', latLng);
    console.log('handleMarkerMove index', index);
    const isAllowed = await applicationService.checkIsAllowed(latLng.lat, latLng.lng);
    if (!isAllowed) {
      ownProps.enqueueSnackbar('Точка находится в запрещенной зоне', { variant: 'error' });
      return;
    }
    dispatch(applicationActions.getLocationByCoords(latLng.lat, latLng.lng, index, true));
  };

  return {
    onLocationPickerChange,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    addMarker,
    addMapMarker,
    removeMarker,
    handleMarkerMove,
    dispatch,

    setMap
  };
};

const connectedApplicationStepRouteForm = withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ApplicationStepRouteForm)
);
export { connectedApplicationStepRouteForm as ApplicationStepRouteForm };
