import React from 'react';

export const FormTextarea = ({ disabled, input, label, type, row, meta: { touched, error } }) => (
  <div className={`form-group${touched && error ? ' has-error' : ''}`}>
    <div className="">
      <textarea
        disabled={disabled}
        className={`form-control${touched && error ? ' form-control-error' : ''}`}
        {...input}
        placeholder={label}
        type={type}
        rows={row}
      />
      {touched && (error && <span>{error}</span>)}
    </div>
  </div>
);
