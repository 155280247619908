import axios from 'axios';
import { SubmissionError } from 'redux-form';
import { apiConstants } from '../constants/apiConstants';
import { maskHelper } from '../helpers/maskHelper';
import { history } from '../helpers/history';

const call = (method, url, data = {}, needAuth = false, config = {}, withHandlers = true) => {
  let promise = null;
  switch (method) {
    case apiConstants.METHOD_GET:
      promise = get(url, needAuth, config, data);
      break;
    case apiConstants.METHOD_POST:
      promise = post(url, data, needAuth, config);
      break;
    case apiConstants.METHOD_PUT:
      promise = put(url, data, needAuth, config);
      break;
    case apiConstants.METHOD_DELETE:
      promise = _delete(url, needAuth, config);
      break;
    default:
      return null;
  }

  if (promise && withHandlers) {
    return promise.then(handleResponse).catch(handleError);
  }

  return promise || null;
};

const get = (url, needAuth, config = {}, data = {}) => {
  return axios.get(handleUrl(url), {...prepareConfig(needAuth, config), params: data});
};

const post = (url, data = {}, needAuth = false, config = {}) => {
  return axios.post(handleUrl(url), prepareData(data), prepareConfig(needAuth, config));
};

const put = (url, data = {}, needAuth = false, config = {}) => {
  return axios.put(handleUrl(url), prepareData(data), prepareConfig(needAuth, config));
};

const _delete = (url, needAuth = false, config = {}) => {
  return axios.delete(handleUrl(url), prepareConfig(needAuth, config));
};

const downloadFile = (url, save_name, openFile) => {
    return new Promise((res,rej) => call(apiConstants.METHOD_GET, url, undefined, true, { responseType: 'blob' })
        .then(response => URL.createObjectURL(response))
        .then(uril => {
            if (openFile) {
                window.open(uril, "window", "width=800,height=1000");
                return;
            }
            var link = document.createElement("a");
            link.href = uril;
            link.download = save_name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            res();
        })
        .catch(e => {
          console.log(e);
          rej(e);
        }));
}

/**
 * Handle api url, add api root if necessary
 * @param endpoint
 * @returns {string}
 */
export const handleUrl = endpoint => {
  if (endpoint.indexOf('http') !== -1) {
    return endpoint;
  }
  return endpoint.indexOf(apiConstants.API_URL) === -1 ? apiConstants.API_URL + endpoint : endpoint;
};

/**
 * Handle data before call
 * @param data
 * @returns {*}
 */
const prepareData = data => {
  return {
    ...data,
    phone: maskHelper.maskPhone(data.phone)
  };
};

/**
 * @param needAuth
 * @param config
 * @returns {*}
 */
export const prepareConfig = (needAuth, config = {}) => {
  // config.validateStatus = function (status) {
  //     if(status === 401){
  //         history.push('/logout');
  //     }
  // };

  if (needAuth) {
    return { ...config, headers: prepareHeaders(needAuth, config.headers) };
  }
  return config;
};

/**
 *
 * @param needAuth
 * @param headers
 * @returns {*}
 */
const prepareHeaders = (needAuth = false, headers = {}) => {
  // @todo get from state?
  const user = JSON.parse(localStorage.getItem('user'));

  if (needAuth && user) {
    return { ...headers, Authorization: `Bearer ${user.api_token}` };
  }
  return headers;
};

/**
 * Base handle errors
 * @param response
 * @returns
 */
export const handleResponse = response => {
  if (!isResponseSuccessfull(response)) {
    return Promise.reject(response.statusText);
  }
  // return Promise.resolve(response.data);
  return response.data;
};

const isResponseSuccessfull = response => {
  return response.status && response.status >= 200 && response.status < 300;
};

/**
 * Standard errors handle
 * @param error
 * @param message
 */
export const handleError = (error, message = 'Неверно заполнены поля формы!') => {
  if (typeof error.response !== 'undefined' && error.response.status === 422) {
    throw new SubmissionError({
      ...error.response.data.errors,
      ...error.response.data,
      _error: error.response.data.error || message
    });
  }

  // logout if 401 error
  if (typeof error.response !== 'undefined' && error.response.status === 401) {
    history.push('/logout');
  }

  throw new SubmissionError({
    _error: 'Произошла ошибка, попробуйте позже!'
  });
};

export const apiService = {
  call,
  get,
  downloadFile,
};
