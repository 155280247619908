import React from 'react';

const PersonalPage = props => {
  return (
    <main role="main" className="container text-white text-left">
      <div className="kyc">
        <h1 className="welcome">Согласие пользователя на обработку персональных данных</h1>

        <div className="about_system">
          <p>
            Предоставляя свои данные, Пользователь дает согласие на обработку, хранение и
            использование своих персональных данных в соответствии с требованиями статей 23, 24
            Конституции Российской Федерации, статьей 9 Федерального закона от 27.07.2006г. №152-ФЗ
            «О персональных данных» государственному бюджетному учреждению «Безопасность дорожного
            движения», расположенному по адресу: г. Казань, ул. Оренбургский тракт, д. 5
          </p>
          <p>
            Под персональными данными подразумевается любая информация личного характера,
            позволяющая установить личность Пользователя такая как:
            <br />
            1. фамилия, имя, отчество;
            <br />
            2. год, месяц, дата и место рождения;
            <br />
            3. рабочий и мобильный номер телефона, адрес электронной почты;
            <br />
            4. индивидуальный номер налогоплательщика (ИНН);
            <br />
            5. адрес регистрации и фактического проживания и контактные телефоны;
            <br />
            6. сведения, содержащиеся в паспорте или ином документе, удостоверяющем личность;
            <br />
            7. сведения, содержащиеся в свидетельстве о регистрации транспортного средства.
            <br />
          </p>
          <p>
            Вышеприведенное согласие на обработку персональных данных представлено с учетом п. 2
            ст. 6 и п. 2 ст. 9 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных"
            (ред. от 23.12.2010), в соответствии с которыми обработка персональных данных может
            осуществляться без дополнительного согласия Пользователя.
          </p>
          <p>
            Настоящее согласие действительно с момента предоставления персональных данных и до
            истечения сроков хранения соответствующей информации или документов, содержащих
            вышеуказанную информацию, определяемых в соответствии с законодательством Российской
            Федерации.
          </p>
          <p>
            Согласие может быть полностью или частично отозвано Пользователем персональных данных на
            основании его личного заявления.
          </p>
          <p>
            Персональные данные Пользователей хранятся исключительно на электронных носителях и
            обрабатываются с использованием автоматизированных систем, за исключением случаев, когда
            неавтоматизированная обработка персональных данных необходима в связи с исполнением
            требований законодательства.
          </p>
          <p>
            Компания оставляет за собой право вносить изменения в одностороннем порядке в настоящие
            правила, при условии, что изменения не противоречат действующему законодательству РФ.
            Изменения условий настоящих правил вступают в силу после их публикации на Сайте.
          </p>
        </div>
      </div>
    </main>
  );
};

export default PersonalPage;
