import mom from 'moment';
import moment from 'moment-timezone';

require('moment-business-days');

const parseMoscowTime = timeString => {
  return moment.utc(timeString).add(-3, 'hours');
};

const getLocalDatetime = (moscowTimeString, format = 'DD.MM.YYYY HH:mm') => {
  const myTz = moment.tz.guess();

  const moscowTime = parseMoscowTime(moscowTimeString);
  const localTime = moscowTime.clone().tz(myTz);
  if (localTime.isValid()) {
    return localTime.format(format);
  }
  return moscowTime;
};

const changeDateFormatFromDbToPlugin = date => {
  if (!date) {
    return '';
  }

  const parts = date.split('.');
  if (parts.length === 3) {
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }

  return date;
};

const changeYmdHisTodmY = date => {
  if (!date) {
    return '';
  }

  let parts = date.split(' ');
  if (parts.length === 2) {
    const datePart = parts[0];
    parts = datePart.split('-');
    if (parts.length === 3) {
      return `${parts[2]}.${parts[1]}.${parts[0]}`;
    }
  }

  return date;
};

const getDateFromPicker = value => {
  return Date.parse(value);
};

const getNowDateTime = () => {
  return Date.now() - 24 * 60 * 60 * 1000;
};

const getYearFromDotFormat = date => {
  if (!date) {
    return '';
  }

  const parts = date.split('.');
  if (parts.length === 3) {
    return parts[2];
  }
  return '';
};

const dotFormatToYmd = date => {
  if (!date) {
    return '';
  }

  const parts = date.split('.');
  if (parts.length === 3) {
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  }
  return '';
};

const diffMoreThanNBussinessDays = (date, d) => {
  const now = mom();
  const momDate = mom(date);
  return now.businessDiff(momDate) >= d;
};

const formatDate = date => {
  return moment(date).format('DD.MM.YYYY');
};

export const momentHelper = {
  formatDate,
  getLocalDatetime,
  getNowDateTime,
  changeDateFormatFromDbToPlugin,
  changeYmdHisTodmY,
  getDateFromPicker,
  getYearFromDotFormat,
  dotFormatToYmd,
  diffMoreThanNBussinessDays
};
