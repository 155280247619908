import React from 'react';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';

function ApplicationSchema({ trailers, vehicle }) {
  return (
    <div className="car-character-inner">
      {dictionaryHelper.vehicleTypes.getItemImgFront(vehicle ? vehicle.vehicle_type_id : null) && (
        <img
          alt="Car Front"
          className="car-character"
          src={dictionaryHelper.vehicleTypes.getItemImgFront(vehicle.vehicle_type_id)}
        />
      )}
      {dictionaryHelper.vehicleTypes.getItemImgBody(vehicle ? vehicle.vehicle_type_id : null) && (
        <img
          alt="Cat Body"
          className="car-character"
          src={dictionaryHelper.vehicleTypes.getItemImgBody(vehicle.vehicle_type_id)}
        />
      )}
      {trailers &&
        trailers.length > 0 &&
        trailers.map(trailer =>
          dictionaryHelper.vehicleTypes.getItemImgBody(trailer.vehicle_type_id) ? (
            <img
              key={trailer.id}
              alt="Cat Body"
              className="car-character"
              src={dictionaryHelper.vehicleTypes.getItemImgBody(trailer.vehicle_type_id)}
            />
          ) : (
            ''
          )
        )}
    </div>
  );
}

export default ApplicationSchema;
