import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getByStatuses = () => {
  return apiService.call(apiConstants.METHOD_GET, '/reg-apps', {}, true);
};

const getPageByStatus = (page, status, role, params = {}) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/reg-apps/by-status/${status}/${role}?page=${page}&name=${params.name ? params.name : ''}`,
    {},
    true
  );
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/reg-apps/${id}`, {}, true);
};

const adminUpdate = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/reg-apps/set-status/${values.id}`,
    values,
    true
  );
};

const accept = id => {
  return apiService.call(apiConstants.METHOD_PUT, `/reg-apps/accept/${id}`, { id }, true);
};
const decline = values => {
  return apiService.call(apiConstants.METHOD_PUT, `/reg-apps/decline/${values.id}`, values, true);
};

const lock = id => {
  return apiService.call(apiConstants.METHOD_PUT, `/reg-apps/lock/${id}`, { id }, true);
};
const unlock = id => {
  return apiService.call(apiConstants.METHOD_PUT, `/reg-apps/unlock/${id}`, { id }, true);
};

const adminFilter = values => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/reg-apps/admin-filter/${values.status}/${values.role}`,
    values,
    true
  );
};

export const regAppService = {
  getByStatuses,
  getPageByStatus,
  getItem,
  // update,
  adminUpdate,

  accept,
  decline,

  lock,
  unlock,

  adminFilter
};
