import React from 'react';
import { get } from 'lodash';
import ApplicationSectionTitle from './ApplicationSectionTitle';
import ApplicationField from './ApplicationField';
import { momentHelper } from '../../../helpers/momentHelper';

const SpecialConditions = ({ application }) => (
  <React.Fragment>
    <ApplicationSectionTitle>Особые условия движения</ApplicationSectionTitle>
    {application.privilegeStatus && (
      <div>
        <ApplicationField
          value={application.privilegeStatus.title}
          label="Название льготы"
          placeholder="Льготник на ЧМ"
        />
        <div className="row">
          <div className="col-md-5">
            <ApplicationField
              value={momentHelper.changeDateFormatFromDbToPlugin(
                application.privilegeStatus.start_date
              )}
              label="Период действия с"
            />
          </div>
          <div className="col-md-5">
            <ApplicationField
              value={momentHelper.changeDateFormatFromDbToPlugin(
                application.privilegeStatus.privilege_finish_date
              )}
              label="по"
            />
          </div>
        </div>
      </div>
    )}
    {!application.privilegeStatus && (
      <ApplicationField label="Статус" value="Без особого статуса" />
    )}
    {get(application, 'dates.is_penalty') && (
      <React.Fragment>
        <br />
        <ApplicationSectionTitle>Штрафник</ApplicationSectionTitle>
        <ApplicationField value={get(application, 'dates.penalty_post.title')} label="№ ПВК" />
        <ApplicationField value={get(application, 'dates.penalty_number')} label="№ акта" />
        <ApplicationField value={get(application, 'dates.penalty_place')} label="Штрафстоянка" />
        <div id="file-list">
          {get(application, 'files.penalty_files', []).map(file => (
            <div className="form-group" key={file.id}>
              <div className="row">
                <div className="col-md-4">
                  <p className="label-form">Акт</p>
                </div>
                <div className="col-md-8">
                  <a target="_blank" href={file.url}>
                    {file.name}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    )}
  </React.Fragment>
);

export default SpecialConditions;
