import React from 'react';
import { connect } from 'react-redux';
import { MainFooterBlock } from '../main/blocks/MainFooterBlock/MainFooterBlock';
import CabinetMainPage from './main/CabinetMainPage';
import { CabinetMainMenu } from './blocks/CabinetMainMenu/CabinetMainMenu';
import { CabinetLeftMenu } from './blocks/CabinetLeftMenu/CabinetLeftMenu';
import { ClientRoute } from '../../components/routes/ClientRoute';
import VehiclesLayout from './vehicles/VehiclesLayout';
import { userConstants } from '../../constants/userConstants';
import { ApplicationsLayout } from './applications/ApplicationsLayout';
import UsersLayout from './users/UsersLayout';

const CabinetLayout = props => {
  const { match, authentication } = props;

  const links = [{ key: '1', path: `${match.url}`, title: 'Личный кабинет', hasChild: false }];

  if (authentication.user.confirmation_status === userConstants.CONFIRMAtION_STATUS_ACCEPT) {
    links.push({
      key: '2',
      path: `${match.url}/vehicles`,
      title: 'Тс и прицепы',
      hasChild: true
    });
    links.push({
      key: '3',
      path: `${match.url}/applications`,
      title: 'Разрешения',
      hasChild: true
    });
    links.push({
      key: '4',
      path: `${match.url}/users`,
      title: 'Сотрудники',
      hasChild: true
    });
  }
  return (
    <div className="">
      <div className="cabinet-header">
        <CabinetMainMenu />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className=" cabinet-left-menu">
              <CabinetLeftMenu links={links} />
            </div>
          </div>

          <div className="col-md-9 cabinet-content">
            <ClientRoute exact path={`${match.url}`} component={CabinetMainPage} />
            <ClientRoute path={`${match.url}/vehicles`} component={VehiclesLayout} />
            <ClientRoute path={`${match.url}/applications`} component={ApplicationsLayout} />
            <ClientRoute path={`${match.url}/users`} component={UsersLayout} />
          </div>
        </div>
      </div>
      <MainFooterBlock />
    </div>
  );
};

const mapStateToProps = state => {
  const { authentication } = state;
  return {
    authentication
  };
};

export default connect(mapStateToProps)(CabinetLayout);
