import { uniqueId } from 'lodash';
import { formValueSelector } from 'redux-form';

export const createFileUploadHandler = (
  dispatch,
  fileType,
  createSuccessAction,
  errorAction
) => event => {
  Array.from(event.target.files).forEach(file => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileInfo = {
        type: fileType,
        myfile: reader.result,
        name: file.name
      };

      const uploaderId = uniqueId('file_uploader');
      dispatch(createSuccessAction(fileInfo, uploaderId));
    };
    reader.onerror = error => {
      dispatch(errorAction);
      console.log('Error: ', error);
    };
    reader.readAsDataURL(file);
  });
};

export const getFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileInfo = {
        plain: file,
        file: reader.result,
        name: file.name
      };

      resolve(fileInfo);
    };
    reader.onerror = error => {
      reject(error);
      console.log('Error: ', error);
    };
    reader.readAsDataURL(file);
  });
};

export const getOldFiles = (form, getState, field = 'files') => {
  const selector = formValueSelector(form);
  const state = getState();
  return selector(state, field) || [];
};
