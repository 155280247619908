import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { withSnackbar } from 'notistack';
import { axleLoadActions } from '../../../../actions/axleLoadActions';
import AxleLoadsForm from './AxleLoadForm';

const AxleLoadModal = props => {
  const { axleLoads, handleSubmit, enqueueSnackbar, handleModalClose } = props;

  return (
    <Dialog
      open={axleLoads.showFormModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Редактирование нагрузки
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <AxleLoadsForm
          currentItem={axleLoads.currentItem}
          onSubmit={(...args) =>
            handleSubmit(...args).then(() =>
              enqueueSnackbar(`Значения "${axleLoads.currentItem.title}" обновлены`, {
                variant: 'success'
              })
            )
          }
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { axleLoads } = state;

  return {
    axleLoads
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { axleLoads } = stateProps;
  const { dispatch } = dispatchProps;

  const handleSubmit = (values, dispatch) => {
    if (axleLoads.currentItem && axleLoads.currentItem.id) {
      return dispatch(axleLoadActions.update(values));
    }
    return dispatch(axleLoadActions.create(values));
  };

  const handleModalClose = () => {
    dispatch(axleLoadActions.closeFormModal());
  };

  return {
    ...ownProps,
    axleLoads,
    handleSubmit,
    handleModalClose
  };
};

const connectedAxleLoadModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withSnackbar(AxleLoadModal));

export { connectedAxleLoadModal as AxleLoadModal };
