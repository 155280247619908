import React from 'react';
import { AdminRoute } from '../../../../components/routes/AdminRoute';
import StatusesPage from './StatusesPage';
import { StatusVehiclesBlock } from './ts/StatusVehiclesBlock';

const StatusesLayout = props => {
  const { match } = props;

  return (
    <div>
      <AdminRoute exact path={`${match.url}`} component={StatusesPage} />
      <AdminRoute path={`${match.url}/:id/ts`} component={StatusVehiclesBlock} />
    </div>
  );
};

export default StatusesLayout;
