import React from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {connect} from 'react-redux';

const ApvgkAndPostLinkCard = props => {
  const { link, } = props;
  return (
    <Card className="">
      <CardContent>
        <h2 className="h2-title">
          Смена №{link.id}
          <br />
          <small>Статус: {(new Date(link.end_date).getTime() > new Date()) ? 'Активная смена' : 'Завершено'}</small>
        </h2>

        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Период смены</TableCell>
              <TableCell numeric>
                с {new Date(link.created_at).toLocaleString()} до {new Date(link.end_date).toLocaleString()}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Пост АПВГК
              </TableCell>
              <TableCell numeric>{link.apvgk.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Пост</TableCell>
              <TableCell numeric>
                {link.post.title + ' ' + link.post_esn}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Направление на рамку</TableCell>
              <TableCell numeric>Направлен {link.apvgk_direction ? ' на рамку ' : ' от рамки '} {link.apvgk.equipment_seria_number}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Камера расположена к 0 км от АПВГК {link.apvgk.equipment_seria_number}</TableCell>
              <TableCell numeric>{link.place_before_apvgk ? ' Да ' : ' Нет '} </TableCell>
            </TableRow>
          </TableBody>
        </Table>

      </CardContent>
      <CardActions>
        {/*<div className="full-width row">*/}
        {/*  <div className="col-md-6">*/}
        {/*    <h4 className=" blue" />*/}
        {/*  </div>*/}
        {/*  <div className="col-md-6 text-right app_action with_price">*/}
        {/*    {isSmev ? (*/}
        {/*      <Link to={`/control/cooperation/view/${application.id}`}>*/}
        {/*        <Button className="moreinfo-btn">Подробнее</Button>*/}
        {/*      </Link>*/}
        {/*    ) : (*/}
        {/*      <Link to={`/control/applications/view/${application.id}`}>*/}
        {/*        <Button className="moreinfo-btn">Подробнее</Button>*/}
        {/*      </Link>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </CardActions>
    </Card>
  );
};

const mapStateToProps = state => {
  return {

  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const connectedApplicationCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApvgkAndPostLinkCard);
export { connectedApplicationCard as ApvgkAndPostLinkCard };
