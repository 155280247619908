import { reduxForm } from 'redux-form';
import React from 'react';
import AgreementsForm from './AgreementsForm';
import { applicationActions } from '../../../actions/applicationActions';

class AgreementsFormWrapper extends React.Component {
  componentWillMount() {
    this.Filter = reduxForm({
      form: this.props.formName
    })(AgreementsForm);
  }

  render() {
    const { Filter } = this;
    const { application } = this.props;

    const agreementsSubmit = (values, dispatch) => {
      return dispatch(applicationActions.sendToDepartment(application.id, values));
    };
    return <Filter {...this.props} onSubmit={agreementsSubmit} />;
  }
}

export default AgreementsFormWrapper;
