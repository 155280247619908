import React from 'react';
import { ErrorMessage } from 'formik';
import { isObject } from 'lodash';
import Grid from '@material-ui/core/Grid';

const FieldLabel = ({
  name,
  label,
  children,
  labelProps,
  childrenProps,
  boldLabel,
  additional
}) => (
  <Grid container direction="column">
    <Grid item>
      <Grid container direction="row" alignItems="center" spacing={8}>
        <Grid item {...labelProps} style={{ lineHeight: 1 }}>
          <span className={boldLabel ? 'font-weight-bold' : ''}>{label}</span>
        </Grid>
        <Grid item {...childrenProps}>
          {children}
        </Grid>
        {additional && <Grid item>{additional}</Grid>}
      </Grid>
    </Grid>
    <Grid item className="has-error">
      {name && (
        <ErrorMessage name={name}>
          {msg =>
            isObject(msg) ? (
              Object.values(msg).map(m => (
                <span style={{ display: 'block' }} key={m}>
                  {m}
                </span>
              ))
            ) : (
              <span>{msg}</span>
            )
          }
        </ErrorMessage>
      )}
    </Grid>
  </Grid>
);

export default FieldLabel;
