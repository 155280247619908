import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Field, Form, Formik } from 'formik';
import MaterialInput from '../quickApplications/CreateQuickApplication/components/MaterialInput';
import FieldLabel from '../quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialSelect from '../quickApplications/CreateQuickApplication/components/MaterialSelect';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import {apvgkService} from "../../../services/apvgkService";
import MaterialCheckbox from "../quickApplications/CreateQuickApplication/components/MaterialCheckbox";


const StatFilter = () => {
    const [open, setOpen] = useState(false);
    const [apvgks, setApvgks] = useState([]);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    const onCancel = () => {
        handleClose()
    };
    const checkboxHandler = (setField) => async (e) => {
        setField(e.target.name, e.target.checked)
    }
    const onSubmit = (values) => {
        try {
            const response = apvgkService.getStatisticPdf(values);
            return
        } catch (e) {
            console.log(e)
        }
        alert("Ошибка при скачивании")
    }
    return (
        <Grid item>
            <Link

                color="primary"
                to={{}}
                onClick={handleClickOpen}
            >
                Отчет по госзаданию
            </Link>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Фильтровать
                    </Typography>
                    <IconButton color="inherit" onClick={handleClose}>
                        <CloseIcon className="blue" />
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <Formik
                        initialValues={{}}
                        onSubmit={values => {
                            onSubmit(values);
                            handleClose();
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                        render={({ values, setFieldValue }) => (
                            <Form>
                                <Grid container direction="column" spacing={16} wrap="nowrap">
                                    <Grid item>
                                        <FieldLabel label="Дата с" name="min_date">
                                            <Field
                                                name="min_date"
                                                component={MaterialInput}
                                                type="date"
                                                max={values.max_date}
                                                required="required"
                                            />
                                        </FieldLabel>
                                    </Grid>
                                    <Grid item>
                                        <FieldLabel label="Дата до" name="max_date">
                                            <Field
                                                name="max_date"
                                                min={values.min_date}
                                                component={MaterialInput}
                                                type="date"
                                                required="required"
                                            />
                                        </FieldLabel>
                                    </Grid>

                                    <Grid item>
                                        <FieldLabel label="Меньше 20" name="low">
                                            <Field
                                                name="low"
                                                onChange={checkboxHandler(setFieldValue)}
                                                component={MaterialCheckbox}
                                            />
                                        </FieldLabel>
                                    </Grid>

                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="btn-add no-margin"
                                            type="submit"
                                        >
                                            Применить
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            component="span"
                                            className="info-btn"
                                            onClick={onCancel}
                                        >
                                            Очистить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    />
                </DialogContent>
            </Dialog>
        </Grid>
    );
};

export default StatFilter;
