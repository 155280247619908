import React from 'react';
import { get } from 'lodash';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const ControlMarkCard = ({ controlMark, index }) => (
  <Card elevation={1}>
    <CardContent>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Отметка {index + 1}</TableCell>
            <TableCell>{controlMark.updated_at}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Название пункта</TableCell>
            <TableCell>{get(controlMark, 'control_post.title')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>ФИО сотрудника</TableCell>
            <TableCell>{controlMark.user.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Направление</TableCell>
            <TableCell>{controlMark.reverse ? 'В обратную сторону' : 'По маршруту'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Учитывается в проездах</TableCell>
            <TableCell>{controlMark.active ? 'Да' : 'Нет'}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CardContent>
  </Card>
);

export default ControlMarkCard;
