export const auditType = {
  application: 'Заявление',
  user: 'Пользователь',
  vehicle: 'ТС',
  postPick: 'Контрольный пост',
  department: 'Ведомство',
  status: 'Статус',
  coefficient: 'Коэффициент',
  agreement: 'Согласование',
  apvgk_processing: 'Обработки данных с АПВГК',
};
