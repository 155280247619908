import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {Check as CheckIcon, CameraAlt, VideocamOff, PermMedia} from "@material-ui/icons";
import {Loader} from "../../../components";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";


const GrzCheckStatusIcon = ({report}) => {

    const grzResult = report.grz_from_post_result || {};

    const [errOpen, setErrOpen] = useState(false);
    const ErrorDialog = () => <>
        <CloseIcon color={"error"} onClick={() => setErrOpen(true)} style={{cursor:"pointer"}}/>
        {`${grzResult.error}`.indexOf('датчики') >=0 ? <VideocamOff style={{color:"gray"}}/> : null}
        <Dialog open={errOpen} onClose={() => setErrOpen(false)} maxWidth="sm" fullWidth>
            <Toolbar>
                <Typography variant="title" color="inherit" className="flex">
                    Полученная ошибка по запросу № {grzResult.requestId}
                </Typography>
                <IconButton color="inherit" onClick={() => setErrOpen(false)}>
                    <CloseIcon className="blue"/>
                </IconButton>
            </Toolbar>
            <DialogContent>
                {grzResult.error || ("Непредвиденная ошибка: " + report.grz_from_post_result)}
            </DialogContent>
        </Dialog>
    </>;
    if (!grzResult) return null;
    return (
        <div style={{display: "flex", alignItems: "center", float: "right"}}>
            {grzResult.error ? <ErrorDialog/> : (
                grzResult.status === 'COMPLETED'
                    ? <>
                        <CheckIcon style={{color: 'green'}}/>
                        {grzResult.find && (grzResult.find || []).length>0
                            ? <PermMedia style={{color:"gray"}}/>
                            : <CameraAlt style={{color:"gray"}}/>}

                    </>
                    : grzResult.hasOwnProperty('status') && <Loader size={20}/>
            )}
        </div>
    );
};

export default GrzCheckStatusIcon;
