import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { controlPostActions } from '../../../../actions/controlPostActions';
import { Loader } from '../../../../components/Loader';
import TablePagination from '../../../../components/TablePagination';

const ControlPostsTableList = props => {
  const { data, meta, handleChangePage, handleDelete, openUpdateModal } = props;

  return (
    <div>
      <Paper className="overflow">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Серийный номер</TableCell>
              <TableCell numeric>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((n, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{(meta.current_page - 1) * meta.per_page + index + 1}</TableCell>
                  <TableCell>{n.title}</TableCell>
                  <TableCell>{n.equipment_seria_number}</TableCell>
                  <TableCell numeric>
                    {/* <IconButton aria-label="Редактировать"> */}
                    {/* <EditIcon onClick={openUpdateModal(n)} /> */}
                    {/* </IconButton> */}
                    <Button
                      variant="outlined"
                      className="decline-btn"
                      size="small"
                      onClick={handleDelete(n.id)}
                    >
                      {n.deleting ? <Loader /> : <span>Удалить</span>}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination meta={meta} onChangePage={handleChangePage} />
    </div>
  );
};

const mapStateToProps = state => {
  const { controlPosts } = state;

  return {
    controlPosts
  };
};

const mapDispatchToProps = dispatch => {
  const handleChangePage = page => {
    dispatch(controlPostActions.getPage(page));
  };

  const handleDelete = id => {
    return e => dispatch(controlPostActions.delete(id));
  };

  const openUpdateModal = item => {
    return e => {
      dispatch(controlPostActions.setCurrentItem(item));
      dispatch(controlPostActions.openFormModal());
    };
  };

  return {
    handleChangePage,
    handleDelete,
    openUpdateModal
  };
};

const connectedControlPostsTableList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlPostsTableList);
export { connectedControlPostsTableList as ControlPostsTableList };
