import React from 'react';
import Paper from '@material-ui/core/Paper';
import { RouteAddressBlock } from './RouteAddressBlock';

const RouteAddressesPage = props => {
  return (
    <div>
      <Paper>
        <RouteAddressBlock />
        <br />
      </Paper>
    </div>
  );
};

export default RouteAddressesPage;
