import React, { useContext } from 'react';
import { range } from 'lodash';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import ContentContainer from './ContentContainer';
import { QuickApplicationStatus } from '../../../../constants/quickApplicationConstants';
import { SortField } from '../Sort';
import SortableColumn from './SortableColumn';
import { StateContext } from '../../reports/components/StateContext';
import { getApplicationViewData } from './util';
import ApplicationActions from './ApplicationActions';

const Row = ({ viewData, agreement, rowSpan = 1, fill = false, user, application }) => (
  <TableRow key={viewData.id}>
    {fill && <TableCell rowSpan={rowSpan}>{viewData.appNumber}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{viewData.updatedAt}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{viewData.userName}</TableCell>}
    {fill && <TableCell rowSpan={rowSpan}>{viewData.userCreated}</TableCell>}
    <TableCell>{agreement && `${agreement.title}: ${agreement.status}`}</TableCell>
    {fill && (
      <TableCell rowSpan={rowSpan} align="right" className="nowrap">
        <ApplicationActions user={user} item={application} />
      </TableCell>
    )}
  </TableRow>
);

const InProgressContent = () => {
  const { sort, onSortChange } = useContext(StateContext);
  return (
    <ContentContainer
      col={6}
      status={QuickApplicationStatus.InProgress}
      renderHeader={() => (
        <TableRow>
          <SortableColumn column={SortField.Id} onSortChange={onSortChange} sort={sort}>
            № заявления
          </SortableColumn>
          <TableCell>Дата создания</TableCell>
          <SortableColumn column={SortField.Name} onSortChange={onSortChange} sort={sort}>
            Организация / ФИО
          </SortableColumn>
          <TableCell>Сотрудник, создавший заявку</TableCell>
          <TableCell>Согласование</TableCell>
          <TableCell align="right">Действие</TableCell>
        </TableRow>
      )}
      renderBody={(items, user) => {
        return items.map(application => {
          const { agreements = [], ...viewData } = getApplicationViewData(
            application,
            QuickApplicationStatus.InProgress,
            user
          );
          const agreementsLength = agreements.length > 0 ? agreements.length : 1;
          return range(agreementsLength).map(i => (
            <Row
              fill={i === 0}
              rowSpan={agreementsLength}
              viewData={viewData}
              agreement={agreements[i]}
              user={user}
              application={application}
            />
          ));
        });
      }}
    />
  );
};

export default InProgressContent;
