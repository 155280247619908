import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { get, range } from 'lodash';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import StartPointIcon from '@material-ui/icons/FiberManualRecord';
import PointIcon from '@material-ui/icons/Adjust';
import EndPointIcon from '@material-ui/icons/Room';
import IconButton from '@material-ui/core/IconButton';
import { Add, Remove } from '@material-ui/icons';

import { fieldValidators } from '../../../../../helpers/fieldValidators';
import { renderSelectField } from '../../../../../components/form/renderSelectField';
import { FormField } from '../../../../../components/form/FormField';
import { FormFieldNoLabel } from '../../../../../components/form/FormFieldNoLabel';
import { MuiCheckbox } from '../../../../../components/form/MuiCheckbox';
import { momentHelper } from '../../../../../helpers/momentHelper';
import { applicationActions } from '../../../../../actions/applicationActions';
import { applicationConstants } from '../../../../../constants/applicationConstants';
import { Loader } from '../../../../../components/Loader';
import { dictionaryHelper } from '../../../../../helpers/dictionaryHelper';
import { specialConditionActions } from '../../../../../actions/specialConditionActions';
import { applicationHelper } from '../../../../../helpers/applicationHelper';
import Truncate from '../../../../../components/Truncate';
import SelectControlPosts from '../../../../admin/quickApplications/CreateQuickApplication/components/SelectControlPosts';
import { createFileUploadHandler } from '../../../../../helpers/fileUploadHelper';
import { fileConstants } from '../../../../../constants/fileConstants';
import FileUploader from '../../../../../components/form/FileUploader';
import {fieldNormalizers} from "../../../../../helpers/fieldNormalizers";
import {AddRemoveFormField} from "../../../../../components/form/AddRemoveNumberField";

const ControlPostWrapper = ({ input }) => (
  <SelectControlPosts
    field={{ name: input.name, value: input.value }}
    form={{
      setFieldValue: (name, newValue) => input.onChange(newValue),
      setFieldTouched: input.onBlur
    }}
  />
);

class ApplicationStepDatesForm extends Component {
  componentDidMount() {
    const { specialConditions, dispatch, id } = this.props;
    const loadDatesInfo = (dispatch, application, load, route, dates) => {};
    if (id) {
      dispatch(applicationActions.getDatesItem(id, loadDatesInfo));
    }

    if (!specialConditions.currentItem) {
      dispatch(specialConditionActions.getItem(1));
    }
  }

  render() {
    const {
      specialConditions,
      handleSubmit,
      userApplications,
      is_penalty,
      start_date,
      finish_date,
      handleStartDateChange,
      penalty_files,
      handleFileUpload,
      handleFileRemove,
      runsCountRef,
    } = this.props;

    const { currentItem, datesStep } = userApplications;
    const { route } = datesStep;
    const markers = route ? JSON.parse(datesStep.route.points) : [];

    const { load } = datesStep;

    const comment = get(currentItem, 'currentItem.comment');

    const finishDateValidators = () => {
      const validators = get(currentItem, 'is_agricultural') ? [
        fieldValidators.required,
        fieldValidators.dateLaterToday,
        fieldValidators.dateLaterThanStartDate,
        fieldValidators.datesInterval9MonthFromStart,
      ] : [
        fieldValidators.required,
        fieldValidators.dateLaterToday,
        fieldValidators.dateLaterThanStartDate,
        fieldValidators.datesInterval3MonthFromStart,
      ];

      return validators;
    };

    return (
      <div>
        {!currentItem && <Loader />}
        {currentItem && (
          <form onSubmit={handleSubmit}>
            <h2 className="h2-title">Время поездки</h2>

            {comment && (
              <div className="blue_frame">
                <p>{comment}</p>
              </div>
            )}

            <div className="row">
              <br />

              <div className="col-md-6">
                <Card style={{ overflow: 'initial' }}>
                  <CardContent className="step_dates_card">
                    {/* Инфа о тс */}

                    <Table>
                      <TableBody>
                        {currentItem && currentItem.vehicle && (
                          <TableRow>
                            <TableCell>Марка и модель ТС</TableCell>
                            <TableCell>
                              {currentItem.vehicle && currentItem.vehicle.brandTitle}{' '}
                              {currentItem.vehicle && currentItem.vehicle.modelTitle}
                            </TableCell>
                          </TableRow>
                        )}

                        {currentItem && currentItem.vehicle && (
                          <TableRow>
                            <TableCell>ГРЗ</TableCell>
                            <TableCell>{currentItem.vehicle.real_number}</TableCell>
                          </TableRow>
                        )}

                        {currentItem &&
                          currentItem.trailers &&
                          currentItem.trailers.map((trailer, index) => [
                            <TableRow key={`trailer-header-${index}`}>
                              <TableCell>Марка и модель прицепа</TableCell>
                              <TableCell>
                                {trailer && trailer.brandTitle} {trailer && trailer.modelTitle}
                              </TableCell>
                            </TableRow>,
                            <TableRow key={`trailer-value-${index}`}>
                              <TableCell>ГРЗ прицепа</TableCell>
                              <TableCell>{trailer.real_number}</TableCell>
                            </TableRow>
                          ])}
                        <TableRow>
                          <TableCell>Общая масса</TableCell>
                          <TableCell>{load && load.weight}т</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    <br />

                    <p>Укажите срок действия разрешения</p>
                    <div className="row">
                      <div className="col-md-5">
                        <Field
                          name="start_date"
                          type="date"
                          label="Начало действия"
                          className="date_style"
                          component={FormFieldNoLabel}
                          validate={[
                            fieldValidators.required,
                            fieldValidators.dateLaterToday,
                            fieldValidators.dateEarlier3Month
                          ]}
                          // onChange={(event, value) => handleStartDateChange(value)}
                        />
                      </div>
                      <div className="col-md-2 text-center">&mdash;</div>
                      <div className="col-md-5">
                        <Field
                          name="finish_date"
                          type="date"
                          label="Конец действия"
                          className="date_style"
                          component={FormFieldNoLabel}
                          validate={finishDateValidators()}
                        />
                      </div>
                    </div>
                    {/* Предупреждение о весеннем ограничении */}
                    {applicationHelper.checkBeforeSpring(
                      currentItem,
                      specialConditions.currentItem
                    ) && (
                      <span className="danger">
                        Данное разрешение не действует в период весеннего ограничения при условии
                        превышения допустимых нагрузок в размере 6 тонн/ось
                      </span>
                    )}

                    {currentItem.privilege_status_id ? (
                      <Field
                        className="form-control"
                        name="runs_count"
                        component={renderSelectField}
                        validate={[fieldValidators.required]}
                        label="Количество поездок"
                        >
                          {range(1, 11).map(option => (
                              <option key={`option-${option}`} value={option}>
                                {option}
                              </option>
                          ))}
                      </Field>
                    ) : (
                      <Field
                        className="form-control"
                        name="runs_count"
                        label="Количество поездок"
                        component={AddRemoveFormField}
                        props={{min:1, max:2000000}}
                        normalize={fieldNormalizers.number}
                        validate={[
                            fieldValidators.required,
                            fieldValidators.number,
                            fieldValidators.minValue0
                        ]}
                    />)}
                    <Field
                      className="form-control"
                      name="issue_place_id"
                      component={renderSelectField}
                      label="Территориальное управление ГБУ БДД"
                      validate={[fieldValidators.required]}
                    >
                      <option key="0" value="">
                        Выберите пункт выдачи
                      </option>
                      {dictionaryHelper.issuePlaces.getList().map(option => (
                        <option key={option.id} value={option.id}>
                          {option.title}
                        </option>
                      ))}
                    </Field>

                    {/* Штрафник */}
                    <Field
                      name="is_penalty"
                      type="checkbox"
                      label="Штрафник"
                      className="checkbox"
                      component={MuiCheckbox}
                    />

                    {is_penalty && (
                      <div className="form-group">
                        <div className="form-group">
                          <Field
                            name="penalty_post_id"
                            label="№ ПВК"
                            className=""
                            component={ControlPostWrapper}
                            validate={[fieldValidators.required]}
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            name="penalty_number"
                            type="text"
                            label="№ акта"
                            className=""
                            component={FormField}
                            validate={[
                              fieldValidators.required,
                              fieldValidators.number,
                              fieldValidators.maxLength10
                            ]}
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            name="penalty_place"
                            type="text"
                            label="Штрафстоянка"
                            className=""
                            component={FormField}
                            validate={[fieldValidators.required, fieldValidators.maxLength150]}
                          />
                        </div>
                        <p>Акт:</p>

                        <FileUploader
                          className=""
                          entity="penalty"
                          inputClassName="file_input"
                          buttonText="+ Добавить файл"
                          fileList={penalty_files}
                          handleFileChange={handleFileUpload}
                          handleFileRemove={handleFileRemove}
                          hasError={userApplications.uploadPenaltyError}
                          uploaders={userApplications.penaltyUploaders}
                        />
                      </div>
                    )}
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-6">
                {!currentItem.privilege_status_id && (
                  <div className="points_area">
                    <div className="points_div">
                      <div className="points_line" />
                    </div>
                    {/* Маршрут */}
                    {markers &&
                      markers.map((marker, index) => (
                        <div className="row points-date">
                          <div className="col-md-1 col-xs-2">
                            {index === 0 && <StartPointIcon />}
                            {index > 0 && index < markers.length - 1 && <PointIcon />}
                            {index === markers.length - 1 && <EndPointIcon className="red" />}
                          </div>
                          <div className="col-md-11 col-xs-10" key={`markers-${index}`}>
                            <Field
                              name=""
                              label="Начало действия"
                              className=""
                              disabled="disabled"
                              placeholder={marker.text}
                              component={FormFieldNoLabel}
                            />
                            {/* <span>{marker.text}</span> */}
                          </div>
                        </div>
                      ))}
                  </div>
                )}
                {currentItem.privilege_status_id && (
                  <div className="col-md-12">
                    <Field
                      className="form-control"
                      name="route_info"
                      component={({ input: { value } }) => (
                        <div className="privilege_status">
                          <Truncate maxCount={500}>{value}</Truncate>
                        </div>
                      )}
                      label="Описание маршрута"
                      disabled="disabled"
                      row="19"
                    />
                  </div>
                )}
              </div>
            </div>
            <br />
            <Field
              name="is_template"
              type="checkbox"
              label="Сохранить заявление как шаблон"
              className="checkbox"
              component={MuiCheckbox}
            />
            <Button className="btn-add no-margin" type="submit">
              {currentItem && currentItem.status === applicationConstants.STATUS_DECLINE
                ? 'Отправить на повторную обработку'
                : 'Завершить'}
            </Button>
          </form>
        )}
      </div>
    );
  }
}

ApplicationStepDatesForm = reduxForm({
  form: 'application-dates-form', // a unique identifier for this form
  enableReinitialize: true
})(ApplicationStepDatesForm);

const mapStateToProps = state => {
  const { userApplications, authentication, specialConditions } = state;
  const { user } = authentication;
  const { currentItem, datesStep } = userApplications;
  const { dates } = datesStep;

  const selector = formValueSelector('application-dates-form');
  const { is_penalty, start_date, finish_date, penalty_files } = selector(
    state,
    'is_penalty',
    'start_date',
    'finish_date',
    'penalty_files',
    'files'
  );
  const runsCountRef = React.createRef();
  return {
    specialConditions,
    userApplications,
    is_penalty,
    start_date,
    finish_date,
    runsCountRef,
    penalty_files: penalty_files || [],
    initialValues: {
      penalty_files: get(currentItem, 'files.penalty_files', []),
      id: currentItem ? currentItem.id : 0,
      start_date: currentItem
        ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.start_date)
        : '',
      finish_date: currentItem
        ? momentHelper.changeDateFormatFromDbToPlugin(currentItem.finish_date)
        : '',
      runs_count: dates ? dates.runs_count : 1,
      issue_place_id: dates ? dates.issue_place_id : '',
      is_penalty: dates ? dates.is_penalty : '',
      penalty_number: dates ? dates.penalty_number : '',
      penalty_place: dates ? dates.penalty_place : '',
      penalty_post_id: dates ? dates.penalty_post_id : '',
      is_template: currentItem ? currentItem.is_template : '',
      role_id: user.role_id,
      route_info:
        currentItem && currentItem.privilegeStatus ? currentItem.privilegeStatus.route_info : ''
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { id: applicationId } = ownProps;
  const { dispatch } = dispatchProps;
  const { userApplications, penalty_files = [] } = stateProps;
  const { currentItem } = userApplications;

  const handleFileUpload = createFileUploadHandler(
    dispatch,
    fileConstants.TYPE_PENALTY,
    (fileInfo, uploaderId) =>
      applicationActions.uploadPenaltyFile(applicationId, fileInfo, uploaderId),
    { type: applicationConstants.LOAD_PENALTY_FAILURE }
  );

  const handleFileRemove = id => {
    dispatch(applicationActions.removePayFile(id));
    dispatch(
      change(
        'application-dates-form',
        'penalty_files',
        penalty_files.filter(item => item.id !== id)
      )
    );
  };

  const handleStartDateChange = value => {
    if (!currentItem.privilege_status_id) {
      return;
    }

    const finishDate = new Date(value);
    finishDate.setDate(finishDate.getDate() + 9);

    const dateStr = `${finishDate.getFullYear()}-${`0${finishDate.getMonth() + 1}`.slice(
      -2
    )}-${`0${finishDate.getDate()}`.slice(-2)}`;

    dispatch(change('application-dates-form', 'finish_date', dateStr));
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    handleFileUpload,
    handleFileRemove,
    handleStartDateChange
  };
};

const connectedApplicationStepDatesForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationStepDatesForm);
export { connectedApplicationStepDatesForm as ApplicationStepDatesForm };
