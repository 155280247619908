import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import SpringForm from './SpringForm';
import { specialConditionActions } from '../../../../actions/specialConditionActions';
import { specialConditionConstants } from '../../../../constants/specialConditionConstants';
import { AxleLoadBlock } from './AxleLoadBlock';
import { axleLoadActions } from '../../../../actions/axleLoadActions';

const conditionSubmit = (values, dispatch) => {
  return dispatch(specialConditionActions.setItem(values));
};

class SpringPage extends Component {
  componentDidMount() {}

  render() {
    const { specialConditions, handleTabChange } = this.props;
    const { tabIndex } = specialConditions;

    return (
      <div>
        <Paper>
          <AppBar position="static" color="default" className="tabs_header">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className="tab_style_inner"
              variant="fullWidth"
            >
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Стандартные нагрузки"
                value={specialConditionConstants.TAB_DEFAULT}
              />
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Весеннее ограничение"
                value={specialConditionConstants.TAB_SPRING}
              />
            </Tabs>
          </AppBar>
          {tabIndex === specialConditionConstants.TAB_SPRING && [
            <br />,
            <SpringForm onSubmit={conditionSubmit} />
          ]}
          <br />
          <AxleLoadBlock />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { specialConditions } = state;

  return {
    specialConditions
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { specialConditions } = stateProps;
  const { tabIndex } = specialConditions;
  const { dispatch } = dispatchProps;

  const handleTabChange = (event, tabIndexNew) => {
    if (tabIndex !== tabIndexNew) {
      dispatch(specialConditionActions.setTab(tabIndexNew));
      dispatch(axleLoadActions.getPage(1, tabIndexNew));
      // dispatch(change('application-admin-filter-form', 'status', tabIndex));
    }
  };

  return {
    specialConditions,
    dispatch,
    handleTabChange
  };
};

const connectedSpringPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(SpringPage);

export { connectedSpringPage as SpringPage };
