import React, { useEffect } from 'react';
import { Marker, Polygon, Popup } from 'react-leaflet';
import { icon } from 'leaflet';
import { applicationService } from '../../services/applicationService';

export const iconOptions = {
  iconSize: [55, 33],
  iconAnchor: [12, 41]
};

const customIcon = icon({
  iconUrl: 'https://activemap.aisktg.ru/dictionary/icons/120/view',
  ...iconOptions
});

const ApvgksLayer = () => {
  const [markers, setPolygons] = React.useState([]);
  useEffect(() => {
    async function loadApvgks() {
      const areas = await applicationService.getApvkgs();
      setPolygons(areas);
    }
    loadApvgks();
  }, []);
  if (markers.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      {markers.map(r => (
        <Marker key={r.name} position={r.coordinates} alt={r.name} icon={customIcon}>
          <Popup>
            <span>{r.name}</span>
          </Popup>
        </Marker>
      ))}
    </React.Fragment>
  );
};

export default ApvgksLayer;
