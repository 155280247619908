import React from 'react';
import { FirmuserRoute } from '../../../components/routes/FirmuserRoute';

import { VehiclesUpdatePage } from '../../cabinet/vehicles/VehiclesUpdatePage';
import VehicleViewPage from '../../admin/vehicles/VehicleViewPage';
import { VehiclesListPage } from '../../cabinet/vehicles/VehiclesListPage';

const VehiclesLayout = ({ match }) => (
  <div>
    <FirmuserRoute exact path={`${match.url}/`} component={VehiclesListPage} />
    <FirmuserRoute exact path={`${match.url}/info/:id`} component={VehicleViewPage} />
    <FirmuserRoute exact path={`${match.url}/update/:id`} component={VehiclesUpdatePage} />
  </div>
);

export default VehiclesLayout;
