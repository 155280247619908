import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { applicationActions } from '../../../../../actions/applicationActions';
import ApplicationMyVehiclesSearchForm from './ApplicationMyVehiclesSearchForm';
import { ApplicationMyVehiclesList } from './ApplicationMyVehiclesList';

const ApplicationMyVehiclesModal = props => {
  const { userApplications, handleModalClose } = props;

  return (
    <Dialog
      open={userApplications.showMyVehiclesModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-left">
        <Typography variant="title" color="inherit" className="flex">
          Выберите ТС / прицеп
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog">
        <ApplicationMyVehiclesSearchForm />
        <ApplicationMyVehiclesList />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { userApplications } = state;

  return {
    userApplications
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { userApplications } = stateProps;
  const { dispatch } = dispatchProps;

  const handleModalClose = () => {
    dispatch(applicationActions.closeMyVehiclesModal());
  };

  return {
    ...ownProps,
    userApplications,
    handleModalClose
  };
};

const connectedApplicationMyVehiclesModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationMyVehiclesModal);

export { connectedApplicationMyVehiclesModal as ApplicationMyVehiclesModal };
