import { roleConstants } from '../constants/roleConstants';
import { applicationConstants } from '../constants/applicationConstants';

const getItemById = (items, id) => {
  const result = items.find(item => {
    try {
      return item.id === parseInt(id, 10) || item.id === id.toString();
    } catch (e) {
      return false;
    }
  });
  if (typeof result === 'undefined') {
    return null;
  }
  return result;
};
const readProperty = (item, propertyName, defaultValue = null) => {
  return item ? item[propertyName] : defaultValue;
};
const readArrayProperty = (item, propertyName, index, defaultValue = null) => {
  return item && item[propertyName] ? item[propertyName][index] : defaultValue;
};

// isTrailer
const isTrailerGetList = () => {
  return [{ id: 0, title: 'ТС' }, { id: 1, title: 'Прицеп' }];
};
const isTrailerGetItem = id => {
  const items = isTrailerGetList();
  return getItemById(items, id);
};
const isTrailerGetItemTitle = id => {
  const item = isTrailerGetItem(id);
  return readProperty(item, 'title');
};
const isTrailer = {
  getList: isTrailerGetList,
  getItem: isTrailerGetItem,
  getItemTitle: isTrailerGetItemTitle
};

export const roles = {
  [roleConstants.ROLE_ADMIN]: 'Администратор',
  [roleConstants.ROLE_OFFICER]: 'Сотрудник ГБУ БДД',
  [roleConstants.ROLE_INDIVIDUAL]: 'Физическое лицо',
  [roleConstants.ROLE_FIRM]: 'Юридическое лицо',
  [roleConstants.ROLE_FIRMUSER]: 'Сотрудник организации',
  [roleConstants.ROLE_DEPARTMENT_AGENT]: 'Сотрудник ведомства',
  [roleConstants.ROLE_WEIGHT_CONTROL]: 'Сотрудник ПВК',
  [roleConstants.ROLE_APVGK]: 'Сотрудник обработки данных с АПВГК',
  [roleConstants.ROLE_ACCOUNTANT]: 'Бухгалтер',
  [roleConstants.ROLE_TRACK_CHECKER]: 'Сотрудник проверки проездов',
};

// user Roles
const userRolesGetList = () =>
  Object.keys(roles).map(role => ({
    id: role,
    title: roles[role]
  }));

const userRolesGetAdminList = () => {
  return [
    { id: roleConstants.ROLE_ADMIN, title: 'Администратор' },
    { id: roleConstants.ROLE_OFFICER, title: 'Сотрудник ГБУ БДД' },
    { id: roleConstants.ROLE_DEPARTMENT_AGENT, title: 'Сотрудник ведомства' },
    { id: roleConstants.ROLE_WEIGHT_CONTROL, title: 'Сотрудник ПВК' },
    { id: roleConstants.ROLE_APVGK, title: 'Сотрудник обработки данных с АПВГК' },
    { id: roleConstants.ROLE_ACCOUNTANT, title: 'Бухгалтер' },
    { id: roleConstants.ROLE_TRACK_CHECKER, title: 'Сотрудник проверки проездов' },
  ];
};
const userRolesGetItem = id => {
  const items = userRolesGetList();
  return getItemById(items, id);
};
const userRolesGetItemTitle = id => {
  const item = userRolesGetItem(id);
  return readProperty(item, 'title');
};
const userRoles = {
  getList: userRolesGetList,
  getAdminList: userRolesGetAdminList,
  getItem: userRolesGetItem,
  getItemTitle: userRolesGetItemTitle
};

// vehicleBrands
const vehicleTypesGetList = (is_trailer = null) => {
  const items = [
    // тс
    {
      id: 1,
      is_trailer: 0,
      title: 'Одиночный грузовой двухосный автомобиль',
      axle_count: 2,
      imgFront: require('../containers/App/img/car/front.png'),
      imgBody: require('../containers/App/img/car/car-1.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '112px' },
        { left: '532px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '420px', left: '112px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 2,
      is_trailer: 0,
      title: 'Одиночный грузовой трехосный автомобиль',
      axle_count: 3,
      imgFront: require('../containers/App/img/car/front.png'),
      imgBody: require('../containers/App/img/car/car-2.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '112px' },
        { left: '447px' },
        { left: '542px' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '325px', left: '112px' },
        { width: '95px', left: '447px' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 3,
      is_trailer: 0,
      title: 'Одиночный грузовой четырехосный автомобиль (2+2)',
      axle_count: 4,
      imgFront: require('../containers/App/img/car/front.png'),
      imgBody: require('../containers/App/img/car/car-3.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '112px' },
        { left: '265px' },
        { left: '445px' },
        { left: '542px' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '153px', left: '112px' },
        { width: '180px', left: '265px' },
        { width: '97px', left: '445px' },
        { display: 'none' }
      ]
    },

    {
      id: 4,
      is_trailer: 0,
      title: 'Одиночный грузовой четырехосный автомобиль (1 + 3)',
      axle_count: 4,
      imgFront: require('../containers/App/img/car/front.png'),
      imgBody: require('../containers/App/img/car/car-4.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '112px' },
        { left: '407px' },
        { left: '495px' },
        { left: '584px' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '295px', left: '112px' },
        { width: '88px', left: '407px' },
        { width: '89px', left: '495px' },
        { display: 'none' }
      ]
    },

    {
      id: 5,
      is_trailer: 0,
      title: 'Одиночный грузовой пятиосный автомобиль',
      axle_count: 5,
      imgFront: require('../containers/App/img/car/front.png'),
      imgBody: require('../containers/App/img/car/car-5.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '112px' },
        { left: '268px' },
        { left: '350px' },
        { left: '520px' },
        { left: '610px' }
      ],
      carDistanceStyle: [
        { width: '161px', left: '112px' },
        { width: '72px', left: '268px' },
        { width: '170px', left: '350px' },
        { width: '90px', left: '520px' }
      ]
    },

    {
      id: 7,
      is_trailer: 0,
      title: 'Тягач двухосный',
      axle_count: 2,
      imgFront: require('../containers/App/img/car/front.png'),
      imgBody: require('../containers/App/img/car/carbody-2.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '115px' },
        { left: '300px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '185px', left: '115px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 8,
      is_trailer: 0,
      title: 'Тягач трехосный',
      axle_count: 3,
      imgFront: require('../containers/App/img/car/front.png'),
      imgBody: require('../containers/App/img/car/carbody-3.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '115px' },
        { left: '288px' },
        { left: '370px' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '173px', left: '115px' },
        { width: '82px', left: '288px' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 9,
      is_trailer: 0,
      title: 'Тягач четырехосный',
      axle_count: 4,
      imgFront: require('../containers/App/img/car/front.png'),
      imgBody: require('../containers/App/img/car/carbody-4.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '115px' },
        { left: '292px' },
        { left: '375px' },
        { left: '458px' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '177px', left: '115px' },
        { width: '83px', left: '292px' },
        { width: '83px', left: '375px' },
        { display: 'none' }
      ]
    },

    // прицепы trailer-1
    {
      id: 10,
      is_trailer: 1,
      title: 'Прицеп одноосный',
      axle_count: 1,
      imgFront: require('../containers/App/img/car/back-1.png'),
      imgBody: require('../containers/App/img/car/trailer-1.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '203px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { display: 'none' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 11,
      is_trailer: 1,
      title: 'Прицеп двухосный (оси рядом)',
      axle_count: 2,
      imgFront: require('../containers/App/img/car/back-1.png'),
      imgBody: require('../containers/App/img/car/trailer-2.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '162px' },
        { left: '250px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '88px', left: '162px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 12,
      is_trailer: 1,
      title: 'Прицеп двухосный (оси разделены)',
      axle_count: 2,
      imgFront: require('../containers/App/img/car/back-1.png'),
      imgBody: require('../containers/App/img/car/trailer-3.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '125px' },
        { left: '278px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '153px', left: '125px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 13,
      is_trailer: 1,
      title: 'Прицеп трехосный',
      axle_count: 3,
      imgFront: require('../containers/App/img/car/back-1.png'),
      imgBody: require('../containers/App/img/car/trailer-4.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '125px' },
        { left: '278px' },
        { left: '365px' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '153px', left: '125px' },
        { width: '87px', left: '278px' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 14,
      is_trailer: 1,
      title: 'Прицеп четырехосный',
      axle_count: 4,
      imgFront: require('../containers/App/img/car/back-1.png'),
      imgBody: require('../containers/App/img/car/trailer-5.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '120px' },
        { left: '207px' },
        { left: '338px' },
        { left: '427px' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '87px', left: '120px' },
        { width: '131px', left: '207px' },
        { width: '89px', left: '338px' },
        { display: 'none' }
      ]
    },

    {
      id: 15,
      is_trailer: 1,
      title: 'Полуприцеп одноосный',
      axle_count: 1,
      imgFront: require('../containers/App/img/car/back-1.png'),
      imgBody: require('../containers/App/img/car/semitrailer-1.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '440px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { display: 'none' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 16,
      is_trailer: 1,
      title: 'Полуприцеп двухосный',
      axle_count: 2,
      imgFront: require('../containers/App/img/car/back-1.png'),
      imgBody: require('../containers/App/img/car/semitrailer-2.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '352px' },
        { left: '440px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '88px', left: '352px' },
        { display: 'none' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 17,
      is_trailer: 1,
      title: 'Полуприцеп трехосный',
      axle_count: 3,
      imgFront: require('../containers/App/img/car/back-1.png'),
      imgBody: require('../containers/App/img/car/semitrailer-3.png'),
      carWidthStyle: { width: 'calc(100% - 40px)', left: '40px' },
      carLengthStyle: { width: 'calc(100% - 60px)', left: '40px' },
      carHeightStyle: { left: '0px' },
      carAxleStyle: [
        { left: '280px' },
        { left: '370px' },
        { left: '458px' },
        { display: 'none' },
        { display: 'none' }
      ],
      carDistanceStyle: [
        { width: '90px', left: '280px' },
        { width: '88px', left: '370px' },
        { display: 'none' },
        { display: 'none' }
      ]
    },

    {
      id: 18,
      is_trailer: 0,
      title: 'Спецтехника',
      axle_count: 2,
      imgFront: null,
      imgBody: null
    },

    // Other
    {
      id: 19,
      is_trailer: 0,
      title: 'Другое',
      axle_count: 2,
      imgFront: null,
      imgBody: null
    },
    {
      id: 20,
      is_trailer: 1,
      title: 'Другое (Прицеп)',
      axle_count: 2,
      imgFront: null,
      imgBody: null
    },
    {
      id: 21,
      is_trailer: 1,
      title: 'Другое (Полуприцеп)',
      axle_count: 2,
      imgFront: null,
      imgBody: null
    }
  ];
  if (is_trailer !== null) {
    return items.filter(item => item.is_trailer === is_trailer);
  }
  return items;
};
const vehicleTypesGetItem = id => {
  const items = vehicleTypesGetList();
  return getItemById(items, id);
};
const vehicleTypesGetItemTitle = id => {
  const item = vehicleTypesGetItem(id);
  return readProperty(item, 'title');
};
const vehicleTypesGetItemAxleCount = (id, defaultValue = 2) => {
  const item = vehicleTypesGetItem(id);
  return readProperty(item, 'axle_count', defaultValue);
};
const vehicleTypesGetItemImgFront = id => {
  const item = vehicleTypesGetItem(id);
  return readProperty(item, 'imgFront');
};
const vehicleTypesGetItemImgBody = id => {
  const item = vehicleTypesGetItem(id);
  return readProperty(item, 'imgBody');
};

const vehicleTypesGetItemCarWidthStyle = id => {
  const item = vehicleTypesGetItem(id);
  return readProperty(item, 'carWidthStyle');
};
const vehicleTypesGetItemCarLengthStyle = id => {
  const item = vehicleTypesGetItem(id);
  return readProperty(item, 'carLengthStyle');
};
const vehicleTypesGetItemCarHeightStyle = id => {
  const item = vehicleTypesGetItem(id);
  return readProperty(item, 'carHeightStyle');
};

const vehicleGetAxleStyle = (id, num) => {
  const item = vehicleTypesGetItem(id);
  return readArrayProperty(item, 'carAxleStyle', num);
};
const vehicleGetDistanceStyle = (id, num) => {
  const item = vehicleTypesGetItem(id);
  return readArrayProperty(item, 'carDistanceStyle', num);
};

// Trailer ids
const vehicleGetSemitrailerIds = () => {
  return [15, 16, 17, 21];
};
// Tractor ids
const vehicleGetTractorIds = () => {
  return [7, 8, 9];
};
const vehicleTypes = {
  getList: vehicleTypesGetList,
  getItem: vehicleTypesGetItem,
  getItemTitle: vehicleTypesGetItemTitle,
  getItemAxleCount: vehicleTypesGetItemAxleCount,
  getItemImgFront: vehicleTypesGetItemImgFront,
  getItemImgBody: vehicleTypesGetItemImgBody,

  getItemCarWidthStyle: vehicleTypesGetItemCarWidthStyle,
  getItemCarLengthStyle: vehicleTypesGetItemCarLengthStyle,
  getItemCarHeightStyle: vehicleTypesGetItemCarHeightStyle,

  getItemCarAxleStyle: vehicleGetAxleStyle,
  getItemCarDistanceStyle: vehicleGetDistanceStyle,

  getSemiTrailerIds: vehicleGetSemitrailerIds,
  getTractorIds: vehicleGetTractorIds
};

// firm Reasons
const firmReasonGetList = () => {
  return [{ id: 1, title: 'Устав' }, { id: 2, title: 'Доверенность' }];
};
const firmReasonGetItem = id => {
  const items = firmReasonGetList();
  return getItemById(items, id);
};
const firmReasonGetItemTitle = id => {
  const item = firmReasonGetItem(id);
  return readProperty(item, 'title');
};
const firmReasons = {
  getList: firmReasonGetList,
  getItem: firmReasonGetItem,
  getItemTitle: firmReasonGetItemTitle
};

// axle types
const axleTypesGetList = () => {
  return [{ id: 0, title: 'Рессор.' }, { id: 1, title: 'Пневм.' }];
};
const axleTypesGetItem = id => {
  const items = axleTypesGetList();
  return getItemById(items, id);
};
const axleTypesGetItemTitle = id => {
  const item = axleTypesGetItem(id);
  return readProperty(item, 'title');
};
const axleTypes = {
  getList: axleTypesGetList,
  getItem: axleTypesGetItem,
  getItemTitle: axleTypesGetItemTitle
};

// vehicle statuses
const vehicleStatusesGetList = () => {
  return [
    { id: 0, title: 'На рассмотрении', class: '' },
    { id: 1, title: 'Отклонено', class: 'danger' },
    { id: 2, title: 'На рассмотрении', class: '' },
    { id: 3, title: 'Одобрено', class: 'success' }
  ];
};
const vehicleStatusesGetItem = id => {
  const items = vehicleStatusesGetList();
  return getItemById(items, id);
};
const vehicleStatusesGetItemTitle = id => {
  const item = vehicleStatusesGetItem(id);
  return readProperty(item, 'title');
};
const vehicleStatusesGetItemClass = id => {
  const item = vehicleStatusesGetItem(id);
  return readProperty(item, 'class');
};
const vehicleStatuses = {
  getList: vehicleStatusesGetList,
  getItem: vehicleStatusesGetItem,
  getItemTitle: vehicleStatusesGetItemTitle,
  getItemClass: vehicleStatusesGetItemClass
};

// application statuses
const applicationStatusesGetList = () => {
  return [
    { id: 0, title: 'Новое', class: '' },
    { id: 1, title: 'На рассмотрении', class: '' },
    { id: 2, title: 'Отклонено', class: 'danger' },
    { id: 3, title: 'Одобрено с изменениями', class: '' },
    { id: 4, title: 'Одобрено', class: 'success' },
    { id: 5, title: 'Активно', class: 'success' },
    { id: 6, title: 'На повторном рассмотрении', class: 'success' }
  ];
};
const applicationStatusesGetItem = id => {
  const items = applicationStatusesGetList();
  return getItemById(items, id);
};
const applicationStatusesGetItemTitle = (id, is_draft = false, is_user = false) => {
  if (id === applicationConstants.STATUS_NEW && !is_draft && is_user)
    return 'Ожидает подтверждения';
  const item = applicationStatusesGetItem(id);
  return readProperty(item, 'title');
};
const applicationStatusesGetItemClass = id => {
  const item = applicationStatusesGetItem(id);
  return readProperty(item, 'class');
};
const applicationStatuses = {
  getList: applicationStatusesGetList,
  getItem: applicationStatusesGetItem,
  getItemTitle: applicationStatusesGetItemTitle,
  getItemClass: applicationStatusesGetItemClass
};

// organizations
const organizationsGetList = () => {
  return [{ id: 1, title: 'Организация 1' }, { id: 2, title: 'Организация 2' }];
};
const organizationsGetItem = id => {
  const items = organizationsGetList();
  return getItemById(items, id);
};
const organizationsGetItemTitle = id => {
  const item = organizationsGetItem(id);
  return readProperty(item, 'title');
};
const organizationsGetItemClass = id => {
  const item = organizationsGetItem(id);
  return readProperty(item, 'class');
};
const organizations = {
  getList: organizationsGetList,
  getItem: organizationsGetItem,
  getItemTitle: organizationsGetItemTitle,
  GetItemClass: organizationsGetItemClass
};

// applicationSortFields
const applicationSortFieldsGetList = () => {
  return [
    { id: 'users.name', title: 'Название / ФИО' },
    { id: 'v.number', title: 'ГРЗ ТС' },
    { id: 't.number', title: 'ГРЗ трейлера' },
    { id: 'applications.start_address', title: 'Начальная точка маршрута' },
    { id: 'applications.finish_address', title: 'Конечная точка маршрута' },
    { id: 'applications.updated_at', title: 'Дата заявления' }
  ];
};
const applicationSortFieldsGetItem = id => {
  const items = applicationSortFieldsGetList();
  return getItemById(items, id);
};
const applicationSortFieldsGetItemTitle = id => {
  const item = applicationSortFieldsGetItem(id);
  return readProperty(item, 'title');
};
const applicationSortFields = {
  getList: applicationSortFieldsGetList,
  getItem: applicationSortFieldsGetItem,
  getItemTitle: applicationSortFieldsGetItemTitle
};

// applicationTypes
const applicationTypesGetList = () => {
  return [{ id: 0, title: 'Однократная' }, { id: 1, title: 'Многократная' }];
};
const applicationTypesGetItem = id => {
  const items = applicationTypesGetList();
  return getItemById(items, id);
};
const applicationTypesGetItemTitle = id => {
  const item = applicationTypesGetItem(id);
  return readProperty(item, 'title');
};
const applicationTypes = {
  getList: applicationTypesGetList,
  getItem: applicationTypesGetItem,
  getItemTitle: applicationTypesGetItemTitle
};

// load types
export const LoadTypes = [
  { id: 1, title: 'Инертные материалы' },
  { id: 2, title: 'Пищевые продукты' },
  { id: 3, title: 'Металлоконструкция' },
  { id: 4, title: 'Сельхозпродукция' },
  { id: 5, title: 'Горюче-смазочные материалы' },
  { id: 6, title: 'Железо-бетонные изделия' },
  { id: 7, title: 'Пиломатериал' },
  { id: 8, title: 'Опасный груз' },
  { id: 9, title: 'Товары бытового назначения' },
  { id: 10, title: 'Промышленные товары' },
  { id: 11, title: 'Вагончик' },
  { id: 12, title: 'Бытовка' },
  { id: 13, title: 'Специализированная техника' },
  { id: 15, title: 'Животные' },
  { id: 16, title: 'Лекарственные препараты' },
  { id: 17, title: 'Семенной фонд' },
  { id: 18, title: 'Удобрения' },
  { id: 19, title: 'Почта и почтовые грузы' },
  { id: 14, title: 'Другое' }
];

const loadTypesGetList = () => LoadTypes;

const loadTypesGetItem = id => {
  const items = loadTypesGetList();
  return getItemById(items, id);
};

const loadTypesGetItemTitle = id => {
  const item = loadTypesGetItem(id);
  return readProperty(item, 'title');
};

const loadTypes = {
  getList: loadTypesGetList,
  getItem: loadTypesGetItem,
  getItemTitle: loadTypesGetItemTitle
};

export const EscortTitle = [
  { id: 0, title: 'Без сопровождения' },
  { id: 1, title: 'Один автомобиль прикрытия спереди' },
  { id: 2, title: 'Один автомобиль прикрытия спереди и один сзади' },
  { id: 3, title: 'Два автомобиля прикрытия спереди и один сзади' }
];

const escortsGetList = () => {
  return EscortTitle;
};

const escortsGetItem = id => {
  const items = escortsGetList();
  return getItemById(items, id);
};

const escortsGetItemTitle = id => {
  const item = escortsGetItem(id);
  return readProperty(item, 'title');
};

const escorts = {
  getList: escortsGetList,
  getItem: escortsGetItem,
  getItemTitle: escortsGetItemTitle
};

export const RouteTypes = [
  // { id: 1, title: 'По маршруту с грузом, обратно без груза' },
  // { id: 2, title: 'По маршруту с грузом, обратно с грузом' },
  // { id: 3, title: 'По маршруту без груза, обратно с грузом' },
  { id: 4, title: 'По маршруту с грузом' },
  // { id: 5, title: 'По маршруту без груза, обратно без груза' },
  { id: 6, title: 'По маршруту (без груза)' }
];

const routeTypesGetList = () => RouteTypes;

const routeTypesGetItem = id => {
  const items = routeTypesGetList();
  return getItemById(items, id);
};
const routeTypesGetItemTitle = id => {
  const item = routeTypesGetItem(id);
  return readProperty(item, 'title');
};
const routeTypes = {
  getList: routeTypesGetList,
  getItem: routeTypesGetItem,
  getItemTitle: routeTypesGetItemTitle
};

export const IssuePlaces = [
  { id: 1, title: 'г.Казань, Оренбургский тракт, 5' },
  { id: 2, title: 'г.Набережные Челны, проспект Казанский, 72' },
  { id: 3, title: 'г.Альметьевск, ул. Советская,184г' },
  { id: 4, title: 'г.Нурлат, ул.Складская, 2' }
];

const issuePlacesGetList = () => {
  return IssuePlaces;
};
const issuePlacesGetItem = id => {
  const items = issuePlacesGetList();
  return getItemById(items, id);
};
const issuePlacesGetItemTitle = id => {
  const item = issuePlacesGetItem(id);
  return readProperty(item, 'title');
};
const issuePlaces = {
  getList: issuePlacesGetList,
  getItem: issuePlacesGetItem,
  getItemTitle: issuePlacesGetItemTitle
};

const getEmptyMeta = () => {
  return {
    current_page: 1,
    from: 0,
    to: 0,
    last_page: 1,
    total: 1,
    per_page: 7
  };
};

const getAgreementStatus = id => {
  switch (id) {
    case applicationConstants.AGREEMENT_NEW:
      return 'Отправлена на согласование';
    case applicationConstants.AGREEMENT_REVIEW:
      return 'В работе';
    case applicationConstants.AGREEMENT_ACCEPTED:
      return 'Заявка согласована';
    case applicationConstants.AGREEMENT_DECLINED:
      return 'Заявка отклонена';
    default:
      return '';
  }
};

export const EquipmentTypeTitle = [{ id: 0, title: 'Стационарный' }];

const equipmentTypeGetList = () => {
  return EquipmentTypeTitle;
};

const equipmentTypeGetItem = id => {
  const items = equipmentTypeGetList();
  return getItemById(items, id);
};

const equipmentTypeGetItemTitle = id => {
  const item = equipmentTypeGetItem(id);
  return readProperty(item, 'title');
};

const equipmentTypes = {
  getList: equipmentTypeGetList,
  getItem: equipmentTypeGetItem,
  getItemTitle: equipmentTypeGetItemTitle
};
export const paymentTypes = [
    {id: 0, title: 'Гос пошлина'},
    {id: 1, title: 'Вред'},
]

export const dictionaryHelper = {
  isTrailer,
  userRoles,
  vehicleTypes,
  firmReasons,
  axleTypes,
  vehicleStatuses,
  applicationStatuses,
  organizations,

  applicationTypes,
  applicationSortFields,
  loadTypes,
  escorts,
  routeTypes,
  issuePlaces,
  equipmentTypes,
  paymentTypes,
  getEmptyMeta,
  getAgreementStatus
};
