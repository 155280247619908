import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import ContentContainer from './ContentContainer';
import DetailsLink from './DetailsLink';
import ApplicationRestoreButton from '../buttons/ApplicationRestoreButton';
import { getApplicationViewData } from './util';
import { applicationConstants } from '../../../../constants/applicationConstants';
import EmployeeCell from '../EmployeeCell';

const RemovedContent = () => (
  <ContentContainer>
    {(items, user) => (
      <React.Fragment>
        <TableHead>
          <TableRow>
            <TableCell>№ заявления</TableCell>
            <TableCell>Дата удаления</TableCell>
            <TableCell>Организация / ФИО</TableCell>
            <TableCell numeric>Действие</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(application => {
            const { id, appNumber, createdAt, userName } = getApplicationViewData(
              application,
              applicationConstants.STATUS_REMOVED,
              user
            );
            return (
              <TableRow key={id}>
                <TableCell>{appNumber}</TableCell>
                <TableCell>{createdAt}</TableCell>
                <EmployeeCell userName={userName} application={application} />
                <TableCell numeric className="nowrap">
                  <DetailsLink user={user} item={application} />
                  <ApplicationRestoreButton cls="btn-add" application={application} user={user} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </React.Fragment>
    )}
  </ContentContainer>
);

export default RemovedContent;
