import React from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core';

import { getAgreementStatus } from '../../../../constants/cooperationConstants';

const styles = {
  deleteCell: {
    width: 50
  },
  rowRoot: {
    borderTop: 0
  }
};

const AgreementsList = ({ agreements, remove, classes }) => (
  <Table classes={{ root: classes.rowRoot }}>
    <TableBody>
      {agreements.map(agreement => (
        <TableRow key={`agreement-${agreement.id}`}>
          <TableCell>{agreement.department.title}</TableCell>
          <TableCell className="text-right">{getAgreementStatus(agreement.status)}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default withStyles(styles)(AgreementsList);
