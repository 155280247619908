import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { FormFieldNoLabel } from '../../../../../components/form/FormFieldNoLabel';

let ApplicationMyVehiclesSearchForm = props => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={0}>
        <Grid item xs={8}>
          <Field
            name="search"
            type="text"
            label="Поиск"
            placeholder="Например, Mercedes"
            className="search-input"
            component={FormFieldNoLabel}
          />
        </Grid>
        <Grid item xs={4}>
          <button type="submit" className="no-margin btn search-btn info-btn">
            Поиск
          </button>
        </Grid>
      </Grid>
    </form>
  );
};

ApplicationMyVehiclesSearchForm = reduxForm({
  form: 'my-vehicle-search-form', // a unique identifier for this form
  enableReinitialize: true
})(ApplicationMyVehiclesSearchForm);

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

ApplicationMyVehiclesSearchForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationMyVehiclesSearchForm);
export default ApplicationMyVehiclesSearchForm;
