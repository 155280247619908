import React, { Component } from 'react';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';

class ApplicationSchema extends Component {
  render() {
    const { currentItem } = this.props;

    return (
      <div className="car-character-inner">
        {dictionaryHelper.vehicleTypes.getItemImgFront(
          currentItem.vehicle ? currentItem.vehicle.vehicle_type_id : null
        ) && (
          <img
            alt="Car Front"
            className="car-character"
            src={dictionaryHelper.vehicleTypes.getItemImgFront(currentItem.vehicle.vehicle_type_id)}
          />
        )}
        {dictionaryHelper.vehicleTypes.getItemImgBody(
          currentItem.vehicle ? currentItem.vehicle.vehicle_type_id : null
        ) && (
          <img
            alt="Cat Body"
            className="car-character"
            src={dictionaryHelper.vehicleTypes.getItemImgBody(currentItem.vehicle.vehicle_type_id)}
          />
        )}
        {currentItem.trailers &&
          currentItem.trailers.length > 0 &&
          currentItem.trailers.map((trailer, index) =>
            dictionaryHelper.vehicleTypes.getItemImgBody(trailer.vehicle_type_id) ? (
              <img
                alt="Cat Body"
                key={index}
                className="car-character"
                src={dictionaryHelper.vehicleTypes.getItemImgBody(trailer.vehicle_type_id)}
              />
            ) : (
              ''
            )
          )}
      </div>
    );
  }
}

export default ApplicationSchema;
