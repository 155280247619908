
export default function CertificateObj(certObj)
{
    this.cert = certObj;
    this.cert.ValidFromDate.then((r) => {
        this.cert.FromDate = new Date(r)
    })
    this.cert.ValidToDate.then((r) => {
        this.cert.TillDate = new Date(r)
    })
    this.cert.SubjectName.then((r) => {
        this.cert.subject = r
        this.cert.user = {
            name: this.extract(this.cert.subject, 'SN=').replace('SN=', '')
            + this.extract(this.cert.subject, 'G=',).replace('G=', ' '),
            work_position: this.extract(this.cert.subject, 'T=').replace('T=', '')
        }
    })
    this.cert.SerialNumber.then((r) => {
        this.cert.serial_number = r
    })
    this.cert.IssuerName.then((r) => {
        this.cert.issuer = (this.extract(r, 'CN=').replace('CN=', ''));
    })

}

CertificateObj.prototype.check = function(digit)
{
    return (digit<10) ? "0"+digit : digit;
}

CertificateObj.prototype.checkQuotes = function(str)
{
    var result = 0, i = 0;
    for(i;i<str.length;i++)if(str[i]==='"')
        result++;
    return !(result%2);
}

CertificateObj.prototype.extract = function(from, what)
{
    var certName = "";
    var begin = from.indexOf(what);

    if(begin>=0)
    {
        var end = from.indexOf(', ', begin);
        while(end > 0) {
            if (this.checkQuotes(from.substr(begin, end-begin)))
                break;
            end = from.indexOf(', ', end + 1);
        }
        certName = (end < 0) ? from.substr(begin) : from.substr(begin, end - begin);
    }

    return certName;
}

CertificateObj.prototype.DateTimePutTogether = function(certDate)
{
    return this.check(certDate.getUTCDate())+"."+this.check(certDate.getUTCMonth()+1)+"."+certDate.getFullYear() + " " +
        this.check(certDate.getUTCHours()) + ":" + this.check(certDate.getUTCMinutes()) + ":" + this.check(certDate.getUTCSeconds());
}

CertificateObj.prototype.GetCertString = function()
{
    return !!this.cert.user.name ? this.cert.user.name
        : this.extract(this.cert.subject,'CN=').replace('CN=', '') + "; Выдан: " +  this.GetCertFromDate();
}

CertificateObj.prototype.GetCertFromDate = function()
{
    return this.DateTimePutTogether(this.cert.FromDate);
}