import { set as lodashSet } from 'lodash';

const createLocationHolder = () => {
  const savedValues = {};
  const getPath = path => savedValues[path] || {};
  const get = (path, key) => getPath(path)[key];
  const set = (path, key, value) => {
    lodashSet(savedValues, `${path}.${key}`, value);
  };
  return { get, set };
};

const LocationHolder = createLocationHolder();

export default LocationHolder;
