import { compose } from 'redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core';

import './App.css';
import { history } from '../../helpers/history';
import { alertActions } from '../../actions/alertActions';
import { AdminRoute } from '../../components/routes/AdminRoute';
import { ApvgkRoute } from '../../components/routes/ApvgkRoute';
import MainLayout from '../main/MainLayout';
import CabinetLayout from '../cabinet/CabinetLayout';
import { ClientRoute } from '../../components/routes/ClientRoute';
import AdminLayout from '../admin/AdminLayout';
import ApvgkLayout from '../apvgk/ApvgkLayout';
import AccountantLayout from '../accountant/AccountantLayout';
import { AccountantRoute } from "../../components/routes/AccountantRoute";
import { OfficerRoute } from '../../components/routes/OfficerRoute';
import OfficerLayout from '../officer/OfficerLayout';
import { LogoutPage } from '../main/LogoutPage';
import FirmuserLayout from '../firmuser/FirmuserLayout';
import { FirmuserRoute } from '../../components/routes/FirmuserRoute';
import DepartmentLayout from '../department/DepartmentLayout';
import { DepartmentRoute } from '../../components/routes/DepartmentRoute';
import { WeightControlRoute } from '../../components/routes/WeightControlRoute';
import WeightControlLayout from '../control/WeightControlLayout';
import ErrorBoundary from './ErrorBoundary';
import ShowByRole from '../../components/ShowByRole';
import ReleaseNotesBanner from '../../components/ReleaseNotesBanner/ReleaseNotesBanner';
import { roleConstants } from '../../constants/roleConstants';

const styles = {
  root: {
    borderRadius: 4,
    minWidth: 288,
    maxWidth: 568
  },
  anchorOriginTopRight: {
    left: 'auto',
    right: 24
  },
  base: {
    borderRadius: 4
  }
};

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      dispatch(alertActions.clear());
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="App">
        <ShowByRole allowedRoles={[roleConstants.ROLE_FIRM, roleConstants.ROLE_FIRMUSER]}>
          <ReleaseNotesBanner name="1.5.0" heading="Выпущена новая версия">
            <ul>
              <h5>Уважаемые грузоперевозчики! В новой версии программы:</h5>
              <b>
                - изменен функционал добавления сотрудников/филиалов для использования определенных
                транспортных средств для подачи заявлений;{' '}
              </b>
              <br />
              Просим грузоперевозчиков, предоставивших доступ к ТС филиалам или сотрудникам,
              заполнить поле &quot;Название&quot; у каждого сотрудника на странице Сотрудники&quot;
              и проверить наличие у сотрудников корректного доступа к ТС
              <br />
              <br />
              <b>- изменен картографический сервис для назначения маршрута поездки;</b>
              <br />
              <br />
              <b>
                - весеннее ограничение в 2020 году будет введено с 01 апреля 2020 г. по 30 апреля
                2020 г.
              </b>{' '}
              Согласно письму ГКУ &quot;Главтатдортранс&quot; от 19.02.2020 № 839 для определенного
              перечня дорог в период весеннего ограничения разрешенная максимальная нагрузка на ось
              ТС будет составлять 6 т.
            </ul>
          </ReleaseNotesBanner>
        </ShowByRole>
        <SnackbarProvider
          ContentProps={{
            classes: {
              root: classes.base
            }
          }}
          classes={{
            root: classes.root,
            anchorOriginTopRight: classes.anchorOriginTopRight
          }}
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <ErrorBoundary>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={history}>
                <Switch>
                  <Route exact path="/" component={MainLayout} />
                  <Route exact path="/agreement" component={MainLayout} />
                  <Route exact path="/instructions" component={MainLayout} />
                  <Route exact path="/personal" component={MainLayout} />
                  <Route exact path="/logout" component={LogoutPage} />
                  <Route exact path="/version" component={MainLayout} />

                  <AdminRoute path="/admin" component={AdminLayout} />
                  <Route path="/apvgk-user" component={ApvgkLayout} />
                  <AccountantRoute path="/accountant" component={AccountantLayout} />
                  <ClientRoute path="/cabinet" component={CabinetLayout} />
                  <OfficerRoute path="/officer" component={OfficerLayout} />

                  <FirmuserRoute path="/firmuser" component={FirmuserLayout} />

                  <DepartmentRoute path="/department" component={DepartmentLayout} />

                  <WeightControlRoute path="/control" component={WeightControlLayout} />
                </Switch>
              </Router>
            </MuiPickersUtilsProvider>
          </ErrorBoundary>
        </SnackbarProvider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}

const connectedApp = compose(
  withStyles(styles),
  connect(mapStateToProps)
)(App);

export { connectedApp as App };
