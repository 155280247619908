import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import {Add, Remove} from "@material-ui/icons";

export const AddRemoveFormField = (props) => {
  const {
    input,
    label,
    className,
    placeholder,
    min,
    max,
    meta: { touched, error }
  } = props;
  const ref = React.createRef();
  return (
      <div className={`form-group${touched && error ? ' has-error' : ''}`}>
        <div className="row">
          <div className="col-md-4">
            <p className="label-form">{label}</p>
          </div>
          <div className="col-md-8 input-link-container">
            <div className="row">
              <div className="">
                <IconButton
                    size="small"
                    style={{padding: 8}}
                    onClick={() => {
                      ref.current.value = Math.max(min, parseInt(ref.current.value) - 1);
                    }}
                >
                  <Remove/>
                </IconButton>
              </div>
              <div style={{minWidth:100, maxWidth:100}}>
                <input
                    ref={ref}
                    type="number"
                    className={`form-control ${className}${touched && error ? ' form-control-error' : ''}`}
                    {...input}
                    placeholder={placeholder}
                />
              </div>
              <div className="">
                <IconButton
                    size="small"
                    style={{padding: 8}}
                    onClick={() => {
                      ref.current.value = Math.min(max, parseInt(ref.current.value) + 1);
                    }}
                >
                  <Add/>
                </IconButton>
              </div>
            </div>
            {touched && (error && <span>{error}</span>)}
          </div>
        </div>
      </div>
  );
};
