import React from 'react';
import { connect } from 'react-redux';
import Select from './Select';
import {
  getIsPrivilegeStatusesLoading,
  getPrivilegeStatuses,
  getPrivilegeStatusesError,
  getPrivilegeStatusesFetched
} from '../../../../../selectors/privelegeStatusSelectors';
import { privilegeStatusActions } from '../../../../../actions/privilegeStatusActions';
import { isStatusAvailable } from '../utils';

const SelectStatus = ({ restrictionDates, ...props }) => {
  return (
    <Select
      noOptions="Марка не найдена"
      placeholder="Выберите статус"
      filter={s => isStatusAvailable(restrictionDates, s)}
      {...props}
    />
  );
};

const mapStateToProps = state => ({
  data: getPrivilegeStatuses(state),
  isLoading: getIsPrivilegeStatusesLoading(state),
  error: getPrivilegeStatusesError(state),
  fetched: getPrivilegeStatusesFetched(state)
});

const mapDispatchToProps = {
  fetch: privilegeStatusActions.getAll
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectStatus);
