import React from 'react';
import Paper from '@material-ui/core/Paper';
import { CoefficientBlock } from './CoefficientBlock';
// import {specialConditionActions} from "../../../../actions/specialConditionActions";

// const conditionSubmit = (values, dispatch) => {
//     return dispatch(specialConditionActions.setItem(values));
// };

const CoefficientsPage = props => {
  return (
    <div>
      <Paper>
        <br />
        {/* <SpringForm onSubmit={conditionSubmit}/> */}
        <CoefficientBlock />
        <br />
      </Paper>
    </div>
  );
};

export default CoefficientsPage;
