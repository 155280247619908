import React from 'react';
import { MainFooterBlock } from '../main/blocks/MainFooterBlock/MainFooterBlock';
import { ApvgkRoute } from '../../components/routes/ApvgkRoute';
import { CabinetMainMenu } from '../cabinet/blocks/CabinetMainMenu/CabinetMainMenu';
import { CabinetLeftMenu } from '../cabinet/blocks/CabinetLeftMenu/CabinetLeftMenu';
import ApvgkMainPage from './main/ApvgkMainPage';
import {ApvgkReportsListPage} from './reports/ApvgkReportsListPage';
import {ReportShowPage} from './reports/ReportShowPage'
import ApplicationsLayout from "./ApplicationsLayout";
import TrackPageView from "../admin/track/TrackPageView";
import { Route } from 'react-router-dom';
import {roleConstants} from "../../constants/roleConstants";
import {TrackCheckerRoute} from "../../components/routes/TrackCheckerRoute";
import {AuthRoute} from "../../components/routes/AuthRoute";

const ApvgkLayout = ({ match }) => {
  const links = [
    { key: 1, path: `${match.url}`, title: 'Личный кабинет', hasChild: false },
    {
      key: 2,
      path: `${match.url}/apvgk`,
      title: 'Проверка актов взвешивания',
      hasChild: true,
      role: roleConstants.ROLE_APVGK,
    },
    {
      key: 3,
      path: `${match.url}/applications`,
      title: 'Проверка разрешений',
      hasChild: true,
      role: roleConstants.ROLE_APVGK,
    },
    {
      key: 4,
      path: `${match.url}/track`,
      title: 'Проезды',
      hasChild: false
    }
  ];

  return (
    <div className="">
      <div className="cabinet-header">
        <CabinetMainMenu />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="cabinet-left-menu">
              <CabinetLeftMenu links={links} />
            </div>
          </div>

          <div className="col-md-9 cabinet-content">
            <AuthRoute roles={[roleConstants.ROLE_APVGK, roleConstants.ROLE_TRACK_CHECKER]} exact path={`${match.url}`} component={ApvgkMainPage} />
            <ApvgkRoute exact path={`${match.url}/apvgk`} component={ApvgkReportsListPage} />
            <ApvgkRoute exact path={`${match.url}/apvgk/:id`} component={ReportShowPage} />
            <ApvgkRoute path={`${match.url}/applications`} component={ApplicationsLayout} />
            <AuthRoute roles={[roleConstants.ROLE_APVGK, roleConstants.ROLE_TRACK_CHECKER]} path={`${match.url}/track`} component={TrackPageView} />
          </div>
        </div>
      </div>
      <MainFooterBlock />
    </div>
  );
};

export default ApvgkLayout;
