import React, { useState , useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Field} from 'formik';
import FieldLabel from '../../admin/quickApplications/CreateQuickApplication/components/FieldLabel';
import MaterialSelect from '../../admin/quickApplications/CreateQuickApplication/components/MaterialSelect';
import { SignService } from '../../../services/signService';


const Filter = ({...props}) => {
    const [certs, setCerts] = useState([]);
    const certsRef = useRef(null);
    useEffect(() => {
        async function fetchCerts() {
            const data = await SignService.getCerts();
            certsRef.current = data;
            setCerts(
                data.map(d => ({
                    label: d.label,
                    value: d.cert,
                }))
            );
        }

        fetchCerts();
    }, []);
    return (
        <Grid item>
            <FieldLabel
                name="ecp"
                label={"ЭЦП"+(props.required ? '*' : '')}
                labelProps={{ xs: 4, lg: 3 }}
                childrenProps={{ xs: 8, lg: 5 }}
                boldLabel
            >
                <Field
                    {...props}
                    name="ecp"
                    component={MaterialSelect}
                    options={certs}
                    afterChange={async (value) => {
                        const {dataUrl} = props;
                        const ecp = value
                        const file = await SignService.downloadFileContent({
                            url: dataUrl+(dataUrl&&dataUrl.indexOf('?')!==-1 ? '&' : '?')+`owner_name=${ecp.user.name}&position=${ecp.user.work_position}&serial_number=${ecp.serial_number}`+
                            `&allowed_date_start=${ecp.FromDate.getTime()/1000}&allowed_date_finish=${ecp.TillDate.getTime()/1000}&base64=1`,
                        }, false);
                        const fileContent = file[0];
                        let result;
                        let fileSignature;
                        try {
                            fileSignature = await SignService.signFile(fileContent, ecp);
                            result = await SignService.signCheck(fileSignature, fileContent);
                            console.log({fileContent, fileSignature})
                        } catch (e) {
                            alert(window.cadesplugin.getLastError(e))
                        }
                        if (!result || !fileSignature) {
                            alert('При проверке подписи произошла ошибка')
                            fileSignature = null
                        } else {
                            props.form.setFieldValue('ecp', fileSignature ? {
                                sig: fileSignature,
                                file: file[1],
                                org: ecp.issuer,
                                owner_name: ecp.user.name,
                                position: ecp.user.work_position,
                                serial_number: ecp.serial_number,
                                allowed_date_start: ecp.FromDate.getTime()/1000,
                                allowed_date_finish: ecp.TillDate.getTime()/1000,
                            } : undefined)
                        }
                    }}
                    placeholder="Выберите сертификат"
                />
            </FieldLabel>
        </Grid>
    );
}

export default Filter;
