import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components/Loader';

let RestorePasswordForm = props => {
  const { error, handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        type="text"
        placeholder="Email"
        validate={[fieldValidators.required, fieldValidators.email]}
        component={FormFieldReg}
      />
      {error && <strong>{error}</strong>}
      <div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="btn lbutton"
          disabled={submitting}
        >
          {submitting && <Loader />} Продолжить
        </Button>
      </div>
    </form>
  );
};

RestorePasswordForm = reduxForm({
  form: 'request-restore-password' // a unique identifier for this form
})(RestorePasswordForm);

export default RestorePasswordForm;
