const maskPhone = phone => {
  if (!phone) {
    return phone;
  }
  const handledPhone = phone.trim();
  if (handledPhone.indexOf('+7') === 0) {
    return handledPhone;
  }
  return `+7${handledPhone}`;
};

const unmaskPhone = phone => {
  if (!phone) {
    return phone;
  }
  const handledPhone = phone.trim();
  if (handledPhone.indexOf('+7') !== 0) {
    return handledPhone;
  }
  return handledPhone.substr(2);
};

export const maskHelper = {
  maskPhone,
  unmaskPhone
};
