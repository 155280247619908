import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { FormField } from '../../../components/form/FormField';
import { applicationActions } from '../../../actions/applicationActions';
import { Loader } from '../../../components/Loader';

let ApplicationFilterForm = props => {
  const { handleSubmit, submitting, handleModalClose } = props;

  return (
    <form onSubmit={handleSubmit}>
      <p>
        <strong>Фильтровать по информации</strong>
      </p>
      <Field name="id" type="text" label="Номер разрешения" className="" component={FormField} />
      <Field name="number" type="text" label="ГРЗ" className="" component={FormField} />
      <p>
        <strong>Фильтровать по дате</strong>
      </p>
      <Field
        name="start_date"
        type="date"
        label="Начало действия"
        className=""
        component={FormField}
      />
      <Field
        name="finish_date"
        type="date"
        label="Конец действия"
        className=""
        component={FormField}
      />
      <Button
        variant="contained"
        color="primary"
        className="no-margin btn-add"
        type="submit"
        disabled={submitting}
      >
        {submitting && <Loader />} Применить
      </Button>
      &nbsp;
      <Button variant="outlined" component="span" className="info-btn" onClick={handleModalClose}>
        Отменить
      </Button>
    </form>
  );
};

ApplicationFilterForm = reduxForm({
  form: 'application-filter-form', // a unique identifier for this form
  enableReinitialize: true,
  destroyOnUnmount: false
})(ApplicationFilterForm);

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const handleModalClose = () => {
    dispatch(applicationActions.closeFilterModal());
  };
  return {
    handleModalClose,
    dispatch
  };
};

ApplicationFilterForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationFilterForm);
export default ApplicationFilterForm;
