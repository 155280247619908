import { paymentsConstants } from '../constants/paymentsConstants';
import { paymentService } from '../services/paymentService';

const getAll = () => {
    const request = () => {
        return { type: paymentsConstants.GETALL_REQUEST };
    };

    const success = payments => {
        return { type: paymentsConstants.GETALL_SUCCESS, payments };
    };

    const failure = error => {
        return { type: paymentsConstants.GETALL_FAILURE, error };
    };

    return dispatch => {
        dispatch(request());

        paymentService.getAll().then(payments => dispatch(success(payments)), error => dispatch(failure(error)));
    };
};
const getStat = (filter) => {
    const request = () => {
        return { type: paymentsConstants.GETSTAT_REQUEST, filter };
    };

    const success = stat => {
        return { type: paymentsConstants.GETSTAT_SUCCESS, stat };
    };

    const failure = error => {
        return { type: paymentsConstants.GETSTAT_FAILURE, error };
    };

    return dispatch => {
        dispatch(request());

        paymentService.getStat(filter).then(stat => dispatch(success(stat)), error => dispatch(failure(error)));
    };
};

const getPage = (page, filters) => {
  const request = { type: paymentsConstants.GET_PAGE_REQUEST, page, filters };

  const success = payments => {
    return { type: paymentsConstants.GET_PAGE_SUCCESS, payments };
  };

  const failure = error => {
    return { type: paymentsConstants.GET_PAGE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request);

    paymentService
      .getPage(page, filters)
      .then(payments => dispatch(success(payments)), error => dispatch(failure(error)));
  };
};

const getItem = id => {
  const request = id => {
    return { type: paymentsConstants.LOAD_REQUEST, id };
  };

  const success = payment => {
    return { type: paymentsConstants.LOAD_SUCCESS, payment };
  };

  const failure = error => {
    return { type: paymentsConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    paymentService
      .getItem(id)
      .then(user => dispatch(success(user)), error => dispatch(failure(error)));
  };
};


const create = values => {
  const request = values => {
    return { type: paymentsConstants.CREATE_REQUEST, values };
  };

  const success = payment => {
    return { type: paymentsConstants.CREATE_SUCCESS, payment };
  };

  return dispatch => {
    dispatch(request(values));

    return paymentService.create(values).then(payment => dispatch(success(payment)));
  };
};

const update = values => {
  const request = values => {
    return { type: paymentsConstants.UPDATE_REQUEST, values };
  };

  const success = payment => {
    return { type: paymentsConstants.UPDATE_SUCCESS, payment };
  };

  return dispatch => {
    dispatch(request(values));
    return paymentService.update(values).then(payment => dispatch(success(payment)));
  };
};

const _delete = id => {
  const request = id => {
    return { type: paymentsConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: paymentsConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: paymentsConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    return paymentService.delete(id).then(
      payment => {
        dispatch(success(id));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};
const setCurrentItem = item => {
    return { type: paymentsConstants.SET_CURRENT_ITEM, item };
};
const openFormModal = () => {
    return { type: paymentsConstants.FORM_MODAL_OPEN };
};

export const paymentsActions = {
  getPage,
  getAll,
  getItem,
  getStat,
  create,
  update,
  delete: _delete,
  setCurrentItem,
  openFormModal

};
