import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { privilegeVehicleActions } from '../../../../../actions/privilegeVehicleActions';
import { Loader } from '../../../../../components/Loader';
import StatusVehiclesTableList from './StatusVehiclesTableList';
import { StatusVehicleModal } from './StatusVehicleModal';
import { privilegeStatusActions } from '../../../../../actions/privilegeStatusActions';

class StatusVehiclesBlock extends Component {
  componentDidMount() {
    const { privilegeStatuses, match } = this.props;

    if (!privilegeStatuses.currentItem || privilegeStatuses.currentItem.id !== match.params.id) {
      this.props.dispatch(privilegeStatusActions.getItem(match.params.id));
    }
  }

  render() {
    const { privilegeStatuses, openCreateModal, handleLicenseFileUpload, match } = this.props;

    return (
      <Paper className="overflow">
        <br />
        <main role="main" className="container text-left">
          {privilegeStatuses.currentItem && privilegeStatuses.currentItem.id && (
            <div>
              <p>
                {privilegeStatuses.currentItem && privilegeStatuses.currentItem.title}: Список ТС
                <Button aria-label="Добавить" className="btn-add" onClick={openCreateModal}>
                  + Добавить
                </Button>
                <div className="file_models">
                  <input
                    accept=".application/vnd.sealed-xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className="file_input"
                    id="license-upload-button"
                    type="file"
                    onChange={handleLicenseFileUpload}
                  />
                  <label className="file_btn" htmlFor="license-upload-button">
                    <Button
                      variant="contained"
                      component="span"
                      aria-label="Добавить"
                      className="info-btn"
                    >
                      <CloudDownloadIcon />
                      &nbsp;&nbsp;Загрузить
                    </Button>
                  </label>
                </div>
              </p>
              <StatusVehiclesTableList statusId={match.params.id} />
              <StatusVehicleModal />
            </div>
          )}
          {(!privilegeStatuses.currentItem || !privilegeStatuses.currentItem.id) && <Loader />}
        </main>
        <br />
      </Paper>
    );
  }
}

const mapStateToProps = ({ privilegeStatuses }) => ({
  privilegeStatuses
});

const mapDispatchToProps = dispatch => {
  const openCreateModal = () => {
    dispatch(privilegeVehicleActions.setCurrentItem({}));
    dispatch(privilegeVehicleActions.openFormModal());
  };

  return {
    openCreateModal,
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { match } = ownProps;
  const { dispatch } = dispatchProps;

  const handleLicenseFileUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      const values = { myfile: reader.result };
      dispatch(privilegeVehicleActions.uploadVehiclesXls(values, match.params.id));
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,

    handleLicenseFileUpload
  };
};

const connectedStatusVehiclesBlock = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(StatusVehiclesBlock);
export { connectedStatusVehiclesBlock as StatusVehiclesBlock };
