export const authConstants = {
  REGISTER_STEP1_OPEN: 'AUTH_REGISTER_STEP1_OPEN',
  REGISTER_STEP1_CLOSE: 'AUTH_REGISTER_STEP1_CLOSE',

  REGISTER_STEP2_OPEN: 'AUTH_REGISTER_STEP2_OPEN',
  REGISTER_STEP2_CLOSE: 'AUTH_REGISTER_STEP2_CLOSE',

  REGISTER_REQUEST: 'AUTH_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'AUTH_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'AUTH_REGISTER_FAILURE',

  REG_CODE_REQUEST: 'AUTH_REG_CODE_REQUEST',
  REG_CODE_SUCCESS: 'AUTH_REG_CODE_SUCCESS',
  REG_CODE_FAILURE: 'AUTH_REG_CODE_FAILURE',

  LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',

  LOGOUT: 'AUTH_LOGOUT',

  PASSWORD_VISIBLE_TOGGLE: 'AUTH_PASSWORD_VISIBLE_TOGGLE',

  PASSWORD_RESTORE_OPEN: 'AUTH_PASSWORD_RESTORE_OPEN',
  PASSWORD_RESTORE_CLOSE: 'AUTH_PASSWORD_RESTORE_CLOSE',
  PASSWORD_RESTORE_TOGGLE: 'AUTH_PASSWORD_RESTORE_TOGGLE',

  PASSWORD_NEW_TOGGLE: 'AUTH_PASSWORD_NEW_TOGGLE',

  PASSWORD_REQUEST_REQUEST: 'AUTH_PASSWORD_REQUEST_REQUEST',
  PASSWORD_REQUEST_SUCCESS: 'AUTH_PASSWORD_REQUEST_SUCCESS',
  PASSWORD_REQUEST_FAILURE: 'AUTH_PASSWORD_REQUEST_FAILURE',

  PASSWORD_CODE_REQUEST: 'AUTH_PASSWORD_CODE_REQUEST',
  PASSWORD_CODE_SUCCESS: 'AUTH_PASSWORD_CODE_SUCCESS',
  PASSWORD_CODE_FAILURE: 'AUTH_PASSWORD_CODE_FAILURE',

  PASSWORD_CHANGE_REQUEST: 'AUTH_PASSWORD_CHANGE_REQUEST',
  PASSWORD_CHANGE_SUCCESS: 'AUTH_PASSWORD_CHANGE_SUCCESS',
  PASSWORD_CHANGE_FAILURE: 'AUTH_PASSWORD_CHANGE_FAILURE',

  LEGAL_FORM_REQUEST: 'AUTH_LEGAL_FORM_REQUEST',
  LEGAL_FORM_SUCCESS: 'AUTH_LEGAL_FORM_SUCCESS',
  LEGAL_FORM_FAILURE: 'AUTH_LEGAL_FORM_FAILURE',

  SAVE_USER_SUCCESS: 'AUTH_SAVE_USER_SUCCESS',

  EMAIL_CONFIRMATION_REQUEST: 'AUTH_EMAIL_CONFIRMATION_REQUEST',
  EMAIL_CONFIRMATION_SUCCESS: 'AUTH_EMAIL_CONFIRMATION_SUCCESS',
  EMAIL_CONFIRMATION_FAILURE: 'AUTH_EMAIL_CONFIRMATION_FAILURE',

  SET_MESSAGE: 'AUTH_SET_MESSAGE'
};
