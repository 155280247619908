import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

function checkEmpty(value) {
  return value === '' || value === null ? undefined : value;
}

// Made dumb to keep in mind what parameters it has
const checkFields = values => ({
  id: checkEmpty(values.id),
  admin_name: checkEmpty(values.admin_name),
  authority_name: checkEmpty(values.authority_name),
  max_date: checkEmpty(values.max_date),
  min_date: checkEmpty(values.min_date),
  name: checkEmpty(values.name),
  number: checkEmpty(values.number),
  output_number: checkEmpty(values.output_number),
  status: values.status,
  per_page: values.per_page,
  page: values.page,
  overdue: values.overdue ? values.overdue : undefined
});

const exportApplications = filters => {
  return apiService.call(
    apiConstants.METHOD_POST,
    '/reports/active-export',
    {
      is_smev: 1,
      ...checkFields(filters)
    },
    true
  );
};

const getApplications = values => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/applications/admin/-1?page=${values.page ? values.page : 1}&is_smev=1`,
    checkFields(values),
    true
  );
};

const saveRoute = (applicationId, paths) => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/applications/${applicationId}/route-save`,
    {
      segments: paths.map(path =>
        path.useSaved
          ? {
              id_list: path.id
            }
          : {
              waypoints: path.steps.map(step => ({
                name: step.text,
                coords: step.coords
              }))
            }
      )
    },
    true
  );
};

const changeRouteByAdmin = (applicationId, paths) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/applications/${applicationId}/change-route-info`,
    {
      segments: paths.map(path =>
        path.useSaved
          ? {
              id_list: path.id
            }
          : {
              waypoints: path.steps.map(step => ({
                name: step.text,
                coords: step.coords
              }))
            }
      )
    },
    true
  );
};

const getRoutesList = () => {
  return apiService.call(apiConstants.METHOD_GET, '/route-list', {}, true);
};

export default {
  getApplications,
  exportApplications,
  saveRoute,
  getRoutesList,
  changeRouteByAdmin
};
