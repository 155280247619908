import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Loader } from '../../../components/Loader';
import { renderSelectFieldReg } from '../../../components/form/renderSelectFieldReg';
import { fieldValidators } from '../../../helpers/fieldValidators';

let AgreementsForm = props => {
  const { handleSubmit, submitting, departments } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-8">
          <Field
            className="form-control"
            name="department_id"
            component={renderSelectFieldReg}
            validate={[fieldValidators.required]}
          >
            <option key="0" value="">
              Ведомство для согласования
            </option>
            {departments.map(option => (
              <option key={option.id} value={option.id}>
                {option.title}
              </option>
            ))}
          </Field>
        </div>
        <div className="col-md-4 text-right">
          <Button
            variant="outlined"
            color="primary"
            className="info-btn no-margin full-width"
            type="submit"
            disabled={submitting}
          >
            {submitting && <Loader />} Отправить на согласование
          </Button>
          &nbsp;
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { application } = ownProps;

  return {
    initialValues: {
      application_id: application.id
    }
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

AgreementsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementsForm);
export default AgreementsForm;
