import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authHelper } from '../../../../helpers/authHelper';
import { urlHelper } from '../../../../helpers/urlHelper';

const AdminMenu = props => {
  const { user, logoutUser } = props;

  return (
    <ul className="navbar-nav mr-auto">
      <li>
        <Link to={urlHelper.getHomeUrl(user.role_id)} className="nav-link">
          {user.name}
        </Link>
      </li>
      <li key="login">
        <Link className="nav-link cursor" onClick={logoutUser} to="/">
          Выйти
        </Link>
      </li>
    </ul>
  );
};

const mapStateToProps = state => {
  const { user } = state.authentication;
  return {
    user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logoutUser: () => authHelper.logout(dispatch)
  };
}

const connectedAdminMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminMenu);
export { connectedAdminMenu as AdminMenu };
