import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import Filter from './Filter';
import NewContent from './content/NewContent';
import { CooperationStatus } from '../../../constants/cooperationConstants';
import InProgressContent from './content/InProgressContent';
import CompleteContent from './content/CompleteContent';
import { StateContext } from './components/StateContext';
import ReturnedContent from './content/ReturnedContent';
import { useWithQuery } from './util/useWithQuery';
import FinishedContent from './content/FinishedContent';

const TabsInfo = {
  [CooperationStatus.New]: {
    value: CooperationStatus.New,
    Component: NewContent,
    name: 'Новые'
  },
  [CooperationStatus.InProgress]: {
    value: CooperationStatus.InProgress,
    Component: InProgressContent,
    name: 'В работе'
  },
  [CooperationStatus.Completed]: {
    value: CooperationStatus.Completed,
    Component: CompleteContent,
    name: 'Обработанные'
  },
  [CooperationStatus.Returned]: {
    value: CooperationStatus.Returned,
    Component: ReturnedContent,
    name: 'Отозванные'
  },
  [CooperationStatus.Finished]: {
    value: CooperationStatus.Finished,
    Component: FinishedContent,
    name: 'Завершенные'
  }
};

const CooperationListPage = () => {
  const [tab, setTab] = useWithQuery('tab', CooperationStatus.New, v => Number.parseInt(v, 10));
  const [filters, onFiltersChange] = useWithQuery('filters', {});
  const [selected, setSelected] = React.useState([]);
  const [meta, setMeta] = useWithQuery('meta', { per_page: 20 });
  const { Component } = TabsInfo[tab];

  return (
    <main role="main" className="container">
      <div className="kyc">
        <Grid container direction="column" spacing={16}>
          <Grid item container alignItems="center">
            <Grid item>
              <h2 className="h2-title no-margin">Межведомственное взаимодействие</h2>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item>
              <Filter tab={tab} currentFilters={filters} onFiltersChange={onFiltersChange} />
            </Grid>
          </Grid>
          <Grid container item>
            <AppBar position="static" color="default" className="tabs_header">
              <Tabs
                value={tab}
                onChange={(e, newValue) => {
                  setMeta({ ...meta, page: 0 });
                  setTab(newValue);
                }}
                indicatorColor="primary"
                textColor="primary"
                className="tab_style"
                variant="fullWidth"
              >
                {Object.entries(TabsInfo).map(([t, { name, value }]) => (
                  <Tab key={name} classes={{ selected: 'tab_active' }} label={name} value={value} />
                ))}
              </Tabs>
            </AppBar>
          </Grid>
          {Component && (
            <StateContext.Provider value={{ filters, tab, meta, setMeta, selected, setSelected }}>
              <Paper className="overflow overflow-y-hidden">
                <Component />
              </Paper>
            </StateContext.Provider>
          )}
        </Grid>
      </div>
    </main>
  );
};

export default CooperationListPage;
