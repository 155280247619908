import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { RouteAddressTableList } from './RouteAddressTableList';
import { Loader } from '../../../../components/Loader';
import { RouteAddressModal } from './RouteAddressModal';
import { routeAddressActions } from '../../../../actions/routeAddressActions';

class RouteAddressBlock extends Component {
  componentDidMount() {
    const { routeAddresses } = this.props;

    if (!routeAddresses.items || routeAddresses.items.length === 0) {
      this.props.dispatch(routeAddressActions.getPage(1, routeAddresses.filters));
    }
  }

  render() {
    const { routeAddresses, openCreateModal } = this.props;

    return (
      <div>
        <main role="main" className="container text-left">
          <br />
          <p>
            Справочник адресов
            <Button aria-label="Добавить" className="btn-add" onClick={openCreateModal}>
              + Создать
            </Button>
          </p>
          <div className="">
            {routeAddresses.loading && <Loader />}
            {routeAddresses.error && (
              <span className="text-danger">Ошибка: {routeAddresses.error}</span>
            )}
            {routeAddresses.items && (
              <RouteAddressTableList data={routeAddresses.items} meta={routeAddresses.meta} />
            )}
          </div>
          <RouteAddressModal />
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { routeAddresses } = state;
  return {
    routeAddresses
  };
};

const mapDispatchToProps = dispatch => {
  const openCreateModal = e => {
    dispatch(routeAddressActions.setCurrentItem(null));
    dispatch(routeAddressActions.openFormModal());
  };

  return {
    openCreateModal,
    dispatch
  };
};

const connectedRouteAddressBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteAddressBlock);
export { connectedRouteAddressBlock as RouteAddressBlock };
