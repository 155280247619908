import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { vehicleBrandActions } from '../../../../../actions/vehicleBrandActions';

const VehicleBrandErrorModal = props => {
  const { vehicleBrands, handleErrorClose } = props;

  return (
    <Dialog
      open={vehicleBrands.showDeleteError}
      onClose={handleErrorClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Удаление марки авто
        </Typography>
        <IconButton color="inherit" onClick={handleErrorClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <span>
          Марка не может быть удалена, т.к. используется в существующих моделях или транспортных
          средствах
        </span>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { vehicleBrands } = state;
  return {
    vehicleBrands
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleErrorClose: () => {
      dispatch(vehicleBrandActions.closeErrorModal());
    }
  };
};

const connectedVehicleBrandErrorModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleBrandErrorModal);
export { connectedVehicleBrandErrorModal as VehicleBrandErrorModal };
