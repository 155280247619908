import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { applicationConstants } from '../../../../constants/applicationConstants';
import InfoModal from '../../../../components/modals/InfoModal';

const ApplicationCommentButton = ({ status, comment }) => {
  const [show, setShow] = useState(false);
  if (status !== applicationConstants.STATUS_REPEAT) {
    return null;
  }
  const open = () => setShow(true);
  const close = () => setShow(false);
  return (
    <>
      <Button onClick={open} className="info-btn no-margin">
        Замечания
      </Button>
      <InfoModal
        title="Замечания"
        onClose={close}
        text={comment || 'Замечания не указаны'}
        isOpen={show}
      />
    </>
  );
};

export default ApplicationCommentButton;
