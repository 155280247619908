import React, { useEffect, useRef } from 'react';
import Select from 'react-select';
import { isEqual } from 'lodash';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const SelectComponent = ({
  field,
  form,
  isLoading,
  data,
  fetch,
  fetched,
  error,
  noOptions,
  placeholder,
  fetchProps = [],
  afterSelect = () => null,
  filter = () => true,
  ...props
}) => {
  const prevValues = usePrevious({ fetchProps });
  const prevProps = prevValues ? prevValues.fetchProps : [];
  useEffect(() => {
    if (!fetched || !isEqual(prevProps, fetchProps)) {
      fetch(...fetchProps);
    }
  }, [fetchProps]);
  const handleChange = newValue => {
    form.setFieldValue(field.name, newValue);
    afterSelect(data.find(d => d.id === newValue) || { id: -1, value: newValue });
  };
  const v = field.value && data && data.find(d => d.id === field.value);
  return (
    <Select
      {...field}
      value={v ? { value: field.value, label: v.title } : undefined}
      placeholder={placeholder}
      options={data.filter(filter).map(b => ({ label: b.title, value: b.id }))}
      onChange={({ value }) => handleChange(value)}
      {...props}
    />
  );
};

export default SelectComponent;
