import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import NewPasswordForm from './NewPasswordForm';
import { authActions } from '../../../actions/authActions';

const newPasswordSubmit = (values, dispatch) => {
  return dispatch(authActions.changePassword(values));
};

const NewPasswordModal = props => {
  const { showNewModal, handleNewClose, newMessage } = props;

  return (
    <Dialog open={showNewModal} onClose={handleNewClose} aria-labelledby="form-dialog-title">
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Восстановление пароля
        </Typography>
        <IconButton color="inherit" onClick={handleNewClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        {/* <p>Введите новый пароль</p> */}

        <NewPasswordForm onSubmit={newPasswordSubmit} />
        {newMessage}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { showNewModal, newMessage } = state.restorePassword;
  return {
    showNewModal,
    newMessage
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleNewOpen: () => {
      dispatch(authActions.toggleNewModal());
    },
    handleNewClose: () => {
      dispatch(authActions.toggleNewModal());
    }
  };
};

const connectedLoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPasswordModal);
export { connectedLoginPage as NewPasswordModal };
