import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as selectors from '../../../selectors/applicationSelectors';
import * as actions from '../../../actions/newApplicationActions';
import { Loader } from '../../../components';
import ApplicationViewPage from './ApplicationViewPage';
import DictionaryLoader from './DictionaryLoader';
import FullPageLoader from './FullPageLoader';

const ApplicationViewPageContainer = ({
  match,
  getApplication,
  isLoading,
  isError,
  application
}) => {
  const { id } = match.params;
  useEffect(() => {
    getApplication(id);
  }, [id]);

  if (isLoading) {
    return (
      <div className="full-screen-loader-container">
        <Loader />
      </div>
    );
  }
  if (isError) {
    return <div>Что-то пошло не так, попробуйте позже</div>;
  }
  if (application) {
    return (
      <React.Fragment>
        <FullPageLoader />
        <DictionaryLoader />
        <ApplicationViewPage application={application} />
      </React.Fragment>
    );
  }
  return <div />;
};

const mapDispatchToProps = {
  getApplication: actions.getApplication
};

const mapStateToProps = state => ({
  isLoading: selectors.getIsApplicationLoading(state),
  isError: selectors.getIsApplicationError(state),
  application: selectors.getApplicationData(state)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationViewPageContainer);
