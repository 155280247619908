import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import RegAppsForm from './RegAppsForm';
import { regAppActions } from '../../../actions/regAppActions';

const updateSubmit = (values, dispatch) => {
  return dispatch(regAppActions.decline(values));
};

const DeclineModal = props => {
  const { handleDeclineClose, regApps } = props;

  return (
    <Dialog
      open={regApps.showDeclineModal}
      onClose={handleDeclineClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Отклонение заявки
        </Typography>
        <IconButton color="inherit" onClick={handleDeclineClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        {regApps && regApps.currentItem && (
          <RegAppsForm currentItem={regApps.currentItem} onSubmit={updateSubmit} />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { regApps } = state;
  return {
    regApps
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleDeclineClose: () => {
      dispatch(regAppActions.closeDeclineModal());
    }
  };
};

const connectedDeclineModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeclineModal);
export { connectedDeclineModal as DeclineModal };
