const APPLICATION_CODE = '01';
const QUICK_APPLICATION_CODE = '02';
const SMEV_APPLICATION_CODE = '03';
const cases = [2, 0, 1, 1, 1, 2];

const zeroFill = (number, width) => {
  if (!number) {
    return '';
  }
  width -= number.toString().length;
  if (width > 0) {
    return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
  }
  return `${number}`;
};

const appFormattedId = id => {
  return zeroFill(id, 6);
};

const getCode = application => {
  if (application.is_fast) {
    return QUICK_APPLICATION_CODE;
  }
  if (application.is_smev) {
    return SMEV_APPLICATION_CODE;
  }
  return APPLICATION_CODE;
};

const appNumber = application => {
  if (!application) {
    return '';
  }
  let year = '18';
  const create_date = application.created_at;
  if (create_date) {
    year = create_date.substr(2, 2);
  }

  let fastRd = (application.fast && application.fast.rd_number ? ' ' + application.fast.rd_number : '');
  return `${year}-${getCode(application)}${appFormattedId(application.id)}${fastRd}`;
};

export const formatHelper = {
  appNumber
};

export function declOfNum(number, titles) {
  return titles[
    number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}
