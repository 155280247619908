import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { coefficientActions } from '../../../../actions/coefficientActions';
import TablePagination from '../../../../components/TablePagination';

export function getRoadType(roadType) {
  switch (roadType) {
    case 0:
      return 'региональный';
    case 1:
      return 'федеральный';
    case 2:
      return 'муниципальный';
    default:
      return 'региональный';
  }
}

const CoefficientTableList = props => {
  const { data, meta, handleChangePage, handleDelete, openUpdateModal } = props;

  return (
    <div>
      <Paper className="overflow">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Значение</TableCell>
              <TableCell>Значение (весна)</TableCell>
              <TableCell>Тип дороги</TableCell>
              <TableCell numeric>Действие</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((n, index) => {
              return (
                <TableRow key={n.id}>
                  <TableCell>{(meta.current_page - 1) * meta.per_page + index + 1}</TableCell>
                  <TableCell>{n.title}</TableCell>
                  <TableCell>{n.value}</TableCell>
                  <TableCell>{n.spring_value}</TableCell>
                  <TableCell>{getRoadType(n.road_type)}</TableCell>
                  <TableCell numeric>
                    <Button
                      variant="outlined"
                      className="info-btn"
                      size="small"
                      onClick={openUpdateModal(n)}
                    >
                      <span>Редактировать</span>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination meta={meta} onChangePage={handleChangePage} />
    </div>
  );
};

const mapStateToProps = state => {
  const { coefficients } = state;

  return {
    coefficients
  };
};

const mapDispatchToProps = dispatch => {
  const handleChangePage = page => {
    dispatch(coefficientActions.getPage(page));
  };

  const handleDelete = id => {
    return e => dispatch(coefficientActions.delete(id));
  };

  const openUpdateModal = item => {
    return e => {
      dispatch(coefficientActions.setCurrentItem(item));
      dispatch(coefficientActions.openFormModal());
    };
  };

  return {
    handleChangePage,
    handleDelete,
    openUpdateModal
  };
};

const connectedCoefficientTableList = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoefficientTableList);
export { connectedCoefficientTableList as CoefficientTableList };
