import React, {Component} from 'react';
import {connect} from 'react-redux';
import {applicationActions} from "../../../actions/applicationActions";
import {ApplicationCard} from "./ApplicationCard";
import Button from "@material-ui/core/Button";
class ApplicationsRDMPage extends Component {
    componentDidMount() {
        const {check} = this.props;
        check();
    }

    render() {
        const { userApplications, params } = this.props;
        const { rdmapplication : items, loading} = userApplications;
        return (
            <main role="main" className="container">
                <div className="">
                    <h2 className="h2-title">Информация о разрешении из реестра РДМ</h2>
                </div>
                <div className="">
                    <br/>
                    <Button
                        className="no-margin info-btn"
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={() => window.location = params.get('origin') + '?uuid=' + params.get('uuid')}
                    >
                        Перейти на сайт Росдормониторинга
                    </Button>
                    <br />
                    <br />
                    {loading ? ' Загрузка...' : (!items || !items.application ? 'АИС КТГ не удалось создать разрешение на основе данных РДМ' :
                        <div>
                            <ApplicationCard application={items.application} />
                        </div>
                    )}
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => {

    const { userApplications } = state;

    return {
        userApplications
    };
};

const mapDispatchToProps = dispatch => {
    const params = new URLSearchParams(window.location.search);
    const check = () => {
        dispatch(applicationActions.getControlByRDMUUID({uuid: params.get('uuid')}))
    };

    return {
        check,
        params,
        dispatch
    };
};

const connectedApplicationsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ApplicationsRDMPage);
export {connectedApplicationsPage as ApplicationsRDMPage};
