import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isString, get } from 'lodash';
import { change, Field, formValueSelector, reduxForm, updateSyncErrors } from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Help from '@material-ui/icons/Help';

import { fieldValidators } from '../../../../../helpers/fieldValidators';
import { FormField } from '../../../../../components/form/FormField';
import { FormFieldNoLabel } from '../../../../../components/form/FormFieldNoLabel';
import { renderSelectField } from '../../../../../components/form/renderSelectField';
import { renderSelectFieldReg } from '../../../../../components/form/renderSelectFieldReg';
import { applicationActions } from '../../../../../actions/applicationActions';
import { Loader } from '../../../../../components/Loader';
import { dictionaryHelper } from '../../../../../helpers/dictionaryHelper';
import { fieldNormalizers } from '../../../../../helpers/fieldNormalizers';
import { fileConstants } from '../../../../../constants/fileConstants';
import { axlesCalculator } from '../../../../../helpers/axlesCalculator';
import ApplicationSchema from '../../ApplicationSchema';
// import ReactToPrint from "react-to-print";
import FileUploader from '../../../../../components/form/FileUploader';
import { createFileUploadHandler } from '../../../../../helpers/fileUploadHelper';

class ApplicationStepLoadForm extends Component {
  componentDidMount() {
    const { dispatch, id, handleSizeChange, axleLoads } = this.props;

    const addVehicleAxles = (vehicle, result) => {
      for (let i = 0; i < vehicle.vehicle_axles.length; i++) {
        result.push(vehicle.vehicle_axles[i]);
      }

      return result;
    };

    const parseAxlesByLoad = load => {
      return JSON.parse(load.axles_info);
    };
    const parseEscortByLoad = load => {
      return isString(load.escort) ? JSON.parse(load.escort) : load.escort;
    };

    // set axles count
    const parseAxles = application => {
      let result = [];

      result = addVehicleAxles(application.vehicle, result);
      if (application.trailers) {
        for (let i = 0; i < application.trailers.length; i++) {
          result = addVehicleAxles(application.trailers[i], result);
        }
      }

      return result;
    };

    // Get all editable distances by vehicle, trailers
    const getEditableDistancesIndexes = application => {
      if (!application.trailers) {
        return [];
      }

      const result = [];
      let currentAxle = application.vehicle.axle_count - 1;

      for (let i = 0; i < application.trailers.length; i++) {
        result.push(currentAxle);
        currentAxle += application.trailers[i];
      }

      return result;
    };

    // Get axles info by early saved data or by vehicles and trailers
    const loadVehicleInfo = (dispatch, application, load) => {
      let axles = [];
      let escort = [];
      if (load) {
        axles = parseAxlesByLoad(load);
        escort = parseEscortByLoad(load);
      } else {
        axles = parseAxles(application);
      }

      // calculate axles permissible weights by initial state
      const distances = [];
      const wheelCounts = [];
      const axleTypes = [];
      const wheels = [];

      for (let i = 0; i < axles.length; i++) {
        if (i < axles.length - 1) {
          distances[i] = isNaN(parseFloat(axles[i].distance)) ? 0 : parseFloat(axles[i].distance);
        }
        wheelCounts[i] = isNaN(parseFloat(axles[i].wheel_count))
          ? 0
          : parseFloat(axles[i].wheel_count);
        axleTypes[i] = isNaN(parseInt(axles[i].type_id)) ? 0 : parseInt(axles[i].type_id);
        wheels[i] = isNaN(parseInt(axles[i].wheels)) ? 0 : parseInt(axles[i].wheels);
      }

      // set available load for axles
      const axlesWeights = axlesCalculator.calculateAxlesAllowedWeights(
        wheelCounts,
        distances,
        axleTypes,
        wheels,
        axleLoads
      );
      for (let i = 0; i < axles.length; i++) {
        axles[i].permissible_load = axlesWeights[i];
      }

      // store load axles info
      dispatch(applicationActions.setLoadAxles(axles));
      dispatch(applicationActions.setEscort(escort));

      // store active distances info(between trailers, editable by user)
      const editableDistances = getEditableDistancesIndexes(application);
      dispatch(applicationActions.setEditableDistances(editableDistances));

      if (load) {
        handleSizeChange(load.length, load.width, load.height);
      }
    };

    if (id) {
      dispatch(applicationActions.getLoadItem(id, loadVehicleInfo));
    }
  }

  render() {
    const {
      handleSubmit,
      handleFileUpload,
      handleFileRemove,
      handleAxleLoadChange,
      handleDistanceChange,
      userApplications,
      load_files,
      length,
      width,
      height,
      escort_count,
      axles_info,
      handleSizeChange
    } = this.props;

    const { currentItem, loadStep } = userApplications;
    const { loadAxles } = loadStep;

    // Check if distance editable - between vehicle and trailers or between trailers
    // const isDistanceEditable = (editableDistances, index) => {
    //     return editableDistances.indexOf(index) !== -1;
    // };

    const createEscortForm = () => {
      const items = [];
      for (let i = 0; i < escort_count; i++) {
        items.push({});
      }

      return items;
    };

    return (
      <div>
        {userApplications.loading && <Loader />}

        {currentItem && (
          <form onSubmit={handleSubmit}>
            <h2 className="h2-title">Задание характеристик груза</h2>

            <div className="row">
              <div className="col-md-6">
                {/* Инфа о тс */}
                <Paper className="overflow">
                  <Table>
                    <TableBody>
                      {currentItem.vehicle && (
                        <TableRow>
                          <TableCell>Транспортное средство</TableCell>
                          <TableCell>{currentItem.vehicle.brandTitle}</TableCell>
                          <TableCell>{currentItem.vehicle.modelTitle}</TableCell>
                          <TableCell>{currentItem.vehicle.real_number}</TableCell>
                        </TableRow>
                      )}

                      {currentItem.trailers &&
                        currentItem.trailers.map((trailer, index) => (
                          <TableRow key={index}>
                            <TableCell>Прицеп {index + 1}</TableCell>
                            <TableCell>{trailer.brandTitle}</TableCell>
                            <TableCell>{trailer.modelTitle}</TableCell>
                            <TableCell>{trailer.real_number}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Paper>
              </div>

              {/* Инфа о типе груза */}
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <Field
                      className="form-control"
                      name="type_id"
                      component={renderSelectField}
                      label="Характер груза"
                      validate={[fieldValidators.required]}
                    >
                      <option key="0" value="">
                        Выберите тип груза
                      </option>
                      {dictionaryHelper.loadTypes.getList().map(option => (
                        <option key={option.id} value={option.id}>
                          {option.title}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div className="col-md-12">
                    <Field
                      className="form-control"
                      name="name"
                      label="Наименование груза"
                      placeholder="Наименование груза"
                      component={FormField}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <h2 className="h2-title">Все нагрузки (габариты) учитываются как ТС + груз</h2>

            <p>
              <strong>Габариты автопоезда</strong>
            </p>

            <div className="row">
              <div className="col-md-4">
                <Field
                  onChange={(event, value) => handleSizeChange(value, width, height)}
                  name="length"
                  label="Длина&nbsp;(м)"
                  // placeholder="4 м"
                  className="mini-input"
                  component={FormField}
                  normalize={fieldNormalizers.number}
                  validate={[
                    fieldValidators.required,
                    fieldValidators.number,
                    fieldValidators.minValue0
                  ]}
                />
              </div>
              <div className="col-md-4">
                <Field
                  onChange={(event, value) => handleSizeChange(length, value, height)}
                  name="width"
                  label="Ширина&nbsp;(м)"
                  // placeholder="2 м"
                  className="mini-input"
                  component={FormField}
                  normalize={fieldNormalizers.number}
                  validate={[
                    fieldValidators.required,
                    fieldValidators.number,
                    fieldValidators.minValue0
                  ]}
                />
              </div>
              <div className="col-md-4">
                <Field
                  onChange={(event, value) => handleSizeChange(length, width, value)}
                  name="height"
                  label="Высота&nbsp;(м)"
                  // placeholder="3 м"
                  className="mini-input"
                  component={FormField}
                  normalize={fieldNormalizers.number}
                  validate={[
                    fieldValidators.required,
                    fieldValidators.number,
                    fieldValidators.maxValue50,
                    fieldValidators.minValue0
                  ]}
                />
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-3">
                            Количество осей
                        </div> */}
              <div className="col-md-4">
                <Field
                  name="axles_count"
                  label="Количество осей"
                  className="mini-input"
                  component={FormField}
                  disabled="disabled"
                  normalize={fieldNormalizers.number}
                  validate={[
                    fieldValidators.required,
                    fieldValidators.number,
                    fieldValidators.maxValue50,
                    fieldValidators.minValue0
                  ]}
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="radius"
                  label="Радиус поворота&nbsp;(м)"
                  className="mini-input"
                  component={FormField}
                  normalize={fieldNormalizers.number}
                  validate={[fieldValidators.number, fieldValidators.minValue0]}
                />
              </div>
            </div>
            <br />

            <div className="car-character-block nowrap overflow">
              <ApplicationSchema currentItem={currentItem} ref={el => (this.componentRef = el)} />
            </div>
            <br />

            <div className="road_train nowrap overflow">
              <div className="">
                <div>
                  <div className="inline">Расстояние между осями (м)</div>
                  {loadAxles.map((axle, index) =>
                    index < loadAxles.length - 1 ? (
                      <div className="inline-input" key={index + `${axle.distance}`}>
                        <span>{index + 1}</span>
                        <Field
                          name={`axles_info[${index}][distance]`}
                          label={index + 1}
                          // placeholder="3 м"
                          component={FormFieldNoLabel}
                          normalize={fieldNormalizers.number}
                          validate={[
                            fieldValidators.number,
                            fieldValidators.maxValue50,
                            fieldValidators.minValue0,
                            fieldValidators.required
                          ]}
                          onChange={(event, value) =>
                            handleDistanceChange(value, index, axles_info)
                          }
                        />
                      </div>
                    ) : (
                      ''
                    )
                  )}
                </div>
              </div>

              <div className="hidden">
                {loadAxles.map((axle, index) => (
                  <Field
                    key={`permissible-input-${index}`}
                    type="hidden"
                    readonly="readonly"
                    name={`axles_info[${index}][permissible_load]`}
                    component={FormFieldNoLabel}
                  />
                ))}
              </div>

              <div className=" ">
                <div>
                  <div className="inline">Нагрузка на оси (тонн)</div>
                  {loadAxles.map((axle, index) => (
                    <div className="inline-input" key={index}>
                      <span>{index + 1}</span>
                      <Field
                        name={`axles_info[${index}][axle_load]`}
                        label={index + 1}
                        // placeholder="3 м"
                        component={FormFieldNoLabel}
                        normalize={fieldNormalizers.number}
                        validate={[
                          fieldValidators.required,
                          fieldValidators.number,
                          fieldValidators.maxValue50,
                          fieldValidators.minValue0
                        ]}
                        onChange={(event, value) => handleAxleLoadChange(value, index)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="">
                <div>
                  <div className="inline">Подвеска</div>

                  {loadAxles.map((axle, index) => (
                    <div className="inline-select" key={index}>
                      <Field
                        className="form-control"
                        name={`axles_info[${index}][type_id]`}
                        component={renderSelectFieldReg}
                        disabled="disabled"
                      >
                        <option key="0" value="">
                          Выберите тип оси
                        </option>
                        {dictionaryHelper.axleTypes.getList().map(option => (
                          <option key={option.id} value={option.id}>
                            {option.title}
                          </option>
                        ))}
                      </Field>
                    </div>
                  ))}
                </div>
              </div>

              <div className=" ">
                <div>
                  <div className="inline">Скатность</div>
                  {loadAxles.map((axle, index) => (
                    <div className="inline-input" key={index}>
                      <span>{index + 1}</span>
                      <Field
                        disabled="disabled"
                        name={`axles_info[${index}][wheel_count]`}
                        label={index + 1}
                        // placeholder="3 м"
                        component={FormFieldNoLabel}
                        normalize={fieldNormalizers.number}
                        validate={[
                          fieldValidators.required,
                          fieldValidators.number,
                          fieldValidators.maxValue50,
                          fieldValidators.minValue0
                        ]}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className=" ">
                            <div>
                                <div className="inline">
                                    Нагрузка в снаряженном состоянии (тонн)
                                </div>
                                {loadAxles.map((axle, index) =>
                                    <div className="inline-input" key={index}>
                                        <span>{index + 1}</span>
                                        <Field
                                            name={`axles_info[${index}][axle_load_empty]`}
                                            label={index + 1}
                                            // placeholder=""
                                            component={FormFieldNoLabel}
                                            normalize={fieldNormalizers.number}
                                            validate={[fieldValidators.number, fieldValidators.maxValue50, fieldValidators.minValue0]}
                                        />
                                    </div>
                                )}
                            </div>
                        </div> */}
            </div>
            <hr />
            <div className="row">
              <div className="col-md-2">Общая масса (тонн)</div>
              <div className="col-md-8 weight_info">
                <Field
                  name="weight"
                  label=" Общая масса:"
                  className="mini-input"
                  component={FormFieldNoLabel}
                  normalize={fieldNormalizers.number}
                  validate={[
                    fieldValidators.required,
                    fieldValidators.minValue0,
                    fieldValidators.maxApplicationWeight
                  ]}
                  disabled="disabled"
                />
              </div>
            </div>

            {/* Сопровождение */}
            <div className="row">
              <div className="col-md-2">Сопровождение</div>
              <div className="col-md-8">
                <Field
                  className="form-control"
                  disabled="disabled"
                  name="escort_count"
                  label="Сопровождение"
                  placeholder=""
                  component={renderSelectFieldReg}
                >
                  {dictionaryHelper.escorts.getList().map(option => (
                    <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  ))}
                </Field>
              </div>
            </div>

            {createEscortForm().map((item, index) => (
              <div key={index} className="row text-right">
                <div className="col-md-2 text-left">Автомобиль прикрытия {index + 1}</div>
                <div className="col-md-3">
                  <Field
                    name={`escort[${index}][brand]`}
                    label="Марка"
                    placeholder=""
                    component={FormField}
                  />
                </div>
                <div className="col-md-3">
                  <Field
                    name={`escort[${index}][model]`}
                    label="Модель"
                    placeholder=""
                    component={FormField}
                  />
                </div>
                <div className="col-md-3">
                  <Field
                    name={`escort[${index}][number]`}
                    label="ГРЗ"
                    placeholder=""
                    component={FormField}
                  />
                </div>
              </div>
            ))}

            <br />
            <p>
              Если у Вас нет автомобилей прикрытия, Вы можете заказать услугу по телефонам
              <span className="blue"> 533-39-85</span> , <span className="blue">533-37-46</span>{' '}
              (круглосуточно)
              <br />
              <Help className="help_icon" />
              <a href="http://gbubdd.tatarstan.ru/rus/soprovozhdenie-krupnogabaritnih-transportnih.htm">
                {' '}
                Подробнее
              </a>
            </p>

            <br />

            <p>
              <strong>Загрузите изображение расположения груза</strong>
            </p>

            {/* <ReactToPrint */}
            {/* trigger={() => <Button className="info-btn no-margin">Скачать схему автопоезда</Button>} */}
            {/* content={() => this.componentRef} */}
            {/* /> */}
            {/* <LandscapeOrientation/> */}

            <br />
            <p>
              <small>
                Вы можете скачать составленную схему и отметить расположение груза, а после
                загрузить исходный файл
              </small>
            </p>

            <p>Схема расположения груза (до 5 Мб):</p>

            <FileUploader
              className=""
              entity="load"
              inputClassName="file_input"
              buttonText="+ Добавить файл"
              fileList={load_files}
              handleFileChange={handleFileUpload}
              handleFileRemove={handleFileRemove}
              hasError={userApplications.uploadLoadError}
              uploaders={userApplications.loadUploaders}
              multiple
            />

            <br />

            <Button type="submit" className="btn-add no-margin">
              Продолжить
            </Button>
          </form>
        )}
      </div>
    );
  }
}

ApplicationStepLoadForm = reduxForm({
  form: 'application-load-form', // a unique identifier for this form
  enableReinitialize: true
})(ApplicationStepLoadForm);

const mapStateToProps = state => {
  const { userApplications, authentication } = state;
  const { currentItem, loadStep } = userApplications;
  const { load, loadAxles, escort } = loadStep;
  const { user } = authentication;

  const calculateMaxHeight = application => {
    if (!application) {
      return 0;
    }

    let result = application.vehicle.height;
    for (let i = 0; i < application.trailers.length; i++) {
      if (application.trailers[i].height > result) {
        result = application.trailers[i].height;
      }
    }

    return result;
  };

  const calculateMaxWidth = application => {
    if (!application) {
      return 0;
    }

    let result = application.vehicle.width;
    for (let i = 0; i < application.trailers.length; i++) {
      if (application.trailers[i].width > result) {
        result = application.trailers[i].width;
      }
    }

    return result;
  };

  const selector = formValueSelector('application-load-form');
  const { load_files, length, width, height, escort_count, axles_info } = selector(
    state,
    'load_files',
    'length',
    'width',
    'height',
    'escort_count',
    'axles_info'
  );

  return {
    userApplications,
    load_files,
    length,
    width,
    height,
    escort_count,
    axles_info,
    initialValues: {
      id: currentItem ? currentItem.id : 0,
      name: load ? load.name : '',
      load_weight: load ? load.load_weight : '',
      type_id: load ? load.type_id : '',
      axles_info: loadAxles,
      escort,
      axles_count: loadAxles.length,

      length: load ? load.length : '',
      width: load ? load.width : calculateMaxWidth(currentItem),
      height: load ? load.height : calculateMaxHeight(currentItem),
      radius: load ? load.radius : '',
      weight: load ? load.weight : '',
      is_penalty: currentItem && currentItem.dates ? currentItem.dates.is_penalty : false,

      load_files: get(currentItem, 'files.load_files', []),
      role_id: user.role_id
    }
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;

  const { load_files, length, width, height, axles_info } = stateProps;

  const handleFileUpload = createFileUploadHandler(
    dispatch,
    fileConstants.TYPE_LOAD,
    applicationActions.uploadLoadFile,
    { type: applicationActions.LOAD_UPLOAD_FAILURE }
  );

  const handleFileRemove = id => {
    // dispatch(applicationActions.removeLoadFile(id));
    dispatch(
      change('application-load-form', 'load_files', load_files.filter(item => item.id !== id))
    );
  };

  const handleSizeChange = (length, width, height) => {
    let result = 0;

    if (width > 0 && width <= 3.5) {
      if (length >= 25 && length <= 40) {
        result = 2;
      }
      if (length > 40) {
        result = 3;
      }
    }

    if (width > 3.5 && width <= 4.5) {
      if (length <= 40) {
        result = 2;
      }
      if (length > 40) {
        result = 3;
      }
    }
    if (width >= 4.5 && width <= 5) {
      result = 3;
    }

    if (result === 0) {
      if (height >= 4.5) {
        result = 1;
      }
      // @todo spec if width > 5
      if (width > 5) {
        result = 3;
      }
    }

    // set escort count
    dispatch(change('application-load-form', 'escort_count', result));
  };

  const handleAxleLoadChange = (value, index) => {
    let result = 0;
    for (let i = 0; i < axles_info.length; i++) {
      if (i !== index) {
        const axle_load = isNaN(parseFloat(axles_info[i].axle_load))
          ? 0
          : parseFloat(axles_info[i].axle_load);
        result += axle_load;
      } else {
        const axle_load = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
        result += axle_load;
      }
    }
    // set escort count
    dispatch(change('application-load-form', 'weight', result));
    // dispatch(touch('application-load-form', 'weight'));
  };

  const handleDistanceChange = (value, index) => {
    const distances = [];
    const wheelCounts = [];
    const axleTypes = [];
    const wheels = [];

    for (let i = 0; i < axles_info.length; i++) {
      if (i < axles_info.length - 1) {
        let distance = 0;
        if (i !== index) {
          distance = isNaN(parseFloat(axles_info[i].distance))
            ? 0
            : parseFloat(axles_info[i].distance);
        } else {
          distance = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
        }

        distances[i] = distance;
      }
      wheelCounts[i] = isNaN(parseFloat(axles_info[i].wheel_count))
        ? 0
        : parseFloat(axles_info[i].wheel_count);
      axleTypes[i] = isNaN(parseInt(axles_info[i].type_id)) ? 0 : parseInt(axles_info[i].type_id);
      wheels[i] = isNaN(parseInt(axles_info[i].wheels)) ? 0 : parseInt(axles_info[i].wheels);
    }

    if (index !== -1) {
      // axles_info[index].distance = value;
    }

    // set available load for axles
    const axlesWeights = axlesCalculator.calculateAxlesAllowedWeights(
      wheelCounts,
      distances,
      axleTypes,
      wheels,
      ownProps.axleLoads
    );
    for (let i = 0; i < axles_info.length; i++) {
      dispatch(
        change('application-load-form', `axles_info[${i}].permissible_load`, axlesWeights[i])
      );
    }
  };

  return {
    ...ownProps,
    ...stateProps,
    dispatch,

    handleFileUpload,
    handleFileRemove,

    handleSizeChange,
    handleAxleLoadChange,
    handleDistanceChange,

    length,
    width,
    height
  };
};

const connectedApplicationStepLoadForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationStepLoadForm);
export { connectedApplicationStepLoadForm as ApplicationStepLoadForm };
