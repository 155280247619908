import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ApplicationFilterForm from './ApplicationFilterForm';
import { applicationActions } from '../../../actions/applicationActions';

const filterSubmit = (values, dispatch) => {
  return dispatch(applicationActions.getControlFiltered(values));
};

const ApplicationFilterModal = props => {
  const { userApplications, handleModalClose } = props;

  return (
    <Dialog
      open={userApplications.showFormModal}
      onClose={handleModalClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-left">
        <Typography variant="title" color="inherit" className="flex">
          Фильтр
        </Typography>
        <IconButton color="inherit" onClick={handleModalClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog">
        <ApplicationFilterForm onSubmit={filterSubmit} />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { userApplications } = state;

  return {
    userApplications
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { userApplications } = stateProps;
  const { dispatch } = dispatchProps;

  const handleModalClose = () => {
    dispatch(applicationActions.closeFilterModal());
  };

  return {
    ...ownProps,
    userApplications,
    handleModalClose
  };
};

const connectedApplicationFilterModal = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationFilterModal);

export { connectedApplicationFilterModal as ApplicationFilterModal };
