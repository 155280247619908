import React from 'react';
import Grid from '@material-ui/core/Grid';
import FieldLabel from './FieldLabel';

const Section = ({ title, children }) => (
  <React.Fragment>
    <Grid item>
      <FieldLabel label={<h3 className="h3-title">{title}</h3>} />
    </Grid>
    {children}
  </React.Fragment>
);

export default Section;
