import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const resourceName = 'reports';

const getStatistic = filters => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${resourceName}/statistic?${filters.start_date ? `start_date=${filters.start_date}&` : ''}${
      filters.finish_date ? `finish_date=${filters.finish_date}&` : ''
    }${filters.issue_place ? `issue_places=${filters.issue_place}&` : ''}`,
    filters,
    true
  );
};

const getReports = ({
  id,
  issue_place_id,
  name,
  create_date_from,
  create_date_to,
  accept_date_from,
  accept_date_to,
  activate_date_from,
  activate_date_to,
  departments,
  per_page,
  page,
  type,
  privilege,
} = {}) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${resourceName}/active`,
    {
      id,
      name,
      issue_place_id: issue_place_id && issue_place_id.value,
      create_date_from,
      create_date_to,
      accept_date_from,
      accept_date_to,
      activate_date_from,
      activate_date_to,
      departments: departments.map(({ value }) => value),
      page,
      per_page,
      type,
      privilege,
    },
    true
  );
};

const exportReports = ({
  id,
  issue_place_id,
  name,
  create_date_from,
  create_date_to,
  accept_date_from,
  accept_date_to,
  activate_date_from,
  activate_date_to,
  departments,
  privilege,
}) => {
  return apiService.call(
    apiConstants.METHOD_POST,
    `/${resourceName}/active-export`,
    {
      id,
      name,
      issue_place_id: issue_place_id && issue_place_id.value,
      create_date_from,
      create_date_to,
      accept_date_from,
      accept_date_to,
      activate_date_from,
      activate_date_to,
      departments: departments.map(({ value }) => value),
      privilege,
    },
    true
  );
};

export default {
  getStatistic,
  getReports,
  exportReports
};
