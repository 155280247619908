import { chain, get } from 'lodash';

import { formatHelper } from '../../../../helpers/formatHelper';
import { QuickApplicationStatus } from '../../../../constants/quickApplicationConstants';
import { dictionaryHelper } from '../../../../helpers/dictionaryHelper';

function mapReview(application) {
  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    updatedAt: application.updated_at,
    userName: get(application, 'fast.name'),
    userCreated: get(application, 'fast.admin.name'),
    agreements: application.application_agreements.map(a => ({
      title: a.department.short_title,
      status: dictionaryHelper.getAgreementStatus(a.status)
    }))
  };
}

function mapDecline(application) {
  const events = application.events || {};
  const applicationAgreements = application.application_agreements || [];
  const declinedEvents = applicationAgreements.map(
    agreement => agreement.events.DEPAGENT_APP_DECLINE
  );
  const { created_at, user_name } =
    chain([events.ADMINGBU_APP_DECLINE, ...declinedEvents])
      .orderBy(['created_at', 'desc'])
      .head()
      .value() || {};

  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    createdAt: created_at || application.updated_at,
    userName: get(application, 'fast.name'),
    eventUser: user_name
  };
}

function mapAccept(application) {
  const events = application.events || {};
  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    createdAt: get(events, `ADMINGBU_APP_ACCEPT.created_at`, application.updated_at),
    userName: get(application, 'fast.name'),
    eventUser: get(events, `ADMINGBU_APP_ACCEPT.user_name`)
  };
}

function mapActive(application) {
  const events = application.events || {};
  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    formNumber: application.form_id,
    createdAt: get(events, 'ADMINGBU_APP_ACTIVATE.created_at', application.updated_at),
    userName: get(application, 'fast.name'),
    eventUser: get(events, 'ADMINGBU_APP_ACTIVATE.user_name'),
    runsLeft: application.dates.runs_count - application.dates.runs_used,
    runsTotal: application.dates.runs_count
  };
}

function mapComplete(application) {
  return {
    id: application.id,
    appNumber: `№${formatHelper.appNumber(application)}`,
    userName: get(application, 'fast.name'),
    runsLeft: application.dates.runs_count - application.dates.runs_used,
    runsTotal: application.dates.runs_count
  };
}

const mapItem = {
  [QuickApplicationStatus.InProgress]: mapReview,
  [QuickApplicationStatus.Declined]: mapDecline,
  [QuickApplicationStatus.Accepted]: mapAccept,
  [QuickApplicationStatus.Active]: mapActive,
  [QuickApplicationStatus.Completed]: mapComplete
};

export function getApplicationViewData(application, tab) {
  return mapItem[tab](application);
}
