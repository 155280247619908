import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { formValueSelector } from 'redux-form';
import { roleConstants } from '../../../constants/roleConstants';
import VehiclesTableList from './VehiclesTableList';
import { vehicleActions } from '../../../actions/vehicleActions';
import { vehicleConstants } from '../../../constants/vehicleConstants';

const VehiclesListPage = ({ cabinetVehicles, authentication, match, handleTabChange }) => (
  <main role="main" className="container text-center">
    <div className="kyc">
      <h2 className="h2-title">
        ТС и прицепы
        {(authentication.user.role_id === roleConstants.ROLE_FIRM ||
          authentication.user.role_id === roleConstants.ROLE_INDIVIDUAL) && (
          <Link to={`${match.url}/create`}>
            <Button aria-label="Добавить" className="btn-add">
              + Добавить
            </Button>
          </Link>
        )}
      </h2>

      <AppBar position="static" color="default" className="tabs_header">
        <Tabs
          value={cabinetVehicles.tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className="tab_style"
          variant="fullWidth"
        >
          <Tab
            classes={{ selected: 'tab_active' }}
            label="Новые"
            value={vehicleConstants.STATUS_NEW}
          />
          <Tab
            classes={{ selected: 'tab_active' }}
            label="Отклоненные"
            value={vehicleConstants.STATUS_DECLINE}
          />
          <Tab
            classes={{ selected: 'tab_active' }}
            label="На рассмотрении"
            value={vehicleConstants.STATUS_REVIEW}
          />
          <Tab
            classes={{ selected: 'tab_active' }}
            label="Одобренные"
            value={vehicleConstants.STATUS_ACCEPT}
          />
        </Tabs>
      </AppBar>
      <VehiclesTableList />
    </div>
  </main>
);

const mapStateToProps = state => {
  const { cabinetVehicles, authentication } = state;

  const selector = formValueSelector('vehicle-filter-form');
  const filterData = selector(state, 'type', 'brand', 'model', 'number');

  return {
    cabinetVehicles,
    filterData,
    authentication
  };
};

const mapDispatchToProps = dispatch => {
  // dispatch(vehicleActions.getCabinetPage(1, vehicleConstants.STATUS_NEW, ));

  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { cabinetVehicles, authentication, filterData } = stateProps;
  const { dispatch } = dispatchProps;

  const handleTabChange = (event, tabIndex) => {
    if (tabIndex !== cabinetVehicles.tabIndex) {
      dispatch(vehicleActions.setTab(tabIndex));
      dispatch(vehicleActions.getCabinetPage(tabIndex, filterData));
    }
  };

  return {
    ...ownProps,

    dispatch,

    cabinetVehicles,
    authentication,
    handleTabChange
  };
};

const connectedVehiclesPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VehiclesListPage);
export { connectedVehiclesPage as VehiclesListPage };
