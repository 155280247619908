import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import StartPointIcon from '@material-ui/icons/FiberManualRecord';
import PointIcon from '@material-ui/icons/Adjust';
import EndPointIcon from '@material-ui/icons/Room';
import DeleteIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles/index';

import { roleConstants } from '../../../constants/roleConstants';
import RouteAutosuggest from './RouteAutosuggest';
import * as actions from '../../../actions/newApplicationActions'

const styles = {
  addressAutosuggest: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 15
  }
};

const Route = ({
  index,
  marker: { text, id },
  length,
  isRouteEditable,
  userRole,

  removeMarker,
  changeMarkerText,

  classes
}) => {
  return (
    <div className="row points">
      <div className="col-md-1 points-date">
        {index === 0 && <StartPointIcon />}
        {index > 0 && index < length - 1 && <PointIcon />}
        {index === length - 1 && <EndPointIcon className="red" />}
      </div>
      <div className="col-md-9">
        <div className={classes.addressAutosuggest}>
          <RouteAutosuggest
            isRouteEditable={isRouteEditable}
            text={text}
            setMarkerValue={value => changeMarkerText(id, value)}
          />
        </div>
      </div>
      {userRole !== roleConstants.ROLE_WEIGHT_CONTROL && length > 2 && isRouteEditable && (
        <div className="col-md-1 points" onClick={() => removeMarker(id)}>
          <span>
            <DeleteIcon />
          </span>{' '}
        </div>
      )}
    </div>
  );
};

const RouteContent = ({ markers, errors, updateError, ...props }) =>
  markers.map((marker, index) => (
    <Route
      key={marker.id}
      index={index}
      marker={marker}
      length={markers.length}
      setError={value => updateError(marker.id, value)}
      {...props}
    />
  ));

export default compose(
  withStyles(styles),
  connect(
    undefined,
    {
      changeMarkerText: actions.changeMarkerText,
      removeMarker: actions.removeMarker
    }
  )
)(RouteContent);
