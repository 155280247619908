import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core';
import { applicationActions } from '../../../../actions/applicationActions';
import { Loader, TablePagination } from '../../../../components';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 700
  }
});

const ContentContainer = props => {
  const {
    adminApplications,
    handlePageChange,
    handlePerPageChange,
    children,
    classes,
    authentication
  } = props;
  const { items, meta } = adminApplications;
  const { user } = authentication;
  return (
    <div>
      {adminApplications.loading && <Loader />}
      <Paper className={classes.root}>
        <Table className={classes.table}>
          {items && !adminApplications.loading && children(items, user)}
        </Table>
      </Paper>
      {!adminApplications.loading && adminApplications.items.length === 0 && (
        <p>Заявки не найдены</p>
      )}
      {items && !adminApplications.loading && (
        <TablePagination
          meta={meta}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerPageChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { adminApplications, authentication } = state;

  const selector = formValueSelector('application-admin-filter-form');
  const filterData = selector(
    state,
    'id',
    'issue_place_id',
    'form_id',
    'name',
    'number',
    'trailer_number',
    'start_address',
    'finish_address',
    'trip_type',
    'admin_name',
    'sort_column',
    'sort_type',
    'showAll'
  );

  return {
    adminApplications,
    filterData,
    authentication
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { adminApplications, filterData, authentication } = stateProps;
  const { dispatch } = dispatchProps;

  const getApplications = ({ page, perPage } = {}) => {
    const values = {
      ...filterData,
      department_id: authentication.user.department_id,
      status: adminApplications.tabIndex,
      page: page || adminApplications.meta.current_page,
      per_page: perPage || adminApplications.meta.per_page
    };
    return dispatch(applicationActions.getAdminApplications(values));
  };

  const handlePageChange = page => getApplications({ page });

  const handlePerPageChange = perPage => getApplications({ page: 1, perPage });

  return {
    ...ownProps,
    adminApplications,
    authentication,

    dispatch,
    handlePageChange,
    handlePerPageChange
  };
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )
)(ContentContainer);
