import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PhotoModal from "../../admin/track/PhotoModal";
import {TableCell, Table, TableBody, TableHead, TableRow} from "@material-ui/core";
import {apvgkReportsService} from "../../../services/apvgkReportsService";
import GrzCheckStatusIcon from "./GrzCheckStatusIcon";


const GrzCheckResultsDialog = ({report}) => {
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);

    let finds = (report.grz_from_post_result) || {};
    finds = finds.find || [];
    return (
        <>
            <Link
                disabled={load || !finds.hasOwnProperty('find')}
                to={{}}
                onClick={() => {
                    setOpen(true)
                }}
            >
                Результаты
            </Link><GrzCheckStatusIcon report={report}/><br/>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
                <Toolbar>
                    <Typography variant="title" color="inherit" className="flex">
                        Результаты проверки №{(report.grz_from_post_result || {}).requestId}
                    </Typography>
                    <IconButton color="inherit" onClick={() => setOpen(false)}>
                        <CloseIcon className="blue"/>
                    </IconButton>
                </Toolbar>
                <DialogContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>ГРЗ</TableCell>
                                <TableCell>Совпадений</TableCell>
                                <TableCell>Фото</TableCell>
                                <TableCell>Документ</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {finds && finds.map && finds.map((result) => {
                            return <TableRow key={result.vTimeCheck}>
                                <TableCell>{result.vRegno}</TableCell>
                                <TableCell>{result.matchedPoints}</TableCell>
                                <TableCell>
                                    <PhotoModal
                                        id={report.apvgk_violation.id}
                                        params={{object: result.imageUrl}}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Link to={{}}
                                          onClick={(e) => {
                                              e.preventDefault()
                                              apvgkReportsService.downloadGrzFile(report.id, true, (new URLSearchParams({grz: result.vRegno})).toString())
                                                  .catch(e => alert('Ошибка, попробуйте позже'))
                                          }}
                                          target="_blank">
                                        Сформировать отчет
                                    </Link>
                                </TableCell>
                            </TableRow>
                        })}</TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default GrzCheckResultsDialog;
