import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { paymentsActions } from '../../../actions/paymentsActions';
import TablePagination from '../../../components/TablePagination/index';
import {dictionaryHelper} from '../../../helpers/dictionaryHelper'
import {roleConstants} from "../../../constants/roleConstants";
import {urlHelper} from "../../../helpers/urlHelper";
import {history} from "../../../helpers/history";

const PaymentsStatTable = props => {
    const { data, meta, handleChangePage} = props;
    let types = [];
    dictionaryHelper.paymentTypes.forEach((v, k) => {
      types[v.id] = v.title
    })
    return (
        <div>
            <Paper className="overflow">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Тип</TableCell>
                            <TableCell>Количество</TableCell>
                            <TableCell>Сумма</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((n, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{types[n.payment_type]}</TableCell>
                                    <TableCell>{n.count}</TableCell>
                                    <TableCell>{n.sum}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
};

const mapStateToProps = state => {
    const { payments } = state;

    return {
        payments
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { payments } = stateProps;
    const { dispatch } = dispatchProps;

    const handleChangePage = page => {
        dispatch(paymentsActions.getPage(page, payments.filters));
    };

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        handleChangePage
    };
};

const connectedPaymentsStatTable = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(PaymentsStatTable);
export { connectedPaymentsStatTable as PaymentsStatTable };
