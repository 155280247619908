import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import { applicationActions } from '../../../../actions/applicationActions';
import { roleConstants } from '../../../../constants/roleConstants';
import { MuiCheckbox } from '../../../../components/form/MuiCheckbox';
import { applicationConstants } from '../../../../constants/applicationConstants';
import ControlMarkCard from './ControlMarkCard';

const ControlMarksBlock = props => {
  const { application, user, adminApplications, handleAddControlMark, controlPosts } = props;

  const { addingMark, markError } = adminApplications;

  return (
    <div>
      {application &&
        application.control_marks &&
        application.control_marks.map((item, index) => {
          return [<ControlMarkCard controlMark={item} index={index} />, <br />];
        })}

      {application.status === applicationConstants.STATUS_ACTIVE &&
        user.role_id === roleConstants.ROLE_WEIGHT_CONTROL &&
        // applicationHelper.checkRunsExist(application) &&
        !addingMark && (
          <div>
            <Field
              name="reverse"
              type="checkbox"
              label="Тс движется в обратном направлении"
              className="checkbox"
              component={MuiCheckbox}
            />
            <br />
            <Button
              className="btn-add no-margin"
              onClick={e => handleAddControlMark(application.id)}
            >
              Добавить отметку о проезде
            </Button>
            <br />
            <span className="danger">{markError}</span>
          </div>
        )}
    </div>
  );
};

const mapStateToProps = state => {
  const { adminApplications, controlPosts } = state;
  return {
    adminApplications,
    controlPosts
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { controlPosts } = stateProps;
  const { reverse } = ownProps;
  const { dispatch } = dispatchProps;

  const handleAddControlMark = id => {
    return dispatch(applicationActions.addControlMark(id, reverse, controlPosts.post_id));
  };

  return {
    ...ownProps,
    ...stateProps,
    handleAddControlMark
  };
};

const connectedControlMarksBlock = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ControlMarksBlock);

export { connectedControlMarksBlock as ControlMarksBlock };
