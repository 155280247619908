import React from 'react';

import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { roleConstants } from '../../../constants/roleConstants';
import { IndividualForm } from './IndividualForm';
import { cabinetActions } from '../../../actions/cabinetActions';
import { FirmForm } from './FirmForm';
import { ChangePasswordForm } from './ChangePasswordForm';
import { userConstants } from '../../../constants/userConstants';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import {AlertModal} from "./AlertModal";

const getConfirmationStatus = statusKey => {
  switch (statusKey) {
    case userConstants.CONFIRMAtION_STATUS_NEW:
      return 'Новая заявка';
    case userConstants.CONFIRMAtION_STATUS_DECLINE:
      return 'Заявка отклонена';
    case userConstants.CONFIRMAtION_STATUS_REVIEW:
      return 'На повторном рассмотрении';
    case userConstants.CONFIRMAtION_STATUS_ACCEPT:
      return 'Заявка принята';
    default:
      return '';
  }
};

export const CABINET_SUBMIT_TITLE = "Подтверждение изменения данных"
export const CABINET_SUBMIT_MESSAGE = "После внесения изменений Вам будет недоступна возможность добавлять ТС или прицепы в систему и подавать заявления, пока админ не подтвердит новую информацию. Желаете продолжить?"
let CabinetMainPage = props => {
  const {
    user,
    cabinet,
    cabinetSubmit,
    changePasswordSubmit,
    handleDataConfirmationClose,
    remoteSubmit
  } = props;

  return (
    <main role="main" className="container">
      <div className="row">
        <div className="col-md-9">
          <h2 className="h2-title">Личный кабинет</h2>

          <div className="blue_frame">
            {cabinet && cabinet.message && <p>{cabinet.message}</p>}
            <p>
              <strong>Статус заявки:</strong>{' '}
              {cabinet && cabinet.info && getConfirmationStatus(user.confirmation_status)}
            </p>
            {cabinet &&
              cabinet.info &&
              user.confirmation_status === userConstants.CONFIRMAtION_STATUS_DECLINE && (
                <p>
                  <span>Причина отклонения: {cabinet.info && user.note}</span>
                </p>
              )}
            {cabinet &&
              cabinet.info &&
              user.confirmation_status !== userConstants.CONFIRMAtION_STATUS_ACCEPT && (
                <p>
                  <span>
                    Вы можете добавлять транспортные средства и заявления, только если заявка
                    утверждена администратором!
                  </span>
                  {!user.email_confirmed && (
                    <span>
                      {' '}
                      Для подтверждения регистрации администратором, пожалуйста, подтвердите Ваш
                      адрес электронной почты!
                    </span>
                  )}
                </p>
              )}
          </div>

          {user.role_id === roleConstants.ROLE_INDIVIDUAL && (
            <IndividualForm onSubmit={cabinetSubmit} />
          )}
          {user.role_id === roleConstants.ROLE_FIRM && <FirmForm onSubmit={cabinetSubmit} />}

          <ConfirmationModal
            title={CABINET_SUBMIT_TITLE}
            description={CABINET_SUBMIT_MESSAGE}
            handleSubmit={remoteSubmit}
            isShow={cabinet.showChangeDataModal}
            handleClose={handleDataConfirmationClose}
          />
          <AlertModal />

          <ChangePasswordForm onSubmit={changePasswordSubmit} />
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  const { user } = state.authentication;
  const { cabinet } = state;
  const { info } = cabinet;
  return {
    user,
    cabinet,
    info
  };
};

const mapDispatchToProps = dispatch => {
  dispatch(cabinetActions.getInfo());

  const cabinetSubmit = (values, dispatch) => {
    return dispatch(cabinetActions.saveInfo(values));
  };

  const changePasswordSubmit = values => {
    return dispatch(cabinetActions.changePassword(values));
  };

  const handleDataConfirmationClose = values => {
    return dispatch(cabinetActions.dataConfirmationClose(values));
  };

  const remoteSubmit = () => {
    dispatch(submit('cabinet-individual'));
    dispatch(submit('cabinet-firm'));
  };

  return {
    cabinetSubmit,
    changePasswordSubmit,
    handleDataConfirmationClose,
    remoteSubmit
  };
};

CabinetMainPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(CabinetMainPage);
export default CabinetMainPage;
