import React from 'react';
import { MainFooterBlock } from '../main/blocks/MainFooterBlock/MainFooterBlock';
import { AdminRoute } from '../../components/routes/AdminRoute';
import UsersLayout from './users/UsersLayout';
import RegAppsLayout from './regApps/RegAppsLayout';
import { CabinetMainMenu } from '../cabinet/blocks/CabinetMainMenu/CabinetMainMenu';
import { CabinetLeftMenu } from '../cabinet/blocks/CabinetLeftMenu/CabinetLeftMenu';
import AdminMainPage from './main/AdminMainPage';
import VehiclesLayout from './vehicles/VehiclesLayout';
import PanelLayout from './panel/PanelLayout';
import ApplicationsLayout from './applications/ApplicationsLayout';
import QuickApplicationsLayout from './quickApplications/QuickApplicationsLayout';
import AuditPage from './audit';
import ReportsPage from './reports';
import CooperationAdminLayout from './cooperation/CooperationAdminLayout';
import TrackPageView from "./track/TrackPageView";
import ApvgkReportsPage from "./reports-apvgk/index";

const AdminLayout = ({ match }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const isSmevEnabled = user.smev_enabled === 1;
  const links = [
    { key: 1, path: `${match.url}`, title: 'Личный кабинет', hasChild: false },
    {
      key: 2,
      path: `${match.url}/reg-apps`,
      title: 'Заявки на регистрацию',
      hasChild: true
    },
    {
      key: 3,
      path: `${match.url}/vehicles`,
      title: 'Заявки на регистрацию ТС',
      hasChild: true
    },
    {
      key: 4,
      path: `${match.url}/applications`,
      title: 'Модерация заявлений',
      hasChild: true
    },

    {
      key: 5,
      path: `${match.url}/users`,
      title: 'Учетные записи сотрудников',
      hasChild: true
    },
    {
      key: 6,
      path: `${match.url}/panel`,
      title: 'Панель администратора',
      hasChild: true
    },
    {
      key: 7,
      path: `${match.url}/quick_applications`,
      title: 'Быстрые разрешения',
      hasChild: true
    },
    {
      key: 8,
      path: `${match.url}/reports`,
      title: 'Статистика',
      hasChild: false
    },
    {
      key: 12,
      path: `${match.url}/reports-apvgk`,
      title: 'Статистика АПВГК',
      hasChild: false
    },
    {
      key: 9,
      path: `${match.url}/audit`,
      title: 'Логирование',
      hasChild: false
    }
  ];

  if (isSmevEnabled) {
    links.push({
      key: 10,
      path: `${match.url}/cooperation`,
      title: 'Межведомственное взаимодействие',
      hasChild: false
    });
  }
  if (user.email === 'gavrilov.albert@tatar.ru' || user.email === 'Lyudmila.Dmitrieva@tatar.ru' || user.email === 'dndinar13@gmail.com') {
    links.push({
      key: 11,
      path: `${match.url}/track`,
      title: 'Проезды',
      hasChild: false
    });
  }

  return (
    <div className="">
      <div className="cabinet-header">
        <CabinetMainMenu />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className="cabinet-left-menu">
              <CabinetLeftMenu links={links} />
            </div>
          </div>

          <div className="col-md-9 cabinet-content">
            <AdminRoute exact path={`${match.url}`} component={AdminMainPage} />

            <AdminRoute path={`${match.url}/reg-apps`} component={RegAppsLayout} />
            <AdminRoute path={`${match.url}/vehicles`} component={VehiclesLayout} />
            <AdminRoute path={`${match.url}/applications`} component={ApplicationsLayout} />

            <AdminRoute path={`${match.url}/users`} component={UsersLayout} />
            {/* Dictionaries */}
            <AdminRoute path={`${match.url}/panel`} component={PanelLayout} />
            <AdminRoute
              path={`${match.url}/quick_applications`}
              component={QuickApplicationsLayout}
            />
            <AdminRoute path={`${match.url}/reports`} component={ReportsPage} />
            <AdminRoute path={`${match.url}/reports-apvgk`} component={ApvgkReportsPage} />
            <AdminRoute path={`${match.url}/track`} component={TrackPageView} />
            <AdminRoute path={`${match.url}/audit`} component={AuditPage} />
            {isSmevEnabled && (
              <AdminRoute path={`${match.url}/cooperation`} component={CooperationAdminLayout} />
            )}
          </div>
        </div>
      </div>
      <MainFooterBlock />
    </div>
  );
};

export default AdminLayout;
