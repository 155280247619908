import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { vehicleBrandActions } from '../../../../../actions/vehicleBrandActions';
import { Loader } from '../../../../../components/Loader';
import TablePagination from '../../../../../components/TablePagination';
import SortableColumn from '../../../quickApplications/content/SortableColumn';
import { VehicleBrandFilter } from './VehicleBrandFilter';

const VehicleBrandsTableList = props => {
  const {
    data,
    meta,
    handleChangePage,
    handleDelete,
    vehicleBrands,
    openUpdateModal,
    handleAccept,
    handleHide
  } = props;

  return (
    <div>
      <Paper className="overflow">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <SortableColumn
                column="title"
                sort={vehicleBrands.sort || {}}
                onSortChange={sort => handleChangePage(1, vehicleBrands.filters, sort)}
              >
                Название
              </SortableColumn>
              <TableCell numeric>Действие</TableCell>
            </TableRow>
            <VehicleBrandFilter />
          </TableHead>
          <TableBody>
            {data.map((n, index) => {
              return (
                <TableRow key={n.id}>
                  <TableCell>{(meta.current_page - 1) * meta.per_page + index + 1}</TableCell>
                  <TableCell>{n.title}</TableCell>
                  <TableCell numeric>
                    {/* <IconButton aria-label="Редактировать"> */}
                    {/* <EditIcon onClick={openUpdateModal(n)} /> */}
                    {/* </IconButton> */}
                    {!n.accepted ? (
                      <Button
                        variant="outlined"
                        className="accept-btn"
                        size="small"
                        onClick={handleAccept(n.id)}
                      >
                        {n.accepting ? <Loader /> : <span>Утвердить</span>}
                      </Button>
                    ) : (
                      ''
                    )}
                    {!n.accepted ? (
                      <Button
                        variant="outlined"
                        className="accept-btn"
                        size="small"
                        onClick={handleHide(n.id)}
                      >
                        {n.accepting ? <Loader /> : <span>Отклонить</span>}
                      </Button>
                    ) : (
                      ''
                    )}
                    <Button
                      variant="outlined"
                      className="decline-btn"
                      size="small"
                      onClick={handleDelete(n.id)}
                    >
                      {n.deleting ? <Loader /> : <span>Удалить</span>}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        meta={meta}
        onChangePage={page => handleChangePage(page, vehicleBrands.filters, vehicleBrands.sort)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { vehicleBrands } = state;

  return {
    vehicleBrands
  };
};

const mapDispatchToProps = dispatch => {
  const handleChangePage = (page, filters, sort) => {
    dispatch(vehicleBrandActions.getPage(page, filters, sort));
  };

  const handleDelete = id => {
    return e => dispatch(vehicleBrandActions.delete(id));
  };

  const handleAccept = id => {
    return e => dispatch(vehicleBrandActions.accept(id));
  };
  const handleHide = id => {
    return e => dispatch(vehicleBrandActions.hide(id));
  };

  const openUpdateModal = item => {
    return e => {
      dispatch(vehicleBrandActions.setCurrentItem(item));
      dispatch(vehicleBrandActions.openFormModal());
    };
  };

  return {
    handleChangePage,
    handleDelete,
    handleAccept,
    handleHide,
    openUpdateModal
  };
};

const connectedVehicleBrandsTableList = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehicleBrandsTableList);
export { connectedVehicleBrandsTableList as VehicleBrandsTableList };
