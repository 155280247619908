import React from 'react';
import { connect } from 'react-redux';
import { Loader } from '../../../components';
import { getApplicationChangingStatus } from '../../../selectors/applicationSelectors';

const FullPageLoader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <div className="application-full-page-loader">
      <div className="application-full-page-loader-content">
        <div className="margin-bottom-10">
          <Loader />
        </div>{' '}
        Операция выполняется, пожалуйста подождите...
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoading: getApplicationChangingStatus(state)
});

export default connect(mapStateToProps)(FullPageLoader);
