import { isNumber } from 'lodash';
import { change } from 'redux-form';
import { vehicleConstants } from '../constants/vehicleConstants';
import { vehicleService } from '../services/vehicleService';
import { history } from '../helpers/history';
import { userService } from '../services/userService';
import { fileService } from '../services/fileService';
import { urlHelper } from '../helpers/urlHelper';
import { getOldFiles } from '../helpers/fileUploadHelper';

function getBrand(brand_id) {
  if (isNumber(brand_id)) {
    return { brand_id };
  }
  return { brand_id: '', brand_title: brand_id, is_new_brand: true };
}

function getModel(data) {
  const { model_id } = data;
  if (isNumber(model_id)) {
    return { model_id };
  }
  return { model_id: '', model_title: model_id, is_new_model: true };
}

const setTab = tabIndex => {
  return { type: vehicleConstants.SET_TAB_INDEX, tabIndex };
};
const setType = typeIndex => {
  return { type: vehicleConstants.SET_TYPE_INDEX, typeIndex };
};

const getByStatuses = () => {
  const request = () => {
    return { type: vehicleConstants.GET_BY_STATUSES_REQUEST };
  };

  const success = vehicles => {
    return {
      type: vehicleConstants.GET_BY_STATUSES_SUCCESS,
      vehicles
    };
  };

  const failure = error => {
    return { type: vehicleConstants.GET_BY_STATUSES_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    vehicleService
      .getByStatuses()
      .then(vehicles => dispatch(success(vehicles)), error => dispatch(failure(error)));
  };
};

const getAdminPage = (page, status, role) => {
  const request = (page, status, role) => {
    return {
      type: vehicleConstants.GET_PAGE_BY_STATUS_REQUEST,
      page,
      status,
      role
    };
  };

  const success = (vehicles, page, status, role) => {
    return {
      type: vehicleConstants.GET_PAGE_BY_STATUS_SUCCESS,
      vehicles,
      page,
      status,
      role
    };
  };

  const failure = error => {
    return { type: vehicleConstants.GET_PAGE_BY_STATUS_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(page, status));

    vehicleService
      .getAdminPage(page, status, role)
      .then(
        vehicles => dispatch(success(vehicles, page, status, role)),
        error => dispatch(failure(error))
      );
  };
};

const setStatus = (id, values) => {
  const request = values => {
    return { type: vehicleConstants.SET_STATUS_REQUEST, values };
  };

  const success = data => {
    return { type: vehicleConstants.SET_STATUS_SUCCESS, data };
  };

  const failure = (id, error) => {
    return { type: vehicleConstants.SET_STATUS_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(values));

    vehicleService.setStatus(values).then(
      user => {
        dispatch(success(user));
      },
      error => {
        dispatch(failure(id, error));
      }
    );
  };
};

/*
const adminUpdate = (values) => {
    const request = (value) => {
        return {type: vehicleConstants.ADMIN_UPDATE_REQUEST, value}
    };

    const success = (vehicle) => {
        return {type: vehicleConstants.ADMIN_UPDATE_SUCCESS, values}
    };

    const failure = (error) => {
        return {type: vehicleConstants.ADMIN_UPDATE_FAILURE, error}
    };

    return dispatch => {
        dispatch(request(values));

        return vehicleService.adminUpdate(values)
            .then(
                vehicle => dispatch(success(vehicle)),
                error => dispatch(failure(error))
            );
    };
};
*/

const toWork = id => {
  const request = id => {
    return { type: vehicleConstants.TO_WORK_REQUEST, id };
  };

  const success = (id, vehicle) => {
    return { type: vehicleConstants.TO_WORK_SUCCESS, id, vehicle };
  };

  const failure = error => {
    return { type: vehicleConstants.TO_WORK_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(id));

    return vehicleService
      .toWork(id)
      .then(vehicle => dispatch(success(id, vehicle.data)), error => dispatch(failure(error)));
  };
};

const accept = (id, role_id = null) => {
  const request = id => {
    return { type: vehicleConstants.ACCEPT_REQUEST, id };
  };

  const success = (id, vehicle) => {
    return { type: vehicleConstants.ACCEPT_SUCCESS, id, vehicle };
  };

  const failure = error => {
    return { type: vehicleConstants.ACCEPT_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(id));

    return vehicleService.accept(id).then(
      vehicle => {
        dispatch(success(id, vehicle.data));
        if (role_id) {
          history.push(`${urlHelper.getHomeUrl(role_id)}/vehicles`);
        }
      },
      error => dispatch(failure(error))
    );
  };
};

const decline = (values, role_id = null) => {
  const request = values => {
    return { type: vehicleConstants.DECLINE_REQUEST, values };
  };

  const success = (id, vehicle) => {
    return { type: vehicleConstants.DECLINE_SUCCESS, id, vehicle };
  };

  const failure = error => {
    return { type: vehicleConstants.DECLINE_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    return vehicleService.decline(values).then(
      vehicle => {
        dispatch(closeDeclineModal());
        dispatch(success(values.id, vehicle.data));
        if (role_id) {
          history.push(`${urlHelper.getHomeUrl(role_id)}/vehicles`);
        }
      },
      error => dispatch(failure(error))
    );
  };
};

const openDeclineModal = id => {
  return { type: vehicleConstants.DECLINE_OPEN, id };
};
const openCurrentDeclineModal = () => {
  return { type: vehicleConstants.DECLINE_CURRENT_ITEM_OPEN };
};
const closeDeclineModal = () => {
  return { type: vehicleConstants.DECLINE_CLOSE };
};

const openReasonModal = id => {
  return { type: vehicleConstants.REASON_OPEN, id };
};
const closeReasonModal = () => {
  return { type: vehicleConstants.REASON_CLOSE };
};

const openDetailsModal = id => {
  return { type: vehicleConstants.DETAILS_OPEN, id };
};
const closeDetailsModal = () => {
  return { type: vehicleConstants.DETAILS_CLOSE };
};

const getFirmUsers = () => {
  const request = () => {
    return { type: vehicleConstants.GET_FIRM_USERS_REQUEST };
  };

  const success = users => {
    return { type: vehicleConstants.GET_FIRM_USERS_SUCCESS, users: users.data };
  };

  const failure = error => {
    return { type: vehicleConstants.GET_FIRM_USERS_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    userService
      .getFirmUsers()
      .then(users => dispatch(success(users)), error => dispatch(failure(error)));
  };
};

const getFirmVehicles = (firmId, values) => {
  const request = v => {
    return { type: vehicleConstants.GET_FIRM_VEHICLES_REQUEST, v };
  };

  const success = vehicles => {
    return {
      type: vehicleConstants.GET_FIRM_VEHICLES_SUCCESS,
      vehicles
    };
  };

  const failure = error => {
    return { type: vehicleConstants.GET_FIRM_VEHICLES_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    vehicleService
      .getFirmVehicles(firmId, values)
      .then(vehicles => dispatch(success(vehicles)), error => dispatch(failure(error)));
  };
};

const resetFirmVehicles = () => ({ type: vehicleConstants.GET_FIRM_VEHICLES_RESET });

const getExceptions = () => {
  const request = () => {
    return { type: vehicleConstants.GET_EXCEPTIONS_REQUEST };
  };

  const success = emails => {
    return { type: vehicleConstants.GET_EXCEPTIONS_SUCCESS, emails };
  };

  const failure = error => {
    return { type: vehicleConstants.GET_EXCEPTIONS_FAILURE, error };
  };

  return dispatch => {
    dispatch(request());

    userService
      .getExceptions()
      .then(response => dispatch(success(response.data)), error => dispatch(failure(error)));
  };
};

const checkPrivilegeStatuses = vehicles => {
  const request = numbers => {
    return { type: vehicleConstants.CHECK_STATUSES_REQUEST, numbers };
  };

  const success = privilegeVehicles => {
    return { type: vehicleConstants.CHECK_STATUSES_SUCCESS, privilegeVehicles };
  };

  const failure = error => {
    return { type: vehicleConstants.CHECK_STATUSES_FAILURE, error };
  };

  const numbers = [];
  for (let i = 0; i < vehicles.data.length; i++) {
    numbers[i] = vehicles.data[i].real_number;
  }
  return dispatch => {
    dispatch(request(numbers));

    vehicleService.checkStatuses(numbers).then(
      response => {
        dispatch(success(response.data));
      },
      error => dispatch(failure(error))
    );
  };
};

const getCabinetPage = (tabIndex, values) => {
  const request = (tab, filters) => {
    return { type: vehicleConstants.GET_CABINET_PAGE_REQUEST, tab, filters };
  };

  const success = vehicles => ({
    type: vehicleConstants.GET_CABINET_PAGE_SUCCESS,
    vehicles
  });

  const failure = error => ({
    type: vehicleConstants.GET_CABINET_PAGE_FAILURE,
    error
  });

  return dispatch => {
    dispatch(request(tabIndex, values));

    vehicleService.getCabinetPage(tabIndex, values).then(
      vehicles => {
        dispatch(success(vehicles));
        // @todo check privilege
        dispatch(checkPrivilegeStatuses(vehicles));
      },
      error => dispatch(failure(error))
    );
  };
};

const _delete = id => {
  const request = id => {
    return { type: vehicleConstants.DELETE_REQUEST, id };
  };

  const success = id => {
    return { type: vehicleConstants.DELETE_SUCCESS, id };
  };

  const failure = (id, error) => {
    return { type: vehicleConstants.DELETE_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleService.delete(id).then(
      response => {
        if (response.success) {
          return dispatch(success(id));
        }
        return dispatch(failure(id, response.error));
      },
      error => dispatch(failure(id, error))
    );
  };
};

const getItem = (id, onSuccess = null) => {
  const request = id => {
    return { type: vehicleConstants.LOAD_REQUEST, id };
  };

  const success = vehicle => {
    return { type: vehicleConstants.LOAD_SUCCESS, vehicle };
  };

  const failure = error => {
    return { type: vehicleConstants.LOAD_FAILURE, id, error };
  };

  return dispatch => {
    dispatch(request(id));

    vehicleService.getItem(id).then(
      vehicle => {
        dispatch(success(vehicle));
        if (onSuccess) {
          onSuccess(dispatch, vehicle.data);
        }
      },
      error => dispatch(failure(error))
    );
  };
};

const create = values => {
  const request = value => {
    return { type: vehicleConstants.CREATE_REQUEST, value };
  };

  const success = (vehicle, dispatch) => {
    history.push('/cabinet/vehicles');
    dispatch(getCabinetPage(1, 0));
    return { type: vehicleConstants.CREATE_SUCCESS, values };
  };

  return async dispatch => {
    const brand = getBrand(values.brand_id);
    const model = getModel(values, brand);
    const newValues = { ...values, ...brand, ...model };
    dispatch(request(newValues));

    return vehicleService.create(newValues).then(vehicle => dispatch(success(vehicle, dispatch)));
  };
};

const update = values => {
  const request = value => {
    return { type: vehicleConstants.UPDATE_REQUEST, value };
  };

  const success = vehicle => {
    return { type: vehicleConstants.UPDATE_SUCCESS, values };
  };

  return async dispatch => {
    dispatch(request(values));
    const brand = getBrand(values.brand_id);
    const model = getModel(values, brand);
    const newValues = { ...values, ...brand, ...model };

    return vehicleService.update(newValues).then(vehicle => {
      // remove from accepted list
      if (newValues.status === vehicleConstants.STATUS_ACCEPT) {
        dispatch(vehicleActions.removeTabListItem(vehicle.id));
      }
      return dispatch(success(vehicle));
    });
  };
};

const setBrandId = value => {
  return { type: vehicleConstants.SET_BRAND_ID, brandId: value };
};
const setVehicleTypeId = value => {
  return { type: vehicleConstants.SET_VEHICLE_TYPE_ID, typeId: value };
};

const setOwnerName = name => {
  return { type: vehicleConstants.SET_VEHICLE_OWNER_NAME, name };
};
const setAxleCount = axle_count => {
  return { type: vehicleConstants.SET_VEHICLE_AXLE_COUNT, axle_count };
};

const userFormAdd = () => {
  return { type: vehicleConstants.USER_FORM_ADD };
};
const userFormRemove = id => {
  return { type: vehicleConstants.USER_FORM_REMOVE, id };
};
const resetCurrentItem = () => {
  return { type: vehicleConstants.RESET_CURRENT_ITEM };
};

const setActiveField = fieldName => {
  return { type: vehicleConstants.ACTIVE_FIELD, field: fieldName };
};
const changeIsExistingNewUser = (id, value) => {
  return { type: vehicleConstants.SET_IS_EXISTING_NEW_USER, id, value };
};

// vehicle
const uploadLicense = (values, uploaderId) => {
  const request = values => {
    return {
      type: vehicleConstants.LICENSE_UPLOAD_REQUEST,
      uploaderId,
      name: values.name
    };
  };
  const success = data => {
    return { type: vehicleConstants.LICENSE_UPLOAD_SUCCESS, data, uploaderId };
  };
  const failure = () => {
    return { type: vehicleConstants.LICENSE_UPLOAD_FAILURE, uploaderId };
  };

  return (dispatch, getState) => {
    dispatch(request(values));

    return fileService
      .uploadLicense(values)
      .then(response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));
        }

        const form = 'vehicle-car-form';
        const oldFiles = getOldFiles(form, getState, 'license_files');
        dispatch(change(form, 'license_files', [...oldFiles, response.data]));

        return response.data;
      })
      .catch(err => {
        dispatch(failure());
        console.log('Upload error', err);
      });
  };
};
const removeLicenseFile = id => {
  return { type: vehicleConstants.LICENSE_REMOVE, id };
};

const uploadPts = (values, uploaderId) => {
  const request = values => {
    return {
      type: vehicleConstants.PTS_UPLOAD_REQUEST,
      uploaderId,
      name: values.name
    };
  };
  const success = data => {
    return { type: vehicleConstants.PTS_UPLOAD_SUCCESS, data, uploaderId };
  };
  const failure = () => {
    return { type: vehicleConstants.PTS_UPLOAD_FAILURE, uploaderId };
  };

  return (dispatch, getState) => {
    dispatch(request(values));

    return fileService
      .uploadPts(values)
      .then(response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));
        }

        const form = 'vehicle-car-form';
        const oldFiles = getOldFiles(form, getState, 'pts_files');
        dispatch(change(form, 'pts_files', [...oldFiles, response.data]));
        // if(onComplete){
        //     onComplete(response.data);
        // }

        return response.data;
      })
      .catch(err => {
        dispatch(failure());
        console.log('Upload error', err);
      });
  };
};
const removePtsFile = id => {
  return { type: vehicleConstants.PTS_REMOVE, id };
};

const uploadLease = (values, existingFiles, dispatch) => {
  const request = data => {
    return { type: vehicleConstants.LEASE_UPLOAD_REQUEST, data };
  };
  const success = data => {
    return { type: vehicleConstants.LEASE_UPLOAD_SUCCESS, data };
  };

  return dispatch => {
    dispatch(request(values));

    return fileService
      .uploadLease(values)
      .then(response => {
        if (response.data && response.data.url) {
          dispatch(success(response.data));
          dispatch(
            change('vehicle-car-form', 'leaseFiles', `${existingFiles},${response.data.id}`)
          );

          // set new files list
          // existingFiles.push(response.data);
          const new_files = [...existingFiles, response.data];
          dispatch(change('vehicle-car-form', 'lease_files', new_files));
        }

        return response.data;
      })
      .catch(err => {
        console.log('Upload error', err);
      });
  };
};

const removeLeaseFile = id => {
  return { type: vehicleConstants.LEASE_REMOVE, id };
};

const removeTabListItem = id => {
  return { type: vehicleConstants.REMOVE_TAB_LIST_ITEM, id };
};

const adminFilter = values => {
  const request = values => {
    return { type: vehicleConstants.ADMIN_FILTER_REQUEST, values };
  };

  const success = response => {
    return { type: vehicleConstants.ADMIN_FILTER_SUCCESS, response };
  };

  const failure = error => {
    return { type: vehicleConstants.ADMIN_FILTER_FAILURE, error };
  };

  return dispatch => {
    dispatch(request(values));

    return vehicleService.adminFilter(values).then(
      response => {
        dispatch(success(response));
      },
      error => dispatch(failure(error))
    );
  };
};

const closeErrorModal = () => {
  return { type: vehicleConstants.CLOSE_ERROR_MODAL };
};

export const vehicleActions = {
  setTab,
  setType,

  getByStatuses,
  getAdminPage,

  setStatus,

  toWork,
  accept,
  decline,

  openDeclineModal,
  closeDeclineModal,
  openCurrentDeclineModal,

  openReasonModal,
  closeReasonModal,

  openDetailsModal,
  closeDetailsModal,

  getFirmUsers,
  getFirmVehicles,
  resetFirmVehicles,

  getCabinetPage,
  getItem,
  create,
  update,
  delete: _delete,

  setBrandId,
  setVehicleTypeId,

  // change vehicle form values
  setOwnerName,
  setAxleCount,

  userFormAdd,
  userFormRemove,
  resetCurrentItem,

  setActiveField,

  changeIsExistingNewUser,

  uploadLicense,
  removeLicenseFile,
  uploadPts,
  removePtsFile,
  uploadLease,
  removeLeaseFile,

  getExceptions,

  removeTabListItem,

  adminFilter,

  closeErrorModal
};
