import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { departmentActions } from '../../../../actions/departmentActions';
import { DepartmentsTableList } from './DepartmentTableList';
import { Loader } from '../../../../components/Loader';
import { DepartmentModal } from './DepartmentModal';

class DepartmentBlock extends Component {
  componentDidMount() {
    const { departments } = this.props;

    if (!departments.items || departments.items.length === 0) {
      this.props.dispatch(departmentActions.getPage(1));
    }
  }

  render() {
    const { departments, openCreateModal } = this.props;

    return (
      <main role="main" className="container text-left">
        <p>
          Ведомства для согласования заявлений
          <Button aria-label="Добавить" className="btn-add" onClick={openCreateModal}>
            + Создать
          </Button>
        </p>

        <div className="">
          {departments.loading && <Loader />}
          {departments.error && <span className="text-danger">Ошибка: {departments.error}</span>}
          {departments.items && (
            <DepartmentsTableList data={departments.items} meta={departments.meta} />
          )}
        </div>

        <DepartmentModal />
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { departments } = state;
  return {
    departments
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    openCreateModal: e => {
      dispatch(departmentActions.setCurrentItem(null));
      dispatch(departmentActions.openFormModal());
    }
  };
};

const connectedDepartmentBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentBlock);
export { connectedDepartmentBlock as DepartmentBlock };
