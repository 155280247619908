import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import * as actions from '../../../actions/newApplicationActions';
import { Loader } from '../../../components';
import { getApplicationData, getIsShowPrintModal } from '../../../selectors/applicationSelectors';
import { applicationService } from '../../../services/applicationService';

const ApplicationPrintModal = ({
  setShowPrintModal,
  showPrintModal,
  application,
  enqueueSnackbar,
  updatePrintForm
}) => {
  const onClose = () => setShowPrintModal(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [number, setNumber] = React.useState(application.form_id || '');
  const onSubmit = React.useCallback(async () => {
    setSubmitting(true);
    try {
      const response = await applicationService.printPdf({
        application_id: application.id,
        form_id: number
      });
      if (response.data && response.data.url) {
        updatePrintForm({ form_id: number, printLink: response.data.url });
        window.open(response.data.url, '_blank');
      } else {
        enqueueSnackbar('Не удалось распечатать заявление! Возможно, заполнены не все данные!', {
          variant: 'error'
        });
      }
    } catch (e) {
      enqueueSnackbar('Номер бланка уже был использован ранее!', { variant: 'error' });
    }
    setSubmitting(false);
  }, [number]);

  return (
    <Dialog
      className="dialog-window"
      open={showPrintModal}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Печать
        </Typography>
        <IconButton color="inherit" onClick={onClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>
      <DialogContent className="dialog">
        <div className="form-group">
          <div className="row">
            <div className="col-md-4">
              <p className="label-form">Номер бланка</p>
            </div>
            <div className="col-md-8 input-link-container">
              <input
                className="form-control"
                value={number}
                onChange={e => setNumber(e.target.value)}
                disabled={!!application.form_id}
              />
            </div>
          </div>
        </div>
        <br />
        <Button
          variant="contained"
          color="primary"
          className="btn-add no-margin"
          type="submit"
          onClick={onSubmit}
          disabled={submitting}
        >
          {submitting && <Loader />} Печать
        </Button>
        <br />
        <br />
        {application.printLink && (
          <a target="_blank" href={application.printLink}>
            Предпромотр документа
          </a>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => ({
  showPrintModal: getIsShowPrintModal(state),
  application: getApplicationData(state)
});

const mapDispatchToProps = {
  updatePrintForm: actions.updatePrintForm,
  setShowPrintModal: actions.setShowPrintModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ApplicationPrintModal));
