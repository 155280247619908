import React, {Component} from 'react';
import {connect} from 'react-redux';
import {controlPostService} from "../../../services/controlPostService";
import {ApvgkAndPostLinkCard} from "./ApvgkAndPostLinkCard";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {Loader} from "../../../components";

class ApvgkConnectionsPage extends Component {
    state = {
        list: [],
        load: false,
    };

    componentDidMount() {
        const {fetchConnectApvgkList} = this.props;
        this.setState({...this.state, load: true})
        fetchConnectApvgkList(localStorage.getItem('post_id')).then(r => {
            this.setState({
                list: r.data || [],
                load:false
            })
        }).catch((e) => {
            console.log(e);
            this.setState({...this.state, load: false})
        })
    }

    render() {
        const {params, controlPosts, connectApvgk} = this.props;
        return (
            <main role="main" className="container">
                <div className="">
                    <h2 className="h2-title">Список смен </h2>
                    <br/>
                    <Link to={`${window.location.pathname}/add`}>
                        <Button className="info-btn">Добавить</Button>
                    </Link>
                </div>
                <div className="">
                    <br/>
                    {this.state.load && <Loader size={45} />}
                    {this.state.list.map(item => (
                        <div key={item.id}>
                            <ApvgkAndPostLinkCard link={item}/>
                            <br/>
                        </div>
                    ))}
                </div>
            </main>
        );
    }
}

const mapStateToProps = state => {

    // const {  } = state;

    return {};
};

const mapDispatchToProps = dispatch => {
    const params = new URLSearchParams(window.location.search);
    const {connectApvgkList} = controlPostService;

    return {
        fetchConnectApvgkList: connectApvgkList,
        params,
        dispatch
    };
};

const connectedApplicationsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ApvgkConnectionsPage);
export {connectedApplicationsPage as ApvgkConnectionsPage};
