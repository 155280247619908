import React from 'react';
import { get } from 'lodash';
import TableCell from '@material-ui/core/TableCell';

const EmployeeCell = ({ application, userName, ...props }) => {
  const employee = get(application, 'employee.name');
  return (
    <TableCell {...props}>
      {userName} {!!employee && `(${employee})`}
    </TableCell>
  );
};

export default EmployeeCell;
