import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { applicationActions } from '../../../../actions/applicationActions';
import { roleConstants } from '../../../../constants/roleConstants';

class ApplicationActivateButton extends Component {
  render() {
    const { application, user, handleActivate, cls } = this.props;

    return (application.status === applicationConstants.STATUS_ACCEPT_WITH_CHANGES ||
      application.status === applicationConstants.STATUS_ACCEPT) &&
      (user.role_id === roleConstants.ROLE_ADMIN || user.role_id === roleConstants.ROLE_OFFICER) ? (
      <Button className={cls} onClick={handleActivate(application.id)}>
        Активировать
      </Button>
    ) : (
      ''
    );
  }
}

const mapStateToProps = state => {
  const { adminApplications } = state;

  return {
    adminApplications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;

  const handleActivate = id => {
    return e => dispatch(applicationActions.openActivateModal(id));
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,

    handleActivate
  };
};

const connectedApplicationActivateButton = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ApplicationActivateButton);
export { connectedApplicationActivateButton as ApplicationActivateButton };
