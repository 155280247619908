export const userConstants = {
  CONFIRMAtION_STATUS_NEW: 0,
  CONFIRMAtION_STATUS_DECLINE: 1,
  CONFIRMAtION_STATUS_REVIEW: 2,
  CONFIRMAtION_STATUS_ACCEPT: 3,

  GET_PAGE_REQUEST: 'USER_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'USER_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'USER_GET_PAGE_FAILURE',

  GETALL_REQUEST: 'USER_GETALL_REQUEST',
  GETALL_SUCCESS: 'USER_GETALL_SUCCESS',
  GETALL_FAILURE: 'USER_GETALL_FAILURE',

  LOAD_REQUEST: 'USER_LOAD_REQUEST',
  LOAD_SUCCESS: 'USER_LOAD_SUCCESS',
  LOAD_FAILURE: 'USER_LOAD_FAILURE',

  CREATE_REQUEST: 'USER_CREATE_REQUEST',
  CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  CREATE_FAILURE: 'USER_CREATE_FAILURE',

  UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'USER_UPDATE_FAILURE',

  DELETE_REQUEST: 'USER_DELETE_REQUEST',
  DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  DELETE_FAILURE: 'USER_DELETE_FAILURE',

  RESET_CURRENT_ITEM: 'USER_RESET_CURRENT_ITEM',
  SET_FILTER: 'USER_SET_FILTER',

  FORM_MODAL_OPEN: 'USER_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'USER_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'USER_SET_CURRENT_ITEM',

  LOAD_FIRMS_REQUEST: 'USER_LOAD_FIRMS_REQUEST',
  LOAD_FIRMS_SUCCESS: 'USER_LOAD_FIRMS_SUCCESS',
  LOAD_FIRMS_FAILURE: 'USER_LOAD_FIRMS_FAILURE',

  USER_CHANGE_EMAIL: 'USER_CHANGE_EMAIL'
};
