import { apiService } from './apiService';
import { apiConstants } from '../constants/apiConstants';

const getResourceName = () => {
  return 'axle-loads';
};

const getPage = (page, tabIndex) => {
  return apiService.call(
    apiConstants.METHOD_GET,
    `/${getResourceName()}/page?page=${page}&is_spring=${tabIndex}`,
    {},
    true
  );
};

const getAll = () => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}`, {}, true);
};

const getItem = id => {
  return apiService.call(apiConstants.METHOD_GET, `/${getResourceName()}/${id}`, {}, true);
};

const update = values => {
  return apiService.call(
    apiConstants.METHOD_PUT,
    `/${getResourceName()}/${values.id}`,
    values,
    true
  );
};

export const axleLoadService = {
  getPage,
  getAll,
  getItem,
  update
};
