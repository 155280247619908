import React from 'react';

export const FormTextareaLabel = ({
  disabled,
  input,
  label,
  type,
  row,
  meta: { touched, error }
}) => (
  <div className={`form-group${touched && error ? ' has-error' : ''}`}>
    <div className="row">
      <div className="col-md-4">
        <p className="label-form">{label}</p>
      </div>
      <div className="col-md-8">
        <textarea
          disabled={disabled}
          className={`form-control${touched && error ? ' form-control-error' : ''}`}
          {...input}
          placeholder={label}
          type={type}
          rows={row}
        />
        {touched && (error && <span>{error}</span>)}
      </div>
    </div>
  </div>
);
