import React from 'react';
import AgreementsList from './AgreementsList';
import AgreementsForm from './AgreementsForm';
import ShowByStatus from '../ShowByStatus';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { roleConstants } from '../../../../constants/roleConstants';

const ApplicationAgreements = ({ application }) => (
  <React.Fragment>
    <AgreementsList application={application} />
    <ShowByStatus
      allowedRoles={[roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER]}
      allowedStatuses={[applicationConstants.STATUS_REVIEW, applicationConstants.STATUS_REPEAT]}
    >
      <AgreementsForm />
    </ShowByStatus>
  </React.Fragment>
);

export default ApplicationAgreements;
