import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';

import { QuickApplicationStatus } from '../../../constants/quickApplicationConstants';
import InProgressContent from './content/InProgressContent';
import DeclineContent from './content/DeclineContent';
import AcceptContent from './content/AcceptContent';
import ActiveContent from './content/ActiveContent';
import CompleteContent from './content/CompleteContent';
import Filter from './Filter';
import { StateContext } from '../reports/components/StateContext';
import { roleConstants } from '../../../constants/roleConstants';
import { useWithQuery } from '../cooperation/util/useWithQuery';

const TabsInfo = {
  [QuickApplicationStatus.InProgress]: {
    value: QuickApplicationStatus.InProgress,
    Component: InProgressContent,
    name: 'В работе'
  },
  [QuickApplicationStatus.Declined]: {
    value: QuickApplicationStatus.Declined,
    Component: DeclineContent,
    name: 'Отклоненные'
  },
  [QuickApplicationStatus.Accepted]: {
    value: QuickApplicationStatus.Accepted,
    Component: AcceptContent,
    name: 'Одобренные'
  },
  [QuickApplicationStatus.Active]: {
    value: QuickApplicationStatus.Active,
    Component: ActiveContent,
    name: 'Активные'
  },
  [QuickApplicationStatus.Completed]: {
    value: QuickApplicationStatus.Completed,
    Component: CompleteContent,
    name: 'Завершенные'
  }
};

const ApplicationsListPage = ({ user }) => {
  const [tab, setTab] = useWithQuery('tab', QuickApplicationStatus.InProgress, v =>
    Number.parseInt(v, 10)
  );
  const [filters, onFiltersChange] = useWithQuery('filters', {});
  const [meta, setMeta] = useWithQuery('meta', { per_page: 10 });
  const [sort, onSortChange] = useWithQuery('sort', {});
  const { Component } = TabsInfo[tab];

  return (
    <main role="main" className="container">
      <div className="kyc">
        <Grid container direction="column" spacing={16}>
          <Grid item container alignItems="center" justify="space-between">
            <Grid item>
              <h2 className="h2-title no-margin">Быстрые разрешения</h2>
            </Grid>
            <Grid item>
              <Button
                className="btn-add"
                variant="outlined"
                color="primary"
                type="button"
                component={Link}
                to="quick_applications/create"
                // onClick={handleModalOpen}
              >
                Создать разрешение
              </Button>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" justify="space-between">
            <Grid item>
              <Filter currentFilters={filters} onFiltersChange={onFiltersChange} />
            </Grid>
            {user.role_id === roleConstants.ROLE_OFFICER && (
              <Grid item>
                <FormControlLabel
                  labelPlacement="start"
                  label={<Typography color="textSecondary">Показать все разрешения</Typography>}
                  control={
                    <Switch
                      color="primary"
                      checked={filters.showAll === 1}
                      onChange={e =>
                        onFiltersChange({ ...filters, showAll: e.target.checked ? 1 : 0 })
                      }
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid container item>
            <AppBar position="static" color="default" className="tabs_header">
              <Tabs
                value={tab}
                onChange={(e, newValue) => {
                  setMeta({ ...meta, page: 0 });
                  setTab(newValue);
                }}
                indicatorColor="primary"
                textColor="primary"
                className="tab_style"
                variant="fullWidth"
              >
                {Object.entries(TabsInfo).map(([t, { name, value }]) => (
                  <Tab key={name} classes={{ selected: 'tab_active' }} label={name} value={value} />
                ))}
              </Tabs>
            </AppBar>
          </Grid>
          {Component && (
            <StateContext.Provider value={{ filters, sort, onSortChange, meta, setMeta }}>
              <Paper className="overflow overflow-y-hidden">
                <Component />
              </Paper>
            </StateContext.Provider>
          )}
        </Grid>
      </div>
    </main>
  );
};

export default connect(state => ({
  user: state.authentication.user
}))(ApplicationsListPage);
