import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { applicationActions } from '../../../../actions/applicationActions';
import { TemplateCard } from './TemplateCard';
import { urlHelper } from '../../../../helpers/urlHelper';

class ApplicationTemplatesListPage extends Component {
  componentDidMount() {
    // const {userApplications} = this.props;

    // if (!userApplications.templates || userApplications.templates.length === 0) {
    this.props.dispatch(applicationActions.getUserTemplates());
    // }
  }

  render() {
    const { userApplications, authentication } = this.props;
    const { user } = authentication;
    const { templates } = userApplications;

    return (
      <main role="main" className="container text-center">
        <div className="">
          <h2 className="h2-title">
            Мои шаблоны &nbsp;&nbsp;&nbsp;
            <Link to={`${urlHelper.getHomeUrl(user.role_id)}/applications`}>
              <Button color="primary" className="info-btn" type="button">
                История заявлений
              </Button>
            </Link>
          </h2>
        </div>

        <div className="">
          {templates.map(item => (
            <TemplateCard key={item.id} application={item} />
          ))}
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { userApplications, authentication } = state;
  return {
    userApplications,
    authentication
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const connectedApplicationTemplatesListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationTemplatesListPage);
export { connectedApplicationTemplatesListPage as ApplicationTemplatesListPage };
