import React from 'react';
import { ControlPostBlock } from './ControlPostBlock';
import { ControlPostErrorModal } from './ControlPostErrorModal';

const ControlPostsPage = props => {
  return (
    <div>
      <ControlPostBlock />
      <ControlPostErrorModal />
    </div>
  );
};

export default ControlPostsPage;
