import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { routeAddressActions } from '../../../../actions/routeAddressActions';
import { Loader } from '../../../../components/Loader';
import TablePagination from '../../../../components/TablePagination';
import { RouteAddressFilter } from './RouteAddressFilter';

const RouteAddressTableList = props => {
  const { data, meta, routeAddresses, handleChangePage, handleDelete, openUpdateModal } = props;

  return (
    <div>
      <Paper className="overflow">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>Название</TableCell>
              <TableCell>Значение</TableCell>
              <TableCell numeric>Действие</TableCell>
            </TableRow>
            <RouteAddressFilter />
          </TableHead>
          <TableBody>
            {data.map((n, index) => {
              return (
                <TableRow key={n.id}>
                  <TableCell>{(meta.current_page - 1) * meta.per_page + index + 1}</TableCell>
                  <TableCell>{n.title}</TableCell>
                  <TableCell>{n.value}</TableCell>
                  <TableCell numeric>
                    <Button
                      variant="outlined"
                      className="info-btn"
                      size="small"
                      onClick={openUpdateModal(n)}
                    >
                      <span>Редактировать</span>
                    </Button>
                    <Button
                      variant="outlined"
                      className="decline-btn"
                      size="small"
                      onClick={handleDelete(n.id)}
                    >
                      {n.deleting ? <Loader /> : <span>Удалить</span>}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        meta={meta}
        onChangePage={page => handleChangePage(page, routeAddresses.filters)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { routeAddresses } = state;

  return {
    routeAddresses
  };
};

const mapDispatchToProps = dispatch => {
  const handleChangePage = (page, filters) => {
    dispatch(routeAddressActions.getPage(page, filters));
  };

  const handleDelete = id => {
    return e => dispatch(routeAddressActions.delete(id));
  };

  const openUpdateModal = item => {
    return e => {
      dispatch(routeAddressActions.setCurrentItem(item));
      dispatch(routeAddressActions.openFormModal());
    };
  };

  return {
    handleChangePage,
    handleDelete,
    openUpdateModal
  };
};

const connectedRouteAddressTableList = connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteAddressTableList);
export { connectedRouteAddressTableList as RouteAddressTableList };
