import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const ConfirmationModal = props => {
  const { title, description, isShow, handleClose, handleSubmit } = props;

  return (
    <Dialog open={isShow} onClose={handleClose} aria-labelledby="form-dialog-title">
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          {title}
        </Typography>
        <IconButton color="inherit" onClick={handleClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <div>{description}</div>
        <br />
        <button className="btn" onClick={handleSubmit}>
          Да
        </button>
        &nbsp;
        <button className="btn" onClick={handleClose}>
          Нет
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
