import React from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import MaterialInput from '../../admin/quickApplications/CreateQuickApplication/components/MaterialInput';

const UsersFilter = ({ filters, setFilters }) => {
  return (
    <Formik
      initialValues={filters}
      onSubmit={value => setFilters(value)}
      render={({ submitForm }) => (
        <TableRow>
          <TableCell colSpan={1}>
            <Field
              onKeyUp={e => (e.key === 'Enter' ? submitForm() : null)}
              name="name"
              component={MaterialInput}
              placeholder="Наименование"
            />
          </TableCell>
          <TableCell colSpan={1}>
            <Field
              onKeyUp={e => (e.key === 'Enter' ? submitForm() : null)}
              name="email"
              component={MaterialInput}
              placeholder="E-mail"
            />
          </TableCell>
          <TableCell colSpan={2}>
            <Button
              variant="contained"
              color="primary"
              className="btn-add no-margin"
              type="submit"
              onClick={submitForm}
            >
              Применить
            </Button>
          </TableCell>
        </TableRow>
      )}
    />
  );
};

export default UsersFilter;
