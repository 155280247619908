import React from 'react';
import {connect} from 'react-redux'
import {apvgkReportsActions} from "../../../actions/apvgkReportsActions";
import EditForm from './EditForm'
import {Button, Grid} from '@material-ui/core';
import {Loader} from "../../../components/index";
import {SubmissionError} from 'redux-form';
import RejectModal from './RejectModal'
import {apvgkReportsConstants} from "../../../constants/apvgkReportsConstants";
import {history} from "../../../helpers/history";
import {urlHelper} from "../../../helpers/urlHelper";
import {roleConstants} from "../../../constants/roleConstants";
import {apvgkReportsService} from "../../../services/apvgkReportsService";

export const ReportStatusTitles = (status) => {
    switch (status) {
        case apvgkReportsConstants.STATUS_ACCEPT: return 'Одобрено';
        case apvgkReportsConstants.STATUS_DECLINE: return 'Отклонено';
        case apvgkReportsConstants.STATUS_IN_WORK: return 'В работе';
    }
}
class ReportShowPage extends React.Component {
    state = {
        editMode: false,
        showReject: false,
        application_id: null,
    }

    componentDidMount() {
        const {match: {params: {id}}, currentItem, dispatch, reasons} = this.props
        this.setState({
            editMode: this.props.match.path.toString().indexOf('/edit/') > -1
        })
        if (!reasons || !reasons.length) {
            dispatch(apvgkReportsActions.getReasons())
        }
        dispatch(apvgkReportsActions.getItem(id))
        dispatch(apvgkReportsActions.clearItems())
    }

    render() {
        const {currentItem, loading, checkGrz} = this.props
        return (

            <Grid
                container
                role="main"
                className="container text-left"
                direction="column"
                spacing={16}
                wrap="nowrap"
            >
                <Grid item>
                    <Grid container spacing={16}>
                        <Grid item>
                            Акт взвешивания {currentItem && `№ ${currentItem.id}  `}
                        </Grid>
                    </Grid>
                </Grid>
                {currentItem && (<Grid item>
                    <div className="blue_frame">
                        <p>
                            <strong>Статус:</strong> {ReportStatusTitles(currentItem.status)}
                        </p>
                        {currentItem.status === apvgkReportsConstants.STATUS_DECLINE && [
                            <div key="reason">
                                <strong>Причина отклонения: </strong>
                                {currentItem.reason}
                            </div>,
                        ]}
                    </div>
                </Grid>)}

                <Grid item className="">
                    {(!currentItem || loading) ? (
                        <Loader size={45}/>
                    ) : (
                        <EditForm report={currentItem}
                                  onSubmit={this.props.handleSubmit}
                                  edit={this.state.editMode}
                                  onCheckGrz={checkGrz}
                                  onReject={(app_id) => this.setState({...this.state, showReject: true, ...app_id})}/>
                    )}
                </Grid>
                {this.state.showReject && (
                    <RejectModal
                        model_id={currentItem.id}
                        application_id={this.state.application_id}
                        reasons={this.props.reasons}
                        onClose={() => {
                            this.setState({...this.state, showReject:false})
                            this.props.dispatch(apvgkReportsActions.getItem(currentItem.id))
                        }}/>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.apvgkReports
    }
}
const mapDispatchToProps = (dispatch, props) => {
    const handleSubmit = (values) => {
        if (!values.id) return null;
        return dispatch(apvgkReportsActions.accept({
            id:values.id,
            data: values
        })).then((r) => {
                if (r.type === apvgkReportsConstants.ACCEPT_SUCCESS) {
                    dispatch(apvgkReportsActions.clearItems())
                    history.push(
                        `${urlHelper.getHomeUrl(roleConstants.ROLE_APVGK)}/apvgk/`
                    );
                    return;
                }
                alert('При одобрении произошла ошибка, попробуйте повторить позднее')
            })
    }
    const checkGrz = ((report, values) => {
        if (!report.id) return;
        return new Promise((res,rej) => apvgkReportsService.checkGrz(report.id, values).then((r) => {
            res(r);
            history.push(`${urlHelper.getHomeUrl(roleConstants.ROLE_APVGK)}/apvgk/`);
        }, (e) => {
            rej(e);
            alert('Произошла ошибка, попробуйте повторить позднее');
        }
    ))});
    return {
        handleSubmit,
        checkGrz,
        dispatch
    };
}
const ConReportShowPage = connect(mapStateToProps, mapDispatchToProps)(ReportShowPage)
export {ConReportShowPage as ReportShowPage}
