import React, { Component } from 'react';
import { connect } from 'react-redux';
import { coefficientActions } from '../../../../actions/coefficientActions';
import { CoefficientTableList } from './CoefficientTableList';
import { Loader } from '../../../../components/Loader';
import { CoefficientModal } from './CoefficientModal';

class CoefficientBlock extends Component {
  componentDidMount() {
    const { coefficients } = this.props;

    if (!coefficients.items || coefficients.items.length === 0) {
      this.props.dispatch(coefficientActions.getPage(1));
    }
  }

  render() {
    const { coefficients } = this.props;

    return (
      <div>
        <main role="main" className="container text-left">
          <p>Справочник коэффициентов</p>
          <div className="">
            {coefficients.loading && <Loader />}
            {coefficients.error && (
              <span className="text-danger">Ошибка: {coefficients.error}</span>
            )}
            {coefficients.items && (
              <CoefficientTableList data={coefficients.items} meta={coefficients.meta} />
            )}
          </div>
          <CoefficientModal />
        </main>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { coefficients } = state;
  return {
    coefficients
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const connectedCoefficientBlock = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoefficientBlock);
export { connectedCoefficientBlock as CoefficientBlock };
