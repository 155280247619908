export const applicationConstants = {
  MAPBOX_KEY: process.env.REACT_APP_MAPBOX_KEY || '',
  MAPBOX_TILES: 'gbubdd/cjs33cyow00fw1hnxvn2uqhnp',

  MAP_URL: 'https://activemap.aisktg.ru',

  DEFAULT_PER_PAGE: 5,

  STATUS_NEW: 0,
  STATUS_REVIEW: 1,
  STATUS_DECLINE: 2,
  STATUS_ACCEPT_WITH_CHANGES: 3,
  STATUS_ACCEPT: 4,
  STATUS_ACTIVE: 5,
  STATUS_REPEAT: 6,
  STATUS_COMPLETE: 7,
  STATUS_REMOVED: 8,

  AGREEMENT_NEW: 0,
  AGREEMENT_REVIEW: 1,
  AGREEMENT_DECLINED: 2,
  AGREEMENT_ACCEPTED_WITH_CHANGES: 3,
  AGREEMENT_ACCEPTED: 4,

  FILTER_MODAL_OPEN: 'APPLICATION_FILTER_FORM_MODAL_OPEN',
  FILTER_MODAL_CLOSE: 'APPLICATION_FILTER_FORM_MODAL_CLOSE',

  PRINT_MODAL_OPEN: 'APPLICATION_PRINT_FORM_MODAL_OPEN',
  PRINT_MODAL_CLOSE: 'APPLICATION_PRINT_FORM_MODAL_CLOSE',

  MY_VEHICLES_MODAL_OPEN: 'APPLICATION_MY_VEHICLES_FORM_MODAL_OPEN',
  MY_VEHICLES_MODAL_CLOSE: 'APPLICATION_MY_VEHICLES_FORM_MODAL_CLOSE',

  GET_USER_FILTERED_REQUEST: 'APPLICATION_GET_USER_FILTERED_REQUEST',
  GET_USER_FILTERED_SUCCESS: 'APPLICATION_GET_USER_FILTERED_SUCCESS',
  GET_USER_FILTERED_FAILURE: 'APPLICATION_GET_USER_FILTERED_FAILURE',

  GET_VEHICLE_SEARCH_REQUEST: 'APPLICATION_GET_VEHICLE_SEARCH_REQUEST',
  GET_VEHICLE_SEARCH_SUCCESS: 'APPLICATION_GET_VEHICLE_SEARCH_SUCCESS',
  GET_VEHICLE_SEARCH_FAILURE: 'APPLICATION_GET_VEHICLE_SEARCH_FAILURE',

  // pick vehicle for app
  SET_APPLICATION_IS_TRAILER: 'APPLICATION_SET_APPLICATION_IS_TRAILER',
  SET_APPLICATION_TRAILER_INDEX: 'APPLICATION_SET_APPLICATION_TRAILER_INDEX',

  SET_APPLICATION_VEHICLE: 'APPLICATION_SET_APPLICATION_VEHICLE',
  ADD_APPLICATION_TRAILER: 'APPLICATION_ADD_APPLICATION_TRAILER',
  SET_APPLICATION_VEHICLE_TYPE: 'APPLICATION_SET_APPLICATION_VEHICLE_TYPE',

  SAVE_VEHICLE_STEP_REQUEST: 'APPLICATION_SAVE_VEHICLE_STEP_REQUEST',
  SAVE_VEHICLE_STEP_SUCCESS: 'APPLICATION_SAVE_VEHICLE_STEP_SUCCESS',
  SAVE_VEHICLE_STEP_FAILURE: 'APPLICATION_SAVE_VEHICLE_STEP_FAILURE',

  SAVE_LOAD_STEP_REQUEST: 'APPLICATION_SAVE_LOAD_STEP_REQUEST',
  SAVE_LOAD_STEP_SUCCESS: 'APPLICATION_SAVE_LOAD_STEP_SUCCESS',
  SAVE_LOAD_STEP_FAILURE: 'APPLICATION_SAVE_LOAD_STEP_FAILURE',

  SAVE_ROUTE_STEP_REQUEST: 'APPLICATION_SAVE_ROUTE_STEP_REQUEST',
  SAVE_ROUTE_STEP_SUCCESS: 'APPLICATION_SAVE_ROUTE_STEP_SUCCESS',
  SAVE_ROUTE_STEP_FAILURE: 'APPLICATION_SAVE_ROUTE_STEP_FAILURE',

  SAVE_DATES_STEP_REQUEST: 'APPLICATION_SAVE_DATES_STEP_REQUEST',
  SAVE_DATES_STEP_SUCCESS: 'APPLICATION_SAVE_DATES_STEP_SUCCESS',
  SAVE_DATES_STEP_FAILURE: 'APPLICATION_SAVE_DATES_STEP_FAILURE',

  LOAD_REQUEST: 'APPLICATION_LOAD_REQUEST',
  LOAD_SUCCESS: 'APPLICATION_LOAD_SUCCESS',
  LOAD_FAILURE: 'APPLICATION_LOAD_FAILURE',

  LOAD_LOAD_REQUEST: 'APPLICATION_LOAD_LOAD_REQUEST',
  LOAD_LOAD_SUCCESS: 'APPLICATION_LOAD_LOAD_SUCCESS',
  LOAD_LOAD_FAILURE: 'APPLICATION_LOAD_LOAD_FAILURE',

  LOAD_ROUTE_REQUEST: 'APPLICATION_LOAD_ROUTE_REQUEST',
  LOAD_ROUTE_SUCCESS: 'APPLICATION_LOAD_ROUTE_SUCCESS',
  LOAD_ROUTE_FAILURE: 'APPLICATION_LOAD_ROUTE_FAILURE',

  LOAD_DATES_REQUEST: 'APPLICATION_LOAD_DATES_REQUEST',
  LOAD_DATES_SUCCESS: 'APPLICATION_LOAD_DATES_SUCCESS',
  LOAD_DATES_FAILURE: 'APPLICATION_LOAD_DATES_FAILURE',

  RESET_CURRENT_ITEM: 'APPLICATION_RESET_CURRENT_ITEM',

  WITHDRAW_REQUEST: 'APPLICATION_WITHDRAW_REQUEST',
  WITHDRAW_SUCCESS: 'APPLICATION_WITHDRAW_SUCCESS',
  WITHDRAW_FAILURE: 'APPLICATION_WITHDRAW_FAILURE',

  SET_TAB_INDEX: 'APPLICATION_SET_TAB_INDEX',

  TO_WORK_REQUEST: 'APPLICATION_TO_WORK_REQUEST',
  TO_WORK_SUCCESS: 'APPLICATION_TO_WORK_SUCCESS',
  TO_WORK_FAILURE: 'APPLICATION_TO_WORK_FAILURE',

  ACCEPT_REQUEST: 'APPLICATION_ACCEPT_REQUEST',
  ACCEPT_SUCCESS: 'APPLICATION_ACCEPT_SUCCESS',
  ACCEPT_FAILURE: 'APPLICATION_ACCEPT_FAILURE',

  ACTIVATE_REQUEST: 'APPLICATION_ACTIVATE_REQUEST',
  ACTIVATE_SUCCESS: 'APPLICATION_ACTIVATE_SUCCESS',
  ACTIVATE_FAILURE: 'APPLICATION_ACTIVATE_FAILURE',

  DECLINE_REQUEST: 'APPLICATION_DECLINE_REQUEST',
  DECLINE_SUCCESS: 'APPLICATION_DECLINE_SUCCESS',
  DECLINE_FAILURE: 'APPLICATION_DECLINE_FAILURE',

  RESTORE_REQUEST: 'APPLICATION_RESTORE_REQUEST',
  RESTORE_SUCCESS: 'APPLICATION_RESTORE_SUCCESS',
  RESTORE_FAILURE: 'APPLICATION_RESTORE_FAILURE',

  LOCK_REQUEST: 'APPLICATION_LOCK_REQUEST',
  LOCK_SUCCESS: 'APPLICATION_LOCK_SUCCESS',
  LOCK_FAILURE: 'APPLICATION_LOCK_FAILURE',

  UNLOCK_REQUEST: 'APPLICATION_UNLOCK_REQUEST',
  UNLOCK_SUCCESS: 'APPLICATION_UNLOCK_SUCCESS',
  UNLOCK_FAILURE: 'APPLICATION_UNLOCK_FAILURE',

  GET_ADMIN_APPLICATIONS_REQUEST: 'APPLICATION_GET_ADMIN_APPLICATIONS_REQUEST',
  GET_ADMIN_APPLICATIONS_SUCCESS: 'APPLICATION_GET_ADMIN_APPLICATIONS_SUCCESS',
  GET_ADMIN_APPLICATIONS_FAILURE: 'APPLICATION_GET_ADMIN_APPLICATIONS_FAILURE',

  DECLINE_OPEN: 'APPLICATION_DECLINE_OPEN',
  DECLINE_CLOSE: 'APPLICATION_DECLINE_CLOSE',
  DECLINE_CURRENT_ITEM_OPEN: 'APPLICATION_DECLINE_CURRENT_ITEM_OPEN',

  ACTIVATE_OPEN: 'APPLICATION_ACTIVATE_OPEN',
  ACTIVATE_CLOSE: 'APPLICATION_ACTIVATE_CLOSE',
  ACTIVATE_CURRENT_ITEM_OPEN: 'APPLICATION_ACTIVATE_CURRENT_ITEM_OPEN',

  CHECK_STATUS_REQUEST: 'APPLICATION_CHECK_STATUS_REQUEST',
  CHECK_STATUS_SUCCESS: 'APPLICATION_CHECK_STATUS_SUCCESS',

  SET_LOAD_AXLES: 'APPLICATION_SET_LOAD_AXLES',
  SET_ESCORT: 'APPLICATION_SET_ESCORT',
  SET_EDITABLE_DISTANCES: 'APPLICATION_SET_EDITABLE_DISTANCES',

  LOAD_UPLOAD_REQUEST: 'APPLICATION_LOAD_UPLOAD_REQUEST',
  LOAD_UPLOAD_SUCCESS: 'APPLICATION_LOAD_UPLOAD_SUCCESS',
  LOAD_UPLOAD_FAILURE: 'APPLICATION_LOAD_UPLOAD_FAILURE',
  LOAD_PENALTY_REQUEST: 'APPLICATION_LOAD_PENALTY_REQUEST',
  LOAD_PENALTY_SUCCESS: 'APPLICATION_LOAD_PENALTY_SUCCESS',
  LOAD_PENALTY_FAILURE: 'APPLICATION_LOAD_PENALTY_FAILURE',
  LOAD_REMOVE: 'APPLICATION_LOAD_REMOVE',

  PAY_UPLOAD_REQUEST: 'APPLICATION_PAY_UPLOAD_REQUEST',
  PAY_UPLOAD_SUCCESS: 'APPLICATION_PAY_UPLOAD_SUCCESS',
  PAY_UPLOAD_FAILURE: 'APPLICATION_PAY_UPLOAD_FAILURE',
  PAY_FILE_REMOVE: 'APPLICATION_PAY_FILE_REMOVE',
  PAY_FILE_REMOVE_REQUEST: 'APPLICATION_PAY_FILE_REMOVE_REQUEST',
  PAY_FILE_REMOVE_SUCCESS: 'APPLICATION_PAY_FILE_REMOVE_SUCCESS',

  SEARCH_LOCATION_REQUEST: 'APPLICATION_SEARCH_LOCATION_REQUEST',
  SEARCH_LOCATION_SUCCESS: 'APPLICATION_SEARCH_LOCATION_SUCCESS',
  SEARCH_LOCATION_FAILURE: 'APPLICATION_SEARCH_LOCATION_FAILURE',

  SET_LOCATION: 'APPLICATION_SET_LOCATION',
  SET_COORDS: 'APPLICATION_SET_COORDS',
  SET_SUGGESTIONS: 'APPLICATION_SET_SUGGESTIONS',

  SET_MAP_COORDS: 'APPLICATION_SET_MAP_COORDS',
  SET_MAP_ZOOM: 'APPLICATION_SET_MAP_ZOOM',

  ADD_MARKER: 'APPLICATION_ADD_MARKER',
  REMOVE_MARKER: 'APPLICATION_REMOVE_MARKER',

  SET_ROUTE_MARKERS: 'APPLICATION_SET_ROUTE_MARKERS',
  SET_MARKER_VALUE: 'APPLICATION_SET_MARKER_VALUE',
  CHANGE_APVGKS_VALUE: 'APPLICATION_CHANGE_APVGKS_VALUE',

  LOAD_FULL_REQUEST: 'APPLICATION_LOAD_FULL_REQUEST',
  LOAD_FULL_SUCCESS: 'APPLICATION_LOAD_FULL_SUCCESS',
  LOAD_FULL_FAILURE: 'APPLICATION_LOAD_FULL_FAILURE',

  DELETE_REQUEST: 'APPLICATION_DELETE_REQUEST',
  DELETE_SUCCESS: 'APPLICATION_DELETE_SUCCESS',
  DELETE_FAILURE: 'APPLICATION_DELETE_FAILURE',

  DELETE_TEMPLATE_REQUEST: 'APPLICATION_DELETE_TEMPLATE_REQUEST',
  DELETE_TEMPLATE_SUCCESS: 'APPLICATION_DELETE_TEMPLATE_SUCCESS',
  DELETE_TEMPLATE_FAILURE: 'APPLICATION_DELETE_TEMPLATE_FAILURE',

  GET_USER_TEMPLATES_REQUEST: 'APPLICATION_GET_USER_TEMPLATES_REQUEST',
  GET_USER_TEMPLATES_SUCCESS: 'APPLICATION_GET_USER_TEMPLATES_SUCCESS',
  GET_USER_TEMPLATES_FAILURE: 'APPLICATION_GET_USER_TEMPLATES_FAILURE',

  USE_TEMPLATE_REQUEST: 'APPLICATION_USE_TEMPLATE_REQUEST',
  USE_TEMPLATE_SUCCESS: 'APPLICATION_USE_TEMPLATE_SUCCESS',
  USE_TEMPLATE_FAILURE: 'APPLICATION_USE_TEMPLATE_FAILURE',

  TO_ADMIN_REQUEST: 'APPLICATION_TO_ADMIN_REQUEST',
  TO_ADMIN_SUCCESS: 'APPLICATION_TO_ADMIN_SUCCESS',
  TO_ADMIN_FAILURE: 'APPLICATION_TO_ADMIN_FAILURE',

  TO_DRAFT_REQUEST: 'APPLICATION_TO_DRAFT_REQUEST',
  TO_DRAFT_SUCCESS: 'APPLICATION_TO_DRAFT_SUCCESS',
  TO_DRAFT_FAILURE: 'APPLICATION_TO_DRAFT_FAILURE',

  GET_CONTROL_FILTERED_REQUEST: 'APPLICATION_GET_CONTROL_FILTERED_REQUEST',
  GET_CONTROL_FILTERED_SUCCESS: 'APPLICATION_GET_CONTROL_FILTERED_SUCCESS',
  GET_CONTROL_FILTERED_FAILURE: 'APPLICATION_GET_CONTROL_FILTERED_FAILURE',

  GET_CONTROL_BYUUID_REQUEST: 'GET_CONTROL_BYUUID_REQUEST',
  GET_CONTROL_BYUUID_SUCCESS: 'GET_CONTROL_BYUUID_SUCCESS',
  GET_CONTROL_BYUUID_FAILURE: 'GET_CONTROL_BYUUID_FAILURE',

  SET_MAP: 'APPLICATION_SET_MAP',
  SET_PLAN: 'APPLICATION_SET_PLAN',
  SET_ROUTING: 'APPLICATION_SET_ROUTING',

  ADD_CONTROL_MARK_REQUEST: 'APPLICATION_ADD_CONTROL_MARK_REQUEST',
  ADD_CONTROL_MARK_SUCCESS: 'APPLICATION_ADD_CONTROL_MARK_SUCCESS',
  ADD_CONTROL_MARK_FAILURE: 'APPLICATION_ADD_CONTROL_MARK_FAILURE',

  SEND_TO_DEPARTMENT_REQUEST: 'APPLICATION_SEND_TO_DEPARTMENT_REQUEST',
  SEND_TO_DEPARTMENT_SUCCESS: 'APPLICATION_SEND_TO_DEPARTMENT_SUCCESS',
  SEND_TO_DEPARTMENT_FAILURE: 'APPLICATION_SEND_TO_DEPARTMENT_FAILURE',

  REMOVE_AGREEMENT_REQUEST: 'APPLICATION_REMOVE_AGREEMENT_REQUEST',
  REMOVE_AGREEMENT_SUCCESS: 'APPLICATION_REMOVE_AGREEMENT_SUCCESS',
  REMOVE_AGREEMENT_FAILURE: 'APPLICATION_REMOVE_AGREEMENT_FAILURE',

  AGREEMENT_DECLINE_OPEN: 'APPLICATION_AGREEMENT_DECLINE_OPEN',
  AGREEMENT_DECLINE_CLOSE: 'APPLICATION_AGREEMENT_DECLINE_CLOSE',
  AGREEMENT_DECLINE_CURRENT_ITEM_OPEN: 'APPLICATION_AGREEMENT_DECLINE_CURRENT_ITEM_OPEN',

  AGREEMENT_ACCEPT_REQUEST: 'APPLICATION_AGREEMENT_ACCEPT_REQUEST',
  AGREEMENT_ACCEPT_SUCCESS: 'APPLICATION_AGREEMENT_ACCEPT_SUCCESS',
  AGREEMENT_ACCEPT_FAILURE: 'APPLICATION_AGREEMENT_ACCEPT_FAILURE',

  AGREEMENT_DECLINE_REQUEST: 'APPLICATION_AGREEMENT_DECLINE_REQUEST',
  AGREEMENT_DECLINE_SUCCESS: 'APPLICATION_AGREEMENT_DECLINE_SUCCESS',
  AGREEMENT_DECLINE_FAILURE: 'APPLICATION_AGREEMENT_DECLINE_FAILURE',

  CHANGE_ROUTE_ADMIN_REQUEST: 'APPLICATION_CHANGE_ROUTE_ADMIN_REQUEST',
  CHANGE_ROUTE_ADMIN_SUCCESS: 'APPLICATION_CHANGE_ROUTE_ADMIN_SUCCESS',
  CHANGE_ROUTE_ADMIN_FAILURE: 'APPLICATION_CHANGE_ROUTE_ADMIN_FAILURE',

  GET_COORDS_LOCATION_REQUEST: 'APPLICATION_GET_COORDS_LOCATION_REQUEST',
  GET_COORDS_LOCATION_SUCCESS: 'APPLICATION_GET_COORDS_LOCATION_SUCCESS',
  GET_COORDS_LOCATION_FAILURE: 'APPLICATION_GET_COORDS_LOCATION_FAILURE',

  TO_WORK_MULTIPLE_REQUEST: 'APPLICATION_TO_WORK_MULTIPLE_REQUEST',
  TO_WORK_MULTIPLE_SUCCESS: 'APPLICATION_TO_WORK_MULTIPLE_SUCCESS',
  TO_WORK_MULTIPLE_FAILURE: 'APPLICATION_TO_WORK_MULTIPLE_FAILURE',

  PRINT_PDF_REQUEST: 'APPLICATION_PRINT_PDF_REQUEST',
  PRINT_PDF_SUCCESS: 'APPLICATION_PRINT_PDF_SUCCESS',
  PRINT_PDF_FAILURE: 'APPLICATION_PRINT_PDF_FAILURE',

  TOGGLE_EDIT_MAP: 'APPLICATION_TOGGLE_EDIT_MAP'
};
