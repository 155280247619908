export const fileConstants = {
  TYPE_INN: 0,
  TYPE_LICENSE: 1,
  TYPE_PTS: 2,
  TYPE_LEASE: 3,
  TYPE_STATUS: 4,

  TYPE_LOAD: 5,
  TYPE_APPLICATION_PAY: 6,
  TYPE_PENALTY: 7
};

export const ProcessingStatus = {
  NEW: 0,
  PROCESSING: 1,
  SUCCEED: 2,
  FAILED: 3
};

export const ProcessingType = {
  REPORTS: 0,
  AUDIT: 1,
  COOPERATE: 2,
  GRUZ_TRACK_DATA: 3,
};
