import React from 'react';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components/Loader';
import { authActions } from '../../../actions/authActions';
import { fieldMasks } from '../../../helpers/fieldMasks';
import { FormField } from '../../../components/form/FormField';
import { cabinetActions } from '../../../actions/cabinetActions';
import { maskHelper } from '../../../helpers/maskHelper';

let IndividualForm = props => {
  const { error, handleSubmit, submitting, cabinet, openConfirmation } = props;

  return (
    <div>
      {cabinet.loading && <Loader />}
      {!cabinet.loading && (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            type="text"
            label="Полное имя (ФИО)"
            validate={[fieldValidators.required, fieldValidators.maxLength60]}
            component={FormField}
          />
          <Field
            name="email"
            type="email"
            label="Email"
            validate={[
              fieldValidators.required,
              fieldValidators.email,
              fieldValidators.maxLength40
            ]}
            component={FormField}
          />
          <Field
            name="phone"
            type="phone"
            label="Контактный телефон"
            validate={[fieldValidators.required, fieldValidators.phoneRus]}
            component={FormField}
            {...fieldMasks.phoneRus}
          />
          <Field
            name="inn"
            type="text"
            label="ИНН"
            validate={[fieldValidators.required, fieldValidators.exactLength12]}
            component={FormField}
            {...fieldMasks.innIndividual}
          />
          {error && (
            <div className="has-error">
              <span>{error}</span>
              <br />
              <br />
            </div>
          )}

          <div className="row">
            <div className="text-left">
              <Button
                variant="contained"
                color="primary"
                className="btn-add"
                type="button"
                onClick={e => {
                  return openConfirmation();
                }}
                disabled={submitting}
              >
                {submitting && <Loader />} Сохранить
              </Button>
            </div>
          </div>
        </form>
      )}
      {cabinet.status}
    </div>
  );
};

IndividualForm = reduxForm({
  form: 'cabinet-individual',
  enableReinitialize: true
})(IndividualForm);

const mapStateToProps = state => {
  const { user } = state.authentication;
  const { cabinet } = state;

  const selector = formValueSelector('cabinet-individual');

  const data = cabinet.info ? cabinet.info : null;
  return {
    user,
    cabinet,
    initialValues: data
      ? {
          name: data.name,
          phone: maskHelper.unmaskPhone(data.phone),
          email: data.email,
          inn: data.inn
        }
      : null
  };
};
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { cabinet, legalForms, initialValues } = stateProps;
  const { dispatch } = dispatchProps;

  const togglePasswordVisible = () => {
    dispatch(authActions.togglePasswordVisible());
  };

  const openConfirmation = () => {
    dispatch(cabinetActions.dataConfirmationOpen());
  };

  return {
    ...ownProps,
    cabinet,
    legalForms,
    initialValues,
    togglePasswordVisible,
    openConfirmation
  };
};

const connectedIndividualForm = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(IndividualForm);
export { connectedIndividualForm as IndividualForm };
