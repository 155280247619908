import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { change } from 'redux-form';
import { vehicleConstants } from '../../../constants/vehicleConstants';
import { VehiclesTab } from './VehiclesTab';
import { vehicleActions } from '../../../actions/vehicleActions';

class VehiclesListPage extends Component {
  componentDidMount() {
    const { adminVehicles } = this.props;

    if (!adminVehicles.items || adminVehicles.items.length === 0) {
      this.props.dispatch(vehicleActions.getAdminPage(1, adminVehicles.tabIndex));
    }
  }

  render() {
    const { adminVehicles, handleTabChange } = this.props;
    const { tabIndex } = adminVehicles;

    return (
      <main role="main" className="container text-center">
        <div className="kyc">
          <h2 className="h2-title">Заявки на регистрацию тс</h2>

          <AppBar position="static" color="default" className="tabs_header">
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              className="tab_style"
              variant="fullWidth"
            >
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Новые"
                value={vehicleConstants.STATUS_NEW}
              />
              <Tab
                classes={{ selected: 'tab_active' }}
                label="В работе"
                value={vehicleConstants.STATUS_REVIEW}
              />
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Отклоненные"
                value={vehicleConstants.STATUS_DECLINE}
              />
              <Tab
                classes={{ selected: 'tab_active' }}
                label="Активные"
                value={vehicleConstants.STATUS_ACCEPT}
              />
            </Tabs>
          </AppBar>

          <VehiclesTab />
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { adminVehicles } = state;
  return {
    adminVehicles
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { adminVehicles } = stateProps;
  const { dispatch } = dispatchProps;

  const handleTabChange = (event, tabIndex) => {
    if (tabIndex !== adminVehicles.tabIndex) {
      dispatch(vehicleActions.setTab(tabIndex));
      dispatch(vehicleActions.getAdminPage(1, tabIndex, adminVehicles.roleIndex));
      //
      dispatch(change('vehicle-admin-filter-form', 'status', tabIndex));
      dispatch(change('vehicle-admin-filter-form', 'page', 1));
    }
  };

  return {
    ...ownProps,
    dispatch,
    adminVehicles,
    handleTabChange
  };
};

const connectedVehiclesPage = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(VehiclesListPage);
export { connectedVehiclesPage as VehiclesListPage };
