import React from 'react';
import { Field } from 'redux-form';
import Button from '@material-ui/core/Button';
import { TableRow } from 'material-ui';
import TableCell from '@material-ui/core/TableCell';
import { FormFieldReg } from '../../../../../components/form/FormFieldReg';

const StatusVehiclesFilterForm = ({ submitting }) => (
  <TableRow>
    <TableCell />
    <TableCell>
      <Field
        name="number"
        type="text"
        label="Номер"
        className="form_input"
        component={FormFieldReg}
      />
    </TableCell>
    <TableCell>
      <Field
        name="brand"
        type="text"
        label="Марка"
        className="form_input"
        component={FormFieldReg}
      />
    </TableCell>
    <TableCell>
      <Field
        name="model"
        type="text"
        label="Модель"
        className="form_input"
        component={FormFieldReg}
      />
    </TableCell>
    <TableCell numeric>
      <Button
        variant="contained"
        color="primary"
        className="btn-add"
        type="submit"
        disabled={submitting}
      >
        Применить
      </Button>
    </TableCell>
  </TableRow>
);

export default StatusVehiclesFilterForm;
