import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { dictionaryHelper } from '../../../helpers/dictionaryHelper';
import { vehicleActions } from '../../../actions/vehicleActions';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { renderSelectFieldReg } from '../../../components/form/renderSelectFieldReg';
import { MuiCheckbox } from '../../../components/form/MuiCheckbox';
import { FormFieldReg } from '../../../components/form/FormFieldReg';
import { fieldNormalizers } from '../../../helpers/fieldNormalizers';

const VehiclesAxlesTable = props => {
  const { axles, setActiveField, is_trailer } = props;

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Ось</TableCell>
            <TableCell>Скатность</TableCell>
            <TableCell>Тип</TableCell>
            <TableCell numeric>Подъемная</TableCell>
            <TableCell>Число колес</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {axles.map((axle, index) => {
            const wheelCountValidators = [fieldValidators.required];
            const wheelsValidators = [
              fieldValidators.required,
              fieldValidators.maxValue20,
              fieldValidators.minValue0
            ];

            if (index === 0 && !is_trailer) {
              wheelCountValidators.push(fieldValidators.exactValue1);
              wheelsValidators.push(fieldValidators.exactValue2);
            }
            return (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  Ось {index + 1}
                </TableCell>
                <TableCell className="skat">
                  <Field
                    className="form-control"
                    name={`axle_length[${index}]`}
                    component={renderSelectFieldReg}
                    validate={wheelCountValidators}
                    onBlur={e => setActiveField('')}
                    onFocus={e => setActiveField(`axle_${index}`)}
                  >
                    <option key="wheels-0" value="">
                      Скатность
                    </option>
                    <option key="wheels-1" value={1}>
                      1
                    </option>
                    <option key="wheels-2" value={2}>
                      2
                    </option>
                  </Field>
                </TableCell>
                <TableCell className="skat">
                  <Field
                    className="form-control"
                    name={`axle_type[${index}]`}
                    component={renderSelectFieldReg}
                    validate={[fieldValidators.required]}
                    onBlur={e => setActiveField('')}
                    onFocus={e => setActiveField(`axle_${index}`)}
                  >
                    <option key="0" value="">
                      Выберите тип оси
                    </option>
                    {dictionaryHelper.axleTypes.getList().map(option => (
                      <option key={option.id} value={option.id}>
                        {option.title}
                      </option>
                    ))}
                  </Field>
                </TableCell>
                <TableCell className="skat">
                  <div className="text-right">
                    <Field
                      name={`axle_main[${index}]`}
                      type="checkbox"
                      className="checkbox-table"
                      component={MuiCheckbox}
                      // validate={fieldValidators.required}
                      onBlur={e => setActiveField('')}
                      onFocus={e => setActiveField(`axle_${index}`)}
                    />
                  </div>
                </TableCell>
                <TableCell className="skat">
                  <Field
                    name={`axle_wheels[${index}]`}
                    placeholder=""
                    className="mini-input"
                    component={FormFieldReg}
                    validate={wheelsValidators}
                    normalize={fieldNormalizers.integer}
                    onBlur={e => setActiveField('')}
                    onFocus={e => setActiveField(`axle_${index}`)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  const setActiveField = fieldName => {
    return dispatch(vehicleActions.setActiveField(fieldName));
  };
  return {
    setActiveField
  };
};

const connectedVehiclesAxlesTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclesAxlesTable);
export { connectedVehiclesAxlesTable as VehiclesAxlesTable };
