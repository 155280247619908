import React from 'react';
import { Button, Grid, IconButton } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import { applicationService } from '../../../../../services/applicationService';
import { Loader } from '../../../../../components';

const RequestBill = ({
  applicationId,
  isRouteValid,
  enqueueSnackbar,
  invoice,
  isSpring,
  disabled,
  onUpdate
}) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [isDeleting, setDeleting] = React.useState(false);

  const closeConfirmModal = () => setShowConfirm(false);
  const createInvoice = async () => {
    try {
      await applicationService.createInvoice(applicationId, isSpring);
      const data = await applicationService
        .getApplication({ id: applicationId })
        .then(res => res.data.permit);
      onUpdate(data);
      enqueueSnackbar('Счет сформирован!', {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar('Произошла ошибка при формировании счета!', {
        variant: 'error'
      });
    }
    closeConfirmModal();
  };

  const deleteInvoice = async () => {
    try {
      setDeleting(true);
      await applicationService.deleteInvoice(applicationId);
      const data = await applicationService
        .getApplication({ id: applicationId })
        .then(res => res.data.permit);
      onUpdate(data);
      enqueueSnackbar('Счет удален!', {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar('Произошла ошибка при удаление счета!', {
        variant: 'error'
      });
    }
    setDeleting(false);
  };

  const createBill = () => {
    if (!isRouteValid) {
      enqueueSnackbar('Сохраните маршрут до формирования счета!', {
        variant: 'error'
      });
      return;
    }
    setShowConfirm(true);
  };

  if (invoice) {
    return (
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <a target="_blank" href={invoice.invoice_link}>
              Счет на оплату
            </a>
          </Grid>
          <Grid item>
            <IconButton onClick={deleteInvoice} disabled={isDeleting}>
              {isDeleting ? <Loader /> : <CloseIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item>
      <Button
        variant="contained"
        disabled={disabled}
        className="btn-add no-margin"
        onClick={createBill}
      >
        Сформировать счет
      </Button>
      <ConfirmationModal
        handleSubmit={createInvoice}
        handleClose={closeConfirmModal}
        isShow={showConfirm}
        title="Формирование счета"
        description="Изменение маршрута будет невозможно после формирования счета. Вы уверены, что хотите продолжить?"
      />
    </Grid>
  );
};

export default withSnackbar(RequestBill);
