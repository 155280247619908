import React from 'react';
import Select from 'react-select';
import { get, isNumber } from 'lodash';

export const renderSelectFieldReg = ({
  disabled,
  input,
  meta: { touched, error },
  children,
  className
}) => {
  const options = React.Children.map(children, element => {
    if (!React.isValidElement(element)) return [];

    const { value, children: label } = element.props;
    const valueWithType = isNumber(input.value) ? value : `${value}`;
    return { value: valueWithType, label };
  });
  const valueWithLabel = options ? options.find(o => o.value === input.value) : '';
  const placeholder = options ? options.find(o => o.value === '') : '';
  return (
    <div className={`form-group${touched && error ? ' has-error' : ''}`}>
      <div className="">
        <Select
          {...input}
          isSearchable={false}
          onBlur={() => input.onBlur()}
          isDisabled={disabled || disabled === 'disabled'}
          placeholder={placeholder ? placeholder.label : get(options, '[0].label')}
          value={valueWithLabel}
          options={options}
          onChange={({ value }) => input.onChange(value)}
        />
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};
