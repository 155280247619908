import React from 'react';
import { connect } from 'react-redux';
import {
  getApplicationEditingMap,
  getIsRouteChanged,
  getRouteChangeComment
} from '../../../selectors/applicationSelectors';
import * as actions from '../../../actions/newApplicationActions';

const ApplicationRouteComment = ({ isRouteChanged, comment, onCommentChange, isEditingMap }) => (
  <div className="row">
    <div className="col-md-10">
      {isRouteChanged && (
        <textarea
          className="form-control margin-bottom-10"
          value={comment}
          onChange={e => onCommentChange(e.target.value)}
          disabled={!isEditingMap}
          placeholder="Комментарий по маршруту"
          rows="4"
        />
      )}
    </div>
  </div>
);

const mapStateToProps = state => ({
  isRouteChanged: getIsRouteChanged(state),
  comment: getRouteChangeComment(state),
  isEditingMap: getApplicationEditingMap(state)
});

const mapDispatchToProps = {
  onCommentChange: actions.onCommentChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationRouteComment);
