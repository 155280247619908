const number = (value, previousValue) => {
  if (!value) {
    return value;
  }
  value = value.replace(',', '.');
  value = value.replace(/[^0-9.]/g, '');
  const dotParts = value.split('.');
  if (dotParts.length > 1) {
    value = `${dotParts[0]}.${dotParts[1]}`;
  }

  // @todo remove start zeros

  const floatValue = parseFloat(value);
  if (isNaN(floatValue)) {
    return previousValue;
  }

  return value;
};

const integer = (value, previousValue) => {
  if (!value) {
    return value;
  }

  value = value.replace(/[^0-9]/g, '');

  value = parseInt(value, 10);
  if (isNaN(value)) {
    return previousValue;
  }
  value = value.toString();
  return value;
};

const vehicleRegion = value => {
  value = value.replace(/[^0-9]/g, '');
  value = value.substr(0, 3);

  return value;
};

export const fieldNormalizers = {
  number,
  integer,

  vehicleRegion
};
