import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { vehicleModelActions } from '../../../../actions/vehicleModelActions';

const StatusErrorModal = props => {
  const { vehicleModels, handleErrorClose } = props;

  return (
    <Dialog
      open={vehicleModels.showDeleteError}
      onClose={handleErrorClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Удаление модели авто
        </Typography>
        <IconButton color="inherit" onClick={handleErrorClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        <span>
          Модель не может быть удалена, т.к. используется в существующих транспортных средствах
        </span>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { vehicleModels } = state;
  return {
    vehicleModels
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleErrorClose: () => {
      dispatch(vehicleModelActions.closeErrorModal());
    }
  };
};

const connectedStatusErrorModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusErrorModal);
export { connectedStatusErrorModal as StatusErrorModal };
