export const controlPostConstants = {
  GET_PAGE_REQUEST: 'CONTROL_POST_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'CONTROL_POST_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'CONTROL_POST_GET_PAGE_FAILURE',

  GET_ALL_REQUEST: 'CONTROL_POST_GET_ALL_REQUEST',
  GET_ALL_SUCCESS: 'CONTROL_POST_GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'CONTROL_POST_GET_ALL_FAILURE',

  LOAD_REQUEST: 'CONTROL_POST_LOAD_REQUEST',
  LOAD_SUCCESS: 'CONTROL_POST_LOAD_SUCCESS',
  LOAD_FAILURE: 'CONTROL_POST_LOAD_FAILURE',

  CREATE_REQUEST: 'CONTROL_POST_CREATE_REQUEST',
  CREATE_SUCCESS: 'CONTROL_POST_CREATE_SUCCESS',
  CREATE_FAILURE: 'CONTROL_POST_CREATE_FAILURE',

  UPDATE_REQUEST: 'CONTROL_POST_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CONTROL_POST_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CONTROL_POST_UPDATE_FAILURE',

  DELETE_REQUEST: 'CONTROL_POST_DELETE_REQUEST',
  DELETE_SUCCESS: 'CONTROL_POST_DELETE_SUCCESS',
  DELETE_FAILURE: 'CONTROL_POST_DELETE_FAILURE',

  FORM_MODAL_OPEN: 'CONTROL_POST_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'CONTROL_POST_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'CONTROL_POST_SET_CURRENT_ITEM',

  CLOSE_ERROR_MODAL: 'CONTROL_POST_CLOSE_ERROR_MODAL',

  CHOOSE_POST: 'CONTROL_POST_CHOOSE_POST'
};
