import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { applicationConstants } from '../../../constants/applicationConstants';
import { roleConstants } from '../../../constants/roleConstants';
import ShowByStatus from './ShowByStatus';
import * as actions from '../../../actions/newApplicationActions';
import {
  getApplicationEditingMap,
  getApplicationId,
  getApplicationMarkers,
  getIsAllAgreementsAccepted,
  getIsDatesValid,
  getIsRouteChanged,
  getIsRunsExists,
  getIsSpring,
  getNeedSizeAgreement,
  getOversizeAgreed,
  getRouteChangeComment
} from '../../../selectors/applicationSelectors';
import DeclineModal from './DeclineModal';
import ApplicationActivateConfirm from './ApplicationActivateConfirm';
import ApplicationPrintModal from './ApplicationPrintModal';
import { urlHelper } from '../../../helpers/urlHelper';

const anchorBottomRight = {
  vertical: 'bottom',
  horizontal: 'right'
};

const DECLINE_PROPS = {
  name: 'Отклонить',
  type: 'decline',
  createHandler: ({ setShowDeclineModal }) => () => setShowDeclineModal(true)
};

const ACCEPT_STANDARD_PROPS = {
  name: 'Одобрить по стандартным нагрузкам',
  type: 'primary',
  disabled: ({ isAllAgreementsAccepted }) => !isAllAgreementsAccepted,
  additionalCondition: ({ oversizeAgreed, needSizeAgreement }) =>
    !needSizeAgreement || oversizeAgreed,
  createHandler: ({ acceptApplication, enqueueSnackbar }) => () =>
    acceptApplication(0)
      .then(() =>
        enqueueSnackbar('Заявление одобрено по стандартным нагрузкам', { variant: 'success' })
      )
      .catch(() => enqueueSnackbar('Ошибка при одобрении заявления', { variant: 'error' }))
};

const ACCEPT_SPRING_PROPS = {
  name: 'Одобрить по весенним нагрузкам',
  type: 'primary',
  disabled: ({ isAllAgreementsAccepted }) => !isAllAgreementsAccepted,
  additionalCondition: ({ oversizeAgreed, needSizeAgreement, isSpring }) =>
    isSpring && (!needSizeAgreement || oversizeAgreed),
  createHandler: ({ acceptApplication, enqueueSnackbar }) => () =>
    acceptApplication(1)
      .then(() =>
        enqueueSnackbar('Заявление одобрено по весенним нагрузкам', { variant: 'success' })
      )
      .catch(() => enqueueSnackbar('Ошибка при одобрении заявления', { variant: 'error' }))
};

const ACTIONS = [
  {
    name: 'Взять в работу',
    type: 'primary',
    statuses: [applicationConstants.STATUS_NEW],
    roles: [
      roleConstants.ROLE_ADMIN,
      roleConstants.ROLE_OFFICER,
      roleConstants.ROLE_DEPARTMENT_AGENT
    ],
    createHandler: ({ application, changeStatusToWork, enqueueSnackbar }) => () =>
      changeStatusToWork(application.id)
        .then(() => enqueueSnackbar('Заявление взято в работу', { variant: 'success' }))
        .catch(() => enqueueSnackbar('Произошла ошибка, попробуйте позже', { variant: 'error' }))
  },
  {
    name: 'Одобрить согласование',
    type: 'primary',
    statuses: [applicationConstants.STATUS_REVIEW, applicationConstants.STATUS_REPEAT],
    roles: [roleConstants.ROLE_DEPARTMENT_AGENT],
    createHandler: ({ application, acceptAgreement, enqueueSnackbar }) => () =>
      acceptAgreement(application.id)
        .then(() => enqueueSnackbar('Заявление согласовано', { variant: 'success' }))
        .catch(() => enqueueSnackbar('Произошла ошибка, попробуйте позже', { variant: 'error' }))
  },
  {
    name: 'Отклонить согласование',
    type: 'decline',
    statuses: [applicationConstants.STATUS_REVIEW, applicationConstants.STATUS_REPEAT],
    roles: [roleConstants.ROLE_DEPARTMENT_AGENT],
    createHandler: ({ application, declineAgreement, enqueueSnackbar }) => () =>
      declineAgreement(application.id)
        .then(() => enqueueSnackbar('Заявление отклонено', { variant: 'success' }))
        .catch(() => enqueueSnackbar('Произошла ошибка, попробуйте позже', { variant: 'error' }))
  },
  {
    name: 'Изменить маршрут',
    statuses: [
      applicationConstants.STATUS_REVIEW,
      applicationConstants.STATUS_REPEAT,
      applicationConstants.STATUS_DECLINE
    ],
    type: 'primary',
    roles: [roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER],
    createHandler: ({ changeEditRoute }) => () => changeEditRoute(true),
    additionalCondition: ({ isEditingMap }) => !isEditingMap
  },
  {
    name: 'Отменить изменение маршрута',
    statuses: [
      applicationConstants.STATUS_REVIEW,
      applicationConstants.STATUS_REPEAT,
      applicationConstants.STATUS_DECLINE
    ],
    type: 'primary',
    roles: [roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER],
    createHandler: ({ changeEditRoute }) => () => changeEditRoute(false),
    additionalCondition: ({ isEditingMap, isRouteChanged }) => isEditingMap && !isRouteChanged
  },
  {
    name: 'Сохранить маршрут',
    statuses: [
      applicationConstants.STATUS_REVIEW,
      applicationConstants.STATUS_REPEAT,
      applicationConstants.STATUS_DECLINE
    ],
    roles: [roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER],
    type: 'primary',
    createHandler: ({
      applicationId,
      changeEditRoute,
      isRouteChanged,
      saveApplicationRoute,
      markers,
      routeChangeComment,
      enqueueSnackbar
    }) => () => {
      if (!routeChangeComment) {
        enqueueSnackbar('Необходимо указать комментарий изменения маршрута!', {
          variant: 'error',
          anchorOrigin: anchorBottomRight
        });
        return;
      }
      if (!markers.map(m => m.text).every(t => !!t)) {
        enqueueSnackbar('Заполните все поля маршрута!', {
          variant: 'error',
          anchorOrigin: anchorBottomRight
        });
      }
      saveApplicationRoute(applicationId, routeChangeComment, markers, isRouteChanged)
        .then(() => {
          enqueueSnackbar('Маршрут сохранен!', {
            variant: 'success',
            anchorOrigin: anchorBottomRight
          });
          return changeEditRoute(false);
        })
        .catch(() =>
          enqueueSnackbar('Произошла ошибка при сохранении маршрута!', {
            variant: 'error',
            anchorOrigin: anchorBottomRight
          })
        );
    },
    additionalCondition: ({ isEditingMap, isRouteChanged }) => isEditingMap && isRouteChanged
  },
  {
    ...ACCEPT_STANDARD_PROPS,
    statuses: [applicationConstants.STATUS_REVIEW, applicationConstants.STATUS_REPEAT],
    roles: [roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER]
  },
  {
    ...ACCEPT_STANDARD_PROPS,
    statuses: [applicationConstants.STATUS_DECLINE],
    roles: [roleConstants.ROLE_ADMIN]
  },
  {
    ...ACCEPT_SPRING_PROPS,
    statuses: [applicationConstants.STATUS_REVIEW, applicationConstants.STATUS_REPEAT],
    roles: [roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER]
  },
  {
    ...ACCEPT_SPRING_PROPS,
    statuses: [applicationConstants.STATUS_DECLINE],
    roles: [roleConstants.ROLE_ADMIN]
  },
  {
    ...DECLINE_PROPS,
    statuses: [applicationConstants.STATUS_REVIEW, applicationConstants.STATUS_REPEAT],
    roles: [roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER]
  },
  {
    ...DECLINE_PROPS,
    statuses: [applicationConstants.STATUS_ACCEPT_WITH_CHANGES, applicationConstants.STATUS_ACCEPT],
    roles: [roleConstants.ROLE_ADMIN]
  },
  {
    name: 'Активировать',
    type: 'primary',
    statuses: [applicationConstants.STATUS_ACCEPT, applicationConstants.STATUS_ACCEPT_WITH_CHANGES],
    roles: [roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER],
    createHandler: ({ setShowActivateModal }) => () => setShowActivateModal(true)
  },
  {
    name: 'Печатать',
    type: 'secondary',
    statuses: [applicationConstants.STATUS_ACTIVE],
    roles: [roleConstants.ROLE_ADMIN, roleConstants.ROLE_OFFICER],
    createHandler: ({ setShowPrintModal }) => () => setShowPrintModal(true)
  },
  {
    name: 'Заблокировать',
    type: 'decline',
    statuses: [applicationConstants.STATUS_ACTIVE],
    roles: [roleConstants.ROLE_ADMIN],
    createHandler: ({ lockApplication, enqueueSnackbar }) => () =>
      lockApplication()
        .then(() => enqueueSnackbar('Разрешение заблокировано!', { variant: 'success' }))
        .catch(() =>
          enqueueSnackbar('Произошла ошибка при блокировке разрешения!', { variant: 'error' })
        ),
    additionalCondition: ({ application }) => !application.is_locked
  },
  {
    name: 'Разблокировать',
    type: 'decline',
    statuses: [applicationConstants.STATUS_ACTIVE],
    roles: [roleConstants.ROLE_ADMIN],
    createHandler: ({ unlockApplication, enqueueSnackbar }) => () =>
      unlockApplication()
        .then(() => enqueueSnackbar('Разрешение разблокировано!', { variant: 'success' }))
        .catch(() =>
          enqueueSnackbar('Произошла ошибка при разблокировке разрешения!', { variant: 'error' })
        ),
    additionalCondition: ({ application }) => application.is_locked
  },
  {
    additionalCondition: ({ application }) => application.is_fast,
    name: 'Создать копию',
    type: 'secondary',
    roles: [roleConstants.ROLE_ADMIN],
    link: ({ user, applicationId }) =>
      `${urlHelper.getHomeUrl(user.role_id)}/quick_applications/copy/${applicationId}`
  },
  {
    name: 'Редактировать',
    type: 'primary',
    roles: [roleConstants.ROLE_FIRMUSER, roleConstants.ROLE_FIRM, roleConstants.ROLE_INDIVIDUAL],
    statuses: [applicationConstants.STATUS_DECLINE],
    link: ({ user, applicationId }) =>
      `${urlHelper.getHomeUrl(user.role_id)}/applications/update/${applicationId}/step/vehicle`
  }
];

function getClasses(type) {
  switch (type) {
    case 'primary':
      return 'no-margin btn-add';
    case 'decline':
      return 'no-margin decline-btn';
    case 'secondary':
      return 'no-margin info-btn';
    default:
      return '';
  }
}

const ApplicationActions = props => {
  return (
    <>
      <h2 className="application-header">
        {ACTIONS.map(
          ({ name, roles, disabled, createHandler, type, statuses, additionalCondition, link }) => (
            <ShowByStatus
              additionalCondition={additionalCondition ? additionalCondition(props) : true}
              key={`${name}_${additionalCondition ? additionalCondition(props) : true}`}
              allowedRoles={roles}
              allowedStatuses={statuses}
            >
              {link ? (
                <Link to={link(props)}>
                  <Button variant="text" className={getClasses(type)}>
                    {name}
                  </Button>
                </Link>
              ) : (
                <Button
                  disabled={disabled && disabled(props)}
                  onClick={createHandler(props)}
                  className={getClasses(type)}
                >
                  {name}
                </Button>
              )}
            </ShowByStatus>
          )
        )}
      </h2>
      <DeclineModal />
      <ApplicationActivateConfirm />
      <ApplicationPrintModal />
    </>
  );
};

const mapStateToProps = state => ({
  applicationId: getApplicationId(state),
  isEditingMap: getApplicationEditingMap(state),
  isRouteChanged: getIsRouteChanged(state),
  routeChangeComment: getRouteChangeComment(state),
  markers: getApplicationMarkers(state),
  needSizeAgreement: getNeedSizeAgreement(state),
  oversizeAgreed: getOversizeAgreed(state),
  isSpring: getIsSpring(state),
  isRunsExists: getIsRunsExists(state),
  isDatesValid: getIsDatesValid(state),
  isAllAgreementsAccepted: getIsAllAgreementsAccepted(state),
  user: state.authentication.user
});

const mapDispatchToProps = {
  changeEditRoute: actions.changeEditRoute,
  changeStatusToWork: actions.changeStatusToWork,
  saveApplicationRoute: actions.saveApplicationRoute,
  acceptApplication: actions.acceptApplication,
  setShowDeclineModal: actions.setShowDeclineModal,
  setShowActivateModal: actions.setShowActivateModal,
  setShowPrintModal: actions.setShowPrintModal,
  lockApplication: actions.lockApplication,
  unlockApplication: actions.unlockApplication,
  acceptAgreement: actions.acceptAgreement,
  declineAgreement: actions.declineAgreement
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ApplicationActions));
