import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { withSnackbar } from 'notistack';

import { LabelField } from '../../../../components/form/LabelField';
import { Loader } from '../../../../components';
import { userService } from '../../../../services/userService';

const EditableEmail = ({ email, id, enqueueSnackbar, onChange }) => {
  const inputRef = useRef(null);
  const [newEmail, setEmail] = useState(email);
  const [isLoading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const disable = e => {
    e.stopPropagation();
    setDisabled(true);
  };

  const changeEmail = async event => {
    setLoading(true);
    try {
      const response = await userService.changeEmail(id, newEmail);
      if (response.email == newEmail) {
        disable(event);
        onChange(newEmail);
        enqueueSnackbar(`Email пользователя успешно изменен`, {
          variant: 'success'
        });
      } else {
        enqueueSnackbar(`Ошибка при обновлении email. ${response}`, {
          variant: 'error'
        });
      }
    } catch (e) {
      enqueueSnackbar(`Ошибка при обновлении email. ${e.message}`, {
        variant: 'error'
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setEmail(email);
  }, [email, setEmail]);

  useEffect(() => {
    if (!disabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled]);

  return (
    <LabelField
      input={{
        ref: inputRef,
        disabled,
        value: disabled ? email : newEmail,
        onChange: e => setEmail(e.target.value)
      }}
      onClick={() => setDisabled(false)}
      label="E-mail"
    >
      {!disabled && (
        <>
          <Button className="btn-add" disabled={isLoading} onClick={changeEmail}>
            {isLoading && <Loader size={24} />} &nbsp; Изменить
          </Button>
          <Button variant="outlined" className="info-btn" onClick={disable} disabled={isLoading}>
            Отменить
          </Button>
        </>
      )}
    </LabelField>
  );
};

export default withSnackbar(EditableEmail);
