import { codeConstants } from '../constants/codeConstants';

export const code = (state = {}, action) => {
  switch (action.type) {
    case codeConstants.REFRESH_REQUEST:
      return {
        ...state,
        refreshSubmitting: true,
        refreshError: false
      };
    case codeConstants.REFRESH_SUCCESS:
      return {
        refreshTime: new Date(),
        refreshSubmitting: false
      };
    case codeConstants.REFRESH_ERROR:
      return {
        ...state,
        refreshSubmitting: false,
        refreshError: action.error
      };
    default:
      return state;
  }
};
