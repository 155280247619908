import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { apvgkReportsActions } from '../../../actions/apvgkReportsActions';
import { apvgkReportsConstants} from "../../../constants/apvgkReportsConstants";
import NewReportsTab from './NewReportsTab'
import WorkReportsTab from './WorkReportsTab'

const filterSubmit = (values, dispatch) => {
  return dispatch(apvgkReportsActions.adminFilter(values));
};

const TabContainer = props => {
  return <Typography component="div">{props.children}</Typography>;
};
const TabContentComponent = {
    [apvgkReportsConstants.STATUS_NEW]: NewReportsTab,
    [apvgkReportsConstants.STATUS_IN_WORK]: WorkReportsTab,
    [apvgkReportsConstants.STATUS_DECLINE]: WorkReportsTab,
    [apvgkReportsConstants.STATUS_ACCEPT]: WorkReportsTab,
};
export const styles = {
    root: {
        width: '100%',
        overflowX: 'auto'
    },
    table: {
        minWidth: 700
    },
    highlight: {
        backgroundColor: '#ffb1b1'
    }
};
const ReportsTab = props => {
  const { apvgkReports, tabIndex, paginateProps} = props;
  const TabContent = TabContentComponent[tabIndex]
  return (
    <div>
          {TabContent && <TabContent paginateProps={paginateProps} styles={styles} />}
    </div>
  );
};

const mapStateToProps = state => {
  const { apvgkReports } = state;
  return {
      apvgkReports
  };
};

const connectedReportsTab = connect(mapStateToProps)(ReportsTab);
export { connectedReportsTab as ReportsTab };
