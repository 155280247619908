import { get } from 'lodash';

import { vehicleConstants } from '../constants/vehicleConstants';
import { authConstants } from '../constants/authConstants';

const vehiclesInitialState = {
  items: [],
  error: undefined,
  loading: false,
  meta: {}
};

const initialState = {
  tabIndex: vehicleConstants.STATUS_NEW,
  currentVehicleTypeId: 0,
  brandId: 1,
  typeIndex: vehicleConstants.TAB_CAR,
  currentItem: {},
  newLicenseFiles: [],
  newPtsFiles: [],
  axleCount: 2,
  vehicleAxles: [{}, {}],
  saveStatus: '',
  newUserForms: [],
  maxNewUserId: -1,
  activeField: '',
  firmUsers: [],
  exceptions: [],

  ...vehiclesInitialState,

  privilegeVehicles: [],

  uploadLicenseError: false,
  licenseUploaders: [],

  uploadPtsError: false,
  ptsUploaders: [],

  showErrorModal: false,
  errorModalText: ''
};

const getFirmUsersByUsers = users => {
  const result = [];
  for (let i = 0; i < users.length; i++) {
    result[i] = {
      id: users[i].id,
      is_existing_user: true,
      user_edit_access: users[i].can_edit,
      list_id: i
    };
  }
  return result;
};

const getMaxUserByUsers = users => {
  return users.length;
};

const addItem = (items, maxId) => {
  return [
    ...items,
    {
      id: maxId + 1,
      list_id: maxId + 1,
      is_existing_user: false,
      user_edit_access: 0
    }
  ];
};
const removeItem = (items, id) => {
  return items.filter(item => item.id !== id);
};
const changeNewUserItems = (items, id, value) => {
  return items.map(item => {
    if (typeof item === 'undefined') {
      return undefined;
    }
    if (item.id === id) {
      item.is_existing_user = value;
    }
    return item;
  });
};

const setVehicleAxlesByCount = (axles, newCount) => {
  const result = [];
  for (let i = 0; i < axles.length && i < newCount; i++) {
    result.push(axles[i]);
  }

  for (let i = axles.length; i < newCount; i++) {
    result.push({});
  }

  return result;
};

export const cabinetVehicles = (state = initialState, action) => {
  switch (action.type) {
    case vehicleConstants.SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.tabIndex
      };
    case vehicleConstants.SET_TYPE_INDEX:
      return {
        ...state,
        typeIndex: action.typeIndex
      };

    // Get page
    case vehicleConstants.GET_CABINET_PAGE_REQUEST:
      return {
        ...state,
        error: undefined,
        loading: true
      };
    case vehicleConstants.GET_CABINET_PAGE_FAILURE:
      return {
        ...state,
        error: 'Не удалось загрузить список!',
        loading: false
      };
    case vehicleConstants.GET_CABINET_PAGE_SUCCESS:
      return {
        ...state,
        items: action.vehicles.data,
        meta: action.vehicles.meta,
        error: undefined,
        loading: false
      };
    // Get privilege statuses for vehicles page
    case vehicleConstants.CHECK_STATUSES_REQUEST:
      return {
        ...state,
        privilegeVehicles: []
      };
    case vehicleConstants.CHECK_STATUSES_SUCCESS:
      return {
        ...state,
        privilegeVehicles: action.privilegeVehicles
      };

    // Delete
    case vehicleConstants.DELETE_REQUEST:
      return {
        ...state,
        items: state.items.map(vehicle =>
          vehicle.id === action.id ? { ...vehicle, deleting: true } : vehicle
        )
      };
    case vehicleConstants.DELETE_SUCCESS:
      // remove deleted HeaderMenu from state
      return {
        ...state,
        loading: false,
        items: state.items.filter(vehicle => vehicle.id !== action.id)
      };
    case vehicleConstants.DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        items: state.items.map(vehicle => {
          if (vehicle.id === action.id) {
            const { deleting, ...vehicleCopy } = vehicle;
            return { ...vehicleCopy, deleteError: action.error };
          }

          return vehicle;
        }),
        showErrorModal: true,
        errorModalText: action.error
      };

    // Get item
    case vehicleConstants.LOAD_REQUEST:
      return {
        ...state,
        uploadPtsError: false,
        loading: true
      };
    case vehicleConstants.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        currentItem: action.vehicle.data,
        vehicleAxles: action.vehicle.data.vehicle_axles,
        axleCount: action.vehicle.data.axle_count,
        newUserForms: getFirmUsersByUsers(action.vehicle.data.firm_users),
        maxNewUserId: getMaxUserByUsers(action.vehicle.data.firm_users),
        saveStatus: ''
      };
    case vehicleConstants.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: 'Не удалось загрузить элемент!'
      };

    case vehicleConstants.SET_BRAND_ID:
      return {
        ...state,
        brandId: action.brandId
      };
    case vehicleConstants.SET_VEHICLE_TYPE_ID:
      return {
        ...state,
        currentVehicleTypeId: action.typeId
      };

    // change form
    case vehicleConstants.SET_VEHICLE_AXLE_COUNT:
      return {
        ...state,
        axleCount: action.axle_count,
        vehicleAxles: setVehicleAxlesByCount(state.vehicleAxles, action.axle_count)
      };

    // Save info
    case vehicleConstants.UPDATE_REQUEST:
      return {
        ...state,
        saveStatus: ''
      };
    case vehicleConstants.UPDATE_SUCCESS:
      return {
        ...state,
        saveStatus: 'Данные успешно сохранены!'
      };
    case vehicleConstants.UPDATE_FAILURE:
      return {
        ...state,
        saveStatus: 'Не удалось сохранить данные!'
      };

    case vehicleConstants.USER_FORM_ADD:
      return {
        ...state,
        newUserForms: addItem(state.newUserForms, state.maxNewUserId),
        maxNewUserId: state.maxNewUserId + 1
      };
    case vehicleConstants.USER_FORM_REMOVE:
      return {
        ...state,
        newUserForms: removeItem(state.newUserForms, action.id)
      };

    case vehicleConstants.RESET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: null
      };

    case vehicleConstants.ACTIVE_FIELD:
      return {
        ...state,
        activeField: action.field
      };
    case vehicleConstants.SET_IS_EXISTING_NEW_USER:
      return {
        ...state,
        newUserForms: changeNewUserItems(state.newUserForms, action.id, action.value)
      };
    case vehicleConstants.GET_FIRM_USERS_SUCCESS:
      return {
        ...state,
        firmUsers: action.users
      };
    case vehicleConstants.GET_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        exceptions: action.emails
      };

    case vehicleConstants.REMOVE_TAB_LIST_ITEM:
      return {
        ...state,
        items: state.items.filter(vehicle => vehicle.id !== action.id)
      };

    case vehicleConstants.PTS_UPLOAD_REQUEST:
      return {
        ...state,
        uploadPtsError: false,
        ptsUploaders: [
          ...state.ptsUploaders,
          {
            uploaderId: action.uploaderId,
            name: action.name
          }
        ]
      };
    case vehicleConstants.PTS_UPLOAD_FAILURE:
      return {
        ...state,
        uploadPtsError: true,
        ptsUploaders: state.ptsUploaders.filter(item => item.uploaderId !== action.uploaderId)
      };

    case vehicleConstants.PTS_UPLOAD_SUCCESS:
      return {
        ...state,
        newPtsFiles: [...state.newPtsFiles, action.data],
        ptsUploaders: state.ptsUploaders.filter(item => item.uploaderId !== action.uploaderId)
      };
    case vehicleConstants.PTS_REMOVE: {
      return {
        ...state,
        currentItem: state.currentItem
          ? {
              ...state.currentItem,
              files: state.currentItem.files
                ? {
                    ...state.currentItem.files,
                    pts_files: get(state, 'currentItem.files.pts_files', []).filter(
                      item => item.id !== action.id
                    )
                  }
                : state.currentItem.files
            }
          : state.currentItem,
        newPtsFiles: state.newPtsFiles.filter(item => item.id !== action.id)
      };
    }

    case vehicleConstants.LICENSE_UPLOAD_REQUEST:
      return {
        ...state,
        uploadLicenseError: false,
        licenseUploaders: [
          ...state.licenseUploaders,
          {
            uploaderId: action.uploaderId,
            name: action.name
          }
        ]
      };
    case vehicleConstants.LICENSE_UPLOAD_FAILURE:
      return {
        ...state,
        uploadLicenseError: true,
        licenseUploaders: state.licenseUploaders.filter(
          item => item.uploaderId !== action.uploaderId
        )
      };

    case vehicleConstants.LICENSE_UPLOAD_SUCCESS:
      return {
        ...state,
        newLicenseFiles: [...state.newLicenseFiles, action.data],
        licenseUploaders: state.licenseUploaders.filter(
          item => item.uploaderId !== action.uploaderId
        )
      };
    case vehicleConstants.LICENSE_REMOVE: {
      return {
        ...state,
        currentItem: state.currentItem
          ? {
              ...state.currentItem,
              files: state.currentItem.files
                ? {
                    ...state.currentItem.files,
                    license_files: get(state, 'currentItem.files.license_files', []).filter(
                      item => item.id !== action.id
                    )
                  }
                : state.currentItem.files
            }
          : state.currentItem,
        newLicenseFiles: state.newLicenseFiles.filter(item => item.id !== action.id)
      };
    }
    case vehicleConstants.CLOSE_ERROR_MODAL: {
      return {
        ...state,
        showErrorModal: false
      };
    }

    case authConstants.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
