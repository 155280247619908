import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { fieldValidators } from '../../../helpers/fieldValidators';
import { Loader } from '../../../components/Loader';
import { authActions } from '../../../actions/authActions';
import { FormField } from '../../../components/form/FormField';
import { roleConstants } from '../../../constants/roleConstants';

let UserMainInfoForm = props => {
  const { error, handleSubmit, submitting, cabinet, user } = props;
  return (
    <div>
      <form onSubmit={handleSubmit}>
        {user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT && (
          <Field
            name="department"
            type="text"
            label="Название ведомства"
            disabled="disabled"
            component={FormField}
          />
        )}
        {/* { */}
        {/* user.role_id === roleConstants.ROLE_WEIGHT_CONTROL && */}
        {/* <Field */}
        {/* name="post" */}
        {/* type="text" */}
        {/* label="№ поста" */}
        {/* disabled="disabled" */}
        {/* component={FormField} */}
        {/* /> */}
        {/* } */}
        <Field
          name="name"
          type="text"
          label="Полное имя (ФИО)"
          validate={[fieldValidators.required, fieldValidators.maxLength60]}
          component={FormField}
        />
        <Field
          name="email"
          type="email"
          label="Email"
          validate={[fieldValidators.required, fieldValidators.email, fieldValidators.maxLength40]}
          component={FormField}
        />
        {error && <strong>{error}</strong>}
        <p>{cabinet.infoStatus}</p>

        <div className="row">
          <div className="text-left">
            <Button className="btn-add" type="submit" disabled={submitting}>
              {submitting && <Loader />} Сохранить
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

UserMainInfoForm = reduxForm({
  form: 'cabinet-main',
  enableReinitialize: true
})(UserMainInfoForm);

const mapStateToProps = state => {
  const { user } = state.authentication;
  const { cabinet } = state;

  const initialValues = user
    ? {
        name: user.name,
        email: user.email
      }
    : null;

  if (initialValues && user.role_id === roleConstants.ROLE_DEPARTMENT_AGENT) {
    initialValues.department = user.department ? user.department.title : '';
  }
  if (initialValues && user.role_id === roleConstants.ROLE_WEIGHT_CONTROL) {
    initialValues.post = user.control_post ? user.control_post.title : '';
  }

  return {
    user,
    cabinet,
    initialValues
  };
};
const mapDispatchToProps = dispatch => {
  return {
    togglePasswordVisible: () => {
      dispatch(authActions.togglePasswordVisible());
    }
  };
};

const connectedUserMainInfoForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMainInfoForm);
export { connectedUserMainInfoForm as UserMainInfoForm };
