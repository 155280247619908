import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { applicationActions } from '../../../../actions/applicationActions';
import AgreementDeclineForm from './AgreementDeclineForm';

const updateSubmit = (values, dispatch) => {
  return dispatch(applicationActions.agreementDecline(values));
};

const AgreementDeclineModal = props => {
  const { handleAgreementDeclineClose, adminApplications } = props;

  return (
    <Dialog
      open={adminApplications.showAgreementDeclineModal}
      onClose={handleAgreementDeclineClose}
      aria-labelledby="form-dialog-title"
    >
      <Toolbar className="text-center">
        <Typography variant="title" color="inherit" className="flex">
          Отклонение заявки
        </Typography>
        <IconButton color="inherit" onClick={handleAgreementDeclineClose}>
          <CloseIcon className="blue" />
        </IconButton>
      </Toolbar>

      <DialogContent className="dialog text-center">
        {adminApplications && adminApplications.currentItem && (
          <AgreementDeclineForm
            currentItem={adminApplications.currentItem}
            onSubmit={updateSubmit}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = state => {
  const { adminApplications } = state;
  return {
    adminApplications
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    handleAgreementDeclineClose: () => {
      dispatch(applicationActions.closeAgreementDeclineModal());
    }
  };
};

const connectedAgreementDeclineModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementDeclineModal);
export { connectedAgreementDeclineModal as AgreementDeclineModal };
