import { createTextMask } from 'redux-form-input-masks';

const ptsDefitition = {
  a: {
    regExp: /[а-яА-Яa-zA-Z0-9]/,
    transform: char => {
      return char.toUpperCase();
    }
  },
  9: {
    regExp: /[0-9]/
  }
};
const russianSymbDefinition = {
  a: {
    regExp: /[авекмнорстухАВЕКМНОРСТУХabekmhopctyxABEKMHOPCTYX]/,
    transform: char => {
      return char.toUpperCase();
    }
  },
  9: {
    regExp: /[0-9]/
  }
};
const regionDefinition = {
  a: {
    regExp: /[a-zA-Z]/,
    transform: char => char.toUpperCase()
  },
  9: {
    regExp: /[0-9]/
  }
};

const phoneRus = createTextMask({
  pattern: '+7 (999) 999-99-99'
});
const innIndividual = createTextMask({
  pattern: '999999999999'
});
const innFirm = createTextMask({
    pattern: '9999999999'
});
const account = createTextMask({
  pattern: '99999999999999999999'
});
const bik = createTextMask({
  pattern: '999999999'
});
const kppFirm = bik;
const confirmationCode = createTextMask({
  pattern: '9999'
});
const ptsNumber = createTextMask({
  // pattern: 'aa aa 999999',
  pattern: 'aaaaaaaaaaaaaaa',
  maskDefinitions: ptsDefitition
});

const vehicleNumber = createTextMask({
  pattern: 'a999aa',
  maskDefinitions: russianSymbDefinition
});
const trailerNumber = createTextMask({
  pattern: 'aa9999',
  maskDefinitions: russianSymbDefinition
});

const vehicleRegion = createTextMask({
  pattern: '999aaa',
  maskDefinitions: regionDefinition
});

// normalizers
const normalizePhoneRus = phone => {
  // @todo handle by mask property
  return `+7${phone}`;
};

export const fieldMasks = {
  phoneRus,
  confirmationCode,

  innIndividual,
  innFirm,
  kppFirm,
  account,
  bik,

  ptsNumber,
  vehicleNumber,
  trailerNumber,
  vehicleRegion,

  normalizePhoneRus
};
