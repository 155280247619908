import React from 'react';
import { connect } from 'react-redux';
import { roleConstants } from '../../../constants/roleConstants';
import ApplicationField from './ApplicationField';
import ShowByStatus from './ShowByStatus';
import { applicationConstants } from '../../../constants/applicationConstants';

function roundPrice(price) {
  return Math.round(price * 100) / 100;
}

const ApplicationFirmPrice = ({ application }) => {
  return (
    <ShowByStatus
      allowedRoles={[
        roleConstants.ROLE_FIRMUSER,
        roleConstants.ROLE_FIRM,
        roleConstants.ROLE_INDIVIDUAL
      ]}
      allowedStatuses={[
        applicationConstants.STATUS_ACCEPT_WITH_CHANGES,
        applicationConstants.STATUS_ACCEPT,
        applicationConstants.STATUS_ACTIVE
      ]}
    >
      <ApplicationField
        label="Стоимость разрешения"
        value={roundPrice(application.real_price || 0)}
      />
    </ShowByStatus>
  );
};

const mapStateToProps = state => ({
  role: state.authentication.user.role_id,
  specialConditions: state.specialConditions
});

export default connect(mapStateToProps)(ApplicationFirmPrice);
