export const departmentConstants = {
  GET_PAGE_REQUEST: 'DEPARTMENT_GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'DEPARTMENT_GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'DEPARTMENT_GET_PAGE_FAILURE',

  GETALL_REQUEST: 'DEPARTMENT_GETALL_REQUEST',
  GETALL_SUCCESS: 'DEPARTMENT_GETALL_SUCCESS',
  GETALL_FAILURE: 'DEPARTMENT_GETALL_FAILURE',

  LOAD_REQUEST: 'DEPARTMENT_LOAD_REQUEST',
  LOAD_SUCCESS: 'DEPARTMENT_LOAD_SUCCESS',
  LOAD_FAILURE: 'DEPARTMENT_LOAD_FAILURE',

  CREATE_REQUEST: 'DEPARTMENT_CREATE_REQUEST',
  CREATE_SUCCESS: 'DEPARTMENT_CREATE_SUCCESS',
  CREATE_FAILURE: 'DEPARTMENT_CREATE_FAILURE',

  UPDATE_REQUEST: 'DEPARTMENT_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'DEPARTMENT_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'DEPARTMENT_UPDATE_FAILURE',

  DELETE_REQUEST: 'DEPARTMENT_DELETE_REQUEST',
  DELETE_SUCCESS: 'DEPARTMENT_DELETE_SUCCESS',
  DELETE_FAILURE: 'DEPARTMENT_DELETE_FAILURE',

  FORM_MODAL_OPEN: 'DEPARTMENT_FORM_MODAL_OPEN',
  FORM_MODAL_CLOSE: 'DEPARTMENT_FORM_MODAL_CLOSE',
  SET_CURRENT_ITEM: 'DEPARTMENT_SET_CURRENT_ITEM',

  CLOSE_ERROR_MODAL: 'DEPARTMENT_CLOSE_ERROR_MODAL'
};
