import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { roleConstants } from '../../constants/roleConstants';

export const AuthRoute = ({ roles, component: Component, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const renderRoute = props => {
    const userRole = user ? user.role_id : null;
    let hasPermission = roles.findIndex((v) => v === user.role_id) !== -1
    return hasPermission ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    );
  };
  return <Route {...rest} render={renderRoute} />;
};
