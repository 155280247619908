import React from 'react';
import { Button } from '@material-ui/core';
import { isArray } from 'lodash';
import { applicationConstants } from '../../../../constants/applicationConstants';
import { apiService } from '../../../../services/apiService';
import { apiConstants } from '../../../../constants/apiConstants';
import { download } from '../../../../helpers/download';
import { get } from 'lodash';

const ApplicationPrintDoc = ({ application }) => {
  if (
    ![
      applicationConstants.STATUS_ACCEPT,
      applicationConstants.STATUS_ACTIVE,
      applicationConstants.STATUS_REPEAT,
      applicationConstants.STATUS_COMPLETE
    ].includes(application.status) ||
    !(application.route || application.privilege_status_id) ||
    !get(application.load, 'axles_info') ||
    !application.load ||
    !isArray(JSON.parse(application.load.axles_info)[0].permissible_load)
  ) {
    return null;
  }

  const url = `/applications/${application.id}/print-doc`;
  return (
    <Button
      onClick={() =>
        apiService
          .call(apiConstants.METHOD_GET, url, undefined, true, {
            responseType: 'blob'
          })
          .then(response => {
            download(response, 'pay.doc');
          })
      }
      className="btn-add no-margin"
    >
      Расчетный лист
    </Button>
  );
};

export default ApplicationPrintDoc;
