import React, { useEffect, useRef, useState } from 'react';
import { flatMap, get, isArray, isEmpty } from 'lodash';
import { withSnackbar } from 'notistack';
import { useAsync } from 'react-async';
import { Field, Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import mime from 'mime-types';
import { encode, decode } from 'js-base64';

import { withRouter } from 'react-router-dom';
import FieldLabel from '../../components/FieldLabel';
import Input from '../../components/Input';
import AgreementsForm from '../../components/AgreementsForm';
import MaterialInput from '../../../quickApplications/CreateQuickApplication/components/MaterialInput';
import MaterialSelect from '../../../quickApplications/CreateQuickApplication/components/MaterialSelect';
import { CooperationRequestStatus } from '../../../../../constants/cooperationConstants';
import { RouteMap } from '../ProcessRouteMap';
import { applicationService } from '../../../../../services/applicationService';
import { Schema } from '../../util/Schema';
import { departmentService } from '../../../../../services/departmentService';
import { applicationHelper } from '../../../../../helpers/applicationHelper';
import SignPluginCheckContainer from './SignPluginCheckContainer';
import { SignService } from '../../../../../services/signService';
import SignPluginCheck from './SignPluginCheck';
import smevService from '../../../../../services/smevService';
import { formatEventDate } from '../../util/mapping';
import ConfirmationModal from '../../../../../components/modals/ConfirmationModal';
import CooperationFiles from '../../components/CooperationFiles';
import Damage from '../../components/Damage';
import MaterialCheckbox from '../../../quickApplications/CreateQuickApplication/components/MaterialCheckbox';
import { apiService } from '../../../../../services/apiService';
import { apiConstants } from '../../../../../constants/apiConstants';
import RequestBill from './RequestBill';
import AddSpecialText from './AddSpecialText';

function toPaths(paths) {
  return paths.map(path => ({
    id: path.id,
    text: path.name || path.name_prefix,
    steps: path.points.map(step => ({
      text: step.name,
      coords: {
        lat: +step.lat,
        lng: +(step.lon || step.lng)
      }
    }))
  }));
}

function roundPrice(price) {
  return Math.round(price * 100) / 100;
}

function getPoints(savedRoutes, points) {
  if (!points || points === '[]') {
    return [
      {
        useSaved: false,
        steps: [{ text: '' }, { text: '' }]
      }
    ];
  }
  const parsed = JSON.parse(points) || [];
  return parsed.map(path => ({
    useSaved: !isArray(path),
    ...(isArray(path)
      ? {
          steps: path.map(part => ({
            text: part.name,
            coords: part.coords
          }))
        }
      : savedRoutes.find(route => route.id === path.id_list))
  }));
}

async function signXml(applicationId, data, cert, fileSignature) {
  const certExport = await cert.cert.Export(0);
  const signedXml = await SignService.signXml(
    cert.cert,
    certExport.replace(/[\r\n]/g, ''),
    applicationId,
    data,
    fileSignature
  );
  return signedXml;
}

function createFilesXml(files) {
  const filesContent = files.map(
    file =>
      `<AppliedDocument><CodeDocument /><Name>${file.name}</Name><Number /><URL>${
        file.name
      }</URL><Type>${mime.lookup(file.name)}</Type><DigestValue>${
        file.hash
      }</DigestValue></AppliedDocument>`
  );
  return `<?xml version="1.0"?><AppliedDocuments xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="http://smev.gosuslugi.ru/request/rev111111">${filesContent.join(
    ''
  )}</AppliedDocuments>`.trim();
}

async function signFiles(files, cert, invoice, applicationId) {
  const allFiles = [...files];
  if (invoice) {
    allFiles.push({
      url: invoice.invoice_link,
      id: `permit_${applicationId}`
    });
  }
  if (!allFiles.length) {
    return {};
  }
  console.log('signing files');
  console.log(allFiles);
  const filesWithSign = await Promise.all(
    allFiles.map(async file => {
      const content = await SignService.downloadFileContent(file);
      console.log('sign file');
      const sig = await SignService.signFile(content[0], cert);
      console.log('get hash');
      const hash = await SignService.getHash(content[0]);
      console.log('finished');
      const fileUrl = file.url;
      const url =
        fileUrl.indexOf('?') >= 0 ? fileUrl.substring(0, fileUrl.lastIndexOf('?')) : fileUrl;
      return {
        hash,
        sig,
        url,
        id: file.id,
        name: url.substring(url.lastIndexOf('/') + 1)
      };
    })
  );
  console.log('creating xml');
  const filesXml = createFilesXml(filesWithSign, cert);
  console.log(filesXml);
  const filesXmlSig = await SignService.signFile(encode(filesXml), cert);
  console.log('finish signing');
  return {
    xml: filesXml,
    sig: filesXmlSig,
    files: filesWithSign
  };
}

async function signData(data, applicationId, certsRef, files, invoice) {
  const certInfo = certsRef.current.find(c => c.id === data.ecp);
  if (!certInfo) {
    return data;
  }
  const { cert } = certInfo;
  const fileSignature = await signFiles(files, cert, invoice, applicationId);
  const ecpData = await SignService.sign(cert, JSON.stringify(data));
  const signedXml = await signXml(applicationId, data, certInfo, fileSignature);
  return {
    ...data,
    ecp: ecpData,
    signed_xml: signedXml,
    cert: certInfo
  };
}

function isRouteValid(value, result) {
  if (result !== 4) {
    return true;
  }
  return flatMap(value, v => v.steps || []).filter(v => !!v.text && !!v.coords).length >= 2;
}

const RequestProcessingInProgress = ({ application, isSpring, reloadData, enqueueSnackbar }) => {
  const { id, admin, route } = application;
  const [declineModal, setDeclineModal] = useState(false);
  const [showNoSignModal, setShowNoSign] = useState(false);
  const [newRoute, setNewRoute] = useState(null);
  const [agreements, setAgreements] = useState(application.application_agreements);
  const [files, setFiles] = useState(get(application, 'files.in_work_files') || []);
  const [savedRoutes, setSavedRoutes] = useState(null);
  const [certs, setCerts] = useState([]);
  const certsRef = useRef(null);
  const [invoice, setInvoice] = useState(get(application, 'permit', null));
  const hasBillFile = !!get(invoice, 'invoice_link');
  useEffect(() => {
    async function fetchSaved() {
      const response = await smevService.getRoutesList();
      setSavedRoutes(toPaths(response));
    }
    fetchSaved();
  }, []);

  useEffect(() => {
    async function fetchCerts() {
      const data = await SignService.getCerts();
      certsRef.current = data;
      setCerts(
        data.map(d => ({
          label: d.label,
          value: d.id
        }))
      );
    }

    fetchCerts();
  }, []);

  const handleFileRemove = file => {
    setFiles(files.filter(f => f.id !== file.id));
  };

  const handleFileAdd = file => {
    setFiles(oldFiles => [...oldFiles, file]);
  };

  const { data: departments } = useAsync({ promiseFn: departmentService.getAll });
  const decline = useAsync({ deferFn: ([a]) => applicationService.decline(a) });
  const reset = useAsync({ deferFn: ([a]) => applicationService.resetStatus(a) });
  const accept = useAsync({
    deferFn: ([a]) =>
      applicationService.accept(
        a.id,
        a.comment,
        a.waypoints,
        a.routeChanged,
        a.changeStatus,
        a.isSpring,
        1,
        a.special_conditions,
        a.ecp,
        a.signed_xml
      )
  });
  const additionalInfoRequired = useAsync({
    deferFn: ([a]) => applicationService.setAdditionalInfoRequired(a)
  });
  const removeAgreement = useAsync({ deferFn: ([a]) => applicationService.removeAgreement(a) });
  const sendToDepartment = useAsync({ deferFn: ([a]) => applicationService.sendToDepartment(a) });

  const onDecline = ({ comment, ecp, special_conditions, signed_xml }) =>
    decline
      .run({ id, note: comment, ecp, special_conditions, status: 1, signed_xml })
      .then(async ({ data }) => {
        if (data) {
          reloadData();
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          enqueueSnackbar('Не удалось отклонить заявку, пожалуйста повторите позднее', {
            variant: 'error'
          });
        }
      });

  const onReset = () =>
    reset.run({ id }).then(async ({ success }) => {
      if (success) {
        reloadData();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      } else {
        enqueueSnackbar('Не удалось прекратить работу, пожалуйста повторите позднее', {
          variant: 'error'
        });
      }
    });

  const onAdditionalInfoRequired = ({
    cert,
    ecp,
    comment,
    special_conditions,
    signed_xml,
    is_spring
  }) => {
    try {
      additionalInfoRequired
        .run({ id, ecp, comment, special_conditions, signed_xml, is_spring: is_spring ? 1 : 0 })
        .then(async ({ data }) => {
          if (data) {
            reloadData();
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          } else {
            enqueueSnackbar('Не удалось изменить статус заявки, пожалуйста, повторите позднее', {
              variant: 'error'
            });
          }
        });
    } catch (e) {
      enqueueSnackbar('Не удалось изменить статус заявки, пожалуйста, повторите позднее', {
        variant: 'error'
      });
    }
  };

  const routeInfo = newRoute || route;
  const priceInfo = get(newRoute, 'info') || application.price_info;
  const employee = get(application, 'events.ADMINGBU_APP_TOWORK');

  // TODO
  const onAccept = values =>
    accept
      .run({
        id,
        comment: values.comment,
        waypoints: null,
        routeChanged: 0,
        changeStatus: 1,
        isSpring: isSpring ? values.is_spring : 0,
        price: 0,
        federal_distance: 0,
        regional_distance: 0,
        federal_price: 0,
        regional_price: 0,
        special_conditions: values.special_conditions,
        ecp: values.ecp,
        signed_xml: values.signed_xml
      })
      .then(async ({ data }) => {
        if (data) {
          reloadData();
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          enqueueSnackbar('Не удалось обработать заявку, пожалуйста повторите позднее', {
            variant: 'error'
          });
        }
      });

  const onRemoveAgreement = departmentId =>
    removeAgreement.run({ id: departmentId }).then(response => {
      if (isEmpty(response)) {
        setAgreements(agreements.filter(a => a.id !== departmentId));
      } else {
        enqueueSnackbar('Не удалось отклонить согласование, пожалуйста повторите позднее', {
          variant: 'error'
        });
      }
    });

  const onSendToDepartment = department =>
    sendToDepartment
      .run({ id, values: { department_id: department } })
      .then(({ data: agreement }) => {
        if (agreement) {
          setAgreements([...agreements, agreement]);
        } else {
          enqueueSnackbar('Не удалось отправить на согласование, пожалуйста повторите позднее', {
            variant: 'error'
          });
        }
      });

  const onSubmit = async data => {
    try {
      const { result } = data;
      const submitData = await signData(data, id, certsRef, files, result !== 2 ? invoice : null);
      switch (submitData.result) {
        case 2:
          return onDecline(submitData);
        case 4:
          return onAccept(submitData);
        case 9:
          return onAdditionalInfoRequired(submitData);
        default:
          return Promise.reject();
      }
    } catch (e) {
      enqueueSnackbar('Произошла ошибка при подписи данных', {
        variant: 'error'
      });
    }
    return Promise.reject();
  };

  async function sign(setFieldValue, values) {
    const hasPlugin = await SignService.hasPlugin();
    if (!hasPlugin) {
      setShowNoSign(true);
      return;
    }
    const ecp = await SignService.sign(values);
    setFieldValue('ecp', ecp);
  }

  if (!savedRoutes) {
    return null;
  }
  return (
    <>
      <SignPluginCheckContainer />
      <Formik
        initialValues={{
          special_conditions: '',
          agreements: [],
          result: 4,
          is_spring: isSpring ? 1 : 0,
          routeSaved: true,
          paths: savedRoutes
            ? getPoints(savedRoutes, application.route.points, application.route.tracks)
            : []
        }}
        onSubmit={async (data, { validateForm, setErrors, setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(data);
          setSubmitting(false);
        }}
        validate={async values => {
          return new Promise((resolve, reject) => {
            validateYupSchema(values, Schema, false, { head: values }).then(
              () => resolve({}),
              err => reject(yupToFormErrors(err))
            );
          });
        }}
        render={({ values, isValid, isSubmitting, setFieldTouched, setFieldValue }) => (
          <Form>
            <Grid container direction="column" spacing={16} wrap="nowrap">
              <Grid item>
                <h2 className="h3-title">Обработка запроса</h2>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Сотрудник, взявший в работу"
                  labelProps={{ xs: 12, sm: 4, lg: 2 }}
                  childrenProps={{ xs: 12, sm: 8, lg: 5 }}
                >
                  <Input value={employee.user_name} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Должность сотрудника"
                  labelProps={{ xs: 12, sm: 4, lg: 2 }}
                  childrenProps={{ xs: 12, sm: 8, lg: 5 }}
                >
                  <Input value={employee.user_position} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Дата взятия запроса в работу"
                  labelProps={{ xs: 12, sm: 4, lg: 2 }}
                  childrenProps={{ xs: 12, sm: 8, lg: 5 }}
                >
                  <Input value={formatEventDate(get(employee, 'created_at'))} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <h2 className="h4-title">Согласуемый маршрут</h2>
              </Grid>
              <Grid item>
                <RouteMap
                  readOnly={hasBillFile}
                  setFieldValue={setFieldValue}
                  savedRoutes={savedRoutes}
                  values={values}
                  setFieldTouched={setFieldTouched}
                  id={application.id}
                  onRouteChange={r => {
                    setNewRoute(r);
                    setFieldValue('routeSaved', false);
                  }}
                />
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Общая протяженность"
                  labelProps={{ xs: 4, lg: 3 }}
                  childrenProps={{ xs: 8, lg: 2 }}
                >
                  <Input value={`${get(routeInfo, 'distance', 0)} км`} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Протяженность дорог с нормативной нагрузкой 11,5 т/ось"
                  labelProps={{ xs: 4, lg: 3 }}
                  childrenProps={{ xs: 8, lg: 2 }}
                >
                  <Input value={`${roundPrice(get(routeInfo, 'distance_info[1]', 0))} км`} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Протяженность дорог с нормативной нагрузкой 10 т/ось"
                  labelProps={{ xs: 4, lg: 3 }}
                  childrenProps={{ xs: 8, lg: 2 }}
                >
                  <Input value={`${roundPrice(get(routeInfo, 'distance_info[0]', 0))} км`} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  label="Протяженность дорог с нормативной нагрузкой 6 т/ось"
                  labelProps={{ xs: 4, lg: 3 }}
                  childrenProps={{ xs: 8, lg: 2 }}
                >
                  <Input value={`${roundPrice(get(routeInfo, 'distance_info[2]', 0))} км`} />
                </FieldLabel>
              </Grid>
              <Damage priceInfo={priceInfo} isSpring={isSpring} />
              <AgreementsForm
                processing={sendToDepartment.isLoading}
                agreements={agreements}
                departments={departments}
                onSend={onSendToDepartment}
                onRemove={onRemoveAgreement}
              />
              <CooperationFiles
                application={application}
                files={files}
                handleFileAdd={handleFileAdd}
                handleFileRemove={handleFileRemove}
              />
              {isSpring && (
                <Grid item>
                  <Field
                    name="is_spring"
                    component={MaterialCheckbox}
                    disabled={!!invoice}
                    label=" Учитывать весенние допустимые нагрузки"
                    boldLabel
                  />
                </Grid>
              )}
              <RequestBill
                applicationId={application.id}
                isRouteValid={isRouteValid(values.paths, values.result)}
                invoice={invoice}
                isSpring={values.is_spring}
                disabled={!values.routeSaved}
                onUpdate={setInvoice}
              />
              <Grid item>
                <FieldLabel
                  name="result"
                  label="Результат рассмотрения заявки"
                  labelProps={{ xs: 4, lg: 3 }}
                  childrenProps={{ xs: 8, lg: 5 }}
                  boldLabel
                >
                  <Field
                    name="result"
                    component={MaterialSelect}
                    options={[
                      { value: 4, label: CooperationRequestStatus[4] },
                      { value: 2, label: CooperationRequestStatus[2] },
                      { value: 9, label: CooperationRequestStatus[9] }
                    ]}
                  />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  name="comment"
                  label="Комментарий"
                  labelProps={{ xs: 4, lg: 3 }}
                  childrenProps={{ xs: 8, lg: 5 }}
                  boldLabel
                >
                  <Field name="comment" component={MaterialInput} />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  name="special_conditions"
                  label="Особые условия движения"
                  labelProps={{ xs: 4, lg: 3 }}
                  childrenProps={{ xs: 8, lg: 5 }}
                  boldLabel
                  additional={
                    <AddSpecialText
                      onAdd={option =>
                        setFieldValue(
                          'special_conditions',
                          `${values.special_conditions} ${option}`.trimStart()
                        )
                      }
                    />
                  }
                >
                  <Field
                    name="special_conditions"
                    component={MaterialInput}
                    Component="textarea"
                    rows={4}
                    style={{ minHeight: 50 }}
                    autocomplete="on"
                  />
                </FieldLabel>
              </Grid>
              <Grid item>
                <FieldLabel
                  name="ecp"
                  label="ЭЦП"
                  labelProps={{ xs: 4, lg: 3 }}
                  childrenProps={{ xs: 8, lg: 5 }}
                  boldLabel
                >
                  <Field
                    name="ecp"
                    component={MaterialSelect}
                    options={certs}
                    placeholder="Выберите сертификат"
                  />
                </FieldLabel>
              </Grid>
              <Grid item>
                <Grid container spacing={16}>
                  <Grid item>
                    <Button className="btn-add no-margin" onClick={() => setDeclineModal(true)}>
                      Прекратить работу
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={`btn-add no-margin ${
                        !isValid || !isRouteValid(values.paths, values.result)
                          ? 'disabled-button'
                          : ''
                      }`}
                      type="submit"
                      disabled={
                        !isValid ||
                        isSubmitting ||
                        !isRouteValid(values.paths, values.result) ||
                        !applicationHelper.allAgreementsAccepted(agreements)
                      }
                    >
                      {isSubmitting ? <CircularProgress /> : 'Отправить'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      />
      <ConfirmationModal
        isShow={declineModal}
        title="Вы уверены, что хотите прекратить работу с данным разрешением?"
        handleClose={() => setDeclineModal(false)}
        handleSubmit={onReset}
      />
      <SignPluginCheck show={showNoSignModal} onClose={() => setShowNoSign(false)} />
    </>
  );
};

export default withSnackbar(withRouter(RequestProcessingInProgress));
