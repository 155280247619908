import React from 'react';

import { MainFooterBlock } from '../main/blocks/MainFooterBlock/MainFooterBlock';
import { CabinetMainMenu } from '../cabinet/blocks/CabinetMainMenu/CabinetMainMenu';
import { CabinetLeftMenu } from '../cabinet/blocks/CabinetLeftMenu/CabinetLeftMenu';
import AdminMainPage from '../admin/main/AdminMainPage';
import { OfficerRoute } from '../../components/routes/OfficerRoute';
import VehiclesLayout from './vehicles/VehiclesLayout';
import ApplicationsLayout from './applications/ApplicationsLayout';
import RegAppsLayout from './regApps/RegAppsLayout';
import QuickApplicationsLayout from './quickApplications/QuickApplicationsLayout';

const OfficerLayout = ({ match }) => {
  const links = [
    { key: '1', path: `${match.url}`, title: 'Личный кабинет' },
    { key: '2', path: `${match.url}/reg-apps`, title: 'Заявки на регистрацию' },
    {
      key: '3',
      path: `${match.url}/vehicles`,
      title: 'Заявки на регистрацию тс',
      hasChild: true
    },
    {
      key: '4',
      path: `${match.url}/applications`,
      title: 'Модерация заявлений',
      hasChild: true
    },
    {
      key: '5',
      path: `${match.url}/quick_applications`,
      title: 'Быстрые разрешения',
      hasChild: true
    }
  ];

  return (
    <div className="">
      <div className="cabinet-header">
        <CabinetMainMenu />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <div className=" cabinet-left-menu">
              <CabinetLeftMenu links={links} />
            </div>
          </div>

          <div className="col-md-9 cabinet-content">
            <OfficerRoute exact path={`${match.url}`} component={AdminMainPage} />
            <OfficerRoute path={`${match.url}/reg-apps`} component={RegAppsLayout} />
            <OfficerRoute path={`${match.url}/vehicles`} component={VehiclesLayout} />
            <OfficerRoute path={`${match.url}/applications`} component={ApplicationsLayout} />
            <OfficerRoute
              path={`${match.url}/quick_applications`}
              component={QuickApplicationsLayout}
            />
          </div>
        </div>
      </div>
      <MainFooterBlock />
    </div>
  );
};

export default OfficerLayout;
