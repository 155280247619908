import React from 'react';
import { isObject, uniqueId } from 'lodash';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import { FieldArray, ErrorMessage } from 'formik';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import StartPointIcon from '@material-ui/icons/FiberManualRecord';
import PointIcon from '@material-ui/icons/Adjust';
import EndPointIcon from '@material-ui/icons/Room';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Close';
import { withSnackbar } from 'notistack';
import { applicationService } from '../../../../services/applicationService';
import LocationAutosuggest from './components/LocationAutosuggest';
import RouteLines from '../../../../components/RouteLines';
import RestrictedArea from '../../../../components/map/RestrictedArea';
import ApvgksLayer from '../../../../components/map/ApvksLayer';
import RouteInfo from "./RouteInfo";
import RouteLength from "./RouteLength";

const stamenTonerTiles = 'https://tile.aisktg.ru/tatarstan/{z}/{x}/{y}.png';
const stamenTonerAttr = '';

const getName = (index, markers) => {
  if (index === 0) {
    return 'Отправление:';
  }
  if (index === markers.length - 1) {
    return 'Прибытие:';
  }
  return 'Промежуточная точка';
};

const getStepByCoords = async latlng => {
  if (!latlng) {
    return {
      id: uniqueId(),
      isEmpty: false
    };
  }
  const { lat, lng } = latlng;
  const response = await applicationService.getCoordsLocation(lat, lng);
  const { data } = response;
  if (!data) {
    return null;
  }
  return {
    id: uniqueId(),
    isEmpty: false,
    text: data.text,
    coords: latlng
  };
};

const RouteMap = ({ values, arrayHelpers, setFieldTouched, setFieldValue, enqueueSnackbar }) => {
  const [mapCenter, setMapCenter] = React.useState([55.211, 50.634]);
  const zoomLevel = 7;
  const { steps } = values.route;

  const updateCenter = ({ coords }) => coords && setMapCenter([coords.lat, coords.lng]);
  const addStep = async ({ latlng }) => {
    try {
      const step = await getStepByCoords(latlng);
      if (!step) {
        enqueueSnackbar('Не удалось определить адрес', { variant: 'error' });
        return;
      }
      const emptyIndex = values.route.steps.findIndex(s => !s.text);
      if (emptyIndex < 0) {
        arrayHelpers.push(step);
      } else {
        arrayHelpers.replace(emptyIndex, step);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onRemove = index => () => {
    if (steps.length > 2) {
      arrayHelpers.remove(index);
    } else {
      arrayHelpers.replace(index, { text: '' });
    }
  };

  const replace = (index, step) => {
    arrayHelpers.replace(index, step);
    updateCenter(step);
  };

  const showNotAllowedError = () =>
    enqueueSnackbar('Точка находится в запрещенной зоне', { variant: 'error' });

  const onDrag = index => async e => {
    const step = await getStepByCoords(e.target.getLatLng());
    if (!step) {
      showNotAllowedError();
      return;
    }
    arrayHelpers.replace(index, step);
    updateCenter(step);
  };

  const onAddBtn = async () => {
    const step = await getStepByCoords();
    if (!step) {
      showNotAllowedError();
      return;
    }
    arrayHelpers.push(step);
  };

  const onLocationChange = index => async newLocation => {
    const { lat, lng } = newLocation.coords;
    const isAllowed = await applicationService.checkIsAllowed(lat, lng);
    if (!isAllowed) {
      showNotAllowedError();
      return;
    }
    replace(index, {
      ...values.route.steps[index],
      ...newLocation,
      isEmpty: false
    });
  };

  return (<>
    <Grid container spacing={16}>
      <Grid item sm={4}>
        <Grid container direction="column" spacing={16}>
          <Grid item>
            <Grid container direction="column" spacing={8} className="points_area">
              <div className="points_div points_line-map">
                <div className="points_line" />
              </div>
              {steps.map((step, index) => (
                <React.Fragment>
                  <Grid item>
                    <Grid container spacing={8} alignItems="center" justify="space-between">
                      <Grid
                        item
                        sm={2}
                        className="map-point"
                        container
                        alignItems="center"
                        justify="flex-start"
                      >
                        {index === 0 && <StartPointIcon />}
                        {index > 0 && index < steps.length - 1 && <PointIcon />}
                        {index === steps.length - 1 && <EndPointIcon className="red" />}
                      </Grid>
                      <Grid item sm={8}>
                        <LocationAutosuggest
                          value={values.route.steps[index]}
                          onTouched={() =>
                            setFieldTouched(`route.steps[${index}].text`, true, true)
                          }
                          onChange={onLocationChange(index)}
                        />
                      </Grid>
                      <Grid item sm={2}>
                        <IconButton aria-label="Delete" size="small" onClick={onRemove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <ErrorMessage name={`route.steps[${index}].text`}>
                    {msg => (
                      <Grid container spacing={8} alignItems="center">
                        <Grid item sm={2} />
                        <Grid item className="has-error">
                          {isObject(msg) ? (
                            Object.values(msg).map(m => (
                              <span style={{ display: 'block' }} key={m}>
                                {m}
                              </span>
                            ))
                          ) : (
                            <span>{msg}</span>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </ErrorMessage>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <Button className="info-btn no-margin" onClick={onAddBtn}>
              + Добавить пункт назначения
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={8}>
        <Map center={mapCenter} zoom={zoomLevel} onClick={addStep}>
          <TileLayer attribution={stamenTonerAttr} url={stamenTonerTiles} />
          <RouteLines steps={steps} setLines={(v) => setFieldValue('lines', v)}/>
          <RestrictedArea />
          <ApvgksLayer />
          {steps.map(({ coords, id, text }, index) => {
            const position = coords && coords.lat ? [coords.lat, coords.lng] : null;
            const textPre = getName(index, steps);
            if (position) {
              return (
                <Marker
                  key={id}
                  position={position}
                  draggable
                  alt={index}
                  onDragend={onDrag(index)}
                >
                  <Popup>
                    <span>
                      {textPre} {text}
                    </span>
                  </Popup>
                </Marker>
              );
            }
            return null;
          })}
        </Map>
      </Grid>
    </Grid>
    {values.is_fast && !values.id && !!values.rd_number && <RouteLength lines={values.lines} setFieldValue={setFieldValue}/>}
  </>);
};

const RouteMapContainer = ({ ...props }) => (
  <FieldArray
    name="route.steps"
    render={arrayHelpers => <RouteMap arrayHelpers={arrayHelpers} {...props} />}
  />
);

export default withSnackbar(RouteMapContainer);
