import { roleConstants } from '../constants/roleConstants';

const getHomeUrl = role_id => {
  switch (role_id) {
    case roleConstants.ROLE_ADMIN:
      return '/admin';
    case roleConstants.ROLE_OFFICER:
      return '/officer';
    case roleConstants.ROLE_INDIVIDUAL:
      return '/cabinet';
    case roleConstants.ROLE_FIRM:
      return '/cabinet';
    case roleConstants.ROLE_FIRMUSER:
      return '/firmuser';
    case roleConstants.ROLE_DEPARTMENT_AGENT:
      return '/department';
    case roleConstants.ROLE_WEIGHT_CONTROL:
      return '/control';
    case roleConstants.ROLE_APVGK:
        return '/apvgk-user';
    case roleConstants.ROLE_ACCOUNTANT:
        return '/accountant';
    case roleConstants.ROLE_TRACK_CHECKER:
        return '/apvgk-user';
    default:
      return '/';
  }
};

const getInitUrl = role_id => {
  const homeUrl = getHomeUrl(role_id);
  if (
    role_id === roleConstants.ROLE_ADMIN ||
    role_id === roleConstants.ROLE_OFFICER ||
    role_id === roleConstants.ROLE_INDIVIDUAL ||
    role_id === roleConstants.ROLE_FIRM ||
    role_id === roleConstants.ROLE_DEPARTMENT_AGENT ||
    role_id === roleConstants.ROLE_WEIGHT_CONTROL
  ) {
    return `${homeUrl}/applications`;
  }
  return homeUrl;
};

const getIdFromApplicationStepUrl = url => {
  const pathParts = url.split('/');
  let id = 0;
  for (let i = 0; i < pathParts.length; i++) {
    if (pathParts[i] === 'step') {
      id = parseInt(pathParts[i - 1], 10);
      if (isNaN(id)) {
        id = 0;
      }
    }
  }

  return id;
};

export const urlHelper = {
  getHomeUrl,
  getInitUrl,

  getIdFromApplicationStepUrl
};
