export const vehicleConstants = {
  SPEC_CAR_TYPE_ID: 18,
  OTHER_CAR_TYPE_ID: 19,
  OTHER_TRAILER_TYPE_ID: 20,
  OTHER_SEMI_TRAILER_TYPE_ID: 21,

  TAB_CAR: 0,
  TAB_TRAILER: 1,

  STATUS_NEW: 0,
  STATUS_DECLINE: 1,
  STATUS_REVIEW: 2,
  STATUS_ACCEPT: 3,
  STATUS_ALL: -1,

  SET_TAB_INDEX: 'VEHICLE_SET_TAB_INDEX',
  SET_TYPE_INDEX: 'VEHICLE_SET_TYPE_INDEX',

  GET_BY_STATUSES_REQUEST: 'VEHICLE_GET_BY_STATUSES_REQUEST',
  GET_BY_STATUSES_SUCCESS: 'VEHICLE_GET_BY_STATUSES_SUCCESS',
  GET_BY_STATUSES_FAILURE: 'VEHICLE_GET_BY_STATUSES_FAILURE',

  GET_PAGE_BY_STATUS_REQUEST: 'VEHICLE_GET_PAGE_BY_STATUS_REQUEST',
  GET_PAGE_BY_STATUS_SUCCESS: 'VEHICLE_GET_PAGE_BY_STATUS_SUCCESS',
  GET_PAGE_BY_STATUS_FAILURE: 'VEHICLE_GET_PAGE_BY_STATUS_FAILURE',

  SET_STATUS_REQUEST: 'VEHICLE_SET_STATUS_REQUEST',
  SET_STATUS_SUCCESS: 'VEHICLE_SET_STATUS_SUCCESS',
  SET_STATUS_FAILURE: 'VEHICLE_SET_STATUS_FAILURE',

  ADMIN_UPDATE_REQUEST: 'VEHICLE_ADMIN_UPDATE_REQUEST',
  ADMIN_UPDATE_SUCCESS: 'VEHICLE_ADMIN_UPDATE_SUCCESS',
  ADMIN_UPDATE_FAILURE: 'VEHICLE_ADMIN_UPDATE_FAILURE',

  TO_WORK_REQUEST: 'VEHICLE_TO_WORK_REQUEST',
  TO_WORK_SUCCESS: 'VEHICLE_TO_WORK_SUCCESS',
  TO_WORK_FAILURE: 'VEHICLE_TO_WORK_FAILURE',

  ACCEPT_REQUEST: 'VEHICLE_ACCEPT_REQUEST',
  ACCEPT_SUCCESS: 'VEHICLE_ACCEPT_SUCCESS',
  ACCEPT_FAILURE: 'VEHICLE_ACCEPT_FAILURE',

  DECLINE_REQUEST: 'VEHICLE_DECLINE_REQUEST',
  DECLINE_SUCCESS: 'VEHICLE_DECLINE_SUCCESS',
  DECLINE_FAILURE: 'VEHICLE_DECLINE_FAILURE',

  DECLINE_OPEN: 'VEHICLE_DECLINE_OPEN',
  DECLINE_CLOSE: 'VEHICLE_DECLINE_CLOSE',
  DECLINE_CURRENT_ITEM_OPEN: 'VEHICLE_DECLINE_CURRENT_ITEM_OPEN',

  REASON_OPEN: 'VEHICLE_REASON_OPEN',
  REASON_CLOSE: 'VEHICLE_REASON_CLOSE',

  DETAILS_OPEN: 'VEHICLE_DETAILS_OPEN',
  DETAILS_CLOSE: 'VEHICLE_DETAILS_CLOSE',

  GET_CABINET_PAGE_REQUEST: 'VEHICLE_GET_CABINET_PAGE_REQUEST',
  GET_CABINET_PAGE_SUCCESS: 'VEHICLE_GET_CABINET_PAGE_SUCCESS',
  GET_CABINET_PAGE_FAILURE: 'VEHICLE_GET_CABINET_PAGE_FAILURE',

  GET_ADMIN_PAGE_REQUEST: 'VEHICLE_GET_ADMIN_PAGE_REQUEST',
  GET_ADMIN_PAGE_SUCCESS: 'VEHICLE_GET_ADMIN_PAGE_SUCCESS',
  GET_ADMIN_PAGE_FAILURE: 'VEHICLE_GET_ADMIN_PAGE_FAILURE',

  LOAD_REQUEST: 'VEHICLE_LOAD_REQUEST',
  LOAD_SUCCESS: 'VEHICLE_LOAD_SUCCESS',
  LOAD_FAILURE: 'VEHICLE_LOAD_FAILURE',

  CREATE_REQUEST: 'VEHICLE_CREATE_REQUEST',
  CREATE_SUCCESS: 'VEHICLE_CREATE_SUCCESS',
  CREATE_FAILURE: 'VEHICLE_CREATE_FAILURE',

  UPDATE_REQUEST: 'VEHICLE_UPDATE_REQUEST',
  UPDATE_SUCCESS: 'VEHICLE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'VEHICLE_UPDATE_FAILURE',

  DELETE_REQUEST: 'VEHICLE_DELETE_REQUEST',
  DELETE_SUCCESS: 'VEHICLE_DELETE_SUCCESS',
  DELETE_FAILURE: 'VEHICLE_DELETE_FAILURE',

  SET_BRAND_ID: 'VEHICLE_SET_BRAND_ID',
  SET_VEHICLE_TYPE_ID: 'VEHICLE_SET_VEHICLE_TYPE_ID',

  SET_VEHICLE_OWNER_NAME: 'VEHICLE_SET_VEHICLE_OWNER_NAME',
  SET_VEHICLE_AXLE_COUNT: 'VEHICLE_SET_VEHICLE_AXLE_COUNT',

  USER_FORM_ADD: 'VEHICLE_USER_FORM_ADD',
  USER_FORM_REMOVE: 'VEHICLE_USER_FORM_REMOVE',
  RESET_CURRENT_ITEM: 'VEHICLE_RESET_CURRENT_ITEM',

  ACTIVE_FIELD: 'VEHICLE_ACTIVE_FIELD',
  SET_IS_EXISTING_NEW_USER: 'VEHICLE_SET_IS_EXISTING_NEW_USER',

  GET_FIRM_USERS_REQUEST: 'VEHICLE_GET_FIRM_USERS_REQUEST',
  GET_FIRM_USERS_SUCCESS: 'VEHICLE_GET_FIRM_USERS_SUCCESS',
  GET_FIRM_USERS_FAILURE: 'VEHICLE_GET_FIRM_USERS_FAILURE',

  GET_FIRM_VEHICLES_REQUEST: 'GET_FIRM_VEHICLES_REQUEST',
  GET_FIRM_VEHICLES_SUCCESS: 'GET_FIRM_VEHICLES_SUCCESS',
  GET_FIRM_VEHICLES_FAILURE: 'GET_FIRM_VEHICLES_FAILURE',
  GET_FIRM_VEHICLES_RESET: 'GET_FIRM_VEHICLES_RESET',

  LICENSE_UPLOAD_REQUEST: 'VEHICLE_LICENSE_UPLOAD_REQUEST',
  LICENSE_UPLOAD_SUCCESS: 'VEHICLE_LICENSE_UPLOAD_SUCCESS',
  LICENSE_UPLOAD_FAILURE: 'VEHICLE_LICENSE_UPLOAD_FAILURE',
  LICENSE_REMOVE: 'VEHICLE_LICENSE_REMOVE',
  SET_UPLOAD_LICENSES: 'VEHICLE_SET_UPLOAD_LICENSES',

  PTS_UPLOAD_REQUEST: 'VEHICLE_PTS_UPLOAD_REQUEST',
  PTS_UPLOAD_SUCCESS: 'VEHICLE_PTS_UPLOAD_SUCCESS',
  PTS_UPLOAD_FAILURE: 'VEHICLE_PTS_UPLOAD_FAILURE',
  PTS_REMOVE: 'VEHICLE_PTS_REMOVE',
  SET_UPLOAD_PTS: 'VEHICLE_SET_UPLOAD_PTS',

  LEASE_UPLOAD_REQUEST: 'VEHICLE_LEASE_UPLOAD_REQUEST',
  LEASE_UPLOAD_SUCCESS: 'VEHICLE_LEASE_UPLOAD_SUCCESS',
  LEASE_UPLOAD_FAILURE: 'VEHICLE_LEASE_UPLOAD_FAILURE',
  LEASE_REMOVE: 'VEHICLE_LEASE_REMOVE',
  SET_UPLOAD_LEASES: 'VEHICLE_SET_UPLOAD_LEASES',

  GET_EXCEPTIONS_REQUEST: 'VEHICLE_GET_EXCEPTIONS_REQUEST',
  GET_EXCEPTIONS_SUCCESS: 'VEHICLE_GET_EXCEPTIONS_SUCCESS',
  GET_EXCEPTIONS_FAILURE: 'VEHICLE_GET_EXCEPTIONS_FAILURE',

  CHECK_STATUSES_REQUEST: 'VEHICLE_CHECK_STATUSES_REQUEST',
  CHECK_STATUSES_SUCCESS: 'VEHICLE_CHECK_STATUSES_SUCCESS',
  CHECK_STATUSES_FAILURE: 'VEHICLE_CHECK_STATUSES_FAILURE',

  REMOVE_TAB_LIST_ITEM: 'VEHICLE_REMOVE_TAB_LIST_ITEM',

  ADMIN_FILTER_REQUEST: 'VEHICLE_ADMIN_FILTER_REQUEST',
  ADMIN_FILTER_SUCCESS: 'VEHICLE_ADMIN_FILTER_SUCCESS',
  ADMIN_FILTER_FAILURE: 'VEHICLE_ADMIN_FILTER_FAILURE',

  CLOSE_ERROR_MODAL: 'VEHICLE_CLOSE_ERROR_MODAL'
};
